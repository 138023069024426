import { LoanModel } from '../../../core/invest/LoanModel'
import { Logger } from '../../../core/utils/Logger'
import { FilterProvider } from '../../../hooks/context/FilterContext'
import { useInvestmentModel } from '../../../hooks/context/InvestmentModelContext'
import { usePivotContent } from '../../../hooks/pivot/usePivotContent'
import { useCardOpen } from '../../../hooks/useCardOpen'
import { LoanBalanceChart } from '../../charts/loan/LoanBalanceChart'
import { LoanCashflowChart } from '../../charts/loan/LoanCashflowChart'
import { useChartProfile } from '../../charts/useChartProfile'
import { Card, CardSection } from '../../controls/Card'
import { CardHeaderWithMenu, defaultToolbarChartProps } from '../CardHeaderWithMenu'
import { CashflowCard } from '../cashflow/CashflowCard'
import { TransactionItemsCard } from '../transaction/TransactionItemsCard'
import { LoanNarrative, LoanSummaryCard } from './LoanSummaryCard'

const logger = new Logger("LoanSection")

export function LoanSection(props:any) {
  const { loan, name } = useInvestmentModel<LoanModel>()

  logger.setContext(name).debug("Rendering: key=%s", loan.key, loan)

  // Render Cards
  return (
    <FilterProvider name="LoanSection">
      <LoanScheduleChartCard open />
      <CashflowCard title="Loan Schedule" />

      <FilterProvider name="LoanAmortization">
        <LoanSummaryCard title="Loan Amortization" />
        <TransactionItemsCard title="Loan Transactions" />
      </FilterProvider>
    </FilterProvider>
  )
}

function LoanScheduleChartCard({open}:{open?:boolean}) {
  const cardName = "LoanScheduleChartCard"
  const { loan } = useInvestmentModel<LoanModel>()
  const { isOpen } = useCardOpen(cardName, loan.key, open === true)
  const { size } = useChartProfile()
  usePivotContent(undefined)

  return (
    <Card id={cardName} open={isOpen}>
      <CardHeaderWithMenu cardName={cardName} icon="fal fa-chart-column"
                           {...defaultToolbarChartProps} />
      <CardSection>
        <LoanBalanceChart height={size.height} />
        <LoanCashflowChart height={size.height} />
        <LoanNarrative />
      </CardSection>
    </Card>
  )
}
