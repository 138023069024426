import { isMobileOnly } from 'react-device-detect'
import { Bar, Cell, Line } from 'recharts'
import { Logger } from '../../../core/utils/Logger'
import { timeScaleEnding } from '../../../core/utils/TimeScale'
import { useFilterContext } from "../../../hooks/context/FilterContext"
import { useInvestmentModel } from '../../../hooks/context/InvestmentModelContext'
import { CartesianChart, CartesianChartProps } from '../CartesianChart'
import { useChartProfile } from '../useChartProfile'
import { useGradientRedGreen } from '../useGradient'
import { useBudgetActualChartData } from './useBudgetActualChartData'

export const logger = new Logger("charts.BudgetActualBarChart")

export function BudgetActualBarChart(props:Partial<CartesianChartProps>) {
  const { name } = useInvestmentModel()
  const { filterStack, filter } = useFilterContext()
  const { chartData } = useBudgetActualChartData()
  const { linearGradient, gradientRedUrl, gradientGreenUrl } = useGradientRedGreen()

  const { height, aspect, scrollable, noHeader } = props
  const { size, animationDuration } = useChartProfile()
  const noTooltip = isMobileOnly

  logger.setContext(name)
        .debug("Rendering %d data points, filter=%o", chartData.length, filter)
  
  return (
    <div id="BudgetActualBarChart" className="chart">
      {!noHeader &&
        <h3>Budget vs Actual</h3>
      }
      <CartesianChart
          data={chartData} scrollable={scrollable} height={height} aspect={aspect} 
          noLegend={true} noTooltip={noTooltip} units={filter.scale.units}
          onClickXAxis={(e:any) => onClickDate(e.value)}>
        <Bar dataKey="Actual"
            animationDuration={animationDuration}
            barSize={size.barSizeMini}
            maxBarSize={size.barSizeMini}
            minPointSize={2}
            radius={size.radiusBar}>
            { chartData.map((dp) => (
              <Cell fill={dp.Actual < 0 ? gradientRedUrl : gradientGreenUrl} key={dp.key} 
                    cursor="pointer" 
                    onClick={() => onClickDate(dp.date)} />
            ))}
        </Bar>
        <Line dataKey="Budget" type="step" dot={false} 
              strokeDasharray="3 3"
              strokeWidth={1}
              animationDuration={animationDuration} />
        <defs>{ linearGradient }</defs>
      </CartesianChart>
    </div>
  )

  function onClickDate(endDate:number) {
    logger.debug("onClickDate: date=%s", Logger.Date(endDate, "yyyy-MM-dd HH:mm:ss.SSS"))

    const scale = timeScaleEnding(filter.scale.units, endDate)
    filterStack.push({ scale, included:undefined })
  }

  // function onBrush({ startIndex, endIndex }:{ startIndex?:number, endIndex?:number }) {
  //   const startData = startIndex ? chartData[startIndex] : undefined
  //   const endData = endIndex ? chartData[endIndex] : undefined

  //   logger.debug("onBrush: start={index=%d, date=%s}, end={index=%d, date=%s}",
  //                 startIndex, Logger.Date(startData?.date),
  //                 endIndex, Logger.Date(endData?.date))
  // }
}


