import { useAtomValue } from "jotai"
import { ReactNode } from "react"
import { useAtomKey } from "../../hooks/useAtomKey"

export function Expandable({ openAtom, children } : { openAtom:any, children: ReactNode}) {
  const visible = useAtomValue(openAtom)
  return visible ? children : <></>
}

export function ExpandableLabel({ label, expand=false, children } : { label:string, expand?:boolean, children: ReactNode}) {
  const [ expanded, setExpanded ] = useAtomKey(label, expand)
  const classImage = (expanded ? "far fa-chevron-up" : "far fa-chevron-down")

  return (
    <div className="expand">
      <label onClick={() => setExpanded(!expanded)}>
        { label }
        <i className={classImage}/>
      </label>
      { expanded && children }
    </div>
  )
}
