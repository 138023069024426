import { Item } from "../../types/Item";
import { NumberFormats } from "../../utils/Numbers";


export class PerformanceStats {
  public valueChange: number | undefined;
  public percentChange: number | undefined;
  public percentFormat: any;

  public balance(category: Item) { return 0; }

  get openingBalance() { return 0; }
  get currentBalance() { return 0; }

  protected calcPercentChange(from: number | undefined, to: number | undefined) {
    const valueChange = (to ?? 0) - (from ?? 0);

    let percentChange, percentFormat;
    if (from) {
      percentChange = valueChange / Math.abs(from);
      const change = Math.abs(percentChange);

      if (change > 10) {
        percentFormat = NumberFormats.percentx;
      } else if (change > 0.1) {
        percentFormat = NumberFormats.percent0;
      } else {
        percentFormat = NumberFormats.percent1;
      }
    }

    this.percentChange = percentChange;
    this.percentFormat = percentFormat;
    this.valueChange = valueChange;
  }
}
