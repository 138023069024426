import { ExpandableContents } from "../PageSection"

export function DeveloperGuides() {
  return (
    <article id="DeveloperGuides">
      <header>
        <h1>Developer Guides</h1>
      </header>
      <section>
        <ExpandableContents path="dev"/>
      </section>
    </article>
  )
}