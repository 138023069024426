import { setProperty } from "../../core/model/Model"
import { ModelKeys } from "../../core/model/ModelKeys"
import { BankStmtRuleItem, BankStmtRuleItemProps } from "../../core/types/BankStmtRuleItem"
import { Item, ItemProps } from "../../core/types/Item"
import { Logger } from "../../core/utils/Logger"
import { NumberFormats } from "../../core/utils/Numbers"
import { useFormItem } from "../../hooks/context/FormContext"
import { useModel } from '../../hooks/context/ModelContext'
import { useAtomRef } from "../../hooks/useAtomRef"
import { Editable } from "../controls/editor/Editable"
import { Field, FieldSet, Form, FormBody } from "../controls/form/Form"
import { FormFooter } from "../controls/form/FormFooter"
import { FormHeader } from "../controls/form/FormHeader"
import { ItemPickerTree } from "../controls/select/ItemPickerTree"
import { ShowIfAtom } from "../controls/ShowIf"
import { ItemFormSharedFields } from "./ItemFormSharedFields"

const logger = new Logger("forms.BankStmtRuleItemForm")

export function BankStmtRuleItemForm(props : {
  item: BankStmtRuleItem
  formItemKey?: string
  open?: boolean
  noHeader?: boolean
  onClose: () => void
}) {
  const { model } = useModel()
  const { formItemKey, open, noHeader, onClose } = props

  const [ rule, setRule ] = useFormItem(formItemKey ?? "item", props.item)
  const openAtom = useAtomRef(open === true)

  const icon = model.getItemIcon(rule) || "fal fa-line-columns"

  logger.debug("Rendering: key=%s, open=%s, item=%o, props.item=%o", rule.key, open, rule, props.item)

  return (
    <Form id="BankStmtRuleItemForm">
      { !noHeader &&
        <FormHeader title="Bank Statement Rule Editor" icon={icon} onClose={onClose} openAtom={openAtom} />
      }
      <FormBody>
        <FieldSet>
          <Field>
            <label>Sort Order</label>
            <Editable item={rule} property={ItemProps.sortOrder} onChange={onChange} readonly={false}
                      numberFormat={NumberFormats.integer} />
          </Field>
          <Field>
            <label>Priority</label>
            <Editable item={rule} property={BankStmtRuleItemProps.priority} onChange={onChange} readonly={false}
                      numberFormat={NumberFormats.integer} />
          </Field>
          <Field>
            <label>Keywords</label>
            <Editable item={rule} property={ItemProps.name} onChange={onChange} readonly={false} />
          </Field>
          <Field>
            <label>Category</label>
            <ItemPickerTree
              rootKey={ModelKeys.category.root}
              selected={model.getItem(rule.categoryKey)}
              onChange={onChangeCategory}
              placeholder="Select a Category..."
            />
          </Field>
        </FieldSet>

        <ShowIfAtom visible={openAtom}>
          <FieldSet>
            <Field>
              <label>Code</label>
              <Editable item={rule} property={ItemProps.code} onChange={onChange} readonly={false} />
            </Field>
            <Field className="description">
              <label>Description</label>
              <Editable item={rule} property={ItemProps.description} onChange={onChange} readonly={false} />
            </Field>
          </FieldSet>
        </ShowIfAtom>

        <ItemFormSharedFields item={rule} onChange={onChange} readonly={false} openAtom={openAtom} />
      </FormBody>
      
      { !noHeader &&
        <FormFooter showClose showDelete showSave onClose={onClose}/>
      }
    </Form>
  )

  function onChange(rule:BankStmtRuleItem, property:any, value:any) {
    logger.debug("onChange: rule.key=%s, property=%s, value=%s", rule.key, property, value)

    const newRule = setProperty({...rule}, property, value)
    setRule(newRule)
  }

  function onChangeCategory(category:Item) {
    const newRule = setProperty({...rule}, BankStmtRuleItemProps.categoryKey, category.key)
    setRule(newRule)
  }
}
