import { getDimension } from '../../../core/builder/CashflowFilter'
import { PivotCell, PivotRow } from '../../../core/builder/PivotTable'
import { ModelKeys } from "../../../core/model/ModelKeys"
import { Item } from '../../../core/types/Item'
import { formatDate, formatDateRange } from '../../../core/utils/DateFormat'
import { Logger } from "../../../core/utils/Logger"
import { NumberFormats, formatCurrency, formatNumber, formatPercent } from '../../../core/utils/Numbers'
import { timeScaleStarting } from '../../../core/utils/TimeScale'
import { clzName } from '../../../core/utils/Utils'
import { useFilterContext } from "../../../hooks/context/FilterContext"
import { useInvestmentModel } from '../../../hooks/context/InvestmentModelContext'
import { useModel } from '../../../hooks/context/ModelContext'
import { FilterStack } from '../../../hooks/filter/FilterStack'
import { usePivotContent } from '../../../hooks/pivot/usePivotContent'
import { usePivotTables } from '../../../hooks/pivot/usePivotTables'
import { usePivotType } from '../../../hooks/pivot/usePivotType'
import { useCurrencyFormat } from '../../../hooks/useCurrencyFormat'
import { DateRange } from '../../controls/editor/DateRange'

const logger = new Logger("cashflow.CashflowPivotTable")

export function CashflowPivotTable(props:any) {
  const { name } = useInvestmentModel()
  const { filterStack, isCategory } = useFilterContext()
  const { pivotTable, filter } = usePivotTables()
  const { rows, columns, totals, categories } = pivotTable
  
  const filterColumn = filter.columnItem
  const showExtraRow = filterColumn && (columns.length > 1)

  const { isBalanceContent, isCumTotalContent } = usePivotContent()
  const { pivotType } = usePivotType()

  const showBalance = !(isCategory && filterColumn) //isBalanceContent || isLoan || !(isCategory && filterColumn)
  const showCumTotal = isCumTotalContent
  const showRowTotal = !isBalanceContent
  const showEquity = false // isAccountType || isLoan

  const filterColTotal = totals.total - columns.reduce((total, column) => total + totals.cell(column.key).total, 0)
  const showFilterCol = Math.abs(filterColTotal) >= 0.01

  const rowProps:RowProps = { 
    columns, categories, filterColumn, filterStack, filterColTotal, 
    showBalance, showCumTotal, showRowTotal, showFilterCol, showEquity,
    isBalanceContent, isCumTotalContent
  }

  logger.setContext(name)
  logger.debug("Rendering: %d rows, pivotType=%s", rows.length, pivotType)
  
  if (logger.isTraceEnabled) {
    logger.debug("filter=%o, accounts=%o, columns=%o, rows=%o, allRows=%o", 
                  Logger.Filter(filter),
                  pivotTable.accounts.map(account => account.name),
                  columns.map(col => col.name), 
                  rows.map(row => row.date),
                  pivotTable.allRows.map(row => row.date))

    console.table(rows.map(row => ({date:formatDate(row.date), total:row.total})))
  }

  return (
    <table id="CashflowPivotTable" className="table cashflow-table sticky-headers">
      <thead>
        <tr>
          <th>
            Date
          </th>
          { showExtraRow 
            ? <th colSpan={columns.length + (showFilterCol ? 1 : 0)}>
                <PivotColumnLink item={filterColumn} parent={true} />
              </th>
            : <ColHeaders columns={columns} showFilterCol={showFilterCol} />
          }
          { showRowTotal && <th>Total</th> }
          { showBalance && <th>Balance</th> }
          { showEquity && <th>Equity</th> }
        </tr>
        { showExtraRow && 
          <tr>
            <th/>
            <ColHeaders columns={columns} showFilterCol={showFilterCol} />
            { showRowTotal && <th/> }
            { showEquity && <th/> }
            <th/>
          </tr>
        }
      </thead>
      <tbody>
        { rows.map(row =>
          <Row row={row} rowProps={rowProps} key={row.key} />
        )}
      </tbody>
      <tfoot className="totals">
        <tr>
          <th>
            { showRowTotal ? "Total" : "Balance" }
          </th>
          { columns.map(col =>
            <PivotCellLink currency={getValue(totals.cell(col.key))} key={col.key} />
          )}
          { showFilterCol && <PivotCellLink currency={filterColTotal} />}
          { showRowTotal && <PivotCellLink currency={totals.total} /> }
          { showBalance && <PivotCellLink currency={totals.balance} /> }
          { showEquity && <td/> }
        </tr>
      </tfoot>
    </table>
  )

  function getValue(cell:PivotCell) {
    return (
      isBalanceContent  ? cell.balance : 
      isCumTotalContent ? cell.cumTotal : cell.total
    )
  }
}

interface RowProps { 
  columns: Item[],
  categories: Item[],
  filterColumn: Item | undefined,
  filterStack: FilterStack,
  filterColTotal: number,
  showBalance: boolean, 
  showCumTotal: boolean, 
  showRowTotal: boolean, 
  showFilterCol: boolean,
  showEquity: boolean,
  isBalanceContent: boolean, 
  isCumTotalContent: boolean
}

function Row({ row, rowProps } : { row:PivotRow, rowProps:RowProps }) {
  const { model } = useModel()
  const { 
    columns,
    categories,
    filterStack,
    filterColumn,
    filterColTotal,
    showBalance, 
    showCumTotal, 
    showRowTotal, 
    showFilterCol,
    showEquity,
    isBalanceContent, 
    isCumTotalContent } = rowProps

  if (row.openingRow && row.count < 1) {
    return <></>
  }

  let filterColValue
  if (filterColTotal && !row.openingRow) {
    const colTotal = columns.reduce((total, column) => total + row.cell(column.key).total, 0)
    const diff = row.total - colTotal
    if (Math.abs(diff) >= 0.01) {
      filterColValue = diff
      logger.warn("Column total %f does not match [%s] row total %f, diff=%f", 
                   colTotal, formatDate(row.date), row.total, filterColValue)
    }
  }

  return (
    <tr>
      <PivotRowLink row={row} />
      { columns.map(column => 
        <Column column={column} key={column.key} />
      )}
      { showFilterCol &&
        <PivotCellLink currency={filterColValue} onClick={() => onClickCell(row, filterColumn as Item, true)} />
      }
      { showRowTotal && 
        <PivotCellLink currency={row.openingRow ? undefined : (showCumTotal ? row.cumTotal : row.total)} /> 
      }
      { showBalance && <PivotCellLink currency={row.balance} /> }
      { showEquity && <PivotCellLink percent={row.equityPercent} /> }
    </tr>
  )

  function Column({column}:{column:Item}) {
    const cell = row.cell(column.key)
    const value = row.openingRow ? undefined : getValue(cell)
    return (
      <PivotCellLink currency={value} onClick={() => onClickCell(row, column)} />
    )
  }

  function onClickCell(row:PivotRow, column:Item, raw?:boolean) {
    const scale = timeScaleStarting(row.scale.units, row.scale.start, row.scale.end)

    const { dimension } = getDimension(model, column)

    if (raw === undefined) {
      raw = ["2W","1W","1D"].includes(scale.units) && !hasCategories()
    }
  
    filterStack.push({ columnItem: column, dimension, scale, raw })
    
    /** Do any sub-categories of the category being clicked have entries in the pivot table */
    function hasCategories() {
      const subCategoryKeys = model.childrenKeys(column.key)
      return categories?.some(category => subCategoryKeys.includes(category.key))
    }
  }  

  function getValue(cell:PivotCell) {
    return (
      isBalanceContent  ? cell.balance : 
      isCumTotalContent ? cell.cumTotal : cell.total
    )
  }
}

function ColHeaders({ columns, showFilterCol }: { columns:Item[], showFilterCol:boolean}) {
  const { model } = useModel()

  return (
    <>
      { columns.map(col =>
        <th title={model.getItemDescription(col.key)} key={col.key}>
          <PivotColumnLink item={col} />
        </th>
      )}
      { showFilterCol && <th>Other</th> }
    </>
  )
}

export function PivotRowLink({row}: { row: PivotRow }) {
  const { dateFormat } = usePivotTables()
  const { filterStack } = useFilterContext()

  return (
    <th className="link" onClick={onClick} title={formatDateRange(row.startDate, row.endDate)}>
      {formatDate(row.endDate, dateFormat) + (row.openingRow ? "*" : "")}
    </th>
  )

  function onClick() {
    const scale = timeScaleStarting(row.scale.units, row.scale.start)
    const raw = ["2W","1W","1D"].includes(scale.units)
    filterStack.push({ scale, raw })
  }
}

export function PivotColumnLink(props: {
  item?: Item,
  itemKey?: string
  parent?: boolean
  className?: string
}) {
  const { filterStack, model } = useFilterContext()
  const { parent } = props
  const columnItem = props.item || model.getItem(props.itemKey)

  const { dimension } = getDimension(model, columnItem)

  return (
    <span className="link" onClick={dimension !== "Type" ? onClick : undefined}>
      {columnItem.name}
    </span>
  )

  function onClick() {
    if (parent) {
      const parentItem = model.getItemParent(columnItem)
      const filterItem = (parentItem.key === ModelKeys.category.root) ? undefined : parentItem

      logger.debug("PivotColumnLink.onClick: parent=%s, columnItem=%s, parentItem=%s, filterItem=%s", 
                    parent, columnItem?.name, parentItem?.name, filterItem?.name)

      filterStack.push({ columnItem:filterItem, dimension:dimension })
    } else {
      logger.debug("PivotColumnLink.onClick: parent=%s, columnItem=%s", parent, columnItem?.name)
      filterStack.push({ columnItem:columnItem, dimension:dimension })
    }
  }
}

export function PivotCellLink(props: {
  value?: number
  percent?: number
  currency?: number
  className?: string
  onClick?: () => void
}) {
  const currencyFormat = useCurrencyFormat()
  const { value, percent, currency, onClick } = props

  const displayStr = (currency !== undefined) ? formatCurrency(currency, currencyFormat) :
                     (percent !== undefined)  ? formatPercent(percent, NumberFormats.percent2) :
                     (value !== undefined)    ? formatNumber(value, NumberFormats.integer) : ""

  const className = clzName(
    props.className, 
    { link: onClick && displayStr !== "" },
    { negative: (currency || percent || value || 0) < 0 },
  )

  return (
    <td className={className} onClick={onClick}>
      { displayStr }
    </td>
  )
}

export function PivotRowFilter(props:any) {
  const { scale } = useFilterContext()

  return <DateRange start={scale.start} end={scale.end} />
}
