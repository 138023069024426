import { Dimension } from "../../../core/filter/Filter"
import { Logger } from "../../../core/utils/Logger"
import { FilterProvider } from '../../../hooks/context/FilterContext'
import { InvestmentModelProvider, useInvestmentModel } from '../../../hooks/context/InvestmentModelContext'
import { CardSelector } from "../CardSelector"
import { CashflowCard } from '../cashflow/CashflowCard'
import { CashflowChartCard } from '../cashflow/CashflowChartCard'
import { getDashboardScale } from '../dashboard/DashboardCard'
import { TransactionItemsCard } from '../transaction/TransactionItemsCard'

const logger = new Logger("BudgetSection")

export function BudgetSection(props:any) {
  const { investmentModel, name, children } = useInvestmentModel()

  logger.setContext(name).debug("Rendering...")

  return (
    <FilterProvider name={logger.name} initFilter={initFilter}>
      <CashflowChartCard open />
      <CashflowCard open />
      <TransactionItemsCard tree title="Budget Transactions" />

      { children.map(child =>
        <InvestmentModelProvider item={child.item} key={child.key}>
          <FilterProvider name={logger.name}>
            <CardSelector />
          </FilterProvider>
        </InvestmentModelProvider>
      )}
    </FilterProvider>
  )
  
  function initFilter() {
    const { scale } = getDashboardScale(investmentModel)
    const dimension:Dimension = "Category"
    return { scale, dimension }
  }
}
