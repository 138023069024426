import { useAtomValue } from "jotai"
import { filesAtom } from "../core/user/FilesModel"
import { useCurrentUserProfile } from "./useCurrentUser"

export function useFilesModel() {
  const { userProfile } = useCurrentUserProfile()
  const files = useAtomValue(filesAtom)
  const currentFile = files.find(file => file.key === userProfile?.fileKey)

  return { files, currentFile }
}