import { useAtomValue } from 'jotai'
import { BankStmtModelAtoms } from '../../core/atom/BankStmtModelAtoms'

export function useBankStmtModel() {
  return useAtomValue(BankStmtModelAtoms.model)
}

export function useBankStmtRules() {
  return useAtomValue(BankStmtModelAtoms.model)?.rules ?? []
}
