import { Logger } from '../../core/utils/Logger'
import { useFilterContext } from '../context/FilterContext'
import { useInvestmentModel } from '../context/InvestmentModelContext'
import { newAtomMap, useAtomMap } from "../useAtomMap"

export const logger = new Logger("hooks.usePivotLayout")

type PivotLayout = "Tree" | "Table"
const pivotLayoutAtomMap = newAtomMap<PivotLayout>()

export function usePivotLayout(initValue: PivotLayout = "Table") {
  const { item } = useInvestmentModel()
  const { stackName } = useFilterContext()
  const [ pivotLayout, setPivotLayout, pivotLayoutAtom ] = useAtomMap(pivotLayoutAtomMap, () => initValue, stackName, item.key)

  logger.trace("Rendering: cardKey=%s, pivotLayout=%s", stackName, pivotLayout)

  return {
    pivotLayout,
    setPivotLayout,
    pivotLayoutAtom,
    isTableLayout: pivotLayout === "Table",
    isTreeLayout: pivotLayout === "Tree",
  }
}
