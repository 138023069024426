import { ReactNode, useEffect } from "react"
import { Logger } from "../../core/utils/Logger"
import { useCurrentPage, useWizard } from "./context/WizardContext"

export const logger = new Logger("wizards.WizardPager")

export function WizardPager({ children } : { children:ReactNode }) {
  const { currentPageId, onScroll } = usePageScroller()

  logger.debug("Rendering: currentPageId=%s", currentPageId)

  return (
    <div className="card-pager" onScroll={onScroll} onClick={(e:any) => e.stopPropagation()}>
      { children }
    </div>
  )
}

let scrollTimeout:any = null

/**
 * Scroll the specified page into view when currentPage changes
 */
function usePageScroller() {
  const { currentPageId, numPages, pageIds } = useWizard()
  const { currentPage, setCurrentPage } = useCurrentPage()

  logger.trace("usePageScroller: currentPageId=%s, page %d/%d, pageIds=%o", 
                currentPageId, currentPage, numPages, pageIds)

  // Scroll the specified page into view when currentPage changes
  useEffect(() => {
    logger.debug("usePageScroller: Scrolling %s into view", currentPageId)

    const pageElement = document.getElementById(currentPageId)
    if (pageElement) {
      pageElement.scrollIntoView({ behavior: "smooth" })
    }
  }, [currentPageId])

  function onScroll(e:any) {
    if (scrollTimeout !== null) {
      clearTimeout(scrollTimeout)
    }
    scrollTimeout = setTimeout(() => onScrollTimeout(e), 100)
  }

  function onScrollTimeout(e:any) {
    const { childElementCount, scrollLeft, scrollWidth } = e.target

    const pageWidth = Math.floor(scrollWidth / childElementCount)
    const thisPage = Math.floor(scrollLeft / pageWidth)

    if (thisPage !== currentPage) {
      setCurrentPage(thisPage)
    }
  }

  return { currentPageId, onScroll }
}