import { useNavigate } from "react-router-dom"
import { FilesItem, newFile } from "../../../core/user/FilesModel"
import { createUserFile } from "../../../core/user/FilesService"
import { Logger } from "../../../core/utils/Logger"
import { FormDataProvider, useFormData, useFormItem } from "../../../hooks/context/FormContext"
import { WizardProvider } from "../context/WizardContext"
import { WizardFormSelector } from "../WizardFormSelector"
import { WizardSavePage } from "../WizardSavePage"
import { CreateFileWizardData } from "./CreateFileWizardData"
import { SelectPersona } from "./SelectPersona"

const logger = new Logger("wizards.CreateFileWizard")

const primaryItemProp = "fileItem"

const pageIds = [
  "SelectPersona",
  primaryItemProp,
  "WizardSavePage"
]

export function CreateFileWizard() {
  logger.debug("Rendering:")

  return (
    <WizardProvider name="CreateFileWizard" pageIds={pageIds} primaryItemProp={primaryItemProp}>
      <FormDataProvider>
        <NewFileThenSelectPersona/>
        <WizardFormSelector formItemKey={primaryItemProp} />
        <CreateFileWizardSavePage />
      </FormDataProvider>
    </WizardProvider>
  )
}

/** Component is required because useFormItem must be within a FormDataProvider */
function NewFileThenSelectPersona() {
  useFormItem(primaryItemProp, () => newFile())
  return <SelectPersona/>
}

function CreateFileWizardSavePage() {
  const navigate = useNavigate()
  const { formData, getFormItems } = useFormData<CreateFileWizardData>()

  return <WizardSavePage onSave={onCreateFile} />

  function onCreateFile() {
    const formItems = getFormItems()
    logger.debug("onSave: items=%o", formItems)

    // Create the new file, and load initial data into it
    const fileItem = formItems[0] as FilesItem
    createUserFile(fileItem, formData.selectedPersona?.data)

    // Finish by opening the File Manager
    navigate("/files")
  }
}
