import { format } from 'date-fns'
import { Filter } from "../filter/Filter"
import { formatDate } from './DateFormat'
import { Level, LoggerConfig } from './LoggerConfig'
import { TimeScale, TimeUnits } from './TimeScale'
import { Timers } from './Timers'

const util = require('util')

export class Logger {
  public static readonly DefaultDateFormat = LoggerConfig.defaultDateFormat

  public name:string
  private context:string | undefined
  private included = true

  private _level = LoggerConfig.DefaultLogLevel

  constructor(name:string, context?:string) {
    this.name = name
    this.context = context
    this.setIncluded()
  }

  public get level() { return this._level }

  public set level(level:Level) {
    this._level = level
  }

  public get isErrorEnabled() { return this._level >= Level.ERROR }
  public get isWarnEnabled()  { return this._level >= Level.WARN }
  public get isInfoEnabled()  { return this._level >= Level.INFO }
  public get isLogEnabled()   { return this._level >= Level.LOG }
  public get isDebugEnabled() { return this._level >= Level.DEBUG }
  public get isTraceEnabled() { return this._level >= Level.TRACE }

  private now() {
    return format(Date.now(), Logger.DefaultDateFormat)
  }

  private label(method?:string) {
    if (method) {
      if (this.context) {
        return `[${this.name}.${method}::${this.context}] `
      }
      return `[${this.name}.${method}] `
    }
    
    if (this.context) {
      return `[${this.name}::${this.context}] `
    }
    
    return `[${this.name}] `
  }
   
  private reformat(formatStr:string, level:number, label:string = this.label()) {
    return `${this.now()} ${label}${formatStr}`
  }

  private setIncluded() {
    this.included = true

    const { inclusions, exclusions } = LoggerConfig

    if (inclusions.size !== 0) {
      this.included = inclusions.has(this.name) || inclusions.has(this.context ?? "")
    }
    if (exclusions.size !== 0) {
      this.included = !(exclusions.has(this.name) || exclusions.has(this.context ?? ""))
    }
  }

  public setName(name:string) : Logger {
    this.name = name
    this.setIncluded()
    return this
  }

  public addContext(context?:string) : Logger {
    if (context && context !== "") {
      if (this.context) {
        this.context = `${this.context}::${context}`
      } else {
        this.context = context
      }
    }
    this.setIncluded()
    return this
  }

  public setContext(context?:string) : Logger {
    this.context = context
    this.setIncluded()
    return this
  }

  /** Used to log the start of a method or execution block */
  public start(method:string, format?:string, ...args:any[]) : number {
    const start = Timers.start(`${this.name}.${method}`)

    if (this.isInfoEnabled && this.included) {
      const label = this.label(method)
      if (LoggerConfig.useGroups) {
        console.groupCollapsed(`${this.now()} ${label}`)
      }
      if (format || !LoggerConfig.useGroups) {
        console.info(this.reformat(format || "Started", Level.INFO, label), ...args)
      }
    }
    return start
  }

  /** Used to log the end of a method or execution block */
  public finish(method:string, format?:string, ...args:any[]) {
    const elapsed = Timers.finish(`${this.name}.${method}`)
    
    if (this.isInfoEnabled && this.included) {
      const label = this.label(method)
      const fmt = `Finished in ${elapsed}ms${format ? ": " + format : ""}`

      if (LoggerConfig.useGroups) {
        console.groupEnd()
      }
      console.info(this.reformat(fmt, Level.INFO, label), ...args)
    }
  }

  // public error(format:string, ...args:any[]) {
  //   if (this.isErrorEnabled) {
  //     console.error(this.reformat(format, Level.ERROR), ...args)
  //   }
  // }

  public error(method:string, format:string, ...args:any[]) {
    const message = util.format(this.reformat(format, Level.ERROR, this.label(method)), ...args)
    console.error(message)
    return message
  }

  public warn(format:string, ...args:any[]) {
    if (this.isWarnEnabled) {
      console.warn(this.reformat(format, Level.WARN), ...args)
    }
  }

  public info(format:string, ...args:any[]) {
    if (this.isInfoEnabled && this.included) {
      console.info(this.reformat(format, Level.INFO), ...args)
    }
  }

  public log(format:string, ...args:any[]) {
    if (this.isLogEnabled && this.included) {
      console.log(this.reformat(format, Level.LOG), ...args)
    }
  }

  public debug(format:string, ...args:any[]) {
    if (this.isDebugEnabled && this.included) {
      console.debug(this.reformat(format, Level.DEBUG), ...args)
    }
  }

  public trace(format:string, ...args:any[]) {
    if (this.isTraceEnabled && this.included) {
      console.trace(this.reformat(format, Level.TRACE), ...args)
    }
  }

  public static Date(date: number|undefined, formatStr:string = Logger.DefaultDateFormat) {
    return new LogDate(date, formatStr)
  }
  
  public static Range(start: number|undefined, end: number|undefined, formatStr:string = "dd-MMM-yyyy") {
    return new LogDateRange(start, end, formatStr)
  }
  
  public static Scale(scale:TimeScale, formatStr:string = Logger.DefaultDateFormat) {
    return new LogScale(scale, formatStr)
  }
  
  public static Filter(filter:Partial<Filter>, formatStr:string = Logger.DefaultDateFormat) {
    const result:any = {}

    setProperty(result, "id",             filter.id)
    setProperty(result, "scale",          filter.scale && new LogScale(filter.scale, formatStr).toString())
    setProperty(result, "included",       filter.included && Array.from(filter.included.keys()).map((date:number) => formatDate(date, formatStr)))
    setProperty(result, "columnItem",     filter.columnItem?.name)
    setProperty(result, "accountItem",    filter.accountItem?.name)
    setProperty(result, "dimension",      filter.dimension)
    setProperty(result, "interval",       filter.interval)
    setProperty(result, "duration",       filter.duration)
    setProperty(result, "dateRangeCode",  filter.dateRangeCode)
    setProperty(result, "raw",            filter.raw)

    return result
  }
}

function setProperty<T, K extends keyof T>(obj: T, key: K, value: T[K]) {
  if (value === undefined || value === null) {
    delete obj[key]
  } else {
    obj[key] = value
  }
  return obj
}

class LogDate {
  private date: number
  private formatStr: string

  constructor(date: number|undefined, formatStr:string) {
    this.date = date ?? 0
    this.formatStr = formatStr
  }

  toString() : string {
    return formatDate(this.date, this.formatStr)
  }
}

class LogDateRange {
  private start: number|undefined
  private end: number|undefined
  private formatStr: string

  constructor(start: number|undefined, end: number|undefined, formatStr:string) {
    this.start = start
    this.end = end
    this.formatStr = formatStr
  }

  toString() : string {
    const start = formatDate(this.start ?? 0, this.formatStr)
    const end = formatDate(this.end ?? 0, this.formatStr)
    return "start=" + start + ", end=" + end
  }
}

class LogScale extends LogDateRange {
  private units:TimeUnits

  constructor(scale:TimeScale, formatStr:string) {
    super(scale.start, scale.end, formatStr)
    this.units = scale.units
  }

  toString() : string {
    return super.toString() + ", units:" + this.units
  }
}
