import { DateRangeCode, DateRangeCodes, formatDateRangeCode } from "../../../core/utils/TimeScale"
import { useFilterContext } from "../../../hooks/context/FilterContext"
import { Field, FieldSet } from "../../controls/form/Form"

export function DateRangeEditor(props: any) {
  return (
    <FieldSet>
      <Field>
        <label>Years</label>
        <DateRangeMenu codes={["YTD", "LY", "TY", "NY", "L12"]} />
      </Field>
      <Field>
        <label>Quarters</label>
        <DateRangeMenu codes={["QTD", "LQ", "TQ", "NQ", "L3M"]} />
      </Field>
      <Field>
        <label>Months</label>
        <DateRangeMenu codes={["MTD", "LM", "TM", "NM", "L1M"]} />
      </Field>
    </FieldSet>
  )
}

export function DateRangeMenu(props: { codes: DateRangeCode[] }) {
  const codes = props.codes ?? ["YTD"]

  return (
    <menu className="segment">
      {codes.map(code => <ButtonDateRange code={code} key={code} />)}
    </menu>
  )
}

export function ButtonDateRange({ code, label }: { code: DateRangeCode, label?: string }) {
  const { filterStack, dateRangeCode } = useFilterContext()
  const className = (code === dateRangeCode ? "active" : undefined)
  const buttonLabel = label || formatDateRangeCode(code)

  return (
    <button className={className} onClick={onChangeRange} title={DateRangeCodes[code]}>
      <abbr>{code}</abbr>
      <label>{buttonLabel}</label>
    </button>
  )

  function onChangeRange() {
    filterStack.push({ dateRangeCode: code })
  }
}
