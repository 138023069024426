import { AccountItem } from "../../../core/types/AccountItem"
import { Item } from "../../../core/types/Item"
import { Logger } from "../../../core/utils/Logger"
import { useFormData, useFormItem } from "../../../hooks/context/FormContext"
import { useModel } from "../../../hooks/context/ModelContext"
import { Card, CardSection } from "../../controls/Card"
import { FormDataJson } from "../../forms/FormDataJson"
import { useWizard } from "../context/WizardContext"
import { WizardHeader } from "../WizardHeader"
import { WizardMenuListItem } from "../WizardMenu"
import { AccountWizardData } from "./AccountWizardData"

const logger = new Logger("wizards.SelectAccountType")

export function SelectAccountType() {
  const { model } = useModel()
  const { wizard, primaryItemProp } = useWizard()
  const { formData } = useFormData<AccountWizardData>()
  const [ accountItem, setAccountItem ] = useFormItem(primaryItemProp)

  const accountTypes = model.toItemArray(formData.allowedAccountTypeKeys)

  logger.debug("Rendering: allowedAccountTypes=%o", accountTypes)

  const title = `Select an Account Type`
  const subtitle = `Please select one of the following account types for your new account`

  return (
    <Card id="SelectAccountType" className="wizard-page">
      <WizardHeader title={title} subtitle={subtitle} />
      <CardSection className="wizard-menu">
        { accountTypes.map(item =>
          <WizardMenuListItem 
            icon={model.getItemIcon(item)}
            title={model.getItemName(item)}
            subtitle={model.getItemDescription(item)}
            active={item.key === accountItem?.typeKey}
            onClick={(e:any) => onSelectAccountType(e, item)}
            key={item.key} />
        )}
      </CardSection>
      <FormDataJson/>
    </Card>
  )

  function onSelectAccountType(e:any, accountType:Item) {
    // If changing type then always delete and start fresh
    if (accountType.key !== accountItem?.typeKey) {
      const newAccountItem = model.newItem<AccountItem>("&!$@", accountType.key)
      setAccountItem(newAccountItem)
    }

    // Next page
    wizard.next()
  }
}
