import { Link } from "react-router-dom"
import { Model } from "../../core/model/Model"
import { ModelKeys } from "../../core/model/ModelKeys"
import { Item } from "../../core/types/Item"
import { useModel } from "../../hooks/context/ModelContext"

// const logger = new Logger("views.ViewLinks")

interface LinkProps {
  item?: Item
  className?: string
  children?: any
}

interface CardLinkProps extends LinkProps {
  icon?: string
  style?: any
  hideIcon?: boolean
  hideName?: boolean
  onClick?: (e:any) => void
}

export function DashboardLink(props:LinkProps) {
  const { model } = useModel()
  const item = props.item || Model.root
  const children = props.children
  const className = props.className || ""

  if (!model.hasChildren(item.key)) {
    return <CardLink item={item} />
  }

  return (
    <Link to={{ pathname: "/dashboard/" + item.key }} className={className}>
      {children ? children : model.getItemName(item.key)}
    </Link>
  )
}

export function useCardLink(item:Item) {
  const { model } = useModel()
  
  if (model.hasAncestor(item, ModelKeys.bank.root)) {
    return `/bank/${item.key}`
  }
  if (model.hasChildDeep(item.typeKey, ModelKeys.file.root, true)) {
    return `/file/${item.key}`
  }
  if (model.isSetting(item)) {
    return`/settings/${item.key}`
  }

  return `/card/${item.key}`
}
 
export function CardLink(props:CardLinkProps) {
  const { model } = useModel()
  const item = props.item || Model.root
  const icon = props.icon || model.getItemIcon(item)
  const { children, className, style, hideIcon, hideName, onClick } = props
  const pathname = useCardLink(item)

  if (!item) {
    return <>{children}</>
  }
  
  return (
    <Link to={{ pathname }} className={className || ""} style={style} onClick={onClickLink}>
      { icon && !hideIcon && <i className={icon} /> }
      { children ? children : (hideName ? "" : model.getItemName(item.key))}
    </Link>
  )

  function onClickLink(e:any) {
    e.stopPropagation()
    onClick && onClick(e)
  }
}

export function CardLinkParent(props:CardLinkProps) {
  const { model } = useModel()
  const { item, children, className, hideIcon, hideName, style } = props
  
  if (item === undefined || item.parentKey === "") {
    return <Link to="/">{children}</Link>
  }

  const parentItem = model.getItem(item.parentKey)

  return (
      <CardLink item={parentItem} 
                    icon={"far fa-angle-up " + (className || "ms-1")}
                    hideIcon={hideIcon}
                    hideName={hideName}
                    style={style}>
        {children}
      </CardLink>
  )
}

export function ItemLink(props:LinkProps) {
  const { model } = useModel()
  const item = props.item || Model.root
  const children = props.children
  const className = props.className || ""

  if (!item) {
    return <>{children}</>
  }
  return (
    <Link to={{ pathname: "/item/" + item.key }} className={className}>
      {children ? children : model.getItemName(item.key)}
    </Link>
  )
}

export function TableLink(props:LinkProps) {
  const { model } = useModel()
  const item = props.item || Model.root
  const children = props.children
  const className = props.className || ""

  if (!item) {
    return <>{children}</>
  }
  return (
    <Link to={{ pathname: "/table/" + item.key }} className={className}>
      {children ? children : model.getItemName(item.key)}
    </Link>
  )
}

// export function CardLink(props:{
//   item: Item
//   card: string
//   className?: string
//   title?: string
//   icon?: string
//   hideIcon?: boolean
// }) {
//   const { model } = useModel()
//   const { item, card, className, hideIcon } = props
//   const title = props.title || model.getItemName(item)
//   const icon = props.icon || model.getItemIcon(item)

//   // logger.debug("CardLink: state=%o", state)

//   return (
//     <Link to={`/card/${card}/${item.key}`} className={className}>
//       { icon && !hideIcon && <i className={icon}/> }
//       { title }
//     </Link>
//   )
// }
