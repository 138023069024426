import { ModelKeys } from "../../../core/model/ModelKeys"
import { Logger } from "../../../core/utils/Logger"
import { TimeScale } from '../../../core/utils/TimeScale'
import { FilterProvider } from '../../../hooks/context/FilterContext'
import { useInvestmentModel } from '../../../hooks/context/InvestmentModelContext'
import { CashflowCard } from '../cashflow/CashflowCard'
import { TransactionItemsCard } from '../transaction/TransactionItemsCard'
import { AssetChartCard } from './AssetChartCard'
import { AssetPerformanceCard, getAssetFilter } from './AssetPerformanceCard'
import { ValuationEditorCard } from './ValuationEditorCard'

const logger = new Logger("ValuationSection")

export function ValuationSection(props: any) {
  const { name, item, isUnitised } = useInvestmentModel()
  const scale:TimeScale = { start:0, end:Date.now(), units:"1D" }

  logger.debug("Rendering: name=%s item=%o, isUnitised=%s", name, item, isUnitised)

  return (
    <FilterProvider name={logger.name} initFilter={getAssetFilter}>
      <AssetChartCard open />
      <CashflowCard />

      <FilterProvider name={logger.name + ".Performance"} initFilter={{scale}}>
        <AssetPerformanceCard />
      </FilterProvider>

      <FilterProvider name={logger.name + ".ValuationEditor"} initFilter={getAssetFilter}>
        <ValuationEditorCard title={isUnitised ? "Unit Prices" : "Valuations"} />
      </FilterProvider>

      { isUnitised &&
        <TransactionItemsCard title="Budget Transactions" tree menuRootKey={ ModelKeys.category.finance } />
      }
    </FilterProvider>
  )
}

