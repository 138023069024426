import { Logger } from "../../../core/utils/Logger"
import { CashflowSearchAtoms } from "../../../hooks/cashflow/CashflowSearchAtoms"
import { useCashflowSearch } from "../../../hooks/cashflow/useCashflowSearch"
import { useFilterContext } from "../../../hooks/context/FilterContext"
import { useInvestmentModel } from "../../../hooks/context/InvestmentModelContext"
import { usePivotLayout } from '../../../hooks/pivot/usePivotLayout'
import { useCardOpen } from "../../../hooks/useCardOpen"
import { Card, CardSection } from "../../controls/Card"
import { SearchBox } from "../../search/SearchBox"
import { CardHeaderWithMenu, defaultToolbarProps } from "../CardHeaderWithMenu"
import { ButtonRaw } from "../filter/DimensionMenu"
import { CashflowPivotTable } from "./CashflowPivotTable"
import { CashflowPivotTreeTable } from "./CashflowPivotTreeTable"
import { CashflowTable } from "./CashflowTable"

const logger = new Logger("CashflowCard")

interface CashflowCardProps {
  className?: string
  title?: string
  open?: boolean
}

export function CashflowCard(props:CashflowCardProps) {
  const { className, title, open } = props
  const { name, item } = useInvestmentModel()
  const { filter } = useFilterContext()
  const { isTreeLayout } = usePivotLayout()
  const { isOpen } = useCardOpen(logger.name, item.key, open === true)

  logger.setContext(name).debug("Rendering: card=%s, filter=%s", logger.name, filter.id)

  const toolbarProps = filter.raw ? { showChildren:true, showFilter:true, showBalance:true } : defaultToolbarProps

  return (
    <Card id={logger.name} className={className} open={isOpen}>
      <CardHeaderWithMenu cardName={logger.name} 
                          title={title || "Cashflow Table"} 
                          icon="fal fa-table" 
                          {...toolbarProps}>
        { filter.raw && <CashflowSearchBox /> }
        {!filter.raw && <ButtonRaw /> }
      </CardHeaderWithMenu>
      <CardSection>
        { filter.raw ? <CashflowTable /> : 
          isTreeLayout ? <CashflowPivotTreeTable/> : <CashflowPivotTable /> 
        }
      </CardSection>
    </Card>
  )
}

export function CashflowSearchBox(props:any) {
  useCashflowSearch()
  
  return <SearchBox searchValueAtom={CashflowSearchAtoms.searchValue} {...props}/>
}
