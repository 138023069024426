/**
 * Collects counter data
 */
export class Counter {
  public name: string
  public count: number = 0
  public total: number = 0
  public average: number = 0
  public min: number = Number.MAX_SAFE_INTEGER
  public max: number = Number.MIN_SAFE_INTEGER

  constructor(name:string) {
    this.name = name
  }

  set(value:number) {
    this.count++
    this.total += value
    this.average = this.total / this.count
    this.min = Math.min(value, this.min)
    this.max = Math.max(value, this.max)

    return this
  }

  get key() {
    return this.name
  }
}

const counters = new Map<string,Counter>()

export class Counters {
  public static get(name:string) : Counter {
    let counter = counters.get(name)
    if (!counter) {
      counter = new Counter(name)
      counters.set(name, counter)
    }
    return counter
  }

  public static set(name:string, method:string | undefined, context:string | undefined, value:number) {
    let key = name
    this.get(key).set(value)

    if (method) {
      key += ("." + method)
      this.get(key).set(value)
    }
    if (context) {
      key += ("::" + context)
      this.get(key).set(value)
    }
  }

  public static clear() {
    counters.clear()
  }

  public static get list() {
    return Array.from(counters.values())
  }

  public static sortByName(counters:Counter[]) {
    return counters.sort((t1,t2) => t1.name.localeCompare(t2.name))
  }

  public static sortByNameDesc(counters:Counter[]) {
    return counters.sort((t1,t2) => t2.name.localeCompare(t1.name))
  }

  public static sortByCount(counters:Counter[]) {
    return counters.sort((t1,t2) => t1.count - t2.count)
  }

  public static sortByCountDesc(counters:Counter[]) {
    return counters.sort((t1,t2) => t2.count - t1.count)
  }

  public static sortByAverage(counters:Counter[]) {
    return counters.sort((t1,t2) => t1.average - t2.average)
  }

  public static sortByAverageDesc(counters:Counter[]) {
    return counters.sort((t1,t2) => t2.average - t1.average)
  }

  public static sortByTotal(counters:Counter[]) {
    return counters.sort((t1,t2) => t1.total - t2.total)
  }

  public static sortByTotalDesc(counters:Counter[]) {
    return counters.sort((t1,t2) => t2.total - t1.total)
  }

  public static sortByMin(counters:Counter[]) {
    return counters.sort((t1,t2) => t1.min - t2.min)
  }

  public static sortByMinDesc(counters:Counter[]) {
    return counters.sort((t1,t2) => t2.min - t1.min)
  }

  public static sortByMax(counters:Counter[]) {
    return counters.sort((t1,t2) => t1.max - t2.max)
  }

  public static sortByMaxDesc(counters:Counter[]) {
    return counters.sort((t1,t2) => t2.max - t1.max)
  }
}
