import { Logger } from '../../../core/utils/Logger'
import { usePivotTables } from '../../../hooks/pivot/usePivotTables'
import { ColorPalette } from '../useChartProfile'

const logger = new Logger("charts.budget.useBudgetActualChartData")

export function useBudgetActualChartData() {
  const { name, budgetPivot, netPivot } = usePivotTables()

  logger.setContext(name).start("Calculate", "Creating chartData")

  // Color indexes for bars
  const colors = new ColorPalette()
  const chartData: ChartDP[] = []

  for (const row of netPivot.rows) {
    if (!row.openingRow && row.endDate > 0) {
      chartData.push({
        key: row.key,
        date: row.endDate,
        color: colors.get(row.total),
        Actual: row.total,
        Budget: 0,
        Balance: row.balance,
        CumTotal: row.cumTotal,
      })
    }
  }

  for (const row of budgetPivot.rows) {
    if (row.endDate > 0) {
      const dp = chartData.find(dp => dp.date === row.endDate)
      if (dp) {
        dp.Budget = row.total
      }
    }
  }

  // Compute rolling average
  for (let i=0; i < chartData.length; i++) {
    let n = 1
    let total = chartData[i].Actual
    if (i > 0) {
      total += chartData[i-1].Actual
      n++
    }
    if (i > 1) {
      total += chartData[i-2].Actual
      n++
    }
    chartData[i].Average = total/n
  }


  logger.finish("Calculate", "Return %d data points", chartData.length)

  return { chartData }
}

interface ChartDP {
  key: string | number
  date: number
  color: string
  Actual: number
  Budget: number
  Balance: number
  CumTotal: number
  Average?: number
}
