import { formatDate } from "../../core/utils/DateFormat"
import { Logger } from "../../core/utils/Logger"
import { TimeScale } from "../../core/utils/TimeScale"
import { usePivotContent } from "../../hooks/pivot/usePivotContent"
import { usePivotTables } from "../../hooks/pivot/usePivotTables"
import { DateRange } from "../controls/editor/DateRange"

const logger = new Logger("charts.ChartHeader")

export function ChartHeader({ title, scale } : { title?:string, scale?:TimeScale }) {
  const { model, filter, pivotTable } = usePivotTables()
  const { isBalanceContent } = usePivotContent()

  const start = filter.scale.start || scale?.start || pivotTable.scale.start
  const end = filter.scale.end || scale?.end || pivotTable.scale.end

  logger.debug("Rendering: range={%s}", Logger.Range(start, end))

  return (
    <h3>
      <>{ title }&nbsp;</>
      {filter.accountItem &&
        <span>{model.getItemName(filter.accountItem)}</span>}
      {filter.columnItem &&
        <span>{model.getItemName(filter.columnItem)}</span>}
      <>&nbsp;</>
      { isBalanceContent
        ? <>{ formatDate(scale?.end  || pivotTable.scale.end) }</>
        : <DateRange start={start} end={end} />
      }
    </h3>
  )
}
