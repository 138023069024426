import { TimeUnits, formatUnits } from "../../../core/utils/TimeScale"
import { useFilterContext } from "../../../hooks/context/FilterContext"
import { FilterStack } from "../../../hooks/filter/FilterStack"
import { Menu } from "../../controls/menu/Menu"

export function IntervalMenu(props: { buttons?: TimeUnits[], segmented?: boolean }) {
  const { filterStack, filter } = useFilterContext()

  const buttons = props.buttons || [ "1Y", "1M", "3M", "6M", "1W", "1D" ]

  if (props.segmented) return (
    <menu className="segment">
      { buttons.map(units => 
        <ButtonInterval units={units} 
                        active={units === filter.scale.units} 
                        filterStack={filterStack} 
                        key={units} />
)}
    </menu>
  )

  return (
    <Menu buttonImage="fal fa-calendar-range" label={formatUnits(filter.scale.units)}>
      { buttons.map(units => 
        <ButtonInterval units={units} 
                        active={units === filter.scale.units} 
                        filterStack={filterStack} 
                        key={units} />
      )}
    </Menu>
  )
}

function ButtonInterval({ units, active, filterStack }: { 
  units: TimeUnits,
  active: boolean,
  filterStack: FilterStack
}) {
  const className = (active ? "active" : "")

  return (
    <button className={className} onClick={onClick} title={formatUnits(units) + " interval"}>
      <abbr>{units}</abbr>
      {/* <label>{formatUnits(units)}</label> */}
    </button>
  )

  function onClick() {
    filterStack.push({ interval: units })
  }
}
