import { Logger } from "../../core/utils/Logger"
import { CartesianChartProps } from "./CartesianChart"
import { AssetEarningsAreaChart } from "./asset/AssetEarningsAreaChart"
import { AssetPriceChart } from "./asset/AssetPriceChart"
import { BudgetActualRadialCharts } from "./budget/BudgetActualRadialCharts"
import { BudgetActualWaterfallChart } from "./budget/BudgetActualWaterfallChart"
import { AccountBalanceAreaChart } from "./cashflow/AccountBalanceAreaChart"
import { CashflowBarChart2 } from "./cashflow/CashflowBarChart2"
import { CashflowPieChart } from "./cashflow/CashflowPieChart"
import { CashflowStackedBarChart } from "./cashflow/CashflowStackedBarChart"
import { CashflowTreemapChart } from "./cashflow/CashflowTreemapChart"
import { CashflowWaterfallChart } from "./cashflow/CashflowWaterfallChart"
import { CompareWaterfallChart } from "./cashflow/CompareWaterfallChart"
import { useChartType } from "./useChartType"

const logger = new Logger("charts.ChartSelector")

export function ChartSelector(props:Partial<CartesianChartProps>) {
  const { chartType } = useChartType()

  logger.debug("Rendering: chartType=%s", chartType)

  switch (chartType) {
    case "CashflowPie":
      return <CashflowPieChart {...props} />
    case "CashflowBar":
      return <CashflowBarChart2 {...props}/>
    case "CashflowWaterfall":
      return <CashflowWaterfallChart {...props} noSort />
    case "CashflowTreemap":
      return <CashflowTreemapChart {...props} />
    case "BudgetActualWaterfall":
      return <BudgetActualWaterfallChart {...props} />
    case "BudgetActualRadial":
      return <BudgetActualRadialCharts {...props} />
    case "CompareWaterfall":
      return <CompareWaterfallChart {...props} />
    case "CashflowStackedBar":
      return <CashflowStackedBarChart {...props} />
    case "AccountBalanceAreaChart":
      return <AccountBalanceAreaChart {...props} />
    case "AssetPriceChart":
      return <AssetPriceChart {...props} />
    case "AssetEarningsAreaChart":
      return <AssetEarningsAreaChart {...props} />
    }
}
