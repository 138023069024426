import { Area } from "recharts"
import { Logger } from "../../../core/utils/Logger"
import { NumberFormats, formatCurrency } from '../../../core/utils/Numbers'
import { useFilterContext } from "../../../hooks/context/FilterContext"
import { useInvestmentModel } from "../../../hooks/context/InvestmentModelContext"
import { CartesianChart, CartesianChartProps } from "../CartesianChart"
import { useChartProfile } from "../useChartProfile"
import { useGradient } from "../useGradient"
import { useAssetPriceData } from "./useAssetPriceData"

export const logger = new Logger("charts.AssetPriceChart")
 
export function AssetPriceChart(props:Partial<CartesianChartProps>) {
  const { name, isUnitised } = useInvestmentModel()
  const { scale } = useFilterContext()
  const { animationDuration } = useChartProfile()
  const { noHeader } = props

  const priceData = useAssetPriceData()
  const { linearGradient, gradientUrl, trendColor } = useGradient(priceData, "Price")

  logger.setContext(name).debug("Rendering %d prices, %s", priceData.length, Logger.Range(scale.start, scale.end))

  if (priceData.length < 2) {
    return <></>
  }
  
  return (
    <div id="AssetPriceChart" className="chart">
      { !noHeader &&
        <h3>{ isUnitised ? "Unit" : "Asset"} Prices</h3>
      }
      <CartesianChart {...props} data={priceData} units="1M" noLegend
                    formatYAxis={isUnitised ? (value:any) => formatCurrency(value, NumberFormats.currency2) : undefined}
      >
        <defs>{ linearGradient }</defs>
        <Area dataKey="Price" type="monotone"
              stroke={trendColor} strokeWidth={2} fill={gradientUrl} animationDuration={animationDuration} />
        {/* <Line type="monotone" dataKey="Price" stroke={trendColor} strokeWidth={2} dot={false} /> */}
      </CartesianChart>
    </div>
  )
}

