import { getApp } from "firebase/app"
import { Navigate, useParams } from "react-router-dom"
import { ModelKeys } from "../../core/model/ModelKeys"
import { Logger } from "../../core/utils/Logger"
import { FilterProvider } from "../../hooks/context/FilterContext"
import { InvestmentModelProvider } from "../../hooks/context/InvestmentModelContext"
import { useModel } from "../../hooks/context/ModelContext"
import { ViewContextProvider } from "../../hooks/context/ViewContext"
import { DashboardCard, getDashboardScale } from "../cards/dashboard/DashboardCard"
import { JemstoneHeader } from "../layouts/JemstoneHeader"
import { JemstoneSidebar } from "../layouts/JemstoneSidebar"
import { ViewMenu } from "./ViewMenu"

const logger = new Logger("view.DashboardView")

export function DashboardView(props: any) {
  const { model } = useModel()
  const { key } = useParams<any>()
  const rootKey = key || ModelKeys.root

  const portfolios = model.childrenOfType(rootKey, ModelKeys.asset.portfolio)
  if (portfolios.length === 0) {
    return <Navigate to="/table"/>
  }

  model.sortItems(portfolios)

  logger.debug("Rendering: key='%s', %d portfolios", rootKey, portfolios.length)
  logger.trace("Firebase configuration=%o", getApp()?.options)

  return (
    <ViewContextProvider>
      <JemstoneHeader>
        <ViewMenu rootKey={rootKey} showTheme showDocs showFiles showWizard showUser/>
      </JemstoneHeader>

      <JemstoneSidebar/>
      
      <main>
        <div id="DashboardView" className="grid">
          { portfolios.map(portfolio =>
            <InvestmentModelProvider item={portfolio} key={portfolio.key}>
              <FilterProvider name="DashboardCard" initFilter={getDashboardScale}>
                <DashboardCard item={portfolio} />
              </FilterProvider>
            </InvestmentModelProvider>
          )}
        </div>
      </main>
    </ViewContextProvider>
  )
}
