import { Item } from "../../../core/types/Item"

export function ButtonBack<T extends Item>({ item, onBack }:{
  item: T
  onBack: (e:any, item:T) => void
}) {
  return (
    <button onClick={(e:any) => onBack(e, item)}>
      <i className="far fa-arrow-left" />
    </button>
  )
}