import { Item } from '../../../core/types/Item'
import { Logger } from "../../../core/utils/Logger"
import { Card, CardHeader } from "../../controls/Card"
import { BuildingItemEditor } from './BuildingItemEditor'
import { PropertyItemEditor } from "./PropertyItemEditor"

const logger = new Logger("cards.PropertyDetailsCard")

interface PropertyDetailsCardProps {
  item: Item
  asset?: Item
}

export function PropertyDetailsCard(props: PropertyDetailsCardProps) {
  const { asset, item } = props

  logger.debug("Rendering: asset=%o, item=%o", asset, item);
  
  // Render a Card view
  return (
    <Card id="PropertyDetailsCard">
      <CardHeader>
        <i className="fal fa-map-marker-alt" />
        <h2>Property Description</h2>
      </CardHeader>
      <section>
        <h3>Land Details</h3>
        <PropertyItemEditor asset={asset} />
      </section>
      <section>
        <h3>Building Details</h3>
        <BuildingItemEditor item={item} />
      </section>
    </Card>
  )
}
