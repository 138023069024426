import { atom, useAtomValue } from 'jotai'
import { ModelKeys } from "../../../core/model/ModelKeys"
import { Item } from '../../../core/types/Item'
import { Logger } from "../../../core/utils/Logger"
import { useModel } from "../../../hooks/context/ModelContext"
import { useViewContext, useViewItemState } from "../../../hooks/context/ViewContext"
import { ShowIfDebug } from '../../controls/ShowIf'
import { ButtonOpen } from '../../controls/buttons/ButtonOpen'
import { ModelRow } from './ModelRow'

const logger = new Logger("table.ModelTable")

const openAtom = atom(true)

export function ModelTable(props:any) {
  const { model } = useModel()
  const isOpen = useAtomValue(openAtom)

  const rootKey = props.rootKey || ModelKeys.root
  const rootItem = model.getItem(rootKey)
  
  logger.debug("Rendering: rootKey='%s', code='%s'", rootKey, rootItem?.code)

  return (
    <table id="ModelTable" className="table sticky-headers">
      <thead>
        <tr>
          <th className="rowmenu">
            <ButtonSelectEnable/>
          </th>

          <th className="name">Name</th>
          <th className="description">Description</th>
          <th className="code">Code</th>
          <th className="type">Type</th>

          <th className="date">Date</th>
          <th className="repeat">Repeat</th>
          <th className="value">Value</th>

          <ShowIfDebug>
            <th className="key">Key</th>
            <th className="sort">Sort</th>
          </ShowIfDebug>

          <th className="rowmenu">
            <ButtonOpen openAtom={openAtom}/>
          </th>
        </tr>
      </thead>

      { isOpen &&
        <tbody>
          <ChildRows parentKey={rootKey} level={0} />
        </tbody>
      }
    </table>
  )
}

function ChildRows({parentKey, level} : { parentKey:string, level:number }) {
  const { model } = useModel()
  const items = model.childrenSorted(parentKey)

  logger.trace("ChildRows: Rendering parentItem='%s', %d children", 
                model.getItemName(parentKey), items.length)

  return (
    <>
      { items.map(item => 
        <Row item={item} level={level} key={item.key} />
      )}
    </>
  )
}

function Row({item,level} : {item:Item, level:number}) {
  const { model } = useModel()
  const viewItemState = useViewItemState(item.key)

  logger.trace("Row: Rendering key=%s, code=%s, name=%s, level=%d", item.key, item.code, item.name, level)

  return (
    <>
      { viewItemState.visible &&
        <ModelRow item={item} level={level} />
      }
      { (viewItemState.openTree && model.hasChildren(item.key)) &&
        <ChildRows parentKey={item.key} level={level+1} />
      }
    </>
  )
}

export function ButtonSelectEnable(props:any) {
  const { viewState } = useViewContext()
  const className = viewState.showSelectCheckbox ? "fal fa-check-circle" : "fal fa-circle"

  return (
    <button onClick={toggleSelectCheckbox}>
      <i className={className} />
    </button>
  )

  function toggleSelectCheckbox() {
    viewState.showSelectCheckbox = !viewState.showSelectCheckbox
    viewState.clearSelected()
    viewState.onEvent({name:"toggleSelectCheckbox", value:viewState.showSelectCheckbox})
  }
}
