import { useAtomValue } from "jotai"
import { useFormData } from "../../hooks/context/FormContext"
import { JsonTable } from "../cards/settings/ItemDetailsJson"
import { showJsonAtom } from "../controls/buttons/ButtonShowJson"
import { ShowIf } from "../controls/ShowIf"

export function FormDataJson() {
  const showJson = useAtomValue(showJsonAtom)
  const { getFormItems } = useFormData()

  return (
    <ShowIf visible={showJson}>
      <div className="json">
        <JsonTable items={getFormItems()} />
      </div>
    </ShowIf>
  )
}
