import { Item, setItemPropNames } from "./Item"

/**
 * BankStmtFileItem represents an OFX transaction that has been imported into the model
 */
export interface BankStmtFileItem extends Item {
  /** Bank account key that the bank stmt is for */
  accountKey: string

  /** Bank account number that OFX file downloaded from */
  accountNumber: string

  /** Date of first transaction in the file */
  startDate: number

  /** Date of last transaction in the file */
  endDate: number

  /** Opening balance amount */
  openingBalance?: number

  /** Closing balance amount */
  closingBalance?: number

  /** Opening unit balance amount (for unitised accounts only) */
  openingUnitBalance?: number

  /** Closing unit balance amount (for unitised accounts only) */
  closingUnitBalance?: number
}

type BankStmtFileItemProp = keyof BankStmtFileItem

type BankStmtFileItemRecord = Record<BankStmtFileItemProp, BankStmtFileItemProp>

export const BankStmtFileItemProps:BankStmtFileItemRecord = {
  key: "key",
  code: "code",
  name: "name",
  description: "description",
  parentKey: "parentKey",
  typeKey: "typeKey",
  links: "links",
  sortOrder: "sortOrder",
  status: "status",
  modifiedDate: "modifiedDate",
  accountKey: "accountKey",
  accountNumber: "accountNumber",
  startDate: "startDate",
  endDate: "endDate",
  openingBalance: "openingBalance",
  closingBalance: "closingBalance",
  openingUnitBalance: "openingUnitBalance",
  closingUnitBalance: "closingUnitBalance",
}

setItemPropNames(BankStmtFileItemProps)

export const BankStmtFileItemPropNames = Object.keys(BankStmtFileItemProps) as BankStmtFileItemProp[]

