import { ValuationModel } from '../../../core/invest/ValuationModel'
import { Logger } from "../../../core/utils/Logger"
import { useFilterContext } from "../../../hooks/context/FilterContext"
import { useInvestmentModel } from '../../../hooks/context/InvestmentModelContext'
import { usePivotContent } from '../../../hooks/pivot/usePivotContent'
import { useCardOpen } from "../../../hooks/useCardOpen"
import { ChartSelector } from "../../charts/ChartSelector"
import { CashflowPieChart } from '../../charts/cashflow/CashflowPieChart'
import { useChartProfile } from "../../charts/useChartProfile"
import { useChartType } from '../../charts/useChartType'
import { Card, CardSection } from '../../controls/Card'
import { CardHeaderWithMenu, defaultToolbarChartProps } from '../CardHeaderWithMenu'
import { DurationMenu } from '../filter/DurationMenu'

const logger = new Logger("ValuationChartCard")

export function ValuationChartCard(props:{ title?:string, open?:boolean }) {
  const { title, open } = props
  const { name, asset } = useInvestmentModel<ValuationModel>()
  const { chartType } = useChartType("AssetEarningsAreaChart")
  const { pivotContent } = usePivotContent("Balance")
  const { filter } = useFilterContext(/*getAssetFilter*/)
  const { size } = useChartProfile()
  const { isOpen } = useCardOpen(logger.name, asset.key, open === true)

  logger.setContext(name)
        .debug("Rendering: asset=%s, filter=%s, chartType=%s, pivotContent=%s", 
                asset?.name, filter.id, chartType, pivotContent)

  return (
    <Card id={logger.name} open={isOpen}>
      <CardHeaderWithMenu cardName={logger.name} title={title} icon="fal fa-chart-line-up" {...defaultToolbarChartProps} showBalance />
      { isOpen &&
        <div className="duration-menu">
          <DurationMenu units={["1Y", "2Y", "3Y", "5Y", "10Y", "20Y", "30Y" ]} />
        </div>
      }
      <CardSection>
        <ChartSelector height={size.height} noLegend />
        <CashflowPieChart height={size.height} noSort />
      </CardSection>
    </Card>
  )
}
