import { useParams } from "react-router-dom"
import { ModelKeys } from "../../core/model/ModelKeys"
import { Logger } from "../../core/utils/Logger"
import { ViewContextProvider } from "../../hooks/context/ViewContext"
import { SettingsSection } from "../cards/settings/SettingsSection"
import { DiagnosticSection } from "../cards/settings/diagnostics/DiagnosticSection"
import { JemstoneSidebar } from "../layouts/JemstoneSidebar"
import { ViewHeader } from "./ViewHeader"
import { ViewMenu } from "./ViewMenu"

const logger = new Logger("view.SettingsView")

export function SettingsView(props:any) {
  const { key } = useParams<any>()

  const rootKey = key || ModelKeys.root
  
  logger.debug("Rendering: rootKey=%s", rootKey)
  
  return (
    <ViewContextProvider>
      <ViewHeader rootKey={rootKey}>
        <ViewMenu rootKey={rootKey} 
                  showClipboard showDebug showReadonly showUser />
      </ViewHeader>
      
      <JemstoneSidebar/>
      
      <main>
        <div id="SettingsView" className="grid">
          { (rootKey === ModelKeys.diagnostics)
            ? <DiagnosticSection />
            : <SettingsSection rootKey={rootKey} />
          }
        </div>
      </main>
    </ViewContextProvider>
  )
}
