import { useState } from "react"
import { useDropzone } from "react-dropzone"
import { loadBankStmtFile } from "../../../core/builder/bankstmt/LoadBankStmtFile"
import { Logger } from "../../../core/utils/Logger"
import { NumberFormats, formatNumber } from '../../../core/utils/Numbers'
import { useBankStmtModel } from "../../../hooks/bankstmt/useBankStmtModel"
import { useModel } from "../../../hooks/context/ModelContext"
import { AppInfo } from "../../../VersionInfo"
import { Card, CardHeader, CardSection } from "../../controls/Card"

const logger = new Logger("cards.BankStmtFileUploadCard")

let droppedFiles:File[] = []

export function BankStmtFileUploadCard({ noHeader }: { noHeader?:boolean}) {
  const { model } = useModel()
  const { getRootProps, getInputProps } = useDropzone({multiple:true, onDrop:onDrop})

  const [, setFiles] = useState<File[]>(droppedFiles)
  const [text] = useState<string>()
  
  const bankStmtModel = useBankStmtModel()

  return (
    <Card id="BankStmtFileUploadCard">
      { !noHeader &&
        <CardHeader>
          <h2>
            <i className="fal fa-file-code" />
            Upload Bank Statement Files
          </h2>
          <menu/>
        </CardHeader>
      }
      <CardSection>
        <div className='dropzone' {...getRootProps()}>
          <input {...getInputProps()} />
          <i className="fal fa-upload" />
          <abbr>Click to select files...</abbr>
          <label>Drag 'n' drop some files here, or click to select files</label>
        </div>

        { (droppedFiles.length > 0) &&
          <p>
            You have selected the following {droppedFiles.length} files for loading 
            into {AppInfo.name}. Please Click the <em>Import</em> button to complete the
            load process for each file.
          </p>
        }

        <table className="table">
          <tbody>
            { droppedFiles.map((file) => 
              <tr key={file.name}>
                <td><i className="fal fa-file-code"/></td>
                <td>{file.name}</td>
                <td>
                  { formatNumber(file.size/1000, NumberFormats.integer) } KB
                </td>
                <td>
                  <menu>
                    <button onClick={() => onImport(file)} title="Import">
                      <i className="fal fa-file-import" />
                      <label>Import</label>
                    </button>
                    <button onClick={() => onDelete(file)} title="Remove">
                      <i className="fal fa-times" />
                      <label>Remove</label>
                    </button>
                  </menu>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        { (text && text.length > 0) &&
          <div className="mt-3">
            <h3>File Source</h3>
            <pre>{text}</pre>
          </div>
        }
      </CardSection>
    </Card>
  )

  function onDrop(acceptedFiles:File[]) {
    logger.debug("onDrop: files=%o", acceptedFiles)
    const newFiles = Array.from(droppedFiles)

    for (const acceptedFile of acceptedFiles) {
      const index = newFiles.findIndex((file:any) => file.name === acceptedFile.name)
      if (index === -1) {
        newFiles.push(acceptedFile)
      }
    }

    newFiles.sort((f1,f2) => f1.name.localeCompare(f2.name))
    setFiles(droppedFiles = newFiles)
  }

  function onDelete(deleteFile:File) {
    const index = droppedFiles.findIndex((file:any) => file.name === deleteFile.name)
    if (index !== -1) {
      const newFiles = Array.from(droppedFiles)
      newFiles.splice(index, 1)
      setFiles(droppedFiles = newFiles)
    }
  }

  function onImport(file:File) {
    const reader = new FileReader()

    reader.onload = (e:any) => {
      const results = loadBankStmtFile(model, file.name, e.target.result)
      if (results) {
        const { statements, transactions } = results
        if (transactions && transactions.length > 0) {
          bankStmtModel.saveStatements(statements, transactions)
        }
      }
    }

    // Read file contents
    reader.readAsText(file)

    // Remove from list of files to be loaded
    onDelete(file)
  }
}
