import { useAtomValue } from "jotai"
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary"
import { Outlet } from "react-router-dom"
import { GridLoader } from "react-spinners"
import { ModelAtoms } from "../../core/atom/ModelAtoms"
import { isModelLoading, setModelErrorHandler } from "../../core/service/ModelService"
import { Logger } from "../../core/utils/Logger"
import { useModel } from "../../hooks/context/ModelContext"
import { useFilesModel } from "../../hooks/useFilesModel"
import { useTheme } from "../../hooks/useTheme"
import { AppInfo } from "../../VersionInfo"
import { useChartProfile } from "../charts/useChartProfile"
import { ItemDialog } from "../controls/dialog/ItemDialog"
import { ErrorFallbackView } from "../views/ErrorFallbackView"
import { JemstoneHeader } from "./JemstoneHeader"

const logger = new Logger("layout.JemstoneLayout")

export function JemstoneLayout() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackView}>
      <ShowView/>
    </ErrorBoundary>
  )
}

function ShowView() {
  const { model } = useModel()
  const { theme } = useTheme()
  const { showBoundary } = useErrorBoundary()

  logger.debug("Rendering: %d items in model, isModelLoading=%s, theme=%s", 
                model.size(), isModelLoading(), theme.code)

  setModelErrorHandler(showBoundary)

  return ( 
    isModelLoading() ? <LoadingModel/> : 
    <>
      <Outlet /> 
      <ItemDialog/>
    </>
  )
}

function LoadingModel() {
  const { colors } = useChartProfile()
  return (
    <>
      <JemstoneHeader/>
      <main className="authentication">
        <section>
          <div>
            <GridLoader color={colors.blue[5]} speedMultiplier={0.75} />
          </div>
          <LoadModelCounter/>
          <h3>{AppInfo.name} {AppInfo.version}</h3>
        </section>
      </main>
    </>
  )  
}

function LoadModelCounter() {
  const { currentFile } = useFilesModel()
  const count = useAtomValue(ModelAtoms.loadModelIntervalAtom)
  logger.debug("LoadModelCounter: %d seconds elapsed", count)

  return (
    <div>Loading {currentFile?.name}, {count} seconds elapsed</div>
  )
}
