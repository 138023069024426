import { ModelKeys } from "../../../core/model/ModelKeys"
import { Logger } from "../../../core/utils/Logger"
import { usePivotTables } from "../../../hooks/pivot/usePivotTables"

const logger = new Logger("charts.useAssetEarningsData")

/** */
export function useAssetEarningsData() {
  const { name, pivotTable, filter } = usePivotTables()

  logger.setContext(name).start("Calculating", "rows=%d, filter=%o", pivotTable.rows.length, Logger.Filter(filter))

  const data:AssetEarningsDP[] = []

  let currentEarnings = 0

  for (const row of pivotTable.rows) {
    if (!row.openingRow && row.endDate > 0) {
      const earnings = row.cell(ModelKeys.category.earnings).cumTotal
      const contribs = row.cumTotal - earnings

      const dp:AssetEarningsDP = { 
        key:  row.key,
        date: row.endDate, 
        Contribs: contribs,
        Earnings: earnings,
        Balance:  row.balance,
      }
      data.push(dp)

      // Update current Earnings
      if (dp.date <= Date.now()) {
        currentEarnings = dp.Earnings
      }
    }
  }

  logger.finish("Calculating", "Returning %d data points", data.length)

  return { data, currentEarnings }
}

interface AssetEarningsDP {
  key: any
  date: number
  Contribs: number
  Earnings: number
  Balance: number
}
