import { Logger } from "../../../core/utils/Logger"
import { useInvestmentModel } from "../../../hooks/context/InvestmentModelContext"
import { useCardOpen } from "../../../hooks/useCardOpen"
import { ChartSelector } from "../../charts/ChartSelector"
import { BudgetActualBarChart } from "../../charts/budget/BudgetActualBarChart"
import { useChartProfile } from "../../charts/useChartProfile"
import { useChartType } from "../../charts/useChartType"
import { Card, CardSection } from "../../controls/Card"
import { CardHeaderWithMenu, defaultToolbarChartProps } from "../CardHeaderWithMenu"
import { DurationMenu } from "../filter/DurationMenu"

export const logger = new Logger("CashflowChartCard")

interface CashflowChartCardProps {
  className?: string
  title?: string
  open?: boolean
}

export function CashflowChartCard(props:CashflowChartCardProps) {
  const { className, title, open } = props
  const { name, item } = useInvestmentModel()
  const { chartType } = useChartType("AutoSelect")
  const { size } = useChartProfile()
  const { isOpen } = useCardOpen(logger.name, item.key, open === true)

  logger.setContext(name).debug("Rendering chartType=%s", chartType)

  return (
    <Card id={logger.name} open={isOpen} className={className}>
      <CardHeaderWithMenu cardName={logger.name}
                          title={title}
                          // icon="fal fa-chart-column"
                          {...defaultToolbarChartProps}
                          showBalance />
      { isOpen &&
        <div className="duration-menu">
          <DurationMenu units={["1Y", "2Y", "3Y", "5Y", "10Y", "20Y", "30Y" ]} />
        </div>
      }
      <CardSection>
        <BudgetActualBarChart height={size.heightMini} />
        <ChartSelector height={size.heightMini} />
      </CardSection>
    </Card>
  )
}
