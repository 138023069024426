import { EmailAuthProvider, getAuth, GoogleAuthProvider, User } from 'firebase/auth'
import { auth } from 'firebaseui'
import { useEffect } from "react"
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom"
import { isModelLoading } from "../../core/service/ModelService"
import { getCurrentUserEmail, onLoginCurrentUser, onLogoutCurrentUser } from "../../core/user/UserService"
import { Logger } from "../../core/utils/Logger"
import { JemstoneHeader } from "./JemstoneHeader"

import 'firebaseui/dist/firebaseui.css'

const logger = new Logger("layout.JemstoneLogin")

/**
 * The login screen is activated via a redirection to /login by the router whenever
 * the user tries to navigate to a protected location. When the user has authenticated
 * we redirect back to the protected location, which is passed in 'location.state.from'.
 * See {@link ProtectedRoute} for implementation.
 */
export function JemstoneLogin() {
  const navigate = useNavigate()
  const location = useLocation()

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    logger.debug("useEffect: user=%s", getCurrentUserEmail())

    const authInstance = getAuth()

    const unregisterAuthObserver = authInstance.onAuthStateChanged(user => {
      onAuthStateChanged(user, navigate, location)
    })

    // Proceed 
    const authContainer = document.getElementById('firebaseui-auth-container')
    if (authContainer) {
      // Create UI if required
      let ui = auth.AuthUI.getInstance()
      if (ui === null) {
        ui = new auth.AuthUI(authInstance)
      }

      // The start method will wait until the DOM is loaded
      ui.start(authContainer, {
        // Popup signin flow rather than redirect flow
        signInFlow: 'popup',
        
        // We will display Google and Email as auth providers
        signInOptions: [
          // GoogleAuthProvider.PROVIDER_ID,
          {
            provider: GoogleAuthProvider.PROVIDER_ID,
            customParameters: {
              // Forces account selection even when one account is available
              prompt: 'select_account'
            }
          },
          EmailAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
          // Avoid redirects after sign-in
          signInSuccessWithAuthResult: () => false,
        },
      })
    }

    // Make sure we un-register Firebase observers when the component unmounts.
    return unregisterAuthObserver
  // eslint-disable-next-line
  },[])

  logger.debug("Rendering: user=%s, pathname=%s", getCurrentUserEmail(), location.pathname)

  return (
    <>
      <JemstoneHeader/>
      <main id="firebaseui-auth-container" className="authentication" />
    </>
  )
}

async function onAuthStateChanged(user:User|null, navigate:NavigateFunction, location:Location<any>) {
  logger.start("onAuthStateChanged", "user=%s", user?.email)

  // Establish database connectivity for newly authenticated user
  if (user) {
    const fileKey = location.state?.fileKey
    const success = await onLoginCurrentUser(fileKey ?? undefined)

    // Navigate back to the protected location
    if (success) {
      const from = location.state?.from ?? '/'
      logger.debug("Redirecting to %o, isModelLoading=%s", from.pathname, isModelLoading())
      navigate(from)
    } 
    
    // Let user choose which file to open
    else {
      navigate("/files")
    }
  }

  // User is logged out so unload current model
  else {
    onLogoutCurrentUser()
  }

  logger.finish("onAuthStateChanged", "user=%s", user?.email)
}

export function signOut() {
  const auth = getAuth()
  auth?.signOut()
  window.location.reload()
}
