import { BankStmtApplyRules } from '../builder/bankstmt/BankStmtApplyRules'
import { Model } from '../model/Model'
import { Asset, ModelKeys } from "../model/ModelKeys"
import { saveItems } from '../service/ModelService'
import { BankStmtFileItem } from '../types/BankStmtFileItem'
import { BankStmtRuleItem } from '../types/BankStmtRuleItem'
import { Item } from '../types/Item'
import { TransactionItem } from '../types/TransactionItem'
import { formatDate } from '../utils/DateFormat'
import { Logger } from "../utils/Logger"
import { formatCurrency, round } from '../utils/Numbers'

const logger = new Logger("atoms.BankStmtModel")

export interface TransactionResults {
  statements: BankStmtFileItem[]
  transactions: TransactionItem[]
  categorised: number
  uncategorised: number
  count: number
  moneyIn: number
  moneyOut: number
  total: number
}

export class BankStmtModel {
  private model:Model
  private _accounts?:Item[]
  private _statements?:BankStmtFileItem[]
  private _rules?:BankStmtRuleItem[]

  constructor(model:Model) {
    this.model = model

    const { statements, transactions } = this.transactions

    this.calcBalances()

    logger.debug("Created new BankStmtModel with %d statements, %d transactions, %d rules",
                  statements.length, transactions.length, this.rules.length)
  }

  get bankStmtModel() {
    return this
  }

  /** Return list of all bank statements, including those loaded from OFX and CSV files, and those from APIs */
  get statements() {
    if (!this._statements) {
      const stmtFiles = this.model.childrenOfTypeDeep<BankStmtFileItem>(ModelKeys.bank.stmts, ModelKeys.file.root)
      const stmtAPIs = this.model.childrenOfTypeDeep<BankStmtFileItem>(ModelKeys.bank.stmtAPI, ModelKeys.file.root)

      // Clean statement files that may not have an accountKey defined
      for (const stmt of stmtFiles) {
        stmt.accountKey ??= this.model.getItemKey(stmt.accountNumber)
      }

      // Combine all statements and sort
      this._statements = [...stmtFiles, ...stmtAPIs].sort((f1,f2) => this.sortFile(f1,f2))
    }
    return this._statements
  }

  /** Return all accounts that have one or more bank statements */
  get accounts() {
    if (!this._accounts) {
      const accounts = this.getAccounts(this.statements)
      this._accounts = this.model.sortByName(Array.from(accounts))
    }
    return this._accounts
  }

  get transactions() {
    return this.getTransactions(this.statements)
  }

  get rules() {
    if (!this._rules) {
      this._rules = this.model.children<BankStmtRuleItem>(ModelKeys.bank.rules)

      // Default
      for (const rule of this._rules) {
        if (rule.priority === undefined) {
          rule.priority = 3
        }
      }

      // Sort into priority order
      this._rules.sort((r1, r2) => (r1.priority - r2.priority) || (r1.sortOrder - r2.sortOrder))
    }
    return this._rules
  }

  /** Return all statements for the specified account */
  public getStatements(account:Item) {
    if (account) return (
      this.statements.filter(stmt => (stmt.accountKey === account.key))
    )
    return []
  }

  /** Return all accounts referenced by the specified statements */
  public getAccounts(statements:BankStmtFileItem[]) {
    const accounts = new Map<string,Item>()
    for (const stmt of statements) {
      const account = this.model.getItem(stmt.accountKey)
      if (account) {
        accounts.set(account.key, account)
      }
    }
    return accounts.values()
  }

  /**
   * Save the statements and transactions that have been read from a BankStmtItemFile.
   * The complexity here is that a file can be loaded more than once, or can contain
   * some transactions that have already been loaded, and some that are new. 
   * 
   * In this case we adjust the statement start/end dates to reflect a unique date range 
   * and only save the new transactions (and associated statements).
   */
  public saveStatements(statements:BankStmtFileItem[], transactions:TransactionItem[]) {
    logger.start("saveStatements", "Processing %d statements, %d transactions", statements.length, transactions.length)

    // Map of statements
    const stmtMap = this.model.toItemMap(statements)
    
    const newStmtMap = new Map<string,BankStmtFileItem>()
    const newTrans:TransactionItem[] = []

    // Sort transactions in ascending date order
    transactions.sort((t1,t2) => t1.sortOrder - t2.sortOrder)

    // Only save items that don't exist
    for (let i=0;  i < transactions.length;  i++) {
      const trans = transactions[i]

      if (this.model.hasCode(trans.code)) {
        logger.debug("Transaction already exists, i=%d, startDate=%s, code=%s", i, formatDate(trans.startDate), trans.code)
      } else {
        newTrans.push(trans)
        logger.debug("newTrans: i=%d, startDate=%s, code=%s", i, formatDate(trans.startDate), trans.code)
 
        const stmt = stmtMap.get(trans.parentKey)
        if (stmt) {
          // Add this statement if not already added
          if (!newStmtMap.has(stmt.key)) {
            newStmtMap.set(stmt.key, stmt)
  
            // This is the first transaction in this statement, so set statement startDate
            if (stmt.startDate === undefined || i > 0) {
              stmt.startDate = trans.startDate ?? 0
              stmt.endDate = stmt.startDate

              logger.debug("newStmt: i=%d, stmt.startDate=%s", i, formatDate(stmt.startDate))
            }
          } 
          
          // Update the statement endDate. Note that if the statement parser found a
          // statement endDate (i.e. OfxParser) then this is expected to be after the
          // last transaction. Otherwise we always use the latest transaction date.
          else {
            stmt.endDate = Math.max(stmt.endDate ?? 0, trans.startDate ?? 0)
            logger.debug("update endDate: i=%d, stmt.endDate=%s", i, formatDate(stmt.endDate))
          }
        }
      }
    }

    // Now save into the model
    logger.finish("saveStatements", "Saving %d/%d statements, and %d/%d transactions", 
                  newStmtMap.size, statements.length, newTrans.length, transactions.length)
    saveItems([...newStmtMap.values(), ...newTrans])

    // Automatically apply categorisation rules to the loaded data
    const builder = new BankStmtApplyRules(this.model, this.rules, newTrans, true)
    builder.run()
  }

  /**
   * Return all transactions for the specified account, sorted in descending date order (latest to earliest).
   * 
   * If no statements for this account, try for the parent account. This can happen for
   * managed funds and stocks where a single account has holdings in multiple funds or stocks.
   * @param account 
   * @returns 
   */
  public getAccountTransactions(account:Item) {
    let statements = this.getStatements(account)
    if (statements.length === 0) {
      account = this.model.getItemParent(account)
      statements = this.getStatements(account)
    }

    return this.getTransactions(statements)
  }

  /** 
   * Return all transactions for specified list of statements, sorted in descending date order (latest to earliest).
   */
  public getTransactions(statements:BankStmtFileItem[], rule?:BankStmtRuleItem) : TransactionResults {
    // Totals and counts
    let moneyIn=0, moneyOut=0
    let categorised=0, uncategorised=0

    const transTypeKeys = this.model.childrenKeysDeep(ModelKeys.transaction.root)

    // Filtered list of transactions
    const transactions:TransactionItem[] = []
    
    for (const bankStmt of statements) {
      for (const key of this.model.childrenKeys(bankStmt.key)) {
        const trans = this.model.getItem<TransactionItem>(key)
        if (transTypeKeys.has(trans.typeKey) && (rule === undefined || rule.key === trans.ruleKey)) {
          // Include this trans
          transactions.push(trans)

          // Totals for moneyIn/moneyOut
          if (trans.value > 0) {
            moneyIn += trans.value
          } else {
            moneyOut += trans.value
          }

          // Count categorised/uncategorised
          if (trans.categoryKey) {
            categorised++
          } else {
            uncategorised++
          }
        }
      }
    }

    return {
      statements: statements,
      transactions: transactions.sort((t1,t2) => t2.sortOrder - t1.sortOrder),
      categorised: categorised,
      uncategorised: uncategorised,
      count: categorised + uncategorised,
      moneyIn: moneyIn,
      moneyOut: moneyOut,
      total: moneyIn + moneyOut,
    }
  }

  public newRule(keywords:string, categoryKey:string) : BankStmtRuleItem {
    const newRule = this.model.newItem<BankStmtRuleItem>(ModelKeys.bank.rules, ModelKeys.bank.rule, "RU-")
    newRule.name = keywords
    newRule.categoryKey = categoryKey
    return newRule
  }

  private sortFile(f1:BankStmtFileItem, f2:BankStmtFileItem) {
    let result = f1.accountNumber.localeCompare(f2.accountNumber)
    if (result === 0) {
      result = f2.startDate - f1.startDate
    }
    if (result === 0) {
      result = f2.endDate - f1.endDate
    }
    return result
  }

  private calcBalances() {
    const start = Date.now()

    for (const account of this.accounts) {
      // Get stmts for an account, sorted in date ascending order
      const stmts = this.getStatements(account).sort((f1, f2) => this.sortFile(f2, f1))
      for (let i=0;  i < stmts.length;  i++) {
        const stmt = stmts[i]
        const transactions = this.model.childrenOfTypeDeep<TransactionItem>(stmt.key, ModelKeys.transaction.root)
                                       .sort((t1,t2) => t1.sortOrder - t2.sortOrder)

        // Get opening balance(s)
        let { balance, unitBalance } = this.calcOpeningBalance(stmts, i, account, transactions)

        // Compute balance forward for each transaction
        for (const trans of transactions) {
          // If unitised then always calculate balance from the unitBalance
          if (trans.units !== undefined && trans.unitPrice !== undefined) {
            trans.unitBalance = unitBalance = (unitBalance ?? 0) + trans.units
            trans.balance = balance = round(trans.unitBalance * trans.unitPrice, 2)
          } else {
            trans.balance = balance = (balance ?? 0) + trans.value
          }

          // Set account for all bank stmt transactions
          trans.accountKey = account.key
        }

        // Set closing balance for stmt
        stmt.closingBalance = balance
        stmt.closingUnitBalance = unitBalance
      }
    }

    logger.debug("calcBalances: completed in %d ms", Date.now()-start)
  }

  private calcOpeningBalance(stmts:BankStmtFileItem[], i:number, account:Item, transactions:TransactionItem[]) {
    const isUnitised = Asset.isUnitised(account)
    
    // Calc balances forward from opening balance
    const stmt = stmts[i]
    let balance = stmt.openingBalance
    let unitBalance = stmt.openingUnitBalance

    // If no opening balance, then use previous statement or first transaction
    if ((isUnitised ? unitBalance : balance) === undefined) {
      // If first statement then use balance from first transaction
      if (i === 0) {
        const trans = transactions.at(0)
        if (trans?.balance !== undefined) {
          // Loans will ALWAYS have a negative balance, but Akahu presents a positive balance
          if (account.typeKey === ModelKeys.account.loan && trans.balance > 0) {
            balance = (-trans.balance) - trans.value
          } else {
            balance = trans.balance - trans.value
          }

          // Unitised balance
          if (trans.units !== undefined && trans.unitBalance !== undefined) {
            unitBalance = trans.unitBalance - trans.units
          }

          logger.debug("calcOpeningBalance: %s %s => %s", formatDate(trans.startDate), account.name, formatCurrency(balance))
        }
      }

      // If not first statement  use previous statements closing balance
      else {
        balance = balance ?? stmts[i-1].closingBalance
        unitBalance = unitBalance ?? stmts[i-1].closingUnitBalance
      }

      // Set opening balances on the statement
      stmt.openingBalance = balance
      stmt.openingUnitBalance = unitBalance
    }

    return { balance, unitBalance }
  }
}

export function logStatements(stmts:BankStmtFileItem[]) {
  return stmts.map(stmt => ({
    key: stmt.key,
    accountNumber: stmt.accountNumber,
    startDate: formatDate(stmt.startDate),
    endDate: formatDate(stmt.endDate),
    openingBalance: stmt.openingBalance,
    closingBalance: stmt.closingBalance,
    openingUnitBalance: stmt.openingUnitBalance,
    closingUnitBalance: stmt.closingUnitBalance,
  }))
}
