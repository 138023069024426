import { XMLParser } from 'fast-xml-parser'
import { Model } from '../../model/Model'
import { ModelKeys } from "../../model/ModelKeys"
import { BankStmtFileItem } from '../../types/BankStmtFileItem'
import { ItemStatus } from '../../types/Item'
import { TransactionItem } from '../../types/TransactionItem'
import { parseDate } from '../../utils/DateFormat'
import { Logger } from '../../utils/Logger'
import { startsWith } from '../../utils/Utils'
import { FileParserResults } from './FileParserResults'

const logger = new Logger("builders.OfxParser")

function sgml2Xml(sgml: string) {
  return sgml
    .replace(/>\s+</g, '><')    // remove whitespace inbetween tag close/open
    .replace(/\s+</g, '<')      // remove whitespace before a close tag
    .replace(/>\s+/g, '>')      // remove whitespace after a close tag
    .replace(/<([A-Z0-9_]*)+\.+([A-Z0-9_]*)>([^<]+)/g, '<$1$2>$3')
    .replace(/<(\w+?)>([^<]+)/g, '<$1>$2</$1>')
}

// Preparing data fixing issues with special characters
function prepareData(content:string) {
  return content.replace(/&/g, '&amp;amp;')
}

function parseXml(content:string) {
  const options:any = {
    ignoreAttributes: true,
    ignoreNameSpace: false,
    allowBooleanAttributes: false,
    parseNodeValue: false,
    parseAttributeValue: false,
    trimValues: true,
    parseTrueNumberOnly: false,
    numberParseOptions: {
      leadingZeros: false,
    }
  }
  
  try {
    const parser = new XMLParser(options)
    return parser.parse(content, options)
  } catch (e:any) {
    const msg:string = e.toString()
    if (msg.indexOf("Expected closing tag 'BRANCHID'") === -1) {
      throw e
    }

    options.unpairedTags = ["BRANCHID"]
    const parser = new XMLParser(options)
    return parser.parse(content, options)
  }

  // const result = XMLValidator.validate(content, options)
  // if (result === true) {
  //   return parser.parse(content, options)
  // }

  // logger.error("parseXml: Error while parsing content, error=%o", result.err)
  // throw new Error(result.err.msg)
}

function parse(data: string) {
  // firstly, split into the header attributes and the footer sgml
  const ofx = data.split('<OFX>', 2)

  // firstly, parse the headers
  const headerString = ofx[0].split(/\r?\n/)
  const header: any = {}
  headerString.forEach((attrs) => {
    const headAttr = attrs.split(/:/, 2)
    header[headAttr[0]] = headAttr[1]
  })

  // make the SGML and the XML
  const content = `<OFX>${ofx[1]}`

  // Parse the XML/SGML portion of the file into an object
  // Try as XML first, and if that fails do the SGML->XML mangling
  let dataParsed = null
  try {
    dataParsed = parseXml(prepareData(content))
  } catch (e) {
    dataParsed = parseXml(prepareData(sgml2Xml(content)))
  }

  // put the headers into the returned data
  dataParsed.header = header

  return dataParsed
}

// eslint-disable-next-line
function serialize(header:any, body:any): string {
  let out = ''
  // header order could matter
  const headers = [
    'OFXHEADER', 'DATA', 'VERSION', 'SECURITY', 'ENCODING', 'CHARSET',
    'COMPRESSION', 'OLDFILEUID', 'NEWFILEUID']

  headers.forEach((name) => {
    out += `${name}:${header[name]}\n`
  })
  out += '\n'

  out += objToOfx({ OFX: body })
  return out
}

function objToOfx(obj: any) {
  let out = ''

  Object.keys(obj).forEach((name) => {
    const item = obj[name]
    const start = `<${name}>`
    const end = `</${name}>`

    if (item instanceof Object) {
      if (item instanceof Array) {
        item.forEach((it) => {
          out += `${start}\n${objToOfx(it)}${end}\n`
        })
        return
      }
      return out += `${start}\n${objToOfx(item)}${end}\n`
    }
    out += start + item + '\n'
  })

  return out
}

export function loadOfx(ofxData:string, model:Model, fileName:string) : FileParserResults {
  const data = parse(ofxData)

  const stmtrs = data.OFX?.BANKMSGSRSV1?.STMTTRNRS?.STMTRS || 
                 data.OFX?.CREDITCARDMSGSRSV1?.CCSTMTTRNRS?.CCSTMTRS
  const acctFrom = stmtrs?.BANKACCTFROM || stmtrs?.CCACCTFROM
  const tranList = stmtrs?.BANKTRANLIST
  const stmttrn:any[] = Array.isArray(tranList?.STMTTRN) ? tranList?.STMTTRN : [tranList?.STMTTRN]

  logger.start("loadOfx", "Loading %d transactions from %s", stmttrn?.length, fileName)
  
  // Create a model item for the bank statement file
  const statement = model.newItem<BankStmtFileItem>(ModelKeys.bank.stmts, ModelKeys.file.ofx, "OFX-")
  const transactions:TransactionItem[] = []
  
  try {
    statement.name = fileName
    statement.accountNumber = getAccountNumber(acctFrom)
    statement.accountKey = model.getItemKey(statement.accountNumber)
    statement.startDate = parseOfxDate(tranList?.DTSTART)
    statement.endDate = parseOfxDate(tranList?.DTEND)

    // Now load all transactions
    const length = stmttrn.length
    for (let i=0;  i < stmttrn.length;  i++) {
      const trn = stmttrn[i]
      const code = statement.accountNumber + ":" + trn.FITID?.toString()

      const date = parseOfxDate(trn.DTPOSTED)
      const trans:TransactionItem = {
        key: model.newKey(statement.key, "OFX-"),
        code: code,
        parentKey: statement.key,
        typeKey: ModelKeys.transaction.moneyOut,
        startDate: date,
        value: trn.TRNAMT,
        name: trn.NAME || "",
        description: trn.MEMO || "",
        sortOrder: date + (length-i),              // Use index to preserve ordering
        status: ItemStatus.NEW,
        modifiedDate: Date.now(),
      }

      // Derive transaction type
      const isTransfer = startsWith(trans.description, "Transfer")
      if (isTransfer) {
        trans.typeKey = (trans.value > 0) ? ModelKeys.transaction.transferIn : ModelKeys.transaction.transferOut
        // item.categoryKey = ModelKeys.category.transfer
      } else {
        trans.typeKey = (trans.value > 0) ? ModelKeys.transaction.moneyIn : ModelKeys.transaction.moneyOut
      }

      // Special processing for paywave
      const isVisa = startsWith(trans.description, "Visa Purchase")
      if (isVisa) {
        const name = trans.description?.substring(13).trimStart()
        trans.description += ": " + trans.name
        trans.name = name || ""
      }

      // Add to array of new items
      transactions.push(trans)
    }

    logger.finish("loadOfx", "Loaded %d/%d transactions from %s", transactions.length, length, fileName)

  } catch (e:any) {
    const message = logger.error("loadOfx", "Error loading transactions from %s", fileName)
    logger.finish("loadOfx", message)
    throw Error(message, { cause:e })
  }

  return { data, statements: [statement], transactions }
}

function getAccountNumber(acctFrom:any) {
  const bankId = acctFrom.BANKID?.toString()
  const branchId = acctFrom.BRANCHID?.toString()
  const acctId = acctFrom.ACCTID?.toString()

  const bank = getBank(bankId, branchId, acctId)
  const branch = getBranch(bankId, branchId, acctId)
  const account = getAccount(bankId, branchId, acctId)

  const accountNumber = bank + branch + account

  logger.debug("getAccountNumber: bankId=%s, branchId=%s, acctId=%s, accountNumber=%s",
                bankId, branchId, acctId, accountNumber)

  return accountNumber

  function getBank(bank:string, branch:string, account:string) {
    // BNZ current accounts
    if (bank && bank.length > 2) {
      bank = bank.substring(0,2)
    }
    return bank ? bank.padStart(2,"0") + "-" : ""
  }

  function getBranch(bank:string, branch:string, account:string) {
    if (!branch) {
      // BNZ current accounts
      if (bank && bank.length > 2) {
        branch = bank.substring(2)
      }
    }
    return branch ? branch.padStart(4,"0") + "-" : ""
  }

  function getAccount(bank:string, branch:string, account:string) {
    if (!account) {
      return ""
    }

    // Accounts with separator for suffix
    if (account.indexOf("-") !== -1) {
      return account
    }

    // BNZ current accounts have 7 digits + 3 digit suffix
    if (account.length === 10) {
      return account.substring(0,7) + "-" + account.substring(7)
    }

    // ANZ loan accounts have 8 digits + 4 digit suffix
    if (account.length === 12) {
      return account.substring(0,8) + "-" + account.substring(8)
    }

    return account
  }
}

function parseOfxDate(ofxDate:number) {
  if (ofxDate) {
    const date = parseDate(ofxDate.toString(10), "yyyyMMdd")
    if (date) {
      return date.getTime()
    }
  }
  return 0
}
