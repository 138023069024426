import { atom } from "jotai"
import { Logger } from "../../../core/utils/Logger"
import { getAtomValue, setAtomValue } from "../../../core/atom/AtomUtils"

const logger = new Logger("wizards.WizardState")

export class WizardState {
  public name = ""
  public pageIds: string[] = []

  public currentPageAtom = atom(0)
  
  private setWizardContext?: (state:WizardState) => void

  private _primaryItemProp = "primaryItem"

  constructor(name="", pageIds:string[], currentPage:number, primaryItemProp:string|undefined, setWizardContext?: (state:WizardState) => void) {
    this.name = name
    this.pageIds = pageIds
    this._primaryItemProp = primaryItemProp ?? "primaryItem"
    this.setWizardContext = setWizardContext
  }

  get wizard() {
    return this
  }

  get primaryItemProp() {
    return this._primaryItemProp
  }

  set primaryItemProp(prop:string) {
    this._primaryItemProp = prop
  }

  private get currentPage() {
    return getAtomValue(this.currentPageAtom)
  }

  get numPages() {
    return this.pageIds.length
  }

  get currentPageId() {
    return this.pageIds[this.currentPage]
  }

  public next() {
    this.setCurrentPage(this.currentPage + 1)
  }

  public back() {
    this.setCurrentPage(this.currentPage - 1)
  }

  public setCurrentPage(currentPage: number) {
    logger.debug("setCurrentPage: %d => %d", this.currentPage, currentPage)

    setAtomValue(this.currentPageAtom, currentPage)
  }
}
