import { startOfQuarter } from "date-fns"
import { atom, useAtom, useSetAtom } from "jotai"
import { useState } from "react"
import { fetchAccounts, fetchTransactions, fetchVersion } from "../../../../core/functions/FirebaseFunctions"
import { rebuildAllModels } from "../../../../core/service/ModelBuilder"
import { reloadModel } from "../../../../core/service/ModelService"
import { runLoanAmortizationTest } from "../../../../core/tests/LoanTest"
import { Logger } from "../../../../core/utils/Logger"
import { CountersCardAtoms } from '../../../../hooks/atoms/CountersCardAtoms'
import { TimersCardAtoms } from '../../../../hooks/atoms/TimersCardAtoms'
import { useModel } from "../../../../hooks/context/ModelContext"
import { Card, CardHeader, CardSection } from '../../../controls/Card'
import { ButtonOpen } from "../../../controls/buttons/ButtonOpen"
import { WizardMenuListItem } from "../../../wizards/WizardMenu"
import { searchSpeedTest } from "./SpeedTest"

const logger = new Logger("settings.DiagnosticTestCard")

const openAtom = atom(true)

export function DiagnosticTestCard(props:any) {
  const { model } = useModel()
  const [ isOpen, setOpen ] = useAtom(openAtom)
  const setTimerSearchValue = useSetAtom(TimersCardAtoms.searchValue)

  const [ response, setResponse ] = useState<any>()

  logger.debug("Rendering:")

  return (
    <Card id="DiagnosticTestCard" open={isOpen}>
      <CardHeader>
        <i className="fal fa-stopwatch-20" />
        <h2 onClick={() => setOpen(!isOpen)}>
          Diagnostic Functions
        </h2>
        <menu>
          <ButtonOpen openAtom={openAtom} />
        </menu>
      </CardHeader>
      <CardSection className="wizard-menu">
        <WizardMenuListItem 
          icon="fal fa-stopwatch-20"
          title="Reset Timers and Counters"
          subtitle={`Reset all timer and counter values`}
          onClick={resetTimersAndCounters}
        />
        <WizardMenuListItem 
          icon="fal fa-database"
          title="Reload from Database"
          subtitle={`Perform a full reload of the model from the database, and rebuild all cashflows`}
          onClick={onReload}
        />
        <WizardMenuListItem 
          icon="fal fa-person-running"
          title="Rebuild All Cashflows"
          subtitle={`Perform a full rebuild of all cashflows, pivot tables, etc`}
          onClick={onRebuild}
        />
        <WizardMenuListItem 
          icon="fal fa-search"
          title="Search Speed Test"
          subtitle={`Run 7 searches of ${model.size()} items in the database`}
          onClick={onRunTest}
        />
        <WizardMenuListItem 
          icon="fal fa-function"
          title="Akahu API Tests"
          subtitle={`Execute Akahu accounts and transactions APIs`}
          onClick={onRunAkahuTest}
        />
      </CardSection>
      <CardSection>
        <pre>{response}</pre>
      </CardSection>
    </Card>
  )

  function resetTimersAndCounters() {
    TimersCardAtoms.reset()
    CountersCardAtoms.reset()
  }

  function onReload() {
    reloadModel()
  }

  function onRebuild() {
    rebuildAllModels()
  }

  function onRunTest() {
    searchSpeedTest(model)
    setTimerSearchValue("diagnostics")

    runLoanAmortizationTest()
  }
  
  async function onRunAkahuTest() {
    const end = Date.now()
    const start = startOfQuarter(end).getTime()
    
    const versionInfo  = await fetchVersion()
    const accounts     = await fetchAccounts()
    const transactions = await fetchTransactions(start, end)

    setResponse(JSON.stringify({ versionInfo, accounts, transactions }, null, 2))
  }
}
