import { PrimitiveAtom, atom } from "jotai"
import { useRef } from "react"
import { InitFn } from "./useConst"

/**
 * Creates an atom that follows the lifecycle of a component
 *
 * @param init Initial value, or a function that returns initial value
 * @returns
 */
export function useAtomRef<T extends any>(init: T | InitFn<T>): PrimitiveAtom<T> {
  const ref = useRef<PrimitiveAtom<T> | null>(null)

  if (ref.current === null) {
    const initialValue = typeof init === "function" ? (init as InitFn<T>)() : init
    ref.current = atom(initialValue)
  }

  return ref.current
}
