import { ModelKeys } from "../../core/model/ModelKeys"
import { Item } from "../../core/types/Item"
import { Logger } from "../../core/utils/Logger"
import { ValuationSection } from "./asset/ValuationSection"
import { BudgetSection } from "./budget/BudgetSection"
import { LoanSection } from "./loan/LoanSection"
import { PortfolioSection } from "./portfolio/PortfolioSection"
import { PropertySection } from "./property/PropertySection"

export const logger = new Logger("cards.SectionSelector")

export function SectionSelector({ item }: { item:Item }) {
  switch (item.typeKey) {
    case ModelKeys.asset.portfolio:
      return <PortfolioSection />

    case ModelKeys.asset.property:
      return <PropertySection />

    case ModelKeys.account.checking:
    case ModelKeys.account.creditcard:
    case ModelKeys.account.deposit:
    case ModelKeys.account.savings:
    case ModelKeys.account.wallet:
      return <BudgetSection />
  
    case ModelKeys.account.loan:
      return <LoanSection />

    case ModelKeys.type.valuation:
    case ModelKeys.asset.investment:
    case ModelKeys.asset.kiwisaver:
    case ModelKeys.asset.stock:
      return <ValuationSection />
  }

  return <></>
}
