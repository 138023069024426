import { PrimitiveAtom, atom } from "jotai"
import { setAtomValue } from "../../core/atom/AtomUtils"
import { isEqualFilter } from "../../core/builder/CashflowFilter"
import { Filter } from "../../core/filter/Filter"
import { InvestmentModel } from "../../core/invest/InvestmentModel"
import { Model } from "../../core/model/Model"
import { CashflowItem } from "../../core/types/CashflowItem"
import { Item } from "../../core/types/Item"
import { formatDate } from "../../core/utils/DateFormat"
import { Logger } from "../../core/utils/Logger"
import { TimeScale, intervalStarting, timeScale, timeScaleSnap, timeScaleStarting } from "../../core/utils/TimeScale"
import { getUniqueId } from "../../core/utils/Utils"
import { InvestmentModelPivotTables } from "./InvestmentModelPivotTables"

export type InitFilter = Partial<Filter> | ((investmentModel:InvestmentModel<Item>) => Partial<Filter>)

/**
 * A FilterStack manages a stack of Filters that can be navigated much like the Browser history using
 * the {@link push} and {@link pop} methods.
 *
 * It is possible to subscribe to changes in the current {@link filter} using {@link filterAtom}, using
 * the {@link useAtomValue} hook.
 */

export class FilterStack {
  public readonly id = getUniqueId()

  public readonly stackName: string

  public readonly investmentModel: InvestmentModel<Item>

  /** Atom that contains the current filter */
  public readonly filterAtom: PrimitiveAtom<Filter>

  private readonly logger: Logger

  private readonly filters: Filter[]

  private _cashflowItems?: CashflowItem[]
  private _pivotTables?: InvestmentModelPivotTables

  private _logFilter: any

  constructor(investmentModel: InvestmentModel<Item>, stackName: string, initFilter?: InitFilter) {
    this.logger = new Logger("hooks.FilterStack", stackName).addContext(investmentModel.name)

    this.stackName = stackName
    this.investmentModel = investmentModel

    this.filters = [{ ...investmentModel.filterDefault, id: this.newFilterId() }]

    if (initFilter) {
      let changes:Partial<Filter>
      if (typeof initFilter === "function") {
        changes = initFilter(investmentModel)
      } else {
        changes = initFilter
      }
      const newFilter = this.applyChanges(changes)
      this.filters = [newFilter]
      // this.filters.push(newFilter)
    }

    // Create an atom that subscribers will use to react to changes
    this.filterAtom = atom(this.filter)

    this.logger.debug("Created stackName=%s, filter=%s, %o", this.stackName, this.filter.id, this.logFilter)
  }

  /** @returns the Model */
  get model(): Model {
    return this.investmentModel.model
  }

  get name() {
    return this.stackName
  }

  get filterStack() {
    return this
  }

  /** The current filter used when building cashflows and pivot tables */
  get filter(): Filter {
    return this.filters.at(-1) as Filter
  }

  get filterItem() {
    const filter = this.filter
    return filter.columnItem || filter.accountItem
  }

  get length() {
    return this.filters.length
  }

  /**
   * The date range used to filter rows in the cashflow
   * @start the lower end of filtered date range i.e. item.date >= start
   * @end the upper end of filtered date range i.e. item.date <= end
   * @units the interval between rows in the filtered pivot table
   */
  get scale() {
    return this.filter.scale
  }

  get interval() {
    return this.filter.interval
  }

  get duration() {
    return this.filter.duration
  }

  get dateRangeCode() {
    return this.filter.dateRangeCode
  }

  get included() {
    return this.filter.included
  }

  get dimension() {
    return this.filter.dimension
  }

  get isAccount() {
    return this.filter.dimension === "Account"
  }

  get isAccountType() {
    return this.filter.dimension === "AccountType"
  }

  get isAsset() {
    return this.filter.dimension === "Asset"
  }

  get isCategory() {
    return this.filter.dimension === "Category"
  }

  get isType() {
    return this.filter.dimension === "Type"
  }

  get logFilter() {
    if (!this._logFilter) {
      this._logFilter = Logger.Filter(this.filter)
    }
    return this._logFilter
  }

  /** @returns a list of all cashflow items filtered by the current filter */
  get cashflowItems(): CashflowItem[] {
    if (!this._cashflowItems) {
      this._cashflowItems = this.investmentModel.cashflow.filterBy(this.filter)
    }
    return this._cashflowItems
  }

  /** @returns the {@link InvestmentModelPivotTables} object for the current filter */
  get pivotTables(): InvestmentModelPivotTables {
    if (!this._pivotTables) {
      this._pivotTables = new InvestmentModelPivotTables(this.investmentModel, this.filter)
    }
    return this._pivotTables
  }

  public applyChanges(changes: Partial<Filter>) {
    const newFilter: Filter = { ...this.filter }

    // Change scale only if explicitly specified
    if ('scale' in changes && changes.scale) {
      newFilter.scale = changes.scale
      newFilter.included = this.getIncludedDateSet(newFilter.scale)
      newFilter.duration = undefined
      newFilter.dateRangeCode = undefined
    }

    // Change dimension only if explicitly specified
    if ('dimension' in changes && changes.dimension) {
      newFilter.dimension = changes.dimension
    }

    // columnItem filters, if explicitly specified
    if ('columnItem' in changes) {
      if (newFilter.dimension === "Account") {
        newFilter.accountItem = changes.columnItem
        newFilter.dimension = "Category"
      } else {
        newFilter.columnItem = changes.columnItem
      }
    }

    // Change 'included' only if explicitly specified, and accept undefined values
    if ('included' in changes) {
      newFilter.included = changes.included
    }

    // Change 'interval' only if explicitly specified, and accept undefined values
    if ('interval' in changes) {
      const { interval } = changes
      newFilter.interval = interval
      if (interval) {
        // The new scale is snapped to the start of the specified interval (1Y,3M, etc)
        newFilter.scale = timeScaleSnap({...newFilter.scale, units:interval})
        newFilter.included = this.getIncludedDateSet(newFilter.scale)
      }
    }

    // Change 'duration' only if explicitly specified, and accept undefined values
    if ('duration' in changes) {
      const { duration } = changes
      newFilter.duration = duration
      if (duration) {
        const scale = timeScaleStarting(duration, this.scaleSnapped.start)
        scale.units = newFilter.interval || scale.units

        // TODO Check whether setting start/end = 0 is correct
        newFilter.scale = scale
        newFilter.included = this.getIncludedDateSet(scale)
        newFilter.dateRangeCode = undefined
      }
    }

    // Change 'dateRangeCode' only if explicitly specified, and accept undefined values
    if ('dateRangeCode' in changes) {
      const code = changes.dateRangeCode
      newFilter.dateRangeCode = code

      if (code) {
        const scale = timeScale(code)
        newFilter.scale = scale
        newFilter.duration = undefined
        newFilter.included = this.getIncludedDateSet(scale)
      } else {
        newFilter.included = undefined
      }
    }

    // Change 'raw' only if explicitly specified
    if (changes.raw !== undefined) {
      newFilter.raw = changes.raw
    }

    return newFilter
  }

  public push(changes: Partial<Filter>) {
    this.logger.start("push", "%d filters, filter=%s, changes=%o", this.length, this.filter.id, Logger.Filter(changes))

    // Apply changes to the existing filter
    const newFilter = this.applyChanges(changes)

    // Update the filter in the investment model
    if (!isEqualFilter(newFilter, this.filter)) {
      newFilter.id = this.newFilterId()
      this.logger.debug("push: newFilter=%o", Logger.Filter(newFilter))
      this.filters.push(newFilter)
      this.onChangeFilter()
    }

    this.logger.finish("push", "%d filters, filter=%s", this.length, this.filter.id)

    return newFilter
  }

  public pop() {
    this.logger.start("pop", "%d filters", this.length)

    // Pop the most recent filter off the stack
    if (this.canPop) {
      this.filters.pop()
      this.onChangeFilter()
    }

    this.logger.finish("pop", "%d filters, filter=%s", this.length, this.filter.id)
  }

  public clear(length = 1) {
    this.logger.start("clear", "%d filters", this.length)

    if (this.filters.length > length) {
      this.filters.length = length
      this.onChangeFilter()
    }

    this.logger.finish("clear", "%d filters, filter=%s", this.length, this.filter.id, this.logFilter)
  }

  get canPop() {
    return this.length > 1
  }

  private onChangeFilter() {
    this._logFilter = undefined
    this._pivotTables = undefined
    this._cashflowItems = undefined

    this.logger.trace("onChangeFilter: filter=%o", this.logFilter)

    setAtomValue(this.filterAtom, this.filter)
  }

  public onModelChanged(updated:number) {
    this.logger.trace("onModelChanged: resetting cashflows and pivotTables, updated at %s", formatDate(updated))

    this._logFilter = undefined
    this._pivotTables = undefined
    this._cashflowItems = undefined
  }

  /** The time scale snapped to the nearest unit e.g. year start/end, month start/end etc */
  get scaleSnapped() {
    let { start, end, units } = this.scale
    if (start <= 0) {
      start = intervalStarting(units, this.investmentModel.start).start
    }
    if (end <= 0) {
      end = intervalStarting(units, this.investmentModel.finish).end
    }
    return { start, end, units }
  }

  get scaleFull() {
    let { start, end, units } = this.scale

    const cashflowItems = this.investmentModel.cashflowItems
    start = cashflowItems.at(0)?.date || 0
    end = cashflowItems.at(-1)?.date || 0

    return { start, end, units }
  }

  public getIncludedDateSet(scale:TimeScale) {
    return new Set(this.getIncludedDates(scale))
  }

  public getIncludedDates(scale: TimeScale) {
    const includedDates:number[] = []

    if (!scale.start && !scale.end) {
      return includedDates
    }

    const { start, end, units } = scale
    for (let date = start; date <= end; ) {
      const includeDate = intervalStarting(units, date).end
      includedDates.push(includeDate)
      date = includeDate + 1
    }

    // this.logger.debug("getIncludedDates: scale={%s}, includedDates=%o", Logger.Scale(scale), 
    //                   Array.from(includedMap.keys()).map((date:number) => formatDate(date, "yyyy-MM-dd")))
    return includedDates
  }

  private newFilterId() {
    return this.id + "." + getUniqueId()
  }
}
