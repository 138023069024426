import { atom, useAtom } from "jotai"

const readonlyAtom = atom(true)

export function useReadonly() {
  const [ readonly, setReadonly ] = useAtom(readonlyAtom)

  return {
    readonly: readonly,
    setReadonly: (readonly:boolean) => setReadonly(readonly)
  }
}
