import { Filter } from "../../filter/Filter"
import { InvestmentModel } from "../../invest/InvestmentModel"
import { ValuationCalculator } from "../../invest/ValuationCalculator"
import { ValuationModel } from "../../invest/ValuationModel"
import { Model } from "../../model/Model"
import { ModelKeys } from "../../model/ModelKeys"
import { Item } from "../../types/Item"
import { Logger } from "../../utils/Logger"
import { getDaysToDate, TimeScale } from "../../utils/TimeScale"
import { PivotRow, PivotTable } from "../PivotTable"
import { PerformanceStats } from "./PerformanceStats"

const logger = new Logger("core.AccountPerformance")

export class AccountPerformance extends PerformanceStats {
  public model: Model
  public item: Item
  public days: number

  private valuationCalc: ValuationCalculator | undefined
  private pivotTable: PivotTable
  private pivotRow: PivotRow

  constructor(investmentModel:InvestmentModel<Item>, days = 30) {
    super()

    logger.start("Constructor", "Creating new for '%s', days=%d", investmentModel.name, days)

    this.model = investmentModel.model
    this.item = investmentModel.item
    this.days = days

    // Create a dedicated pivot table
    let scale:TimeScale = { start:0, end:Date.now(), units:"1M" }
    if (days !== 0) {
      scale = getDaysToDate(Date.now(), days)
    }
    const filter:Filter = { scale, dimension: "Account", raw: false }

    this.pivotTable = new PivotTable(this.model, this.item, filter, "Forecast")
    this.pivotTable.build(investmentModel.cashflowItems)
    this.pivotRow = this.pivotTable.currentRow

    // Also set handle to the ValuationCalc object (which may be undefined)
    this.valuationCalc = (investmentModel as ValuationModel).valuationCalc

    this.calcPercentChange(this.openingBalance, this.currentBalance)

    logger.finish("Constructor", "Created new for '%s', scale=%s", investmentModel.name, Logger.Scale(scale))

  }

  get key() {
    return this.item.key
  }

  get name() {
    return this.item.name
  }

  get date() {
    return this.pivotRow.date
  }

  get openingPrice() {
    return this.valuationCalc?.first?.unitPrice
  }

  get currentPrice() {
    return this.valuationCalc?.last?.unitPrice
  }

  get cagr() {
    return this.valuationCalc?.cagr
  }

  get openingBalance() {
    return this.pivotTable?.firstRow.balance
  }

  get currentBalance() {
    return this.pivotRow.balance
  }

  get currentRow() {
    return this.pivotRow
  }

  public balance(category: Item) {
    return this.pivotRow?.cell(category.key).balance
  }

  public total(category: Item) {
    return this.pivotRow?.cell(category.key).total
  }

  public cumTotal(category: Item) {
    return this.pivotRow?.cell(category.key).cumTotal
  }

  get nonFinanceTotal() {
    let total = 0
    for (const categoryKey of this.model.childrenKeys(ModelKeys.category.root)) {
      if (categoryKey !== ModelKeys.category.finance) {
        total += this.pivotRow?.cell(categoryKey)?.cumTotal || 0
      }
    }
    return total
  }
}
