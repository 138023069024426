import { Type } from "../../core/model/ModelKeys"
import { Item } from "../../core/types/Item"
import { TransactionItem } from "../../core/types/TransactionItem"
import { formatDateRange, formatDurationSafe } from "../../core/utils/DateFormat"
import { Logger } from "../../core/utils/Logger"
import { formatCurrency } from "../../core/utils/Numbers"
import { clzName } from "../../core/utils/Utils"
import { useFormData, useFormItem } from "../../hooks/context/FormContext"
import { useModel } from "../../hooks/context/ModelContext"
import { Card, CardSection } from "../controls/Card"
import { useWizard } from "./context/WizardContext"
import { CreateFileWizardData } from "./files/CreateFileWizardData"
import { WizardFooter } from "./WizardFooter"
import { WizardHeader } from "./WizardHeader"

const logger = new Logger("wizards.WizardSavePage")

export type WizardSaveFn = () => void

export function WizardSavePage({ onSave }:{ onSave?: WizardSaveFn }) {
  const { model } = useModel()
  const { primaryItemProp } = useWizard()
  const { formData, getFormItems } = useFormData()
  const [ primaryItem ] = useFormItem(primaryItemProp)

  const itemType = model.getItem(primaryItem?.typeKey)
  const itemTypeName = clzName(itemType?.name, { 
    "Account": model.isAccount(itemType),
    "Folder":  Type.isFolder(primaryItem),
    "File":    Type.isFile(primaryItem),
  })

  const title = `Save your new ${itemTypeName}`
  const subtitle = `Please confirm that you would like to create your new ${itemTypeName} as described below.`
  const icon = model.getItemIcon(primaryItem)

  logger.debug("Rendering: formItems=%o", getFormItems())

  return (
    <Card id="WizardSavePage" className="wizard-page">
      <WizardHeader title={title} subtitle={subtitle} />
      <CardSection>
        <div className={"wizard-save"}>
          {icon && <i className={icon} />}
          <h2>{primaryItem?.name} - {primaryItem?.code}</h2>
          <p>The following items will be created when you click Save.</p>
          <ol>
            { getFormItems().map((item,index) =>
              <li key={index}>
                <ModifiedItemSummary item={item} />
              </li>
            )}
          </ol>
        </div>
      </CardSection>
      <WizardFooter showClose showSave onSave={onSave} />
    </Card>
  )

  function ModifiedItemSummary({item} : { item:Item }) {
    const itemName = model.getItemName(item)
    const typeName = model.getItemName(item?.typeKey)
    const parentName = model.getItemName(item?.parentKey)

    // Accounts
    if (model.isAccount(item)) return (
      <>
        A new {typeName} account called <em>{itemName}</em> will be created in <em>{parentName}</em>
        {item?.code ? `with account number ${item?.code}` : ""}.
      </>
    )

    // Assets
    if (model.isAsset(item)) return (
      <> 
        A new <em>{typeName}</em> asset called<em>{itemName}</em> will be created n <em>{parentName}</em>.
      </>
    )

    // Transactions
    if (model.isTransaction(item)) {
      const trans = item as TransactionItem
      const frequency = trans.frequency && formatDurationSafe(trans.frequency)
      const dateRange = formatDateRange(trans.startDate ?? 0, trans.finishDate ?? 0)

      return (
      <>
        A new <em>{typeName}</em> transaction for {formatCurrency(trans.value)}
        { frequency 
        ? <> every {frequency} from {dateRange}</>
        : <> on {dateRange} </>
        }.
      </>
    )}

    // File
    if (Type.isFile(item)) return (
      <>
        A new <em>File</em> called <em>{itemName}</em> will be created, and loaded with 
        data from {(formData as CreateFileWizardData).selectedPersona?.name}'s Persona.
      </>
    )
  }
}
