import { Logger } from "../../../core/utils/Logger"
import { useFilterContext } from "../../../hooks/context/FilterContext"
import { useInvestmentModel } from "../../../hooks/context/InvestmentModelContext"
import { AssetEarningsAreaChart } from "../../charts/asset/AssetEarningsAreaChart"
import { AccountBalanceAreaChart } from "../../charts/cashflow/AccountBalanceAreaChart"
import { PortfolioSummaryTable } from "./PortfolioSummaryTable"

const logger = new Logger("dashboard.PortfolioSummary")

export function PortfolioSummary() {
  const { name, hasAssets, hasProperty } = useInvestmentModel()
  const { filterStack } = useFilterContext()

  logger.setContext(name).debug("Rendering: currentRow=%o, filter=%s", filterStack.filter.id)

  return (
    <>
      <PortfolioSummaryTable />

      { hasAssets
        ? <AssetEarningsAreaChart height="100%" 
                                  units={hasProperty ? "5Y" : hasAssets ? "3M" : undefined} 
                                  noHeader noLegend
                                  onClickXAxis={(e:any) => onClickDate(e.value)} />
        : <AccountBalanceAreaChart height="100%" noHeader noLegend 
                                   onClickXAxis={(e:any) => onClickDate(e.value)} />
      }
    </>
  )

  function onClickDate(endDate:number) {
    logger.debug("onClickDate: date=%s", Logger.Date(endDate, "yyyy-MM-dd HH:mm:ss.SSS"))
  }
}
