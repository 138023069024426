import { ValuationModel } from '../../../core/invest/ValuationModel'
import { Logger } from "../../../core/utils/Logger"
import { useFilterContext } from "../../../hooks/context/FilterContext"
import { useInvestmentModel } from '../../../hooks/context/InvestmentModelContext'
import { useCardOpen } from "../../../hooks/useCardOpen"
import { AssetEarningsAreaChart } from "../../charts/asset/AssetEarningsAreaChart"
import { AssetPriceChart } from '../../charts/asset/AssetPriceChart'
import { useChartProfile } from "../../charts/useChartProfile"
import { Card, CardSection } from "../../controls/Card"
import { CardHeaderWithMenu, defaultToolbarChartProps } from '../CardHeaderWithMenu'
import { DurationMenu } from '../filter/DurationMenu'
import { AssetPriceNarrative } from "./AssetPriceNarrative"

const logger = new Logger("AssetChartCard")

export function AssetChartCard(props: { title?:string, open?:boolean }) {
  const { title, open } = props
  const { name, asset } = useInvestmentModel<ValuationModel>()
  const { isOpen } = useCardOpen(logger.name, asset.key, open === true)
  const { filter } = useFilterContext()
  const { size } = useChartProfile()

  logger.setContext(name).debug("Rendering: asset=%s, filter=%s", asset?.name, filter.id)

  return (
    <Card id={logger.name} open={isOpen}>
      <CardHeaderWithMenu cardName={logger.name} title={title} {...defaultToolbarChartProps} showBalance />
      { isOpen &&
        <div className="duration-menu">
          <DurationMenu units={["1Y", "2Y", "3Y", "5Y", "10Y", "20Y", "30Y" ]} />
        </div>
      }
      <CardSection>
        <AssetEarningsAreaChart height={size.heightMini} noLegend />
        <AssetPriceChart height={size.heightMini} />
        <AssetPriceNarrative/>
      </CardSection>
    </Card>
  )
}
