import { Item, ItemProps, ItemStatus } from "../types/Item"
import { TypeItem } from "../types/TypeItem"
import { Logger } from "../utils/Logger"
import { getUniqueId } from "../utils/Utils"
import { ModelCodes } from "./ModelCodes"

const logger = new Logger("model.Model")

export interface ModelError {
  key: string
  message: string
  item: Item
}

/**
 * Container for a model of many items
 */
export class Model {
  /** Map of items keyed by Item.key */
  private itemMap = new Map<string,Item>()

  /** Map of items keyed by Item.code */
  private codeMap = new Map<string,Item>()

  /** Cached array of all items in the map */
  private itemArray:Item[] = []

  /** Map containing an array of children for each type, keyed by Item.typeKey */
  private typeMap = new Map<string,string[]>()

  /** Map containing an array of children keys for each item, keyed by Item.parentKey */
  private childrenMap = new Map<string,string[]>()

  /** Map containing an array of item keys that reference the key item (using Item.links) */
  private linksMap = new Map<string,string[]>()

  /** Map of validation errors key by Item.key */
  private errorsMap = new Map<string,ModelError[]>()

  /** Clear the model of all items */
  public clear() {
    this.itemArray = []
    this.itemMap.clear()
    this.childrenMap.clear()
    this.typeMap.clear()
    this.linksMap.clear()
    this.codeMap.clear()
    this.errorsMap.clear()
  }

  public isEmpty(): boolean {
    return this.itemMap.size <= 0
  }

  public has(key:string|undefined): boolean {
    return key !== undefined && this.itemMap.has(key)
  }

  public hasCode(code:string|undefined): boolean {
    return code !== undefined && this.codeMap.has(code)
  }

  public size(): number {
    return this.itemMap.size
  }

  public keys(): Iterable<string> {
    return this.itemMap.keys()
  }

  public values() : Iterable<Item> {
    return this.itemMap.values()
  }

  public items(): Item[] {
    if (this.itemArray.length === 0) {
      this.itemArray = Array.from(this.itemMap.values())
    }
    return this.itemArray
  }

  public types(): Item[] {
    return this.childrenDeep(this.codeMap.get(ModelCodes.type.root)?.key as string)
  }

  public categories(): Item[] {
    return this.childrenDeep(this.codeMap.get(ModelCodes.category.root)?.key as string)
  }

  public getItem<T extends Item>(key:string|undefined) : T {
    let item
    if (key !== undefined){
      item = this.itemMap.get(key) ?? this.codeMap.get(key)
    }
    return (item as T)
  }

  public setItems(items:Item[]) : Model {
    const isReload = this.isEmpty()

    // Set all items in the model
    items.forEach(item => this.setItem(item, isReload))

    // Validate reference keys
    items.forEach(item => this.validateRefKeys(item))

    // Log all validation errors
    if (isReload && this.errorsMap.size > 0) {
      logger.warn("setItems: Validation errors detected:", this.getErrorsAll())
    }

    if (isReload) {
      logger.debug("setItems: Model reloaded, size=%d", this.size())
    }

    return this
  }

  public setItem<T extends Item>(item:T, isReload=false) : T {
    try {
      // Check for invalid item
      this.validateItem(item)

      // Clear array if req'd
      if (this.itemArray.length > 0) {
        this.itemArray = []
      }

      // If status is DELETED then remove
      if (item.status === ItemStatus.DELETED) {
        return this.removeItem<T>(item)
      }
      
      // Handle change of parentKey
      const oldItem = this.getItem(item.key)
      if (oldItem !== undefined && oldItem.parentKey !== item.parentKey) {
        this.removeFromArray(oldItem.key, this.childrenMap.get(oldItem.parentKey))
      }

      // Add to all items map
      this.itemMap.set(item.key, item)

      // Add item to parent's list of children if required
      let childKeys = this.childrenMap.get(item.parentKey)
      if (childKeys === undefined) {
        childKeys = []
        this.childrenMap.set(item.parentKey, childKeys)
      }
      if (isReload || !childKeys.includes(item.key)) {
        childKeys.push(item.key)
      }

      // Add item to the typeMap
      let typeKeys = this.typeMap.get(item.typeKey)
      if (typeKeys === undefined) {
        typeKeys = []
        this.typeMap.set(item.typeKey, typeKeys)
      }
      if (isReload || !typeKeys.includes(item.key)) {
        typeKeys.push(item.key)
      }

      // Update the linksMap if required
      this.updateLinksMap(item, isReload)

      // Update codeMap if code is present
      if (oldItem?.code !== undefined && oldItem.code !== item.code) {
        this.codeMap.delete(oldItem.code)
      }
      if (item.code !== undefined) {
        this.codeMap.set(item.code, item)
      }

      // Return the item just updated
      return item
    } catch (e:any) {
      const message = logger.error("setItem", "Error setting item: %o", item)
      throw Error(message, { cause:e })
    }
  }

  /** 
   * Remove an item from the model, updating all internal data structures
   */
  private removeItem<T extends Item>(item:T) : T {
    const key = item.key

    // Remove from maps
    this.itemMap.delete(key)
    this.typeMap.delete(key)
    this.linksMap.delete(key)
    this.errorsMap.delete(key)

    // Children map, and from parent item's array of children
    this.childrenMap.delete(key)
    this.removeFromArray(key, this.childrenMap.get(item.parentKey))

    // Type map
    this.removeFromArray(key, this.typeMap.get(item.typeKey))

    // Code map
    if (item.code !== undefined) {
      this.codeMap.delete(item.code)
    }

    // Return the item just removed
    return item
  }

  private updateLinksMap(item:Item, isReload:boolean) {
    if (!item.links || item.links.length === 0) {
      return
    }

    for (const targetKey of item.links) {
      let targetLinkMapKeys = this.linksMap.get(targetKey)
      if (targetLinkMapKeys === undefined) {
        targetLinkMapKeys = []
        this.linksMap.set(targetKey, targetLinkMapKeys)
      }
      if (isReload || !targetLinkMapKeys.includes(item.key)) {
        targetLinkMapKeys.push(item.key)
      }
    }
  }

  /**
   * Return an array of Items that are children of the specified key and match the [optional] filter
   * @param key The key of the parent Item
   * @param filter An optional filter function applied to each child Item
   * @returns An array of child Items
   */
  public children<T extends Item>(key:string, filter?: (child:T) => boolean) : T[] {
    const children:T[] = []

    const ckeys = this.childrenMap.get(key)
    if (ckeys !== undefined) {
      // logger.warn("children: key=%s, ckeys=%o", key, ckeys)
      for (const ckey of ckeys) {
        const child = this.itemMap.get(ckey) as T
        if (child && (!filter || filter(child))) {
          children.push(child)
        }
      }
    }

    return children
  }


  /**
   * Return an array of Items that are descendents of the specified key and match the [optional] filter
   * @param key The key of the parent Item
   * @param filter An optional filter function applied to each child Item
   * @param children An array of descendent Items, passed recursively
   * @returns 
   */
  public childrenDeep<T extends Item>(key:string, filter?: (child:T) => boolean, children?:T[]) : T[] {
    children = children ?? []

    const ckeys = this.childrenMap.get(key)
    if (ckeys !== undefined) {
      for (const ckey of ckeys) {
        const child = this.itemMap.get(ckey) as T
        if (child && (!filter || filter(child))) {
          children.push(child)
          this.childrenDeep(ckey, filter, children)
        }
      }
    }

    return children
  }

  public childrenSorted<T extends Item>(key:string) : T[] {
    return this.sortItems(this.children<T>(key))
  }

  /** Return an array of item keys that are immediate descendents of the specified key */
  public childrenKeys(key:string): string[] {
    const ckeys = this.childrenMap.get(key)
    return ckeys ? Array.from(ckeys) : []
  }

  /** Return a set of item keys that are descendents of the specified key */
  public childrenKeysDeep(key:string, keys = new Set<string>()) {    
    const ckeys = this.childrenMap.get(key)
    if (ckeys !== undefined) {
      for (const ckey of ckeys) {
        keys.add(ckey)
        this.childrenKeysDeep(ckey, keys)
      }
    }
    return keys
  }

  /** Return a list of children of the specified key that are of the specified type */
  public childrenOfType<T extends Item>(key:string, typeCodeOrKey:string) : T[] {
    const typeKey = this.getItem(typeCodeOrKey)?.key
    return this.children<T>(key, (child:T) => (child.typeKey === typeKey))
  }

  /**
   * Return a list of children of the specified key, 
   * that are of the specified type or any descendent of that type
   */
  public childrenOfTypeDeep<T extends Item>(key:string, typeCodeOrKey:string) : T[] {
    const typeKey = this.getItem(typeCodeOrKey)?.key
    const typeKeySet = this.childrenKeysDeep(typeKey).add(typeKey)

    return this.children<T>(key, (child:T) => typeKeySet.has(child.typeKey))
  }
 
  public relatedKeys(key:string) {
    const item = this.getItem(key)
    const outboundKeys = item?.links || []
    const inboundKeys  = this.linksMap.get(key) || []
    const childrenKeys = this.childrenMap.get(key) || []

    const keys = new Set<string>(inboundKeys)

    for (const key of childrenKeys) {
      keys.add(key)
    }
    for (const key of outboundKeys) {
      keys.add(key)
    }

    return keys
  }

  /** Return true if item has ancestorKey as a parent/grandparent etc */
  public hasAncestor(item:Item|undefined, ancestorKey:string) : Item|undefined {
    while (item !== undefined && item.code !== ModelCodes.root) {
      if (item.key === ancestorKey) {
        return item
      }
      item = this.itemMap.get(item.parentKey)
    }
  }

  public itemsHaveAncestor(items:Iterable<Item>, ancestorKey:string) : Item|undefined {
    for (const item of items) {
      if (this.hasAncestor(item, ancestorKey)) {
        return item
      }
    }
  }

  /**
   * Determine whether childKey is a child of parentKey
   * @param childKey key of child item
   * @param parentKey key of parent item
   * @param matchParent true if parentKey should be 
   * @returns true if childKey is considered a child of parentKey
   */
  public hasChild(childKey:string, parentKey:string, matchParent=false): boolean { 
    if (matchParent && (childKey === parentKey)) {
      return true
    }  
    const ckeys = this.childrenMap.get(parentKey)
    return ckeys ? ckeys.includes(childKey) : false
  }

  public hasChildDeep(childKey:string, parentKey:string, matchParent=false): boolean { 
    if (matchParent && (childKey === parentKey)) {
      return true
    }  
    const keyMap = this.childrenKeysDeep(parentKey)
    return keyMap.has(childKey)
  }

  public hasChildOfType(key:string, typeKey:string) {
    const children = this.children<Item>(key)
    return children.some((child) => child.typeKey === typeKey)
  }

  public hasChildren(key:string) {
    const ckeys = this.childrenMap.get(key)
    return ckeys ? ckeys.length > 0 : false
  }

  public hasErrors(key:string) {
    return this.errorsMap.has(key)
  }

  public getErrors(key:string) {
    return this.errorsMap.get(key)
  }

  public getErrorsAll() : ModelError[] {
    const results:ModelError[] = []

    for (const errors of this.errorsMap.values()) {
      for (const error of errors) {
        results.push(error)
      }
    }

    return results
  }

  public addError(key:string, item:Item, message:string) {
    let errors = this.errorsMap.get(key)
    if (errors === undefined) {
      errors = []
      this.errorsMap.set(key, errors)
    }

    errors.push({
      key: key,
      message: message,
      item: item
    })
  }

  private clearErrors(key:string) {
    return this.errorsMap.delete(key)
  }

  private validateItem(item:Item) : boolean {
    this.clearErrors(item.key)

    let valid = true
    if (item === undefined) {
      logger.warn("isValid: item is undefined")
      valid = false
    }
    if (item.key === undefined) {
      this.addError(item.key, item, "item.key is undefined")
      valid = false
    }
    if (item.code === "") {
      this.addError(item.key, item, "item.code cannot be blank")
      valid = false
    }
    if (item.status === undefined) {
      this.addError(item.key, item, "item.status is undefined")
      valid = false
    }
    if (item.parentKey === undefined) {
      this.addError(item.key, item, "item.parentKey is undefined")
      valid = false
    }
    if (item.typeKey === undefined && !this.isType(item)) {
      this.addError(item.key, item, "item.typeKey is undefined")
      valid = false
    }
    return valid
  }

  private validateRefKeys(item:Item) {
    if (item.parentKey !== undefined && item.parentKey !== Model.root.key && !this.itemMap.has(item.parentKey)) {
      this.addError(item.key, item, "item.parentKey references missing item")
    }

    if (item.typeKey !== undefined) {
      if (!this.itemMap.has(item.typeKey)) {
        this.addError(item.key, item, "item.typeKey references missing type")
      } else if (!this.typeMap.has(item.typeKey)) {
        this.addError(item.key, item, "item.typeKey references item which is not a type")
      }
    }

    const categoryKey = (item as any).categoryKey
    if (categoryKey !== undefined) {
      if (!this.itemMap.has(categoryKey)) {
        this.addError(item.key, item, "item.categoryKey references missing category")
      } else if (!this.isCategory(categoryKey)) {
        this.addError(item.key, item, "item.categoryKey references item which is not a category")
      }
    }
  }
 
  /**
   * Return an array of all items where Item.typeKey === typeKey
   * @param typeKey 
   */
  public getItemsByType<T extends Item>(typeKey: string) : T[] {
    const keys = this.typeMap.get(typeKey)
    return (keys !== undefined) ? this.toItemArray<T>(keys) : []
  }

  /**
   * Return an array of items for each key in the specified list
   * @param keys
   */
  public toItemArray<T extends Item>(keys? : Iterable<string>): T[] {
    const items:T[] = []
    if (keys) {
      for (const key of keys) {
        const item = this.itemMap.get(key)
        if (item) {
          items.push(item as T)
        }
      }  
    }
    return items
  }

  public toItemMap<T extends Item>(items:Iterable<T>) : Map<string,T> {
    const map = new Map<string,T>()
    for (const item of items) {
      map.set(item.key, item)
    }
    return map
  }

  private removeFromArray(key:string, keys?:string[]) {
    if (keys !== undefined) {
      const index = keys.indexOf(key)
      if (index !== -1) {
        keys.splice(index, 1)
      }
    }
  }

  public getItemKey(code:string) : string {
    return this.getItem(code)?.key as string
  }

  public getItemCode(key:string) {
    return this.getItem(key)?.code
  }

  public getItemName(item:Item | string | undefined) : string {
    if (typeof item === "string") {
      item = this.getItem(item)
    }
    if (item !== undefined) {
      if (item.name !== undefined && item.name !== "") {
        return item.name
      }
      const categoryKey = (item as any).categoryKey
      if (categoryKey !== undefined) {
        return this.getItemName(categoryKey)
      }
      return this.getItemType(item)?.name
    }
    return ""
  }

  public getItemDescription(item:Item | string | undefined) : string {
    if (typeof item === "string") {
      item = this.getItem(item)
    }
    if (item !== undefined) {
      if (item.description && item.description !== "") {
        return item.description as string
      }
      
      const categoryKey = (item as any).categoryKey
      if (categoryKey && categoryKey !== item.key) {
        return this.getItemDescription(categoryKey)
      }

      if (item.typeKey && item.typeKey !== item.key) {
        return this.getItemDescription(item.typeKey)
      }
    }
    return ""
  }

  public getItemIcon(item:Item | string | undefined) : string {
    if (typeof item === "string") {
      item = this.getItem(item)
    }
    if (item !== undefined) {
      const typeIcon = (item as TypeItem).typeIcon
      if (typeIcon && typeIcon !== "") {
        return typeIcon
      }

      const categoryKey = (item as any).categoryKey
      if (categoryKey && categoryKey !== item.key) {
        return this.getItemIcon(categoryKey)
      }

      if (item.typeKey && item.typeKey !== item.key) {
        return this.getItemIcon(item.typeKey)
      }
    }

    return "fal fa-question-circle"
  }

  public getItemParent<T extends Item>(item:Item | string | undefined) : T {
    if (typeof item === "string") {
      item = this.getItem(item)
    }
    return this.getItem<T>(item?.parentKey as string)
  }

  public getItemType(item:Item | string) : TypeItem {
    if (typeof item === "string") {
      item = this.getItem(item)
    }
    if (this.isType(item)) {
      return item as TypeItem
    }
    return this.getItem<TypeItem>(item?.typeKey)
  }

  public getItemTypeKey(key:string) : string {
    return this.getItemType(key)?.key
  }

  public isAccount(item:Item | string) {
    return this.hasAncestor(this.getItemType(item), this.getItemKey(ModelCodes.account.root))
  }

  public isAsset(item:Item | string) {
    return this.hasAncestor(this.getItemType(item), this.getItemKey(ModelCodes.asset.root))
  }

  public isCategory(category:Item | string) {
    if (typeof category === "string") {
      category = this.getItem(category)
    }
    return this.hasAncestor(category, this.getItemKey(ModelCodes.category.root))
  }

  public isFile(item:Item | string) {
    return this.hasAncestor(this.getItemType(item), this.getItemKey(ModelCodes.file.root))
  }

  public isRoot(itemKey:string|undefined) : boolean {
    return itemKey !== undefined && itemKey !== Model.root.key && itemKey !== ""
  }

  public isSetting(item:Item): boolean {
    return this.hasAncestor(item, this.getItemKey(ModelCodes.settings)) !== undefined
  }

  public isTransaction(item:Item | string) {
    return this.hasAncestor(this.getItemType(item), this.getItemKey(ModelCodes.transaction.root))
  }

  public isType(item:Item): boolean {
    return item && item?.key?.startsWith(Model.KeyPrefixType)
  }

  public sortItems<T extends Item>(items:T[]) : T[] {
    return items.sort((i1,i2) => (i1.sortOrder - i2.sortOrder))
  }

  public sortByName<T extends Item>(items:T[]) : T[] {
    return items.sort((i1,i2) => this.getItemName(i1).localeCompare(this.getItemName(i2)))
  }

  public newKey(parentKey:string, prefix?:string) : string {
    // Find the hyphen in the parentKey XX-####
    if (prefix === undefined) {
      const hyphen = parentKey.indexOf("-")
      prefix = (hyphen > 0) ? parentKey.substring(0, hyphen+1) : Model.KeyPrefixPortfolioItem
    }

    // Create the new key
    const newKey = prefix + getUniqueId()
    return newKey
  }

  /**
   * Factory method to create a new item with the specified parent
   * @param parentKey The parentKey for the new item
   * @param typeKey The type key or code for the new item
   */
  public newItem<T extends Item>(parentKey:string, typeKey?:string, prefix?:string): T {
    // Allocate a key for the new item
    const newKey = this.newKey(parentKey, prefix)

    // If type is not defined then use type of parent
    const parent = this.getItem(parentKey)
    if (typeKey) {
      typeKey = this.getItemKey(typeKey)
    } else {
      typeKey = (parent !== undefined) ? parent.typeKey : this.getItemKey(ModelCodes.type.root)
    }

    // Allocate sort order
    const sortOrder = this.newSortOrder(parent)

    // New item
    const item:Item = {
      key: newKey,
      name: "",
      parentKey: parentKey,
      typeKey: typeKey,
      sortOrder: sortOrder,
      status: ItemStatus.NEW,
      modifiedDate: Date.now(),
    }

    // Types do not have a type
    if (this.isType(item)) {
      delete item[ItemProps.typeKey]
    }

    return item as T
  }

  /** Allocate a sortOrder at the end of the parent collection */
  public newSortOrder(parent:Item) {
    let sortOrder = 10
    if (parent && this.hasChildren(parent.key)) {
      const children = this.children(parent.key)
      const maxItem = children.reduce((maxItem, item) => maxItem.sortOrder > item.sortOrder ? maxItem : item)
      sortOrder = maxItem.sortOrder + 10
    }
    return sortOrder
  }

  public static readonly KeyPrefixType = "ZZ-"
  public static readonly KeyPrefixPortfolioItem = "PI-"

  public static readonly root:Item = {
    key: "",
    code: "ROOT",
    name: "ROOT",
    parentKey: "",
    typeKey: "",
    sortOrder: 0,
    status: ItemStatus.TRANSIENT
  }
}

/**
 * Enable compile time checking of property name
 * @param item 
 * @param key 
 */
export function getProperty<T, K extends keyof T>(item: T, key: K) {
  return item[key]  // Inferred type is T[K]
}

export function setProperty<T, K extends keyof T>(item: T, key: K, value: T[K]) {
  if (value === undefined) {
    delete item[key]
  } else {
    item[key] = value
  }
  return item
}


export function setProperties<T>(items:(T|undefined)[], ...props:{ key:keyof T, value:any }[]) {
  for (const item of items) {
    if (item) {
      for (const prop of props) {
        setProperty(item, prop.key, prop.value)
      }
    }
  }
}
