
export interface VersionInfo {
  name: string
  version: string
}

export const AppInfo:VersionInfo = { 
  name:    "Jemstone Money", 
  version: "2024.10.11", 
}
