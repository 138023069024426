/**
 * Collects timing data
 */
export class Timer {
  public name: string
  public last: number = 0
  public count: number = 0
  public total: number = 0
  public average: number = 0
  public min: number = Number.MAX_SAFE_INTEGER
  public max: number = Number.MIN_SAFE_INTEGER

  private _start: number = 0

  constructor(name:string) {
    this.name = name
  }

  start() : number {
    this._start = Date.now()
    this.count++
    return this._start
  }

  finish() : number {
    this.last = Date.now() - this._start
    this.total += this.last
    this.average = this.total / this.count
    this.min = Math.min(this.last, this.min)
    this.max = Math.max(this.last, this.max)
    return this.last
  }

  get key() {
    return this.name
  }
}

const timers = new Map<string,Timer>()

export class Timers {
  public static get(name:string) : Timer {
    let timer = timers.get(name)
    if (!timer) {
      timer = new Timer(name)
      timers.set(name, timer)
    }
    return timer
  }

  public static start(name:string) {
    return this.get(name).start()
  }

  public static finish(name:string) {
    let timer = timers.get(name)
    if (timer) {
      // timers.delete(name)
      return timer.finish()
    }
  }

  public static clear() {
    timers.clear()
  }

  public static get list() {
    return Array.from(timers.values())
  }

  public static sortByName(timers:Timer[]) {
    return timers.sort((t1,t2) => t1.name.localeCompare(t2.name))
  }

  public static sortByNameDesc(timers:Timer[]) {
    return timers.sort((t1,t2) => t2.name.localeCompare(t1.name))
  }

  public static sortByCount(timers:Timer[]) {
    return timers.sort((t1,t2) => t1.count - t2.count)
  }

  public static sortByCountDesc(timers:Timer[]) {
    return timers.sort((t1,t2) => t2.count - t1.count)
  }

  public static sortByAverage(timers:Timer[]) {
    return timers.sort((t1,t2) => t1.average - t2.average)
  }

  public static sortByAverageDesc(timers:Timer[]) {
    return timers.sort((t1,t2) => t2.average - t1.average)
  }

  public static sortByTotal(timers:Timer[]) {
    return timers.sort((t1,t2) => t1.total - t2.total)
  }

  public static sortByTotalDesc(timers:Timer[]) {
    return timers.sort((t1,t2) => t2.total - t1.total)
  }

  public static sortByMin(timers:Timer[]) {
    return timers.sort((t1,t2) => t1.min - t2.min)
  }

  public static sortByMinDesc(timers:Timer[]) {
    return timers.sort((t1,t2) => t2.min - t1.min)
  }

  public static sortByMax(timers:Timer[]) {
    return timers.sort((t1,t2) => t1.max - t2.max)
  }

  public static sortByMaxDesc(timers:Timer[]) {
    return timers.sort((t1,t2) => t2.max - t1.max)
  }
}
