import { FilterProvider } from "../../../hooks/context/FilterContext"
import { InvestmentModelProvider } from "../../../hooks/context/InvestmentModelContext"
import { useModel } from "../../../hooks/context/ModelContext"
import { useFilterBar } from "../../../hooks/filter/useFilterBar"
import { CashflowFilterCard } from "./CashflowFilterCard"

// const logger = new Logger("cards.CashflowFilterBar")

/**
 * The CashflowFilterBar provides an InvestmentModel and a FilterStack for the CashflowFilterCard,
 * using filterBarProviderAtom to specifiy which investment model and filter stack to provide.
 * It is used to enable the CashflowFilterCard to be displayed in the filter bar, outside the main 
 * content area.
 * 
 * @returns <CashflowFilterCard/> wrapped in <FilterProvider/> and <InvestmentModelProvider/>
 */
export function CashflowFilterBar(props:any) {
  const { model } = useModel()
  const { stackName, itemKey, setVisible } = useFilterBar()

  const item = model.getItem(itemKey)

  // logger.setContext(stackName).debug("Providing context=%o", context)

  return (!stackName || !item) ? <></> : (
    <InvestmentModelProvider item={item}>
      <FilterProvider name={stackName}>
        <CashflowFilterCard onClose={() => setVisible(false)}/> 
      </FilterProvider>
    </InvestmentModelProvider>
  )
}