import { Duration, Interval, add, addDays, addMonths, addQuarters, addYears, endOfDay, endOfMonth, endOfQuarter, endOfWeek, endOfYear, startOfDay, startOfMonth, startOfQuarter, startOfWeek, startOfYear, sub, subDays, subMonths, subYears } from "date-fns"
import { formatDate, formatDurationSafe } from './DateFormat'

export type TimeUnits = "1D" | "1W" | "2W" | "1M" | "3M" | "6M" | "1Y" | "2Y" | "3Y" | "5Y" | "10Y" | "20Y" | "30Y"

export const DateRangeCodes = {
  YTD: "Year to Date",
  TY:  "This Year",
  LY:  "Last Year",
  NY:  "Next Year",
  QTD: "Qtr to Date",
  TQ:  "This Qtr",
  LQ:  "Last Qtr",
  NQ:  "Next Qtr",
  MTD: "Month to Date",
  TM:  "This Month",
  LM:  "Last Month",
  NM:  "Next Month",
  YS1: "Span 1 Year",
  YS2: "Span 2 Years",
  YS3: "Span 3 Years",
  YS4: "Span 4 Years",
  YS5: "Span 5 Years",
  YS10: "Span 10 Years",
  YS20: "Span 20 Years",
  YS30: "Span 30 Years",
  DS30: "Span 30 Days",
  DS60: "Span 60 Days",
  DS90: "Span 90 Days",
  DS360: "Span 360 Days",
  L12: "Last 12 Months",
  L3M: "Last 3 Months",
  L1M: "Last 1 Months",
}

export type DateRangeCode = keyof typeof DateRangeCodes

export interface TimeScale extends Interval {
  units: TimeUnits
  start: number
  end: number
}

export const TimeScaleDefault:TimeScale = { units:"1Y", start:0, end:0 }

export function unitsToDuration(units:TimeUnits) : Duration {
  switch (units) {
    case "1D":  return { days:1 }
    case "1W":  return { weeks:1 }
    case "2W":  return { weeks:2 }
    case "1M":  return { months:1 }
    case "3M":  return { months:3 }
    case "6M":  return { months:6 }
    case "1Y":  return { years:1 }
    case "2Y":  return { years:2 }
    case "3Y":  return { years:3 }
    case "5Y":  return { years:5 }
    case "10Y": return { years:10 }
    case "20Y": return { years:20 }
    case "30Y": return { years:30 }
  }
}

export function intervalStarting(units:TimeUnits, start:number, end:number=0) : { start:number, end:number } {
  if (start <= 0) {
    return { start:0, end:0 }
  }
  
  let startDate:Date
  switch (units) {
    case "30Y": 
    case "20Y": 
    case "10Y": 
    case "5Y": 
    case "3Y": 
    case "2Y": 
    case "1Y": 
      startDate = startOfYear(start)
      break

    case "6M":
      startDate = startOfHalf(start)
      break

    case "3M":
      startDate = startOfQuarter(start)
      break

    case "1M":
      startDate = startOfMonth(start)
      break

    case "2W":
    case "1W":
      startDate = startOfWeek(start, { weekStartsOn: 1 })
      break

    case "1D":
      startDate = startOfDay(start)
      break
  }

  const duration = unitsToDuration(units)
  const endDate = add(startDate, duration).getTime() - 1

  return { start: startDate.getTime(), end: endDate }
}

export function intervalEnding(units:TimeUnits, date:number) : { start:number, end:number } {
  let endDate:Date
  switch (units) {
    case "30Y": 
    case "20Y": 
    case "10Y":
    case "5Y": 
    case "3Y": 
    case "2Y": 
    case "1Y": 
      endDate = endOfYear(date)
      break

    case "6M":
    case "3M":
      endDate = endOfQuarter(date)
      break

    case "1M":
      endDate = endOfMonth(date)
      break

    case "2W":
    case "1W":
      endDate = endOfWeek(date, { weekStartsOn: 1 })
      break

    case "1D":
      endDate = endOfDay(date)
      break
  }

  const duration = unitsToDuration(units)
  const startDate = sub(endDate.getTime()+1, duration)

  // console.debug("intervalEnding: start=%s, end=%s, duration=%o", startDate, endDate, duration)

  return { start:startDate.getTime(), end:endDate.getTime() }
}

export function endOfHalf(date: Date | number) {
  const quarter = endOfQuarter(date)
  const month = quarter.getMonth()

  // If the quarter ends in March or September, then advance to June or December respectively 
  if (month === 2 || month === 8) {
    return endOfQuarter(quarter.getTime()+1)
  }
  return quarter
}

export function startOfHalf(date: Date | number) {
  const quarter = startOfQuarter(date)
  const month = quarter.getMonth()

  // If the quarter starts in March or September, then reverse to June or December respectively 
  if (month === 2 || month === 8) {
    return startOfQuarter(quarter.getTime()-1)
  }
  return quarter
}

export function timeScaleSnap(scale: TimeScale) : TimeScale { 
  let { start, end, units } = scale
  start = intervalStarting(units, start).start

  return { start, end, units }
}

export function timeScaleStarting(units:TimeUnits, start:number, end:number=0) : TimeScale {  
  return {...intervalStarting(units, start, end), units: unitsDrilldown(units) }
}

export function timeScaleEnding(units:TimeUnits, end:number) : TimeScale {  
  return {...intervalEnding(units, end), units: unitsDrilldown(units) }
}

export function timeScaleYTD() : TimeScale {
  return timeScale("YTD")
}

export function timeScale(code: DateRangeCode, date = Date.now(), minDate=0): TimeScale {
  switch (code) {
    case "YTD": return getYearsToDate(date, 0)
    case "TY":  return getYearScale(date)
    case "NY":  return getYearScale(addYears(date, 1))
    case "LY":  return getYearScale(addYears(date, -1))

    case "MTD": return getMonthsToDate(date, 0)
    case "TM":  return getMonthScale(date)
    case "NM":  return getMonthScale(addMonths(date, 1))
    case "LM":  return getMonthScale(addMonths(date, -1))

    case "QTD": return {...getQuarterScale(date), end: endOfDay(date).getTime() }
    case "TQ":  return getQuarterScale(date)
    case "NQ":  return getQuarterScale(addQuarters(date, 1))
    case "LQ":  return getQuarterScale(addQuarters(date, -1))

    case "YS1":  return getYearSpan(date, 1, minDate)
    case "YS2":  return getYearSpan(date, 2, minDate)
    case "YS3":  return getYearSpan(date, 3, minDate)
    case "YS4":  return getYearSpan(date, 4, minDate)
    case "YS5":  return getYearSpan(date, 5, minDate)
    case "YS10": return getYearSpan(date, 10, minDate)
    case "YS20": return getYearSpan(date, 20, minDate)
    case "YS30": return getYearSpan(date, 30, minDate)

    case "DS30":  return getDaySpan(date, 30)
    case "DS60":  return getDaySpan(date, 60)
    case "DS90":  return getDaySpan(date, 90)
    case "DS360": return getDaySpan(date, 360)

    case "L12": return getMonthsToDate(date, 12)
    case "L3M": return getMonthsToDate(date, 3)
    case "L1M": return getMonthsToDate(date, 1)
  }
}

export function getMonthScale(date:Date|number) : TimeScale {
  return { units:"1M", start: startOfMonth(date).getTime(), end: endOfMonth(date).getTime() }
}

export function getQuarterScale(date:Date|number) : TimeScale {
  return { units:"1M", start: startOfQuarter(date).getTime(), end: endOfQuarter(date).getTime() }
}

export function getYearScale(date:Date|number) : TimeScale {
  return { units:"1M", start: startOfYear(date).getTime(), end: endOfYear(date).getTime() }
}

export function getDaysToDate(date:Date|number, days:number) : TimeScale {
  const start = startOfDay(subDays(date, days)).getTime()
  const end = endOfDay(date).getTime()
  return { units:"1D", start, end }
}

export function getMonthsToDate(date:Date|number, months:number) : TimeScale {
  const start = startOfMonth(subMonths(date, months)).getTime()
  const end = endOfDay(date).getTime()
  return { units:"1M", start, end }
}

export function getYearsToDate(date:Date|number, years:number) : TimeScale {
  const start = startOfYear(subYears(date, years)).getTime()
  const end = endOfDay(date).getTime()
  return { units:"1M", start, end }
}

export function getYearSpan(date:Date|number, years:number, minDate=0) : TimeScale {
  let start = 0
  let units:TimeUnits = "1M"

  if (years >= 5) {
    start = startOfYear(subYears(date, Math.floor(years/2))).getTime()
    units = (years >= 10) ? "1Y" : "3M"
  } else if (years > 3) {
    start = startOfYear(subYears(date, 2)).getTime()
  } else if (years > 1) {
    start = startOfQuarter(subYears(date, 1)).getTime()
  } else if (years > 0) {
    start = startOfMonth(subMonths(date, 6)).getTime()
  }

  if (start < minDate) {
    start = minDate
  }

  const end = addYears(start, years).getTime() - 1

  return { start, end, units }
}

export function getDaySpan(date:Date|number, days:number, minDate=0) : TimeScale {
  const start = startOfDay(subDays(date, Math.floor(days/2))).getTime()
  const end = endOfDay(addDays(start, days)).getTime()

  return { start, end, units:"1D" }
}

function unitsDrilldown(units:TimeUnits) : TimeUnits {  
  switch (units) {
    case "30Y": 
      return "5Y"
      
    case "20Y": 
    case "10Y": 
      return "1Y"

    case "5Y": 
    case "3Y": 
      return "3M"
    
    case "2Y": 
    case "1Y": 
      return "1M"
    
    case "6M":
    case "3M":
      return "1W"
      
    case "1M":
    case "2W":
    case "1W":
    case "1D":
      return "1D"
  }
}

export function formatUnits(units:TimeUnits|undefined) {
  const duration = units && unitsToDuration(units)
  return formatDurationSafe(duration)
}

export function formatInterval(interval: { start:number, end:number }, dateFormat?:string) {
  return formatDateRange(interval.start, interval.end, dateFormat)
}

export function formatDateRange(startDate:number, endDate:number, dateFormat?:string) {
  return [ formatDate(startDate, dateFormat), formatDate(endDate, dateFormat) ]
}

export function formatDateRangeCode(code:DateRangeCode) {
  const ts = timeScale(code)
  switch (code) {
    case "YTD":
    case "QTD":
    case "MTD":
      return code

    case "L12": return "-12M"
    case "L3M": return "-3M"
    case "L1M": return "-1M"

    case "TY":
    case "NY":
    case "LY":
      return formatDate(ts.start, "yyyy")

    case "TM":
    case "NM":
    case "LM":
      return formatDate(ts.start, "MMM-yy")

    case "TQ":
    case "NQ":
    case "LQ":
      return formatDate(ts.end, "MMM-yy")
  }
}

/** 
 * Get the preferred date format to use for the specified scale 
 */
export function getDateFormat(scale:TimeScale) : string {
  const duration = unitsToDuration(scale.units)

  // logger.debug("getDateFormat: units=%s, range={%s}, duration=%o, duration1=%o", 
  //              scale.units, Logger.Range(scale.start, scale.end), duration, duration1)

  const dateFormat = (duration.years)  ? "yyyy" :
                     (duration.months) ? "MMM-yy" : "dd-MMM-yy"
  return dateFormat
}
