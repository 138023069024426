import { useFilterContext } from "../../../hooks/context/FilterContext"

export function ButtonPopFilter(props:{
  icon?: string
  title?: string
}) {
  const { filterStack, canPop } = useFilterContext()
  const { icon } = props

  return (
    <button onClick={onClick}>
      <i className={canPop ? "far fa-chevron-left" : icon} />
    </button>
  )

  function onClick(e:any) {
    e.stopPropagation()
    if (canPop) {
      filterStack.pop()
    }
  }
}