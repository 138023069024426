import { startOfDay } from "date-fns"
import { useState } from "react"
import { isMobileOnly } from "react-device-detect"
import { ModelKeys } from "../../../core/model/ModelKeys"
import { TransactionItem } from '../../../core/types/TransactionItem'
import { Logger } from "../../../core/utils/Logger"
import { useInvestmentModel } from '../../../hooks/context/InvestmentModelContext'
import { useCardOpen } from '../../../hooks/useCardOpen'
import { Card, CardSection } from '../../controls/Card'
import { setDialogItem } from '../../controls/dialog/ItemDialog'
import { CardHeaderWithMenu } from '../CardHeaderWithMenu'
import { TransactionItemsTable } from './TransactionItemsTable'
import { TransactionItemsTree } from './TransactionItemsTree'

const logger = new Logger("TransactionItemsCard")

export function TransactionItemsCard(props:{
  title: string
  open?: boolean
  tree?: boolean
  menuRootKey?: string
  className?: string
}) {
  const { model, item } = useInvestmentModel()
  const { title, open, className } = props
  const { isOpen } = useCardOpen(logger.name, item.key, open === true)
  const [ tree, setTree ] = useState(props.tree && !isMobileOnly)

  logger.debug("Rendering")

  return (
    <Card id={logger.name} open={isOpen} className={className}>
      <CardHeaderWithMenu cardName={logger.name} title={title} icon="fal fa-list" showChildren>
        <button onClick={() => setTree(!tree)}>
          <i className={tree ? "fal fa-table" : "fal fa-table-tree"} />
        </button>
        <button onClick={onCreate} title={"Add transaction to " + item.name}>
          <i className="far fa-plus" />
        </button>
      </CardHeaderWithMenu>
      <CardSection>
        {!tree && <TransactionItemsTable />}
        { tree && <TransactionItemsTree />}
      </CardSection>
    </Card>
  )

  function onCreate() {
    logger.debug("onCreate: parent=%s", item.name)

    // Create a new transaction, default to a credit (expense)
    const newItem = model.newItem<TransactionItem>(item.key, ModelKeys.transaction.moneyOut)
    newItem.startDate = startOfDay(Date.now()).getTime()

    // Open the transaction for editing
    setDialogItem(newItem, true)
  }
}
