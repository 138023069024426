import { Link } from "react-router-dom"
import { useFilesModel } from "../../hooks/useFilesModel"
import { AppInfo } from "../../VersionInfo"
import { ButtonSidebar } from "../controls/buttons/ButtonSidebar"
import { UserMenu } from "../views/ViewMenu"

export function JemstoneHeader({title, children}:{
  title?: any
  children?: any
}) {
  const { currentFile } = useFilesModel()
  const tooltip = `${AppInfo.name} ${AppInfo.version}`

  return (
    <header>
      <ButtonSidebar/>
      <Link to="/" title={tooltip}/>
      
      <h1>{title ?? currentFile?.name}</h1>

      <nav>
        {children}
        {!children &&
          <menu>
            v{AppInfo.version}
            <UserMenu/>
          </menu>
        }
      </nav>
    </header>
  )
}
