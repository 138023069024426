import { getInvestmentModel } from "../atom/InvestmentModelAtoms"
import { Model } from "../model/Model"
import { ModelKeys } from "../model/ModelKeys"
import { PropertyItem } from "../types/PropertyItem"
import { ValuationItem } from "../types/ValuationItem"
import { PortfolioModel } from "./PortfolioModel"
import { ValuationCalculator } from "./ValuationCalculator"
import { ValuationModel } from "./ValuationModel"

export class PropertyModel extends PortfolioModel<PropertyItem> {
  constructor(model:Model, item:PropertyItem) {
    super(model, item)
    this.logger.setName("models.PropertyModel").debug("Created new PropertyModel for %s", this.item.name) 
  }

  get start() : number {
    return this.asset.purchaseDate ?? (this.valuationCalc?.firstDate ?? 0)
  }

  get finish() : number {
    return this.asset.sellDate ?? super.finish
  }

  get valuationItems() : ValuationItem[] {
    return this.valuationCalc?.valuations || []
  }
 
  /** Get the ValuationCalculator for this asset - note we assume there is only one */
  get valuationCalc() : ValuationCalculator | undefined {
    const valuationItems = this.model.childrenOfType<ValuationItem>(this.item.key, ModelKeys.type.valuation)
    if (valuationItems.length > 0) {
      const valuationModel = getInvestmentModel<ValuationModel>(valuationItems[0].key)
      return valuationModel?.valuationCalc
    }
    return undefined
  }
}
