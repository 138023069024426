import { useState } from "react"
import { getAllowedChildTypeKeys } from "../../../core/model/ModelKeys"
import { Item } from "../../../core/types/Item"
import { Logger } from "../../../core/utils/Logger"
import { clzName, toCamelCase } from "../../../core/utils/Utils"
import { useFormData, useFormItem } from "../../../hooks/context/FormContext"
import { useModel } from "../../../hooks/context/ModelContext"
import { Card, CardSection } from "../../controls/Card"
import { FormDataJson } from "../../forms/FormDataJson"
import { useWizard } from "../context/WizardContext"
import { WizardData } from "../WizardData"
import { WizardHeader } from "../WizardHeader"
import { WizardMenuListItem } from "../WizardMenu"

const logger = new Logger("wizards.SelectAdditionalItems")

export function SelectAdditionalItems() {
  const { model } = useModel()
  const { primaryItemProp } = useWizard()
  const [ primaryItem ] = useFormItem(primaryItemProp)

  const primaryItemType = model.getItemType(primaryItem)?.name
  const allowedChildTypes = model.toItemArray(getAllowedChildTypeKeys(primaryItem?.typeKey))

  model.sortByName(allowedChildTypes)

  logger.debug("Rendering: allowedChildTypes=%o", allowedChildTypes)

  const title = `Select Additional Items`
  const subtitle = `Please select any additional items you would like to create for your new ${primaryItemType} - you can select many`

  return (
    <Card id="SelectAdditionalItems" className="wizard-page">
      <WizardHeader title={title} subtitle={subtitle} />
      <CardSection className="wizard-menu">
        { allowedChildTypes.map(typeItem =>
          <MenuItem typeItem={typeItem} primaryItem={primaryItem} key={typeItem.key}/>
        )}
      </CardSection>
      <FormDataJson/>
    </Card>
  )
}

function MenuItem({ typeItem, primaryItem } : { typeItem:Item, primaryItem:Item }) {
  const { model } = useModel()
  const { formItemMap, setFormItem } = useFormData<WizardData>()
  const [ selected, setSelected ] = useState(formItemMap.has(typeItem.key))

  // logger.debug("MenuItem: type=%s, selected=%s", typeItem.name, selected)

  return (
    <WizardMenuListItem 
      icon={model.getItemIcon(typeItem)}
      title={model.getItemName(typeItem)}
      subtitle={model.getItemDescription(typeItem)}
      active={selected}
      onClick={onSelectType} 
    />
  )

  function onSelectType(e:any) {
    const isAccount = model.isAccount(typeItem)
    const formItemKey = toCamelCase(clzName(typeItem.name, { "Account": isAccount }, "Item"))

    // If the selected item doesn't exist then create it
    const formItem = formItemMap.get(formItemKey)
    if (formItem === undefined) {
      const newItem = model.newItem(primaryItem.key, typeItem.key)
      setFormItem(formItemKey, newItem)
    } 
    
    // Otherwise remove it (and all children of it)
    else {
      setFormItem(formItemKey, undefined)
    }

    // Toggle the selected status
    setSelected(!selected)

    logger.debug("Selected formItemKey=%s, formItems=%o", formItemKey, formItemMap)
  }
}
