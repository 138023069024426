import { formatDistance } from 'date-fns'
import { ValuationModel } from '../../../core/invest/ValuationModel'
import { NumberFormats, formatAssetValue, formatNumber } from '../../../core/utils/Numbers'
import { useInvestmentModel } from '../../../hooks/context/InvestmentModelContext'

export function AssetPriceNarrative(props: any) {
  const { asset, model, valuationCalc, isUnitised } = useInvestmentModel<ValuationModel>()
  const description = asset.description ?? model.getItemType(asset).name

  if (valuationCalc === undefined || valuationCalc.count < 2) return (
    <p id="AssetPriceNarrative">
      {asset.name} is a {description} that has historically provided 
      an annual growth rate (CAGR) of <b>{formatNumber(asset.rate, NumberFormats.percent1)} per year</b>.
    </p>
  )

  return (
    <p id="AssetPriceNarrative">
      The {isUnitised ? "unit price" : "capital value"} of {asset.name} has
      <b> {valuationCalc.increase >= 0 ? "increased" : "decreased"} by {formatAssetValue(valuationCalc.increase)} ({formatNumber(valuationCalc.increasePercent, NumberFormats.percent0)}) </b>
      in {formatDistance(valuationCalc.lastDate, valuationCalc.firstDate)}, a compound annual growth rate (CAGR)
      of <b>{formatNumber(valuationCalc.cagr, NumberFormats.percent1)} per year</b>.
    </p>
  )
}
