import { PrimitiveAtom, atom, useAtom, useAtomValue } from "jotai"
import { Logger } from "../core/utils/Logger"

const logger = new Logger("hooks.useAtomKey")

const atomMap = new Map<string,PrimitiveAtom<any>>()

function getAtom<T extends any>(atomKey:string, initValue:T) {
  if (!atomMap.has(atomKey)) {
    atomMap.set(atomKey, atom<T>(initValue))
  }
  return atomMap.get(atomKey) as PrimitiveAtom<T>
}

export function useAtomKey<T>(atomKey:string, initValue?:T) {
  try {
    const atom = getAtom(atomKey, initValue)
    return useAtom(atom)
  } catch (e:any) {
    const message = logger.error("useAtomKey", "Unable to get atomKey=%s, initValue=%s", atomKey, initValue)
    throw Error(message, { cause:e })
  }
}

export function useAtomKeyValue(atomKey:string) {
  const atom = getAtom(atomKey, null)
  return useAtomValue(atom)
}

export function resetAtomKeys() {
  atomMap.clear()
}