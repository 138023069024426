export function ButtonClose({ label, onClose }:{
  label?: string,
  onClose: () => void
}) {
  return (
    <button className="cancel" onClick={onClose}>
      <i className="far fa-times" />
      { label }
    </button>
  )
}