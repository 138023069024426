import { atom, useAtom, useAtomValue } from 'jotai'
import { Counter } from '../../../../core/utils/Counters'
import { Logger } from "../../../../core/utils/Logger"
import { NumberFormats, formatNumber } from '../../../../core/utils/Numbers'
import { Card, CardHeader, CardSection } from '../../../controls/Card'
import { ButtonOpen } from '../../../controls/buttons/ButtonOpen'
import { SearchBox } from '../../../search/SearchBox'
import { CountersCardAtoms } from '../../../../hooks/atoms/CountersCardAtoms'

const logger = new Logger("cards.CountersCard")

const openAtom = atom(false)

export function CountersCard(props:any) {
  const [isOpen, setOpen] = useAtom(openAtom)

  logger.trace("Rendering:")

  return (
    <Card id="CountersCard" open={isOpen}>
      <CardHeader>
        <i className="fal fa-tally" />
        <h2 onClick={() => setOpen(!isOpen)}>
          Counters
        </h2>
        <menu onClick={(e) => e.stopPropagation()}>
          { isOpen &&
            <SearchBox searchValueAtom={CountersCardAtoms.searchValue} />
          }
          <ButtonOpen openAtom={openAtom} />
        </menu>
      </CardHeader>
      <CardSection>
        <CountersTable/>
      </CardSection>
    </Card>
  )

  function CountersTable(props:any) {
    const [ sortOrder, setSortOrder ] = useAtom(CountersCardAtoms.sortOrder)
    const counters = useAtomValue(CountersCardAtoms.sortedCounters)

    logger.trace("CountersTable: sortOrder=%s, %d timers", sortOrder, counters.length)
  
    return (
      <table className="table sticky-headers">
        <thead>
          <tr>
            <SortButton label="NAME" alpha={true} />
            <SortButton label="COUNT" />
            <SortButton label="TOTAL" />
            <SortButton label="AVERAGE" />
            <SortButton label="MIN" />
            <SortButton label="MAX" />
          </tr>
        </thead>
        <tbody>
          { counters.map(counter => <CounterRow counter={counter} key={counter.name} />)}
        </tbody>
      </table>
    )

    function SortButton({ label, alpha } : { label:string, alpha?:boolean }) {
      const showIcon = sortOrder.startsWith(label)
      return (
        <th className="link" onClick={() => setSortOrder(sortOrder === label ? `${label}-DESC` : label)}>
          { showIcon &&
            <i className={`fal fa-sort-${alpha ? "alpha" : "numeric"}-${sortOrder === label ? "down" : "up"}`} />
          }
          { label }
        </th>
      )
    }

    function CounterRow({counter} : {counter:Counter}) {
      return (
        <tr>
          <td>{counter.name}</td>
          <td>{formatNumber(counter.count, NumberFormats.integer)}</td>
          <td>{formatNumber(counter.total, NumberFormats.integer)}</td>
          <td>{formatNumber(counter.average, NumberFormats.integer)}</td>
          <td>{formatNumber(counter.min, NumberFormats.integer)}</td>
          <td>{formatNumber(counter.max, NumberFormats.integer)}</td>
        </tr>
      )
    }
  }
}

