import { getModel } from "../atom/ModelAtoms"
import { Model, setProperty } from "../model/Model"
import { ModelKeys } from "../model/ModelKeys"
import { Item, ItemStatus } from "../types/Item"
import { TransactionItem } from '../types/TransactionItem'
import { Logger } from "../utils/Logger"
import { isEqual } from "../utils/Utils"
import { saveItems } from "./ModelService"

const logger = new Logger("core.ModelAction")

/**
 * Create list of items specified
 * @param model
 * @param parentKey The parent item for the new items
 * @param newItems 
 */
export function createItems<T extends Item>(model:Model, newItems:T[]=[]) {
  logger.start("createItems", "Creating %d items:", newItems.length)

  // Clone and set the status
  newItems = newItems.map(item => clone(item, ItemStatus.NEW))

  // Update the model
  saveItems(newItems)

  logger.finish("createItems", "Created %d items", newItems.length)
}

/**
 * Paste a list of items specified into the model, noting that this could be a flattened 
 * hierarchy and because new key values must be allocated we also need to reallocate 
 * parentKey values right thru the hierarchy.
 * 
 * @param model
 * @param parentKey The parent item for the new items
 * @param newItems 
 */
export function pasteItems<T extends Item>(model:Model, parentKey:string, items:T[]) {
  logger.start("pasteItems", "Pasting %d items to parentKey=%s", items.length, parentKey)

  // If supplied parentKey is not a valid item, use the recycle bin
  if (!model.has(parentKey)) {
    parentKey = ModelKeys.recycleBin
  }

  // Add items to a map, cloning those that already exist in the model
  const itemMap = new Map<string,Item>()
  for (const item of items) {
    const itemKey = item.key

    if (model.has(itemKey)) {
      const key = model.newKey(item.parentKey)
      itemMap.set(itemKey, {...item, key, status:ItemStatus.NEW })
    } else {
      itemMap.set(itemKey, item)
    }
  }

  // Allocate a new parentKey to items that (a) whose parent is not being pasted
  // or (b) whose parent has a new key
  for (const item of itemMap.values()) {
    const parentItem = itemMap.get(item.parentKey)
    item.parentKey = parentItem?.key ?? parentKey
  }

  // Update the model
  saveItems(Array.from(itemMap.values()))

  logger.finish("pasteItems", "Pasted %d items", items.length)
}

/**
 * Remove items with the specified keys from the model and post to server
 * @param model 
 * @param keys
 */
export function removeItems(model:Model, keys:Iterable<string>) {
  logger.start("removeItems", "Removing items")

  // Ensure we delete all children of specified keys
  const keySet = new Set<string>()
  for (const key of keys) {
    keySet.add(key)
    model.childrenKeysDeep(key, keySet)
  }

  logger.debug("1. Removing %d items", keySet.size, keySet)

  // Also delete any related transaction
  for (const key of keySet) {
    const relatedTransactionKey = model.getItem<TransactionItem>(key)?.relatedTransactionKey
    if (relatedTransactionKey) {
      keySet.add(relatedTransactionKey)
    }
  }

  logger.debug("2. Removing %d items=%o", keySet.size, keySet)

  // Build array of items to be deleted
  const deleteItems:Item[] = []
  for (const key of keySet) {
    const item = model.getItem(key)
    if (item !== undefined) {
      deleteItems.push(clone(item, ItemStatus.DELETED))
    }
  }

  logger.debug("3. Removing %d items=%o", deleteItems.length, deleteItems)

  // Update the model
  saveItems(deleteItems)

  logger.finish("removeItems", "Removed %d items", deleteItems.length)
}

/**
 * Update specified attribute of specified item(s) in the model and post to server
 * @param model 
 * @param keys 
 * @param property 
 * @param value 
 */
export function updateItems<T extends Item>(model:Model, keys:string[], property:keyof T, value:any)
{
  return updateItemsX(model, keys, { key:property, value:value })
}

export function updateItemsX<T extends Item>(
  model:Model, keys:string[], ...props:{ key:keyof T, value:any }[])
{
  logger.start("updateItemsX", "Updating %d items", keys.length)

  // Update properties in related transaction
  const newKeys:string[] = []
  for (const key of keys) {
    newKeys.push(key)
    
    const item = model.getItem<TransactionItem>(key)
    if (item && item.relatedTransactionKey && model.has(item.relatedTransactionKey)) {
      newKeys.push(item.relatedTransactionKey)
    }
  }
  
  // Create new item with modified properties for each key
  const newItems:T[] = []
  for (const key of newKeys) {
    // Clone old item
    const item = model.getItem<T>(key)
    const newItem = clone(item, ItemStatus.MODIFIED)
    newItems.push(newItem)

    // Update properties as specified in Event.props
    for (const prop of props) {
      setProperty(newItem, prop.key, prop.value)
    }

    logger.debug("handleUpdate: key=%s, item, newItem:", key, item, newItem)
  }

  // Update the model
  saveItems(newItems)

  logger.finish("updateItemsX", "Updated %d items", newKeys.length)
}

/**
 * Save all form items that are new or modified
 */
export function saveModifiedItems(items:Iterable<Item>) {
  const model = getModel()

  // Filter only those items that are new or modified
  const modifiedItems:Item[] = []
  for (const item of items) {
    if (!isEqual(item, model.getItem(item.key))) {
      modifiedItems.push(item)
    }
  }

  logger.debug("saveModifiedItems: %d modified items", modifiedItems.length)

  // Save all items that have been modified
  saveItems(modifiedItems)
}

/**
 * Sort item with the specified key (either UP,DOWN,ALPHA) and post to server
 * @param model 
 * @param keys
 */
export function sortItem(model:Model, key:string, direction: "UP" | "DOWN" | "ALPHA") {
  const item = model.getItem(key)
  if (!item) {
    logger.warn("sortItem: itemKey=%s does not exist", key)
    return []
  }

  logger.start("sortItem", "item=%o, direction=%s", item, direction)

  const newItems:Item[] = []
  const siblings = model.childrenSorted(item.parentKey)
  const index = siblings.findIndex(sibling => (sibling.key === item.key))

  if (direction === "ALPHA") {
    // Sort siblings by name
    model.sortByName(siblings)
  
    // Recalc sortOrder for all siblings, while cloning the siblings
    let sortOrder = 10000
    const increment = 10
    for (const sibling of siblings) {
      const newItem = clone(sibling, ItemStatus.MODIFIED)
      newItem.sortOrder = (sortOrder += increment)
      newItems.push(newItem)
    }
  } else {
    // Swap sortOrder for specified item according to 'direction'
    const swapIndex = index + (direction === "UP"  ? -1 :
                              (direction === "DOWN" ? 1 : 0))
    if (swapIndex >= 0 && swapIndex < siblings.length) {
      const newItem1 = {...siblings[index], sortOrder:siblings[swapIndex].sortOrder }
      const newItem2 = {...siblings[swapIndex], sortOrder:siblings[index].sortOrder }
      newItems.push(newItem1, newItem2)
    }
  }

  // Update the model
  saveItems(newItems)

  logger.finish("sortItem", "Updated %d items, direction=%s", newItems.length, direction)
}

export function sortRenumber(model:Model, key:string) {
  const item = model.getItem(key)
  if (!item) {
    logger.warn("sortItem: itemKey=%s does not exist", key)
    return []
  }

  logger.start("sortRenumber", "item=%o", item)

  const newItems:Item[] = []
  const siblings = model.childrenSorted(item.parentKey)

  // Recalc sortOrder for all siblings, while cloning the siblings
  let sortOrder = 10000
  const increment = 10
  for (const sibling of siblings) {
    const newItem = clone(sibling, ItemStatus.MODIFIED)
    newItem.sortOrder = (sortOrder += increment)
    newItems.push(newItem)
  }

  // Update the model
  saveItems(newItems)

  logger.finish("sortRenumber", "Updated %d items", newItems.length)
}

export function promoteItems(model:Model, keys:string[]) {
  const parentKey = model.getItem(keys[0])?.parentKey
  const newParentKey = model.getItem(parentKey).parentKey

  logger.start("promoteItems", "parentKey='%s', newParentKey='%s':", parentKey, newParentKey)

  const newItems:Item[] = []

  if (newParentKey !== undefined) {
    // Only include siblings of the first item
    for (const key of keys) {
      const item = model.getItem(key)
      if (item.parentKey !== undefined && item.parentKey === parentKey) {
        const newItem = clone(item, ItemStatus.MODIFIED)
        newItem.parentKey = newParentKey
        newItems.push(newItem)
      }
    }
  }

  // Update the model
  saveItems(newItems)

  logger.finish("promoteItems", "Updated %d items", newItems.length)
}

export function demoteItems(model:Model, keys:string[]) {
  logger.start("demoteItems", "keys=%o", keys)

  const itemKey = keys[0]
  const parentKey = model.getItem(itemKey)?.parentKey
  const newItems:Item[] = []

  if (parentKey !== undefined) {
    const siblings = model.childrenSorted(parentKey)
    const index = siblings.findIndex(sibling => (sibling.key === itemKey))
    
    if (index > 0) {
      const newParentKey = siblings[index-1].key

      // Only include siblings of the first item
      for (const key of keys) {
        const item = model.getItem(key)
        if (item.parentKey === parentKey) {
          const newItem = clone(item, ItemStatus.MODIFIED)
          newItem.parentKey = newParentKey
          newItems.push(newItem)
        }
      }
    }
  }

  // Update the model
  saveItems(newItems)

  logger.finish("demoteItems", "Updated %d items", newItems.length)
}

function clone<T extends Item>(item:T, status = ItemStatus.MODIFIED): T {
  return {...item,
    status: status,
    modifiedDate: Date.now(),
  }
}
