import { atom } from 'jotai'
import { BankStmtModelAtoms } from '../../core/atom/BankStmtModelAtoms'
import { ModelAtoms } from '../../core/atom/ModelAtoms'
import { Progress } from "../../core/builder/Progress"
import { RankedSearchOptions, Rankings } from '../../core/model/RankedSearch'
import { BankStmtRuleItem } from '../../core/types/BankStmtRuleItem'
import { Item, ItemProps } from '../../core/types/Item'
import { Logger } from "../../core/utils/Logger"
import { SearchAtoms } from '../atoms/SearchAtoms'
import { getAtomValue, setAtomValue } from '../../core/atom/AtomUtils'

const logger = new Logger("atoms.BankStmtRuleAtoms")

export class BankStmtRuleAtoms {
  public static sortOrder = atom("PRI-DOWN")
  public static searchValue = atom("")

  public static reset() {
    setAtomValue(BankStmtRuleAtoms.searchValue, "")
    getAtomValue(BankStmtRuleAtoms.sortedRules)
  }

  public static filteredRules = atom<BankStmtRuleItem[]>(
    (get) => {
      let rules = get(BankStmtModelAtoms.model)?.rules
      if (!rules) {
        return []
      }

      let length = rules.length
    
      const searchValue = get(BankStmtRuleAtoms.searchValue)
      const searchOptions = get(BankStmtRuleAtoms.searchOptions)
      if (searchValue && searchValue.length > 0) {
        rules = SearchAtoms.matchItems(rules, searchValue, searchOptions)
      }

      logger.debug("filteredRulesAtom.get: %d/%d rules, searchValue='%s'", 
                    rules.length, length, searchValue)
      return rules
    }
  )

  public static sortedRules = atom<BankStmtRuleItem[]>(
    (get) => {
      let rules = Array.from(get(BankStmtRuleAtoms.filteredRules))
      if (!rules) {
        return []
      }
      
      const model = get(ModelAtoms.modelAtom).model
      const sortOrder = get(BankStmtRuleAtoms.sortOrder)
      switch (sortOrder) {
        case "SEQ-UP":
          rules.sort((r1, r2) => r2.sortOrder - r1.sortOrder)
          break
        case "SEQ-DOWN":
          rules.sort((r1, r2) => r1.sortOrder - r2.sortOrder)
          break
        case "PRI-UP":
          rules.sort((r1, r2) => (r2.priority - r1.priority) || (r2.sortOrder - r1.sortOrder))
          break
        case "PRI-DOWN":
          rules.sort((r1, r2) => (r1.priority - r2.priority) || (r1.sortOrder - r2.sortOrder))
          break
        case "KEY-UP":
          rules.sort((r1, r2) => r2.name.localeCompare(r1.name))
          break
        case "KEY-DOWN":
          rules.sort((r1, r2) => r1.name.localeCompare(r2.name))
          break
        case "CAT-UP":
          rules.sort((r1, r2) => model.getItemName(r2.categoryKey).localeCompare(model.getItemName(r1.categoryKey)))
          break
        case "CAT-DOWN":
          rules.sort((r1, r2) => model.getItemName(r1.categoryKey).localeCompare(model.getItemName(r2.categoryKey)))
          break
      }

      logger.debug("sortedRulesAtom.get: %d rules, sortOrder=%s", rules.length, sortOrder)
      return rules
    }
  )

  public static searchOptions = atom<RankedSearchOptions<Item>>(
    (get) => {
      const { CONTAINS, WORD_STARTS_WITH } = Rankings
      const { model } = get(ModelAtoms.modelAtom)
      
      return { 
        props: [
          { prop: ItemProps.name,        threshold: WORD_STARTS_WITH },
          { prop: ItemProps.description, threshold: CONTAINS },
          { prop: ItemProps.sortOrder,   threshold: WORD_STARTS_WITH },
          { prop: (item:any) => model.getItemName(item.categoryKey), threshold: WORD_STARTS_WITH },
          { prop: (item:any) => model.getItemName(item.typeKey), threshold: WORD_STARTS_WITH },
        ], 
      }
    }
  )

  public static progress = atom<Progress>({
    total: 0,
    count: 0,
  })

  public static progressStatus = atom<"Started" | "Finished" | undefined>(undefined)
}
