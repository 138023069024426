import { useEffect, useState } from 'react'
import { getProperty } from '../../../core/model/Model'
import { updateItems } from '../../../core/service/ModelAction'
import { Item } from '../../../core/types/Item'
import { formatDate, parseDate } from '../../../core/utils/DateFormat'
import { Logger } from "../../../core/utils/Logger"
import { useModel } from '../../../hooks/context/ModelContext'

const logger = new Logger("control.DateEditor")

export function DateEditor<T extends Item>(props:{
  item: T
  property: keyof T
  onChange?: any
  placeholder?: any
  readonly?: boolean
}) {
  const { model } = useModel()
  const { item, property, onChange, readonly } = props

  const initialDate = getProperty(item, property) as number|undefined

  const [ date, setDate ] = useState<Date>()

  // This is required when the date value is modified outside the control, for example
  // when the date is calculated in the form
  useEffect(() => {
    setDate(initialDate ? new Date(initialDate) : undefined)
  },[initialDate])

  logger.debug("Rendering: date=%s", date)

  if (readonly) return (
    <div className="readonly">{ formatDate(initialDate, "dd-MMM-yyyy")}</div>
  )
              
  return (
    <input type="date" 
           value={formatDate(date?.getTime(), "yyyy-MM-dd")}
           readOnly={readonly}
           tabIndex={readonly ? undefined : 0}
           onChange={(e:any) => setDate(parseDate(e.target.value, "yyyy-MM-dd"))}
           onBlur={(e:any) => onChangeDate(parseDate(e.target.value, "yyyy-MM-dd"))}/>
  )

  function onChangeDate(newDate:Date) {
    if (newDate && newDate.getTime() !== initialDate) {
      logger.debug("onChangeDate: newDate=%o", newDate)

      if (onChange !== undefined) {
        onChange(item, property, newDate?.getTime())
      } else {
        updateItems<T>(model, [item.key], property, newDate?.getTime())
      }
    }
  }
}