import { PortfolioPerformance } from "../../core/builder/performance/PortfolioPerformance"
import { Logger } from "../../core/utils/Logger"
import { useInvestmentModelBuilder } from "../context/InvestmentModelContext"
import { useModel } from "../context/ModelContext"
import { usePivotTables } from "../pivot/usePivotTables"

export const logger = new Logger("hooks.usePortfolioPerformance")

export function usePortfolioPerformance(days:number) {
  const { model } = useModel()
  const { builder } = useInvestmentModelBuilder()
  const pivotTables = usePivotTables()

  const accountPerfs = builder ? pivotTables.pivotTable.accounts.map(account => builder.getModelPerf(account.key, days)) : []

  // portfolioPerf has all accounts in the portfolio, assetPerf only has the assets
  const portfolioPerf = new PortfolioPerformance(accountPerfs)

  const assetPerf = new PortfolioPerformance(accountPerfs.filter(perf => model.isAsset(perf.item)))

  logger.debug("Calculate: %s => %o", pivotTables.name, accountPerfs.map(account => account.name))

  return { portfolioPerf, assetPerf, date:Date.now() }
}


