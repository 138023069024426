import { createContext, useContext, useState } from "react"
import { Logger } from "../../core/utils/Logger"
import { ViewState } from "./ViewState"

const logger = new Logger("context.ViewContext")

interface ViewContextState {
  viewState: ViewState,
  lastUpdated: number
}

const initialState:ViewContextState = { 
  viewState: new ViewState("", undefined),
  lastUpdated: Date.now(),
}

const ViewContext = createContext<ViewContextState>(initialState)

export function useViewContext() {
  return useContext(ViewContext)
}

export function useViewItemState(itemKey:string) {
  const viewState = useViewContext().viewState
  return viewState.get(itemKey)
}

export function ViewContextProvider(props:any) {
  const [state, setState] = useState(() => getViewContextState())

  logger.trace("Providing view state=%o", state)

  function getViewContextState()  {
    const viewState = new ViewState("", onViewStateChanged)
    const context = {
      viewState,
      lastUpdated: Date.now()
    }
    logger.trace("Created ViewContextState=%o", context)

    return context as ViewContextState
  }

  function onViewStateChanged(event:any) {
    logger.debug("onViewStateChanged: rootKey='%s', event", state.viewState.key, event)
    
    setState({...state, lastUpdated:Date.now() })
  }

  return (
    <ViewContext.Provider value={state}>
      { props.children }
    </ViewContext.Provider>
  )
}
