import { Transaction } from "../../../core/model/ModelKeys"
import { Item, ItemProps } from "../../../core/types/Item"
import { formatDate, formatDurationSafe } from '../../../core/utils/DateFormat'
import { Logger } from "../../../core/utils/Logger"
import { NumberFormats, formatNumber } from '../../../core/utils/Numbers'
import { clzName } from "../../../core/utils/Utils"
import { useModel } from "../../../hooks/context/ModelContext"
import { useViewContext } from "../../../hooks/context/ViewContext"
import { useReadonly } from "../../../hooks/useReadonly"
import { ShowIfDebug } from '../../controls/ShowIf'
import { setDialogItem } from '../../controls/dialog/ItemDialog'
import { Editable } from "../../controls/editor/Editable"
import { MenuContext } from "../../controls/menu/MenuContext"
import { CardLink } from '../../views/ViewLinks'

const logger = new Logger("table.ModelRow")

export function ModelRow<T extends Item>({ item, level }:{ item:T, level:number }) {
  const { model } = useModel()
  const { viewState } = useViewContext()
  const { selected, openTree, searchHit } = viewState.get(item.key)
  const { readonly } = useReadonly()

  const anyItem = item as any
  const hasErrors = model.hasErrors(item.key)
  const isInterestRate = Transaction.isInterestRate(item)

  const classRow = clzName("link", { "errors":hasErrors, "search-hit":searchHit, "selected":selected })
  const widthButton = 1.5
  const styleIndent = { paddingLeft: (widthButton * (level+2)) + "rem" }

  logger.trace("Rendering: key=%s, code=%s, selected=%s, openTree=%s, searchHit=%s", 
                item.key, item.code, selected, openTree, searchHit)

  return (
    <tr className={classRow} onClick={() => setDialogItem(item, true)}>
      { viewState.showSelectCheckbox &&
        <td className="rowmenu">
          <ButtonSelectRow item={item} />
        </td>
      }

      <td className="name" colSpan={viewState.showSelectCheckbox ? 1 : 2}>
        <Name item={item}/>
        <Description item={item} style={styleIndent}/>
      </td>

      <td className={"description"}>
        <Description item={item}/>
      </td>

      <td className="code">
        <Editable item={item} property={ItemProps.code} readonly={readonly} />
      </td>

      <td className="type">
        { model.getItemType(item)?.name }
      </td>

      <td className="date"><Date /></td>
      <td className="repeat"><Frequency /></td>
      <td className="value"><Value /></td>

      <ShowIfDebug>
        <td className="key">{item.key}</td>
        <td className="sort">
          {formatNumber(item.sortOrder, NumberFormats.integer)}
        </td>
      </ShowIfDebug>

      <td className="rowmenu" onClick={(e:any) => e.stopPropagation()}>
        <MenuContext item={item} />
      </td>
    </tr>
  )

  function Name({item} : { item:Item }) {
    return (
      <div>
        <ExpandButton />
        <CardLink item={item} onClick={(e:any) => e.stopPropagation()} />
      </div>
    )
  }

  function Description({ item, style } : { item:Item, style?:any }) {
    return (
      <div style={style}>
        <Editable item={item} property={ItemProps.description} readonly={readonly} />
        <Errors/>
      </div>
    )

    function Errors(props:any) {
      if (hasErrors) return (
        <div className="error">
          { model.getErrors(item.key)?.map((err,i) =>
            <div key={i}>Error: {err.message}</div>
          )}
        </div>
      )
    }
  }

  function Date(props:any) {
    const date = anyItem.startDate ?? anyItem.date
    return date ? formatDate(date) : ""
  }

  function Value(props:any) {
    const value = anyItem.value ?? anyItem.principal
    return formatNumber(value, isInterestRate ? NumberFormats.percent2 : NumberFormats.currency0)
  }

  function Frequency(props:any) {
    const duration = anyItem.frequency ?? anyItem.term
    return formatDurationSafe(duration)
  }

  function ExpandButton(props:any) {
    const classImage = openTree ? "far fa-angle-down" : "far fa-angle-right"
    const styleIndent = { paddingLeft: widthButton * level + "rem" }
    const disabled = !model.hasChildren(item.key)

    return (
      <button onClick={toggleOpenTree} style={styleIndent} disabled={disabled}>
        <i className={classImage} />
      </button>
    )

    function toggleOpenTree(e:any) {
      e.stopPropagation()

      const itemState = viewState.toggleOpenTree(item.key)
      if (itemState.openTree) {
        viewState.openTo(model, item.key)
      }
      viewState.onEvent({name:"toggleOpenTree", key:item.key, value:itemState.openTree})
    }
  }  
}

function ButtonSelectRow({item}:{item:Item}) {
  const { model } = useModel()
  const { viewState } = useViewContext()
  const { selected, openTree } = viewState.get(item.key)
  const classImage = (selected ? "fal fa-check-circle" : "fal fa-circle")

  return (
    <button onClick={onClickSelect}>
      <i className={classImage} />
    </button>
  )

  function onClickSelect(e:any) {
    e.stopPropagation()

    const recursive = !openTree
    const itemState = viewState.setSelected(item.key, !selected, recursive, model)
    viewState.onEvent(itemState)
  }
}
