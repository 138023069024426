import { ReactElement, ReactNode } from "react"

export function EditField(props:{ 
  label: ReactElement | string
  children: ReactNode
  className?: string
 }) {
  const { label, className, children } = props
  
  return (
    <div className="form row">
      <div className="col">
        <label>{label}</label>
      </div>
      <div className={"col " + (className || "") }>
        {children}
      </div>
    </div>
  )
}
