import { Logger } from "../../../core/utils/Logger"
import { useBankStmtModel } from '../../../hooks/bankstmt/useBankStmtModel'
import { useFilterContext } from "../../../hooks/context/FilterContext"
import { useInvestmentModel } from "../../../hooks/context/InvestmentModelContext"

const logger = new Logger("hooks.useAssetPriceData")

export function useAssetPriceData() {
  const { item, valuationItems, isUnitised } = useInvestmentModel()
  const { scale } = useFilterContext()
  const bankStmtModel = useBankStmtModel()
  const { transactions } = bankStmtModel.getAccountTransactions(item)

  const start = scale.start
  const end = scale.end || Number.MAX_SAFE_INTEGER

  const priceData: PriceData[] = []

  for (const item of valuationItems) {
    if (item.date >= start && item.date <= end) {
      priceData.push({
        date: item.date,
        Price: isUnitised ? item.unitPrice : item.value
      })
    }
  }

  for (const trans of transactions) {
    if (trans.startDate !== undefined && trans.startDate >= start && trans.startDate <= end) {
      priceData.push({
        date: trans.startDate,
        Price: isUnitised ? trans.unitPrice : trans.value
      })
    }
  }

  priceData.sort((v1, v2) => v1.date - v2.date)

  const first = priceData.at(0)?.date
  const last = priceData.at(-1)?.date

  logger.debug("Returning %d prices for %s, %s", priceData.length, item.name, Logger.Range(first, last))

  return priceData

  interface PriceData {
    date: number
    Price: number | undefined
  }
}
