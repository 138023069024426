import { useParams } from "react-router-dom"
import { ModelKeys } from "../../core/model/ModelKeys"
import { Logger } from "../../core/utils/Logger"
import { useModel } from "../../hooks/context/ModelContext"
import { ViewContextProvider } from "../../hooks/context/ViewContext"
import { ExportDataCard } from "../cards/settings/ExportDataCard"
import { TableCard } from "../cards/table/TableCard"
import { JemstoneSidebar } from "../layouts/JemstoneSidebar"
import { ViewHeader } from "./ViewHeader"
import { ViewMenu } from "./ViewMenu"

export const logger = new Logger("view.TableView")

export function TableView(props:any) {
  const { model } = useModel()
  const { key } = useParams<any>()
  
  if (model.isEmpty()) {
    return <></>
  }
  
  const rootItem = model.getItem(key ?? ModelKeys.root)
  const rootKey = rootItem?.key || ModelKeys.root
  logger.debug("Rendering: key=%s, rootKey=%s, code=%s", key, rootKey, rootItem?.code)
            
  return (
    <ViewContextProvider>
      <ViewHeader rootKey={rootKey}>
        <ViewMenu rootKey={rootKey} 
                  showClipboard showDebug showDocs showFiles 
                  showTheme showWizard showUser />
      </ViewHeader>

      <JemstoneSidebar/>
      
      <main>
        <div id="TableView" className="grid">
          <TableCard rootKey={rootKey} />
          <ExportDataCard/>
        </div>
      </main>
    </ViewContextProvider>
  )
}
