import Papa from 'papaparse'
import { useState } from 'react'
import { NormalizeItem } from '../../../core/model/NormalizeItem'
import { Item } from '../../../core/types/Item'
import { TransactionItemPropNames } from '../../../core/types/TransactionItem'
import { getCurrentUserEmail } from '../../../core/user/UserService'
import { formatDate, formatDurationSafe } from '../../../core/utils/DateFormat'
import { Logger } from '../../../core/utils/Logger'
import { formatNumber, NumberFormats } from '../../../core/utils/Numbers'
import { useBankStmtModel } from '../../../hooks/bankstmt/useBankStmtModel'
import { useModel } from '../../../hooks/context/ModelContext'
import { useViewContext } from '../../../hooks/context/ViewContext'
import { useAtomRef } from '../../../hooks/useAtomRef'
import { Card, CardHeader, CardSection } from '../../controls/Card'
import { ShowIfAtom } from '../../controls/ShowIf'
import { ButtonOpen } from '../../controls/buttons/ButtonOpen'

const logger = new Logger("cards.ExportDataCard")

export function ExportDataCard({bankStmts} : {bankStmts?:boolean}) {
  const { model } = useModel()
  const { viewState } = useViewContext()
  const bankStmtModel = useBankStmtModel()
  const email = getCurrentUserEmail()
  const openAtom = useAtomRef(false)

  const formats = [
    { code:"json", name:"JSON", icon:"fal fa-brackets-curly" },
    { code:"csv",  name:"CSV",  icon:"fal fa-file-csv" },
  ]
  const [ format, setFormat ] = useState(formats[0])

  const selectedKeys = viewState.getSelectedKeys()
  const exportCount = selectedKeys.length || model.size()

  logger.debug("Rendering: %d items selected for export", exportCount)

  // Render a Card view
  return (
    <Card id="ExportDataCard">
      <CardHeader>
        <i className="fal fa-download" />
        <h2>Export Data</h2>
        <menu>
          <ExportDataMenu/>
          <ButtonOpen openAtom={openAtom} />
        </menu>
      </CardHeader>
      <CardSection>
        <ShowIfAtom visible={openAtom}>
          { bankStmts && <DownloadBankStmts/> }
          {!bankStmts && format.code === "json" && <DownloadJSON/> }
          {!bankStmts && format.code === "csv"  && <DownloadCSV/> }
        </ShowIfAtom>
      </CardSection>
    </Card>
  )

  function getExportItems() {
    return (selectedKeys.length > 0) ? model.toItemArray(selectedKeys) : model.items()
  }

  function encodeData(items:any) {
    let data = ""
    if (format.code === "json") {
      data = JSON.stringify(items, null, 2)
    }
    if (format.code === "csv") {
      data = Papa.unparse(items)
    }
    return encodeURIComponent(data)
  }

  function DownloadJSON() {
    const encodedData = encodeData(getExportItems())
    return (
      <a className="dropzone"
         href={`data:text/${format.code};charset=utf-8,${encodedData}`}
         download={`jemstone-${email}-${formatDate(Date.now(), "yyyy-MM-dd")}.${format.code}`}>
        <i className="fal fa-download" />
        <abbr>Click to download a {format.name} file...</abbr>
        <label>
          Click to export <b>{formatNumber(exportCount, NumberFormats.integer)} items</b> as 
          a {format.name} file and download to your device
        </label>
      </a>
    )
  }

  function DownloadCSV() {
    const csvMap = getCsvMap()
    const csvTypes = model.sortByName(model.toItemArray(csvMap.keys()))

    return (
      <div>
        <p>
          Because the model contains many different record types, and each CSV file can only have one record type,
          you need to download separate files for each record type using the links in the table below.
        </p>
        <table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Items</th>
              <th>Link</th>
            </tr>
          </thead>
          <tbody>
            { csvTypes.map(typeItem => 
              <tr key={typeItem.key}>
                <td>{typeItem.name}</td>
                <td>{formatNumber(csvMap.get(typeItem.key)?.length, NumberFormats.integer)}</td>
                <td><DownloadLink typeItem={typeItem}/></td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <th>Total</th>
              <th>{formatNumber(exportCount, NumberFormats.integer)}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    )

    function DownloadLink({typeItem}:{typeItem:Item}) {
      const [downloaded, setDownloaded] = useState(false)

      const items = csvMap.get(typeItem.key)
      if (!items) return <></>

      const encodedData = encodeData(items)
      const downloadFile = `jemstone-${email}_${formatDate(Date.now(), "yyyy-MM-dd")}_${typeItem.name}.${format.code}`

      if (downloaded) {
        return <>Downloaded</>
      }

      return (
        <a href={`data:text/${format.code};charset=utf-8,${encodedData}`} 
           download={downloadFile} 
           onClick={() => setDownloaded(true)}>
          Download {downloadFile}
        </a>
      )
    }
  }

  function getCsvMap() {
    const itemMap = new Map<string,Item[]>()
    const sortedKeys = new Map<string,string[]>()

    // Put all items into separate arrays according to type
    for (const item of getExportItems()) {
      const typeKey = model.getItemType(item)?.key
      if (!itemMap.has(typeKey)) {
        itemMap.set(typeKey,[])
      }
      if (!sortedKeys.has(typeKey)) {
        const keys = NormalizeItem.sortedKeys(item)
        sortedKeys.set(typeKey, keys)
      }

      const normItem = normalize(item, sortedKeys.get(typeKey))
      itemMap.get(typeKey)?.push(normItem)
    }

    return itemMap
  }

  function ExportDataMenu(props:any) {
    return (
      <>
        { formats.map(fmt => 
          <button onClick={() => setFormat(fmt)} key={fmt.code}
                  className={fmt.code === format.code ? "active" : ""}>
            <i className={ fmt.icon }/>
            <label>{ fmt.name }</label>
          </button>
        )}
      </>
    )
  }

  function DownloadBankStmts() {
    const statements = bankStmtModel.statements.filter(stmt => selectedKeys.includes(stmt.key))
    const accounts = Array.from(bankStmtModel.getAccounts(statements))

    return (
      <div>
        <p>
          Because you have selected statements from {accounts.length} accounts, you need to
          download separate files for each account using the links in the table below.
        </p>
        <table>
          <thead>
            <tr>
              <th>Account</th>
              <th>Stmts</th>
              <th>Trans</th>
              <th>Link</th>
            </tr>
          </thead>
          <tbody>
            { accounts.map(account => 
              <AccountRow account={account} key={account.key}/>
            )}
          </tbody>
        </table>
      </div>
    )

    function AccountRow({account}:{account:Item}) {
      const stmts = statements.filter(stmt => stmt.accountKey === account.key)
      const transactions = bankStmtModel.getTransactions(stmts).transactions
                                        .sort((t1,t2) => t1.sortOrder - t2.sortOrder)
                                        .map(trans => normalize(trans,TransactionItemPropNames))

      return (
        <tr>
          <td>{account.name}</td>
          <td>{stmts.length}</td>
          <td>{transactions.length}</td>
          <td><DownloadCSVLink name={account.code ?? "Transactions"} items={transactions} /></td>
        </tr>
      )
    }
  }

  function DownloadCSVLink({name, items}:{name:string, items:Item[]}) {
    const [downloaded, setDownloaded] = useState(false)
    const date = formatDate(Date.now(), "yyyy-MM-dd")

    const encodedData = encodeData(items)
    const downloadFile = `jemstone-${email}_${date}_${name}.${format.code}`

    if (downloaded) {
      return <>Downloaded</>
    }

    return (
      <a href={`data:text/${format.code};charset=utf-8,${encodedData}`} 
         download={downloadFile} 
         onClick={() => setDownloaded(true)}>
        Download {downloadFile}
      </a>
    )
  }

  function normalize<T extends Item>(item:T, sortedKeys:string[]|undefined) {
    if (!sortedKeys) return item

    const newItem = {} as any
    for (const key of sortedKeys as any[]) {
      newItem[key] = resolve(key, (item as any)[key])
    }
    return newItem as T
  }


  function resolve(key:any, value:any) {
    if (key.endsWith("Date") || key.endsWith("date")) {
      return new Date(value)
    }

    if (key.endsWith("Duration")  || key.endsWith("duration") ||
        key.endsWith("Frequency") || key.endsWith("frequency")) {
      return formatDurationSafe(value)
    }

    if (key !== "key" && (key.endsWith("Key") || key.endsWith("key"))) {
      return model.getItem(value)?.name
    }

    return value
  }
}
