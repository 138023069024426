import { Item, ItemStatus } from '../../../core/types/Item'
import { TransactionItem } from "../../../core/types/TransactionItem"
import { Logger } from "../../../core/utils/Logger"
import { useModel } from "../../../hooks/context/ModelContext"
import { useTransferAccount } from "../../../hooks/transfer/useTransferAccount"
import { useTransferMapper } from "../../../hooks/transfer/useTransferMapper"
import { AccountPicker } from "./AccountPicker"

export const logger = new Logger("control.TransferAccountPicker")

export function TransferAccountPicker({ transaction, relatedTrans, readonly, onChange }: {
  transaction: TransactionItem,
  relatedTrans?: TransactionItem,
  readonly?: boolean,
  onChange?: (transaction:TransactionItem, transferAccount:Item, relatedTransaction?:TransactionItem) => void
}) {
  const { model } = useModel()
  const { transferAccount, relatedTransaction } = useTransferAccount(transaction, relatedTrans)
  const transferMapper = useTransferMapper()

  logger.debug("Rendering: transactionKey=%s, transferAccount=%s", 
                transaction.key, transferAccount?.name)

  return (
    <AccountPicker item={transaction} selected={transferAccount} onChange={onChangeAccount} readonly={readonly}
                   showClear showAccounts />
  )

  function onChangeAccount(transferAccount:Item) {
    let relatedTrans = relatedTransaction
    let relatedTransactionKey = relatedTrans?.key

    logger.debug("onChangeAccount: Called transferAccount=%o, relatedTrans=%o", transferAccount, relatedTrans)

    // Hack to allow removal of the transfer
    if (transferAccount.code === "DELETE") {
      if (relatedTrans) {
        relatedTrans = {...relatedTrans, status:ItemStatus.DELETED }
      }
      relatedTransactionKey = undefined
    }

    // Create a new related transaction (a contra to transaction)
    else if (relatedTrans === undefined) {
      const { relatedCategoryKey, relatedTypeKey } = transferMapper.getSafeRelatedCategory(transaction.categoryKey, transaction.typeKey)

      relatedTrans = {
        ...transaction,
        key:                   model.newKey(transferAccount.key),
        parentKey:             transferAccount.key,
        typeKey:               relatedTypeKey,
        categoryKey:           relatedCategoryKey,
        relatedTransactionKey: transaction.key,
        status:                ItemStatus.NEW,
      }
      relatedTransactionKey = relatedTrans.key
    }

    // Change the account that relatedTrans is in to match transferAccount
    else {
      relatedTrans = {...relatedTrans, parentKey:transferAccount.key, status:ItemStatus.MODIFIED }
    }

    // The only thing that changes on the transaction is the relatedTransactionKey
    const trans = {...transaction, relatedTransactionKey}

    logger.debug("onChangeAccount: Returning transferAccount=%s, trans, relatedTrans=%o", 
                  transferAccount?.name, trans, relatedTrans)

    if (onChange) {
      onChange(trans, transferAccount, relatedTrans)
    }
  }
}
