import { Item } from '../../../core/types/Item'
import { Logger } from "../../../core/utils/Logger"
import { useModel } from '../../../hooks/context/ModelContext'
import { useCardOpen } from '../../../hooks/useCardOpen'
import { Card, CardHeader, CardSection } from "../../controls/Card"
import { ButtonBack } from '../../controls/buttons/ButtonBack'
import { ButtonClose } from "../../controls/buttons/ButtonClose"
import { ButtonOpen } from '../../controls/buttons/ButtonOpen'
import { ButtonShowJson } from '../../controls/buttons/ButtonShowJson'
import { ItemDetailsEditor } from './ItemDetailsEditor'
import { ItemDetailsJson } from "./ItemDetailsJson"

const logger = new Logger("settings.ItemDetailsCard")

export function ItemDetailsCard(props:{
  item:Item
  id?: string
  open?: boolean
  noHeader?: boolean
  onBack?: (e:any, item:Item) => void
  onClose?: () => void
}) {
  const { item, id, open, noHeader, onBack, onClose } = props
  const { model } = useModel()
  const { isOpen, setOpen, openAtom } = useCardOpen("ItemDetailsCard", item.key, open)

  const iconClass = model.getItemIcon(item) || "fal fa-line-columns"

  logger.debug("Rendering: key=%s", item.key, item)

  // Render a Card view
  return (
    <Card id={id || "ItemDetailsCard"} open={isOpen}>
      { !noHeader &&
        <CardHeader className="sticky">
          { onBack 
            ? <ButtonBack item={item} onBack={onBack} />
            : <button><i className={iconClass} /></button>
          }
          <h2 onClick={() => setOpen(!isOpen)}>
            Item Details
          </h2>
          <menu>
            <ButtonShowJson/>
            <ButtonOpen openAtom={openAtom}/>
            { onClose &&
              <ButtonClose onClose={onClose} />
            }
          </menu>
        </CardHeader>
      }
      <CardSection>
        <ItemDetailsEditor item={item} />
        <ItemDetailsJson item={item} />
      </CardSection>
    </Card>
  )
}
