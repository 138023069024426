import { Bar, Cell, ComposedChart, LabelList, Line, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { Logger } from '../../../core/utils/Logger'
import { formatAssetValue } from '../../../core/utils/Numbers'
import { useFilterContext } from '../../../hooks/context/FilterContext'
import { useInvestmentModel } from '../../../hooks/context/InvestmentModelContext'
import { usePivotTables } from '../../../hooks/pivot/usePivotTables'
import { ColorPalette, useChartProfile } from '../useChartProfile'
import { formatCredit, formatDebit } from './CompareWaterfallChart'

const logger = new Logger("charts.CashflowWaterfallChart")

export function CashflowWaterfallChart(props:any) {
  const { name } = useInvestmentModel()
  const { filterStack } = useFilterContext()
  const { onClick, noXAxis, noYAxis, date, height, showCumTotal } = props

  let { colors, margin, size, animationDuration, ydomain } = useChartProfile()
  margin = {...margin, bottom:25 }

  const { chartData } = useChartData(date, showCumTotal)

  logger.setContext(name).debug("Rendering %d data points, data=%o, filter=%o", 
                                chartData.length, chartData, filterStack.logFilter)
  
  return (
    <ResponsiveContainer id="CashflowWaterfallChart" width="100%" height={height}>
      <ComposedChart data={chartData} margin={margin} onClick={onClick}
                    barCategoryGap={size.barCategoryGap} barGap={size.barGap} maxBarSize={size.barSizeMini}>
        { (!noXAxis) &&
          <XAxis dataKey="name" textAnchor={"end"} angle={-35} interval={0} />
        }
        { (!noYAxis) &&
          <YAxis width={40} domain={ydomain} tickFormatter={formatAssetValue} />
        }
        <ReferenceLine y={0} strokeDasharray="3 3" />
        <Bar dataKey="space" stackId="1" fill="transparent" animationDuration={animationDuration} radius={size.radiusBar} />
        <Bar dataKey="debit" stackId="1" animationDuration={animationDuration} radius={size.radiusBar}>
          { chartData.map((dp) => (
            <Cell fill={dp.color} key={dp.key} />
          ))}
          <LabelList dataKey="value" position="top" formatter={formatDebit} />
        </Bar>
        <Bar dataKey="credit" stackId="1" animationDuration={animationDuration} radius={size.radiusBar}>
          { chartData.map((dp) => (
            <Cell fill={dp.color} key={dp.key} />
          ))}
          <LabelList dataKey="value" position="bottom" formatter={formatCredit} />
        </Bar>
        <Line dataKey="total" type="step" dot={false} 
              stroke={colors.green[5]}
              strokeDasharray="2 2"
              strokeWidth={1}
              animationDuration={animationDuration} />
      </ComposedChart>
    </ResponsiveContainer>
  )
}

interface DP {
  key: string
  name: string 
  space: number
  debit: number
  credit: number
  value: number
  total: number
  color: string
}

function useChartData(date?:number, showCumTotal?:boolean) {
  const { pivotTable } = usePivotTables()
  const { currentRow, columns } = pivotTable

  // model.sortByName(columns)

  // Color indexes for bars
  const colorPalette = new ColorPalette()

  const chartData:DP[] = []

  const row = date ? pivotTable.rowAt(date) : currentRow

  let total = 0
  for (const column of columns) {
    const value = showCumTotal ? row.cell(column.key).cumTotal : row.cell(column.key).total
    if (Math.abs(value) > 0.05) {
      total += value
      chartData.push({
        key: column.key,
        name: column.name,
        space: (value > 0 ? total - value : total),
        debit: (value > 0 ? value : 0),
        credit: (value < 0 ? -value : 0),
        value: value,
        total: total,
        color: colorPalette.get(value),
      })
    }
  }

  // Finally add the balance
  const value = total
  chartData.push({
    key: "total",
    name: "Total",
    space: 0,
    debit:  (value > 0 ? value : 0),
    credit: (value < 0 ? value : 0),
    value: value,
    total: value,
    color: colorPalette.get(value),
  })

  return { chartData }
}
