import { getAtomValue, setAtomValue } from "../atom/AtomUtils"
import { ModelAtoms, getModel } from "../atom/ModelAtoms"
import { Item } from "../types/Item"
import { getUserFilePath } from "../user/FilesService"
import { getCurrentUser, initUserProfile } from "../user/UserService"
import { Logger } from "../utils/Logger"
import * as FireStore from './FireStoreService'
import { unloadModel } from "./ModelBuilder"

const logger = new Logger("core.ModelService")

// Timers
let loadModelTimerId: any = null
let loadModelIntervalId: any = null

const loadModelTimeoutMillis = 20000

// ModelService error handler - should be the ErrorBoundary
export let onModelErrorHandler: (error:any) => void

export function setModelErrorHandler(onError: (error:any) => void) {
  onModelErrorHandler = onError
}

export async function initializeDB(fileKey?:string) {
  const user = getCurrentUser()
  logger.start("initializeDB", "Initializing DB for user=%s", user?.email)

  try {
    if (FireStore.isActive()) {
      // Initialize Firestore
      FireStore.initializeDB()

      // Initialize the user profile in the DB
      return await initUserProfile(fileKey)
    }
  } catch (e:any) {
    const message = logger.error("initializeDB", "Unable to initialize DB")
    const error = Error(message, { cause:e })

    if (onModelErrorHandler) {
      onModelErrorHandler(error)
    } else {
      throw error
    }
  } finally {
    logger.finish("initializeDB", "Initialized DB for %s", user?.email)
  }
  
  return false
}

export function loadModel() {
  const model = getModel()
  logger.start("loadModel", "Loading model, current size=%d", model.size())

  try {
    if (FireStore.isActive()) {
      // Set a timeout to handle empty model scenario, and an interval for ui updates
      setLoadModelTimer()
      setLoadModelInterval()

      // Subscribe to changes - this will callback to the snapshotHandler when data is ready
      FireStore.subscribeToFileItems()
    }
  } catch (e:any) {
    const message = logger.error("loadModel", "Unable to load model from FireStore")
    const error = Error(message, { cause:e })

    if (onModelErrorHandler) {
      onModelErrorHandler(error)
    } else {
      throw error
    }
  } finally {
    logger.finish("loadModel", "Waiting for notification from Firestore")
  }
}

export function reloadModel() {
  logger.start("reloadModel", "Reloading model")

  unloadModel()
  loadModel()

  logger.finish("reloadModel")
}

export async function saveItems(items:Item[], newOnly = false) {
  logger.start("saveItems", "%d items to be saved", items.length)

  try {
    if (FireStore.isActive()) {
      // If saving new items only then filter the list
      if (newOnly) {
        const model = getModel()
        const newItems = items.filter(item => !model.has(item.key))
  
        logger.debug("saveItems: %d/%d new items to be saved", newItems.length, items.length, newItems)
        items = newItems
      }
  
      // Call FireStore
      return FireStore.saveToDB(items)
    }  
  } catch (e:any) {
    const message = logger.error("saveItems", "Unable to save %d items to FireStore", items.length)
    const error = Error(message, { cause:e })

    if (onModelErrorHandler) {
      onModelErrorHandler(error)
    } else {
      throw error
    }
  } finally {
    logger.finish("saveItems", "%d items saved", items.length)
  }
}

export async function saveItemsX(...items:(Item|undefined)[]) {
  return saveItems(items.filter(item => (item !== undefined)))
}

function setLoadModelTimer() {
  const start = Date.now()

  if (loadModelTimerId !== null) {
    clearTimeout(loadModelTimerId)
  }
  loadModelTimerId = setTimeout(onTimeout, loadModelTimeoutMillis)

  function onTimeout() {
    loadModelTimerId = null

    const model = getModel()
    logger.debug("loadModel.onTimeout: Timeout expired, %d items in model", model.size())

    // Create a new model if required
    if (model.isEmpty()) {
      const elapsed = Date.now() - start
      const message = logger.error("loadModel.onTimeout", 
                                   "Unable to load model from FireStore after %d secs, path=%s",
                                   elapsed/1000, getUserFilePath())
      const error = Error(message)
  
      if (onModelErrorHandler) {
        onModelErrorHandler(error)
      } else {
        throw error
      }
    }
  }
}

function setLoadModelInterval() {
  if (loadModelIntervalId !== null) {
    clearInterval(loadModelIntervalId)
  }
  loadModelIntervalId = setInterval(onInterval, 1000)
  setAtomValue(ModelAtoms.loadModelIntervalAtom, 0)

  function onInterval() {
    const value = getAtomValue(ModelAtoms.loadModelIntervalAtom)
    setAtomValue(ModelAtoms.loadModelIntervalAtom, value+1)
  }
}

export function clearTimers() {
  if (loadModelTimerId !== null) {
    clearTimeout(loadModelTimerId)
    loadModelTimerId = null
  }
  
  if (loadModelIntervalId !== null) {
    clearInterval(loadModelIntervalId)
    loadModelIntervalId = null
  }
}

export function isModelLoading() {
  return (loadModelIntervalId !== null)
}