
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import { ReactNode, useEffect } from "react"
import { BankStmtApplyRules } from "../../../core/builder/bankstmt/BankStmtApplyRules"
import { Model } from "../../../core/model/Model"
import { ModelKeys } from "../../../core/model/ModelKeys"
import { createItems, updateItems, updateItemsX } from "../../../core/service/ModelAction"
import { Item } from "../../../core/types/Item"
import { TransactionItem, TransactionItemProps } from "../../../core/types/TransactionItem"
import { formatDate } from '../../../core/utils/DateFormat'
import { Logger } from "../../../core/utils/Logger"
import { NumberFormats, formatNumber } from '../../../core/utils/Numbers'
import { BankStmtRuleAtoms } from "../../../hooks/bankstmt/BankStmtRuleAtoms"
import { BankStmtTransAtoms } from "../../../hooks/bankstmt/BankStmtTransAtoms"
import { useBankStmtModel } from '../../../hooks/bankstmt/useBankStmtModel'
import { useModel } from "../../../hooks/context/ModelContext"
import { Card, CardHeader, CardSection } from "../../controls/Card"
import { ProgressBar, ProgressBarProps } from "../../controls/ProgressBar"
import { ItemPickerTree } from "../../controls/select/ItemPickerTree"
import { SearchBox } from "../../search/SearchBox"
import { CardLink } from "../../views/ViewLinks"
import { BankStmtTransTable } from "./BankStmtTransTable"

const logger = new Logger("cards.BankStmtCard")

export function BankStmtCard(props:any) {
  const { model } = useModel()
  const setShowAll = useSetAtom(BankStmtTransAtoms.showAllTransactions)

  const bankStmt = useAtomValue(BankStmtTransAtoms.bankStmtFile)
  const allTransactions = useAtomValue(BankStmtTransAtoms.allTransactions)
  const uncatTransactions = useAtomValue(BankStmtTransAtoms.uncategorised)
  const transactions = useAtomValue(BankStmtTransAtoms.transactions)

  const categorised = allTransactions.length - uncatTransactions.length
  const uncatAmount = uncatTransactions.reduce((total,trans) => total + trans.value, 0)

  // Ensure all are visible on first load
  // eslint-disable-next-line
  useEffect(() => setShowAll(true),[])

  logger.debug("Rendering %d/%d transactions (%d uncategorised) from %s", 
                transactions.length, allTransactions.length, uncatTransactions.length, bankStmt.name)

  return (
    <Card id="BankStmtCard">
      <CardHeader>
        <h2 title={bankStmt.accountNumber}>
          <CardLink item={model.getItem(bankStmt.accountKey)} />
        </h2>
        <div className="subtitle">
          <div className="date-range">
            {formatDate(bankStmt.startDate)} to {formatDate(bankStmt.endDate)}
          </div>
          <div className="trans-count">
            <em className="link" onClick={() => setShowAll(true)}>
              {formatNumber(allTransactions.length, NumberFormats.integer)} transactions
            </em>
            {(uncatTransactions.length > 0) &&
              <>
                ,&nbsp;
                <em className="link" onClick={() => setShowAll(false)}>
                  {formatNumber(uncatTransactions.length, NumberFormats.integer)}
                  {' uncategorised = '}
                  {formatNumber(Math.abs(uncatAmount), NumberFormats.currency2)}
                </em>
              </>
            }
          </div>
        </div>

        <SearchAndCategorise />
        <ApplyMenu transactions={allTransactions} categorised={categorised} uncategorised={uncatTransactions.length} />
        <ApplyProgressBar total={allTransactions.length} count={categorised} />
      </CardHeader>
      <CardSection>  
        <BankStmtTransTable/>
      </CardSection>
    </Card>
  )

  function SearchAndCategorise(props:any) {
    const bankStmtModel = useBankStmtModel()
    const [searchValue, setSearchValue] = useAtom(BankStmtTransAtoms.searchValue)
    const searchResults = useAtomValue(BankStmtTransAtoms.searchResults)
    const rules = bankStmtModel.rules
  
    return (
      <>
        <SearchBox searchValueAtom={BankStmtTransAtoms.searchValue} {...props}/>
        <ItemPickerTree className="categorise"
                        rootKey={ModelKeys.category.root}
                        onChange={(selectedItem: Item) => onChooseCategory(selectedItem.key)}
                        placeholder="Category..." />
      </>
    )
  
    /**
     * This is called when a user has searched using keywords, and then selected a category 
     * to associate with those keywords
     */
    function onChooseCategory(categoryKey:string) {
      logger.debug("onChooseCategory: Associated category '%s' (%s) with keywords '%s'", 
                    model.getItemName(categoryKey), categoryKey, searchValue)

      // Update an existing rule, or create a new one
      let rule = rules.find(item => item.name === searchValue)
      if (rule) {
        rule.categoryKey = categoryKey
        updateItems(model, [rule.key], TransactionItemProps.categoryKey, categoryKey)
      } else {
        rule = bankStmtModel.newRule(searchValue, categoryKey)
        createItems(model, [rule])
      }

      // Set category for transactions that match the keywords
      const transactionKeys = searchResults.map(trans => trans.key)
      const categoryProp = { key:TransactionItemProps.categoryKey, value:categoryKey }
      const ruleProp = { key:TransactionItemProps.ruleKey, value:rule.key }

      updateItemsX(model, transactionKeys, categoryProp, ruleProp)

      // Clear search value
      setSearchValue("")
    }
  }
}

export function ApplyProgressBar(props:ProgressBarProps) {
  const status = useAtomValue(BankStmtRuleAtoms.progressStatus)
  const progress = useAtomValue(BankStmtRuleAtoms.progress)
  const setTransModalAtom = useSetAtom(BankStmtTransAtoms.modalTransactions)

  if (status !== undefined) {
    props = {...progress, className:props.className}
  }

  const { count, total, modified } = progress

  logger.trace("ApplyProgressBar: status=%s, count=%d, total=%d, modified=%d", status, count, total, modified)

  return (
    <>
      <ProgressBar {...props} >
        { props.children }
      </ProgressBar>
      { (status === "Started" || ((modified || 0) > 0)) &&
        <div className="mt-1 fs-6">
          <button className="btn-inline" onClick={onClick}>
            Applied {total} rules, {modified} transactions updated
          </button>
        </div>
      }
    </>
  )

  function onClick() {
    const transactions = progress.modifiedItems as TransactionItem[]
    if (transactions){
      setTransModalAtom({
        title: `${transactions?.length} Transactions Updated`,
        transactions: transactions,
      })
    }
  }
}

export function ApplyMenu(props: {
  transactions: TransactionItem[]
  categorised: number
  uncategorised: number
  children?: ReactNode
}) {
  const { model } = useModel()
  const { rules } = useBankStmtModel()
  const setProgress = useSetAtom(BankStmtRuleAtoms.progress)
  const setStatus = useSetAtom(BankStmtRuleAtoms.progressStatus)

  const { transactions, uncategorised, children } = props

  return (
    <menu>
      {children}
      <button onClick={() => applyRules(false)}
              title={`Apply categorisation rules to ${uncategorised} uncategorised transactions`}>
        <i className="fal fa-play" />
      </button>
      {/* <button onClick={() => applyRules(true)}
              title={`Overwrite categories for ${transactions.length} transactions`}>
        <i className="fal fa-forward" />
      </button>
      <button onClick={() => clearCategories(model, transactions)}
              title={`Clear categories from ${categorised} transactions`}>
        <i className="fal fa-trash-list" />
      </button> */}
    </menu>
  )

  function applyRules(overwrite:boolean) {
    const builder = new BankStmtApplyRules(model, rules, transactions, overwrite)
    builder.run({ 
      onStart:  () => setStatus("Started"),
      onFinish: () => setStatus("Finished"),
      onProgress: (progress) => setProgress(progress)
    })
  }
  
  // eslint-disable-next-line
  async function clearCategories(model:Model, transactionItems:TransactionItem[]) {
    if (transactionItems.length > 0) {
      const transKeys = transactionItems.map(t => t.key)
      updateItems(model, transKeys, TransactionItemProps.categoryKey, undefined)
    }
  }
}
