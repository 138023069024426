import { BuildingItemProps } from '../../../core/types/BuildingItem';
import { ItemProps } from '../../../core/types/Item';
import { Logger } from "../../../core/utils/Logger";
import { NumberFormats } from '../../../core/utils/Numbers';
import { useReadonly } from '../../../hooks/useReadonly';
import { EditField } from '../../controls/editor/EditField';
import { Editable } from '../../controls/editor/Editable';

const logger = new Logger("editor.BuildingItemEditor");

export function BuildingItemEditor(props:any) {
  const { readonly } = useReadonly()
  const { item } = props

  logger.debug("Rendering:", item);

  return (
    <div>
      <EditField label="Description">
        <Editable item={item} property={ItemProps.name} readonly={readonly} />
      </EditField>
      <EditField label={<span>Floor Area (m<sup>2</sup>)</span>}>
        <Editable item={item} property={BuildingItemProps.area} numberFormat={NumberFormats.integer} readonly={readonly} />
      </EditField>
      <EditField label="Floors">
        <Editable item={item} property={BuildingItemProps.floors} numberFormat={NumberFormats.integer} readonly={readonly} />
      </EditField>
      <EditField label="Living Rooms">
        <Editable item={item} property={BuildingItemProps.livingRooms} numberFormat={NumberFormats.integer} readonly={readonly} />
      </EditField>
      <EditField label="Bedrooms">
        <Editable item={item} property={BuildingItemProps.bedrooms} numberFormat={NumberFormats.integer} readonly={readonly} />
      </EditField>
      <EditField label="Bathrooms">
        <Editable item={item} property={BuildingItemProps.bathrooms} numberFormat={NumberFormats.integer} readonly={readonly} />
      </EditField>
      <EditField label="Garages">
        <Editable item={item} property={BuildingItemProps.garages} numberFormat={NumberFormats.integer} readonly={readonly} />
      </EditField>
      <EditField label="Carparks">
        <Editable item={item} property={BuildingItemProps.carparks} numberFormat={NumberFormats.integer} readonly={readonly} />
      </EditField>
     </div>
  )
}