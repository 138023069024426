import { Logger } from "../../../core/utils/Logger"
import { useFormData, useFormItem } from "../../../hooks/context/FormContext"
import { Card, CardSection } from "../../controls/Card"
import { useWizard } from "../context/WizardContext"
import { WizardFooter } from "../WizardFooter"
import { WizardHeader } from "../WizardHeader"
import { CreateFileWizardData } from "./CreateFileWizardData"
import { Persona, PersonaList } from "./PersonaList"

const logger = new Logger("wizards.SelectPersona")

export function SelectPersona() {
  const title = "Select a Persona"
  const subtitle = "Select a persona for your new file - this will prepopulate your model with a set of accounts and budget transaction. Just click next if you wish to start with an empty model."
    
  return (
    <Card id="SelectPersona" className="wizard-page" open>
      <WizardHeader title={title} subtitle={subtitle}/>
      <CardSection>
        <SelectPersonaEx/>
      </CardSection>
      <WizardFooter showClose/>
    </Card>
  )

  function SelectPersonaEx() {
    const { wizard, primaryItemProp } = useWizard()
    const { formData } = useFormData<CreateFileWizardData>()
    const [ fileItem, setFileItem ] = useFormItem(primaryItemProp)
  
    return <PersonaList selectedPersona={formData.selectedPersona} onChange={onSelectPersona} />

    function onSelectPersona(persona:Persona|undefined) {
      logger.debug("onSelectPersona: persona=%o", persona)
      formData.selectedPersona = persona
      
      setFileItem({...fileItem, name: `${persona?.name} File`})

      wizard.next()
    }
  }
}
