import { useModelKeys } from "../../../core/model/ModelKeys"
import { Logger } from "../../../core/utils/Logger"
import { FormDataProvider, useFormData } from "../../../hooks/context/FormContext"
import { WizardProvider } from "../context/WizardContext"
import { SelectAccountParent } from "../shared/SelectAccountParent"
import { WizardFormSelector } from "../WizardFormSelector"
import { WizardSavePage } from "../WizardSavePage"
import { AccountWizardData } from "./AccountWizardData"
import { SelectAccountType } from "./SelectAccountType"

const logger = new Logger("wizards.AccountWizard")

const primaryItemProp = "accountItem"

const pageIds = [
  "SelectAccountType",
  "SelectAccountParent",
  primaryItemProp,
  "WizardSavePage"
]

export function AccountWizard() {
  logger.debug("Rendering:")

  return (
    <WizardProvider name="AccountWizard" pageIds={pageIds} primaryItemProp={primaryItemProp}>
      <FormDataProvider>
        <SelectAllowedAccountTypes />
        <SelectAccountParent />
        <WizardFormSelector formItemKey={primaryItemProp} />
        <WizardSavePage />
      </FormDataProvider>
    </WizardProvider>
  )
}

function SelectAllowedAccountTypes() {
  const { AccountKeys, AssetKeys } = useModelKeys()
  const { formData } = useFormData<AccountWizardData>()

  formData.allowedAccountTypeKeys = [
    AccountKeys.checking, 
    AccountKeys.creditcard, 
    AccountKeys.loan, 
    AccountKeys.deposit,
    AccountKeys.savings,
    AccountKeys.wallet,
    AssetKeys.investment,
    AssetKeys.kiwisaver,
    AssetKeys.stock
  ]

  return <SelectAccountType/>
}
