import { arrow, autoUpdate, flip, FloatingArrow, FloatingFocusManager, FloatingPortal, offset, shift, size, useClick, useDismiss, useFloating, useInteractions, useRole } from "@floating-ui/react"
import { useRef, useState } from "react"
import { isMobileOnly } from "react-device-detect"
import { Logger } from "../../../core/utils/Logger"
import { clzName } from "../../../core/utils/Utils"
import { MenuPopoverProps } from "./MenuPopover"

const logger = new Logger("controls.PopoverFUI")

export function PopoverFUI(props:MenuPopoverProps) {
  const { children, label, placeholder, title, buttonImage } = props
  const buttonClass = clzName("popup", props.buttonClass)
  const popoverClass = clzName("popover", props.popoverClass)

  const [ isOpen, setOpen ] = useState(false)
  const arrowRef = useRef(null)

  // Override placement if mobile to ensure space for keyboard
  const placement = (isMobileOnly) ? "top-end" : props.placement

  const {refs, floatingStyles, context} = useFloating({
    open: isOpen,
    onOpenChange(isOpen, event, reason) {
      logger.debug("onOpenChange: isOpen=%s, event=%o, reason=%o", isOpen, event, reason)
      setOpen(isOpen)
    },
    placement: placement,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(4), 
      flip(), 
      shift({ padding:8 }), 
      arrow({element: arrowRef}),
      size({
        apply({ availableHeight, availableWidth, elements }) {
          Object.assign(elements.floating.style, {
            maxWidth:  `${availableWidth - 20}px`,
            maxHeight: `${availableHeight - 8}px`,
          })
        },
      }),
    ],
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context, { role:'menu' })
 
  // Merge all the interactions into prop getters
  const {getReferenceProps, getFloatingProps} = useInteractions([
    click,
    dismiss,
    role,
  ])

  return (
    <>
      <button ref={refs.setReference} 
              {...getReferenceProps({ onClick: (e:any) => e.stopPropagation() })} 
              className={buttonClass} title={title} tabIndex={0}>
        <span data-placeholder={placeholder}>{label}</span>
        { buttonImage && <i className={buttonImage} /> }
      </button>
      { isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div ref={refs.setFloating} {...getFloatingProps()} 
                style={floatingStyles} className={popoverClass} onClick={() => setOpen(false)}>
              { children}
              <FloatingArrow ref={arrowRef} context={context} />
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  )
}