import { BudgetModel } from '../../../core/invest/BudgetModel'
import { Model } from '../../../core/model/Model'
import { Transaction } from "../../../core/model/ModelKeys"
import { TransactionItem } from '../../../core/types/TransactionItem'
import { formatDate, formatDurationSafe } from '../../../core/utils/DateFormat'
import { Logger } from '../../../core/utils/Logger'
import { NumberFormats, formatNumber } from '../../../core/utils/Numbers'
import { clzName } from '../../../core/utils/Utils'
import { useInvestmentModel } from '../../../hooks/context/InvestmentModelContext'
import { useModel } from '../../../hooks/context/ModelContext'
import { useTransferAccount } from "../../../hooks/transfer/useTransferAccount"
import { useReadonly } from '../../../hooks/useReadonly'
import { ButtonDelete } from '../../controls/buttons/ButtonDelete'
import { setDialogItem } from '../../controls/dialog/ItemDialog'
import { DateRange } from '../../controls/editor/DateRange'

const logger = new Logger("editor.TransactionItemsTable")

export function TransactionItemsTable(props:any) {
  const { model, item, transactionItems } = useInvestmentModel<BudgetModel>()
  const { readonly } = useReadonly()

  const transactionTypes = getTransactionTypes(model, transactionItems)

  logger.debug("Rendering: key=%s, code=%s", item.key, item.code)

  return (
    <table id="TransactionItemsTable" className={clzName("table sticky-headers", { readonly:readonly })}>
      { transactionTypes.map((type, index) => 
        <TransactionsByType typeKey={type.key} index={index} transactionItems={transactionItems} key={type.key} />
      )}
    </table>
  )
}

function TransactionsByType({ typeKey, index, transactionItems } : { 
  typeKey: string, 
  index: number, 
  transactionItems: TransactionItem[] 
}) {
  const { model } = useModel()

  const transactions = transactionItems.filter(trans => trans.typeKey === typeKey)
                                       .sort((t1,t2) => (t1.startDate ?? 0) - (t2.startDate ?? 0))

  return (
    <>
      <thead>
        <tr className={index > 0 ? "space" : ""}>
          <th className="name">
            {model.getItemName(typeKey)}
          </th>
          <th className="startDate">Start Date</th> 
          <th className="endDate">End Date</th> 
          <th className="repeat">Repeat</th>
          <th className="amount">Amount</th>
          <th className="menu"></th>
        </tr>
      </thead>
      <tbody>
        { transactions.map(transaction =>
          <TransactionRow transaction={transaction} key={transaction.key} />
        )}
      </tbody>
    </>
  )
}

function TransactionRow({ transaction } : { transaction:TransactionItem }) {
  const { model } = useModel()
  const { transferDescription } = useTransferAccount(transaction)
  const currencyFormat = NumberFormats.number2
  const isInterestRate = Transaction.isInterestRate(transaction)

  return (
    <tr className="link" onClick={() => setDialogItem(transaction, true)} key={transaction.key}>
      <th className="name">
        <i className={model.getItemIcon(transaction)} />
        <span>{ transferDescription ?? model.getItemName(transaction) }</span>
        <div className="date-range">
          { transaction.frequency &&
            <span>Every { formatDurationSafe(transaction.frequency) } from </span>
          }
          <DateRange start={transaction.startDate} end={transaction.finishDate} />
        </div>
      </th>
      <td className="startDate">
        { formatDate(transaction.startDate) }
      </td>
      <td className="endDate">
        { formatDate(transaction.finishDate) }
      </td>
      <td className="repeat">
        { transaction.frequency ? formatDurationSafe(transaction.frequency) : "" }
      </td>
      <td className="amount">
        { formatNumber(transaction.value, isInterestRate ? NumberFormats.percent2 : currencyFormat)}
      </td>
      <td className="menu">
        <ButtonDelete item={transaction} />
      </td>
    </tr>
  )
}

function getTransactionTypes(model:Model, transactionItems:TransactionItem[]) {
  const typeKeys = new Set<string>()
  for (const transaction of transactionItems) {
    typeKeys.add(transaction.typeKey)
  }

  return model.sortItems(model.toItemArray(typeKeys))
}
