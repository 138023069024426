import { resetHookAtoms } from "../../hooks/atoms/resetHookAtoms"
import { BankStmtModelAtoms } from "../atom/BankStmtModelAtoms"
import { InvestmentModelAtoms } from "../atom/InvestmentModelAtoms"
import { getModel, ModelAtoms } from "../atom/ModelAtoms"
import { createModelKeys } from "../model/ModelKeys"
import { Item } from "../types/Item"
import { Logger } from "../utils/Logger"
import * as FireStore from './FireStoreService'
import { clearTimers, onModelErrorHandler } from "./ModelService"

const logger = new Logger("core.ModelUpdater")

export async function handleModelSnapshot(modifiedItems:Item[]) {
  const model = getModel()

  logger.start("setItems", "Setting %d items into model, current size=%d", modifiedItems.length, model.size())
  
  // Clear load model timer and interval
  clearTimers()

  // Update items in the model
  const initialLoad = model.isEmpty()
  model.setItems(modifiedItems)
  createModelKeys(model)
  
  logger.finish("setItems", "Set %d modified items into model, new size=%d", modifiedItems.length, model.size())

  // Asynchronously rebuild impacted models
  rebuildModels(modifiedItems, initialLoad)
}

async function rebuildModels(modifiedItems:Item[], initialLoad:boolean) {
  logger.start("rebuildModels", 
                "Notifying ModelContext of %d modified items, initialLoad=%s", 
                modifiedItems.length, initialLoad)

  try {
    const items = initialLoad ? [] : modifiedItems

    await ModelAtoms.onModelUpdated(getModel(), initialLoad)
    await BankStmtModelAtoms.onModelUpdated(getModel(),   initialLoad, items)
    await InvestmentModelAtoms.onModelUpdated(getModel(), initialLoad, items)
  } 
  catch (e:any) {
    const message = logger.error("setItems.notifyModelAtom", 
                                  "Failed processing %d modified items, initialLoad=%s", 
                                  modifiedItems.length, initialLoad)
    const error = Error(message, { cause:e })
  
    if (onModelErrorHandler) {
      onModelErrorHandler(error)
    } else {
      throw error
    }
  } finally {
    logger.finish("rebuildModels")
  }
}

export async function rebuildAllModels() {
  rebuildModels([], true)
}

export function unloadModel() {
  logger.start("unloadModel", "Unloading model")

  try {
    // Clear load model timer and interval
    clearTimers()

    // Close the database subscriptions
    FireStore.unsubscribeFileItems()

    // Reset the Model
    ModelAtoms.reset()

    // Reset the bank model
    BankStmtModelAtoms.reset()

    // Reset all investment models
    InvestmentModelAtoms.reset()

    // Reset hooks
    resetHookAtoms()

  } catch (e:any) {
    const message = logger.error("unloadModel", "Error clearing models")
    const error = Error(message, { cause:e })

    if (onModelErrorHandler) {
      onModelErrorHandler(error)
    } else {
      throw error
    }
  } finally {
    logger.finish("unloadModel")
  }
}
