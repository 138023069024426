import { Logger } from '../../core/utils/Logger'
import { useFilterContext } from '../context/FilterContext'
import { useInvestmentModel } from '../context/InvestmentModelContext'
import { newAtomMap, useAtomMap } from "../useAtomMap"

const logger = new Logger("hooks.usePivotContent")

type PivotContent = "Balance" | "CumTotal" | undefined
const pivotContentAtomMap = newAtomMap<PivotContent>()

export function usePivotContent(initValue: PivotContent = undefined) {
  const { item } = useInvestmentModel()
  const { stackName } = useFilterContext()
  const [ pivotContent, setPivotContent, pivotContentAtom ] = useAtomMap(pivotContentAtomMap, () => initValue, stackName, item.key)

  logger.trace("Rendering: stackName=%s, itemKey=%s, pivotContent=%s", stackName, item.key, pivotContent)

  return {
    pivotContent,
    setPivotContent,
    pivotContentAtom,
    isBalanceContent: pivotContent === "Balance",
    isCumTotalContent: pivotContent === "CumTotal",
  }
}
