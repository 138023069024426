import { atom, useAtom, useAtomValue } from "jotai"
import { useState } from "react"
import { NavLink } from "react-router-dom"
import { Logger } from "../core/utils/Logger"
import { clzName } from "../core/utils/Utils"
import { documentationRoutes } from "./DocumentationRoutes"

const logger = new Logger("docs.Sidebar")

const sidebarAtom = atom(false)

export function Sidebar(props:any) {
  const showSidebar = useAtomValue(sidebarAtom)

  logger.debug("Rendering: showSidebar=%s, routes=%o", showSidebar, documentationRoutes)

  return (
    <aside className={clzName("documentation sidebar", showSidebar ? "open" : "closed")}>
      <nav className="tree">
        { documentationRoutes.map(item =>
          <TreeItem item={item} base="/docs" key={item.path} />
        )}
      </nav>
    </aside>
  )
}

function TreeItem({ item, base } : { item:any, base:string }) {
  const [ open, setOpen ] = useState(hasChildren(item))

  const path = `${base}/${item.path}`
  const icon = open ? "far fa-chevron-down" : "far fa-chevron-right"

  return (
    <li>
      <i className={icon} onClick={() => setOpen(!open)} />
      <NavLink to={path}>{item.name}</NavLink>

      { open && hasChildren(item) && 
        <ul>
          { item.children?.filter((child:any) => child.path).map((child:any, index:number) =>
            <TreeItem item={child} base={path} key={index} />
          )}
        </ul>
      }
    </li>
  )
}

function hasChildren(item:any) {
  return item.children && item.children.length > 0
}

export function ButtonSidebar(props:any) {
  const [ showSidebar, setShowSidebar ] = useAtom(sidebarAtom)

  return (
    <button onClick={() => setShowSidebar(!showSidebar)}>
      <i className="fal fa-bars"/>
    </button>
  )
}
