import { Item } from "../types/Item"

import baseItems from "./items.json"
import janeItems from "./items.persona-jane.json"

const DataFiles = {
  Base: baseItems,
  Jane: janeItems,
  John: janeItems,
  Emma: janeItems,
  Kath: janeItems,
  Parker: janeItems,
}

export type DataFileName = keyof typeof DataFiles

export function getDataItems(dataFile:DataFileName = "Base") : Item[] {
  const items = DataFiles[dataFile] as Item[]
  return items
}