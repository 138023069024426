import { Account, Asset, Bank, Transaction, Type } from "../../core/model/ModelKeys"
import { AssetItem } from "../../core/types/AssetItem"
import { BankStmtFileItem } from "../../core/types/BankStmtFileItem"
import { BankStmtRuleItem } from "../../core/types/BankStmtRuleItem"
import { CashflowItem } from "../../core/types/CashflowItem"
import { Item } from "../../core/types/Item"
import { LoanItem } from "../../core/types/LoanItem"
import { PropertyItem } from "../../core/types/PropertyItem"
import { TransactionItem } from "../../core/types/TransactionItem"
import { ValuationItem } from "../../core/types/ValuationItem"
import { FilesItem } from "../../core/user/FilesModel"
import { Logger } from "../../core/utils/Logger"
import { useModel } from "../../hooks/context/ModelContext"
import { AccountItemForm } from "./AccountItemForm"
import { AssetItemForm } from "./AssetItemForm"
import { BankStmtFileItemForm } from "./BankStmtFileItemForm"
import { BankStmtRuleItemForm } from "./BankStmtRuleItemForm"
import { BankTransItemForm } from "./BankTransItemForm"
import { FilesItemForm } from "./FilesItemForm"
import { ItemForm } from "./ItemForm"
import { LoanItemForm } from "./LoanItemForm"
import { PropertyItemForm } from "./PropertyItemForm"
import { TransactionItemForm } from "./TransactionItemForm"
import { ValuationItemForm } from "./ValuationItemForm"

const logger = new Logger("forms.FormSelector")

export function FormSelector(props : {
  item: Item | TransactionItem | CashflowItem | ValuationItem | LoanItem | undefined
  formItemKey?: string
  open?: boolean
  noHeader?: boolean
  onClose: () => void
}) {
  const { model } = useModel()
  const { item, formItemKey } = props
  
  logger.debug("Rendering: formItemKey=%s, itemKey=%s", formItemKey, item?.key)

  if (!item) { 
    return <></>
  }

  // File or Folder
  if (Type.isFilesItem(item)) {
    return <FilesItemForm {...props} item={item as FilesItem}/>
  }
    
  // CategoryItem
  if (model.isCategory(item) || model.isType(item)) {
    return <ItemForm {...props} item={item} />
  }
  
  // ValuationItem
  if (Type.isValuation(item)) {
    return <ValuationItemForm {...props} item={item as ValuationItem} />
  }

  // TransactionItem in a BankStmt
  if (Transaction.isBankTransaction(model, item)) {
    return <BankTransItemForm {...props} item={item as TransactionItem} />
  }

  // TransactionItem
  if (model.isTransaction(item)) {
    return <TransactionItemForm {...props} item={item as TransactionItem} />
  }
  
  // LoanItem
  if (Account.isLoan(item)) {
    return <LoanItemForm {...props} item={item as LoanItem} />
  }
  
  // PropertyItem
  if (Asset.isProperty(item)) {
    return <PropertyItemForm {...props} item={item as PropertyItem} />
  }
  
  // AssetItem
  if (Asset.isUnitised(item)) {
    return <AssetItemForm {...props} item={item as AssetItem} />
  }
  
  // AssetItem - deposit account
  if (Account.isTermDeposit(item)) {
    return <AssetItemForm {...props} item={item as AssetItem} />
  }

  // BankStmtFileItem
  if (Bank.isStmt(model, item)) {
    return <BankStmtFileItemForm {...props} item={item as BankStmtFileItem} />
  }

  // BankStmtRuleItem
  if (Bank.isRule(item)) {
    return <BankStmtRuleItemForm {...props} item={item as BankStmtRuleItem} />
  }
    
  // Account Item
  if (model.isAccount(item)) {
    return <AccountItemForm {...props} item={item as Item} />
  }

  // General
  return <ItemForm {...props} item={item} />
}
