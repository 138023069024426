import { atom, useAtom } from "jotai"
import { pasteItems, removeItems } from "../core/service/ModelAction"
import { Item } from "../core/types/Item"
import { Logger } from "../core/utils/Logger"
import { useModel } from "./context/ModelContext"
import { useViewContext } from "./context/ViewContext"
import { setAtomValue } from "../core/atom/AtomUtils"

const logger = new Logger("useClipboard")

const clipboardAtom = atom<Item[]>([])

export function resetClipboard() {
  setAtomValue(clipboardAtom, [])
}

export function useClipboard() {
  const { model } = useModel()
  const { viewState } = useViewContext()
  const [ clipboardItems, setClipboardItems ] = useAtom(clipboardAtom)

  const selectedKeys = viewState.getSelectedKeys()

  return {
    selectedKeys,
    clipboardItems,
    hasClipboardItems: clipboardItems.length > 0,
    setClipboardItems: (items:Item[]) => setClipboardItems(items),

    onCut,
    onCopy,
    onPaste
  }

  function onCut(item?:Item) {
    // Cut selected items (if any)
    if (selectedKeys.length) {
      logger.debug("onCut: %d selected keys", selectedKeys.length)

      // Ensure we cut to clipboard a deep copy of all child items
      const keySet = new Set<string>()
      for (const key of selectedKeys) {
        keySet.add(key)
        model.childrenKeysDeep(key, keySet)
      }

      logger.debug("onCut: selectedKeys", selectedKeys, keySet)

      // Put all cut items on the clipboard
      setClipboardItems(model.toItemArray(keySet))
      viewState.clearSelected()

      removeItems(model, keySet)
    }

    // Cut passed item
    else if (item) {
      setClipboardItems([item])
      removeItems(model, [item.key])
    }
  }

  function onCopy(item?:Item) {
    // Copy selected items (if any)
    if (selectedKeys.length) {
      logger.debug("onCopy: %d selected items", selectedKeys.length)

      setClipboardItems(model.toItemArray(selectedKeys))    
      viewState.clearSelected()
    }

    // Copy passed item
    else if (item) {
      setClipboardItems([item])
    }
  }

  function onPaste(parentItem:Item) {
    logger.debug("onPaste: parentKey=%s", parentItem.key)
    pasteItems(model, parentItem.key, clipboardItems)
  }
}
