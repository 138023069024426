import { getApp } from "firebase/app"
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions"
import { onModelErrorHandler } from "../service/ModelService"
import { Logger } from "../utils/Logger"
import { VersionInfo } from "../../VersionInfo"

const logger = new Logger("service.FirebaseFunctions")

function getCallable(name:string) {
  try {
    logger.start("getCallable", "name=%s", name)

    const app = getApp()
    logger.debug("app=%o", app)

    const functions = getFunctions(app, "https://jemstone.co.nz")
    functions.region = "australia-southeast1"
    logger.debug("functions=%o", functions)

    if (process.env.NODE_ENV === "development") {
      connectFunctionsEmulator(functions, "localhost", 5001)
    }
    
    const callable = httpsCallable(functions, name)
    logger.debug("callable=%o", callable)

    return callable

  } catch (e:any) {
    logger.error("getCallable", "Unable to create Callable for name=%s", name, e)
    return undefined
  } finally {
    logger.finish("getCallable", "name=%s", name)
  }
}

export async function fetchAccounts() {
  logger.start("fetchAccounts")

  try {
    const fetchAccountsCall = getCallable('accounts')
    if (!fetchAccountsCall) {
      return undefined
    }

    const result = await fetchAccountsCall()
    const accounts = result.data as any

    logger.debug("fetchAccounts: %d accounts received", accounts.length, accounts)

    return accounts
  
  } catch (e:any) {
    const message = logger.error("fetchAccounts", "Unable to retrieve accounts from Firebase", e)
    const error = Error(message, { cause:e })

    if (onModelErrorHandler) {
      onModelErrorHandler(error)
    } else {
      throw error
    }
  } finally {
    logger.finish("fetchAccounts")
  }
}

export async function fetchTransactions(start:number, end:number) {
  logger.start("fetchTransactions")

  try {
    const fetchTransactionsCall = getCallable('transactions')
    if (!fetchTransactionsCall) {
      return undefined
    }

    logger.debug("Start: %d, %s", start, new Date(start).toISOString())
    logger.debug("End: %d, %s", end, new Date(end).toISOString())
  
    const result = await fetchTransactionsCall({ start, end })
    const transactions = result.data as any

    logger.debug("fetchTransactions: %d transactions received", transactions.length, transactions)

    return transactions
  
  } catch (e:any) {
    const message = logger.error("fetchTransactions", "Unable to retrieve transactions from Firebase", e)
    const error = Error(message, { cause:e })

    if (onModelErrorHandler) {
      onModelErrorHandler(error)
    } else {
      throw error
    }
  } finally {
    logger.finish("fetchTransactions")
  }
}

export async function fetchVersion() {
  logger.start("fetchVersion")

  try {
    const callFetchVersion = getCallable('version')
    if (!callFetchVersion) {
      return undefined
    }

    logger.debug("callFetchVersion=%o", callFetchVersion)

    return callFetchVersion()
      .then((result) => { 
        logger.debug("fetchVersion responded with: %o", result) 
        return result.data as VersionInfo
      })
      .catch((e) => {
        logger.error("fetchVersion", "Unable to fetch Version Info from Firebase", e)
        return undefined
      })
  
  } catch (e:any) {
    const message = logger.error("fetchVersion", "Unable to fetch Version Info from Firebase", e)
    const error = Error(message, { cause:e })

    if (onModelErrorHandler) {
      onModelErrorHandler(error)
    } else {
      throw error
    }
  } finally {
    logger.finish("fetchVersion")
  }
}
