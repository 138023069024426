export enum ItemStatus { 
  NEW = "New", 
  MODIFIED = "Modified", 
  DELETED = "Deleted",
  TRANSIENT = "Transient"
}

export interface HasKey {
  key: any
}

export interface HasStatus {
  key: string
  status: ItemStatus
}

export interface Item {
  key: string
  code?: string
  name: string
  description?: string
  parentKey: string
  typeKey: string
  links?: string[]
  sortOrder: number
  status: ItemStatus
  modifiedDate?: number
}

type ItemProp = keyof Item

type ItemRecord = Record<ItemProp, ItemProp>

export const ItemProps:ItemRecord = {
  key: "key",
  code: "code",
  name: "name",
  description: "description",
  parentKey: "parentKey",
  typeKey: "typeKey",
  links: "links",
  sortOrder: "sortOrder",
  status: "status",
  modifiedDate: "modifiedDate",
}

setItemPropNames(ItemProps)

export const ItemPropNames = Object.keys(ItemProps) as ItemProp[]

export function setItemPropNames<T>(props:T) {
  for (const key in props) {
    (props as any)[key] = key
  }
  return props
}