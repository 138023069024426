import { isMobile } from "react-device-detect"
import { Cell, Label, LabelList, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts"
import { getDimension } from "../../../core/builder/CashflowFilter"
import { PivotCell } from "../../../core/builder/PivotTable"
import { ModelKeys } from "../../../core/model/ModelKeys"
import { Item } from "../../../core/types/Item"
import { formatDate } from '../../../core/utils/DateFormat'
import { Logger } from "../../../core/utils/Logger"
import { NumberFormats, formatAssetValue, formatCurrency } from '../../../core/utils/Numbers'
import { useFilterContext } from "../../../hooks/context/FilterContext"
import { usePivotContent } from '../../../hooks/pivot/usePivotContent'
import { usePivotTables } from "../../../hooks/pivot/usePivotTables"
import { usePivotType } from '../../../hooks/pivot/usePivotType'
import { ChartHeader } from "../ChartHeader"
import { useBudgetActualPivotRows } from "../budget/useBudgetActualPivotRows"
import { useChartProfile } from "../useChartProfile"

const logger = new Logger("charts.CashflowPieChart")

export function CashflowPieChart({ height, noSort }:{ height?:number|string, noSort?:boolean }) {
  const { model, name, pivotTable } = usePivotTables()
  const { filterStack, filter, filterItem } = useFilterContext()
  const { isBalanceContent, isCumTotalContent } = usePivotContent()
  const { pivotType } = usePivotType()
  const { actualRow } = useBudgetActualPivotRows()

  const row = (isBalanceContent ? pivotTable.currentRow : actualRow)
  const scale = (isBalanceContent ? row.scale : filter.scale)
  
  const { colors, size, animationDuration } = useChartProfile()
  const margin = { top:0, right:0, left:0, bottom:0 }

  const { chartData, totalAmount } = getData(pivotTable.columns, !noSort, colors.bluered)

  logger.setContext(name)
        .debug("Rendering: pivotType=%s, %d data points=%o, filter=%o", 
                pivotType, chartData.length, chartData, filterStack.logFilter)
  
  return (
    <div id="CashflowPieChart" className="chart">
      <ChartHeader scale={scale} />
      <ResponsiveContainer width="100%" height={height || 250}>
        <PieChart margin={margin}>
          <Pie data={chartData} 
              dataKey="value" 
              cx="50%" cy="50%" 
              animationDuration={animationDuration}
              innerRadius="60%" 
              paddingAngle={1} 
              legendType="circle" 
              cornerRadius={size.radiusPie}
              strokeWidth={0}
              //  onClick={onPush}
            >
            { chartData.map((dp) =>
              <Cell fill={dp.color} key={dp.key} cursor="pointer" onClick={() => onClickCell(dp.key)} />
            )}
            <Label content={<CustomLabel/>} />
            <LabelList dataKey="label" position="outside" />
          </Pie>
          {!isMobile &&
            <Tooltip formatter={(value:any) => formatValue(value)} labelFormatter={(date:any) => formatDate(date)} />
          }
          {/* <Legend iconSize={10} /> */}
        </PieChart>
      </ResponsiveContainer>
    </div>
  )

  function CustomLabel(props:any) {
    const { cx, cy } = props.viewBox
    // let { start, end } = filter.scale
    // if (!start) {
    //   start = row.startDate
    //   end = row.endDate
    // }
    
    return (
      <g onClick={() => onClickLabel(filterItem?.parentKey)} cursor="pointer">
        <text x={cx} y={cy-8} textAnchor="middle">
          <tspan>{model.getItemName(filterItem)}</tspan>
        </text>
        <text x={cx} y={cy+15} textAnchor="middle">
          <tspan className="h2">{formatValue(totalAmount)}</tspan>
        </text>
        {/* <text x={cx} y={cy+22} textAnchor="middle">
          <tspan>{isBalanceContent ? formatDate(end) : formatDateRange(start, end)}</tspan>
        </text> */}
      </g>
    )
  }

  function getData(columns:Item[], sort:boolean, colors:string[]) {
    interface DP {
      key: string
      name: string
      amount: number
      value: number
      label: string
      color?: string
    }
  
    const chartData:DP[] = []
  
    let totalValue=0, totalAmount=0
    for (const column of columns) {
      const amount = getAmount(row.cell(column.key)) 
      const dp:any = { 
        key: column.key,
        name: column.name, 
        amount: amount,
        value: Math.abs(amount)
      }
      chartData.push(dp)
  
      totalAmount += amount
      totalValue += dp.value
    }
  
    if (sort) {
      chartData.sort((d1,d2) => d2.value - d1.value)
    }
  
    chartData.forEach((dp,i) => {
      if (dp.value/totalValue > 0.01) {
        dp.label = dp.name + ": " + formatAssetValue(dp.amount)
      }
      dp.color = colors[i % colors.length]
    })

    return { chartData, totalAmount }
  }

  function formatValue(value:any) {
    return formatCurrency(value, NumberFormats.currency0)
  }

  function getAmount(cell:PivotCell) {
    return (
      isBalanceContent ? cell.balance : 
      isCumTotalContent ? cell.cumTotal : cell.total
    )
  }

  function onClickCell(key:string | undefined) {
    const columnItem = model.getItem(key)
    if (columnItem) {
      const { dimension } = getDimension(model, columnItem)
      filterStack.push({columnItem, dimension})
    }
  }

  function onClickLabel(key:string | undefined) {
    const columnItem = (key === ModelKeys.category.root) ? undefined : model.getItem(key)
    const { dimension } = getDimension(model, columnItem)
    filterStack.push({columnItem, dimension})
  }
}
