import { add } from 'date-fns'
import { useMemo } from 'react'
import { AmortizationBuilder } from '../../core/builder/AmortizationBuilder'
import { LoanAmortization } from '../../core/invest/LoanAmortization'
import { setProperty } from '../../core/model/Model'
import { Item, ItemProps } from '../../core/types/Item'
import { LoanItem, LoanItemProps } from '../../core/types/LoanItem'
import { TransactionItem } from '../../core/types/TransactionItem'
import { Logger } from '../../core/utils/Logger'
import { NumberFormats } from '../../core/utils/Numbers'
import { useFormItem } from '../../hooks/context/FormContext'
import { useModel } from '../../hooks/context/ModelContext'
import { useAtomRef } from "../../hooks/useAtomRef"
import { DateEditor } from '../controls/editor/DateEditor'
import { DurationEditor } from '../controls/editor/DurationEditor'
import { Editable } from '../controls/editor/Editable'
import { Field, FieldSet, Form, FormBody } from '../controls/form/Form'
import { FormFooter } from '../controls/form/FormFooter'
import { FormHeader } from '../controls/form/FormHeader'
import { AccountPicker } from '../controls/select/AccountPicker'
import { TransferAccountPicker } from '../controls/select/TransferAccountPicker'
import { ItemFormSharedFields } from './ItemFormSharedFields'

const logger = new Logger("forms.LoanItemForm")

export function LoanItemForm(props : {
  item: LoanItem
  formItemKey?: string
  open?: boolean
  noHeader?: boolean
  onClose: () => void
}) {
  const { model } = useModel()
  const { formItemKey, open, noHeader, onClose } = props

  // State for the editor
  const [ loan, setLoan ] = useFormItem(formItemKey ?? "loanItem", {...props.item} as LoanItem)
  const [ paymentTrans, setPaymentTrans ] = useFormItem("paymentTrans", () => AmortizationBuilder.getPaymentTransaction(model, loan))
  const [ relatedTrans, setRelatedTrans ] = useFormItem("relatedTrans", model.getItem(paymentTrans?.relatedTransactionKey) as TransactionItem|undefined)

  // Amortization calculator, which directly modifies the LoanItem
  const amort = useMemo(() => new LoanAmortization(loan), [loan])
  
  const openAtom = useAtomRef(open === true)

  const icon = model.getItemIcon(loan) || "fal fa-line-columns"
  const currencyFormat = NumberFormats.currency0

  logger.debug("Rendering: loan=%o, props.item=%o, (loan === props.item)=%s", loan, props.item, Object.is(loan, props.item))

  // Render a Card view
  return (
    <Form id="LoanItemForm">
      {!noHeader &&
        <FormHeader title="Loan Editor" icon={icon} onClose={onClose} openAtom={openAtom} />
      }
      <FormBody>
        <FieldSet>
          <Field className="name">
            <label>Name</label>
            <Editable item={loan} property={ItemProps.name} onChange={onChange} />
          </Field>
          <Field className="accountNumber">
            <label>Account Number</label>
            <Editable item={loan} property={ItemProps.code} onChange={onChange} />
          </Field>
          <Field className="description">
            <label>Description</label>
            <Editable item={loan} property={ItemProps.description} onChange={onChange} />
          </Field>
        </FieldSet>

        <FieldSet>
          <Field className="principal">
            <label>Principal</label>
            <Editable item={loan} property={LoanItemProps.principal} onChange={onChangeAmort} numberFormat={currencyFormat} />        
          </Field>
          <Field className="rate">
            <label>Interest Rate</label>
            <Editable item={loan} property={LoanItemProps.rate} onChange={onChangeAmort} numberFormat={NumberFormats.percent2} />        
          </Field>
          <Field className="term">
            <label>Term</label>
            <DurationEditor item={loan} property={LoanItemProps.term} onChange={onChangeAmort} />
          </Field>
          <Field className="startDate">
            <label>Start Date</label>
            <DateEditor item={loan} property={LoanItemProps.startDate} onChange={onChangeAmort}
                        placeholder={Date.now()} />
          </Field>
          <Field className="finishDate">
            <label>Finish Date</label>
            <DateEditor item={loan} property={LoanItemProps.finishDate} onChange={onChangeAmort}
                        placeholder={amort.finishDate} />
          </Field>
        </FieldSet>

        <FieldSet>
          <Field className="paymentFrequency">
            <label>Payment Frequency</label>
            <DurationEditor item={loan} property={LoanItemProps.paymentFrequency} onChange={onChangeAmort} />
          </Field>
          <Field className="paymentAmount">
            <label>Payment Amount</label>
            <Editable item={loan} property={LoanItemProps.paymentAmount} onChange={onChangeAmort} numberFormat={NumberFormats.currency2} 
                      placeholder={amort.paymentAmount} />        
          </Field>
          <Field className="paymentAccount">
            <label>Payment Account</label>
            <TransferAccountPicker transaction={paymentTrans} relatedTrans={relatedTrans} 
              onChange={onChangeTransferAccount} />
          </Field>
          <Field className="offsetAccount">
            <label>Offset Account</label>
            <AccountPicker 
              item={loan} 
              selected={model.getItem(loan.offsetAccountKey)}
              onChange={(selected:Item) => onChange(loan, LoanItemProps.offsetAccountKey, selected.key)}
              showClear showAccounts
            />
          </Field>
        </FieldSet>

        <ItemFormSharedFields item={loan} onChange={onChange} readonly={false} openAtom={openAtom} />
      </FormBody>
      
      { !noHeader &&
        <FormFooter showClose showDelete showSave onClose={onClose}/>
      }
    </Form>
  )

  function onChange(loan:LoanItem, property:any, value:any) {
    logger.debug("onChange: loanKey=%s, property=%s, value=%o", loan.key, property, value)

    const newLoan = setProperty({...loan}, LoanItemProps.paymentTransactionKey, paymentTrans.key)
    setProperty(newLoan, property, value)
    setLoan(newLoan)

    amort.loan = newLoan
  }

  function onChangeAmort(loan:LoanItem, property:any, value:any) {
    logger.debug("onChangeAmort: loanKey=%s, property=%s, value=%o", loan.key, property, value)

    setProperty(amort, property, value)

    const newLoan = setProperty({...amort.loan}, LoanItemProps.paymentTransactionKey, paymentTrans.key)
    setLoan(newLoan)

    // Update the paymentTrans
    const newPaymentTrans = {
      ...paymentTrans,
      parentKey: newLoan.key,
      startDate: add(newLoan.startDate, newLoan.paymentFrequency).getTime(),
      frequency: newLoan.paymentFrequency,
      value:     newLoan.paymentAmount,
      sortOrder: paymentTrans.sortOrder ?? paymentTrans.startDate,
    }
    setPaymentTrans(newPaymentTrans)

    // Update relatedTrans to match paymentTrans
    if (relatedTrans) {
      setRelatedTrans({...relatedTrans,
        startDate: newPaymentTrans.startDate,
        frequency: newPaymentTrans.frequency,
        value:     newPaymentTrans.value,
        sortOrder: newPaymentTrans.sortOrder,
      })
    }
  }

  function onChangeTransferAccount(paymentTrans:TransactionItem, transferAccount:Item, relatedTrans?:TransactionItem) {
    // Ensure payment is saved
    const newLoan = setProperty({...loan}, LoanItemProps.paymentTransactionKey, paymentTrans.key)
    setLoan(newLoan)
    setPaymentTrans(paymentTrans)
    setRelatedTrans(relatedTrans)
  }
}
