import { useAtom } from "jotai"
import { useEffect } from "react"
import { Logger } from "../../../core/utils/Logger"
import { BankStmtTransAtoms } from "../../../hooks/bankstmt/BankStmtTransAtoms"
import { Card, CardHeader, CardSection } from "../../controls/Card"
import { ButtonClose } from "../../controls/buttons/ButtonClose"
import { Dialog } from "../../controls/dialog/Dialog"
import { BankStmtTransTable } from "./BankStmtTransTable"

const logger = new Logger("cards.BankStmtTransDialog")

export function BankStmtTransDialog(props:{title:string}) {
  const [ transactions, setTransactions ] = useAtom(BankStmtTransAtoms.modalTransactions)

  logger.debug("Rdendering: %d transactions", transactions?.transactions?.length)
    
  // eslint-disable-next-line
  useEffect(() => setTransactions(undefined),[])

  return (
    <Dialog open={transactions !== undefined} modal={true} onClose={onClose}>
      <Card>
        <CardHeader>
          <menu>
            <ButtonClose onClose={onClose} />
          </menu>
          <h2>{transactions?.title}</h2>
        </CardHeader>
        <CardSection>
          <BankStmtTransTable transactions={transactions?.transactions} />
        </CardSection>
      </Card>
    </Dialog>
  )

  function onClose() {
    setTransactions(undefined)
  }
}
