import { InvestmentModel } from "../invest/InvestmentModel"
import { Model } from "../model/Model"
import { ModelKeys, Transaction, Type } from "../model/ModelKeys"
import { BankStmtFileItem } from "../types/BankStmtFileItem"
import { Item } from "../types/Item"
import { TransactionItem } from "../types/TransactionItem"
import { Counters } from "../utils/Counters"
import { Logger } from "../utils/Logger"

const logger = new Logger("builder.ModelAnalyzer")

/**
 * Analyzes the {@link Model}s and determines interdependencies
 * between them, which is also used to determine the impact of changes and
 * which models require rebuilding as a consequence
 */
export class ModelAnalyzer {
  private model: Model

  /** 
   * Provides a Set of model keys that produce results required by the consumer model
   * e.g. the accounts in a portfolio are used to create the portfolio
   */
  private _producers = new Map<string, Set<string>>()

  /** 
   * Provides a Set of model keys that consume the producer model e.g. when a model
   * needs to be rebuilt, what other models must then be rebuilt.
   */
  private _consumers = new Map<string, Set<string>>()

  constructor(model:Model, runAnalyze = false) {
    this.model = model

    if (runAnalyze) {
      this.analyzeDependencies()
    }
  }

  get producers() {
    return this.model.toItemArray(this._producers.keys())
  }

  get consumers() {
    return this.model.toItemArray(this._consumers.keys())
  }

  /**
   * @param consumerKey The key of the "consumer" model
   * @returns A Set of model keys that produce results required by the consumer model
   * e.g. the accounts in a portfolio are used to create the portfolio. In this sense
   * the specified consumer model is dependent upon the producer models returned.
   */
  public getProducers(consumerKey: string) {
    let producerKeys = this._producers.get(consumerKey)
    if (!producerKeys) {
      producerKeys = new Set<string>()
      this._producers.set(consumerKey, producerKeys)
    }
    return producerKeys
  }

  public getProducerItems(consumerKey:string) {
    return this.model.toItemArray(this.getProducers(consumerKey))
  }

  /**
   * @param producerKey The key of the "producer" model
   * @returns A Set of model keys that consume the producer model e.g. when a model
   * needs to be rebuilt, what other models must then be rebuilt. In this sense the
   * consumer models are dependent upon the specified producer model.
   */
  public getConsumers(producerKey: string) {
    let consumerKeys = this._consumers.get(producerKey)
    if (!consumerKeys) {
      consumerKeys = new Set<string>()
      this._consumers.set(producerKey, consumerKeys)
    }
    return consumerKeys
  }

  public getConsumerItems(producerKey:string) {
    return this.model.toItemArray(this.getConsumers(producerKey))
  }

  public getConsumerNames(producerKey:string) {
    return this.getItemNames(this.getConsumerItems(producerKey))
  }

  public getProducerNames(consumerKey:string) {
    return this.getItemNames(this.getProducerItems(consumerKey))
  }

  public getItemNames(items:Item[]) {
    this.model.sortByName(items)
    
    let names = ""

    for (let i=0;  i < items.length;  i++) {
      if (i > 0) names += ", "
      names += items[i].name
    }
    return names
  }

  /**
   * Derive a set of dependencies between accounts over and above the hierarchial 
   * dependencies e.g. from transfer transactions between accounts
   */
  public analyzeDependencies() {
    const supportedItems: Item[] = []

    logger.start("analyzeDependencies")

    this._producers.clear()
    this._consumers.clear()

    let count = 0
    const portfolios = this.model.childrenOfType(ModelKeys.root, ModelKeys.asset.portfolio)
    for (const portfolioItem of portfolios) {
      supportedItems.push(portfolioItem)

      const items = this.model.childrenDeep(portfolioItem.key)
      for (const item of items) {
        count++

        // Supported items have an InvestmentModel
        if (this.isSupportedItem(item)) {
          supportedItems.push(item)
        } 
        
        // If item is a link then resolve
        else if (item.links) {
          for (const linkKey of item.links) {
            const linkedItem = this.model.getItem(linkKey)
            if (this.isSupportedItem(linkedItem)) {
              this.addDependency(item.parentKey, linkedItem.key)
            }
          }
        }

        // Transfer transactions create dependencies between accounts
        else if ((item as any).relatedTransactionKey && Transaction.isTransferIn(item)) {
          const relatedTransaction = this.model.getItem<TransactionItem>((item as any).relatedTransactionKey)
          if (relatedTransaction) {
            // The dependency is from the transaction account to the related transaction account
            this.addDependency(item.parentKey, relatedTransaction.parentKey)
          }
        }
      }
    }

    // Update counters
    Counters.set(logger.name, "analyzeDependencies", undefined, count)

    // Log dependencies
    if (logger.isDebugEnabled) {
      for (const [consumerKey,producerKeys] of this._producers.entries()) {
        logger.debug("%s => %o", this.model.getItemName(consumerKey), 
                                 this.model.toItemArray(producerKeys).map(item => item.name))
      }
    }

    logger.finish("analyzeDependencies", "Identified %d supported items, %d with dependencies", 
                  supportedItems.length, this._producers.size)

    return supportedItems
  }

  private addDependency(consumerKey:string, producerKey:string) {
    // Check for circular dependencies
    if (producerKey === consumerKey) return

    // Add producer for the specified consumer
    this.getProducers(consumerKey).add(producerKey)

    // Add consumer for specified producer
    this.getConsumers(producerKey).add(consumerKey)
  }

  /**
   * Given a list of modifiedItems, return all models that require rebuilding
   * @param modifiedItems 
   * @param modelItemKeys 
   * @param modelCount 
   * @returns 
   */
  public getDirtyModels(modifiedItems:Item[], modelMap:Map<string,InvestmentModel<Item>>) : Map<string,Item> {
    logger.start("getDirtyModels", "Checking %d models for dependencies on %d modified items", 
                  modelMap.size, modifiedItems.length)

    const dirty = new Map<string,Item>()

    // Mark as dirty all dependents of each modified item
    for (const item of modifiedItems) {
      // Mark all ancestors of the modified item
      this.markDirty(item, modelMap, dirty)

      // For bank transactions, mark the account and all ancestors
      const accountKey = this.getBankAccount(item, modifiedItems)
      if (accountKey) {
        this.markDirty(accountKey, modelMap, dirty)
      }
    }

    const dirtyModels = Array.from(dirty.values()).map(item => item.name)

    logger.finish("getDirtyModels", "Marked %d/%d models as dirty", 
                  dirty.size, modelMap.size, dirtyModels)

    return dirty
  }

  private markDirty(dirtyItem:Item, modelMap:Map<string, InvestmentModel<Item>>, dirty:Map<string,Item>) {
    // Mark specified model and all ancesstors as dirty
    for (let item = dirtyItem;  item && item.key !== ModelKeys.root; ) {
      if (modelMap.has(item.key) && !dirty.has(item.key)) {
        dirty.set(item.key, item)

        // All consumers of this model
        for (const consumerKey of this.getConsumers(item.key)) {
          this.markDirty(this.model.getItem(consumerKey), modelMap, dirty)
        }
      }
      item = this.model.getItemParent(item)
    }
  }

  /**
   * @param item A [potential] transaction in a bank statement
   * @return The bank account key that this transaction is part of
   */
  private getBankAccount(item:Item, modifiedItems:Item[]) {
    // If item is a TransactionItem with accountKey, then use that
    let accountKey:string|undefined = (item as any).accountKey
    if (!accountKey) {
      // If item is a BankStmtFileItem it will have an accountNumber
      let accountNumber = (item as BankStmtFileItem).accountNumber
      if (!accountNumber) {
        // If item is a TransactionItem, it's parent will have an accountNumber
        let stmt = this.model.getItemParent<BankStmtFileItem>(item)
        if (!stmt) {
          // Statement may be deleted, so check modifiedItems
          stmt = modifiedItems.find(modItem => modItem.key === item.parentKey) as any
        }
        accountNumber = stmt?.accountNumber
      }
      accountKey = this.model.getItemKey(accountNumber)
    }

    const account = this.model.getItem(accountKey)

    logger.trace("getBankAccount: accountKey=%s, itemType=%s, item=%o", 
                  accountKey, this.model.getItemType(item)?.name, item)

    return account
  }

  public isSupportedItem(item:Item) {
    // Ignore setting items
    if (!item || this.model.isSetting(item)) {
      return false
    }
  
    // First check if the item type is supported
    if (item.typeKey && this.SupportedItemTypeKeys.has(item.typeKey)) {
  
      // If one of the nested item types, ensure only the top level gets created
      if (this.NestedItemTypeKeys.has(item.typeKey)) {
        // If item is same as parent then don't create a model
        const parentItem = this.model.getItemParent(item)
        if (!parentItem || parentItem.typeKey === item.typeKey) {
          return false
        }
  
        // If parent item not an asset the 
        if (Type.isValuation(item) && 
          parentItem.typeKey && this.AssetTypeKeys.has(parentItem.typeKey)) {
          return false
        }
      }
  
      return true
    }
  
    return false
  }
  
  private SupportedItemTypeKeys = new Set([
    ModelKeys.asset.portfolio,
    ModelKeys.asset.property,
    ModelKeys.asset.stock,
    ModelKeys.asset.investment,
    ModelKeys.asset.kiwisaver,
    ModelKeys.account.loan,
    ModelKeys.account.checking,
    ModelKeys.account.creditcard,
    ModelKeys.account.deposit,
    ModelKeys.account.savings,
    ModelKeys.account.wallet,
    ModelKeys.type.valuation,
  ])
  
  private NestedItemTypeKeys = new Set([
    ModelKeys.account.checking,
    ModelKeys.account.creditcard,
    ModelKeys.account.deposit,
    ModelKeys.account.savings,
    ModelKeys.account.wallet,
    ModelKeys.type.valuation,
  ])
  
  private AssetTypeKeys = new Set([
    ModelKeys.asset.stock,
    ModelKeys.asset.investment,
    ModelKeys.asset.kiwisaver,
    ModelKeys.account.deposit,
  ])
}
