import { Logger } from "../../core/utils/Logger"
import { useFilterContext } from "../../hooks/context/FilterContext"
import { useInvestmentModel } from "../../hooks/context/InvestmentModelContext"
import { newAtomMap, useAtomMap } from "../../hooks/useAtomMap"

const logger = new Logger("charts.useChartType")

export const ChartTypes = { 
  AutoSelect:             { name:"Auto",        title:"Auto Chart Select",            icon:"fal fa-chart-line" }, 
  BudgetActualRadial:     { name:"Gauge",       title:"Gauge - Budget vs Actual",     icon:"fal fa-gauge-low" },
  CashflowPie:            { name:"Pie",         title:"Pie - Cashflow",               icon:"fal fa-chart-pie" }, 
  CashflowBar:            { name:"Bar",         title:"Bar - Cashflow",               icon:"fal fa-chart-simple" }, 
  CashflowStackedBar:     { name:"Stacked Bar", title:"Stacked Bar - Cashflow",       icon:"fal fa-chart-column" },
  CashflowTreemap:        { name:"Treemap",     title:"Treemap - Cashflow",           icon:"fal fa-chart-tree-map" },
  BudgetActualWaterfall:  { name:"Waterfall",   title:"Waterfall - Budget vs Actual", icon:"fal fa-chart-waterfall" }, 
  CashflowWaterfall:      { name:"Waterfall",   title:"Waterfall - Cashflow",         icon:"fal fa-circle-three-quarters" }, 
  CompareWaterfall:       { name:"Compare",     title:"Waterfall - Compare",          icon:"fal fa-code-compare" }, 
  AssetPriceChart:        { name:"Line",        title:"Line - Asset Price",           icon:"fal fa-chart-line" }, 
  AssetEarningsAreaChart: { name:"Area",        title:"Area - Asset Earnings",        icon:"fal fa-chart-area" }, 
  AccountBalanceAreaChart:{ name:"Balance",     title:"Area - Account Balance",       icon:"fal fa-chart-area" }, 
}

export type ChartType = keyof typeof ChartTypes

const chartTypeAtomMap = newAtomMap<ChartType>()

export function useChartType(initValue:ChartType = "AutoSelect") {
  const { item, name } = useInvestmentModel() 
  const { stackName, filterItem, model } = useFilterContext()
  let [ chartType, setChartType, chartTypeAtom ] = useAtomMap(chartTypeAtomMap, () => initValue, stackName, item.key)

  logger.trace("stackName=%s, itemName=%s, isOpen=%s, initValue=%s", 
                stackName, name, chartType, initValue)

  if (chartType === "AutoSelect") {
    chartType = (filterItem && model.isCategory(filterItem)) ? "CashflowPie" : "AccountBalanceAreaChart"
  }

  return {
    chartType,
    setChartType,
    chartTypeAtom,
  }
}
