import { createItems, removeItems, sortItem, sortRenumber } from "../../../core/service/ModelAction"
import { Item } from '../../../core/types/Item'
import { Logger } from "../../../core/utils/Logger"
import { useModel } from '../../../hooks/context/ModelContext'
import { useViewContext } from '../../../hooks/context/ViewContext'
import { useClipboard } from '../../../hooks/useClipboard'
import { Menu } from './Menu'

const logger = new Logger("control.MenuContext")

export function MenuContext({item}:{item:Item}) {
  const { model } = useModel()
  const { viewState } = useViewContext()
  const { clipboardItems, hasClipboardItems, onCut, onCopy, onPaste } = useClipboard()

  const numSelected = viewState.getSelectedKeys().length

  const siblings = model.childrenSorted(item?.parentKey)
  const index = siblings.findIndex(sibling => (sibling.key === item?.key))

  logger.trace("Rendering: itemKey=%s", item?.key)

  return (
    <Menu buttonImage="fal fa-circle-ellipsis" placement="left">
      <button onClick={() => onCut(item)} title={`Cut ${numSelected || 1} item(s) to clipboard`}>
        <i className="fal fa-cut"></i>
        Cut
      </button>
      <button onClick={() => onCopy(item)} title={`Copy ${numSelected || 1} item(s) to clipboard`}>
        <i className="fal fa-copy"></i>
        Copy
      </button>
      <button onClick={() => onPaste(item)} disabled={!hasClipboardItems}
              title={hasClipboardItems ? `Paste ${clipboardItems.length} items as children of ${item.name}` : undefined}>
        <i className="fal fa-paste"></i>
        Paste
      </button>
      <button onClick={onRemove}>
        <i className="fal fa-trash-alt"></i>
        Delete
      </button>
      <div className="divider"/>
      <button onClick={onCreate}>
        <i className="fal fa-plus"></i>
        Create Sibling
      </button>
      <button onClick={onCreateChild}>
        <i className="fal fa-layer-plus"></i>
        Create Child
      </button>
      <div className="divider"/>
      <button onClick={() => onSort("UP")} disabled={index <= 0}>
        <i className="fal fa-sort-numeric-up"></i>
        Move Up
      </button>
      <button onClick={() => onSort("DOWN")} disabled={index < 0 || index >= siblings.length}>
        <i className="fal fa-sort-numeric-down"></i>
        Move Down
      </button>
      <button onClick={() => onSort("ALPHA")}>
        <i className="fal fa-sort-alpha-down"></i>
        Sort by Name
      </button>
      <button onClick={() => onSortRenumber()}>
        <i className="fal fa-sort-numeric-down"></i>
        Sort Renumber
      </button>
    </Menu>
  )

  function onCreate() {
    logger.debug("onCreate: typeKey=%s, parentKey=%s", item.typeKey, item.parentKey)

    // Create a new item, with same type as it's sibling
    const newItem = model.newItem(item.parentKey, item.typeKey)
    createItems(model, [newItem])
  }

  function onCreateChild() {
    logger.debug("onCreate: parentKey=%s", item.key)
    const newItem = model.newItem(item.key, item.typeKey)
    createItems(model, [newItem])
  }

  function onRemove() {
    logger.debug("onRemove: itemKey=%s", item.key)
    removeItems(model, [item.key])
  }

  function onSort(direction: "UP" | "DOWN" | "ALPHA") {
    logger.debug("onSort: itemKey=%s, direction=%s", item.key, direction)
    sortItem(model, item.key, direction)
  }

  function onSortRenumber() {
    logger.debug("onSortRenumber", item.key)
    sortRenumber(model, item.key)
  }
}
