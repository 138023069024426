import { formatDistance } from 'date-fns'
import { PivotRow } from '../../../core/builder/PivotTable'
import { ValuationModel } from '../../../core/invest/ValuationModel'
import { YieldCalculator } from '../../../core/invest/YieldCalculator'
import { ModelKeys } from "../../../core/model/ModelKeys"
import { formatDate } from '../../../core/utils/DateFormat'
import { Logger } from "../../../core/utils/Logger"
import { formatNumber, isZero, NumberFormats } from '../../../core/utils/Numbers'
import { useInvestmentModel } from '../../../hooks/context/InvestmentModelContext'
import { usePivotTables } from '../../../hooks/pivot/usePivotTables'
import { CurrencyCell, PercentCell, UnitsCell } from '../../controls/NumericCell'
import { Field, FieldSet } from '../../controls/form/Form'
import { AssetPriceNarrative } from './AssetPriceNarrative'

const logger = new Logger("asset.AssetPerformance")

export function AssetPerformance(props:any) {
  const { model, name, asset, isUnitised, isProperty, valuationCalc } = useInvestmentModel<ValuationModel>()
  const { pivotTable, filter } = usePivotTables()
  const { currentRow } = pivotTable

  logger.setContext(name)
  logger.debug("Rendering: date=%s, filter=%s, asset=%o", 
                Logger.Date(currentRow?.date), filter.id, asset.name)

  return (
    <div id="AssetPerformance">
      { isUnitised && <Unitised/> }
      { isProperty && <Property/> }
    </div>
  )

  function Unitised(props:any) {
    const categories = model.children(ModelKeys.category.finance, 
                                      (category => !isZero(currentRow.cell(category.key)?.cumTotal)))
    model.sortItems(categories)

    return (
    <>
      <FieldSet>
        <Field>
          <label>Holdings</label>
          <label>{formatDate(asset.purchaseDate)}</label>
          <label>{formatDate(currentRow.date)}</label>
        </Field>
         <Field>
          <label>Unit Balance</label>
          <UnitsCell value={valuationCalc.first?.unitBalance} />
          <UnitsCell value={valuationCalc.last?.unitBalance} />
        </Field>
        <Field>
          <label>Unit Price</label>
          <UnitsCell value={valuationCalc.first?.unitPrice} />
          <UnitsCell value={valuationCalc.last?.unitPrice} />
        </Field>
        <Field visible={valuationCalc !== undefined} className="totals">
          <label>Price Growth</label>
          <PercentCell value={asset.rate} />
          <PercentCell value={valuationCalc.cagr} />
        </Field>
      </FieldSet>

      <AssetPriceNarrative />

      <FieldSet>
        { categories.map(category => 
          <Field key={category.key}>
            <label>{ category.name }</label>
            <div/>
            <CurrencyCell value={currentRow.cell(category.key)?.cumTotal} />
          </Field>
        )}
        <Field>
          <label>Expenses & Tax</label>
          <div/>
          <CurrencyCell value={getNonFinanceTotal(currentRow)} />
        </Field>
        <Field className="totals">
          <label>Balance</label>
          <CurrencyCell value={valuationCalc.first?.value} />
          <CurrencyCell value={currentRow.balance} />
        </Field>
      </FieldSet>
    </>
    )
  }

  function getNonFinanceTotal(row:PivotRow) {
    let total = 0
    for (const categoryKey of model.childrenKeys(ModelKeys.category.root)) {
      if (categoryKey !== ModelKeys.category.finance) {
        total += row.cell(categoryKey)?.cumTotal || 0
      }
    }
    return total
  }

  function Property(props:any) {
    const yieldCalc = new YieldCalculator(asset, pivotTable, valuationCalc)
    const { value, debt, equity, equityRatio, equityGrowth, yields } = yieldCalc

    return (
    <>
      <FieldSet>
        <Field>
          <h3>Holdings</h3>
          <h3>{formatDate(asset.purchaseDate)}</h3>
          <h3>{formatDate(currentRow.date)}</h3>
        </Field>
        <Field>
          <label>Capital Value</label>
          <CurrencyCell value={value.initialValue} />
          <CurrencyCell value={value.currentValue} />
        </Field>
        <Field visible={valuationCalc !== undefined} className="totals">
          <label>Capital Growth</label>
          <PercentCell value={asset.rate} />
          <PercentCell value={valuationCalc.cagr} />
        </Field>
      </FieldSet>
      <AssetPriceNarrative />

      <FieldSet>
        <Field>
          <label>Debt</label>
          <CurrencyCell value={debt.initialDebt} />
          <CurrencyCell value={debt.currentDebt} />
        </Field>
        <Field>
          <label>Equity</label>
          <CurrencyCell value={equity.initialEquity} />
          <CurrencyCell value={equity.currentEquity} />
        </Field>
        <Field className="totals">
          <label>Equity Ratio</label>
          <PercentCell value={equityRatio.initialEquityRatio} />
          <PercentCell value={equityRatio.currentEquityRatio} />
        </Field>
      </FieldSet>
      <DebtEquityNarrative yieldCalc={yieldCalc} />

      <FieldSet>
        <Field>
          <label>Income</label>
          <td/>
          <CurrencyCell value={yields.income} />
        </Field>
        <Field className="totals">
          <label>Gross Yield</label>
          <td/>
          <PercentCell value={yields.grossYield}/>
        </Field>
      </FieldSet>
      <GrossYieldNarrative yieldCalc={yieldCalc} />

      <FieldSet>
        <Field>
          <label>Income</label>
          <td/>
          <CurrencyCell value={yields.income} />
        </Field>
        <Field>
          <label>Expenses</label>
          <td/>
          <CurrencyCell value={yields.expenses} />
        </Field>
        <Field>
          <label>Interest</label>
          <td/>
          <CurrencyCell value={yields.interest} />
        </Field>
        <Field>
          <label>Net Margin</label>
          <td/>
          <CurrencyCell value={yields.netMargin} />
        </Field>
        <Field className="totals">
          <label>Net Yield</label>
          <td/>
          <PercentCell value={yields.netYield} />
        </Field>
      </FieldSet>
      <NetYieldNarrative yieldCalc={yieldCalc} />

      <FieldSet>
        <Field>
          <label>Equity Growth</label>
          <td/>
          <CurrencyCell value={equityGrowth} />
        </Field>
        <Field>
          <label>Total Return</label>
          <td/>
          <CurrencyCell value={yields.totalMargin} />
        </Field>
        <Field className="totals">
          <label>Total Yield</label>
          <td/>
          <PercentCell value={yields.totalYield} />
        </Field>
      </FieldSet>
      <TotalYieldNarrative yieldCalc={yieldCalc} />
    </>
    )
  }

  function DebtEquityNarrative({yieldCalc} : {yieldCalc:YieldCalculator}) {
    const { debt, equityRatio, value } = yieldCalc
    const dDebt = debt.currentDebt - debt.initialDebt
    const dRatio = equityRatio.currentEquityRatio - equityRatio.initialEquityRatio
    const dValue = value.currentValue - value.initialValue

    return (
      <p>
        Your debt has <b>{dDebt >= 0 ? "decreased" : "increased"} by <Currency value={dDebt}/></b> and the capital value has <b>{dValue >= 0 ? "increased" : "decreased"} by <Currency value={dValue}/></b> in <Duration/>, {dRatio >= 0 ? "improving" : "reducing"} your debt/equity ratio to <Percent value={equityRatio.currentEquityRatio}/>.
      </p>
    )
  }

  function GrossYieldNarrative({yieldCalc} : {yieldCalc:YieldCalculator}) {
    const { yields } = yieldCalc
    return (
      <p>
        Gross yield is the ratio of total income earned from an asset (e.g. rental) to capital value. The {asset.name} has generated income of <Currency value={yields.income}/> in <Duration/>, a <b>gross yield of <Percent value={yields.grossYield}/></b> per year.
      </p>
    )
  }

  function NetYieldNarrative({yieldCalc} : {yieldCalc:YieldCalculator}) {
    const { yields } = yieldCalc
    return (
      <p>
        Net yield is the ratio of net profit (total income less expenses) to capital value. The {asset.name} has generated a net profit <Currency value={yields.netMargin}/> in <Duration/>, a <b>net yield of <Percent value={yields.netYield}/></b> per year.
      </p>
    )
  }

  function TotalYieldNarrative({yieldCalc} : {yieldCalc:YieldCalculator}) {
    const { equityGrowth, yields } = yieldCalc
    return (
      <p>
        Total yield adds capital gain of <Currency value={equityGrowth}/> to the net margin which gives a total return on investment of <Currency value={yields.totalMargin}/>, or <Percent value={yields.totalYield}/> per year.
      </p>
    )
  }

  function Currency({value}:{value:number|undefined}) {
    return <b>{formatNumber(value, NumberFormats.currency0)}</b>
  }

  function Percent({value}:{value:number|undefined}) {
    return <b>{formatNumber(value, NumberFormats.percent1)}</b>
  }

  function Duration() {
    return <>{formatDistance(valuationCalc.lastDate, valuationCalc.firstDate)}</>
  }

  // function className(value:number|undefined) {
  //   return isNegative(value) ? "negative" : "positive"
  // }
}
