import { AccountBalanceAreaChart } from "../../../ui/charts/cashflow/AccountBalanceAreaChart"
import { PageProvider } from "../../PageProvider"
import { Section } from "../../PageSection"

export function ChartPage() {
  return (
    <article>
      <h1>Charts for Visualisation</h1>
      <section>
        <p>
          TODO Describe what chart types are support and what they are good for
        </p>

        <Section title="Sample Account Balance Chart">
          <p>TODO...</p>
          <PageProvider>
            <AccountBalanceAreaChart height={150}/>
          </PageProvider>
        </Section>
      </section>
    </article>
  )
}