import { Dimension, Filter } from "../filter/Filter"
import { Model } from "../model/Model"
import { ModelKeys } from "../model/ModelKeys"
import { CashflowItem } from "../types/CashflowItem"
import { Item, ItemStatus } from "../types/Item"
import { Logger } from "../utils/Logger"
import { newCashflowItemKey } from "./CashflowBuilder"

/** 
 * Utility class for filtering of cashflows 
 */
export class CashflowFilter {
  private model:Model
  private logger = new Logger("builders.CashflowFilter")

  constructor(model:Model) {
    this.model = model
  }

  public filterBy(filter:Filter, cashflowItems:CashflowItem[]) : CashflowItem[] {
    this.logger.start("filterBy", "Filtering %d cashflow items, filter=%o", cashflowItems.length, Logger.Filter(filter))
    // this.logger.warn("filterBy: Filtering %d cashflow items, filter=%o", cashflowItems.length, Logger.Filter(filter))

    // this.logger.debug("cashflowItems", new LogCashflowItems(cashflowItems).logItems)

    let results = cashflowItems

    // Filter by account/type/category
    if (filter.accountItem) {
      results = this.filterByKeyDeep(filter.accountItem, results)
    }
    if (filter.columnItem) {
      results = this.filterByKeyDeep(filter.columnItem, results)
    }

    // Filter by date if if required
    const { start, end } = filter.scale
    if (start || end) {
      results = this.filterByDate(start, end, results)
    }

    this.logger.finish("filterBy", "Returning %d/%d items", results.length, cashflowItems.length)
    return results
  }

  public filterByKeyDeep(item:Item, cashflowItems:CashflowItem[]) : CashflowItem[] {
    this.logger.start("filterByKeyDeep", "Filtering %d cashflow items", cashflowItems.length)

    // Property will be assetKey, accountKey, categoryKey or typeKey
    const { property } = getDimension(this.model, item)

    const keysMap = this.model.childrenKeysDeep(item.key).add(item.key)
    const results = cashflowItems.filter((ci:any) => keysMap.has(ci[property]))

    this.logger.finish("filterByKeyDeep", "parent=%s, property=%s, matching %d, returning %d/%d items", 
                      this.model.getItemName(item), property, keysMap.size, results.length, cashflowItems.length)

    return results
  }

  /**
   * Filter cashflow items according the to Dimension of item
   * - Asset:    item.key = cashflowItem.assetKey
   * - Account:  item.key = cashflowItem.accountKey
   * - Category: item.key = cashflowItem.categoryKey
   * - Type:     item.key = cashflowItem.typeKey
   */
  public filterByKey(item:Item, cashflowItems:CashflowItem[]) : CashflowItem[] {
    // Property will be assetKey, accountKey, categoryKey or typeKey
    const { property } = getDimension(this.model, item)
    const results = cashflowItems.filter((ci:any) => (ci[property] === item.key))

    this.logger.debug("filterByKey: item=%s, property=%s, returning %d/%d items", 
                      this.model.getItemName(item), property, results.length, cashflowItems.length)

    return results
  }

  /**
   * Filter cashflow items according the to Dimension of item
   * - Asset:    item.key = cashflowItem.assetKey
   * - Account:  item.key = cashflowItem.accountKey
   * - Category: item.key = cashflowItem.categoryKey
   * - Type:     item.key = cashflowItem.typeKey
   */
   public countBy(item:Item, cashflowItems:CashflowItem[]) : number {
    // Property will be accountKey, categoryKey or typeKey
    const { property } = getDimension(this.model, item)

    let count = 0
    for (const ci of cashflowItems) {
      if ((ci as any)[property] === item.key) {
        count++
      }
    }
    return count
  }

  public filterByDate(startDate:number, finishDate:number, cashflowItems:CashflowItem[]) : CashflowItem[] {
    this.logger.start("filterByDate", "%s", Logger.Range(startDate, finishDate))

    const balanceCategory = this.model.getItem(ModelKeys.category.balance)

    let openingBalance:CashflowItem | undefined = undefined

    const items:CashflowItem[] = []

    for (const item of cashflowItems) {
      // Determine opening balance
      if (item.date < startDate) {
        openingBalance = item
        continue
      }

      // item.date NOT BETWEEN startDate AND finishDate
      if (item.date > finishDate) {
        break
      }

      // Add opening balance as first item
      if (openingBalance) {
        items.push({
          key: newCashflowItemKey(),
          parentKey: newCashflowItemKey(),
          assetKey: openingBalance.assetKey,
          accountKey: openingBalance.accountKey,
          accountTypeKey: openingBalance.accountTypeKey,
          categoryKey: balanceCategory.key,
          typeKey: balanceCategory.typeKey,
          name: "Opening Balance" + (openingBalance.bankStmt ? "*" : ""),
          date: openingBalance.date,
          amount: 0,
          balance: openingBalance.balance,
          calculated: true,
          sortOrder: item.date,
          status: ItemStatus.TRANSIENT,
        })
        openingBalance = undefined
      }

      // Add this item
      items.push(item)
    }

    this.logger.finish("filterByDate", "Returning %d/%d items, range={%s}", 
                        items.length, cashflowItems.length, Logger.Range(startDate, finishDate))

    return items
  }
}

export function getDimension(model:Model, item:Item | undefined) : { dimension:Dimension, property:string } {
  if (item) {
    if (model.hasChildDeep(item.key, ModelKeys.category.root)) {
      return { dimension:"Category", property:"categoryKey" }
    }

    if (model.hasChildDeep(item.typeKey, ModelKeys.account.root)) {
      return { dimension:"Account", property:"accountKey" }
    }

    if (model.hasChildDeep(item.key, ModelKeys.account.root)) {
      return { dimension:"AccountType", property:"accountTypeKey" }
    }

    if (model.hasChildDeep(item.typeKey, ModelKeys.asset.root)) {
      return { dimension:"Asset", property:"assetKey" }
    }

    if (model.hasChildDeep(item.key, ModelKeys.transaction.root)) {
      return { dimension:"Type", property:"typeKey" }
    }
  }
  return { dimension:"Category", property:"categoryKey" }
}

export function isEqualFilter(f1:Filter|undefined, f2:Filter|undefined) : boolean {
  if (f1 === f2) {
    return true
  }

  if (!f1 || !f2) {
    return false
  }

  if (f1.accountItem !== f2.accountItem) {
    return false
  }
  if (f1.columnItem !== f2.columnItem) {
    return false
  }
  if (f1.raw !== f2.raw) {
    return false
  }
  if (f1.dimension !== f2.dimension) {
    return false
  }
  if (f1.interval !== f2.interval) {
    return false
  }
  if (f1.duration !== f2.duration) {
    return false
  }
  if (f1.dateRangeCode !== f2.dateRangeCode) {
    return false
  }

  if (f1.scale.units !== f2.scale.units) {
    return false
  }
  if (f1.scale.start !== f2.scale.start) {
    return false
  }
  if (f1.scale.end !== f2.scale.end) {
    return false
  }

  if (f1.included === f2.included) {
    return true
  }
  if (!f1.included || !f2.included) {
    return false
  }

  if (f1.included.size !== f2.included.size) {
    return false
  }

  for (const date of f1.included) {
    if (!f2.included.has(date)) {
      return false
    }
  }

  for (const date of f2.included) {
    if (!f1.included.has(date)) {
      return false
    }
  }

  return true
}
