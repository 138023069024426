import { Logger } from "../../core/utils/Logger"
import { useFormData } from "../../hooks/context/FormContext"
import { useModel } from "../../hooks/context/ModelContext"
import { useWizard } from "./context/WizardContext"
import { WizardFormSelector } from "./WizardFormSelector"

const logger = new Logger("wizards.WizardFormPages")

/**
 * Render a form page for each Item being created by the wizard. Forms are used to gather data required for
 * each item being created (or edited)
 * @returns 
 */
export function WizardFormPages({ pageIds } : { pageIds:string[] }) {
  const { model } = useModel()
  const { wizard } = useWizard()
  const { formItemMap } = useFormData()

  // Only accounts get a separate page
  const formItemKeys:string[] = []
  for (const [formItemKey,formItem] of formItemMap) {
    if (model.isAccount(formItem)) {
      formItemKeys.push(formItemKey)
    }
  }

  // Set directly to avoid recursive renders
  wizard.pageIds = pageIds.toSpliced(pageIds.length-1, 0, ...formItemKeys)

  logger.debug("WizardFormPages: pageIds=%o", wizard.pageIds)

  return formItemKeys.map(formItemKey => (
    <WizardFormSelector formItemKey={formItemKey} key={formItemKey} />
  ))
}
