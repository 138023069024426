import { Link } from "react-router-dom"
import { AppInfo } from "../VersionInfo"

export function WelcomePage() {
  return (
    <article>
      <h1>Welcome to Jemstone Money</h1>

      <section>
        <p>
          Jemstone Money enables you to understand where your money gets spent, to create budgets to help reduce spending and increase savings, and understand how your wealth will grow over time to support your retirement, special holiday plans, saving for a home, or for a <em>rainy day</em>.
        </p>
        <p>
          At the heart of Jemstone Money is a powerful cashflow forecasting engine, a pivot table which summarizes the cashflow data, and a charting engine for visualization. The cashflow engine runs locally and so is blazingly fast to respond to changes, drilldowns, filtering etc.
        </p>

        <ul className="grid">
          <li>
            <h2>
              <Link to="user/personas">Personas</Link>
              <i className="fal fa-user-tie-hair-long"/>
            </h2>
            <p>
              Our user personas describe a [fictional] user cohort that describe shared financial circumstances, goals and aspirations, and make it easy to get started in {AppInfo.name}.
            </p>
          </li>
          <li>
            <h2>
              <Link to="user/concepts#dashboard">Dashboards</Link>
              <i className="fal fa-gauge"/>
            </h2>
            <p>
              Dashboards provide a quick overview of the state of a portfolio of accounts and/or assets and trends over the last 30 days or so.
            </p>
          </li>
          <li>
            <h2>
              <Link to="user/concepts#chart">Charts</Link>
              <i className="fal fa-chart-waterfall"/>
            </h2>
            <p>
              A wide range of chart types are used to visualise the actual and projected cashflows, compare time periods, and help you utilise your money to the best effect.
            </p>
          </li>
          <li>
            <h2>
              <Link to="user/concepts#cashflow">Cashflow Forecast</Link>
              <i className="fal fa-file-invoice"/>
            </h2>
            <p>
              The cashflow forecasting engine generates budget, actual and forecast cashfows for your accounts and portfolios based upon actual transaction data loaded from your bank statements and forecast recurring budget transactions.
            </p>
          </li>
          <li>
            <h2>
              <Link to="user/concepts#data-tables">Data Tables</Link>
              <i className="fal fa-table-pivot"/>
            </h2>
            <p>
              Jemstone Money uses pivot tables to summarize cashflow data from multiple accounts across multiple dimensions (including time, account, category), and allows you to filter and drill down to gain insights and understanding.
            </p>
          </li>
          <li>
            <h2>
              <Link to="dev/architecture">Architecture</Link>
              <i className="fal fa-sitemap"/>
            </h2>
            <p>
              Jemstone Money runs on your laptop, iPad or phone and can run without an internet connection. When connected, changes are shared in real-time with all users of the same model files, and with other browser tabs running on your machine.
            </p>
          </li>
        </ul>
      </section>
    </article>
  )
}