import { isMobileOnly } from "react-device-detect"
import { saveModifiedItems } from "../../../core/service/ModelAction"
import { useFormData } from "../../../hooks/context/FormContext"
import { CardHeader } from "../Card"
import { ButtonClose } from "../buttons/ButtonClose"
import { ButtonOpen } from "../buttons/ButtonOpen"
import { ButtonShowJson } from "../buttons/ButtonShowJson"
import { FormFooterProps } from "./FormFooter"

export interface FormHeaderProps extends FormFooterProps {
  icon?: string
  title?: string
  openAtom: any
}

export function FormHeader(props : FormHeaderProps) {
  const { title, openAtom, onSave, onClose } = props
  return (
    <CardHeader>
      <menu>
        <ButtonClose onClose={onClose}/>
      </menu>
      <h2>
        { title ?? "Item Editor" }
      </h2>
      <menu>
        {!isMobileOnly && <ButtonShowJson/> }
        <ButtonSave />
        <ButtonOpen openAtom={openAtom} />
      </menu>
    </CardHeader>
  )
  
  function ButtonSave() {
    const { formItemMap } = useFormData()
  
    return (
      <button onClick={onClick}>
        Save
      </button>
    )
  
    function onClick() {
      if (onSave) {
        onSave()
      } else {
        // Use form data hook to save changes
        saveModifiedItems(formItemMap.values())
  
        // Close the editor (if required)
        onClose && onClose()
      }
    }
  }
}
