import { atom } from "jotai"
import { getAtomValue, setAtomValue } from "../atom/AtomUtils"
import { Item, ItemStatus } from "../types/Item"
import { getUniqueId } from "../utils/Utils"
import { Logger } from "../utils/Logger"

const logger = new Logger("core.FilesService")

export interface FilesItem extends Item {
  key: string
  name: string
  description?: string
  parentKey: string
  typeKey: string // "file" | "folder"
  status: ItemStatus
}

export const filesAtom = atom<FilesItem[]>([])

export function getFiles() {
  return getAtomValue(filesAtom)
}

export function setFiles(files:FilesItem[]) {
  logger.debug("setFiles: files=%o", files)
  return setAtomValue(filesAtom, files)
}

export function newFile(name="New File", parentKey = "") : FilesItem {
  return {
    key: `FI-${getUniqueId()}`,
    name,
    parentKey,
    typeKey: "file",
    sortOrder: 10,
    status: ItemStatus.NEW
  }
}

export function newFolder(name="New Folder", parentKey = "") : FilesItem {
  return {
    key: `FI-${getUniqueId()}`,
    name,
    parentKey,
    typeKey: "folder",
    sortOrder: 10,
    status: ItemStatus.NEW
  }
}
