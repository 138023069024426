import { atom, useAtom } from "jotai"

const debugAtom = atom(false)

export function useDebug() {
  const [ debug, setDebug ] = useAtom(debugAtom)

  return {
    debug: debug,
    setDebug: setDebug
  }
}
