import Papa from 'papaparse'
import { Model } from "../../model/Model"
import { ModelKeys } from "../../model/ModelKeys"
import { BankStmtFileItem } from "../../types/BankStmtFileItem"
import { ItemStatus } from "../../types/Item"
import { TransactionItem } from "../../types/TransactionItem"
import { formatDate, parseDate } from '../../utils/DateFormat'
import { Logger } from "../../utils/Logger"
import { FileParserResults } from './FileParserResults'

const logger = new Logger("builders.CsvSimpleTransParser")

interface CsvTrans {
  Date: string
  Name: string
  Description: string
  Amount: number
  Balance: number
}

export function loadSimpleTransCsv(csvData:string, model:Model, fileName:string) : FileParserResults {
  logger.start("loadSimpleTransCsv", "Parsing file '%s'", fileName)

  // Parse the CSV data
  const result = Papa.parse<CsvTrans>(csvData, {
    header: true,
    dynamicTyping: true
  })

  // Create a model item for the bank statement file
  const statement = model.newItem<BankStmtFileItem>(ModelKeys.bank.stmts, ModelKeys.file.csv, "CSV-")
  statement.name = fileName
  statement.accountNumber = getAccountNumber(fileName)
  statement.accountKey = model.getItemKey(statement.accountNumber)

  const transactions:TransactionItem[] = []

  // Map data to Transaction items
  const length = result.data.length
  for (let i=0; i < length; i++) {
    const record = result.data[i]
    if (record.Date) {
      const date = parseDate(record.Date, "yyyy-MM-dd").getTime()
      const value = record.Amount
      const name = record.Name
      const description = record.Description

      let typeKey = (value >= 0) ? ModelKeys.transaction.moneyIn : ModelKeys.transaction.moneyOut
      let categoryKey = undefined

      if (description.includes("DEBIT TRANSFER") || description.includes("CREDIT TRANSFER")) {
        typeKey = (value >= 0) ? ModelKeys.transaction.transferIn : ModelKeys.transaction.transferOut
        categoryKey = (value >= 0) ? ModelKeys.category.transferIn : ModelKeys.category.transferOut  
      }

      const trans:TransactionItem = {
        key:          model.newKey(statement.key, "CSV-"),
        parentKey:    statement.key,
        typeKey:      typeKey,
        categoryKey:  categoryKey,
        startDate:    date,
        value:        value,
        name:         name,
        description:  description,
        sortOrder:    date + (length-i),              // Use index to preserve ordering
        status:       ItemStatus.NEW,
        modifiedDate: Date.now(),
      }

      transactions.push(trans)
    }
  }

  if (logger.isTraceEnabled) {
    logger.debug("Parsed %d items from file '%s'", 
      transactions.length, fileName,
      transactions.map(item => ({ 
        key: item.key,
        code: item.code,
        accountKey: item.accountKey,
        accountCode: model.getItem(item.accountKey)?.code,
        date: formatDate(item.startDate || 0),
        value: item.value,
      })))
  }

  logger.finish("loadSimpleTransCsv", "Loaded %d/%d transactions from %s", transactions.length, length, fileName)

  return { data:result.data, statements:[statement], transactions }
  
  
  /** Extract the account number from the filename */
  function getAccountNumber(fileName:string) {
    const p0 = 0  //fileName.indexOf("Wise-")
    const p1 = fileName.indexOf("_", p0)
  
    return fileName.substring(p0, p1)
  }
}
