import { ReactNode } from "react"
import { Logger } from "../../../core/utils/Logger"
import { FilterProvider } from "../../../hooks/context/FilterContext"
import { InvestmentModelProvider } from "../../../hooks/context/InvestmentModelContext"
import { InitFilter } from "../../../hooks/filter/FilterStack"
import { ItemDialogProps } from "./ItemDialog"

const logger = new Logger("dialog.ItemDialogProvider")

export interface ItemDialogProviderProps extends ItemDialogProps {
  children: ReactNode
  initFilter?: InitFilter
}

export function ItemDialogProvider({ item, stackName, initFilter, editor, children }: ItemDialogProviderProps) {
  // If we have an item and stackName then provide dialog with InvestmentModel and FilterStack
  if (item && stackName) {
    logger.debug("Providing InvestmentModel and FilterStack: editor=%s, itemKey=%s, stackName=%s", editor, item?.key, stackName)
    return (
      <InvestmentModelProvider item={item}>
        <FilterProvider name={stackName} initFilter={initFilter}>
          {children}
        </FilterProvider>
      </InvestmentModelProvider>
    )
  }

  // If we have an item then provide dialog with InvestmentModel
  if (item) {
    logger.debug("Providing InvestmentModel: editor=%s, itemKey=%s, stackName=%s", editor, item?.key, stackName)
    return (
      <InvestmentModelProvider item={item}>
        {children}
      </InvestmentModelProvider>
    )
  }

  // Just return the children
  return children
}
