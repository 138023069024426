import { removeItems } from "../../../core/service/ModelAction"
import { Item } from "../../../core/types/Item"
import { Logger } from "../../../core/utils/Logger"
import { useModel } from "../../../hooks/context/ModelContext"

const logger = new Logger("controls.ButtonDelete")

export function ButtonDelete({ item, label }:{ item:Item, label?:string }) {
  const { model } = useModel()
  const title = "Delete " + model.getItemName(item)

  return (
    <button onClick={onDelete} title={title}>
      <i className="fas fa-times negative" />
      { label }
    </button>
  )

  function onDelete(e:any) {
    e.stopPropagation()
    
    logger.debug("onDelete: item:", item)
    const event = removeItems(model, [item.key])
    logger.debug("onDelete finished: event=%o", event)
  }
}