import { Item } from "../../types/Item"
import { AccountPerformance } from "./AccountPerformance"
import { PerformanceStats } from "./PerformanceStats"


export class PortfolioPerformance extends PerformanceStats {
  public accountPerfs: AccountPerformance[]

  constructor(accountPerfs: AccountPerformance[]) {
    super()

    this.accountPerfs = accountPerfs
    this.calcPercentChange(this.openingBalance, this.currentBalance)
  }

  get date() {
    return this.accountPerfs.reduce((date, account) => Math.max(date, account.date), 0)
  }

  get names() {
    return this.accountPerfs.map(account => account.name)
  }

  get accounts() {
    return this.accountPerfSorted.map(account => account.item)
  }

  public balance(category: Item) {
    return this.accountPerfs.reduce((total, account) => total + (account.balance(category) ?? 0), 0)
  }

  public cumTotal(category: Item) {
    return this.accountPerfs.reduce((total, account) => total + (account.cumTotal(category) ?? 0), 0)
  }

  get nonFinanceTotal() {
    return this.accountPerfs.reduce((total, account) => total + account.nonFinanceTotal, 0)
  }

  get openingBalance() {
    return this.accountPerfs.reduce((total, account) => total + (account.openingBalance ?? 0), 0)
  }

  get currentBalance() {
    return this.accountPerfs.reduce((total, account) => total + (account.currentBalance ?? 0), 0)
  }

  get accountPerfSorted() {
    return this.accountPerfs.sort(this.sortPerf)
  }

  private sortPerf(p1: AccountPerformance, p2: AccountPerformance) {
    let b1 = p1.currentBalance
    let b2 = p2.currentBalance
    let result = 0

    if (b1 === 0 || b2 === 0) {
      result = b2 - b1
    }
    if (result === 0) {
      result = (p2.cagr ?? 0) - (p1.cagr ?? 0)
    }
    return result
  }
}
