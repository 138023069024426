import { PivotType } from '../../core/invest/BudgetActualModel'
import { Logger } from '../../core/utils/Logger'
import { useFilterContext } from '../context/FilterContext'
import { useInvestmentModel } from '../context/InvestmentModelContext'
import { newAtomMap, useAtomMap } from "../useAtomMap"

const logger = new Logger("hooks.usePivotType")

const pivotTypeAtomMap = newAtomMap<PivotType>()

export function usePivotType(initValue: PivotType = "Forecast") {
  const { item } = useInvestmentModel()
  const { stackName } = useFilterContext()
  const [ pivotType, setPivotType, pivotTypeAtom ] = useAtomMap(pivotTypeAtomMap, () => initValue, stackName, item.key)

  logger.trace("Rendering: stackName=%s, itemKey=%s, pivotType=%s", stackName, item.key, pivotType)

  return {
    pivotType,
    setPivotType,
    pivotTypeAtom,
    isActual: pivotType === "Actual",
    isBudget: pivotType === "Budget",
    isForecast: pivotType === "Forecast",
  }
}
