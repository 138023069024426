import { AssetItem } from "./AssetItem"
import { setItemPropNames } from "./Item"

/**
 * Property item describes details of a home or other property
 */
export interface PropertyItem extends AssetItem {
  /** Address of the property */
  address: string

  /** Official identifier of the land */
  legalDescription: string

  /** Area of the land */
  landArea: number
}

type PropertyItemProp = keyof PropertyItem

type PropertyItemRecord = Record<PropertyItemProp, PropertyItemProp>

export const PropertyItemProps:PropertyItemRecord = {
  key: "key",
  code: "code",
  name: "name",
  description: "description",
  parentKey: "parentKey",
  typeKey: "typeKey",
  links: "links",
  sortOrder: "sortOrder",
  status: "status",
  modifiedDate: "modifiedDate",
  address: "address",
  legalDescription: "legalDescription",
  landArea: "landArea",
  purchaseDate: "purchaseDate",
  purchasePrice: "purchasePrice",
  sellDate: "sellDate",
  rate: "rate"
}

setItemPropNames(PropertyItemProps)

export const PropertyItemPropNames = Object.keys(PropertyItemProps) as PropertyItemProp[]
