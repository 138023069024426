import { atom } from "jotai"
import { Progress } from "../../core/builder/Progress"

export interface ProgressBarProps extends Progress {
  className?: string
  children?: any
}

export const progressAtom = atom<Progress>({
  total: 0,
  count: 0,
})

export function ProgressBar(props:ProgressBarProps) {
  const { count, total, className } = props

  const percent = (total > 0) ? (count/total * 100) : 100
  const title = `${Math.round(percent)}% complete (${count}/${total})`

  return (
    <progress className={className} 
              value={count} 
              max={total} 
              title={title}>
    </progress>
  )

  // return (
  //   <>
  //     <div className={`progress ${className || ""}`}>
  //       <div className="progress-bar" role="progressbar" style={{width:percent + "%"}}
  //            title={title}>
  //         { children }
  //       </div>
  //     </div>
  //   </>
  // )
}
