import { PrimitiveAtom, useAtomValue } from "jotai"
import { ReactNode } from "react"
import { isMobile } from 'react-device-detect'
import { useDebug } from "../../hooks/useDebug"

export function ShowIf({ visible, children } : { visible:boolean|undefined, children: ReactNode}) {
  return visible ? children : <></>
}

export function ShowIfAtom({ visible, children } : { visible:PrimitiveAtom<boolean>, children: ReactNode}) {
  const showChildren = useAtomValue(visible)
  return showChildren ? children : <></>
}

export function ShowIfDebug({ children }: { children: ReactNode }) {
  const { debug } = useDebug()

  return (
    <ShowIf visible={debug && !isMobile}>
      {children}
    </ShowIf>
  )
}