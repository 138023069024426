import { useAtomValue } from "jotai"
import { setUserProfile, userProfileAtom } from "../core/user/UserService"

export function useCurrentUser() {
  return useAtomValue(userProfileAtom)
}

export function useCurrentUserProfile() {
  const userProfile = useAtomValue(userProfileAtom)

  return { userProfile, setUserProfile }
}
