import { Item, setItemPropNames } from "./Item"

/**
 * Contains a set of rules that map keywords found in bank statement transactions
 * to a category
 */
export interface BankStmtRuleItem extends Item {
  /** Category for transactions */
  categoryKey: string

  /** Set priority to be used when matching transactions - P1 first, then P2, then P3 etc */
  priority: number
}

type BankStmtRuleItemProp = keyof BankStmtRuleItem

type BankStmtRuleRecord = Record<BankStmtRuleItemProp, BankStmtRuleItemProp>

export const BankStmtRuleItemProps:BankStmtRuleRecord = {
  key: "key",
  code: "code",
  name: "name",
  description: "description",
  parentKey: "parentKey",
  typeKey: "typeKey",
  links: "links",
  sortOrder: "sortOrder",
  status: "status",
  modifiedDate: "modifiedDate",
  categoryKey: "categoryKey",
  priority: "priority"
}

setItemPropNames(BankStmtRuleItemProps)

export const BankStmtRulePropNames = Object.keys(BankStmtRuleItemProps) as BankStmtRuleItemProp[]
