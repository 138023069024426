import { ReactNode, useState } from "react"
import { Link } from "react-router-dom"
import { ModelKeys } from "../../core/model/ModelKeys"
import { demoteItems, promoteItems, removeItems } from "../../core/service/ModelAction"
import { reloadModel } from "../../core/service/ModelService"
import { getCurrentUser } from "../../core/user/UserService"
import { Logger } from "../../core/utils/Logger"
import { clzName } from "../../core/utils/Utils"
import { useModel } from "../../hooks/context/ModelContext"
import { useViewContext } from "../../hooks/context/ViewContext"
import { useClipboard } from "../../hooks/useClipboard"
import { useDebug } from "../../hooks/useDebug"
import { useReadonly } from "../../hooks/useReadonly"
import { setDialogItem } from "../controls/dialog/ItemDialog"
import { Menu } from "../controls/menu/Menu"
import { MenuCreate } from "../controls/menu/MenuCreate"
import { signOut } from "../layouts/JemstoneLogin"
import { SearchResults } from "../search/SearchResults"
import { ThemeMenu } from "./ThemeMenu"

const logger = new Logger("views.ViewMenu")

export interface ViewMenuProps {
  rootKey?: string
  showCreateKeys?: string[]
  showClipboard?: boolean
  showExpandAll?: boolean
  showChildren?: boolean
  showDebug?: boolean
  showSearch?: boolean
  showTheme?: boolean
  showReadonly?: boolean
  showEdit?: boolean
  showFiles?: boolean
  showRebuild?: boolean
  showWizard?: boolean
  showUser?: boolean
  showDocs?: boolean
  children?: ReactNode
}

export function ViewMenu(props:ViewMenuProps) {
  const { model } = useModel()
  const { viewState } = useViewContext()
  const { onCut, onCopy } = useClipboard()

  const { children, showCreateKeys, showDebug, showClipboard, showEdit, showFiles, showTheme, 
          showReadonly, showRebuild, showWizard, showUser, showDocs } = props
  const rootKey = props.rootKey || ModelKeys.root

  const [loading] = useState(false)

  const numSelected = viewState.getSelectedKeys().length
  const hasSelection = numSelected > 0

  logger.debug("Rendering: props=%o", props)

  return (
    <menu>
      { showDebug && <DebugMenu/> }
      { showRebuild && <RebuildMenu/> }
      { showFiles && <FilesMenu/> }
      { showTheme && <ThemeMenu/> }
      { showDocs && <DocumentationMenu/> }
      { showClipboard && <ClipboardMenu/> }
      { children }
      { showCreateKeys && showCreateKeys.length > 0 && <CreateMenu/>}
      { showWizard && <WizardMenu/> }
      { showReadonly && <ReadonlyMenu/> }
      { showEdit && <EditMenu/> }
      <SearchMenu/>
      { showUser && <UserMenu/> }
    </menu>
  )

  function ClipboardMenu(props:any) {
    return (
      <Menu buttonImage="fal fa-circle-ellipsis">
        <button onClick={() => onCut()} disabled={!hasSelection}
                title={hasSelection ? `Cut ${numSelected} items to clipboard` : undefined}>
          <i className="fal fa-cut"/>
          <label>Cut</label>
        </button>
        <button onClick={() => onCopy()} disabled={!hasSelection}
                title={hasSelection ? `Copy ${numSelected} items to clipboard` : undefined}>
          <i className="fal fa-copy"/>
          <label>Copy</label>
        </button>
        <button onClick={onRemove} disabled={!hasSelection}
                title={hasSelection ? `Delete ${numSelected} items` : undefined}>
          <i className="fal fa-trash-alt"/>
          <label>Delete</label>
        </button>
        <button onClick={onIndent} disabled={!hasSelection}>
          <i className="fal fa-indent"/>
          <label>Indent</label>
        </button>
        <button onClick={onOutdent} disabled={!hasSelection}>
          <i className="fal fa-outdent"/>
          <label>Outdent</label>
        </button>
      </Menu>
    )
  }

  function WizardMenu(props:any) {
    return (
      <Link to="/wizard" className="WizardMenu" title="Configure new accounts, properties, portfolios, etc">
        <i className="fal fa-diagram-project" />
      </Link>
    )
  }

  function CreateMenu(props:any) {
    return <MenuCreate rootKey={rootKey} menuItemKeys={showCreateKeys} />
  }

  function DebugMenu(props:any) {
    const { debug, setDebug } = useDebug()

    return (
      <button className={clzName("DebugMenu", { active:debug })} 
              onClick={() => setDebug(!debug)} title="Show more details">
        <i className={"fal fa-debug"}/>
      </button>
   )
  }

  function DocumentationMenu(props:any) {
    return (
      <Link to="/docs" title="View documentation pages" className="DocsMenu">
        <i className="fal fa-info-circle"></i>
      </Link>
    )
  }

  function RebuildMenu(props:any) {
    return (
      <button onClick={onRebuild} 
              title={model.isEmpty() ? "Create new model" : "Reload model from server"}>
        <i className={"fal fa-sync " + (loading ? "fa-spin" : "")}></i>
      </button>
    )
  }

  function ReadonlyMenu(props:any) {
    const { readonly, setReadonly } = useReadonly()

    return (
      <button className={readonly ? "" : " active"} 
              onClick={() => setReadonly(!readonly)}>
        <i className="fal fa-edit"/>
      </button>
    )
  }

  function EditMenu() {
    const item = model.getItem(rootKey)
    return (
      <button className="EditMenu" onClick={() => setDialogItem(item, true)}>
        <i className="fal fa-edit"/>
      </button>
    )
  }

  function FilesMenu() {
    return (
      <Link to="/files" className="FilesMenu" title="Open another file, or create a new file">
        <i className="fal fa-files" />
      </Link>
    )
  }

  function SearchMenu(props:any) {
    return (
      <SearchResults rootKey={ModelKeys.root} placement="bottom" /> 
    )
  }

  function onRemove() {
    const selectedKeys = viewState.getSelectedKeys()
    logger.debug("onRemove: selected:", selectedKeys)

    const event = removeItems(model, selectedKeys)
    viewState.onEvent(event)
    viewState.clearSelected()
  }

  function onIndent() {
    const selectedKeys:string[] = viewState.getSelectedKeys()
    logger.debug("onIndent: selected:", selectedKeys)
    
    if (selectedKeys.length !== 0) {
      const event = demoteItems(model, selectedKeys)
      viewState.onEvent(event)
      viewState.clearSelected()
    }
  }

  function onOutdent() {
    const selectedKeys = viewState.getSelectedKeys()
    logger.debug("onOutdent: selected:", selectedKeys)
    
    if (selectedKeys.length !== 0) {
      const event = promoteItems(model, selectedKeys)
      viewState.onEvent(event)
      viewState.clearSelected()
    }    
  }

  function onRebuild() {
    logger.debug("onRebuild started")
    reloadModel()
  }
}

export function UserMenu(props:any) {
  const user = getCurrentUser()
  const initials = getInitials()

  return (
    <button className="avatar UserMenu" onClick={signOut} title={`Logout ${user?.email}`}>
      { (user?.photoURL && user?.photoURL !== "") 
        ? <img src={user.photoURL} alt={initials}/>
        : <>{initials}</>
      }
    </button>
  )

  function getInitials() {
    const name = user?.displayName?.toUpperCase()?.trim()
    if (name) {
      let first = name.substring(0,1)
      let second = ""

      let space = name.indexOf(' ')
      if (space < 0) {
        space = name.indexOf('.')
      }
      if (space >= 0) {
        second = name.substring(space+1,space+2)
      }
      return first + second
    }
    return "?"
  }
}

// export function onPaste(model:Model, clipboardItems:Item[], viewState?:ViewState) {
//   // Create a child of the root-level, or of the first selected item
//   const parentKey = clipboardItems.at(0)?.key

//   logger.debug("onPaste: parentKey=%s, items:", parentKey, clipboardItems)

//   // If any pasted items already exist, clone them
//   const itemMap = new Map<string,Item>()
//   for (let item of clipboardItems) {
//     if (model.has(item.key)) {
//       item = {...item, status:ItemStatus.NEW }
//     }
//     if (parentKey) {
//       item.parentKey = parentKey
//     }
//     itemMap.set(item.key, item)
//   }

//   // Allocate a new key to items that already exist
//   for (let item of itemMap.values()) {
//     if (item.status === ItemStatus.NEW) {
//       const parentItem = itemMap.get(item.parentKey)
//       item.parentKey = parentItem?.key || item.parentKey
//       item.key = model.newKey(item.parentKey)
//     }
//   }

//   // Create the new items
//   createItems(model, Array.from(itemMap.values()))

//   // Clear selected state
//   viewState?.clearSelected()
// }
