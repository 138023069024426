import { MenuPopover, MenuPopoverProps } from "./MenuPopover"

export function Menu(props:Partial<MenuPopoverProps>) {
  const { buttonImage, buttonClass, placement, offset, title, children } = props

  if (!children) 
    return <></>

  let label = props.label
  if (label === undefined || typeof label === "string") {
    label = 
      <>
        <i className={buttonImage} />
        <label>{props.label}</label>
      </>
  }

  return (
    <MenuPopover label={label} buttonClass={buttonClass} title={title} placement={placement ?? "bottom"} offset={offset}>
      <menu className="popup">
        {children}
      </menu>
    </MenuPopover>
  )
}
