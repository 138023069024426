import { atom, useAtom } from "jotai"
import { isMobileOnly } from "react-device-detect"

const sidebarAtom = atom(false)
const autohideSidebarAtom = atom(isMobileOnly)

export function useSidebar() {
  const [ showSidebar, setShowSidebar ] = useAtom(sidebarAtom)
  const [ autohide, setAutohide ] = useAtom(autohideSidebarAtom)

  return {
    showSidebar: showSidebar,
    toggleSidebar:  () => setShowSidebar(!showSidebar),
    toggleAutohide: () => setAutohide(!autohide),
    autohideSidebar,
  }

  function autohideSidebar() {
    if (autohide && showSidebar) {
      setTimeout(() => setShowSidebar(false), 50)
    }
  }
}
