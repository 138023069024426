import { PivotTable } from "../builder/PivotTable"
import { ModelKeys } from "../model/ModelKeys"

export class LoanCalculator {
  private pivotTable:PivotTable

  constructor(pivotTable:PivotTable) {
    this.pivotTable = pivotTable
  }
  
  get totalInterest() : number {
    return this.pivotTable.total(ModelKeys.category.interest)
  }

  get totalDeposits() : number {
    return this.pivotTable.total(ModelKeys.category.deposit)
  }

  get totalPayments() : number {
    return this.pivotTable.total(ModelKeys.category.payment)
  }

  get countPayments() {
    return this.pivotTable.count(ModelKeys.category.payment)
  }
}