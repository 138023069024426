import { useState } from "react"
import { Logger } from "../../core/utils/Logger"
import { useFilterContext } from "../../hooks/context/FilterContext"
import { useInvestmentModel } from "../../hooks/context/InvestmentModelContext"
import { useFilterBar } from "../../hooks/filter/useFilterBar"
import { useCardOpen } from "../../hooks/useCardOpen"
import { ChartType, ChartTypes, useChartType } from "../charts/useChartType"
import { Dialog } from "../controls/dialog/Dialog"
import { setDialogItem } from "../controls/dialog/ItemDialog"
import { Menu } from "../controls/menu/Menu"
import { MenuPopover } from "../controls/menu/MenuPopover"
import { CashflowFilterCard } from "./filter/CashflowFilterCard"
import { DimensionMenu } from "./filter/DimensionMenu"
import { IntervalMenu } from "./filter/IntervalMenu"

const logger = new Logger("cards.CardMenu")

export interface CardMenuProps {
  showFilter?: boolean
  showDimension?: boolean
  showUnits?: boolean
  showChartType?: boolean
  showChildren?: boolean
  showEdit?: boolean
  children?: any
}

interface CardMenuProperties extends CardMenuProps {
  cardName: string
}

export function CardMenu(props:CardMenuProperties) {
  const { showFilter, showDimension, showUnits, showChartType, showChildren, showEdit, children, cardName } = props

  const { item } = useInvestmentModel()
  const { filter, stackName } = useFilterContext()
  const { isOpen } = useCardOpen(cardName, item.key)

  logger.debug("Rendering: cardName=%s, itemKey=%s, stackName=%s, filter=%s, isOpen=%s", 
                cardName, item.key, stackName, filter.id, isOpen)

  return (isOpen !== true) ? <></> : (
    <>
      { showChartType && <ChartTypeMenu/> }
      { showChildren && children }
      { showDimension && <DimensionMenu/> }
      { showUnits && !filter.raw && <IntervalMenu/> }
      { showFilter && (true ? <FilterMenuDialog/> : <FilterMenuBar cardName={cardName}/>) }
      { showEdit && <EditMenu/> }
    </>
  )
  
  function EditMenu() {
    return (
      <button onClick={() => setDialogItem(item, true, stackName)}>
        <i className="fal fa-edit"/>
      </button>
    )
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function FilterMenu(props:any) {
  const label = 
    <>
      <i className="fal fa-filter"/>
      <label>Filter</label>
    </>

  return (
    <MenuPopover label={label} placement="bottom">
      <CashflowFilterCard/>
    </MenuPopover>
  )
}

function FilterMenuDialog(props:any) {
  const [visible, setVisible] = useState(false)

  logger.debug("FilterMenuDialog: Rendering visible=%s", visible)

  return (
    <>
      <button className="popup" onClick={() => setVisible(true)}>
        <i className="fal fa-filter"/>
        <label>Filter</label>
      </button>
      <Dialog open={visible} modal={true}>
        <CashflowFilterCard onClose={() => setVisible(false)} />
      </Dialog>
    </>
  )
}

function FilterMenuBar({ cardName } : { cardName:string }) {
  const { stackName, investmentModel:{item} } = useFilterContext()
  const { active, visible, setVisible } = useFilterBar(stackName, item.key)

  return (
    <button className={active ? "popup active" : "popup"} 
            onClick={() => setVisible(active ? !visible : true)}>
      <i className="fal fa-filter"/>
      <label>Filter</label>
    </button>
  )
}

function ChartTypeMenu(props:any) {
  const { chartType, setChartType } = useChartType()

  return (
    <Menu buttonImage={ChartTypes[chartType].icon} label={ChartTypes[chartType].name}>
      <ButtonChartType type="BudgetActualRadial" />
      <ButtonChartType type="CashflowPie" />
      <ButtonChartType type="CashflowBar" />
      <ButtonChartType type="CashflowStackedBar" />
      <ButtonChartType type="BudgetActualWaterfall" />
      <ButtonChartType type="CompareWaterfall" />
      <ButtonChartType type="AssetEarningsAreaChart" />
      <ButtonChartType type="AccountBalanceAreaChart" />
      <ButtonChartType type="AutoSelect" />
    </Menu>
  )

  function ButtonChartType(props:{type:ChartType}) {
    const type = props.type
    return (
      <button className={chartType === type ? "active" : ""} 
              onClick={onClick} title={ChartTypes[type].title}>
        <i className={ChartTypes[type].icon}/>
        <label>{ChartTypes[type].title}</label>
      </button>
    )
  
    function onClick() {
      logger.debug("ButtonChartType: setting chartType to '%s'", type)
      setChartType(type)
    }
  }
}
