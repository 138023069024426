import { formatNumber, ifZero } from '../../core/utils/Numbers'

export function Pill({ value, formatStr, title } : { value:number|undefined, formatStr:any, title?:string }) {
  // Deal with undefined and rounding
  value = ifZero(value)
  
  const arrow = (value < 0) ? "fas fa-arrow-down" : 
                (value > 0) ? "fas fa-arrow-up" : "fas fa-dash"
  const cname = (value < 0) ? "pill negative" : 
                (value > 0) ? "pill positive" : "pill"
        
  return (
    <div className={cname} title={title}>
      { formatNumber(value, formatStr)}
      <i className={arrow} />
    </div>
  )
}