import { FloatingFocusManager, FloatingOverlay, FloatingPortal, useClick, useDismiss, useFloating, useInteractions, useRole } from "@floating-ui/react"
import { Logger } from "../../../core/utils/Logger"
import { DialogProps } from "./Dialog"

const logger = new Logger("controls.DialogFUI")

export function DialogFUI(props:DialogProps) {
  const { open, modal, onClose, children } = props
 
  const {refs, context} = useFloating({
    open: open,
    onOpenChange(isOpen, event, reason) {
      logger.debug("onOpenChange: isOpen=%s, event=%o, reason=%o", isOpen, event, reason)
      !isOpen && onClose && onClose()
    },
  })
 
  const click = useClick(context)
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'pointerdown',
  })
  const role = useRole(context, { role:'dialog' })
 
  // Merge all the interactions into prop getters
  const {getFloatingProps} = useInteractions([
    click,
    dismiss,
    role,
  ])
 
  logger.debug("Rendering: open=%s", open)

  return (
    <>
      { open && (
        <FloatingPortal>
          <FloatingOverlay lockScroll className="dialog-backdrop">
            <FloatingFocusManager context={context} guards={false} modal={modal}>
              <div ref={refs.setFloating} {...getFloatingProps()} className="dialog">
                { children }
              </div>
            </FloatingFocusManager>
          </FloatingOverlay>
        </FloatingPortal>
      )}
    </>
  )
}