import { getInvestmentModel } from "../../core/atom/InvestmentModelAtoms"
import { CashflowItem } from "../../core/types/CashflowItem"
import { Item } from "../../core/types/Item"
import { Logger } from "../../core/utils/Logger"
import { NumberFormats } from '../../core/utils/Numbers'
import { getDaysToDate } from "../../core/utils/TimeScale"

const logger = new Logger("hooks.useAccountCashflow")

/**
 * Return a filtered cashflow for a specific account and date range. The cashflowItems
 * are obtained from InvestmentModel.cashflow.
 * 
 * @param account Account to get cashflow for
 * @param interval Time interval to filter the cashflow by
 * @returns An array of CashflowItem
 */
export function useAccountCashflow(account?:Item, interval?:{ start:number, end:number }) : CashflowItem[] {
  const items:CashflowItem[] = []

  const investmentModel = account && getInvestmentModel(account.key)
  if (investmentModel) {
    const { start, end } = interval ?? getDaysToDate(Date.now(), 30)

    for (const item of investmentModel.cashflowItems) {
      if (item.date < start) continue
      if (item.date > end) break
      items.push(item)
    }

    logger.debug("Returning %d items, range={%s}", items.length, Logger.Range(start, end))
  }

  return items
}

export function calcPercentChange(from:number | undefined, to:number | undefined) {
  const valueChange = (to ?? 0) - (from ?? 0)

  // logger.debug("calcPercentChange: from=%f, to=%f, change=%f", from, to, valueChange)
  
  let percentChange, percentFormat
  if (from) {
    percentChange = valueChange / from
    const change = Math.abs(percentChange)

    if (change > 10) {
      percentFormat = NumberFormats.percentx
    } else if (change > 0.1) {
      percentFormat = NumberFormats.percent0
    } else {
      percentFormat = NumberFormats.percent1
    }
  }

  return { percentChange, percentFormat, valueChange }
}
