import { Model } from "../../model/Model"
import { ModelKeys } from "../../model/ModelKeys"
import { RankedItem, RankedSearchOptions, Rankings, searchAndRankItems } from "../../model/RankedSearch"
import { saveItems } from "../../service/ModelService"
import { BankStmtRuleItem } from "../../types/BankStmtRuleItem"
import { ItemProps } from "../../types/Item"
import { TransactionItem } from "../../types/TransactionItem"
import { Logger } from "../../utils/Logger"
import { BackgroundBuilder } from "../BackgroundBuilder"
import { TransferMapper } from "../TransferMapper"

const logger = new Logger("bankstmt.BankStmtApplyRules")

export class BankStmtApplyRules extends BackgroundBuilder<TransactionItem> {
  private model:Model
  private rules:BankStmtRuleItem[]
  private transactions:TransactionItem[]
  private searchOptions:RankedSearchOptions<TransactionItem>
  private overwrite:boolean
  private updatedRankedItems = new Map<string,RankedItem<TransactionItem>>()
  private transferMapper:TransferMapper

  constructor(model:Model, rules:BankStmtRuleItem[], transactions:TransactionItem[], overwrite=false) {
    super()
    this.model = model
    this.overwrite = overwrite
    this.transactions = transactions
    this.rules = rules
    this.total = this.rules.length
    this.searchOptions = this.applyRulesSearchOptions
    this.transferMapper = new TransferMapper(model)
  }

  protected onStart() {
    super.onStart()
    logger.start("doWork", "Applying %d rules to %d transactions, overwrite=%s", 
                  this.rules.length, this.transactions.length, this.overwrite)
  }

  protected doWork() {
    let updates = 0
    while (this.workRemaining && this.timeRemaining) {
      const rule = this.rules[this.count++]

      // Get a list of ranked items (RI) by matching the current rule against ALL transactions
      const rankedItems = searchAndRankItems(this.transactions, rule.name, this.searchOptions)

      if (logger.isDebugEnabled && rankedItems.size > 0) {
        logger.debug("doWork: Matched %d/%d items searching for rule='%s'", 
                      rankedItems.size, this.transactions.length, rule.name)
      }

      for (const rankedItem of rankedItems.values()) {
        const trans = rankedItem.item

        // Manual overrides do not get overwritten
        if (!this.overwrite && 
           (trans.ruleKey === ModelKeys.bank.ruleOveride || trans.ruleKey === ModelKeys.bank.ruleApiDefined)) {
          logger.trace("doWork: Skipping manual override trans=%o", trans)
          continue
        }

        // If trans has already been updated with higher ranked match, then skip
        const updatedRI = this.updatedRankedItems.get(rankedItem.item.key)
        if (updatedRI && updatedRI.rank >= rankedItem.rank) {
          continue
        }

        // If trans has already been updated using a rule with a higher priority (1-5, 1 is highest), then skip
        if (updatedRI) {
          const updatedItemRule = this.model.getItem<BankStmtRuleItem>(updatedRI.item.ruleKey)
          if (updatedItemRule && updatedItemRule.priority < rule.priority) {
            continue
          }
        }
        
        // Ensure transfer in/out aligns with money in/out
        const { categoryKey, typeKey } = this.transferMapper.getSafeCategoryForValue(rule.categoryKey, trans.typeKey, trans.value)

        // Updated transaction
        rankedItem.item = {...trans, categoryKey, typeKey, ruleKey:rule.key }
        this.updatedRankedItems.set(trans.key, rankedItem)
        updates++
      }
    }

    logger.debug("doWork: Processed %d/%d rules (%d%) in %dms, %d updates", 
                  this.count, this.total, this.percentComplete, this.timeElapsed, updates)
  }

  protected onFinish() {
    const modifiedTrans:TransactionItem[] = []

    // Determine which items have changed
    for (const trans of this.transactions) {
      const newTrans = this.updatedRankedItems.get(trans.key)?.item
      if (newTrans && (trans.categoryKey !== newTrans.categoryKey || trans.typeKey !== newTrans.typeKey || trans.ruleKey !== newTrans.ruleKey)) {
        modifiedTrans.push(newTrans)
      }
    }
    logger.debug("onFinish: %d/%d transactions were modified", modifiedTrans.length, this.updatedRankedItems.size)

    // Save all changes
    if (modifiedTrans.length > 0) {
      saveItems(modifiedTrans)
    }

    // Record modified transaction count, and call super
    this.modified = modifiedTrans
    super.onFinish()

    // Finish message
    logger.finish("doWork", "Applied %d rules to %d transactions, %d modified", 
                  this.rules.length, this.transactions.length, this.modified.length)
  }

  get applyRulesSearchOptions() {
    const { CONTAINS, WORD_STARTS_WITH, STARTS_WITH } = Rankings

    return {
      props: [
        { prop: ItemProps.name, threshold: WORD_STARTS_WITH },
        { prop: ItemProps.description, threshold: CONTAINS },
        { prop: (item:TransactionItem) => this.model.getItemName(item.accountKey), threshold: STARTS_WITH },
        { prop: (item:TransactionItem) => this.model.getItemName(item.typeKey), threshold: STARTS_WITH },
      ]
    }
  }
}
