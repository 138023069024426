import { ResponsiveContainer, Tooltip, Treemap } from "recharts"
import { Item } from "../../../core/types/Item"
import { Logger } from "../../../core/utils/Logger"
import { NumberFormats, formatCurrency } from '../../../core/utils/Numbers'
import { useModel } from "../../../hooks/context/ModelContext"
import { usePivotTables } from "../../../hooks/pivot/usePivotTables"
import { CartesianChartProps } from "../CartesianChart"
import { useChartProfile } from "../useChartProfile"

const logger = new Logger("charts.CashflowTreemapChart")

export function CashflowTreemapChart(props:Partial<CartesianChartProps>) {
  const { name } = usePivotTables()
  const { size } = useChartProfile()
  const { noHeader } = props

  const data = useCashflowTreemapData()

  // const { linearGradient, gradientFillUrl, gradientLineUrl } = useGradientOffset(data, "Balance")

  // For responsive, prioritise height over aspect 
  // let { height, aspect } = props
  // aspect = (height) ? undefined : aspect || size.aspect4x3

  const height = 600
  const aspect = size.aspect4x3
  
  logger.setContext(name).debug("Rendering: name=%s, data=%o", name, data)

  return (
    <div id="CashflowTreemapChart" className="chart">
      {!noHeader &&
        <h3>Cashflow Treemap</h3>
      }

      <ResponsiveContainer className="chart-body" height={height}>
        <Treemap
          data={data}
          dataKey="size"
          aspectRatio={aspect}
          stroke="#fff"
          fill="#8884d8"
          type="flat"
        >
          <Tooltip/>
        </Treemap>    
      </ResponsiveContainer>
    </div>
  )
}

function useCashflowTreemapData() {
  const { model } = useModel()
  const { pivotTable } = usePivotTables()
  const { columns, totals } = pivotTable

  const nodes:TreemapNode[] = []

  for (const column of columns) {
    addNode(column, nodes)
  }

  return nodes

  function addNode(item:Item, nodes:TreemapNode[]) {
    const amount = totals.cell(item.key).total
    const size = Math.abs(amount)
    const name = `${item.name} ${formatCurrency(amount, NumberFormats.currency0)}`

    const node:TreemapNode = { name, size }
    nodes.push(node)

    const children = model.childrenSorted(item.key)
    if (children.length > 0) {
      node.children = []

      for (const child of children) {
        addNode(child, node.children)
      }
    }
  }
}

interface TreemapNode {
  name: string
  size: number
  children?: TreemapNode[]
}
