import { formatDistance } from 'date-fns'
import { ValuationModel } from '../../../core/invest/ValuationModel'
import { NumberFormats, formatAssetValue, formatNumber } from '../../../core/utils/Numbers'
import { useInvestmentModel } from '../../../hooks/context/InvestmentModelContext'

export function PortfolioPriceNarrative(props: any) {
  const { asset, valuationCalc, isUnitised } = useInvestmentModel<ValuationModel>()

  if (valuationCalc === undefined || valuationCalc.count < 2) {
    return <></>
  }

  return (
    <p id="AssetPriceNarrative">
      The {isUnitised ? "unit price" : "value"} of {asset.name} has
      <b> {valuationCalc.increase >= 0 ? "increased" : "decreased"} by {formatNumber(valuationCalc.increasePercent, NumberFormats.percent1)} ({formatAssetValue(valuationCalc.increase)}) </b>
      in {formatDistance(valuationCalc.lastDate, valuationCalc.firstDate)}, a compound annual growth rate (CAGR)
      of <b>{formatNumber(valuationCalc.cagr, NumberFormats.percent1)} per year</b>.
    </p>
  )
}
