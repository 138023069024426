import { updateItems } from '../../../core/service/ModelAction'
import { Item } from "../../../core/types/Item"
import { Logger } from "../../../core/utils/Logger"
import { NumberFormats, formatNumber, parseNumber } from '../../../core/utils/Numbers'
import { clzName, toSentenceCase } from '../../../core/utils/Utils'
import { useModel } from "../../../hooks/context/ModelContext"

const logger = new Logger("component.Editable")

interface EditableProps<T extends Item> {
  item: T
  property: any // keyof T
  placeholder?: string | number
  readonly?: boolean
  numberFormat?: any
  emptyStringIsUndefined?: boolean
  // className?: string
  onChange?: (item:T, property:any, newValue:any) => void
}

export function Editable<T extends Item>(props:EditableProps<T>) {
  const { model } = useModel()

  const { item, placeholder, readonly, onChange } = props

  const property:keyof Item = props.property
  const numberFormat = props.numberFormat || NumberFormats.integer
  const isNumeric = props.numberFormat !== undefined
  const emptyStringIsUndefined = props.emptyStringIsUndefined ?? true

  const value = item[property]

  // Format numerics for display
  let valueStr = value
  if (typeof value === "number") {
    valueStr = formatNumber(value as number, numberFormat)
  } else if (typeof value === "string") {
    if (emptyStringIsUndefined && value.trim().length === 0) {
      valueStr = undefined
    }
  }

  // Format placeholder
  let placeholderStr
  if (valueStr === undefined) {
    if (typeof placeholder === "number") {
      placeholderStr = formatNumber(placeholder as number, numberFormat)
    } else {
      placeholderStr = placeholder ?? `Enter a ${toSentenceCase(property).toLowerCase()}...`
    }
  }

  logger.trace("Rendering: %s='%s', valueStr=%s", property, value, valueStr)
  
  const className = clzName({ editable:!readonly, numeric:isNumeric, readonly:readonly })

  return (
    <div contentEditable={!readonly} 
         tabIndex={readonly ? undefined : 0}
         onBlur={onBlur}
        //  onFocus={onFocus}
         onClick={(e:any) => (!readonly) && e.stopPropagation()}
         className={className}
         data-placeholder={placeholderStr}
      >
      { valueStr }
    </div>
  )

  // return (
  //   <input type="text"
  //          defaultValue={valueStr}
  //          placeholder={placeholderStr}
  //          tabIndex={readonly ? undefined : 0}
  //          onBlur={onBlur}
  //          onFocus={onFocus}
  //          readOnly={readonly} />
  // )

  // /** TODO Implement selectAll for contentEditable */
  // function onFocus(e:any) {
  //   e.target.select()
  // }

  function onBlur(e:any) {
    // logger.debug("onBlur: newValue=%s", newValue)
    let newValue = e.target.innerText
    if (newValue !== null) {
      // Convert to number if required
      if (isNumeric && typeof newValue === "string") {
        newValue = parseNumber(newValue)
      }

      // Trim string values, empty/blank string gets set to undefined
      else if (typeof newValue === "string") {
        newValue = newValue.trim()
        if (emptyStringIsUndefined && newValue.length === 0) {
          newValue = undefined
        }
      }

      if (newValue !== value) {
        logger.debug("onBlur: property=%s, value='%s', newValue='%s'",
                      property, value, newValue, item)

        if (onChange) {
          onChange(item, property, newValue)
        } else {
          updateItems(model, [item.key], property, newValue)
        }
      }
    }
  }
}
