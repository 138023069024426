import { ReactNode } from "react"
import { Navigate, useLocation, useSearchParams } from "react-router-dom"
import { getCurrentUser } from "../../core/user/UserService"
import { Logger } from "../../core/utils/Logger"
import { JemstoneLayout } from "./JemstoneLayout"

const logger = new Logger("layouts.ProtectedRoute")

export function ProtectedRoute() : ReactNode {
  const currentUser = getCurrentUser()
  
  if (!currentUser) {
    return <RedirectToLogin />
  }

  logger.debug("Rendering: currentUser=%s", currentUser.email)
  return <JemstoneLayout/>
}

function RedirectToLogin() {
  const location = useLocation()
  const [ searchParams ] = useSearchParams()

  logger.debug("Redirecting to /login from %o", location)
    
  const fileKey = searchParams.get("fileKey")
  return <Navigate to="/login" state={{ from:location.pathname, fileKey }} />
}
