import { Item, setItemPropNames } from "./Item"

/**
 * Attributes of interest for a specific building
 */
export interface BuildingItem extends Item {
  /** Area of the building */
  area: number

  /** Number of floors */
  floors: number

  /** Number of living areas */
  livingRooms: number

  /** Number of bedrooms */
  bedrooms: number

  /** Number of bathrooms */
  bathrooms: number

  /** Number of garages */
  garages: number

  /** Number of carparks */
  carparks: number
}

type BuildingItemProp = keyof BuildingItem

type BuildingItemRecord = Record<BuildingItemProp, BuildingItemProp>

export const BuildingItemProps:BuildingItemRecord = {
  key: "key",
  code: "code",
  name: "name",
  description: "description",
  parentKey: "parentKey",
  typeKey: "typeKey",
  links: "links",
  sortOrder: "sortOrder",
  status: "status",
  modifiedDate: "modifiedDate",
  area: "area",
  floors: "floors",
  livingRooms: "livingRooms",
  bedrooms: "bedrooms",
  bathrooms: "bathrooms",
  garages: "garages",
  carparks: "carparks",
}

setItemPropNames(BuildingItemProps)

export const BuildingItemPropNames = Object.keys(BuildingItemProps) as BuildingItemProp[]
