import { Logger } from "../../../core/utils/Logger"
import { TimeScale } from '../../../core/utils/TimeScale'
import { FilterProvider } from '../../../hooks/context/FilterContext'
import { InvestmentModelProvider, useInvestmentModel } from '../../../hooks/context/InvestmentModelContext'
import { CardSelector } from "../CardSelector"
import { CashflowCard } from '../cashflow/CashflowCard'
import { getDashboardScale } from '../dashboard/DashboardCard'
import { PortfolioPerformanceCard } from './PortfolioPerformanceCard'

const logger = new Logger("PortfolioSection")

export function PortfolioSection(props:any) {
  const { children, hasAssets } = useInvestmentModel()

  const scale:TimeScale = { start:0, end:Date.now(), units:"1D" }

  return (
    <FilterProvider name={logger.name} initFilter={getDashboardScale}>
      <CardSelector open />
      <CashflowCard />

      { hasAssets &&
        <FilterProvider name={logger.name + ".Performance"} initFilter={{scale}}>
          <PortfolioPerformanceCard />
        </FilterProvider>
      }
    
      { children.map(child =>
        <InvestmentModelProvider item={child.item} key={child.key}>
          <FilterProvider name={logger.name} initFilter={getDashboardScale}>
            <CardSelector />
          </FilterProvider>
        </InvestmentModelProvider>
      )}
    </FilterProvider>
  )
}
