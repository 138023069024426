import { Atom, WritableAtom, createStore } from "jotai"
import { Logger } from "../utils/Logger"

const logger = new Logger("core.AtomUtils")

let atomStore = createStore()

export function createAtomStore() {
  logger.warn("Creating a new atom store")
  atomStore = createStore()
}

export function getAtomStore() {
  return atomStore
  // return getDefaultStore()
}

export function getAtomValue<T>(atom:Atom<T>) : T {
  const store = getAtomStore()
  return store.get(atom)
}

export function setAtomValue<T>(atom:WritableAtom<T, T[], unknown>, value:T) {
  const store = getAtomStore()
  store.set(atom, value)
}

export function subscribeAtom<T>(atom:Atom<T>, listener: () => void) {
  const store = getAtomStore()
  return store.sub(atom, listener)
}