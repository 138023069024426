import { atom } from "jotai"
import { Model } from "../model/Model"
import { formatDate } from "../utils/DateFormat"
import { Logger } from "../utils/Logger"
import { getAtomValue, setAtomValue } from "./AtomUtils"

export const logger = new Logger("core.ModelAtoms")

/**
 * Describes the data structure provided by ModelContext
 */
export interface ModelState {
  model: Model
  updated: string
}

export class ModelAtoms {
  /**
   * The globally available Model
   */
  public static modelAtom = atom<ModelState>({ model: new Model(), updated:"" })

  /**  */
  public static loadModelIntervalAtom = atom(0)

  public static reset() {
    setAtomValue(ModelAtoms.modelAtom, { model: new Model(), updated:"" })
  }

  /** Callback from ModelService whenever model is updated */
  public static async onModelUpdated(model:Model, initialLoad:boolean) {
    logger.start("onModelUpdated", "initialLoad=%s", initialLoad)
    
    const newState:ModelState = { model, updated: formatDate(Date.now(),"HH:mm:ss.SSS") }
    setAtomValue(ModelAtoms.modelAtom, newState)

    logger.finish("onModelUpdated", "lastUpdated=%s", newState.updated)
  }
}

export function getModel(): Model {
  return getAtomValue(ModelAtoms.modelAtom).model
}
