import { isSameDay } from "date-fns"
import { useAtomValue } from "jotai"
import { Transaction } from "../../../core/model/ModelKeys"
import { CashflowItem } from "../../../core/types/CashflowItem"
import { TransactionItem } from "../../../core/types/TransactionItem"
import { formatDate } from '../../../core/utils/DateFormat'
import { Logger } from "../../../core/utils/Logger"
import { NumberFormats, formatNumber, formatPercent } from '../../../core/utils/Numbers'
import { clzName } from "../../../core/utils/Utils"
import { CashflowSearchAtoms } from "../../../hooks/cashflow/CashflowSearchAtoms"
import { useFilterContext } from "../../../hooks/context/FilterContext"
import { useInvestmentModel } from "../../../hooks/context/InvestmentModelContext"
import { useModel } from "../../../hooks/context/ModelContext"
import { useTransferAccount } from "../../../hooks/transfer/useTransferAccount"
import { ShowIfDebug } from '../../controls/ShowIf'
import { setDialogItem } from "../../controls/dialog/ItemDialog"
import { CardLink } from "../../views/ViewLinks"
import { PivotColumnLink } from "./CashflowPivotTable"

const logger = new Logger("table.CashflowTable")

export function CashflowTable(props:any) {
  const { name } = useInvestmentModel()
  const { filter, filterItem } = useFilterContext()

  const showAccount = true
    
  const { tableRows, cashflowItems, closingBalance, moneyIn, moneyOut } = useTableRows(showAccount)

  logger.setContext(name)
        .debug("Rendering: %d/%d items, filter=%s", tableRows.length, cashflowItems.length, filter.id)
  
  return (
    <table id="CashflowTable" className="table cashflow-table sticky-headers">
      <thead>
        <tr>
          { showAccount &&
            <th className="account">Account</th>
          }
          <th className="name">{ filterItem?.name || "Name" }</th>
          <th className="description">Description</th>
          <th className="category">Category</th>
          <th className="money-in">Money In</th>
          <th className="money-out">Money Out</th>
          <th className="amount">Amount</th>
          <th className="total">Total</th>
          <th className="balance">Balance</th>
          <ShowIfDebug>
            <th>Days</th>
            <th>Rate</th>
            <th>Interest</th>
            <th>Earnings</th>
            <th>Offset</th>
          </ShowIfDebug>
        </tr>
      </thead>
      <tbody>
        { tableRows }
      </tbody>
      <tfoot className="totals">
        <tr>
          <th className="text-start">Total</th>
          { showAccount && <th className="account"/> }
          <td className="description" />
          <td className="category" />
          <PivotCellLink className="money-in"  currency={moneyIn} />
          <PivotCellLink className="money-out" currency={moneyOut} />
          <PivotCellLink className="amount"    currency={moneyIn + moneyOut} />
          <PivotCellLink className="total"     currency={moneyIn + moneyOut} />
          <PivotCellLink className="balance"   currency={closingBalance} />
          <ShowIfDebug>
            <td colSpan={5}/>
          </ShowIfDebug>
        </tr>
      </tfoot>
    </table>
  )
}

function useTableRows(showAccount:boolean) {
  const { model } = useModel()
  const cashflowItems = useAtomValue(CashflowSearchAtoms.searchResults)
  // const { cashflowItems } = useFilterContext()

  logger.start("useTableRows", "Creating tableRows: %d items", cashflowItems.length)
  
  const tableRows:JSX.Element[] = []
  const openingBalance = cashflowItems.at(0)?.balance || 0
  const closingBalance = cashflowItems.at(-1)?.balance || 0

  let moneyIn=0, moneyOut=0, cumTotal=0

  for (let i=0; i < cashflowItems.length; i++ ) {
    const item = cashflowItems[i]

    cumTotal += item.amount
    if (item.amount > 0) moneyIn += item.amount
    if (item.amount < 0) moneyOut += item.amount

    tableRows.push(          
      <TableRow item={item} 
        isNewDay={(i === 0) || !isSameDay(item.date, cashflowItems[i-1].date)} 
        cumTotal={cumTotal}
        showAccount={showAccount}
        onClick={() => setDialogItem(model.getItemParent(item), true)}
        key={item.key} />
    )
  }
  logger.finish("useTableRows", "Created %d tableRows from %d items", tableRows.length, cashflowItems.length)

  return { tableRows, cashflowItems, openingBalance, closingBalance, moneyIn, moneyOut }
}

function TableRow({item, isNewDay, showAccount, cumTotal, onClick} : { 
  item:CashflowItem, 
  isNewDay:boolean, 
  showAccount:boolean, 
  cumTotal:number
  onClick:() => void
}) {
  return (
  <>
    { isNewDay &&
      <tr className="subtitle">
        <th className="date">{formatDate(item.date || 0)}</th>
        { showAccount && <th className="account"/> }
        <td className="description" />
        <td className="category" />
        <td className="money-in" />
        <td className="money-out" />
        <td className="amount" />
        <td className="total" />
        <td className="balance" />
        <ShowIfDebug>
          <td colSpan={5}/>
        </ShowIfDebug>
      </tr>
    }
    <tr className="link" onClick={onClick}>
      { showAccount &&
        <th className="account">
          <PivotColumnLink itemKey={item.accountKey} />
        </th>
      }
      { Transaction.isInterestRate(item)
      ? <>
          <th className="name">Interest Rate Change to {formatNumber(item.amount, NumberFormats.percent2)}</th>
          <td className="description" />
          <td className="category"></td>
          <td className="money-in"></td>
          <td className="money-out"></td>
          <td className="amount"></td>
          <td className="total"></td>
        </>
      : <>
          <Name item={item}/>
          <Description item={item}/>
          <CategoryName item={item}/>
          <AmountCell currency={item.amount} />
          <PivotCellLink className="total" currency={cumTotal} />
        </>
      }
      <PivotCellLink className="balance" currency={item.balance} />
      <ShowIfDebug>
        <PivotCellLink value={item.days} />
        <PivotCellLink percent={item.interestRate} />
        <PivotCellLink currency={item.interest} />
        <PivotCellLink currency={item.earnings} />
        <PivotCellLink currency={item.offsetBalance} />
      </ShowIfDebug>
    </tr>
  </>
  )
}

function AmountCell(props:any) {
  const value = props.currency
  const valueStr = value === undefined ? "" : formatNumber(value, NumberFormats.number2)

  if (value >= 0) return (
    <>
      <td className="money-in">{valueStr}</td>
      <td className="money-out"></td>
      <td className="amount">{valueStr}</td>
    </>
  )

  return (
    <>
      <td className="money-in"></td>
      <td className="money-out">{valueStr}</td>
      <td className="amount negative">{valueStr}</td>
    </>
  )
}

function Name({item}:{item:CashflowItem}) {
  const { model } = useModel()

  // For a CashflowItem, the parent is the TransactionItem from which the CashflowItem
  // is derived - which may be from a bank statement or a budget item
  const transaction = model.getItem<TransactionItem>(item.parentKey)
  const { transferAccount, transferDescription } = useTransferAccount(transaction, undefined, item)

  return (
    <th className="name">
      { (transferAccount && transferDescription) 
        ? <CardLink item={transferAccount} hideIcon>{transferDescription}</CardLink>
        : item.name 
      }
      { item.bankStmt ? "*" : "" }
    </th>
  )
}

function Description({item}:{item:CashflowItem}) {
  return (
    <td className="description">
      {item.description}
    </td>
  )
}

function CategoryName({item}:{item:CashflowItem}) {
  const { model } = useModel()

  return <td className="category">{model.getItemName(item.categoryKey)}</td>
}

function PivotCellLink(props: {
  value?: number
  percent?: number
  currency?: number
  className?: string
  onClick?: () => void
}) {
  const { value, percent, currency, onClick } = props

  const displayStr = (currency !== undefined) ? formatNumber(currency, NumberFormats.number2) :
                     (percent !== undefined)  ? formatPercent(percent, NumberFormats.percent2) :
                     (value !== undefined)    ? formatNumber(value, NumberFormats.integer) : ""

  const className = clzName(
    props.className, 
    { link: onClick && displayStr !== "" },
    { negative: (currency || percent || value || 0) < 0 },
  )
                  
  return (
    <td className={className} onClick={onClick}>
      { displayStr }
    </td>
  )
}
