import { Logger } from "../../../core/utils/Logger"
import { FormDataProvider } from "../../../hooks/context/FormContext"
import { AppInfo } from "../../../VersionInfo"
import { BankStmtFileUploadCard } from "../../cards/bankstmt/BankStmtFileUploadCard"
import { Card } from "../../controls/Card"
import { WizardProvider } from "../context/WizardContext"
import { WizardHeader } from "../WizardHeader"

const logger = new Logger("wizards.BankStmtFileWizard")

const pageIds = [
  "BankStmtFilePage"
]

const primaryItemProp = "bankStmtFileItem"

export function BankStmtFileWizard() {
  logger.debug("Rendering:")

  return (
    <WizardProvider name="BankStmtFileWizard" pageIds={pageIds} primaryItemProp={primaryItemProp}>
      <FormDataProvider>
        <BankStmtFilePage/>
      </FormDataProvider>
    </WizardProvider>
  )
}

function BankStmtFilePage() {
  const title = "Select Bank Statement Files to Load"

  return (
    <Card id="BankStmtFilePage" className="wizard-page">
      <WizardHeader title={title}>
        <p>
          Select one or more bank statement files to be loaded into {AppInfo.name}. 
          You will need to have exported transaction data from your Internet Banking 
          or Fund Manager's website into OFX or CSV files, each of which contains 
          transactions for a specific date range.
        </p>
      </WizardHeader>
      <BankStmtFileUploadCard noHeader/>
    </Card>
  )
}
