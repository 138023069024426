import { Bar, Cell, Line } from "recharts"
import { PivotCell } from "../../../core/builder/PivotTable"
import { Item } from "../../../core/types/Item"
import { Logger } from "../../../core/utils/Logger"
import { timeScaleEnding } from "../../../core/utils/TimeScale"
import { useFilterContext } from "../../../hooks/context/FilterContext"
import { useInvestmentModel } from "../../../hooks/context/InvestmentModelContext"
import { usePivotContent } from '../../../hooks/pivot/usePivotContent'
import { usePivotTables } from "../../../hooks/pivot/usePivotTables"
import { CartesianChart } from "../CartesianChart"
import { ChartHeader } from "../ChartHeader"
import { ColorPalette, useChartProfile } from "../useChartProfile"

const logger = new Logger("charts.CashflowStackedBarChart")

export function CashflowStackedBarChart(props: { height?:number|string, aspect?:number, noLine?:boolean }) {
  const { item } = useInvestmentModel()
  const { filterStack, filter, scale } = useFilterContext()
  const { isCumTotalContent } = usePivotContent()
  const { colors, size, animationDuration } = useChartProfile()
  const { chartData, chartColors, columns } = useChartData()

  const { aspect, height } = props
  const noLine = (props.noLine === undefined) ? !isCumTotalContent : props.noLine

  logger.debug("Rendering %d data points", chartData.length)
  
  return (
    <div id="CashflowStackedBarChart" className="chart">
      <ChartHeader scale={scale} />
      <CartesianChart data={chartData} 
                      scrollable={false} 
                      height={height}
                      aspect={aspect || size.aspect21x9} 
                      syncId={item.key}
                      onClickXAxis={(e:any) => onClick(e.value)}
                      units={scale.units}
                      barCategoryGap={20}
                      noLegend>
        { columns.map((column,colIndex) =>
          <Bar type="monotone" 
                dataKey={column.name} 
                // stackId="1"
                stroke={chartColors[colIndex]} 
                fill={chartColors[colIndex]}
                maxBarSize={12}
                opacity={0.8}
                key={column.key}>
            { chartData.map((dp) => (
              // @ts-ignore
              <Cell key={dp.key} 
                    radius={getCellRadius(column, colIndex, dp) as any}
                    cursor="pointer" 
                    onClick={() => onClick(dp.date)} />
              ))
            }
          </Bar>
        )}
        { !noLine &&
          <Line dataKey="Balance" 
                type="monotone" 
                stroke={colors.blue[7]} 
                dot={{r:2}} 
                animationDuration={animationDuration} />
        }
      </CartesianChart>
    </div>
  )

  function getCellRadius(column:Item, colIndex:number, dp:any) {
    return (colIndex === dp.maxIndex || colIndex === dp.minIndex) ? [2,2,0,0] : 0
  }

  function onClick(endDate:number) {
    // const units = filter.scale.units
    // const scale = {...intervalEnding(units, endDate), units: units}
    const scale = timeScaleEnding(filter.scale.units, endDate)
    filterStack.push({ scale })
  }
}

interface DP {
  key: any
  date: number
  Cashflow: number
  Balance:  number
  CumTotal: number
  minIndex: number
  maxIndex: number
}

function useChartData() {
  const { pivotTable:{ rows, columns } } = usePivotTables()
  const { isBalanceContent, isCumTotalContent } = usePivotContent()

  const chartData:DP[] = []
  const chartColors:string[] = []
  const colorPalette = new ColorPalette()

  for (const row of rows) {
    if (!row.openingRow && row.endDate > 0) {
      const dp:any = { 
        key: row.key,
        date: row.endDate, 
        Cashflow: row.total,
        Balance: row.balance,
        CumTotal: row.cumTotal,
        minIndex: -1,
        maxIndex: -1,
      }

      // Used to suppress very small values (< 1% of total) from chart
      const threshold = Math.abs(isBalanceContent ? row.balance : row.cumTotal) / 100

      for (let i=0;  i < columns.length;  i++) {
        const column = columns[i]
        const amount = getAmount(row.cell(column.key))

        if (amount !== undefined && Math.abs(amount) >= threshold) {
          dp[column.name] = amount
          chartColors[i] = colorPalette.get(amount)

          if (amount > 0) {
            dp.maxIndex = Math.max(i, dp.maxIndex)
          } else {
            dp.minIndex = Math.max(i, dp.minIndex)
          }
        }
      }
      chartData.push(dp)
    }
  }

  return { chartData, chartColors, columns }

  function getAmount(cell:PivotCell) {
    return (
      isBalanceContent  ? cell.balance : 
      isCumTotalContent ? cell.cumTotal : cell.total
    )
  }
}
