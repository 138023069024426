import { ReactElement } from "react"
import { Link } from "react-router-dom"
import { DataFileName } from "../../../core/data/DataItems"
import { Logger } from "../../../core/utils/Logger"

const logger = new Logger("wizards.PersonaList")

export interface Persona {
  name: string
  icon: string
  data: DataFileName
  badges: Badge[]
  narrative: ReactElement
}

interface Badge {
  label: string
  value: string
}

export function PersonaList({ selectedPersona, onChange }: { selectedPersona?:Persona, onChange?: (persona?: Persona) => void }) {
  logger.debug("Rendering: active=%s", selectedPersona?.name)

  return (
    <ul className="grid persona">
      { getPersonas().map((persona, index) => 
        <PersonaItem persona={persona} onChange={onChange} key={index} />
      )}
    </ul>
  )
  
  function PersonaItem({persona, onChange}:{
    persona: Persona
    onChange?: (persona?: Persona) => void
  }) {
    const isActive = (persona === selectedPersona)
    
    return (
      <li className={isActive ? "active" : undefined} onClick={onClick}>
        <h3>
          <Link to=".">{persona.name}</Link>
          <i className={persona.icon} />
        </h3>
        <ul className="badges">
          {persona.badges.map(badge => <li>{badge.label}: <b>{badge.value}</b></li>
          )}
        </ul>
        {persona.narrative}
      </li>
    )
  
    function onClick(e:any) {
      e.stopPropagation()
      
      logger.debug("onClick: isActive=%s", isActive)

      if (onChange) {
        onChange(isActive ? undefined : persona)
      }
    }
  }
}

export function getPersonas() {
  return personas
}

const personas: Persona[] = [
  {
    name: "Jane: Individual",
    icon: "fal fa-user-tie-hair-long",
    data: "Jane",
    badges: [
      { label: "Income", value: "$110k" },
      { label: "Age", value: "25-35" },
      { label: "Children", value: "none" },
      { label: "Lives", value: "Wellington" },
      { label: "Occupation", value: "Recruitment Consultant" },
    ],
    narrative: 
    <div>
      <p>
        Jane is a young professional female living alone and earning an above average income (that increases regularly) that allows her to grow her savings.
      </p>
      <p>
        She would like to buy a home, but cannot see a pathway to saving a deposit while paying high rent and other living costs, and still enjoying some travel at this stage in her life.
      </p>
    </div>
  },

  {
    name: "John: Professional",
    icon: "fal fa-user-tie",
    data: "John",
    badges: [
      { label: "Income", value: "$250k+" },
      { label: "Age", value: "45-65" },
      { label: "Children", value: "none" },
      { label: "Lives", value: "Auckland" },
      { label: "Occupation", value: "Executive Manager" },
    ],
    narrative: 
    <div>
      <p>
        John is a mid-senior executive at the peak of his earning powers, who lives with his wife at their home in Auckland.
      </p>
      <p>
        He wants to ensure a comfortable retirement that maintains their standard of living, and to ensure their estate provides a meaningful inhertitance for thier children.
      </p>
    </div>
  },

  {
    name: "Emma & Josh: Family",
    icon: "fal fa-people",
    data: "Emma",
    badges: [
      { label: "Income", value: "$150k+" },
      { label: "Age", value: "30-50" },
      { label: "Children", value: "2+" },
      { label: "Lives", value: "Hamilton" },
      { label: "Occupation", value: "Plumber" },
    ],
    narrative: 
    <div>
      <p>
        Emma & Josh have a young family, Josh runs his own plumbing business and Emma manages accounts and administrative tasks.
      </p>
      <p>
        They have recently purchased their own home, but this leaves no money for saving or investing and they want a plan to a secure future.
      </p>
    </div>
  },

  {
    name: "Parker: Ninja",
    icon: "fal fa-user-ninja",
    data: "Parker",
    badges: [
      { label: "Income", value: "$80k" },
      { label: "Age", value: "20-30" },
      { label: "Children", value: "none" },
      { label: "Lives", value: "Auckland" },
      { label: "Occupation", value: "IT Developer" },
    ],
    narrative: 
    <div>
      <p>
        Parker is brilliant young IT developer with aspirations to start his own business, which will require him to use his savings during the early stages.
      </p>
    </div>
  },

  {
    name: "Kath & Bob: Retirees",
    icon: "fal fa-people-pulling",
    data: "Kath",
    badges: [
      { label: "Income", value: "$80k" },
      { label: "Age", value: "65+" },
      { label: "Children", value: "none" },
      { label: "Lives", value: "Waikanae" },
      { label: "Occupation", value: "Part-time Accountant" },
    ],
    narrative: 
    <div>
      <p>
        Kath & Bob are a retired couple who live in their own home on the Kapiti Coast.
      </p>
      <p>
        They have a healthy KiwiSaver balance, and Bob supplements their superannuation by working part-time in an accounting practice.
      </p>
    </div>
  },
]
