import { useState } from "react"
import { PerformanceStats } from "../../../core/builder/performance/PerformanceStats"
import { Item } from "../../../core/types/Item"
import { Logger } from "../../../core/utils/Logger"
import { formatCurrency, ifZero, isZero, NumberFormats } from '../../../core/utils/Numbers'
import { useInvestmentModel } from "../../../hooks/context/InvestmentModelContext"
import { usePortfolioPerformance } from "../../../hooks/portfolio/usePortfolioPerformance"
import { CurrencyCell } from "../../controls/NumericCell"
import { Pill } from "../../controls/Pill"
import { CardLink } from "../../views/ViewLinks"

const logger = new Logger("dashboard.PortfolioSummary")

export function PortfolioSummaryTable() {
  const { name } = useInvestmentModel()
  const [ showPercentChange, setShowPercentChange ] = useState(true)

  const { portfolioPerf } = usePortfolioPerformance(30)

  logger.setContext(name).debug("Rendering for accounts: %o", portfolioPerf.names)

  return (
    <table id="PortfolioSummaryTable" className="table form">
      <tbody>
        { portfolioPerf.accountPerfSorted.map((perf) => 
          <Row perf={perf} column={perf.item} key={perf.key} />
        )}
      </tbody>
      <tfoot>
        <Row label="Balance" perf={portfolioPerf} balance={portfolioPerf.currentBalance} />
      </tfoot>
    </table>
  )

  function Row({ perf, column, label, balance }: { 
    perf?: PerformanceStats, 
    column?: Item, 
    label?: string, 
    balance?: number
  }) {

    const { percentChange, percentFormat, valueChange, 
            openingBalance, currentBalance } = perf ?? {}

    const pillValue  = showPercentChange ? percentChange : valueChange
    const pillFormat = showPercentChange ? percentFormat : NumberFormats.currency0

    const title = `From ${formatCurrency(openingBalance)} to ${formatCurrency(currentBalance)}`

    // Get value to be displayed - depends on settings
    if (!balance && perf && column) {
      balance = perf.balance(column)
    }

    // Don't display if no change and zero balance
    if (isZero(valueChange) && isZero(balance)) {
      return <></>
    }
  
    return (
      <tr>
        <td>
          { label ?? <CardLink item={column} hideIcon /> }
        </td>
        <CurrencyCell value={ifZero(balance)} className="balance"/>
        <td className="change link" onClick={() => setShowPercentChange(!showPercentChange)}>
          <Pill value={pillValue} formatStr={pillFormat} title={title} />
        </td>
      </tr>
    )
  }
}
