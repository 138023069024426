import { useParams } from "react-router-dom"
import { getAllowedChildTypeKeys, ModelKeys } from "../../core/model/ModelKeys"
import { Logger } from "../../core/utils/Logger"
import { InvestmentModelProvider } from "../../hooks/context/InvestmentModelContext"
import { useModel } from "../../hooks/context/ModelContext"
import { ViewContextProvider } from "../../hooks/context/ViewContext"
import { SectionSelector } from "../cards/SectionSelector"
import { JemstoneFilterBar } from "../layouts/JemstoneFilterBar"
import { JemstoneSidebar } from "../layouts/JemstoneSidebar"
import { ViewHeader } from "./ViewHeader"

const logger = new Logger("view.CardView")

export function CardView(props:any) {
  const { model } = useModel()
  const { key } = useParams<any>()

  const rootKey = key || ModelKeys.root
  const rootItem = model.getItem(rootKey)

  if (!model.has(rootKey)) {
    return <>Loading...</>
  }
  
  const createKeys = getAllowedChildTypeKeys(rootItem.typeKey)
  
  logger.debug("Rendering: rootKey=%s, rootItem=%o, createKeys=%o", 
                rootKey, rootItem, createKeys)

  return (
    <ViewContextProvider>
      <ViewHeader rootKey={rootKey} showCreateKeys={createKeys} showTheme showEdit />
      <JemstoneSidebar/>
      
      <main>
        <div id="CardView" className="grid">
          <InvestmentModelProvider item={rootItem}>
            <SectionSelector item={rootItem} />
          </InvestmentModelProvider>
        </div>
      </main>

      <JemstoneFilterBar/>
    </ViewContextProvider>
  )
}
