import { Logger } from "../../core/utils/Logger"
import { SearchAtoms } from "./SearchAtoms"
import { resetAtomKeys } from "../useAtomKey"
import { resetAtomMaps } from "../useAtomMap"
import { resetClipboard } from "../useClipboard"
import { CountersCardAtoms } from "./CountersCardAtoms"
import { TimersCardAtoms } from "./TimersCardAtoms"
import { BankStmtRuleAtoms } from "../bankstmt/BankStmtRuleAtoms"
import { BankStmtTransAtoms } from "../bankstmt/BankStmtTransAtoms"
import { resetFilterBarAtom } from "../filter/useFilterBar"

const logger = new Logger("hooks.resetHookAtoms")

export function resetHookAtoms() {
  logger.debug("resetHookAtoms called")

  resetAtomMaps()
  resetAtomKeys()
  resetClipboard()
  resetFilterBarAtom()
  
  BankStmtRuleAtoms.reset()
  BankStmtTransAtoms.reset()
  SearchAtoms.reset()
  CountersCardAtoms.reset()
  TimersCardAtoms.reset()
}