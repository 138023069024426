import { setProperty } from "../../core/model/Model"
import { Asset } from "../../core/model/ModelKeys"
import { AssetItem, AssetItemProps } from "../../core/types/AssetItem"
import { ItemProps } from "../../core/types/Item"
import { Logger } from "../../core/utils/Logger"
import { NumberFormats } from "../../core/utils/Numbers"
import { useFormItem } from "../../hooks/context/FormContext"
import { useModel } from '../../hooks/context/ModelContext'
import { useAtomRef } from "../../hooks/useAtomRef"
import { DateEditor } from "../controls/editor/DateEditor"
import { Editable } from "../controls/editor/Editable"
import { Field, FieldSet, Form, FormBody } from "../controls/form/Form"
import { FormFooter } from "../controls/form/FormFooter"
import { FormHeader } from "../controls/form/FormHeader"
import { ItemFormSharedFields } from "./ItemFormSharedFields"

const logger = new Logger("forms.AssetItemForm")

export function AssetItemForm(props : {
  item: AssetItem
  formItemKey?: string
  open?: boolean
  noHeader?: boolean
  onClose: () => void
}) {
  const { model } = useModel()
  const { formItemKey, open, noHeader, onClose } = props
  
  const [ asset, setAsset ] = useFormItem(formItemKey ?? "item", props.item)
  const openAtom = useAtomRef(open === true)

  const currencyFormat = NumberFormats.currency0
  const icon = model.getItemIcon(asset) || "fal fa-line-columns"

  const isProperty = Asset.isProperty(asset) || Asset.isCommercial(asset)
  const isUnitised = Asset.isUnitised(asset)

  logger.debug("Rendering: key=%s, open=%s, item=%o, props.item=%o", asset.key, open, asset, props.item)

  return (
    <Form id="AssetItemForm">
      { !noHeader &&
        <FormHeader title="Asset Editor" icon={icon} onClose={onClose} openAtom={openAtom} />
      }
      <FormBody>
        <FieldSet>
          <Field className="name">
            <label>Name</label>
            <Editable item={asset} property={ItemProps.name} onChange={onChange} readonly={false} />
          </Field>
          { !isProperty && 
            <Field>
              <label>Account Number</label>
              <Editable item={asset} property={ItemProps.code} onChange={onChange} readonly={false} />
            </Field>
          }
          <Field className="description">
            <label>Description</label>
            <Editable item={asset} property={ItemProps.description} onChange={onChange} readonly={false} />
          </Field>
        </FieldSet>

        <FieldSet>
          <Field>
            <label>{isProperty ? "Purchase Price": isUnitised ? "Opening Balance" : "Deposit Amount"}</label>
            <Editable item={asset} property={AssetItemProps.purchasePrice} onChange={onChange} numberFormat={currencyFormat} />
          </Field>
          <Field>
            <label>{isUnitised || isProperty ? "Growth Rate (CAGR)" : "Interest Rate"}</label>
            <Editable item={asset} property={AssetItemProps.rate} onChange={onChange} numberFormat={NumberFormats.percent1} />
          </Field>
          <Field>
            <label>{isProperty ? "Purchase Date" : "Open Date"}</label>
            <DateEditor item={asset} property={AssetItemProps.purchaseDate} onChange={onChange} placeholder={Date.now()} />
          </Field>
          <Field>
            <label>{isProperty ? "Sell Date" : "Close Date"}</label>
            <DateEditor item={asset} property={AssetItemProps.sellDate} onChange={onChange} />
          </Field>          
        </FieldSet>

        <ItemFormSharedFields item={asset} onChange={onChange} readonly={false} openAtom={openAtom} />
      </FormBody>
      
      { !noHeader &&
        <FormFooter showClose showDelete showSave onClose={onClose}/>
      }
    </Form>
  )

  function onChange(asset:AssetItem, property:any, value:any) {
    logger.debug("onChange: item.key=%s, property=%s, value=%s", asset.key, property, value)

    const newAsset = setProperty({...asset}, property, value)
    setAsset(newAsset)
  }
}
