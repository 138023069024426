import { Model } from "../../core/model/Model"
import { Transaction } from "../../core/model/ModelKeys"
import { CashflowItem } from "../../core/types/CashflowItem"
import { Item } from '../../core/types/Item'
import { TransactionItem } from "../../core/types/TransactionItem"
import { Logger } from "../../core/utils/Logger"
import { useModel } from "../context/ModelContext"

const logger = new Logger("hooks.useTransferAccount")

export function useTransferAccount(transaction: TransactionItem|string|undefined, 
                                   relatedTransaction?:TransactionItem, 
                                   cashflowItem?: CashflowItem) {
  const { model } = useModel()

  // logger.warn("Called: transaction=%o, cashflowItem=%o", transaction, cashflowItem)

  let transferAccount: Item | undefined
  let transferDescription: string | undefined

  if (transaction !== undefined) {
    // For a CashflowItem, the parent is the TransactionItem from which the CashflowItem
    // is derived - which may be from a bank statement or a budget item
    if (cashflowItem?.bankStmt) {
      transaction = model.getItem<TransactionItem>(cashflowItem.parentKey)
    }

    else if (typeof transaction === "string") {
      transaction = model.getItem<TransactionItem>(transaction)
    }

    const isTransferIn = Transaction.isTransferIn(transaction)
    const isTransferOut = Transaction.isTransferOut(transaction)

    if (isTransferIn || isTransferOut) {
      if (cashflowItem?.bankStmt) {
        transferAccount = getAccountItem(model, cashflowItem.name)
      }
      else if (transaction.relatedTransactionKey) {
        relatedTransaction ??= model.getItem<TransactionItem>(transaction.relatedTransactionKey)
        transferAccount = model.getItem(relatedTransaction?.parentKey)
      }

      const fromTo = isTransferIn ? "From " : "To "
      transferDescription = fromTo + (transferAccount?.name ?? "Account...")
    }
  }

  logger.trace("Returning: transferAccount=%s, transferDescription=%s, transaction=%o, relatedTransaction=%o", 
                transferAccount?.name, transferDescription, transaction, relatedTransaction)

  return { transferAccount, transferDescription, relatedTransaction }
}

function getAccountItem(model: Model, accountNumber: string | undefined) {
  let accountCode = accountNumber
  try {
    if (accountCode !== undefined) {
      const re = /\d+/g
      const parts = accountCode.toString().match(re)
      // logger.debug("AccountLink: name=%s, parts=%o", item.name, parts)
      if (parts?.length === 2) {
        accountCode = parts.join("********")
      }
      if (parts?.length === 4) {
        accountCode = parts.join("-")
      }
    }

    return accountCode ? model.getItem(accountCode) : undefined
  } catch (e:any) {
    const message = logger.error("getAccountItem","accountCode=%o, type=%s", accountCode, typeof accountCode)
    throw Error(message, { cause:e })
  }
}
