
export const ModelCodes = {
  root:          "ROOT",
  settings:      "SETTINGS",
  parties:       "PARTIES",
  diagnostics:   "DIAGNOSTICS",
  recycleBin:    "RECYCLE-BIN",
  type: {
    root:        "TYPES",
    setting:     "ZZ-SETTING",
    category:    "ZZ-CATEGORY",
    folder:      "ZZ-FOLDER",
    link:        "ZZ-LINK",
    party:       "ZZ-PARTY",
    valuation:   "ZZ-VALUATION",
    building:    "ZZ-BUILDING",
  },
  account: {
    root:        "ACCOUNT-TYPES",
    checking:    "CHECKING",
    savings:     "SAVINGS",
    deposit:     "TERMDEPOSIT",
    loan:        "LOAN",
    creditcard:  "CREDITCARD",
    wallet:      "WALLET",
  },
  asset: {
    root:        "ASSET-TYPES",
    portfolio:   "PORTFOLIO",
    property:    "PROPERTY",
    commercial:  "COMMERCIAL",
    investment:  "INVESTMENT",
    kiwisaver:   "KIWISAVER",
    stock:       "STOCK",
  },
  category: {
    root:         "CATEGORIES",
    income:       "CAT-INCOME",
    expense:      "CAT-EXPENSE",
    finance:      "CAT-FINANCE",
    tax:          "nzfcc_ckouvvzbj005z08mle3z667go",
    balance:      "CAT-BALANCE",
    deposit:      "CAT-DEPOSIT",
    withdrawal:   "CAT-WITHDRAWAL",
    transferIn:   "CAT-TRANSFER-IN",
    transferOut:  "CAT-TRANSFER-OUT",
    payment:      "CAT-PAYMENT",
    buy:          "CAT-BUY",
    sell:         "CAT-SELL",
    earnings:     "CAT-EARNINGS",
    interest:     "CAT-INTEREST",
    interestRate: "CAT-INTEREST-RATE",
    incomeOther:  "CAT-INCOME-OTHER",
    expenseOther: "CAT-EXPENSE-OTHER",
  },
  transaction: {
    root:         "TRANS-TYPES",
    balance:      "BALANCE",
    moneyIn:      "MONEY-IN",
    moneyOut:     "MONEY-OUT",
    transferIn:   "TRANSFER-IN",
    transferOut:  "TRANSFER-OUT",
    interest:     "INTEREST",
    interestRate: "INTEREST-RATE",
  },
  file: {
    root:   "FILE-TYPES",
    ofx:    "FILE-OFX",
    csv:    "FILE-CSV",
    api:    "FILE-API",
  },
  bank: {
    root:    "MY-BANK",
    stmts:   "BANK-STMT-FILES",
    stmtAPI: "BANK-STMT-API",
    rules:   "BANK-STMT-RULES",
    rule:    "BANK-STMT-RULE",
    ruleOveride: "RU-OVERIDE",
    ruleApiDefined: "RU-API-DEFINED",
  }
}