import { Area } from "recharts"
import { ModelKeys } from "../../../core/model/ModelKeys"
import { Logger } from "../../../core/utils/Logger"
import { useInvestmentModel } from "../../../hooks/context/InvestmentModelContext"
import { usePivotTables } from "../../../hooks/pivot/usePivotTables"
import { CartesianChart } from "../CartesianChart"
import { useChartProfile } from "../useChartProfile"
import { useGradientRedGreen } from "../useGradient"

const logger = new Logger("charts.LoanCashflowChart")

export function LoanCashflowChart(props:any) {
  const { item, name } = useInvestmentModel()
  const { chartData } = useLoanChartData()
  const { size } = useChartProfile()
  
  const { linearGradient, gradientRedUrl, gradientGreenUrl } = useGradientRedGreen(false, true)

  logger.setContext(name).debug("Rendering %d data points", chartData.length)
  
  return (
    <div id="LoanCashflowChart" className="chart">
      <h3>Loan Repayments</h3>
      <CartesianChart data={chartData} syncId={item.key} height={size.height} noLegend>
        <defs>{ linearGradient }</defs>
        <Area type="monotone" dataKey="Interest"  stackId="1" fill={gradientRedUrl}   stroke={gradientRedUrl}   strokeWidth={2} />
        <Area type="monotone" dataKey="Principal" stackId="1" fill={gradientGreenUrl} stroke={gradientGreenUrl} strokeWidth={2} />
      </CartesianChart>
    </div>
  )
}

export function useLoanChartData() {
  const { pivotTable:{rows} } = usePivotTables()

  const chartData:any[] = []

  for (const row of rows) {
    if (row.endDate > 0 && !row.openingRow) {
      const dp = { 
        date:      row.endDate,
        Principal: 0,
        Repayment: row.cell(ModelKeys.category.payment).total,
        Interest:  -row.cell(ModelKeys.transaction.interest).total,
        Balance:   row.balance,
      }
      dp.Principal = dp.Repayment - dp.Interest
      chartData.push(dp)
    }
  }

  return { chartData }
}