import { add, sub } from "date-fns"
import { isMobile } from "react-device-detect"
import { AxisDomain } from "recharts/types/util/types"
import { TimeUnits } from "../../core/utils/TimeScale"

export function useChartProfile() {
  return ChartProfile
}

const ChartProfile = {
  size: {
    aspect1x1: 1,
    aspect2x1: 2,
    aspect4x1: 4,
    aspect4x3: 4/3,
    aspect16x9: 16/9,
    aspect21x9: 21/9,
    radiusBar: 2,
    radiusPie: 3,
    radiusMiniBar: 3,
    barCategoryGap: 3,
    barGap: 5,
    barSize: 20,
    barSizeMini: 20,
    maxBarSize: 25,
    height: isMobile ? 200 : 250,
    heightMini: isMobile ? 150 : 200,
  },
  margin: { top:20, right:0, left:0, bottom:0 },
  margin0: { top:0, right:0, left:0, bottom:0 },
  colors: {
    green:    '#4BD963',
    red:      '#FF0000',
    orange:   ['#ff8300', '#ff7801', '#fe6d04', '#fc620a', '#f95711', '#f44d18', '#ef441f', '#e83c26', '#df362d'],
    rose:     ['#eed6d3', '#e4c2c2', '#d4b2b3', '#c1a2a4', '#ae9395', '#9a8487', '#887679', '#77676b', '#67595e'],
    peach:    ['#e4b7a0', '#ddab95', '#d59f89', '#cd947d', '#c68872', '#be7d66', '#b57259', '#ad674d', '#a45c40'],
    blue:     ['#bfd7ed', '#a0c3e5', '#82afdb', '#669bcf', '#4b88c0', '#3274b0', '#1c619d', '#084e89', '#003b73'],
    bluegold: ['#2d10ff', '#772fe8', '#9c48d1', '#b661ba', '#cb78a2', '#db9089', '#e9a86e', '#f5bf4c', '#ffd700'],
    seared:   ['#04ecf0', '#43d5e5', '#3cc2d7', '#2aafcb', '#059dc0', '#ffbcaf', '#f4777f', '#cf3759', '#93003a'],
    bluered:  ['#4f5dff', '#687ce8', '#7a98ce', '#8ab3b1', '#98cd8e', '#a7e663', '#f4d934', '#f8b345', '#f58d50', 
               '#eb6755', '#d84257', '#b42f54'],
    bluered2: ['#05445e', '#084e68', '#0d5973', '#14647d', '#1c6f87', '#247a90', '#2d869a', '#3791a3', '#429cac', 
               '#4da8b5', '#5ab4be', '#68bfc6', '#77cacf', '#88d5d7', '#9ce0e0', '#b4eae9', '#ffefd4', '#ffdec7', 
               '#ffcebb', '#ffbcaf', '#ffaba2', '#ff9895', '#fa888a', '#f4777f', '#ec6775', '#e4576b', '#da4762', 
               '#cf3759', '#c22650', '#b41648', '#a50641', '#93003a']
  },
  xdomain: ["dataMin", "dataMax"] as AxisDomain,
  ydomain: ["auto", "auto"] as AxisDomain,
  animationDuration: 200,
}

export class ColorPalette {
  private o=-1
  private b=-1
  private colors = ChartProfile.colors

  public get(value:number) {
    if (value < 0) {
      this.o = (this.o+1) % this.colors.orange.length
      return this.colors.orange[this.o]
    } else {
      this.b = (this.b+1) % this.colors.bluered.length
      return this.colors.bluered[this.b]
    }
  }
}

export function getDateFormat(data:{date:number}[], units:TimeUnits) {
  // Find min/max date
  let minDate = Number.MAX_SAFE_INTEGER, maxDate=-1
  for (const item of data) {
    const date = item.date
    if (date > maxDate) {
      maxDate = date
    }
    if (date < minDate) {
      minDate = date
    }
  }

  let dateFormat, leftMargin
  switch (units) {
    case "1D": 
    case "1W": 
    case "2W": 
      dateFormat = "dd-MMM-yy"
      leftMargin = { weeks:2 }
      break

    case "1M": 
    case "3M": 
    case "6M": 
      dateFormat = "MMM-yy"
      leftMargin = { months:2 }
      break
      
    case "1Y": 
    case "2Y": 
    case "3Y": 
    case "5Y": 
    case "10Y":
    case "20Y":
    case "30Y":
      dateFormat = "yyyy"
      leftMargin = { years:1 }
  }

  let start = sub(minDate, leftMargin).getTime()
  let end = add(maxDate, leftMargin).getTime()

  // const domain = d3Extent(data, (d:any) => d.date)
  // const tScale = d3ScaleTime().domain([minDate,maxDate]).range([minDate,maxDate])
  // const tickFormat = tScale.tickFormat()

  return {
    start: minDate,
    finish: maxDate,
    units: units,
    dateFormat: dateFormat,
    // interval: interval,
    xdomain: [start, end],
    // tickFormat: tickFormat,
  }
}
