import { isMobileOnly } from "react-device-detect"
import { Bar, Cell, Line } from "recharts"
import { Logger } from "../../../core/utils/Logger"
import { useFilterContext } from "../../../hooks/context/FilterContext"
import { usePivotTables } from "../../../hooks/pivot/usePivotTables"
import { CartesianChart } from "../CartesianChart"
import { ChartHeader } from "../ChartHeader"
import { useChartProfile } from "../useChartProfile"
import { useGradientRedGreen } from "../useGradient"

const logger = new Logger("charts.CashflowBarChart2")

export function CashflowBarChart2(props:any) {
  const { name, pivotTable:{ rows } } = usePivotTables()
  const { filter } = useFilterContext()
  const { size, animationDuration } = useChartProfile()
  const { linearGradient, gradientRedUrl, gradientGreenUrl } = useGradientRedGreen()
  
  const { height, syncId, aspect } = props

  const data:any[] = []
  const dataKey = "Cashflow"

  for (const row of rows) {
    if (row.endDate > 0) {
      const dp = { date: row.endDate, Cashflow: row.total }
      data.push(dp)
    }
  }

  for (let i=0; i < data.length; i++) {
    let n = 1
    let total = data[i].Cashflow
    if (i > 0) {
      total += data[i-1].Cashflow
      n++
    }
    if (i > 1) {
      total += data[i-2].Cashflow
      n++
    }

    data[i].Average = total/n
  }

  logger.setContext(name).debug("Rendering: %d data points", data.length)

  return (
    <div id="CashflowBarChart2" className="chart">
      <ChartHeader />
      <CartesianChart data={data} syncId={syncId} height={height} aspect={aspect} 
                      noLegend={true} noTooltip={isMobileOnly} units={filter.scale.units}>
        <defs>{ linearGradient }</defs>
        <Bar dataKey={dataKey} 
              animationDuration={animationDuration}
              barSize={size.barSizeMini}
              maxBarSize={size.barSizeMini}
              minPointSize={2}
              radius={size.radiusBar}>
          { data.map((dp) => (
            <Cell fill={dp[dataKey] < 0 ? gradientRedUrl : gradientGreenUrl} key={dp.key} />
          ))}
        </Bar>
        <Line dataKey="Average" type="monotone" dot={false} />
      </CartesianChart>
    </div>
  )
}
