import { Model } from "../../core/model/Model"
import { ModelKeys } from "../../core/model/ModelKeys"
import { Item } from "../../core/types/Item"
import { Logger } from "../../core/utils/Logger"
import { getUniqueId } from "../../core/utils/Utils"

export interface ItemState {
  key: string
  level: number
  openTree: boolean
  openItem: boolean
  selected: boolean
  searchHit: boolean
  visible: boolean
}

/**
 * ViewState holds view related information about Item's (as defined by ItemState) that are in the current view
 */
export class ViewState {
  public readonly key = getUniqueId()

  /** The item at the root, of this view */
  public readonly rootKey:string

  /** Map of ItemState elements, keyed by the model Item.key */
  private itemStateMap = new Map<string,ItemState>()

  /** */
  private onEventHandler:any

  /** The level to which the "item tree" is open */
  public openLevel:number = 1

  /** Is the item selection checkbox visible or not */
  public showSelectCheckbox:boolean = false

  private logger = new Logger("context.ViewState")

  constructor(rootKey:string, onEventHandler:any) {
    this.rootKey = rootKey || ModelKeys.root
    this.onEventHandler = onEventHandler

    this.logger.setContext(this.key)
    this.logger.trace("Created new ViewState for rootKey='%s'", this.rootKey)
  }

  public onEvent(event:any) {
    if (this.onEventHandler !== undefined) {
      this.logger.debug("onEvent: Calling onEventHandler:", event, this)
      this.onEventHandler(event)
    } else {
      this.logger.debug("onEvent:", event, this)
    }
  }

  public has(key:string) {
    return this.itemStateMap.has(key)
  }

  public get(key:string): ItemState {
    let item = this.itemStateMap.get(key)
    if (item === undefined) {
      item = this.getNew(key)
    }
    return item
  }

  private getNew(key:string): ItemState {
    let newItem:ItemState
    let oldItem = this.itemStateMap.get(key)
    if (oldItem !== undefined) {
      newItem = { ...oldItem }
    } else {
      newItem = {
        key: key,
        level: 0,
        openTree: false,
        openItem: false,
        selected: false,
        searchHit: false,
        visible: true,
      }
    }
    
    this.itemStateMap.set(key, newItem)
    return newItem
  }

  public getSelected(): ItemState[] {
    let keys:ItemState[] = []

    this.itemStateMap.forEach(item => {
      if (item.selected) {
        keys.push(item)
      }
    })

    return keys
  }

  public getSelectedKeys(): string[] {
    let keys:string[] = []

    this.itemStateMap.forEach(item => {
      if (item.selected) {
        keys.push(item.key)
      }
    })

    return keys
  }

  public clearSelected() {
    this.itemStateMap.forEach(item => {
      this.getNew(item.key).selected = false
    })
  }
  
  public isOpenTree(key:string): boolean {
    return this.get(key).openTree
  }

  public isVisible(key:string): boolean {
    return this.get(key).visible
  }

  public isSelected(key:string): boolean {
    return this.get(key).selected
  }

  public toggleOpenTree(key:string): ItemState {
    const itemState = this.getNew(key)
    itemState.openTree = !itemState.openTree
    return itemState
  }

  public toggleSelected(key:string): ItemState {
    const itemState = this.getNew(key)
    itemState.selected = !itemState.selected
    return itemState
  }

  public setSelected(key:string, selected:boolean, recursive=false, model?:Model): ItemState {
    const itemState = this.getNew(key)
    itemState.selected = selected

    if (recursive && model) {
      const ckeys = model.childrenKeysDeep(key)
      this.logger.debug("setSelected: key=%s, ckeys:", key, ckeys)

      for (const ckey of ckeys) {
        this.getNew(ckey).selected = selected
      }
    }

    return itemState
  }

  public setOpenItem(key:string, isOpen:boolean): ItemState {
    const itemState = this.getNew(key)
    itemState.openItem = isOpen
    return itemState
  }

  public setOpenTree(key:string, isOpen:boolean): ItemState {
    const itemState = this.getNew(key)
    itemState.openTree = isOpen
    return itemState
  }

  public setOpenLevel(model:Model, key:string, maxLevel:number, reset:boolean=true) {
    if (key === undefined) {
      key = this.rootKey
    }
    if (reset) {
      this.itemStateMap.clear()
      this.openLevel = 0
    }

    this.logger.debug("setOpenLevel: key=%s, maxLevel=%d, reset=%s", key, maxLevel, reset)

    const itemState = this.openTree(model, key, maxLevel)
    this.openLevel = maxLevel

    return itemState
  }

  private openTree(model:Model, key:string, maxLevel:number, level:number=1) {
    const itemState = this.get(key)
    itemState.level = level
    itemState.openTree = true

    const relatedKeys = model.relatedKeys(key)

    this.logger.trace("openTree: key=%s, maxLevel=%d, level=%d, relatedKeys:", key, maxLevel, level, relatedKeys)

    for (const childKey of relatedKeys) {
      const childState = this.get(childKey)
      childState.visible = true
    }

    return itemState
  }

  public openTo(model:Model, key:string) {
    for (let i=0; key && key !== this.rootKey; i++) {
      const itemState = this.getNew(key)
      itemState.visible = true
      if (i > 0) {
        itemState.openTree = true
      }
      key = model.getItem(key).parentKey
    }
  }

  /**
   * Recursively set the search hit flag on all items that are in the searchResultsMap
   * @param model 
   * @param searchResults 
   */
  public setSearchHits(model:Model, searchResults:Item[]) {
    this.logger.start("setSearchHits", "%d search results", searchResults.length)

    // Clear searchHit and visible for all items
    let reset = 0
    for (const item of model.items()) {
      const itemState = this.getNew(item.key)
      itemState.searchHit = false
      itemState.visible = false
      reset++
    }

    this.logger.trace("Reset %d itemStates:", reset, this.itemStateMap)

    // Set searchHit and visible for each item in searchResults
    let hits=0, visible=0
    for (const result of searchResults) {
      const itemState = this.get(result.key)
      itemState.searchHit = true
      itemState.visible = true
      hits++
      visible++

      // Set visible for all parents up the tree
      for (let item=result; item && item.parentKey !== ModelKeys.root && item.parentKey !== ""; ) {
        item = model.getItem(item.parentKey)
        if (item) {
          const parentState = this.get(item.key)
          parentState.visible = true
          parentState.openTree = true
          visible++
        }
      }
    }

    this.logger.finish("setSearchHits", "%d hits, %d visible", hits, visible)
  }

  public clearSearchHits() {
    this.itemStateMap.forEach(item => {
      item.searchHit = false
      item.visible = true
    })
  }
}
