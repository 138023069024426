import { Duration, intervalToDuration } from "date-fns"
import { AmortizationBuilder } from "../builder/AmortizationBuilder"
import { CashflowBuilder } from "../builder/CashflowBuilder"
import { Model } from "../model/Model"
import { ModelKeys } from "../model/ModelKeys"
import { LoanItem } from "../types/LoanItem"
import { Logger } from "../utils/Logger"
import { InvestmentModel } from "./InvestmentModel"
import { LoanAmortization } from "./LoanAmortization"

export class LoanModel extends InvestmentModel<LoanItem> {
  private _amortization?: AmortizationBuilder
  private _loanAmort: LoanAmortization

  constructor(model:Model, item:LoanItem) {
    super(model, item)
    this._loanAmort = new LoanAmortization(item)
    this.logger.setName("models.LoanModel").debug("Created new LoanModel for %s", this.item.name) 
  }

  get amortization() : AmortizationBuilder {
    if (!this._amortization) {
      this._amortization = new AmortizationBuilder(this.model, this.asset, this.loan)
    }
    return this._amortization
  }

  get loan() {
    return this.item
  }

  get numPayments() {
    return this._loanAmort.numPayments
  }

  get calcTotalPayments() {
    return this.numPayments * this.loan.paymentAmount
  }

  get calcTotalInterest() {
    return this.calcTotalPayments - this.loan.principal
  }

  get actualStartDate() : number {
    const cashflowItems = this.cashflow.getCashflowItems()
    return cashflowItems.at(0)?.date ?? 0
  }

  get actualFinishDate() : number {
    const cashflowItems = this.cashflow.getCashflowItems()
    return cashflowItems.at(-1)?.date ?? 0
  }

  get actualTerm() : Duration {
    return intervalToDuration({ start: this.actualStartDate, end: this.actualFinishDate })
  }

  get countRateChanges() {
    return this.cashflow.countBy(this.model.getItem(ModelKeys.category.interestRate))
  }
  
  protected getCashflowBuilder() : CashflowBuilder | undefined {
    return this.amortization.getCashflow()
  }

  public onCreate() {
    this.logger.start("onCreate")   
    super.onCreate()
    
    this._amortization = new AmortizationBuilder(this.model, this.asset, this.loan)

    this.logger.finish("onCreate", "%s", Logger.Range(this.start, this.finish))
  }

  public onBuild() {
    this.logger.start("onBuild")   
    this.amortization.build(true)
    this.logger.finish("onBuild", "%s", Logger.Range(this.start, this.finish))
  }
}