import { Item, ItemProps } from "../types/Item"
import { formatDate } from "../utils/DateFormat"
import { LoggerConfig } from "../utils/LoggerConfig"
import { Model } from "./Model"

export class NormalizeItem {
  public static normalize(model:Model, item:Item) {
    const newItem = {} as any
    for (const key of NormalizeItem.sortedKeys(item) as any[]) {
      newItem[key] = NormalizeItem.resolve(model, key, (item as any)[key])
    }
    return newItem
  }

  /**
   * 
   * @param item 
   * @returns 
   */
  public static sortedKeys(item:Item) {
    const keys1: string[] = [
      ItemProps.key, ItemProps.code, ItemProps.name,
      ItemProps.description, ItemProps.parentKey, ItemProps.typeKey
    ]
    const keys3: string[] = [
      ItemProps.links, ItemProps.sortOrder, ItemProps.status, ItemProps.modifiedDate
    ]
    const keys2: string[] = []

    for (const key of Object.keys(item)) {
      if (!keys1.includes(key) && !keys3.includes(key)) {
        keys2.push(key)
      }
    }

    keys2.sort((k1, k2) => k1.localeCompare(k2))

    return [...keys1, ...keys2, ...keys3]
  }

  public static format(model:Model, key:any, value:any) {
    if (value === undefined || value === null) {
      return ""
    }
    if (typeof value === "string") {
      return `"${value}"`
    }
    if (typeof value === "object") {
      return JSON.stringify(value, null, 2)
    }
    return isNaN(value) ? "NaN" : value
  }

  public static resolve(model:Model, key:any, value:any) {
    if (key.endsWith("Date") || key.endsWith("date")) {
      return new Date(value).toISOString()
    }

    if (key !== "key" && (key.endsWith("Key") || key.endsWith("key"))) {
      return model.getItem(value)?.name
    }

    return undefined
  }
}
