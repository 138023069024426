import { setProperty } from "../../core/model/Model"
import { Item, ItemProps } from "../../core/types/Item"
import { Logger } from "../../core/utils/Logger"
import { useFormItem } from "../../hooks/context/FormContext"
import { useModel } from '../../hooks/context/ModelContext'
import { useAtomRef } from "../../hooks/useAtomRef"
import { Editable } from "../controls/editor/Editable"
import { Field, FieldSet, Form, FormBody } from "../controls/form/Form"
import { FormFooter } from "../controls/form/FormFooter"
import { FormHeader } from "../controls/form/FormHeader"
import { ItemFormSharedFields } from "./ItemFormSharedFields"

const logger = new Logger("forms.ItemForm")

export function ItemForm(props : {
  item: Item
  formItemKey?: string
  open?: boolean
  noHeader?: boolean
  onClose: () => void
}) {
  const { model } = useModel()
  const { formItemKey, open, noHeader, onClose } = props

  const [ item, setItem ] = useFormItem(formItemKey ?? "item", props.item)
  const openAtom = useAtomRef(open === true)

  const icon = model.getItemIcon(item) || "fal fa-line-columns"

  logger.debug("Rendering: key=%s, open=%s, item=%o, props.item=%o", item.key, open, item, props.item)

  return (
    <Form id="ItemForm">
      { !noHeader &&
        <FormHeader title="Item Editor" icon={icon} onClose={onClose} openAtom={openAtom} />
      }
      <FormBody>
        <FieldSet>
          <Field className="name">
            <label>Name</label>
            <Editable item={item} property={ItemProps.name} onChange={onChange} readonly={false} />
          </Field>
          <Field>
            <label>Code</label>
            <Editable item={item} property={ItemProps.code} onChange={onChange} readonly={false} />
          </Field>
          <Field className="description">
            <label>Description</label>
            <Editable item={item} property={ItemProps.description} onChange={onChange} readonly={false} />
          </Field>
        </FieldSet>

        <ItemFormSharedFields item={item} onChange={onChange} readonly={false} openAtom={openAtom} />
      </FormBody>
      
      { !noHeader &&
        <FormFooter showClose showDelete showSave onClose={onClose}/>
      }
    </Form>
  )

  function onChange(item:Item, property:any, value:any) {
    logger.debug("onChange: item.key=%s, property=%s, value=%s", item.key, property, value)

    const newItem = setProperty({...item}, property, value)
    setItem(newItem)
  }
}
