import { useSetAtom } from 'jotai'
import { useEffect, useRef } from 'react'
import { Logger } from "../../core/utils/Logger"
import { clzName } from '../../core/utils/Utils'
import { SearchAtoms } from '../../hooks/atoms/SearchAtoms'

const logger = new Logger("search.SearchBox")

export function SearchBox(props:{
  setfocus?: boolean
  placeholder?: string
  className?: string
  searchValueAtom?: any
  setSearchValue?: (searchValue:string) => void
}) {
  const { placeholder, setfocus, searchValueAtom } = props
  const setSearchValueAtom = useSetAtom(searchValueAtom ?? SearchAtoms.searchValue)
  const className = clzName("editable", props.className)

  let setSearchValue:any
  if (props.setSearchValue) {
    setSearchValue = props.setSearchValue
  } else {
    setSearchValue = (searchValue:string) => setSearchValueAtom(searchValue)
  }

  const inputRef = useRef<any>()

  let timeoutId:any = null
  let timeoutPeriod = 200

  logger.debug("Rendering: value='%s'")

  useEffect(() => {
    setSearchValue("")
    if (setfocus) {
      inputRef.current.focus()
    }
  // eslint-disable-next-line
  },[])

  return (
    <div className="search">
      <i className="fal fa-search"/>
      <input type="search"
              onChange={onChange}
              className={className}
              placeholder={placeholder || "Search"}
              ref={inputRef} />
    </div>
  )

  function onChange(e:any) {
    // Set timeout for view refresh
    if (timeoutId !== null) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => onWakeup(e.target.value), timeoutPeriod)
  }
  
  function onWakeup(searchValue:string) {
    timeoutId = null
    setSearchValue(searchValue.trim())
    logger.debug("onWakeup called, searchValue='%s'", searchValue)
  }
}
