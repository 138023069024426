import { Duration, endOfMonth, startOfMonth } from "date-fns"
import { Model } from "../../model/Model"
import { ModelKeys } from "../../model/ModelKeys"
import { saveItems } from "../../service/ModelService"
import { AccountItem } from "../../types/AccountItem"
import { BankStmtFileItem } from "../../types/BankStmtFileItem"
import { Item, ItemStatus } from "../../types/Item"
import { LoanItem } from "../../types/LoanItem"
import { PartyItem } from "../../types/PartyItem"
import { TransactionItem } from "../../types/TransactionItem"
import { Account, Connection, EnrichedTransaction, Transaction } from "../../types/akahu"
import { Logger } from "../../utils/Logger"

const logger = new Logger("builder.AkahuBuilder")

const accountToParent = {
  CHECKING:   "MY-ACCOUNTS",
  SAVINGS:    "MY-ACCOUNTS",
  CREDITCARD: "MY-ACCOUNTS",
  LOAN:       "MY-PROPERTY",
  KIWISAVER:  "MY-INVESTMENTS",
  INVESTMENT: "MY-INVESTMENTS",
  TERMDEPOSIT:"MY-INVESTMENTS",
  FOREIGN:    "MY-INVESTMENTS",
  TAX:        "MY-ACCOUNTS",
  REWARDS:    "MY-ACCOUNTS",
  WALLET:     "MY-INVESTMENTS",
}

/**
 * 
 * @param model 
 * @param accounts 
 */
export function processAccounts(model:Model, accounts:Account[]) {
  logger.start("processAccounts", "Processing %d accounts", accounts.length)

  const newItems = new Map<string,Item>()

  for (const account of accounts) {
    // We don't overwrite existing accounts
    if (model.has(account._id)) {
      continue
    }

    // Create an account
    const accountItem = createAccountItem(model, account)
    newItems.set(accountItem.key, accountItem)

    // Create a Party for the Bank / Provider if necessary
    accountItem.providerKey = createParty(model, account.connection, newItems)
  }

  // Save them
  saveNewItems(model, setSortOrder(newItems))
  
  logger.finish("processAccounts", "Processed %d accounts", accounts.length)
}

/**
 * 
 * @param model 
 * @param transactions 
 */
export function processTransactions(model:Model, transactions:Transaction[]) {
  logger.start("processTransactions", "Processing %d transactions", transactions.length)

  // All existing statements to search,
  const statements = model.children<BankStmtFileItem>(ModelKeys.bank.stmtAPI)

  // New items to create
  const newItems = new Map<string,Item>()
  const transItems:TransactionItem[] = []

  const length = transactions.length
  for (let i=0; i < length; i++) {
    const trans = transactions[i]

    // We don't overwrite existing accounts
    if (model.has(trans._id)) {
      continue
    }

    // Create [if necessary] a BankStmtFileItem for this transaction
    const stmtItem = createStatement(model, trans, statements)
    newItems.set(stmtItem.key, stmtItem)

    // Create an account
    const transItem = createTransactionItem(model, trans, stmtItem, (length-i))
    transItems.push(transItem)

    // If the transaction has a category, use it if we can find it
    if (!transItem.categoryKey) {
      const { categoryKey, ruleKey } = getCategory(model, trans)
      transItem.categoryKey = categoryKey
      transItem.ruleKey = ruleKey
    }

    // Create a Party for the Merchant if necessary
    const merchant = (trans as EnrichedTransaction).merchant
    transItem.merchantKey = createParty(model, merchant, newItems)
  }

  // Save them
  const items = setSortOrder(newItems)
  saveNewItems(model, [...items, ...transItems])
  
  logger.finish("processTransactions", "Processed %d transactions", transactions.length)
}

/**
 * 
 * @param model 
 * @param items 
 */
function saveNewItems(model:Model, items:Item[]) {
  const newItems = items.filter(item => !model.has(item.key))
 
  logger.debug("saveNewItems: Saving %d/%d items", newItems.length, items.length, items)

  if (newItems.length > 0) {
    saveItems(newItems)
  }
}

/** Sort by name and allocate sortOrder */
function setSortOrder(itemMap:Map<string,Item>) {

  const items = Array.from(itemMap.values())
  items.sort((a1,a2) => a1.name.localeCompare(a2.name))

  let sortOrder = 10000
  for (const item of items) {
    item.sortOrder = (sortOrder += 10)
  }

  return items
}

/**
 * 
 * @param model 
 * @param account 
 * @returns 
 */
function createAccountItem(model:Model, account:Account) {
  // Create new AccountItem
  const parentKey = model.getItemKey(accountToParent[account.type])
  const typeKey = model.getItemKey(account.type) ?? 
                                  (account.type === "FOREIGN" ? ModelKeys.account.wallet : undefined) ?? 
                                  ModelKeys.account.checking
  const accountItem = model.newItem<AccountItem>(parentKey, typeKey, "PI-")

  // Set attributes
  accountItem.key = account._id
  accountItem.code = account.formatted_account
  accountItem.name = account.name
  accountItem.currency = account.balance?.currency

  // Prefix the name with provider name
  if (!account.name.startsWith(account.connection.name)) {
    accountItem.name = `${account.connection.name} ${account.name}`
  }

  // Special trick for Fisher Funds
  if (!accountItem.code && account.connection.name === "Fisher Funds") {
    accountItem.code = account.meta?.payment_details?.reference
  }

  // Handle Loans
  if (account.type === "LOAN" && account.meta?.loan_details) {
    const loan_details = account.meta?.loan_details
    const loanItem = accountItem as LoanItem

    loanItem.principal = loan_details.initial_principal as any
    loanItem.rate = loan_details.interest.rate / 100
    loanItem.term = loan_details.term as Duration

    if (loan_details.repayment?.next_amount !== undefined) {
      loanItem.paymentAmount = -loan_details.repayment?.next_amount
    }
  }

  return accountItem
}

/**
 * 
 * @param model 
 * @param transaction 
 * @returns 
 */
function createTransactionItem(model:Model, trans:Transaction, statement:BankStmtFileItem, count:number) {
  // Create new TransactionItem
  const date = Date.parse(trans.date)
  const value = trans.amount
  const balance = trans.balance
  const accountKey = trans._account

  // No name in trans, so use merchant name or fallback to description
  let name, description
  const merchant = (trans as EnrichedTransaction).merchant
  if (merchant) {
    name = merchant.name
    description = trans.description
  } else {
    name = trans.description
  }

  // Type based upon sign of amount
  let typeKey = (value >= 0) ? ModelKeys.transaction.moneyIn : ModelKeys.transaction.moneyOut
  let categoryKey:string|undefined

  // Handle transfers
  if (trans.type === "TRANSFER") {
    typeKey = (value >= 0) ? ModelKeys.transaction.transferIn : ModelKeys.transaction.transferOut
    categoryKey = (value >= 0) ? ModelKeys.category.transferIn : ModelKeys.category.transferOut

    // Tidy up name
    let index = name.indexOf("Debit Transfer")
    if (index === -1) index = name.indexOf("Credit Transfer")
    if (index !== -1 && !description) {
      description = name.substring(index)
      name = name.substring(0, index-1)
    }
  }

  // Create the transactionItem
  const transItem:TransactionItem = {
    key:          trans._id,
    parentKey:    statement.key,
    typeKey:      typeKey,
    categoryKey:  categoryKey,
    accountKey:   accountKey,
    startDate:    date,
    value:        value,
    balance:      balance,
    name:         name,
    description:  description,
    sortOrder:    date + count,              // Use count to preserve ordering
    status:       ItemStatus.NEW,
    modifiedDate: Date.now(),
  }

  return transItem
}

/**
 * Create [if necessary] a bank statement to hold the specified transaction. This is a notional
 * construct, and we create fictitous monthly statements for each account.
 * To find the correct statement for a transaction we must search all statements for the account,
 * and find the one with the correct date range.
 * 
 * @param model 
 * @param transaction 
 * @returns 
 */
function createStatement(model:Model, trans:Transaction, statements:BankStmtFileItem[]) {
  const transDate = Date.parse(trans.date)
  const accountKey = trans._account

  // Check whether a statement already exists
  let statement = statements.find(stmt => (
    (stmt.accountKey === accountKey) &&
    (transDate >= stmt.startDate && transDate <= stmt.endDate)
  ))

  if (!statement) {
    // Create a model item for the bank statement file
    statement = model.newItem<BankStmtFileItem>(ModelKeys.bank.stmtAPI, ModelKeys.file.api, "API-")
    statement.accountKey = accountKey
    statement.accountNumber = model.getItem(accountKey)?.code ?? accountKey
    statement.name = model.getItemName(accountKey)

    // Create date range
    statement.startDate = startOfMonth(transDate).getTime()
    statement.endDate = endOfMonth(transDate).getTime()

    // Add to complete list so will not create twice
    statements.push(statement)
  }

  return statement
}

/**
 * Create [if necessary] a {@link PartyItem} item for {@link source} which could be a 
 * {@link Connection} or a Merchant. If a new party is created is added to {@link newItems}.
 * 
 * @param model 
 * @param source 
 * @param newItems
 * @returns The key of the party
 */
function createParty(model:Model, source:Connection|any, newItems:Map<string,Item>) {
  if (!source) return undefined

  // Does party already exist, or has been created already
  let partyItem:PartyItem = model.getItem(source._id) ?? newItems.get(source._id)
  if (!partyItem) {
    partyItem = model.newItem<PartyItem>(ModelKeys.parties, ModelKeys.type.party, "PI-")
    partyItem.code = source._id
    partyItem.name = source.name
    partyItem.logoURL = source.logo
    partyItem.website = source.website
    
    newItems.set(source._id, partyItem)
  }

  // Return the [new] party
  return partyItem?.key
}

/**
 * Get a Jemstone category for the Akahu Transaction category, if not mapped
 * then try the group.
 * 
 * @param model 
 * @param trans 
 * @returns 
 */
function getCategory(model:Model, trans:Transaction) {
  const category = (trans as EnrichedTransaction).category
  if (!category) return {}

  // Return existing category, or a group to assign to
  let ruleKey
  let categoryKey = model.getItemKey(category._id)
  if (categoryKey) {
    ruleKey = ModelKeys.bank.ruleApiDefined
  } else {
    // Category doesn't exist, so assign to the group
    categoryKey = model.getItemKey(category.groups.personal_finance._id)
  }

  // Return the category key
  return { categoryKey, ruleKey }
}

/*
function createCategory(model:Model, trans:Transaction, newItems:Map<string,Item>) {
  const category = (trans as EnrichedTransaction).category
  if (!category) return undefined

  // Return existing category, or one that has been created already
  let categoryItem = model.getItem(category._id) ?? newItems.get(category._id)
  if (!categoryItem) {
    // Category doesn't exist, so assign to the group
    categoryItem = model.getItem(category.groups.personal_finance._id)

    // Category doesn't exist, but if group does (as it should) then create the sub-category
    // const group = category.groups.personal_finance
    // const groupItem = model.getItem(group._id)
    // if (groupItem) {
    //   categoryItem = model.newItem(groupItem.key, ModelKeys.transaction.moneyOut, "CI-")
    //   categoryItem.code = category._id
    //   categoryItem.name = category.name
    //   newItems.set(category._id, categoryItem)
      
    //   logger.debug("Creating new Category='%s' in %o", categoryItem.name, groupItem)
    // }
  }

  // Return the category key
  return categoryItem?.key
}
*/