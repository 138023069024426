import { ModelKeys } from "../../../core/model/ModelKeys"
import { Logger } from "../../../core/utils/Logger"
import { Card, CardSection } from "../../controls/Card"
import { ModelTable } from "./ModelTable"

const logger = new Logger("settings.TableCard")

export function TableCard(props: { rootKey?: string }) {
  const rootKey = props.rootKey || ModelKeys.root

  logger.debug("Rendering: rootKey=%s", rootKey)

  return (
    <Card id="TableCard">
      <CardSection>
        <ModelTable rootKey={rootKey} />
      </CardSection>
    </Card>
  )
}
