import { endOfDay, subDays } from "date-fns"
import { Logger } from "../../core/utils/Logger"
import { formatNumber, formatPercent, ifZero, isNegative, NumberFormats } from "../../core/utils/Numbers"
import { useFilterContext } from "../../hooks/context/FilterContext"
import { useInvestmentModel } from "../../hooks/context/InvestmentModelContext"
import { useFilterBar } from "../../hooks/filter/useFilterBar"
import { useCardOpen } from "../../hooks/useCardOpen"
import { CardHeader } from "../controls/Card"
import { ButtonOpen } from "../controls/buttons/ButtonOpen"
import { ButtonPopFilter } from "../controls/buttons/ButtonPopFilter"
import { CardLink } from "../views/ViewLinks"
import { CardMenu, CardMenuProps } from "./CardMenu"
import { YieldCalculator } from "../../core/invest/YieldCalculator"
import { ValuationModel } from "../../core/invest/ValuationModel"
import { usePivotTables } from "../../hooks/pivot/usePivotTables"

const logger = new Logger("cards.CardHeaderWithMenu")

export interface CardHeaderWithMenuProps extends CardMenuProps {
  cardName: string
  title?: string
  icon?: string
  showEdit?: boolean
  showBalance?: boolean
  showYield?: boolean
}

export function CardHeaderWithMenu(props:CardHeaderWithMenuProps) {
  const { cardName, title, icon, showBalance, showYield } = props
  const { item, model } = useInvestmentModel()
  const { isOpen, setOpen, openAtom } = useCardOpen(cardName, item.key)
  const { stackName } = useFilterContext()
  const { visible, setVisible } = useFilterBar(stackName, item.key)

  logger.debug("Rendering name=%s, itemKey=%s, isOpen=%s", item.name, item.key, isOpen)

  return (
    <CardHeader className="sticky">
      <ButtonPopFilter icon={icon || model.getItemIcon(item)} />
      
      <h2 onClick={onClick}>
        { title || <CardLink item={item} hideIcon /> }
      </h2>

      { !isOpen && showBalance && <BalanceInfo/> }
      { !isOpen && showYield   && <AssetYield /> }

      <menu onClick={(e:any) => e.stopPropagation()}>
        <CardMenu {...props} />
        <ButtonOpen openAtom={openAtom} />
      </menu>
    </CardHeader>
  )

  function onClick() {
    setOpen(!isOpen)
    if (!isOpen && visible) {
      setVisible(true)
    }
  }
}

function BalanceInfo() {
  const { cashflow } = useInvestmentModel()

  const currentDate = endOfDay(Date.now()).getTime()
  const openingDate = subDays(currentDate, 30).getTime()

  const cashflowItems = cashflow.getCashflowItems()
  const openingIndex = cashflow.findLatestFrom(cashflowItems, 0, openingDate)
  const currentIndex = cashflow.findLatestFrom(cashflowItems, openingIndex, currentDate)

  const openingBalance = cashflowItems.at(openingIndex)?.balance ?? 0
  const currentBalance = cashflowItems.at(currentIndex)?.balance ?? 0

  const title = `From ${formatNumber(openingBalance, NumberFormats.currency2)} to ${formatNumber(currentBalance, NumberFormats.currency2)}`

  const className = isNegative(currentBalance) ? "negative" : undefined

  const change = ifZero(currentBalance - openingBalance)
  const arrow = (change < 0) ? "fas fa-arrow-down negative" : 
                (change > 0) ? "fas fa-arrow-up positive" : "fas fa-dash"

  return (
    <span className="balance" title={title}>
      <b className={className}>{formatNumber(currentBalance, NumberFormats.currency2)}</b>
      <i className={arrow} />
    </span>
  ) 
}

function AssetYield() {
  const { asset, valuationCalc } = useInvestmentModel<ValuationModel>()
  const { pivotTable } = usePivotTables()

  const yieldCalc = new YieldCalculator(asset, pivotTable, valuationCalc)
  const { yields } = yieldCalc

  const className = isNegative(yields.totalYield) ? "negative" : undefined

  return (
    <span className="balance">
      <b className={className}>{formatPercent(yields.totalYield, NumberFormats.percent1)} Yield</b>
      {/* <i className={arrow} /> */}
    </span>
  )
}

export const defaultToolbarProps:CardMenuProps = {
  showFilter: true,
  showDimension: true,
  showUnits: false,
  showChildren: true,
}

export const defaultToolbarChartProps:CardMenuProps = {
  ...defaultToolbarProps,
  showDimension: false,
  showChartType: true,
}
