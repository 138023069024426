import { PivotType, PivotTypes } from "../../../core/invest/BudgetActualModel"
import { BudgetModel } from "../../../core/invest/BudgetModel"
import { Logger } from "../../../core/utils/Logger"
import { useFilterContext } from "../../../hooks/context/FilterContext"
import { useInvestmentModel } from "../../../hooks/context/InvestmentModelContext"
import { usePivotContent } from "../../../hooks/pivot/usePivotContent"
import { usePivotLayout } from "../../../hooks/pivot/usePivotLayout"
import { usePivotType } from '../../../hooks/pivot/usePivotType'
import { Menu } from "../../controls/menu/Menu"

const logger = new Logger("cards.CashflowPivotTableMenu")

export function CashflowPivotTableMenu(props: any) {
  const { pivotType } = usePivotType()

  logger.trace("Rendering pivotType=%s", pivotType)

  if (props.segmented) return (
    <>
      <menu className="segment">
        <PivotTypeButtons />
      </menu>
      <menu className="segment">
        <ButtonPivotLayout />
        <ButtonCumTotal />
        <ButtonBalance />
      </menu>
    </>
  )

  return (
    <Menu buttonImage={PivotTypes[pivotType].icon} label={pivotType}>
      <PivotTypeButtons />
      <div className="divider" />
      <ButtonPivotLayout />
    </Menu>
  )
}

export function PivotTypeButtons(props:any) {
  const budgetModel = useInvestmentModel<BudgetModel>()
  const { pivotType, setPivotType } = usePivotType()

  const types:PivotType[] = [ "Budget", "Actual", "Forecast" ]

  return (
    <>
      { types.map(type =>
        <button className={type === pivotType ? "active" : ""} 
                disabled={type === "Actual" && !budgetModel.actualCashflow}
                title={ PivotTypes[type].title }
                onClick={() => onClick(type)} key={type}>
          <i className={PivotTypes[type].icon}/>
          <label>{PivotTypes[type].title}</label>
        </button>
      )}
    </>
  )

  function onClick(type:PivotType) {
    budgetModel.pivotType = type
    setPivotType(type)
  }
}

export function ButtonPivotLayout(props:any) {
  const { pivotLayout, setPivotLayout, isTableLayout } = usePivotLayout()

  const nextLayout = (pivotLayout === "Tree" ? "Table" : "Tree")
  const title = `${nextLayout} Layout`

  const className = isTableLayout ? "fal fa-table-tree" : "fal fa-table-list"

  return (
    <button title={title} onClick={() => setPivotLayout(nextLayout)}>
      <i className={className}/>
      <label>{title}</label>
    </button>
  )
}

export function ButtonBalance(props:any) {
  const { isAccount, isAsset } = useFilterContext()
  const { setPivotContent, isBalanceContent } = usePivotContent()
  const className = isBalanceContent ? "active" : ""
  const disabled = !isAccount && !isAsset

  return (
    <button className={className} title="Show Balance"
            disabled={disabled}
            onClick={() => setPivotContent(isBalanceContent ? undefined : "Balance")}>
      <i className="fal fa-balance-scale"/>
      <label>Account Balances</label>
    </button>
  )
}

export function ButtonCumTotal(props:any) {
  const { setPivotContent, isCumTotalContent } = usePivotContent()
  const className = isCumTotalContent ? "active" : ""

  return (
    <button className={className} title="Show Cumulative Totals"
            onClick={() => setPivotContent(isCumTotalContent ? undefined : "CumTotal")}>
      <i className="fal fa-sigma"/>
      <label>Cumulative Totals</label>
    </button>
  )
}
