import { CashflowBuilder } from "../builder/CashflowBuilder"
import { Model } from "../model/Model"
import { Item } from "../types/Item"
import { InvestmentModel } from "./InvestmentModel"

export const PivotTypes = { 
  Budget:   { title:"Budget",   icon:"fal fa-bullseye-arrow" }, 
  Actual:   { title:"Actual",   icon:"fal fa-circle-dot" },
  Forecast: { title:"Forecast", icon:"fal fa-crosshairs-simple" },
}

export type PivotType = keyof typeof PivotTypes

export class BudgetActualModel<T extends Item> extends InvestmentModel<T> {
  protected _netCashflow?: CashflowBuilder
  protected _actualCashflow?: CashflowBuilder
  protected _budgetCashflow?: CashflowBuilder

  private _pivotType: PivotType = "Forecast"

  constructor(model:Model, item:T) {
    super(model, item)
    this.logger.setName("models.BudgetActualModel")
  }

  /** Get a reference to this model, useful when destructuring */
  get budgetModel() {
    return this
  }

  get netCashflow() {
    return this._netCashflow as CashflowBuilder
  }

  get actualCashflow() {
    return this._actualCashflow as CashflowBuilder
  }

  get budgetCashflow() {
    return this._budgetCashflow as CashflowBuilder
  }

  get cashflowLength() : number {
    return (this._actualCashflow?.length || 0)
         + (this._budgetCashflow?.length || 0)
         + (this._netCashflow?.length || 0)
  }

  get pivotType() {
    return this._pivotType
  }

  set pivotType(type:PivotType) {
    this._pivotType = type
  }

  protected getCashflowBuilder() : CashflowBuilder {
    // this.logger.debug("getCashflowBuilder: pivotType=%s", this.pivotType)
    
    const cashflow = this.pivotType === "Actual" ? this._actualCashflow :
                     this.pivotType === "Budget" ? this._budgetCashflow : this._netCashflow
    return cashflow as CashflowBuilder
  }

  public onPostBuild(): void {
    super.onPostBuild()
    
    // Update start/end dates
    const items = this._netCashflow?.getCashflowItems()
    if (items && items.length !== 0) {
      this._start = items[0].date
      this._finish = items[items.length-1].date
    }
  }
}
