import { Logger } from '../../../core/utils/Logger'
import { ChartHeader } from "../ChartHeader"
import { CompareRowsWaterfallChart } from '../cashflow/CompareWaterfallChart'
import { useBudgetActualPivotRows } from './useBudgetActualPivotRows'

const logger = new Logger("charts.BudgetActualWaterfallChart")

export function BudgetActualWaterfallChart(props:{height?:number|string}) {
  const { actualRow, budgetRow, scale } = useBudgetActualPivotRows()

  logger.debug("Rendering: scale={%s}", Logger.Scale(scale))

  return (
    <div id="BudgetActualWaterfallChart" className="chart">
      <ChartHeader scale={scale} />
      <CompareRowsWaterfallChart {...props} budgetRow={budgetRow} actualRow={actualRow} />
    </div>
  )
}
