import { useTheme } from "../../hooks/useTheme"
import { Menu } from "../controls/menu/Menu"

export function ThemeMenu(props:any) {
  const { themes, theme, setTheme } = useTheme()
  
  return (
    <Menu buttonImage={ theme.icon } buttonClass="ThemeMenu">
      { themes.map(t => 
        <button onClick={() => setTheme(t.code)} key={t.code}
                className={t.code === theme.code ? "active" : ""}>
          <i className={ t.icon }/>
          <label>{ t.name }</label>
        </button>
      )}
    </Menu>
  )
}
