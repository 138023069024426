import { Item, setItemPropNames } from "./Item"

/**
 * Representation of a financial asset whose value we can model
 */
export interface AssetItem extends Item {
  /** Date the asset was purchased */
  purchaseDate: number

  /** Price paid for the asset */
  purchasePrice: number

  /** Date the asset was sold */
  sellDate?: number

  /** The expected annual rate of appreciation for the asset */
  rate: number
}

type AssetItemProp = keyof AssetItem

type AssetItemRecord = Record<AssetItemProp, AssetItemProp>

export const AssetItemProps:AssetItemRecord = {
  key: "key",
  code: "code",
  name: "name",
  description: "description",
  parentKey: "parentKey",
  typeKey: "typeKey",
  links: "links",
  sortOrder: "sortOrder",
  status: "status",
  modifiedDate: "modifiedDate",
  purchaseDate: "purchaseDate",
  purchasePrice: "purchasePrice",
  sellDate: "sellDate",
  rate: "rate",
}

setItemPropNames(AssetItemProps)

export const AssetItemPropNames = Object.keys(AssetItemProps) as AssetItemProp[]
