import { setProperty } from "../../core/model/Model"
import { Asset, ModelKeys, Transaction } from "../../core/model/ModelKeys"
import { Item, ItemProps } from "../../core/types/Item"
import { TransactionItem, TransactionItemProps } from "../../core/types/TransactionItem"
import { Logger } from "../../core/utils/Logger"
import { NumberFormats, round } from '../../core/utils/Numbers'
import { useFormItem } from "../../hooks/context/FormContext"
import { useModel } from '../../hooks/context/ModelContext'
import { useTransferMapper } from "../../hooks/transfer/useTransferMapper"
import { useAtomRef } from "../../hooks/useAtomRef"
import { ShowIf, ShowIfAtom } from "../controls/ShowIf"
import { DateEditor } from "../controls/editor/DateEditor"
import { DurationEditor } from "../controls/editor/DurationEditor"
import { Editable } from "../controls/editor/Editable"
import { Field, FieldSet, Form, FormBody } from "../controls/form/Form"
import { FormFooter } from "../controls/form/FormFooter"
import { FormHeader } from "../controls/form/FormHeader"
import { ItemPickerTree } from "../controls/select/ItemPickerTree"
import { TransferAccountPicker } from "../controls/select/TransferAccountPicker"
import { ItemFormSharedFields } from "./ItemFormSharedFields"

const logger = new Logger("forms.TransactionItemForm")

export function TransactionItemForm(props : {
  item: TransactionItem
  formItemKey?: string
  open?: boolean
  noHeader?: boolean
  onClose: () => void
}) {
  const { model } = useModel()
  const { formItemKey, onClose, noHeader, open } = props
  
  // Editor state
  const [ trans, setTrans ] = useFormItem(formItemKey ?? "trans", props.item)
  const [ relatedTrans, setRelatedTrans ] = useFormItem("relatedTrans", model.getItem(trans.relatedTransactionKey) as TransactionItem|undefined)

  const openAtom = useAtomRef(open === true)
  const transferMapper = useTransferMapper()

  const icon = model.getItemIcon(trans) || "fal fa-line-columns"

  const account = model.getItem(trans.accountKey ?? trans.parentKey)
  const isUnitised = Asset.isUnitised(account)
  const isTransfer = Transaction.isTransfer(trans)
  const numberFormat = Transaction.isInterestRate(trans) ? NumberFormats.percent2 : NumberFormats.currency2

  logger.debug("Rendering: key=%s", trans.key, trans)

  // Render a Card view
  return (
    <Form id="TransactionItemForm">
      {!noHeader &&
        <FormHeader title="Transaction Editor" icon={icon} onClose={onClose} openAtom={openAtom} />
      }
      <FormBody>
        <FieldSet>
          <Field>
            <label>Account</label>
            <div className="readonly">{model.getItemName(trans.accountKey ?? trans.parentKey)}</div>
          </Field>
          <Field className="name">
            <label>Name</label>
            <Editable item={trans} property={ItemProps.name} onChange={onChange} readonly={false} />
          </Field>
          <ShowIfAtom visible={openAtom}>
            <Field className="code">
              <label>Code</label>
              <Editable item={trans} property={ItemProps.code} onChange={onChange} readonly={false} />
            </Field>
          </ShowIfAtom>
          <Field className="description">
            <label>Description</label>
            <Editable item={trans} property={ItemProps.description} onChange={onChange} readonly={false} />
          </Field>
        </FieldSet>

        <FieldSet>
          <Field>
            <label>Date</label>
            <DateEditor item={trans} property={TransactionItemProps.startDate} onChange={onChange} readonly={false} />
          </Field>
          <Field>
            <label>Amount</label>
            <Editable item={trans} property={TransactionItemProps.value} onChange={onChange} readonly={false}
                      placeholder="Enter an amount..."
                      numberFormat={numberFormat} />          
          </Field>
        </FieldSet>

        <FieldSet visible={isUnitised && false}>
          <Field>
            <label>Value</label>
            <Editable item={trans} property={TransactionItemProps.value} onChange={onChangeValue} readonly={false}
                      placeholder="Enter an amount..."
                      numberFormat={numberFormat} />          
          </Field>
          <Field>
            <label>Unit Price</label>
            <Editable item={trans} readonly={false}
                      property={TransactionItemProps.unitPrice}
                      onChange={onChangeUnitPrice}
                      numberFormat={NumberFormats.units} />
          </Field>
          <Field>
            <label>Units</label>
            <Editable item={trans} readonly={true}
                      property={TransactionItemProps.units}
                      onChange={onChangeUnits}
                      numberFormat={NumberFormats.units} />
          </Field>
          <Field>
            <label>Unit Balance</label>
            <Editable item={trans} readonly={true}
                      property={TransactionItemProps.unitBalance}
                      onChange={onChangeUnitBalance}
                      numberFormat={NumberFormats.units} />
          </Field>
        </FieldSet>
        
        <FieldSet>
          <Field>
            <label>Repeat</label>
            <DurationEditor item={trans} property={TransactionItemProps.frequency} onChange={onChange} readonly={false} />
          </Field>
          <ShowIf visible={trans.frequency !== undefined}>
            <Field>
              <label>Repeat Until</label>
              <DateEditor item={trans} property={TransactionItemProps.finishDate} onChange={onChange} readonly={false} />
            </Field>
          </ShowIf>
        </FieldSet>

        <FieldSet>
          <Field>
            <label>Category</label>
            <ItemPickerTree
              rootKey={ModelKeys.category.root}
              selected={model.getItem(trans.categoryKey)}
              onChange={onChangeCategory}
              placeholder="Select a Category..."
            />
          </Field>
          <Field>
            <label>Type</label>
            <ItemPickerTree
              rootKey={ModelKeys.transaction.root}
              selected={model.getItem(trans.typeKey)}
              onChange={onChangeType}
              placeholder="Select a Type..."
              readonly={false}
            />
          </Field>
          <ShowIf visible={isTransfer}>
            <Field>
              <label>Transfer Account</label>
              <TransferAccountPicker transaction={trans} relatedTrans={relatedTrans} onChange={onChangeTransferAccount} readonly={false} />
            </Field>
          </ShowIf>
        </FieldSet>

        <ItemFormSharedFields item={trans} onChange={onChange} readonly={false} openAtom={openAtom} />
      </FormBody>
      
      { !noHeader &&
        <FormFooter showClose showDelete showSave onClose={onClose}/>
      }
    </Form>
  )
  
  function onChange(trans:TransactionItem, property:any, value:any) {
    setTrans(setProperty({...trans}, property, value))
    if (relatedTrans) {
      setRelatedTrans(setProperty({...relatedTrans}, property, value))
    }
    logger.debug("onChange: transKey=%s, relatedTransKey=%s, property=%s, value=%s", 
                  trans.key, relatedTrans?.key, property, value)
  }

  /**
   * The user has selected the category, ensure consistency
   * @param trans 
   * @param category 
   */
  function onChangeCategory(category:Item) {
    // Update the transaction first
    const { categoryKey, typeKey } = transferMapper.getSafeCategory(trans.categoryKey, trans.typeKey, category.key)
    setTrans({...trans, categoryKey, typeKey })

    // If a relatedTrans then reflect contra category and type
    if (relatedTrans) {
      const { relatedCategoryKey, relatedTypeKey } = transferMapper.getSafeRelatedCategory(categoryKey, typeKey)
      setRelatedTrans({...relatedTrans, categoryKey:relatedCategoryKey, typeKey:relatedTypeKey })
    }
  }

  function onChangeType(typeItem:Item) {
    // Update the transaction first
    const { categoryKey, typeKey } = transferMapper.getSafeCategoryForType(trans.categoryKey, trans.typeKey, typeItem.key)
    setTrans({...trans, categoryKey, typeKey })

    // If a relatedTrans then reflect contra category and type
    if (relatedTrans) {
      const { relatedCategoryKey, relatedTypeKey } = transferMapper.getSafeRelatedCategory(categoryKey, typeKey)
      setRelatedTrans({...relatedTrans, categoryKey:relatedCategoryKey, typeKey:relatedTypeKey })
    }
  }

  function onChangeTransferAccount(trans:TransactionItem, transferAccount:Item, relatedTrans?:TransactionItem) {
    setTrans(trans)
    setRelatedTrans(relatedTrans)
  }

  function onChangeValue(trans:TransactionItem, property:any, value:number) {
    const newTrans = setProperty({...trans}, property, value)
    if (newTrans.unitPrice) {
      newTrans.units = round(value / newTrans.unitPrice, 4)
    } else if (newTrans.units) {
      newTrans.unitPrice = round(value / newTrans.units, 4)
    }

    setTrans(newTrans)
  }

  function onChangeUnits(trans:TransactionItem, property:any, units:number) {
    const newTrans = setProperty({...trans}, property, units)
    if (newTrans.value) {
      newTrans.unitPrice = round(newTrans.value / units, 4)
    } else if (newTrans.unitPrice) {
      newTrans.value = round(newTrans.unitPrice * units, 2)
    }

    setTrans(newTrans)
  }

  function onChangeUnitPrice(trans:TransactionItem, property:any, unitPrice:number) {
    const newTrans = setProperty({...trans}, property, unitPrice)
    if (newTrans.value) {
      newTrans.units = round(newTrans.value / unitPrice, 4)
    } else if (newTrans.units) {
      newTrans.value = round(unitPrice * newTrans.units, 2)
    }

    setTrans(newTrans)
  }

  function onChangeUnitBalance(trans:TransactionItem, property:any, unitBalance:any) {
    const newTrans = setProperty({...trans}, property, unitBalance)
    newTrans.value = unitBalance * (newTrans.unitPrice ?? 0)
    setTrans(newTrans)
  }
}

