import { useModel } from "../../hooks/context/ModelContext"
import { JemstoneHeader } from "../layouts/JemstoneHeader"
import { CardLinkParent } from "./ViewLinks"
import { ViewMenu } from "./ViewMenu"

export function ViewHeader(props:{
  rootKey: string
  showCreateKeys?: string[]
  showEdit?: boolean
  showTheme?: boolean
  children?: any
}) {
  const { model } = useModel()
  const { rootKey, showCreateKeys, showEdit, showTheme, children } = props

  return (
    <JemstoneHeader title={getTitle()}>
      { children ||
        <ViewMenu rootKey={rootKey} 
                  showWizard
                  showDocs
                  showFiles
                  showEdit={showEdit}
                  showTheme={showTheme !== false}
                  showCreateKeys={showCreateKeys} />
      }
    </JemstoneHeader>
  )

  function getTitle() {
    const rootItem = model.getItem(rootKey)
    return (
      <CardLinkParent item={rootItem} hideIcon>
        {rootItem?.name}
      </CardLinkParent>
    )
  }
}