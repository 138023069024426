import { ValuationModel } from '../../../core/invest/ValuationModel'
import { YieldCalculator } from '../../../core/invest/YieldCalculator'
import { ModelKeys } from "../../../core/model/ModelKeys"
import { formatDate } from '../../../core/utils/DateFormat'
import { Logger } from "../../../core/utils/Logger"
import { NumberFormats } from '../../../core/utils/Numbers'
import { useInvestmentModel } from '../../../hooks/context/InvestmentModelContext'
import { usePivotTables } from '../../../hooks/pivot/usePivotTables'
import { usePortfolioPerformance } from '../../../hooks/portfolio/usePortfolioPerformance'
import { CurrencyCell, PercentCell, UnitsCell } from '../../controls/NumericCell'
import { Pill } from '../../controls/Pill'
import { CardLink } from '../../views/ViewLinks'

const logger = new Logger("portfolio.PortfolioPerformance")

export function PortfolioPerformance(props:any) {
  const { model, name, asset, isProperty } = useInvestmentModel<ValuationModel>()
  const { pivotTable } = usePivotTables()
  const { assetPerf, portfolioPerf, date } = usePortfolioPerformance(0)
  const isUnitised = true

  const assetPerfs = portfolioPerf.accountPerfSorted

  logger.setContext(name).debug("Rendering:")

  return (
    <table id="PortfolioPerformance" className="table form">
      <thead>
        <tr>
          <th>Price Growth to {formatDate(date)}</th>
          { assetPerfs.map(calc => 
            <th key={calc.key}>
              <CardLink item={calc.item} hideIcon />
            </th>
          )}
          <th>Totals</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Opening Price</th>
          { assetPerfs.map(calc => 
            <UnitsCell value={calc.openingPrice} key={calc.key} />
          )}
          <td/>
        </tr>
        <tr>
          <th>Current Price</th>
          { assetPerfs.map(calc => 
            <UnitsCell value={calc.currentPrice} key={calc.key} />
          )}
          <td/>
        </tr>
        <tr>
          <th>Growth Rate (CAGR)</th>
          { assetPerfs.map(calc => 
            <td className="change" key={calc.key}>
              <Pill value={calc.cagr} formatStr={NumberFormats.percent1} key={calc.key} />
            </td>
          )}
          <td/>
        </tr>
      </tbody>
      { isUnitised &&
        <Unitised/>
      }
      { isProperty &&
        <Property/>
      }
    </table>
  )

  function Unitised(props:any) {
    const currentRow = pivotTable.currentRow
    const categories = model.children(ModelKeys.category.finance, 
                                      (category => (currentRow.cell(category.key)?.cumTotal || 0) !== 0))
    model.sortItems(categories)

    return (
      <>
        <thead>
          <tr>
            <th>Holdings</th>
            <td colSpan={assetPerfs.length}></td>
            <th>Totals</th>
          </tr>
        </thead>
        <tbody>
          { categories.map(category => 
            <tr key={category.key}>
              <th>{ category.name }</th>
              { assetPerfs.map(calc => 
                <CurrencyCell value={calc.cumTotal(category)} key={calc.key} />
              )}
              <CurrencyCell value={assetPerf.cumTotal(category)} />
            </tr>
          )}
          <tr>
            <th>Expenses & Tax</th>
            { assetPerfs.map(calc => 
              <CurrencyCell value={calc.nonFinanceTotal} key={calc.key} />
            )}
            <CurrencyCell value={assetPerf.nonFinanceTotal} />
          </tr>
        </tbody>
        <tfoot>
          <tr className="totals">
            <th>Balance</th>
            { assetPerfs.map(calc => 
              <CurrencyCell value={calc.currentBalance} key={calc.key} />
            )}
            <CurrencyCell value={assetPerf.currentBalance} />
          </tr>
        </tfoot>
      </>
    )
  }

  function Property(props:any) {
    const valuationCalc = undefined
    const currentRow = pivotTable.currentRow
    const yieldCalc = new YieldCalculator(asset, pivotTable, valuationCalc)
    const { value, debt, equity, equityRatio, yields } = yieldCalc

    return (
      <>
      <tbody>
        <tr>
          <th>Capital Value</th>
          <CurrencyCell value={value.initialValue} />
          <CurrencyCell value={value.currentValue} />
        </tr>
        <tr>
          <th>Mortgage</th>
          <CurrencyCell value={debt.initialDebt} />
          <CurrencyCell value={debt.currentDebt} />
        </tr>
        <tr>
          <th>Equity</th>
          <CurrencyCell value={equity.initialEquity} />
          <CurrencyCell value={equity.currentEquity} />
        </tr>
        <tr>
          <th>Equity Ratio</th>
          <PercentCell value={equityRatio.initialEquityRatio} format={NumberFormats.percent0} />
          <PercentCell value={equityRatio.currentEquityRatio} format={NumberFormats.percent0} />
        </tr>
        <tr>
          <th/>
          <td/>
          <td/>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th>Yield</th>
          <th/>
          <th>{formatDate(currentRow.date)}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Income</th>
          <td/>
          <CurrencyCell value={yields.income} />
        </tr>
        <tr>
          <th>Expenses</th>
          <td/>
          <CurrencyCell value={yields.expenses} />
        </tr>
        <tr>
          <th>Margin</th>
          <td/>
          <CurrencyCell value={yields.grossMargin} />
        </tr>
        <tr>
          <th>Gross Yield</th>
          <td/>
          <PercentCell value={yields.grossYield} />
        </tr>
        <tr>
          <th>Net Yield</th>
          <td/>
          <PercentCell value={yields.netYield} />
        </tr>
      </tbody>
      </>
    )
  }
}
