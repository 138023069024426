import { PropertyItemProps } from '../../../core/types/PropertyItem'
import { Logger } from "../../../core/utils/Logger"
import { NumberFormats } from '../../../core/utils/Numbers'
import { useReadonly } from "../../../hooks/useReadonly"
import { EditField } from '../../controls/editor/EditField'
import { Editable } from '../../controls/editor/Editable'

const logger = new Logger("editor.PropertyItemEditor")

export function PropertyItemEditor(props:any) {
  const { readonly } = useReadonly()
  const { asset } = props

  logger.debug("Rendering: item.key=%s", asset.key);

  return (
    <div>
      <EditField label="Address">
        <Editable item={asset} property={PropertyItemProps.address} readonly={readonly} />
      </EditField>
      <EditField label="Legal Description">
        <Editable item={asset} property={PropertyItemProps.legalDescription} readonly={readonly} />
      </EditField>
      <EditField label={<span>Land Area (m<sup>2</sup>)</span>}>
        <Editable item={asset} property={PropertyItemProps.landArea} readonly={readonly} numberFormat={NumberFormats.integer} />
      </EditField>
    </div>
  )
}