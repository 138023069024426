import { Placement } from '@floating-ui/react'
import { ReactNode } from 'react'
import { PopoverFUI } from './PopoverFUI'

export type MenuPlacement = Placement

export interface MenuPopoverProps {
  label?: ReactNode
  title?: string
  placeholder?: string
  buttonImage?: string
  buttonClass?: string
  popoverClass?: string
  placement?: MenuPlacement
  offset?: number[]
  children?: ReactNode
  onOpen?: () => void
  onClose?: () => void
}

export function MenuPopover(props: MenuPopoverProps) {
  return <PopoverFUI {...props} />
}
