import { FormatDurationOptions } from 'date-fns'
import { LoanCalculator } from '../../../core/invest/LoanCalculator'
import { LoanModel } from '../../../core/invest/LoanModel'
import { formatDate, formatDurationSafe } from '../../../core/utils/DateFormat'
import { Logger } from "../../../core/utils/Logger"
import { formatPercent } from '../../../core/utils/Numbers'
import { useInvestmentModel } from '../../../hooks/context/InvestmentModelContext'
import { usePivotTables } from '../../../hooks/pivot/usePivotTables'
import { useTransferAccount } from "../../../hooks/transfer/useTransferAccount"
import { Field, FieldSet } from '../../controls/form/Form'
import { CurrencyCell } from '../../controls/NumericCell'

const logger = new Logger("cards.LoanItemDetails");

export function LoanItemDetails(props:any) {
  const { model, name, loan, currentItem, 
          actualTerm, actualFinishDate, calcTotalInterest, calcTotalPayments
        } = useInvestmentModel<LoanModel>()
  
  const { pivotTable } = usePivotTables()
  const { transferDescription } = useTransferAccount(loan.paymentTransactionKey)

  const { totalPayments, totalDeposits, totalInterest } = new LoanCalculator(pivotTable)
  
  const durationFormat = { format: ['years'] } as FormatDurationOptions

  logger.debug("Rendering: name=%s, loan=%o", name, loan)

  return (
    <div id="LoanItemDetails">
      <FieldSet visible={false}>
        <Field className="name">
          <label>Name</label>
          <div>{loan.name}</div>
        </Field>
        <Field className="accountNumber">
          <label>Account Number</label>
          <div>{loan.code}</div>
        </Field>
        <Field className="description">
          <label>Description</label>
          <div>{loan.description}</div>
        </Field>
      </FieldSet>

      <FieldSet>
        <Field>
          <h3>Amortization</h3>
          <h3>Original</h3>
          <h3>Current</h3>
        </Field>
        <Field className="principal">
          <label>Principal</label>
          <CurrencyCell value={-loan.principal} />
          <CurrencyCell value={currentItem?.balance} />
        </Field>
        <Field className="rate">
          <label>Interest Rate</label>
          <div>{ formatPercent(loan.rate) }</div>
          <div>{ formatPercent(currentItem?.interestRate) }</div>
        </Field>
        <Field className="term">
          <label>Term</label>
          <div>{ formatDurationSafe(loan.term, durationFormat) }</div>
          <div>{ formatDurationSafe(actualTerm, durationFormat) }</div>
        </Field>
        <Field className="startDate">
          <label>Start Date</label>
          <div>{formatDate(loan.startDate)}</div>
        </Field>
        <Field className="finishDate">
          <label>Finish Date</label>
          <div>{ formatDate(loan.finishDate) }</div>
          <div>{ formatDate(actualFinishDate) }</div>
        </Field>
      </FieldSet>

      <FieldSet>
        <Field className="paymentFrequency">
          <label>Payment Frequency</label>
          <div>{formatDurationSafe(loan.paymentFrequency)}</div>
        </Field>
        <Field className="paymentAmount">
          <label>Payment Amount</label>
          <CurrencyCell value={-loan.paymentAmount} />
        </Field>
        <Field className="paymentAccount">
          <label>Payment Account</label>
          <div>{transferDescription}</div>
        </Field>
        <Field className="offsetAccount" visible={loan.offsetAccountKey !== undefined}>
          <label>Offset Account</label>
          <div>{ model.getItem(loan.offsetAccountKey)?.name }</div>
        </Field>
      </FieldSet>

      <FieldSet className="totals">
        <Field>
          <label>Total Payments</label>
          <CurrencyCell value={-calcTotalPayments} />
          <CurrencyCell value={-(totalPayments + totalDeposits)} />
        </Field>
        <Field>
          <label>Total Interest</label>
          <CurrencyCell value={-calcTotalInterest} />
          <CurrencyCell value={totalInterest} />
        </Field>
      </FieldSet>
    </div>
  )
}