import * as rdd from 'react-device-detect'

export function getDeviceInfo() {
  return {
    deviceType: rdd.isDesktop ? "Desktop" : 
                rdd.isMobileOnly ? "Mobile" : 
                rdd.isTablet ? "Tablet" : rdd.deviceType,
    os:       `${rdd.osName} ${rdd.osVersion}`,
    browser:  `${rdd.browserName} ${rdd.fullBrowserVersion}`,
    engine:   `${rdd.engineName} ${rdd.engineVersion}`,
    mobile:   `${rdd.mobileVendor} ${rdd.mobileModel}`,
    userAgent: rdd.getUA,
  }
}