import { Duration } from "date-fns"
import { setItemPropNames } from "./Item"
import { AccountItem } from "./AccountItem"

/**
 * Defines the parameters of an amortized loan (e.g. a mortgage)
 */
export interface LoanItem extends AccountItem {
  /** Type of loan, interest-only allows a specified period where no principal is repaid */
  type: "Amortizing" | "InterestOnly"

  /** Date at which the loan starts i.e. is drawn-down */
  startDate: number

  /** Date at which the loan finishes i.e. is fully repaid */
  finishDate: number

  /** The initial amount of the loan */
  principal: number

  /** The interest rate expressed as percent per annum */
  rate: number

  /** The duration of the loan */
  term: Duration

  /** The frequency of repayments, expressed as the duration between payments */
  paymentFrequency: Duration

  /** The repayment amount per period */
  paymentAmount: number

  /** Transaction for repayments */
  paymentTransactionKey?: string

  /** Account used for repayments */
  paymentAccountKey?: string

  /** Account to offset interest charges */
  offsetAccountKey?: string

  /** The duration for which interest-only payments are allowed */
  termInterestOnly?: Duration
}

type LoanItemProp = keyof LoanItem

type LoanItemRecord = Record<LoanItemProp, LoanItemProp>

export const LoanItemProps:LoanItemRecord = {
  key: "key",
  code: "code",
  name: "name",
  description: "description",
  parentKey: "parentKey",
  typeKey: "typeKey",
  links: "links",
  sortOrder: "sortOrder",
  status: "status",
  modifiedDate: "modifiedDate",
  providerKey: "providerKey",
  currency: "currency",
  type: "type",
  startDate: "startDate",
  finishDate: "finishDate",
  principal: "principal",
  rate: "rate",
  term: "term",
  paymentFrequency: "paymentFrequency",
  paymentAmount: "paymentAmount",
  paymentTransactionKey: "paymentTransactionKey",
  paymentAccountKey: "paymentAccountKey",
  offsetAccountKey: "offsetAccountKey",
  termInterestOnly: "termInterestOnly"
}

setItemPropNames(LoanItemProps)

export const LoanItemPropNames = Object.keys(LoanItemProps) as LoanItemProp[]
