import { useAtom, useAtomValue, useSetAtom } from "jotai"
import { ModelKeys } from "../../../core/model/ModelKeys"
import { BankStmtRuleItem } from "../../../core/types/BankStmtRuleItem"
import { Logger } from "../../../core/utils/Logger"
import { BankStmtRuleAtoms } from "../../../hooks/bankstmt/BankStmtRuleAtoms"
import { BankStmtTransAtoms } from "../../../hooks/bankstmt/BankStmtTransAtoms"
import { useBankStmtModel } from '../../../hooks/bankstmt/useBankStmtModel'
import { useModel } from "../../../hooks/context/ModelContext"
import { Card, CardHeader, CardSection } from "../../controls/Card"
import { ButtonDelete } from "../../controls/buttons/ButtonDelete"
import { setDialogItem } from "../../controls/dialog/ItemDialog"
import { SearchBox } from "../../search/SearchBox"

const logger = new Logger("cards.BankStmtRuleCard")

export function BankStmtRuleCard(props:any) {
  const { model } = useModel()

  logger.debug("Rendering")

  return (
    <Card id="BankStmtRuleCard">
      <CardHeader>
        <i className={model.getItemIcon(ModelKeys.bank.rules)} />
        <h2>Categorisation Rules</h2>
        <SearchBox searchValueAtom={BankStmtRuleAtoms.searchValue} />
      </CardHeader>
      <CardSection>
        <table className="table sticky-headers">
          <THead/>
          <TBody/>
        </table>
      </CardSection>
    </Card>
  )

  function THead(props:any) {
    const [sortOrder, setSortOrder] = useAtom(BankStmtRuleAtoms.sortOrder)
  
    logger.debug("THead: sortOrder=%s", sortOrder)

    return (
      <thead>
        <tr>
          <th>
            <button onClick={sortBySequence}>
              <i className={"fal fa-sort-numeric-" + (sortOrder === "SEQ-DOWN" ? "up" : "down")} />
            </button>
          </th>
          <th>
            <button onClick={sortByPriority}>
              <label>Priority</label>
              <i className={"fal fa-sort-numeric-" + (sortOrder === "PRI-DOWN" ? "up" : "down")} />
            </button>
          </th>
          <th>
            <button onClick={sortByKeywords}>
              Keywords
              <i className={"fal fa-sort-alpha-" + (sortOrder === "KEY-DOWN" ? "up" : "down")} />
            </button>
          </th>
          <th>
            <button onClick={sortByCategory}>
              Category
              <i className={"fal fa-sort-alpha-" + (sortOrder === "CAT-DOWN" ? "up" : "down")} />
            </button>
          </th>
          <th/>
        </tr>
      </thead>
    )

    function sortBySequence() {
      setSortOrder((sortOrder === "SEQ-DOWN") ? "SEQ-UP" : "SEQ-DOWN")
    }
  
    function sortByPriority() {
      setSortOrder((sortOrder === "PRI-DOWN") ? "PRI-UP" : "PRI-DOWN")
    }
  
    function sortByKeywords() {
      setSortOrder((sortOrder === "KEY-DOWN") ? "KEY-UP" : "KEY-DOWN")
    }
  
    function sortByCategory() {
      setSortOrder((sortOrder === "CAT-DOWN") ? "CAT-UP" : "CAT-DOWN")
    }
  }

  function TBody(props:any) {
    const rules = useAtomValue(BankStmtRuleAtoms.sortedRules)

    logger.debug("TBody: Rendering %d rules", rules.length)

    return (
      <tbody>
        { rules.map((rule) => 
          <BankStmtRuleRow rule={rule} key={rule.key}/>
        )}
      </tbody>
    )
  }

  function BankStmtRuleRow({ rule }:{ rule:BankStmtRuleItem }) {
    const bankStmtModel = useBankStmtModel()
    const setTransactions = useSetAtom(BankStmtTransAtoms.modalTransactions)

    return (
      <tr className="link" onClick={() => setDialogItem(rule, true)}>
        <td>
          { rule.sortOrder }
        </td>
        <td>
          { rule.priority }
        </td>
        <td>
          { rule.name }
        </td>
        <td>
          { model.getItem(rule.categoryKey)?.name }
        </td>
        <td className="button-delete">
          <menu>
            <button onClick={onShowTrans} title={`Show transactions matching rule "${rule.name}"`}>
              <i className="fal fa-memo" />
            </button>

            <ButtonDelete item={rule}/>
          </menu>
        </td>
      </tr>
    )

    function onShowTrans(e:any) {
      e.stopPropagation()
      
      const { transactions } = bankStmtModel.getTransactions(bankStmtModel.statements, rule)
      setTransactions({
        title: `${transactions.length} Transactions matching rule "${rule?.name}"`,
        transactions: transactions,
      })
    }
  }
}
