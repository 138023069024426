import { Model } from "../../model/Model"
import { Logger } from "../../utils/Logger"
import { loadFisherFundsCsv } from "./CsvFisherFundsParser"
import { loadSimpleTransCsv } from "./CsvSimpleTransParser"
import { loadWiseCsv } from "./CsvWiseParser"
import { loadOfx } from "./OfxParser"

const logger = new Logger("builder.loadBankStmtFile")

export function loadBankStmtFile(model:Model, fileName:string, content:any) {
  let results
  try {
    if (fileName.endsWith(".ofx")) {
      results = loadOfx(content, model, fileName)
    } else if (fileName.endsWith(".csv") && fileName.startsWith("FF_")) {
      results = loadFisherFundsCsv(content, model, fileName)
    } else if (fileName.endsWith(".csv") && fileName.startsWith("Wise-")) {
      results = loadWiseCsv(content, model, fileName)
    } else if (fileName.endsWith(".csv")) {
      results = loadSimpleTransCsv(content, model, fileName)
    }

    // setText(JSON.stringify(result, null, 2))
    logger.debug("Parsed '%s', results:", fileName, results)

  } catch (e:any) {
    const message = logger.error("loadBankStmtFile", "Error loading %s", fileName)
    throw Error(message, { cause:e })
  }

  return results
}
