import { setProperty } from "../../core/model/Model"
import { Asset } from "../../core/model/ModelKeys"
import { ItemProps } from "../../core/types/Item"
import { ValuationItem, ValuationItemProps } from "../../core/types/ValuationItem"
import { Logger } from "../../core/utils/Logger"
import { NumberFormats } from "../../core/utils/Numbers"
import { useFormItem } from "../../hooks/context/FormContext"
import { useModel } from '../../hooks/context/ModelContext'
import { useAtomRef } from "../../hooks/useAtomRef"
import { ShowIf, ShowIfAtom } from "../controls/ShowIf"
import { DateEditor } from "../controls/editor/DateEditor"
import { Editable } from "../controls/editor/Editable"
import { Field, FieldSet, Form, FormBody } from "../controls/form/Form"
import { FormFooter } from "../controls/form/FormFooter"
import { FormHeader } from "../controls/form/FormHeader"
import { ItemFormSharedFields } from "./ItemFormSharedFields"

const logger = new Logger("forms.ValuationItemForm")

export function ValuationItemForm({ item, formItemKey, onClose, noHeader, open } : {
  item: ValuationItem
  formItemKey?: string
  open?: boolean
  noHeader?: boolean
  onClose: () => void
}) {
  const { model } = useModel()
  const [ valuation, setValuation ] = useFormItem(formItemKey ?? "valuationItem", item)
  const openAtom = useAtomRef(open === true)

  const isUnitised = Asset.isUnitised(model.getItemParent(valuation))
  const icon = model.getItemIcon(valuation) || "fal fa-line-columns"

  logger.debug("Rendering: key=%s", valuation.key, valuation)

  // Render a Card view
  return (
    <Form id="ValuationItemForm">
      {!noHeader &&
        <FormHeader title="Valuation Editor" icon={icon} onClose={onClose} openAtom={openAtom} />
      }

      <FormBody>
        <FieldSet>
          <Field>
            <label>Name</label>
            <Editable item={valuation} property={ItemProps.name} onChange={onChange} readonly={false} />
          </Field>
          <ShowIfAtom visible={openAtom}>
            <Field>
              <label>Code</label>
              <Editable item={valuation} property={ItemProps.code} onChange={onChange} readonly={false} />
            </Field>
          </ShowIfAtom>
          <Field>
            <label>Description</label>
            <Editable item={valuation} property={ItemProps.description} onChange={onChange} readonly={false} />
          </Field>
        </FieldSet>

        <FieldSet>
          <Field>
            <label>Date</label>
            <DateEditor item={valuation} property={ValuationItemProps.date} onChange={onChange} readonly={false} />
          </Field>

          <ShowIf visible={isUnitised}>
            <Field>
              <label>Price</label>
              <Editable item={valuation} readonly={false}
                        property={ValuationItemProps.unitPrice}
                        onChange={onChangeUnitPrice}
                        numberFormat={NumberFormats.units} />
            </Field>
            <Field>
              <label>Balance</label>
              <Editable item={valuation} readonly={false}
                        property={ValuationItemProps.unitBalance}
                        onChange={onChangeUnitBalance}
                        numberFormat={NumberFormats.units} />
            </Field>
          </ShowIf>
          
          <Field>
            <label>Value</label>
            <Editable item={valuation} readonly={isUnitised}
                      property={ValuationItemProps.value}
                      onChange={onChange}
                      numberFormat={NumberFormats.currency2} />
          </Field>
        </FieldSet>

        <ItemFormSharedFields item={valuation} onChange={onChange} readonly={false} openAtom={openAtom} />
      </FormBody>
      
      { !noHeader &&
        <FormFooter showClose showDelete showSave onClose={onClose}/>
      }
    </Form>
  )

  function onChange(valuation:ValuationItem, property:any, value:any) {
    setValuation(setProperty({...valuation}, property, value))
  }

  function onChangeUnitPrice(valuation:ValuationItem, property:any, unitPrice:number) {
    const newValuation = setProperty({...valuation}, property, unitPrice)
    if (newValuation.unitBalance) {
      newValuation.value = unitPrice * newValuation.unitBalance
    }
    setValuation(newValuation)
  }

  function onChangeUnitBalance(item:ValuationItem, property:any, unitBalance:any) {
    const newValuation = setProperty({...item}, property, unitBalance)
    if (newValuation.unitPrice) {
      newValuation.value = unitBalance * newValuation.unitPrice
    }
    setValuation(newValuation)
  }
}

