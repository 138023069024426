import { ReactNode } from "react"
import { Filter } from "../core/filter/Filter"
import { useModel } from "../hooks/context/ModelContext"
import { ViewContextProvider } from "../hooks/context/ViewContext"
import { useFilter } from "../hooks/filter/useFilter"
import { getDashboardScale } from "../ui/cards/dashboard/DashboardCard"
import { ItemDialogProvider } from "../ui/controls/dialog/ItemDialogProvider"

export function PageProvider({ itemCode, stackName, filter, children } : { 
  itemCode?: string, 
  stackName?: string,
  filter?: Partial<Filter>
  children: ReactNode
}) {
  const { model } = useModel()
  const item = model.getItem(itemCode ?? "MY-ACCOUNTS")

  return (
    <ItemDialogProvider item={item} stackName={stackName ?? "docs.PageProvider"} initFilter={getDashboardScale} editor={false}>
      <ViewContextProvider>
        <Children/>
      </ViewContextProvider>
    </ItemDialogProvider>
  )

  function Children() {
    useFilter(filter ?? {})
    return <div className="excerpt">{children}</div>
  }
}