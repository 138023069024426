import { CashflowPivotTable } from "../../ui/cards/cashflow/CashflowPivotTable"
import { PortfolioSummaryTable } from "../../ui/cards/dashboard/PortfolioSummaryTable"
import { ModelTable } from "../../ui/cards/table/ModelTable"
import { AccountBalanceAreaChart } from "../../ui/charts/cashflow/AccountBalanceAreaChart"
import { ExpandableLabel } from "../../ui/controls/Expandable"
import { PageProvider } from "../PageProvider"
import { Section, SectionList } from "../PageSection"

const sectionMap = new Map<string,string>()

export function ConceptsPage() {
  return (
    <article>
      <h1>Concepts</h1>
      
      <SectionList sectionMap={sectionMap}/>

      <p>
        TODO Introduce the concepts
      </p>

      <section>
        <Section title="Accounts" sectionMap={sectionMap}>
          <p></p>
          <ExpandableLabel label="Show your Chart of Accounts">
            <PageProvider>
              <ModelTable />
            </PageProvider>
          </ExpandableLabel>
        </Section>

        <Section title="Portfolios" sectionMap={sectionMap}>
          <p></p>
          <ExpandableLabel label="Show sample Portfolio Dashboard" expand>
            <PageProvider itemCode="MY-INVESTMENTS" stackName="docs.Dashboard">
              <PortfolioSummaryTable/>
            </PageProvider>
          </ExpandableLabel>
        </Section>

        <Section title="Cashflows" sectionMap={sectionMap}>
          <p></p>
          <ExpandableLabel label="Show sample Cashflow Chart">
            <PageProvider>
              <AccountBalanceAreaChart height={150}/>
            </PageProvider>
          </ExpandableLabel>
        </Section>

        <Section title="Data Tables" sectionMap={sectionMap}>
          <p></p>
          <ExpandableLabel label="Show sample Pivot Table">
            <PageProvider itemCode="MY-ACCOUNTS" filter={{ dimension:"Category"}}>
              <CashflowPivotTable/>
            </PageProvider>
          </ExpandableLabel>
        </Section>
      </section>
    </article>
  )
}
