import { LoanAmortization } from "../invest/LoanAmortization"
import { LoanItem } from "../types/LoanItem"
import { Logger } from "../utils/Logger"

const logger = new Logger("tests.LoanTest")

const loan = {
  "status": "New",
  "code": "0089048200-00001",
  "paymentFrequency": {
    "weeks": 2
  },
  "principal": 1585000,
  "name": "BNZ Term Loan",
  "paymentTransactionKey": "PI-DYyOStVA",
  "rate": 0.0535,
  "description": "Mortgage on Wendover Rd",
  "startDate": 1668769199999,
  "parentKey": "PI-wjeHIWav",
  "sortOrder": 10020,
  "key": "PI-IeWAvwBy",
  "modifiedDate": 1708315998675,
  "typeKey": "ZZ-bVIwNkbW",
  "term": {
    "years": 30
  },
  "paymentAccountKey": "PI-4vvrRwdf"
} as LoanItem

export function runLoanAmortizationTest() {
  logger.start("LoanAmortizationTest")

  const la = new LoanAmortization(loan)
  logger.debug("loan=%o", la.logItem)

  // Set start date
  const startDate = new Date(2020,0,1)
  la.startDate = startDate.getTime()

  // Set finish date
  const finishDate = new Date(2035,11,31)
  la.finishDate = finishDate.getTime()

  // Set term
  la.term = { years:11 }

  // Set payment, calculate term
  la.paymentAmount = 4500

  // Set term again
  const term = la.term
  la.term = term

  logger.finish("LoanAmortizationTest", "loan=%o", la.logItem)
}
