import { useAtom } from "jotai"

export function ButtonOpen({ openAtom } : { openAtom:any }) {
  const [ isOpen, setOpen ] = useAtom(openAtom)

  const classImage = (isOpen ? "fas fa-chevron-up" : "fas fa-chevron-down")

  return (
    <button onClick={() => setOpen(!isOpen)}>
      <i className={classImage} />
    </button>
  )
}