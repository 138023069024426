import { ExpandableContents } from "../PageSection"

export function FeaturesPage() {
  return (
    <article>
      <h1>Feature Overview</h1>
      <section>
        <ExpandableContents path="features"/>
      </section>
    </article>
  )
}