import { isMobileOnly } from "react-device-detect"
import { removeItems, saveModifiedItems } from "../../../core/service/ModelAction"
import { Logger } from "../../../core/utils/Logger"
import { useFormData } from "../../../hooks/context/FormContext"
import { useModel } from "../../../hooks/context/ModelContext"
import { WizardSaveFn } from "../../wizards/WizardSavePage"
import { CardFooter } from "../Card"
import { FieldSet, Field } from "./Form"

const logger = new Logger("wizards.FormFooter")

export interface FormFooterProps {
  showSave?: boolean
  showClose?: boolean
  showDelete?: boolean
  onSave?: WizardSaveFn
  onClose: () => void
}

export function FormFooter({ showClose, showDelete, showSave, onSave, onClose } : FormFooterProps) {
  logger.debug("Rendering:")

  if (isMobileOnly) {
    return <></>
  }

  return (
    <>
      <CardFooter>
        <menu>
          { showDelete && <ButtonDelete onClose={onClose} /> }
        </menu>
        <menu>
          { showSave && <ButtonSave /> }
        </menu>
      </CardFooter>
    </>
  )

  function ButtonSave() {
    const { formItemMap } = useFormData()
  
    return (
      <button className="accept" onClick={onClick}>
        Save
      </button>
    )
  
    function onClick() {
      if (onSave) {
        onSave()
      } else {
        // Use form data hook to save changes
        saveModifiedItems(formItemMap.values())
  
        // Close the editor (if required)
        onClose && onClose()
      }
    }
  }
}
  
function ButtonDelete({ onClose }:{ onClose?: () => void }) {
  const { model } = useModel()
  const { formItemMap } = useFormData()

  return (
    <button className="delete" onClick={onDelete}>
      Delete
    </button>
  )

  function onDelete() {
    // Use form data hook to save changes
    removeItems(model, Array.from(formItemMap.values()).map(item => item.key))

    // Close the editor (if required)
    onClose && onClose()
  }
}


export function FieldDeleteButton({ onClose }:{ onClose?: () => void }) {
  return (
    <FieldSet>
      <Field>
        {/* <label>Delete this Item</label> */}
        <ButtonDelete onClose={onClose} />
      </Field>
    </FieldSet>
  )
}