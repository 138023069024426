import { useState } from 'react'
import { ValuationModel } from '../../../core/invest/ValuationModel'
import { formatDate } from '../../../core/utils/DateFormat'
import { Logger } from "../../../core/utils/Logger"
import { NumberFormats, formatNumber } from '../../../core/utils/Numbers'
import { calcPercentChange } from "../../../hooks/cashflow/useAccountCashflow"
import { useInvestmentModel } from '../../../hooks/context/InvestmentModelContext'
import { usePivotTables } from '../../../hooks/pivot/usePivotTables'
import { useCurrencyFormat } from "../../../hooks/useCurrencyFormat"
import { useReadonly } from "../../../hooks/useReadonly"
import { Pill } from "../../controls/Pill"
import { ButtonDelete } from "../../controls/buttons/ButtonDelete"
import { setDialogItem } from '../../controls/dialog/ItemDialog'

const logger = new Logger("asset.ValuationItemTable")

export function ValuationItemTable() {
  const { name, valuationItems, isUnitised } = useInvestmentModel<ValuationModel>()
  const { pivotTable: { currentRow } } = usePivotTables()
  const { readonly } = useReadonly()
  const currencyFormat = useCurrencyFormat()
  const [ showPercentChange, setShowPercentChange ] = useState(true)

  const valuationSorted = valuationItems.toSorted((i1,i2) => (i2.date - i1.date))

  logger.setContext(name)
        .debug("Rendering: date=%s, currentRow=%o", Logger.Date(currentRow?.date), currentRow)

  return (
    <table id="ValuationItemTable" className="table sticky-headers">
      <thead>
        <tr>
          <th className="date">Date</th>
          { isUnitised &&
            <>
              <th className="price">Price</th>
              <th className="balance">Balance</th>
            </>
          }
          <th className="value">Value</th>
          <th className="change link" onClick={onClickPill}>
            {showPercentChange ? '% ' : '$ '}Change
          </th>
          <th className="rowmenu">
          </th>
        </tr>
      </thead>
      <tbody>
        { valuationSorted.map((valuation, index) =>
          <tr className="link" onClick={() => setDialogItem(valuation, true)} key={valuation.key}>
            <td className="date">
              { formatDate(valuation.date)}
            </td>
            { isUnitised &&
              <>
                <td className="price">
                  { formatNumber(valuation.unitPrice, NumberFormats.units) }
                </td>
                <td className="balance">
                { formatNumber(valuation.unitBalance, NumberFormats.units) }
                </td>
              </>
            }
            <td className="value">
              { formatNumber(valuation.value, currencyFormat) }
            </td>
            <td className="change">
              <Pill {...calcPercent(index)} />
            </td>
            <td className="rowmenu">
              { !readonly && 
                <ButtonDelete item={valuation} />
              }
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )

  function onClickPill(e:any) {
    e.stopPropagation()
    setShowPercentChange(!showPercentChange)
  }

  function calcPercent(index:number) {
    // Note that valuations are sorted in date descending order
    const toItem = valuationSorted.at(index)
    const fromItem = valuationSorted.at(index+1)
    const to = toItem?.unitPrice ?? toItem?.value
    const from = fromItem?.unitPrice ?? fromItem?.value

    const { percentChange, percentFormat, valueChange } = calcPercentChange(from, to)

    if (showPercentChange) {
      return { value:percentChange, formatStr:percentFormat }
    }

    const value = valueChange * (toItem?.unitBalance ?? 1)
    return { value, formatStr:currencyFormat }
  }
}