import { Type } from "../../core/model/ModelKeys"
import { CashflowItem } from "../../core/types/CashflowItem"
import { Item } from "../../core/types/Item"
import { TransactionItem } from "../../core/types/TransactionItem"
import { Logger } from "../../core/utils/Logger"
import { useModel } from "../../hooks/context/ModelContext"
import { ItemDetailsCard } from "./settings/ItemDetailsCard"
import { TransactionItemDetailsCard } from "./transaction/TransactionItemDetailsCard"

const logger = new Logger("cards.ItemDetailsCardSelector")

export function ItemDetailsCardSelector(props:{
  item: Item | TransactionItem | CashflowItem | undefined
  id?: string
  open?: boolean
  noHeader?: boolean
  onBack?: (e:any, item:Item) => void
  onClose?: () => void
}) {
  const { model } = useModel()
  const { item, id, open, noHeader, onBack, onClose } = props
  
  logger.debug("Rendering: itemKey=%s", item?.key)

  if (!item) { 
    return <></>
  }

  // For a CashflowItem, the parent is the TransactionItem from which the CashflowItem
  // is derived - which may be from a bank statement or a budget item
  const parent = model.getItem<TransactionItem>(item.parentKey)
  if (isTransaction(parent)) {
    return <TransactionItemDetailsCard id={id} 
                                       transaction={parent} 
                                       onBack={onBack} 
                                       onClose={onClose} 
                                       noHeader={noHeader} />
  }

  // TransactionItem
  if (isTransaction(item)) {
    return <TransactionItemDetailsCard id={id} 
                                       transaction={item as TransactionItem} 
                                       onBack={onBack} 
                                       onClose={onClose} 
                                       noHeader={noHeader} />
  }

  // General
  return <ItemDetailsCard id={id} item={item} onBack={onBack} onClose={onClose} open={open} noHeader={noHeader} />

  function isTransaction(item?:Item) {
    return item && (model.isTransaction(item) || Type.isValuation(item))
  }
}
