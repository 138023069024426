import { setProperty } from "../../core/model/Model"
import { Item, ItemProps } from "../../core/types/Item"
import { FilesItem } from "../../core/user/FilesModel"
import { saveFileItem } from "../../core/user/FilesService"
import { Logger } from "../../core/utils/Logger"
import { NumberFormats } from "../../core/utils/Numbers"
import { useFormItem } from "../../hooks/context/FormContext"
import { useAtomRef } from "../../hooks/useAtomRef"
import { Editable } from "../controls/editor/Editable"
import { Field, FieldSet, Form, FormBody } from "../controls/form/Form"
import { FormFooter } from "../controls/form/FormFooter"
import { FormHeader } from "../controls/form/FormHeader"

const logger = new Logger("forms.ItemForm")

export function FilesItemForm(props : {
  item: FilesItem
  formItemKey?: string
  open?: boolean
  noHeader?: boolean
  onClose: () => void
}) {
  const { formItemKey, open, noHeader, onClose } = props

  const [ fileItem, setFileItem ] = useFormItem(formItemKey ?? "item", props.item)
  const openAtom = useAtomRef(open === true)

  const title = (fileItem.typeKey === "file" ? "File" : "Folder") + " Item Editor"
  const icon = "fal fa-file"
  const readonly = false

  logger.debug("Rendering: key=%s, open=%s, item=%o", fileItem.key, open, fileItem)

  return (
    <Form id="FilesItemForm">
      { !noHeader &&
        <FormHeader title={title} icon={icon} onClose={onClose} onSave={onSave} openAtom={openAtom} />
      }
      <FormBody>
        <FieldSet>
          <Field className="name">
            <label>Name</label>
            <Editable item={fileItem} property={ItemProps.name} onChange={onChange} readonly={false} />
          </Field>
          <Field className="description">
            <label>Description</label>
            <Editable item={fileItem} property={ItemProps.description} onChange={onChange} readonly={false} />
          </Field>
        </FieldSet>
        <FieldSet>
          <Field>
            <label>Key</label>
            <div className="readonly">{fileItem.key}</div>
          </Field>
          <Field>
            <label>Type</label>
            <div className="readonly">{fileItem.typeKey}</div>
          </Field>
          <Field>
            <label>Status</label>
            <div className="readonly">{fileItem.status}</div>
          </Field>
          <Field>
            <label>Sort Order</label>
            <Editable item={fileItem} property={ItemProps.sortOrder} onChange={onChange} numberFormat={NumberFormats.integer} readonly={readonly} />
          </Field>
        </FieldSet>
      </FormBody>

      { !noHeader &&
        <FormFooter showSave showClose onClose={onClose} onSave={onSave} />
      }
    </Form>
  )

  function onChange(item:Item, property:any, value:any) {
    logger.debug("onChange: item.key=%s, property=%s, value=%s", item.key, property, value)

    const newItem = setProperty({...item}, property, value)
    setFileItem(newItem)
  }

  function onSave() {
    saveFileItem(fileItem)
    onClose()
  }
}
