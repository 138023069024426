import { ModelKeys } from "../../core/model/ModelKeys"
import { Item, ItemProps } from "../../core/types/Item"
import { TypeItemProps } from "../../core/types/TypeItem"
import { formatDate } from '../../core/utils/DateFormat'
import { NumberFormats } from '../../core/utils/Numbers'
import { useModel } from "../../hooks/context/ModelContext"
import { ShowIf, ShowIfAtom } from "../controls/ShowIf"
import { Editable } from "../controls/editor/Editable"
import { Field, FieldSet } from "../controls/form/Form"
import { ItemPickerTree } from "../controls/select/ItemPickerTree"

export function ItemFormSharedFields<T extends Item>({ item, readonly, openAtom, hideType, onChange }: {
  item: T
  readonly: boolean
  openAtom: any
  hideType?: boolean
  onChange: (item:T, property:any, value:any) => void
}) {
  const { model } = useModel()
  const icon = model.getItemIcon(item)

  return (
    <ShowIfAtom visible={openAtom}>
      <FieldSet>
        <Field className="parent">
          <label>Parent Item</label>
          <ItemPickerTree
            rootKey={ModelKeys.root}
            searchItems={model.items()}
            selected={model.getItem(item.parentKey)}
            onChange={(selectedItem: Item) => onChange(item, ItemProps.parentKey, selectedItem.key)} 
            placeholder="Select a parent item"
            readonly={readonly}
          />
        </Field>
        <ShowIf visible={!hideType && !model.isType(item)}>
          <Field>
            <label>Type</label>
            <ItemPickerTree
              rootKey={ModelKeys.type.root}
              selected={model.getItem(item.typeKey)}
              onChange={(selected:Item) => onChange(item, ItemProps.typeKey, selected.key)}
              placeholder="Select a Type..."
              readonly={readonly}
            />
          </Field>
        </ShowIf>
        <Field>
          <label>Icon</label>
          <div className={readonly ? "icon readonly" : "icon"}>
            <Editable item={item} property={TypeItemProps.typeIcon} onChange={onChange} readonly={readonly} />
            <i className={icon} />
          </div>
        </Field>
        <Field>
          <label>Sort Order</label>
          <Editable item={item} property={ItemProps.sortOrder} onChange={onChange} numberFormat={NumberFormats.integer} readonly={readonly} />
        </Field>
      </FieldSet>

      <FieldSet>
        <Field>
          <label>Key</label>
          <div className="readonly">{item.key}</div>
        </Field>
        <Field>
          <label>Status</label>
          <div className="readonly">{item.status}</div>
        </Field>
        <Field>
          <label>Modified</label>
          <div className="readonly">{formatDate(item.modifiedDate, "yyyy-MM-dd HH:mm:ss")}</div>
        </Field>
      </FieldSet>
    </ShowIfAtom>
  )
}
