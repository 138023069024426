import { Link, useNavigate } from "react-router-dom"
import { reloadModel } from "../../../core/service/ModelService"
import { FilesItem } from "../../../core/user/FilesModel"
import { useCurrentUserProfile } from "../../../hooks/useCurrentUser"
import { useFilesModel } from "../../../hooks/useFilesModel"
import { Card, CardHeader, CardSection } from "../../controls/Card"
import { setDialogItem } from "../../controls/dialog/ItemDialog"

export function FilesCard() {
  const { files } = useFilesModel()

  return (
    <Card id="FilesCard">
      <CardHeader>
        <h2>
          <i className="fal fa-files" />
          File Manager
        </h2>
        <menu>
          <ButtonCreate />
        </menu>
      </CardHeader>
      <CardSection>
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Active</th>
            </tr>
          </thead>
          <tbody>
            { files.map(file => 
              <FileRow file={file} key={file.key} />
            )}
          </tbody>
        </table>
      </CardSection>
    </Card>
  )
}

function FileRow({ file }:{ file:FilesItem }) {
  return (
    <tr className="link" onClick={onClick} key={file.key}>
      <td>{file.name}</td>
      <td>{file.description}</td>
      <td><ButtonOpen fileKey={file.key}/></td>
    </tr>
  )

  function onClick() {
    setDialogItem(file, true)
  }
}

function ButtonCreate() {
  return (
    <menu>
      <Link to="/wizard/files" title="Create a new File">
        <i className="far fa-plus"></i>
      </Link>
    </menu>
  )
}

function ButtonOpen({ fileKey }:{ fileKey:string}) {
  const navigate = useNavigate()
  const { userProfile, setUserProfile } = useCurrentUserProfile()
  const { currentFile } = useFilesModel()
  const selected = (fileKey === currentFile?.key)
  const icon = (selected ? "fal fa-check-circle" : "fal fa-circle")

  return (
    <menu>
      <button onClick={onClick}>
        <i className={icon}/>
        <label>Open</label>
      </button>
    </menu>
  )

  function onClick(e:any) {
    e.stopPropagation()
    if (userProfile !== undefined && userProfile.fileKey !== fileKey) {
      // Update user profile, load specified file, and open the dashboard
      setUserProfile({...userProfile, fileKey:fileKey })
        .then(() => reloadModel())
        .then(() => navigate("/"))
    }
  }
}
