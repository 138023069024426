import { isSameDay } from "date-fns"
import { useAtomValue } from "jotai"
import { Asset } from "../../../core/model/ModelKeys"
import { TransactionItem } from "../../../core/types/TransactionItem"
import { formatDate } from '../../../core/utils/DateFormat'
import { Logger } from "../../../core/utils/Logger"
import { NumberFormats, formatCurrency, formatNumber } from '../../../core/utils/Numbers'
import { BankStmtTransAtoms } from "../../../hooks/bankstmt/BankStmtTransAtoms"
import { useModel } from "../../../hooks/context/ModelContext"
import { setDialogItem } from "../../controls/dialog/ItemDialog"

const logger = new Logger("table.BankStmtTransTable")

export function BankStmtTransTable(props:{transactions?:TransactionItem[]}) {
  const { model } = useModel()

  const bankStmt = useAtomValue(BankStmtTransAtoms.bankStmtFile)
  const isUnitised = Asset.isUnitised(model.getItem(bankStmt.accountKey))

  let transactions = useAtomValue(BankStmtTransAtoms.searchResults)
  if (props.transactions) {
    transactions = props.transactions
  }
  
  const { moneyIn, moneyOut, total, totalUnits } = calcTotal()

  logger.debug("Rendering: %s, %d transactions", bankStmt.name, transactions.length)

  return (
    <table id="BankStmtTransTable" className={"table cashflow-table sticky-headers" + (isUnitised ? " unitised" : "")}>
      <thead>
        <tr>
          <th className="name">Name</th>
          <th className="description">Description</th>
          <th className="rule">Rule</th>
          <th className="category">Category</th>
          <th className="units">Units</th>
          <th className="unit-price">Unit Price</th>
          <th className="unit-balance">Unit Balance</th>
          <th className="money-in">Money In</th>
          <th className="money-out">Money Out</th>
          <th className="amount">Amount</th>
          <th className="balance">Balance</th>
        </tr>
      </thead>
      <tbody>
        { transactions.map((trans,i) =>
          <TransactionRow 
            transaction={trans} 
            isNewDay={(i === 0) || !isSameDay(trans.startDate ?? 0, transactions[i-1].startDate ?? 0)}
            isUnitised={isUnitised}
            key={trans.key} />
        )}
      </tbody>
      <tfoot className="totals">
        <tr>
          <th className="name">Total</th>
          <td className="description"/>
          <td className="rule"/>
          <td className="category"/>
          <td className="units">{formatNumber(totalUnits, NumberFormats.units)}</td>
          <td className="unit-price"></td>
          <td className="unit-balance">{formatNumber(bankStmt.openingUnitBalance, NumberFormats.units)}</td>
          <td className="money-in">{formatCurrency(moneyIn)}</td>
          <td className="money-out">{formatCurrency(moneyOut)}</td>
          <td className="amount">{formatCurrency(total)}</td>
          <td className={"balance " + negative(bankStmt.openingBalance)}>
            { formatCurrency(bankStmt.openingBalance) }
          </td>
        </tr>
      </tfoot>
    </table>
  )

  function calcTotal() {
    // Calc money in/out here so it reflects results of search (if any)
    let moneyIn=0, moneyOut=0, total=0, totalUnits
    for (const trans of transactions) {
      total += trans.value
      if (trans.value > 0)
        moneyIn += trans.value
      else 
        moneyOut += trans.value

      if (trans.units !== undefined) {
        totalUnits = (totalUnits || 0) + trans.units
      }
    }     

    return { moneyIn, moneyOut, total, totalUnits }
  }
}

function TransactionRow({ transaction, isNewDay, isUnitised }: { transaction:TransactionItem, isNewDay:boolean, isUnitised:boolean }) {
  const { model } = useModel() 

  return (
    <>
      { isNewDay &&
        <tr className="subtitle">
          <th className="date" colSpan={isUnitised ? 9 : 7}>
            {formatDate(transaction.startDate || 0)}
          </th>
        </tr>
      }
      <tr className="link" onClick={() => setDialogItem(transaction, true)}>
        <td className="name">
          <i className={model.getItemIcon(transaction)} />
          {transaction.name}
        </td>
        <td className="description">
          {transaction.description}
        </td>
        <td className="rule">
          { model.getItemName(transaction.ruleKey)}
        </td>
        <td className="category">
          { model.getItemName(transaction.categoryKey)}
        </td>

        <UnitCells/>
        <MoneyInOutAmountCells/>

        <td className={"balance" + negative(transaction.balance)}>
          {formatCurrency(transaction.balance)}
        </td>
      </tr>
    </>
  )

  function MoneyInOutAmountCells() {
    const value = transaction.value
    const valueStr = formatCurrency(value)

    if (value >= 0) return (
      <>
        <td className="money-in">{valueStr}</td>
        <td className="money-out"></td>
        <td className="amount">{valueStr}</td>
      </>
    )

    return (
      <>
        <td className="money-in"></td>
        <td className="money-out">{valueStr}</td>
        <td className="amount negative">{valueStr}</td>
      </>
    )
  }

  function UnitCells() {
    const { units, unitPrice, unitBalance } = transaction

    return (
      <>
        <td className={"units" + negative(units)}>{formatNumber(units, NumberFormats.units)}</td>
        <td className="unit-price">{formatNumber(unitPrice, NumberFormats.units)}</td>
        <td className="unit-balance">{formatNumber(unitBalance, NumberFormats.units)}</td>
      </>
    )
  }
}

function negative(value?:number) {
  return (value && value < 0) ? " negative" : ""
}
