import { Duration } from "date-fns"
import { Item } from '../../../core/types/Item'
import { formatDurationSafe } from "../../../core/utils/DateFormat"
import { Logger } from "../../../core/utils/Logger"
import { clzName } from "../../../core/utils/Utils"

const logger = new Logger("control.DurationEditor")

const allowedUnits:(keyof Duration)[] = [ "days", "weeks", "months", "years" ]

export function DurationEditor<T extends Item>(props:{
  item: T
  property: any // keyof T
  readonly?: boolean
  onChange?: (item:T, property:any, newValue:any) => void
}) {
  const { item, onChange, readonly } = props
  const property:keyof T = props.property

  const duration:Duration = item[property] ?? {}

  const durationStr = formatDurationSafe(duration) // || placeholder || "No Repeats"
  const placeholderStr = durationStr ? undefined : "Enter a frequency like 2w, 1m, 1y"

  const className = clzName({ editable:!readonly, readonly:readonly })

  logger.debug("Rendering: %s=%o", property, duration)

  return (
    <div contentEditable={!readonly} 
         tabIndex={readonly ? undefined : 0}
         onBlur={onChangeDuration}
        //  onFocus={onFocus}
         className={className}
         data-placeholder={placeholderStr}
      >
      { durationStr }
    </div>
  )

  // return (
  //   <input type="text" 
  //          defaultValue={durationStr}  
  //          placeholder={placeholderStr}
  //          tabIndex={readonly ? undefined : 0}
  //          onBlur={onChangeDuration} 
  //          onFocus={onFocus}
  //          readOnly={readonly} />
  // )

  // function onFocus(e:any) {
  //   e.target.select()
  // }

  function onChangeDuration(e:any) {
    const text = e.target.innerText
    const value = parseValue(text)
    const units = parseUnits(text)

    const duration:Duration|undefined = value ? { [units]:value } : undefined

    logger.debug("onChangeValue: text=%s, value=%s, units=%s, duration=%o", text, value, units, duration)

    // Notify caller
    onChange && onChange(item, property, duration)
  }

  function parseValue(text:string) {
    return parseInt(text)
  }

  function parseUnits(text:string) : keyof Duration {
    for (const units of allowedUnits) {
      const p = text.indexOf(units.charAt(0))
      if (p !== -1) {
        return units
      }
    }
    return "months"
  }
}
