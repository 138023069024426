import { atom, useAtom } from "jotai"
import { setAtomValue } from "../../../core/atom/AtomUtils"
import { Item } from "../../../core/types/Item"
import { Logger } from "../../../core/utils/Logger"
import { FormDataProvider } from "../../../hooks/context/FormContext"
import { ItemDetailsCardSelector } from "../../cards/ItemDetailsCardSelector"
import { FormSelector } from "../../forms/FormSelector"
import { Dialog } from "./Dialog"
import { ItemDialogProvider } from "./ItemDialogProvider"

export const logger = new Logger("dialog.ItemDialog")

export interface ItemDialogProps {
  item: Item | undefined
  stackName: string | undefined
  editor: boolean
}

const itemDialogAtom = atom<ItemDialogProps>({ item:undefined, stackName:undefined, editor:false })

export function setDialogItem(item:Item|undefined, editor=false, stackName?:string) {
  setAtomValue(itemDialogAtom, { item, editor, stackName })
}

export function ItemDialog(props:any) {
  const [dialogItem, setDialogItem] = useAtom(itemDialogAtom)
  const { item, editor, stackName } = dialogItem

  logger.debug("Rendering: editor=%s, itemKey=%s, stackName=%s", editor, item?.key, stackName)

  return (
    <ItemDialogProvider {...dialogItem}>
      <Dialog open={item !== undefined} modal={true} onClose={onClose}>
        { editor
          ? <FormDataProvider>
              <FormSelector item={item} onClose={onClose} />
            </FormDataProvider>
          : <ItemDetailsCardSelector item={item} onClose={onClose} open />
        }
      </Dialog>
    </ItemDialogProvider>
  )

  function onClose() {
    logger.debug("onClose: editable=%s, itemKey=%s", editor, item?.key)
    setDialogItem({ item:undefined, editor, stackName })
  }
}
