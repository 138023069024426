import { BudgetModel } from "../invest/BudgetModel"
import { InvestmentModel } from "../invest/InvestmentModel"
import { LoanModel } from "../invest/LoanModel"
import { PortfolioModel } from "../invest/PortfolioModel"
import { PropertyModel } from "../invest/PropertyModel"
import { ValuationModel } from "../invest/ValuationModel"
import { Model } from "../model/Model"
import { ModelKeys, Type } from "../model/ModelKeys"
import { Item, ItemStatus } from "../types/Item"
import { LoanItem } from "../types/LoanItem"
import { PropertyItem } from "../types/PropertyItem"
import { ValuationItem } from "../types/ValuationItem"
import { Logger } from "../utils/Logger"
import { ModelAnalyzer } from "./ModelAnalyzer"
import { AccountPerformance } from "./performance/AccountPerformance"

const logger = new Logger("builders.InvestmentModelBuilder")

export class InvestmentModelBuilder {
  private model: Model
  private analyzer: ModelAnalyzer

  private _created = false
  private _built = false
  private modelMap = new Map<string, InvestmentModel<Item>>()
  private builtMap = new Map<string, boolean>()
  private postBuiltMap = new Map<string, boolean>()
  private modifiedItems = new Set<string>()
  
  private performanceMap = new Map<string, AccountPerformance>()

  constructor(model:Model) {
    this.model = model
    this.analyzer = new ModelAnalyzer(model)

    logger.debug("Created new InvestmentModelBuilder")
  }

  get created() {
    return this._created
  }

  get built() {
    return this._built
  }

  get builtCount() {
    let count = 0
    for (const built of this.builtMap.values()) {
      if (built) count++
    }
    return count
  }

  get postBuiltCount() {
    let count = 0
    for (const built of this.postBuiltMap.values()) {
      if (built) count++
    }
    return count
  }

  get models() {
    return this.modelMap.values()
  }

  get modelCount() {
    return this.modelMap.size
  }

  get modelItemKeys() {
    return this.modelMap.keys()
  }

  public getModel<T extends InvestmentModel<Item>>(itemKey:string) : T {
    return this.modelMap.get(itemKey) as T
  }

  public getModelPerf(itemKey:string, days=30) {
    let perf = this.performanceMap.get(itemKey)
    if (!perf || perf.days !== days) {
      perf = new AccountPerformance(this.getModel(itemKey), days)
      this.performanceMap.set(itemKey, perf)
    }
    return perf
  }

  public getChildren(itemKey:string) {
    const children = this.model.childrenSorted(itemKey)
    const childModels:InvestmentModel<Item>[] = []
  
    for (const child of children) {
      let childKey = child.key
      if (Type.isLink(child) && child.links?.length) {
        childKey = child.links[0]
      }

      const investModel = this.getModel(childKey)
      if (investModel) {
        childModels.push(investModel)
      }
    }
    logger.trace("getChildren: Found models for %d/%d children of %s", childModels.length, children.length, itemKey)
    return childModels
  }

  public hasModifiedItem(items:Item[]): boolean {
    for (const item of items) {
      if (this.modifiedItems.has(item.key)) {
        logger.debug("hasModifiedItem: Found %o", item)
        return true
      }
    }
    return false
  }

  public create() : InvestmentModelBuilder {
    logger.start("create")

    this.clear()

    // Get items that required an InvestmentModel, and dependencies between models/accounts
    const supportedItems = this.analyzer.analyzeDependencies()
  
    // Create InvestmentModel for all qualifying items
    if (supportedItems.length > 0) {
      logger.start("newModel", "Creating models for %d/%d items", supportedItems.length, this.model.size())
  
      for (const item of supportedItems) {
        this.addModel(this.newModel(item))
      }
  
      logger.finish("newModel", "Created %d models", this.modelMap.size)
    }
    this._created = true
    
    // Return the newly populated map of InvestmentModel objects
    logger.finish("create", "Created %d models", this.modelMap.size)

    return this
  }

  public build(modifiedItems:Item[]) : InvestmentModelBuilder {
    // Create if required
    if (!this._created) {
      this.create()
    }

    logger.start("build")

    this.modifiedItems.clear()
    
    // Mark all "dirty" models for rebuild
    this.markDirty(this.analyzer.getDirtyModels(modifiedItems, this.modelMap))

    // Populate set of modified items
    for (const item of modifiedItems) {
      this.modifiedItems.add(item.key)

      // Check whether a model exists - create if not
      if (this.analyzer.isSupportedItem(item)) {
        logger.debug("Supported item has been modified: item=%s, status=%s", item.name, item.status)

        if (item.status === ItemStatus.DELETED) {
          this.deleteModel(item.key)
        }
        else if (!this.modelMap.has(item.key)) {
          this.addModel(this.newModel(item))
        }
      }
    }

    // Refresh dependency graph
    this.analyzer.analyzeDependencies()

    // Run the build stages recursively from top-pevel portfolios down
    const portfolios = this.model.childrenOfType(ModelKeys.root, ModelKeys.asset.portfolio)
  
    // Call onCreate for all models
    logger.start("createModels")
    for (const item of portfolios) {
      this.onCreateModel(item)
    }
    logger.finish("createModels")
  
    // Call onBuild for all models
    const builtCount = this.builtCount
    logger.start("buildModels", "%d/%d models to be built", this.builtMap.size - builtCount, this.builtMap.size)
    for (const item of portfolios) {
      this.onBuildModel(item)
    }
    logger.finish("buildModels", "%d/%d models have been built", this.builtCount - builtCount, this.builtMap.size)
  
    // Finally call onPostBuild for all models
    logger.start("postBuildModels")
    for (const item of portfolios) {
      this.onPostBuildModel(item)
    }
    logger.finish("postBuildModels")

    // Clear modified map
    this.modifiedItems.clear()
    
    this._built = true
    logger.finish("build")

    return this
  }

  public clear() : InvestmentModelBuilder {
    this.modelMap.clear()
    this.builtMap.clear()
    this.postBuiltMap.clear()
    this.performanceMap.clear()
    this.modifiedItems.clear()
    this._created = false
    this._built = false

    return this
  }

  private newModel(item:Item) {
    switch (item?.typeKey) {
      case ModelKeys.asset.portfolio:
        return new PortfolioModel(this.model, item)
  
      case ModelKeys.asset.property:
        return new PropertyModel(this.model, item as PropertyItem)
  
      case ModelKeys.account.loan:
        return new LoanModel(this.model, item as LoanItem)
      
      case ModelKeys.account.checking:
      case ModelKeys.account.creditcard:
      case ModelKeys.account.deposit:
      case ModelKeys.account.savings:
      case ModelKeys.account.wallet:
        return new BudgetModel(this.model, item)
  
      case ModelKeys.asset.stock:
      case ModelKeys.asset.investment:
      case ModelKeys.asset.kiwisaver:
      case ModelKeys.type.valuation:
        return new ValuationModel(this.model, item as ValuationItem)
    }
  
    logger.debug("newModel: No model created for typeCode=%s, item=%s [key=%s]", this.model.getItemType(item)?.code, item?.name, item?.key)
    return undefined  
  }

  private addModel(investModel:InvestmentModel<Item> | undefined) {
    if (investModel) {
      const modelKey = investModel.item.key
      this.modelMap.set(modelKey, investModel)
      this.builtMap.set(modelKey, false)
      this.postBuiltMap.set(modelKey, false)
    }
  }

  private deleteModel(modelKey:string) {
    this.modelMap.delete(modelKey)
    this.builtMap.delete(modelKey)
    this.postBuiltMap.delete(modelKey)
    this.performanceMap.delete(modelKey)
  }

  private onCreateModel(item:Item) {
    const investModel = this.modelMap.get(item.key)
    try {
      if (investModel && !this.builtMap.get(item.key)) {
        // onCreate the parent first
        logger.trace("onCreateModel: Calling onCreate for [%s:%s]", investModel.key, investModel.name)
        investModel.onCreate()
  
        // Then recursively createModel on all child models
        for (const child of this.model.children(item.key)) {
          this.onCreateModel(child)
        }
      }
    } catch (e:any) {
      const message = logger.error("onCreateModel", "Exception building [%s:%s]", investModel?.key, investModel?.name)
      throw Error(message, { cause:e })
    }
  }
  
  private onBuildModel(item:Item) {
    const investModel = this.modelMap.get(item.key)
    try {
      if (investModel && !this.builtMap.get(item.key)) {
        // IMPORTANT - Set built flag to avoid infinite loops
        this.builtMap.set(item.key, true)
  
        // Then recursively build all child models
        for (const child of this.model.children(item.key)) {
          this.onBuildModel(child)
        }

        // Then recursively build all producer models
        for (const producer of this.analyzer.getProducerItems(item.key)) {
          this.onBuildModel(producer)
        }
  
        // Finally build this model
        logger.debug("onBuildModel: Calling onBuild for [%s:%s]", investModel.key, investModel.name)
        investModel.onBuild()
      }
    } catch (e:any) {
      const message = logger.error("onBuildModel", "Exception building [%s:%s]", investModel?.key, investModel?.name)
      throw Error(message, { cause:e })
    }
  }
  
  private onPostBuildModel(item:Item) {
    const investModel = this.modelMap.get(item.key)
    try {
      if (investModel && !this.postBuiltMap.get(item.key)) {
        // IMPORTANT - Set postBuilt flag to avoid infinite loops
        this.postBuiltMap.set(item.key, true)
  
        // Then recursively postBuildModel all child models
        for (const child of this.model.children(item.key)) {
          this.onPostBuildModel(child)
        }
        
        // The recursively postBuildModel on all producer models
        for (const producer of this.analyzer.getProducerItems(item.key)) {
          this.onPostBuildModel(producer)
        }
  
        // Finally onPostBuild this model
        logger.trace("onPostBuildModel: Calling onPostBuild for [%s:%s]", investModel.key, investModel.name)
        investModel.onPostBuild()

        // Delete any existing performance model
        this.performanceMap.delete(item.key)
      }
    } catch (e:any) {
      const message = logger.error("onPostBuildModel","Exception building [%s:%s]", investModel?.key, investModel?.name)
      throw Error(message, { cause:e })
    }
  }

  private markDirty(dirtyModels:Map<string,Item>) {
    for (const modelKey of dirtyModels.keys()) {
      this.builtMap.set(modelKey, false)
      this.postBuiltMap.set(modelKey, false)
    }
  }
}
