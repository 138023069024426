import { CashflowBuilder } from "../builder/CashflowBuilder"
import { Model } from "../model/Model"
import { Item } from "../types/Item"
import { Logger } from "../utils/Logger"
import { getUniqueId } from "../utils/Utils"
import { BudgetActualModel } from "./BudgetActualModel"

export class PortfolioModel<T extends Item> extends BudgetActualModel<T> {
  constructor(model:Model, item:T) {
    super(model, item)
    this.logger.setName("models.PortfolioModel")

    if (this.hasAssets) {
      this._filterDefault = {
        ...this._filterDefault,
        id: getUniqueId(),
        dimension: "Account",
      }
    }

    this.logger.debug("Created new PortfolioModel for %s", this.item.name) 
  }

  get start() : number {
    return super.start
  }

  get finish() : number {
    return super.finish
  }

  public onCreate() {
    this.logger.start("onCreate")
    super.onCreate()

    // Now create the budget cashflow
    this._actualCashflow = new CashflowBuilder(this.model, this.asset, this.item, false, this.start, this.finish, 0).addContext("Actual")
    this._budgetCashflow = new CashflowBuilder(this.model, this.asset, this.item, false, this.start, this.finish, 0).addContext("Budget")
    this._netCashflow    = new CashflowBuilder(this.model, this.asset, this.item, false, this.start, this.finish, 0).addContext("Forecast")

    this.logger.finish("onCreate", "%s", Logger.Range(this.start, this.finish))
  }

  public onBuild() {
    this.logger.start("onBuild")
    super.onBuild()

    // Add cashflows from child models
    for (const childModel of this.getChildren()) {
      // This handles _netCashflow
      if (childModel.cashflow) {
        this._netCashflow?.addCashflow(childModel.cashflow)
        this.logger.debug("onBuild: Added cashflow from %s", childModel.name)
      }

      // Only models that extend BudgetModelBase have actualcashflow and budgetCashflow
      const budgetModel = childModel as BudgetActualModel<T>

      if (budgetModel.actualCashflow) {
        this._actualCashflow?.addCashflow(budgetModel.actualCashflow)
        this.logger.debug("onBuild: Added actualCashflow from %s", budgetModel.name)
      }
      
      if (budgetModel.budgetCashflow) {
        this._budgetCashflow?.addCashflow(budgetModel.budgetCashflow)
        this.logger.debug("onBuild: Added budgetCashflow from %s", budgetModel.name)
      }
    }

    this._netCashflow?.build()
    this._actualCashflow?.build()
    this._budgetCashflow?.build()

    this.logger.finish("onBuild", "%s", Logger.Range(this.start, this.finish))   
  }
}
