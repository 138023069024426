import { setProperty } from "../../core/model/Model"
import { Asset } from "../../core/model/ModelKeys"
import { BankStmtFileItem, BankStmtFileItemProps } from "../../core/types/BankStmtFileItem"
import { ItemProps } from "../../core/types/Item"
import { Logger } from "../../core/utils/Logger"
import { NumberFormats } from "../../core/utils/Numbers"
import { useFormItem } from "../../hooks/context/FormContext"
import { useModel } from '../../hooks/context/ModelContext'
import { useAtomRef } from "../../hooks/useAtomRef"
import { DateEditor } from "../controls/editor/DateEditor"
import { Editable } from "../controls/editor/Editable"
import { Field, FieldSet, Form, FormBody } from "../controls/form/Form"
import { FormFooter } from "../controls/form/FormFooter"
import { FormHeader } from "../controls/form/FormHeader"
import { ShowIfAtom } from "../controls/ShowIf"
import { ItemFormSharedFields } from "./ItemFormSharedFields"

const logger = new Logger("forms.BankStmtFileItemForm")

export function BankStmtFileItemForm(props : {
  item: BankStmtFileItem
  formItemKey?: string
  open?: boolean
  noHeader?: boolean
  onClose: () => void
}) {
  const { model } = useModel()
  const { formItemKey, open, noHeader, onClose } = props

  const [ item, setItem ] = useFormItem(formItemKey ?? "bankStmtFileItem", props.item)
  const openAtom = useAtomRef(open === true)

  const account = model.getItem(item.accountKey)
  const isUnitised = Asset.isUnitised(account)

  const icon = model.getItemIcon(item) || "fal fa-line-columns"

  logger.debug("Rendering: key=%s, open=%s, item=%o, props.item=%o", item.key, open, item, props.item)

  return (
    <Form id="BankStmtFileItemForm">
      { !noHeader &&
        <FormHeader title="Bank Statement File Editor" icon={icon} onClose={onClose} openAtom={openAtom} />
      }
      <FormBody>
        <FieldSet>
          <Field className="name">
            <label>Name</label>
            <Editable item={item} property={ItemProps.name} onChange={onChange} readonly />
          </Field>
          <ShowIfAtom visible={openAtom}>
            <Field>
              <label>Code</label>
              <Editable item={item} property={ItemProps.code} onChange={onChange} readonly={false} />
            </Field>
          </ShowIfAtom>
          <Field className="description">
            <label>Description</label>
            <Editable item={item} property={ItemProps.description} onChange={onChange} readonly={false} />
          </Field>
        </FieldSet>

        <FieldSet>
          <Field>
            <label>Account Number</label>
            <Editable item={item} property={BankStmtFileItemProps.accountNumber} onChange={onChange} readonly />
          </Field>
          <Field>
            <label>Start Date</label>
            <DateEditor item={item} property={BankStmtFileItemProps.startDate} onChange={onChange} readonly/>
          </Field>
          <Field>
            <label>End Date</label>
            <DateEditor item={item} property={BankStmtFileItemProps.endDate} onChange={onChange} readonly />
          </Field>
        </FieldSet>

        <FieldSet visible={!isUnitised}>
          <Field>
            <label>Opening Balance</label>
            <Editable item={item} property={BankStmtFileItemProps.openingBalance} onChange={onChange} readonly={false}
                      numberFormat={NumberFormats.currency2} />
          </Field>
          <Field className="readonly">
            <label>Closing Balance</label>
            <Editable item={item} property={BankStmtFileItemProps.closingBalance} onChange={onChange} readonly
                      numberFormat={NumberFormats.currency2} />
          </Field>
        </FieldSet>

        <FieldSet visible={isUnitised}>
          <Field>
            <label>Opening Unit Balance</label>
            <Editable item={item} property={BankStmtFileItemProps.openingUnitBalance} onChange={onChange} readonly={false}
                      numberFormat={NumberFormats.units} />
          </Field>
          <Field>
            <label>Closing Unit Balance</label>
            <Editable item={item} property={BankStmtFileItemProps.closingUnitBalance} onChange={onChange} readonly={false}
                      numberFormat={NumberFormats.units} />
          </Field>
        </FieldSet>

        <ItemFormSharedFields item={item} onChange={onChange} readonly={false} openAtom={openAtom} />
      </FormBody>
        
      { !noHeader &&
        <FormFooter showClose showDelete showSave onClose={onClose}/>
      }
  </Form>
  )

  function onChange(item:BankStmtFileItem, property:any, value:any) {
    logger.debug("onChange: item.key=%s, property=%s, value=%s", item.key, property, value)

    const newItem = setProperty({...item}, property, value)
    setItem(newItem)
  }
}
