import { ReactNode } from "react"
import { CartesianGrid, ComposedChart, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { formatDate } from '../../core/utils/DateFormat'
import { Logger } from "../../core/utils/Logger"
import { formatAssetValue } from '../../core/utils/Numbers'
import { TimeUnits } from "../../core/utils/TimeScale"
import { getDateFormat, useChartProfile } from "./useChartProfile"

const logger = new Logger("chart.CartesianChart")

export interface CartesianChartProps {
  children: ReactNode
  data: any[]
  scrollable?: boolean
  title?: string
  height?: number | string
  width?: number | string
  aspect?: number
  syncId?: string
  xdomain?: any[]
  ydomain?: any[]
  noHeader?: boolean
  noXAxis?: boolean
  noYAxis?: boolean
  noLegend?: boolean
  noTooltip?: boolean
  onClickXAxis?: any
  onClickYAxis?: any
  formatXAxis?: (x:any) => string
  formatYAxis?: (y:any) => string
  units?: TimeUnits
  barCategoryGap?: number
}

export function CartesianChart(props:Partial<CartesianChartProps>) {
  const chartProfile = useChartProfile()
  const { size, margin } = chartProfile
  
  const { children, data, scrollable, title, syncId, ydomain } = props
  const { noXAxis, noYAxis, noLegend, noTooltip } = props
  const { onClickXAxis, onClickYAxis } = props

  const formatXAxis = props.formatXAxis || formatDateAxis
  const formatYAxis = props.formatYAxis || formatAssetValue

  if (!data || data.length === 0) {
    return <></>
  }

  const { dateFormat, start, finish, xdomain, units } = getDateFormat(data, props.units || "1Y")

  logger.debug("Rendering: title=%s, %d data points, units=%s, %s, xdomain={%s}, scrollable=%s", 
                title, data.length, units, 
                Logger.Range(start, finish),
                Logger.Range(xdomain[0], xdomain[1]),
                scrollable)

  // Render chart within horizontally scrollable container. 
  // Height is fixed, width is calculated from number of data points, or aspect ratio
  if (scrollable) {
    const aspect = props.aspect || size.aspect21x9
    const height = (props.height && typeof props.height === "number") ? props.height : 250
    const width = Math.max(data.length * Math.max(size.barSize + size.barGap, 35), height*aspect)
    return (
      <div className="chart-body scrollable">
        <ChartContent height={height} width={width} barCategoryGap={size.barCategoryGap} barGap={size.barGap}/>
      </div>
    )
  }

  // For responsive, prioritise height over aspect 
  let { height, aspect } = props
  aspect = (height) ? undefined : aspect || size.aspect21x9
  
  // Render within a responsive container
  return (
    <ResponsiveContainer className="chart-body" aspect={aspect} height={height}>
      <ChartContent />
    </ResponsiveContainer>
  )

  function ChartContent(props:any) {
    return (
      <ComposedChart {...props} data={data} margin={margin} stackOffset="sign" syncId={syncId}>
        { (!noYAxis) &&
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
        }
        { (!noXAxis) &&
          <XAxis dataKey="date" name="Date" type="number" scale="time" 
                domain={xdomain || chartProfile.xdomain}
                // interval={scrollable ? 0 : undefined}
                interval="preserveStartEnd"
                tickFormatter={formatXAxis}
                minTickGap={20}
                cursor={onClickXAxis ? "pointer" : undefined}
                onClick={onClickXAxis} />
        }
        <ReferenceLine y={0} stroke="#000000" />
        { (!noYAxis) &&
          <YAxis width={40}
                domain={ydomain || chartProfile.ydomain}
                tickFormatter={formatYAxis}
                onClick={onClickYAxis} />
        }
        { (!noTooltip) &&
          <Tooltip formatter={(value:any) => formatYAxis(value)} 
                   labelFormatter={(date:number) => formatDate(date)}
                   position={{y: 0}} />
        }
        { (!noLegend) && <Legend /> }
        { children }
      </ComposedChart>
    )
  }

  function formatDateAxis(date:number) {
    return formatDate(date, dateFormat)
  }
}

export function XAxisTick(props:any) {
  const { x, y, stroke, payload } = props
  return (
    <g transform={`translate(${x},${y})`}>
      <text stroke={stroke}>
        { payload.value.split(" ").map((value:string, index:number) => 
          <tspan textAnchor="middle" x={0} dy={index*3 + 6}>
            {value}
          </tspan>
        )}
      </text>
    </g>
  )
}
