import { useEffect } from "react"
import { ThemeCode } from "../core/user/UserProfile"
import { useCurrentUserProfile } from "./useCurrentUser"

export interface Theme {
  code: ThemeCode,
  name: string,
  icon: string
}

const themes:Theme[] = [
  { code:"auto",    name:"Auto",     icon:"fal fa-temperature-sun" },
  { code:"light",   name:"Light",    icon:"fal fa-sun-bright" },
  { code:"dark",    name:"Dark",     icon:"fas fa-moon-stars" }, 
  { code:"dim",     name:"Dim",      icon:"far fa-cloud-sun" },
  { code:"bluered", name:"Blue-Red", icon:"far fa-moon-cloud fa-sharp-duotone" },
]

export function useTheme() {
  const { userProfile, setUserProfile } = useCurrentUserProfile()

  const themeCode = userProfile?.theme ?? "auto"
  const theme = themes.find(theme => theme.code === themeCode) as Theme

  // Ensure page adapts to new theme
  useEffect(() => {
    const htmlElement = document.firstElementChild
    if (htmlElement) {
      if (themeCode === "auto") {
        htmlElement.removeAttribute("color-scheme")
      } else {
        htmlElement.setAttribute("color-scheme", themeCode)
      }
    }
  }, [themeCode])

  return { themes, theme, setTheme }

  function setTheme(themeCode:ThemeCode) {

    // console.log("setTheme: themeCode=%s, profile=%o", themeCode, userProfile)

    // Save the theme in the user profile
    if (userProfile && userProfile.theme !== themeCode) {
      setUserProfile({...userProfile, theme:themeCode })
    }
  }
}