import { ModelKeys } from "../../../core/model/ModelKeys"
import { Logger } from "../../../core/utils/Logger"
import { TableCard } from "../table/TableCard"
import { ExportDataCard } from "./ExportDataCard"
import { ImportDataCard } from "./ImportDataCard"

const logger = new Logger("settings.SettingsSection")

export function SettingsSection(props: { rootKey?:string }) {
  const rootKey = props.rootKey || ModelKeys.root

  logger.debug("Rendering: rootKey=%s", rootKey)

  return (
    <section id="SettingsSection">
      <TableCard rootKey={rootKey} />
      <ExportDataCard />
      <ImportDataCard />
    </section>
  )
}
