import { ReactNode } from "react"
import { Type } from "../../core/model/ModelKeys"
import { Item } from "../../core/types/Item"
import { Logger } from "../../core/utils/Logger"
import { clzName } from "../../core/utils/Utils"
import { useFormData } from "../../hooks/context/FormContext"
import { useModel } from "../../hooks/context/ModelContext"
import { CardHeader } from "../controls/Card"
import { ProgressBar } from "../controls/ProgressBar"
import { useCurrentPage, useWizard } from "./context/WizardContext"
import { ButtonShowJson } from "../controls/buttons/ButtonShowJson"

const logger = new Logger("wizards.WizardHeader")

export function WizardHeader({ title, subtitle, children } : { 
  title: any, 
  subtitle?: string
  children?: ReactNode
  onSave?: (items:Iterable<Item>) => void
}) {
  logger.debug("Rendering: title=%s", title)

  return (
    <>
      <CardHeader>
        <h2>{title}</h2>
        <menu>
          <ButtonShowJson/>
          <ButtonBack/>
          <ButtonNext/>
        </menu>
      </CardHeader>

      <Progress/>

      { subtitle && <p>{subtitle}</p> }
      { children }
    </>
  )
}

function ButtonBack() {
  const { wizard } = useWizard()
  const { currentPage } = useCurrentPage()

  return (
    <button onClick={() => wizard.back()} disabled={currentPage === 0}>
      <i className="far fa-arrow-left"/>
    </button>
  )
}

function ButtonNext() {
  const { wizard, numPages } = useWizard()
  const { currentPage } = useCurrentPage()

  return (
    <button onClick={() => wizard.next()} disabled={currentPage >= numPages-1}>
      <i className="far fa-arrow-right"/>
    </button>
  )
}

function Progress() {
  const { model } = useModel()
  const { numPages, pageIds } = useWizard()
  const { currentPage, setCurrentPage } = useCurrentPage()
  const { formItemMap } = useFormData()

  if (numPages === 0) {
    return <></>
  }

  return (
    <div className="wizard-progress">
      <ProgressBar count={currentPage+1} total={numPages+1} />
      <div className="wizard-steps">
        { pageIds.map((pageId,index) =>
          <Step pageId={pageId} index={index} />
        )}
      </div>
    </div>
  )

  function Step({ pageId, index } : { pageId:string, index:number }) {
    const item = formItemMap.get(pageId)
    const icon = getIcon()
    const className = clzName("step link", { "complete": index <= currentPage })

    return (
      <div className={className} title={pageId} onClick={onClick}>
        { icon ? <i className={icon} /> : index+1 }
      </div>
    )

    function onClick() {
      setCurrentPage(index)
    }

    function getIcon() {
      if (index === numPages-1) return "fal fa-save"

      let icon = model.getItemIcon(item)
      if (icon === "fal fa-question-circle") {
        if (Type.isFile(item)) {
          icon = "fal fa-file"
        } else if (Type.isFolder(item)) {
          icon = "fal fa-folder"
        } else {
          return (index <= currentPage) ? "fal fa-check" : undefined
        }
      }
      return icon
    }
  }
}
