import { atom } from "jotai"
import { InvestmentModelBuilder } from "../builder/InvestmentModelBuilder"
import { InvestmentModel } from "../invest/InvestmentModel"
import { Model } from "../model/Model"
import { Item } from "../types/Item"
import { formatDate } from '../utils/DateFormat'
import { Logger } from "../utils/Logger"
import { getAtomValue, setAtomValue } from "./AtomUtils"

const logger = new Logger("core.InvestmentModelAtoms")

interface BuilderState {
  builder: InvestmentModelBuilder | undefined
  updated: number
}

export class InvestmentModelAtoms {
  /** Atom to hold the InvestmentModelBuilder, updated whenever the underlying Model changes */
  public static builder = atom<BuilderState>({ builder:undefined, updated:0 })

  /**
   * This is a callback called by ModelService.setItems when items in the Model are updated
   * @param model 
   * @param modifiedItems 
   */
  public static async onModelUpdated(model:Model, initialLoad:boolean, modifiedItems:Item[]) {
    logger.start("onModelUpdated", "initialLoad=%s, %d modifiedItems", initialLoad, modifiedItems.length)

    // Get the builder, or create a new one if necessary
    let { builder } = getAtomValue(InvestmentModelAtoms.builder)
    if (!builder || initialLoad) {
      builder = new InvestmentModelBuilder(model)
      setAtomValue(InvestmentModelAtoms.builder, { builder:builder, updated:Date.now() })

      logger.debug("onModelUpdated: Created new builder=%o", InvestmentModelAtoms.logBuilderState())
    }

    // Build the models
    builder.build(modifiedItems)
    setAtomValue(InvestmentModelAtoms.builder, { builder:builder, updated:Date.now() })

    logger.finish("onModelUpdated", "builder=%o, %d modifiedItems", InvestmentModelAtoms.logBuilderState(), modifiedItems.length)
  }

  public static reset() {
    setAtomValue(InvestmentModelAtoms.builder, { builder:undefined, updated:0 })
  }
  
  public static logBuilderState() {
    const { builder, updated } = getAtomValue(InvestmentModelAtoms.builder)
    return { builder, updated: formatDate(updated, Logger.DefaultDateFormat) }
  }
}

export function getInvestmentModel<T extends InvestmentModel<Item>>(itemKey: string) {
  const { builder } = getInvestmentModelBuilder()
  const investmentModel = builder?.getModel(itemKey)
  
  return investmentModel as T
}

export function getInvestmentModelBuilder() {
  return getAtomValue(InvestmentModelAtoms.builder)
}
