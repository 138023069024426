import { CashflowPivotTable } from "../../../ui/cards/cashflow/CashflowPivotTable"
import { PageProvider } from "../../PageProvider"
import { Section } from "../../PageSection"

export function PivotTablePage() {
  return (
    <article>
      <h1>Pivot Tables</h1>
      <section>
        <p>
          TODO: Describe what pivot tables provide
        </p>
        <Section title="Sample Pivot Table">
          <p>Here is a live pivot table from your model</p>
          <PageProvider>
            <CashflowPivotTable/>
          </PageProvider>
        </Section>
      </section>
    </article>
  )
}