import { atom } from "jotai"
import { RankedSearchOptions, Rankings, searchAndRankItems } from "../../core/model/RankedSearch"
import { Timer, Timers } from "../../core/utils/Timers"
import { getAtomValue, setAtomValue } from "../../core/atom/AtomUtils"

export class TimersCardAtoms {
  public static sortOrder = atom("NAME")

  public static searchValue = atom("")

  public static updatedTime = atom(Date.now())

  public static filteredTimers = atom<Timer[]>(
    (get) => {
      const searchValue = get(TimersCardAtoms.searchValue)
      get(TimersCardAtoms.updatedTime)

      if (searchValue && searchValue.length >= 2) {
        const rankedItems = searchAndRankItems(Timers.list, searchValue, TimersCardAtoms.searchOptions)
        return Array.from(rankedItems.values()).map(ri => ri.item)
      }

      return Timers.list
    }
  )

  public static sortedTimers = atom<Timer[]>(
    (get) => {
      const sortOrder = get(TimersCardAtoms.sortOrder)
      const filteredTimers = get(TimersCardAtoms.filteredTimers)

      switch (sortOrder) {
        case "NAME": return Timers.sortByName(filteredTimers)
        case "NAME-DESC": return Timers.sortByNameDesc(filteredTimers)
        case "COUNT": return Timers.sortByCount(filteredTimers)
        case "COUNT-DESC": return Timers.sortByCountDesc(filteredTimers)
        case "TOTAL": return Timers.sortByTotal(filteredTimers)
        case "TOTAL-DESC": return Timers.sortByTotalDesc(filteredTimers)
        case "AVERAGE": return Timers.sortByAverage(filteredTimers)
        case "AVERAGE-DESC": return Timers.sortByAverageDesc(filteredTimers)
        case "MIN": return Timers.sortByMin(filteredTimers)
        case "MIN-DESC": return Timers.sortByMinDesc(filteredTimers)
        case "MAX": return Timers.sortByMax(filteredTimers)
        case "MAX-DESC": return Timers.sortByMaxDesc(filteredTimers)
      }
      return []
    }
  )

  public static searchOptions: RankedSearchOptions<Timer> = {
    props: [
      { prop: "name", threshold: Rankings.CONTAINS },
    ],
  }

  public static reset() {
    Timers.clear()
    setAtomValue(TimersCardAtoms.updatedTime, Date.now())
    getAtomValue(TimersCardAtoms.sortedTimers)
  }
}
