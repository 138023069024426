import { TimeUnits, formatUnits } from "../../../core/utils/TimeScale"
import { useFilterContext } from "../../../hooks/context/FilterContext"
import { FilterStack } from "../../../hooks/filter/FilterStack"

export function DurationMenu(props: { units?:TimeUnits[] }) {
  const { filterStack, duration } = useFilterContext()

  const units:TimeUnits[] = props.units ?? [ "3Y", "5Y", "10Y", "20Y", "30Y" ]

  return (
    <menu className="segment">
      { units.map(units => 
        <ButtonUnits units={units} active={units === duration} filterStack={filterStack} key={units} />
      )}
    </menu>
  )
}

function ButtonUnits({ units, active, filterStack } : { 
  units: TimeUnits, 
  active: boolean, 
  filterStack: FilterStack 
}) {
  const label = formatUnits(units)

  return (
    <button className={active ? "active" : ""} onClick={onChangeDuration} title={label}>
      <label>{units}</label>
    </button>
  )

  function onChangeDuration() {
    filterStack.push({ duration: units })
  }
}
