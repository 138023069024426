import { add, endOfYear, startOfMonth } from "date-fns"
import { getBankStmtModel } from "../atom/BankStmtModelAtoms"
import { CashflowBuilder } from "../builder/CashflowBuilder"
import { FutureValueBuilder } from "../builder/FutureValueBuilder"
import { Model } from "../model/Model"
import { ModelKeys } from "../model/ModelKeys"
import { ValuationItem } from "../types/ValuationItem"
import { Logger } from "../utils/Logger"
import { BudgetActualModel } from "./BudgetActualModel"
import { ValuationCalculator } from "./ValuationCalculator"

export class ValuationModel extends BudgetActualModel<ValuationItem> {
  private _netBuilder?: FutureValueBuilder
  private _budgetBuilder?: FutureValueBuilder
  private _valuationCalc?: ValuationCalculator
  
  constructor(model:Model, item:ValuationItem) {
    super(model, item)
    this.logger.setName("models.ValuationModel").debug("Created new ValuationModel for %s", this.item.name) 
  }

  get start() : number {
    if (!this._start) {
      this._start = this.asset.purchaseDate || this._valuationCalc?.firstDate || Date.now()
    }
    return this._start
  }

  get finish() : number {
    if (!this._finish) {
      this._finish = this.asset.sellDate || endOfYear(add(Date.now(), this._term ?? { years:30 })).getTime()
    }
    return this._finish
  }

  get currentValue() {
    const currentItem = this.getCashflowBuilder()?.findLatest()
    return currentItem?.balance
  }

  get valuationCalc() : ValuationCalculator {
    return this._valuationCalc as ValuationCalculator
  }

  get valuationItems() : ValuationItem[] {
    return this.model.childrenOfType<ValuationItem>(this.item.key, ModelKeys.type.valuation)
  }
 
  public onCreate() {
    this.logger.start("onCreate")
    super.onCreate()

    const valuationItems = this.valuationItems

    const bankStmtModel = getBankStmtModel()
    const { transactions } = bankStmtModel.getAccountTransactions(this.item)

    // Add any [unitised] transactions to the valuations
    for (const trans of transactions) {
      if (trans.startDate) {
        valuationItems.push({...trans, date: trans.startDate })
      }
    }

    this._valuationCalc = new ValuationCalculator(valuationItems, this.isUnitised)

    // Calculate the start and finish dates
    // const startDate = this.asset.purchaseDate || this._valuationCalc.firstDate || Date.now()
    // const finishDate = this.asset.sellDate || add(startDate, this.term).getTime()

    // Create the builders
    this._netBuilder = new FutureValueBuilder(this.model, this.asset, this.item,
      this.asset.purchasePrice, this.rate, this.term, this.start, this.finish)

    this._budgetBuilder = new FutureValueBuilder(this.model, this.asset, this.item,
      this.asset.purchasePrice, this.rate, this.term, this.start, this.finish)

    // Now create the budget cashflow
    this._netCashflow    = this._netBuilder?.getCashflow().addContext("Forecast")
    this._budgetCashflow = this._budgetBuilder?.getCashflow().addContext("Budget")
    this._actualCashflow = new CashflowBuilder(this.model, this.asset, this.item, false, this.start, this.finish, 0)

    this.logger.finish("onCreate", "%s", Logger.Range(this.start, this.finish))
  }

  public onBuild() {
    this.logger.start("onBuild")

    // Create transactions to add to builders
    const firstDate = startOfMonth(add(this.start, { months:1 })).getTime()
    const transactions = this.transactionItems.map((t) => (
      { ...t, startDate: t.startDate || firstDate }
    ))
    
    // Now build them
    this._actualCashflow?.addBankStatements().build()
    this._budgetBuilder?.addTransactions(transactions).build()
    this._netBuilder?.addTransactions(transactions).setValuationCalc(this.valuationCalc).build(true)

    this.logger.finish("onBuild", "%s. Added %d valuations, %d transactions, %d cashflow items", 
                        Logger.Range(this.start, this.finish),
                        this.valuationItems.length, transactions.length, this.cashflowLength)   
  }
}