import { getPersonas, PersonaList } from "../../ui/wizards/files/PersonaList"

export function PersonasPage() {
  const personas = getPersonas()

  return (
    <article id="Personas">
      <h1>User Personas</h1>
      <section>
        <p>
          User personas describe a [fictional] user cohort with similar demographics, financial circumstances, goals and aspirations. They are the basis for an initial template of portfolios, account and budgets which can then be edited and make getting started very quick and easy.
        </p>
        <p>
          The following {personas.length} personas are described below.
        </p>
        <PersonaList/>
      </section>
    </article>
  )
}
