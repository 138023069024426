import { atom } from 'jotai'
import { ModelAtoms } from '../../core/atom/ModelAtoms'
import { ModelKeys } from "../../core/model/ModelKeys"
import { RankedSearchOptions, Rankings, searchAndRankItems, sortRankedItems } from '../../core/model/RankedSearch'
import { Item, ItemProps } from '../../core/types/Item'
import { Logger } from '../../core/utils/Logger'
import { getAtomValue, setAtomValue } from '../../core/atom/AtomUtils'

const logger = new Logger("atoms.SearchAtoms")

const { CONTAINS, WORD_STARTS_WITH, MATCHES, STARTS_WITH } = Rankings

export class SearchAtoms {
  public static searchRootKey = atom(ModelKeys.root)
  public static showSearchBox = atom(false)
  public static searchVisible = atom(false)
  public static searchValue = atom("")
  public static selectedItem = atom<Item|undefined>(undefined)

  public static reset() {
    setAtomValue(SearchAtoms.searchRootKey, ModelKeys.root)
    setAtomValue(SearchAtoms.showSearchBox, false)
    setAtomValue(SearchAtoms.searchVisible, false)
    setAtomValue(SearchAtoms.searchValue, "")
    setAtomValue(SearchAtoms.selectedItem, undefined)
    getAtomValue(SearchAtoms.searchResultsMap)
  }

  public static searchItems = atom<Item[]>(
    (get) => {
      const model = get(ModelAtoms.modelAtom).model
      const rootKey = model.getItemKey(get(SearchAtoms.searchRootKey))

      let searchItems
      if (rootKey && rootKey !== ModelKeys.root) {
        searchItems = model.childrenDeep(rootKey)
      } else {
        searchItems = model.items()
      }

      logger.debug("searchItems: Found %d items for root='%s'", searchItems.length, model.getItemName(rootKey))
      return searchItems
    }
  )

  public static searchResults = atom<Item[]>(
    (get) => {
      let results:Item[] = []
      let matches = 0

      const searchItems = get(SearchAtoms.searchItems)
      const searchValue = get(SearchAtoms.searchValue)
      const searchOptions = get(SearchAtoms.searchOptions)

      if (searchValue && searchValue.length >= 2) {
        results = SearchAtoms.matchItems(searchItems, searchValue, searchOptions)
        matches = results.length
        if (matches > 1000) {
          results.length = 1000
        }

        logger.debug("searchResults: Matched %d/%d items, returning %d, searchValue='%s'", 
                      matches, searchItems.length, results.length, searchValue)
      } else {
        logger.debug("searchResults: searchValue='%s' is too short", searchValue)
      }

      return results
    }
  )

  /**
   * Return a map of search results, and all ancestors of each search hit.
   * searchHit = map.get(key) === true
   * ancestor = map.get(key) === false
   */
  public static searchResultsMap = atom(
    (get) => {
      const model = get(ModelAtoms.modelAtom).model
      const map = new Map<string,boolean>()

      const items = get(SearchAtoms.searchResults)
      for (let item of items) {
        map.set(item.key, true)

        while (item.parentKey !== ModelKeys.root) {
          item = model.getItem(item.parentKey)
          if (!item) {
            break
          }
          map.set(item.key, false)
        }
      }

      logger.debug("searchResultsMap: %d items in map", map.size)
      return map
    }
  )

  public static searchOptions = atom<RankedSearchOptions<Item>>(
    (get) => {      
      const model = get(ModelAtoms.modelAtom).model
      return { 
        props: [
          { prop: ItemProps.key,          threshold: MATCHES },
          { prop: ItemProps.code,         threshold: MATCHES },
          { prop: ItemProps.name,         threshold: WORD_STARTS_WITH },
          { prop: ItemProps.description,  threshold: CONTAINS },
          { prop: ItemProps.sortOrder,    threshold: CONTAINS },
          { prop: (item:any) => model.getItemName(item.categoryKey), threshold: STARTS_WITH },
          { prop: (item:any) => model.getItemName(item.accountKey), threshold: STARTS_WITH },
          { prop: (item:any) => model.getItemName(item.typeKey), threshold: STARTS_WITH },
          // { prop: (item:any) => model.getItemName(item.ruleKey), threshold: STARTS_WITH },
          { prop: "amount" as any,       threshold: MATCHES },
          { prop: "value" as any,        threshold: MATCHES },
        ], 
      }
    }
  )

  public static matchItems<T extends Item>(
    searchItems: T[], 
    searchValue: string, 
    searchOptions = SearchAtoms.defaultSearchOptions) : T[]
  {
    let resultItems = searchItems
  
    if (searchValue && searchValue.length) {
      const rankedItems = searchAndRankItems(searchItems, searchValue, searchOptions)
      resultItems = sortRankedItems(rankedItems).map(ri => ri.item)
    }
    
    return resultItems
  }

  public static defaultSearchOptions:RankedSearchOptions<Item> = { 
    props: [
      { prop: ItemProps.name,        threshold: CONTAINS },
      { prop: ItemProps.description, threshold: CONTAINS },
      { prop: ItemProps.code,        threshold: STARTS_WITH },
    ], 
  }
}
