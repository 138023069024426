import { RouteItem } from "../ui/controls/RouteUtils"
import { ArchitecturePage } from "./dev/ArchitecturePage"
import { DeveloperGuides } from "./dev/DeveloperGuides"
import { ConceptsPage } from "./user/ConceptsPage"
import { AccountPage } from "./user/features/AccountPage"
import { BankStmtPage } from "./user/features/BankStmtPage"
import { CashflowsPage } from "./user/features/CashflowPage"
import { ChartPage } from "./user/features/ChartPage"
import { PivotTablePage } from "./user/features/PivotTablePage"
import { PortfolioPage } from "./user/features/PortfolioPage"
import { FeaturesPage } from "./user/FeaturesPage"
import { GettingStartedPage } from "./user/GettingStartedPage"
import { PersonasPage } from "./user/PersonasPage"
import { UserGuides } from "./user/UserGuides"
import { WelcomePage } from "./WelcomePage"

/**
 * Define documentation sub-routes. Note that the use of { index:true } is required
 * by Outlet in order to render nested children correctly.
 */
export const documentationRoutes:RouteItem[] = [
  { path: "*",    name:"Introduction", Component:WelcomePage },
  { path: "user", name:"User Guides",
    children: [
      { index: true, Component:UserGuides },
      { path: "start",    name:"Getting Started",  Component:GettingStartedPage },
      { path: "personas", name:"Personas",         Component:PersonasPage },
      { path: "concepts", name:"Concepts",         Component:ConceptsPage },
      { path: "features", name:"Features", 
        children: [
          { index:true, Component:FeaturesPage },
          { path:"account",     name:"Accounts",        Component:AccountPage },
          { path:"portfolio",   name:"Portfolio",       Component:PortfolioPage },
          { path:"cashflow",    name:"Cashflow",        Component:CashflowsPage },
          { path:"pivot-table", name:"Pivot Table",     Component:PivotTablePage },
          { path:"bank-stmt",   name:"Bank Statements", Component:BankStmtPage },
          { path:"chart",       name:"Charts",          Component:ChartPage },
        ]
      }
    ]
  },
  { path: "dev", name:"Developer Guides",
    children: [
      { index: true, Component:DeveloperGuides },
      { path: "architecture", name:"Architecture", Component:ArchitecturePage }
    ]
  },
]
