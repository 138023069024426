import { useAtom } from "jotai"
import { ReactNode, createContext, useContext, useState } from "react"
import { Logger } from "../../../core/utils/Logger"
import { WizardPager } from "../WizardPager"
import { WizardState } from "./WizardState"

const logger = new Logger("context.WizardContext")

const WizardContext = createContext<WizardState>(new WizardState("", [], 0, undefined))

export function useWizard() {
  return useContext(WizardContext)
}

export function useCurrentPage() {
  const wizard = useWizard()
  const [ currentPage, setCurrentPage ] = useAtom(wizard.currentPageAtom)

  return { 
    currentPage,
    setCurrentPage: (page:number) => setCurrentPage(page),
  }
}

export function WizardProvider({ name, pageIds, currentPage, primaryItemProp, children } : { 
  name: string, 
  pageIds: string[], 
  currentPage?: number,
  primaryItemProp?: string
  children:ReactNode 
}) {
  const [state, setState] = useState(initWizardState)

  logger.debug("Providing state=%o", state)

  return (
    <WizardContext.Provider value={state}>
      <WizardPager>
        {children}
      </WizardPager>
    </WizardContext.Provider>
  )

  function initWizardState() {
    const state = new WizardState(name, pageIds, currentPage ?? 0, primaryItemProp, setWizardState)
    logger.debug("initWizardState: state=%o", state)
    return state
  }

  function setWizardState(state:WizardState) {
    logger.debug("setWizardState: state=%o", state)
    setState(state)
  }
}
