import { add, addMonths, addQuarters, addYears, endOfYear, startOfDay, startOfMonth } from "date-fns"
import { getBankStmtModel } from "../atom/BankStmtModelAtoms"
import { CashflowBuilder } from "../builder/CashflowBuilder"
import { Model } from "../model/Model"
import { ModelKeys, Transaction } from "../model/ModelKeys"
import { CashflowItem } from "../types/CashflowItem"
import { Item } from "../types/Item"
import { Logger } from "../utils/Logger"
import { BudgetActualModel } from "./BudgetActualModel"

export class BudgetModel extends BudgetActualModel<Item> {
  private _actualTotals?: Map<string,CategoryTotal>
  private _budgetTotals?: Map<string,CategoryTotal>
  private _netTotals?: Map<string,CategoryTotal>

  constructor(model:Model, item:Item) {
    super(model, item)
    this.logger.setName("models.BudgetModel").debug("Created new BudgetModel for %s", this.item.name) 
  }

  get start() : number {
    if (!this._start) {
      this._start = this.getEarliestStartDate()
    }
    return this._start
  }

  get finish() : number {
    if (!this._finish) {
      this._finish = this.asset.sellDate || endOfYear(add(Date.now(), this._term ?? { years:30 })).getTime()
    }
    return this._finish
  }

  get netTotals() {
    if (!this._netTotals) {
      this._netTotals = this.calcTotals(this._netCashflow)
    }
    return this._netTotals
  }

  get actualTotals() {
    if (!this._actualTotals) {
      this._actualTotals = this.calcTotals(this._actualCashflow)
    }
    return this._actualTotals
  }

  get budgetTotals() {
    if (!this._budgetTotals) {
      this._budgetTotals = this.calcTotals(this._budgetCashflow)
    }
    return this._budgetTotals
  }

  public onBuild() {
    this.logger.start("onBuild")
    
    // Build the cashflows - the net cashflow can only
    const budgetDirty = this.buildBudgetCashflow()
    const actualDirty = this.buildActualCashflow()
    if (budgetDirty || actualDirty || !this._netCashflow) {
      this.logger.debug("Calling buildNetCashflow, budgetDirty=%s, actualDirty=%s", budgetDirty, actualDirty)
      this._netTotals = undefined
      this.buildNetCashflow()
    }

    this.logger.finish("onBuild", "%s. Added %d items to netCashflow, %d to actualCashflow, %d to budgetCashflow", 
                    Logger.Range(this.start, this.finish),
                    this._netCashflow?.length, this._actualCashflow?.length, this._budgetCashflow?.length)   
  }

  private buildActualCashflow() : boolean {
    const { transactions } = getBankStmtModel().getAccountTransactions(this.item)
    if (!this._actualCashflow || this.builder.hasModifiedItem(transactions)) {
      this._actualTotals = undefined
      this._actualCashflow = new CashflowBuilder(this.model, this.asset, this.item, false, this.start, this.finish, 0).addContext("Actual")
      this._actualCashflow?.addBankStatements().build()
      return true
    }
    return false
  }

  private buildBudgetCashflow() : boolean {
    // Get transactions to add to the cashflow
    const transactionItems = this.transactionItems
    if (!this._budgetCashflow || this.builder.hasModifiedItem(transactionItems)) {
      // Get transactions to add to the cashflow, default the startDate if required
      const firstDate = this.start
      const transactions = transactionItems.map((transaction) => (
        { ...transaction, startDate: transaction.startDate || firstDate }
      ))

      this._budgetTotals = undefined
      this._budgetCashflow = new CashflowBuilder(this.model, this.asset, this.item, false, this.start, this.finish, this.asset.rate).addContext("Budget")
      this._budgetCashflow.addTransactions(transactions).build()

      this.logger.debug("buildBudgetCashflow: Built %d items from %d transactions", this._budgetCashflow.length, transactions.length)
      return true
    }

    return false
  }

  private buildNetCashflow() {
    this._netCashflow = new CashflowBuilder(this.model, this.asset, this.item, false, this.start, this.finish, 0).addContext("Forecast")
    this._netCashflow.addCashflow(this._budgetCashflow).addBankStatements().build()
    return true
  }

  protected getEarliestStartDate() : number {
    let now = startOfDay(Date.now()).getTime()
    let start = Number.MAX_SAFE_INTEGER
    
    for (const trans of this.transactionItems) {
      start = Math.min(trans.startDate || now, start)
    }
    if (start === Number.MAX_SAFE_INTEGER) {
      start = 0
    }
    return start
  }

  private calcTotals(cashflow:CashflowBuilder | undefined) {
    const totalsMap = new Map<string,CategoryTotal>()
    if (!cashflow) {
      return totalsMap
    }

    const start = startOfMonth(Date.now()).getTime()
    const end = addYears(start, 1).getTime()
    const eoq = addQuarters(start, 1).getTime()
    const eom = addMonths(start, 1).getTime()
    
    this.logger.start("calcTotals", "Date range={%s}", Logger.Range(start, end))

    const cashflowItems = cashflow.getCashflowItems()
    for (const item of cashflowItems) {
      if (item.date >= start && item.date <= end && 
        !Transaction.isBalance(item) && !Transaction.isInterestRate(item)) {

        this.calcTotal(item, item.parentKey as string, totalsMap, eoq, eom)

        let categoryKey = item.categoryKey as string
        while (categoryKey && categoryKey !== ModelKeys.category.root) {
          this.calcTotal(item, categoryKey, totalsMap, eoq, eom)
          categoryKey = this.model.getItem(categoryKey).parentKey
        }

        // Grand total
        this.calcTotal(item, ModelKeys.category.root, totalsMap, eoq, eom)
      }
    }
    
    this.logger.finish("calcTotals", "%d totals from %d items", totalsMap.size, cashflowItems.length)

    return totalsMap
  }

  /** Accumulate item total */
  private calcTotal(item:CashflowItem, key:string, totalsMap:Map<string,CategoryTotal>, eoq:number, eom:number) {
    if (item.amount) {
      let total = totalsMap.get(key)
      if (!total) {
        total = { weeks2:0, months1:0, months3:0, years1:0 }
        totalsMap.set(key, total)
      }
  
      // Accumlate totals
      total.years1 += item.amount
      if (item.date <= eoq) {
        total.months3 += item.amount
      }
      if (item.date <= eom) {
        total.months1 += item.amount
      }
    }
  }
}

export interface CategoryTotal {
  weeks2:  number
  months1: number
  months3: number
  years1:  number
}