import { createContext, ReactNode, useContext, useState } from "react"
import { Item } from "../../core/types/Item"
import { TransactionItem } from "../../core/types/TransactionItem"
import { Logger } from "../../core/utils/Logger"

const logger = new Logger("context.FormContext")

export interface FormData {
  name?: string
}

interface FormState<T extends FormData> {
  formData: T
  formItemMap:  Map<string,Item>
  getFormItems: () => Item[]
  setFormItem:  (key:string|undefined, item:Item|undefined) => void
}

const FormContext = createContext({} as FormState<FormData>)

export function useFormData<T extends FormData>() {
  return useContext(FormContext) as FormState<T>
}

export function useFormItem<T extends Item>(key:string, initValue?: T | (() => T)) {
  const { formItemMap, setFormItem } = useFormData()
  
  let item = formItemMap.get(key)
  if (!item && initValue) {
    item = (typeof initValue === "function") ? initValue() : initValue

    // Note that we set the initValue directly to protect against infinite loops
    formItemMap.set(key, item)
  }

  let response: readonly [ T, (item:T|undefined) => void ] = [ item as T, setItem ]
  return response

  function setItem(item:T|undefined) {
    setFormItem(key, item)
  }
}

export function FormDataProvider({ children } : { children:ReactNode }) {
  const [state, setState] = useState(newState)

  return (
    <FormContext.Provider value={state}>
      { children }
    </FormContext.Provider>
  )

  function newState() : FormState<FormData> {
    return { 
      formData: {},
      formItemMap: new Map<string,Item>(),
      getFormItems, 
      setFormItem
    }
  }

  function getFormItems() {
    return Array.from(state.formItemMap.values())
  }

  /**
   * Store an Item in state.formItemMap. If {@link item} is undefined, remove any existing
   * entry for {@link formItemKey}, along with any other items that are related to the
   * one being deleted i.e. children or related transactions.
   * 
   * @param formItemKey The key of an entry in state.formItemMap
   * @param item The {@link Item} to store in state.formItemMap
   */
  function setFormItem(formItemKey:string|undefined, item:Item|undefined) {
    if (formItemKey) {
      if (item !== undefined) {
        state.formItemMap.set(formItemKey, item)
      } else {
        // A set of formItemKey's for removal from formItemMap
        const deleteKeys = new Set(formItemKey)

        // Mark all related formItems for deletion
        const formItem = state.formItemMap.get(formItemKey)
        if (formItem) {
          getRelatedFormItemKeys(formItem.key, deleteKeys)
        }

        // Now remove items
        for (const key of deleteKeys) {
          state.formItemMap.delete(key)
        }
      }

      // Update the form state
      setState({...state})
    }
  }

  /**
   * For each Item in formItemMap, check whether Item.key === itemKey. If so then add the
   * formItemKey to the relatedKeys set.
   * @param itemKey The 
   * @param relatedFormItemKeys 
   */
  function getRelatedFormItemKeys(itemKey:string, relatedFormItemKeys:Set<string>, depth=0) {
    // logger.start("getRelatedFormItemKeys", "Checking itemKey=%s, depth=%d", itemKey, depth)

    for (const [formItemKey, formItem] of state.formItemMap.entries()) {
      // const { key, parentKey, relatedTransactionKey } = formItem as any
      // logger.debug("Checking itemKey=%s, formItemKey=%s, formItem=%o", 
      //             itemKey, formItemKey, { key, parentKey, relatedTransactionKey })

      let relatedItemKey = formItem.parentKey as string|undefined
      if (relatedItemKey !== itemKey) {
        relatedItemKey = (formItem as TransactionItem)?.relatedTransactionKey
      }

      if (relatedItemKey === itemKey && !relatedFormItemKeys.has(formItemKey)) {
        logger.debug("Marking %s for deletion, relatedItemKey=%s", formItemKey, relatedItemKey)

        relatedFormItemKeys.add(formItemKey)
        if (depth < 10) {
          getRelatedFormItemKeys(formItem.key, relatedFormItemKeys, ++depth)
        }
      }
    }

    // logger.finish("getRelatedFormItemKeys", "Checking itemKey=%s, depth=%d, relatedFormKeys=%o", itemKey, depth, Array.from(relatedFormItemKeys))
  }
}
