import { ModelKeys } from "../../../core/model/ModelKeys"
import { Logger } from "../../../core/utils/Logger"
import { useBankStmtModel } from '../../../hooks/bankstmt/useBankStmtModel'
import { useModel } from "../../../hooks/context/ModelContext"
import { Card, CardHeader, CardSection } from "../../controls/Card"
import { ApplyMenu, ApplyProgressBar } from "./BankStmtCard"
import { BankStmtTable } from "./BankStmtTable"

const logger = new Logger("cards.BankStmtListCard")

export function BankStmtListCard(props:any) {
  const { model } = useModel()
  const { transactions, categorised, uncategorised } = useBankStmtModel().transactions

  logger.trace("Rendering")

  return (
    <Card id="BankStmtListCard">
      <CardHeader>
        <h2>
          <i className={ model.getItemIcon(ModelKeys.bank.stmts) }/>
          Bank Statements
        </h2>
        <ApplyMenu transactions={transactions} categorised={categorised} uncategorised={uncategorised} />
        <ApplyProgressBar total={transactions.length} count={categorised} />
      </CardHeader>
      <CardSection>
        <BankStmtTable />
      </CardSection>
    </Card>
  )
}
