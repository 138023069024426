import { Dimension, Filter } from "../../../core/filter/Filter"
import { InvestmentModel } from "../../../core/invest/InvestmentModel"
import { Item } from "../../../core/types/Item"
import { formatDate } from "../../../core/utils/DateFormat"
import { Logger } from "../../../core/utils/Logger"
import { getYearSpan } from "../../../core/utils/TimeScale"
import { useInvestmentModel } from "../../../hooks/context/InvestmentModelContext"
import { usePivotTables } from "../../../hooks/pivot/usePivotTables"
import { Card, CardHeader, CardSection } from "../../controls/Card"
import { CardLink } from "../../views/ViewLinks"
import { PortfolioSummary } from "./PortfolioSummary"

export const logger = new Logger("cards.DashboardCard")

export function DashboardCard(props:any) {
  const { item, name } = useInvestmentModel()
  const { filter, pivotTable } = usePivotTables()

  if (pivotTable.allRows.length === 0) {
    return <></>
  }

  logger.setContext(name).debug("Rendering with filter=%s", filter.id)

  return (
    <Card id="DashboardCard" open>
      <CardHeader className="sticky">
        <h2><CardLink item={item}/></h2>
        <Period />
      </CardHeader>
      <CardSection>
        <PortfolioSummary />
      </CardSection>
    </Card>
  )
}

function Period() {
  return (
    <button disabled>
      { formatDate(Date.now()) }
    </button>
  )
}

export function getDashboardScale(investmentModel:InvestmentModel<Item>) : Partial<Filter> {
  let dimension:Dimension = "Account"
  let scale = {...investmentModel.filterDefault.scale }

  if (investmentModel.hasProperty) {
    scale = getYearSpan(Date.now(), 10, investmentModel.start)
    scale.units = "1M"
  } else if (investmentModel.hasAssets) {
    scale = getYearSpan(Date.now(), 5, investmentModel.start)
    scale.units = "1M"
  } else {
    scale = getYearSpan(Date.now(), 2, investmentModel.start)
  }

  // logger.setContext(investmentModel.name)
  //       .debug("getDashboardScale: scale={%s}", Logger.Scale(scale))

  return { scale, dimension }
}
