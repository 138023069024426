import { useState } from "react"
import { BudgetModel } from "../../../core/invest/BudgetModel"
import { ModelKeys, Transaction } from "../../../core/model/ModelKeys"
import { Item } from '../../../core/types/Item'
import { TransactionItem } from "../../../core/types/TransactionItem"
import { formatDate, formatDurationSafe } from '../../../core/utils/DateFormat'
import { Logger } from "../../../core/utils/Logger"
import { NumberFormats, formatNumber } from '../../../core/utils/Numbers'
import { clzName } from "../../../core/utils/Utils"
import { useInvestmentModel } from "../../../hooks/context/InvestmentModelContext"
import { useModel } from "../../../hooks/context/ModelContext"
import { useViewContext } from "../../../hooks/context/ViewContext"
import { useTransferAccount } from "../../../hooks/transfer/useTransferAccount"
import { useCurrencyFormat } from "../../../hooks/useCurrencyFormat"
import { useReadonly } from "../../../hooks/useReadonly"
import { ButtonDelete } from "../../controls/buttons/ButtonDelete"
import { setDialogItem } from "../../controls/dialog/ItemDialog"
import { DateRange } from "../../controls/editor/DateRange"

const logger = new Logger("cards.TransactionItemsTree")

const iconWidth = 1.5

export function TransactionItemsTree(props:any) {
  const { model, name } = useInvestmentModel<BudgetModel>()
  const { readonly } = useReadonly()

  const rootItem = model.getItem(ModelKeys.category.root)
  
  logger.setContext(name).debug("Rendering: rootKey=%s", rootItem.key)

  return (
    <table id="TransactionItemsTree" className={clzName("table sticky-headers", { readonly:readonly })}>
      <CategorySection category={rootItem} />
    </table>
  )
}

function CategorySection({ category } : { category:Item }) {
  const { model } = useModel()
  const categoryTotal = useTotals(category)
  const categories = model.childrenSorted(category.key)

  logger.debug("CategorySection: Rendering category=%s with %d children", category?.name, categories.length)

  return (
    <>
      <thead>
        <tr>
          <th className="name">
            { model.getItemName(category.key) }
          </th>
          <th className="startDate">Start Date</th> 
          <th className="endDate">End Date</th> 
          <th className="repeat">Repeat</th>
          <th className="amount">Amount</th>
          <th className="menu"></th>

          <th className="row-totals1">1M</th>
          <th className="row-totals2">3M</th>
          <th className="row-totals3">1Y</th>
        </tr>
      </thead>
      <tbody>
        { categories.map(category => 
          <CategoryRow category={category} level={0} key={category.key} />
        )}
      </tbody>
      <tfoot className="totals">
        <tr>
          <td className="name">Totals</td>
          <td className="startDate"/> 
          <td className="endDate"/> 
          <td className="repeat"/>
          <td className="amount"/>
          <td className="menu"/>

          <TableCell currency={categoryTotal?.months1} className="row-totals1" />
          <TableCell currency={categoryTotal?.months3} className="row-totals2" />
          <TableCell currency={categoryTotal?.years1}  className="row-totals3" />
        </tr>
      </tfoot>
    </>
  )
}

function CategoryRow({ category, level } : { category:Item, level:number }) {
  const { model, item, transactionItems } = useInvestmentModel<BudgetModel>()
  const { viewState } = useViewContext()
  const [ expanded, setExpanded ] = useState(false)
  const [ expandAll, setExpandAll ] = useState(false)

  const isOpenTree = viewState.isOpenTree(category.key)
  const categoryTotal = useTotals(category)
  const categoryIcon = model.getItemIcon(category)

  const styleIndent = { paddingLeft: iconWidth*level + "rem" }

  if (category === undefined || !viewState.isVisible(category.key)) {
    return <></>
  }
  // if (!categoryTotal?.years1) {
  //   return <></>
  // }

  const transactions = transactionItems.filter(trans => trans.categoryKey === category.key)
                                       .sort(sortCompare)

  const hasCategories = model.hasChildren(category?.key)
  const hasTransactions = transactions.length > 0
                                     
  // Don't show category total when only one transaction, but do show if none
  let showCategoryTotals=true, showTransactions=expanded
  // if (transactions.length === 0) {
  //   showCategoryTotals = true
  //   showTransactions = false
  // } else if (transactions.length === 1) {
  //   showCategoryTotals = false
  //   showTransactions = true
  // } else {
  //   showCategoryTotals = true
  //   showTransactions = expanded
  // }

  logger.debug("CategoryRow: Rendering category=%s, %d transactions", category.name, transactions.length)

  const className = clzName("name", (hasCategories || hasTransactions ? "link" : "disabled"))

  return (
    <>
      { showCategoryTotals &&
        <tr>
          <th className={className} onClick={onExpand} style={styleIndent} title={model.getItemDescription(category.key)}>
            <i className={categoryIcon} />
            <span>{ model.getItemName(category) }</span>

            { hasCategories &&
              <button onClick={onExpandDeep}>
                <i className={expandAll ? "far fa-chevrons-up" : "far fa-chevrons-down"} />
              </button>
            }
            {!hasCategories &&
              <button onClick={onCreate} title={"Add transaction to " + model.getItemName(category)}>
                <i className="far fa-plus" />
              </button>
            }
          </th>

          <td className="startDate"/> 
          <td className="endDate"/> 
          <td className="repeat"/>
          <td className="amount"/>
          
          <td className="menu">
          </td>

          <TableCell currency={categoryTotal?.months1} className="row-totals1" />
          <TableCell currency={categoryTotal?.months3} className="row-totals2" />
          <TableCell currency={categoryTotal?.years1}  className="row-totals3" />
        </tr>
      }

      { showTransactions && transactions.map(transaction =>
        <TransactionRow category={category} 
                        transaction={transaction}
                        level={level + 1 /*(transactions.length > 1 ? 1 : 0)*/}
                        key={transaction.key} />
      )}

      { isOpenTree && model.childrenSorted(category.key).map(child =>
        <CategoryRow category={child} level={level+1} key={child.key} />
      )}
    </>
  )

  function onExpand(e:any) {
    e.stopPropagation()
    viewState.toggleOpenTree(category.key)
    setExpanded(!expanded)
  }

  function onExpandDeep(e:any) {
    e.stopPropagation()
    viewState.setOpenLevel(model, category.key, (expandAll ? 0 : 3), expandAll)
    viewState.setOpenTree(category.key, !expandAll)
    setExpandAll(!expandAll)
  }

  function onCreate() {
    logger.debug("onCreate: parent=%s, category=%s", item.name, category.name)

    // Create a new transaction according to the specified category
    const newItem = model.newItem<TransactionItem>(item.key, category.typeKey)
    newItem.categoryKey = category.key

    // Open the transaction for editing
    setDialogItem(newItem, true)
  }
}

function TransactionRow( { transaction, category, level } : { 
  category: Item,
  transaction: TransactionItem,
  level: number
}) {
  const { model } = useInvestmentModel<BudgetModel>()
  const { transferDescription } = useTransferAccount(transaction)
  const currencyFormat = NumberFormats.number2
  const transactionTotal = useTotals(transaction)
  
  const isInterestRate = Transaction.isInterestRate(transaction)

  const styleIndent = { paddingLeft: iconWidth*level + "rem" }

  return (
    <tr className="link" onClick={() => setDialogItem(transaction, true)}>
      <th className="name" style={styleIndent} title={model.getItemDescription(transaction.key)}>
        <i className={model.getItemIcon(transaction)} />
        <span>{ transferDescription ?? model.getItemName(transaction) }</span>
            
        <div className="date-range">
          { transaction.frequency &&
            <span>Every { formatDurationSafe(transaction.frequency) } from </span>
          }
          <DateRange start={transaction.startDate} end={transaction.finishDate} />
        </div>
      </th>
      <td className="startDate">
        { formatDate(transaction.startDate) }
      </td>
      <td className="endDate">
        { formatDate(transaction.finishDate) }
      </td>
      <td className="repeat">
        { transaction.frequency ? formatDurationSafe(transaction.frequency) : "" }
      </td>
      <td className="amount">
        { formatNumber(transaction.value, isInterestRate ? NumberFormats.percent2 : currencyFormat)}
      </td>

      <td className="menu" onClick={(e:any) => e.stopPropagation()}>
        <ButtonDelete item={transaction} />
      </td>

      <TableCell currency={transactionTotal?.months1} className="row-totals1" />
      <TableCell currency={transactionTotal?.months3} className="row-totals2" />
      <TableCell currency={transactionTotal?.years1}  className="row-totals3" />
    </tr>
  )
}

function TableCell({currency, className }: { currency:number|undefined, className:string }) {
  const currencyFormat = useCurrencyFormat()

  if (currency && currency < 0) {
    className += " negative"
  }

  return (
    <td className={className}>
      { formatNumber(currency, currencyFormat) }
    </td>
  )
}

function useTotals(item:Item | undefined) {
  const { budgetTotals, netTotals } = useInvestmentModel<BudgetModel>()
  const totalsMap = budgetTotals ?? netTotals

  return item ? totalsMap?.get(item.key) : undefined
}

function sortCompare(t1:TransactionItem, t2:TransactionItem) {
  let result = (t1.startDate ?? 0) - (t2.startDate ?? 0)
  if (result === 0) {
    result = (t1.finishDate ?? 0) - (t2.finishDate ?? 0)
  }
  if (result === 0) {
    result = t1.name.localeCompare(t2.name)
  }
  return result
}
