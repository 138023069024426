import { useNavigate } from "react-router-dom"
import { saveModifiedItems } from "../../core/service/ModelAction"
import { Logger } from "../../core/utils/Logger"
import { useFormData, useFormItem } from "../../hooks/context/FormContext"
import { CardFooter } from "../controls/Card"
import { useCurrentPage, useWizard } from "./context/WizardContext"
import { WizardSaveFn } from "./WizardSavePage"

const logger = new Logger("wizards.WizardFooter")

export function WizardFooter({ showClose, showSave, onSave } : { 
  showSave?: boolean
  showClose?: boolean
  onSave?: WizardSaveFn
}) {
  logger.debug("Rendering:")

  return (
    <>
      <CardFooter>
        <menu>
          { showClose && <ButtonCancel /> }
        </menu>
        <menu>
          <ButtonBack/>
          {!showSave && <ButtonNext/> }
          { showSave && <ButtonSave onSave={onSave} /> }
        </menu>
      </CardFooter>
    </>
  )
}

function ButtonBack() {
  const { wizard } = useWizard()
  const { currentPage } = useCurrentPage()

  return (
    <button onClick={() => wizard.back()} disabled={currentPage === 0}>
      <i className="far fa-arrow-left"/>
      Back
    </button>
  )
}

function ButtonNext() {
  const { wizard } = useWizard()
  return (
    <button onClick={() => wizard.next()}>
      Next
      <i className="far fa-arrow-right"/>
    </button>
  )
}

function ButtonCancel() {
  const navigate = useNavigate()
  return (
    <button className="cancel" onClick={() => navigate("/wizard")}>
      Close
    </button>
  )
}

function ButtonSave({ onSave } : { onSave?:WizardSaveFn }) {
  const { primaryItemProp } = useWizard()
  const { formItemMap } = useFormData()
  const [ primaryItem ] = useFormItem(primaryItemProp)
  const navigate = useNavigate()

  return (
    <button className="accept" onClick={onClick}>
      Save
    </button>
  )

  function onClick() {
    if (onSave) {
      onSave()
    } else {
      saveModifiedItems(formItemMap.values())
      navigate(`/card/${primaryItem.key}`)
    }
  }
}
