import { atom, useAtom } from "jotai"
import { useEffect } from "react"
import { fetchVersion } from "../core/functions/FirebaseFunctions"
import { AppInfo, VersionInfo } from "../VersionInfo"

const functionsInfoAtom = atom<VersionInfo>()

export function useVersionInfo() {
  const [ FunctionsInfo, setFunctionsInfo ] = useAtom(functionsInfoAtom)

  useEffect(() => {
    fetchVersion().then(functionsInfo => setFunctionsInfo(functionsInfo))
  },[setFunctionsInfo])

  return { AppInfo, FunctionsInfo }
}