import numbro from "numbro"

export type Format = numbro.Format | string

export const NumberFormats = {
  integer:   { output: "number", average: false, thousandSeparated: true, mantissa: 0 } as Format,
  number1:   { output: "number", thousandSeparated: true, mantissa: 1, optionalMantissa: true } as Format,
  number2:   { output: "number", thousandSeparated: true, mantissa: 2, optionalMantissa: false } as Format,
  currency2: { output: "currency", thousandSeparated: true, mantissa: 2, optionalMantissa: false } as Format,
  currency0: { output: "currency", thousandSeparated: true, mantissa: 0, optionalMantissa: false } as Format,
  percent0:  { output: "percent", mantissa: 0 } as Format,
  percent1:  { output: "percent", mantissa: 1, optionalMantissa: true } as Format,
  percent2:  { output: "percent", mantissa: 2 } as Format,
  percentx:  { output: "percent", mantissa: 0, thousandSeparated: true, average: true } as Format,
  average0:  { average: true, mantissa: 0, optionalMantissa: true } as Format,
  average1:  { average: true, mantissa: 1, optionalMantissa: true } as Format,
  units:     { output: "number", average: false, thousandSeparated: true, mantissa: 4 } as Format,
}

export function parseNumber(valueStr: string | undefined): number | undefined {
  if (valueStr && valueStr.trim().length > 0) {
    return numbro.unformat(valueStr)
  }
  return undefined
}

export function formatNumber(value: number | undefined, format:Format = NumberFormats.average1) {
  if (value === undefined || isNaN(value as any)) {
    return ""
  }
  return numbro(value).format(format)
}

export function formatCurrency(value: number | undefined, format:Format = NumberFormats.currency2) {
  if (value === undefined || isNaN(value as any)) {
    return ""
  }
  if (isZero(value)) {
    return ""
  }
  return numbro(value).format(format)
}

export function formatPercent(value: number | undefined, format:Format = NumberFormats.percent2) {
  if (value === undefined || isNaN(value as any) || Math.abs(value) < 0.0001) {
    return ""
  }
  return numbro(value).format(format)
}

export function formatAssetValue(amount: number | undefined) {
  const value = Math.abs(amount || 0)
  const mantissa = (value >= 1000000 ? 1 : (value < 100 ? 2 : 0))
  const format:Format = { output: "currency", average: true, mantissa: mantissa, optionalMantissa: true }

  return formatCurrency(amount, format)
}

export function round(value: number, dps: number = 2): number {
  const x = Math.pow(10, dps)
  return Math.round(value * x) / x
}

export function floor(value: number, dps: number = 2): number {
  const x = Math.pow(10, dps)
  return Math.floor(value * x) / x
}

export function ifZero(value:number|undefined) : number {
  return isZero(value) ? 0 : (value ?? 0)
}

export function isZero(value:number|undefined) : boolean {
  return (Math.abs(value ?? 0) < 0.005)
}

export function isNegative(value:number|undefined) : boolean {
  return ifZero(value) < 0
}
