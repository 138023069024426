import { atom, useAtom, useAtomValue } from 'jotai'
import { Logger } from "../../../../core/utils/Logger"
import { formatNumber, NumberFormats } from '../../../../core/utils/Numbers'
import { Timer } from '../../../../core/utils/Timers'
import { Card, CardHeader, CardSection } from '../../../controls/Card'
import { ButtonOpen } from '../../../controls/buttons/ButtonOpen'
import { SearchBox } from '../../../search/SearchBox'
import { TimersCardAtoms } from '../../../../hooks/atoms/TimersCardAtoms'

const logger = new Logger("cards.TimersCard")

const openAtom = atom(false)

export function TimersCard(props:any) {
  const [isOpen, setOpen] = useAtom(openAtom)

  logger.trace("Rendering:")

  return (
    <Card id="TimersCard" open={isOpen}>
      <CardHeader>
        <i className="fal fa-timer" />
        <h2 onClick={() => setOpen(!isOpen)}>
          Timers
        </h2>
        <menu onClick={(e) => e.stopPropagation()}>
          { isOpen &&
            <SearchBox searchValueAtom={TimersCardAtoms.searchValue} />
          }
          <ButtonOpen openAtom={openAtom} />
        </menu>
      </CardHeader>
      <CardSection>
        <TimersTable/>
      </CardSection>
    </Card>
  )

  function TimersTable(props:any) {
    const [ sortOrder, setSortOrder ] = useAtom(TimersCardAtoms.sortOrder)
    const timers = useAtomValue(TimersCardAtoms.sortedTimers)

    logger.trace("TimersTable: sortOrder=%s, %d timers", sortOrder, timers.length)
  
    return (
      <table className="table sticky-headers">
        <thead>
          <tr>
            <SortButton label="NAME" alpha={true} />
            <SortButton label="COUNT" />
            <SortButton label="TOTAL" />
            <SortButton label="LAST" />
            <SortButton label="AVERAGE" />
            <SortButton label="MIN" />
            <SortButton label="MAX" />
          </tr>
        </thead>
        <tbody>
          { timers.map(timer => <TimerRow timer={timer} key={timer.name} />)}
        </tbody>
      </table>
    )

    function SortButton({ label, alpha } : { label:string, alpha?:boolean }) {
      const showIcon = sortOrder.startsWith(label)
      return (
        <th className="link" onClick={() => setSortOrder(sortOrder === label ? `${label}-DESC` : label)}>
          { showIcon &&
            <i className={`fal fa-sort-${alpha ? "alpha" : "numeric"}-${sortOrder === label ? "down" : "up"}`} />
          }
          { label }
        </th>
      )
    }

    function TimerRow({timer} : {timer:Timer}) {
      return (
        <tr>
          <td>{timer.name}</td>
          <td>{timer.count}</td>
          <td>{timer.total}</td>
          <td>{formatNumber(timer.last, NumberFormats.number1)}</td>
          <td>{formatNumber(timer.average, NumberFormats.number1)}</td>
          <td>{formatNumber(timer.min, NumberFormats.number1)}</td>
          <td>{formatNumber(timer.max, NumberFormats.number1)}</td>
        </tr>
      )
    }
  }
}

