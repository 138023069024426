import { Item } from "../types/Item"
import { Model } from "./Model"
import { ModelCodes } from "./ModelCodes"

export const ModelKeys = {...ModelCodes}

export function useModelKeys() {
  const TypeKeys = ModelKeys.type
  const AccountKeys = ModelKeys.account
  const AssetKeys = ModelKeys.asset
  const CategoryKeys = ModelKeys.category
  const TransactionKeys = ModelKeys.transaction
  const FileKeys = ModelKeys.file
  const BankKeys = ModelKeys.bank

  return { TypeKeys, AccountKeys, AssetKeys, CategoryKeys, TransactionKeys, FileKeys, BankKeys } as const
}

export function createModelKeys(model:Model) {
  if (ModelKeys.settings === ModelCodes.settings) {
    Object.assign(ModelKeys, {
      root:          "",
      settings:      model.getItemKey(ModelCodes.settings),
      parties:       model.getItemKey(ModelCodes.parties),
      diagnostics:   model.getItemKey(ModelCodes.diagnostics),
      recycleBin:    model.getItemKey(ModelCodes.recycleBin),
      type: {
        root:        model.getItemKey(ModelCodes.type.root),
        setting:     model.getItemKey(ModelCodes.type.setting),
        category:    model.getItemKey(ModelCodes.type.category),
        folder:      model.getItemKey(ModelCodes.type.folder),
        link:        model.getItemKey(ModelCodes.type.link),
        party:       model.getItemKey(ModelCodes.type.party),
        valuation:   model.getItemKey(ModelCodes.type.valuation),
        building:    model.getItemKey(ModelCodes.type.building),
      },
      account: {
        root:        model.getItemKey(ModelCodes.account.root),
        checking:    model.getItemKey(ModelCodes.account.checking),
        savings:     model.getItemKey(ModelCodes.account.savings),
        deposit:     model.getItemKey(ModelCodes.account.deposit),
        loan:        model.getItemKey(ModelCodes.account.loan),
        creditcard:  model.getItemKey(ModelCodes.account.creditcard),
        wallet:      model.getItemKey(ModelCodes.account.wallet),
      },
      asset: {
        root:        model.getItemKey(ModelCodes.asset.root),
        portfolio:   model.getItemKey(ModelCodes.asset.portfolio),
        property:    model.getItemKey(ModelCodes.asset.property),
        commercial:  model.getItemKey(ModelCodes.asset.commercial),
        investment:  model.getItemKey(ModelCodes.asset.investment),
        kiwisaver:   model.getItemKey(ModelCodes.asset.kiwisaver),
        stock:       model.getItemKey(ModelCodes.asset.stock),
      },
      category: {
        root:         model.getItemKey(ModelCodes.category.root),
        income:       model.getItemKey(ModelCodes.category.income),
        expense:      model.getItemKey(ModelCodes.category.expense),
        finance:      model.getItemKey(ModelCodes.category.finance),
        tax:          model.getItemKey(ModelCodes.category.tax),
        balance:      model.getItemKey(ModelCodes.category.balance),
        deposit:      model.getItemKey(ModelCodes.category.deposit),
        withdrawal:   model.getItemKey(ModelCodes.category.withdrawal),
        transferIn:   model.getItemKey(ModelCodes.category.transferIn),
        transferOut:  model.getItemKey(ModelCodes.category.transferOut),
        payment:      model.getItemKey(ModelCodes.category.payment),
        buy:          model.getItemKey(ModelCodes.category.buy),
        sell:         model.getItemKey(ModelCodes.category.sell),
        earnings:     model.getItemKey(ModelCodes.category.earnings),
        interest:     model.getItemKey(ModelCodes.category.interest),
        interestRate: model.getItemKey(ModelCodes.category.interestRate),
        incomeOther:  model.getItemKey(ModelCodes.category.incomeOther),
        expenseOther: model.getItemKey(ModelCodes.category.expenseOther),
        },
      transaction: {
        root:         model.getItemKey(ModelCodes.transaction.root),
        balance:      model.getItemKey(ModelCodes.transaction.balance),
        moneyIn:      model.getItemKey(ModelCodes.transaction.moneyIn),
        moneyOut:     model.getItemKey(ModelCodes.transaction.moneyOut),
        transferIn:   model.getItemKey(ModelCodes.transaction.transferIn),
        transferOut:  model.getItemKey(ModelCodes.transaction.transferOut),
        interest:     model.getItemKey(ModelCodes.transaction.interest),
        interestRate: model.getItemKey(ModelCodes.transaction.interestRate),
      },
      file: {
        root:   model.getItemKey(ModelCodes.file.root),
        ofx:    model.getItemKey(ModelCodes.file.ofx),
        csv:    model.getItemKey(ModelCodes.file.csv),
        api:    model.getItemKey(ModelCodes.file.api),
      },
      bank: {
        root:    model.getItemKey(ModelCodes.bank.root),
        stmts:   model.getItemKey(ModelCodes.bank.stmts),
        stmtAPI: model.getItemKey(ModelCodes.bank.stmtAPI),
        rules:   model.getItemKey(ModelCodes.bank.rules),
        rule:    model.getItemKey(ModelCodes.bank.rule),
        ruleOveride:    model.getItemKey(ModelCodes.bank.ruleOveride),
        ruleApiDefined: model.getItemKey(ModelCodes.bank.ruleApiDefined),
      }
    })
  }
}

export class Type {
  public static isLink(item?:Item) {
    return (item?.typeKey === ModelKeys.type.link)
  }

  public static isValuation(item?:Item) {
    return (item?.typeKey === ModelKeys.type.valuation)
  }

  public static isFile(item?:Item) {
    return (item?.typeKey === "file")
  }

  public static isFolder(item?:Item) {
    return (item?.typeKey === "folder")
  }

  public static isFilesItem(item?:Item) {
    return (Type.isFile(item) || Type.isFolder(item))
  }
}

export class Account {
  public static isChecking(item?:Item) {
    return (item?.typeKey === ModelKeys.account.checking)
  }

  public static isCreditCard(item?:Item) {
    return (item?.typeKey === ModelKeys.account.creditcard)
  }

  public static isTermDeposit(item?:Item) {
    return (item?.typeKey === ModelKeys.account.deposit)
  }

  public static isLoan(item?:Item) {
    return (item?.typeKey === ModelKeys.account.loan)
  }

  public static isSavings(item?:Item) {
    return (item?.typeKey === ModelKeys.account.savings)
  }

  public static isWallet(item?:Item) {
    return (item?.typeKey === ModelKeys.account.wallet)
  }
}

export class Asset {
  public static isPortfolio(item?:Item) {
    return (item?.typeKey === ModelKeys.asset.portfolio)
  }

  public static isProperty(item?:Item) {
    return (item?.typeKey === ModelKeys.asset.property)
  }

  public static isInvestment(item?:Item) {
    return (item?.typeKey === ModelKeys.asset.investment)
  }

  public static isKiwiSaver(item?:Item) {
    return (item?.typeKey === ModelKeys.asset.kiwisaver)
  }

  public static isStock(item?:Item) {
    return (item?.typeKey === ModelKeys.asset.stock)
  }

  public static isCommercial(item?:Item) {
    return (item?.typeKey === ModelKeys.asset.commercial)
  }

  public static isUnitised(item?:Item) {
    return Asset.isInvestment(item) || Asset.isKiwiSaver(item) || Asset.isStock(item)
  }
}

export class Bank {
  public static isRule(item?:Item) {
    return (item?.parentKey === ModelKeys.bank.rules)
  }
  
  public static isStmt(model:Model, item?:Item) {
    return model.hasAncestor(item, ModelKeys.bank.stmts) !== undefined || 
           model.hasAncestor(item, ModelKeys.bank.stmtAPI) !== undefined
  }
}

export class Transaction {
  public static isBalance(item?:Item) {
    return (item?.typeKey === ModelKeys.transaction.balance)
  }

  public static isDebit(item?:Item) {
    return (item?.typeKey === ModelKeys.transaction.moneyIn)
  }

  public static isCredit(item?:Item) {
    return (item?.typeKey === ModelKeys.transaction.moneyOut)
  }

  public static isInterest(item?:Item) {
    return (item?.typeKey === ModelKeys.transaction.interest)
  }

  public static isInterestRate(item?:Item) {
    return (item?.typeKey === ModelKeys.transaction.interestRate)
  }

  public static isTransferIn(item?:Item) {
    return (item?.typeKey === ModelKeys.transaction.transferIn)
  }

  public static isTransferOut(item?:Item) {
    return (item?.typeKey === ModelKeys.transaction.transferOut)
  }

  public static isTransfer(item?:Item) {
    return Transaction.isTransferIn(item) || Transaction.isTransferOut(item)
  }

  public static isBankTransaction(model:Model, item:Item) {
    return model.isTransaction(item) && 
          (model.hasAncestor(item, ModelKeys.bank.stmts) || model.hasAncestor(item, ModelKeys.bank.stmtAPI))
  }
}

export class Category {
  public static isBalance(item?:HasCategory) {
    return (item?.categoryKey === ModelKeys.category.balance)
  }

  public static isEarnings(item?:HasCategory) {
    return (item?.categoryKey === ModelKeys.category.earnings)
  }

  public static isInterest(item?:HasCategory) {
    return (item?.categoryKey === ModelKeys.category.interest)
  }

  public static isInterestRate(item?:HasCategory) {
    return (item?.categoryKey === ModelKeys.category.interestRate)
  }

  public static isPayment(item?:HasCategory) {
    return (item?.categoryKey === ModelKeys.category.payment)
  }
}

export interface HasCategory {
  categoryKey?: string
}

/**
 * Given a parent type, return a list of types that are allowed to be created as
 * children of the parent
 * @param parentTypeKey type of parent Item
 * @returns array of allowed child Item type keys for the parent
 */
export function getAllowedChildTypeKeys(parentTypeKey:string): string[] {
  switch (parentTypeKey) {
    case ModelKeys.asset.portfolio:
      return [
        ModelKeys.asset.portfolio,
        ModelKeys.asset.property,
        ModelKeys.asset.investment,
        ModelKeys.asset.kiwisaver,
        ModelKeys.account.checking,
        ModelKeys.account.savings,
        ModelKeys.account.loan,
        ModelKeys.account.creditcard,
        ModelKeys.account.deposit,
        ModelKeys.account.wallet,
        // ModelKeys.asset.stock,
        // ModelKeys.asset.commercial,
      ]

    case ModelKeys.asset.property:
      return [
        ModelKeys.account.loan,
        ModelKeys.account.checking,
        ModelKeys.type.valuation,
      ]
  }

  return []
}