import { atom } from 'jotai'
import { BankStmtModel } from '../invest/BankStmtModel'
import { Model } from '../model/Model'
import { ModelKeys } from "../model/ModelKeys"
import { Item } from '../types/Item'
import { Logger } from '../utils/Logger'
import { getAtomValue, setAtomValue } from './AtomUtils'

const logger = new Logger("core.BankStmtModelAtoms")

export class BankStmtModelAtoms {
  private static modelAtom = atom<BankStmtModel | undefined>(undefined)

  public static async onModelUpdated(model:Model, initialLoad:boolean, modifiedItems:Item[]) {
    logger.start("onModelUpdated", "initialLoad=%s, %d modifiedItems", initialLoad, modifiedItems.length)

    const bankStmtModel = getAtomValue(BankStmtModelAtoms.modelAtom)

    if (initialLoad || !bankStmtModel || model.itemsHaveAncestor(modifiedItems, ModelKeys.bank.root)) {
      setAtomValue(BankStmtModelAtoms.modelAtom, new BankStmtModel(model))
    }

    logger.finish("onModelUpdated")
  }

  public static reset() {
    setAtomValue(BankStmtModelAtoms.modelAtom, undefined)
  }
  
  public static model = atom(
    (get) => get(BankStmtModelAtoms.modelAtom) as BankStmtModel
  ) 
}

export function getBankStmtModel() {
  return getAtomValue(BankStmtModelAtoms.model)
}