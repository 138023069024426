import { useState } from "react"
import { getDimension } from "../../../core/builder/CashflowFilter"
import { PivotRow } from "../../../core/builder/PivotTable"
import { Item } from '../../../core/types/Item'
import { Logger } from "../../../core/utils/Logger"
import { timeScaleStarting } from "../../../core/utils/TimeScale"
import { useFilterContext } from "../../../hooks/context/FilterContext"
import { useViewContext } from "../../../hooks/context/ViewContext"
import { usePivotTables } from "../../../hooks/pivot/usePivotTables"
import { PivotCellLink, PivotRowFilter, PivotRowLink } from "./CashflowPivotTable"

const logger = new Logger("table.CashflowPivotTreeTable")

export function CashflowPivotTreeTable(props:any) {
  const { name, pivotTable } = usePivotTables()

  logger.setContext(name).debug("Rendering:")

  let { rows, columns, totals } = pivotTable
  if (rows.length > 0 && rows[0].openingRow) {
    rows.splice(0, 1)
  }

  return (
    <table id="CashflowPivotTreeTable" className="table cashflow-table sticky-headers">
      <thead>
        <tr>
          <th>
            <PivotRowFilter />
          </th>
          { rows.map((row) =>
            <PivotRowLink row={row} key={row.key}/>
          )}
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        { columns.map(category => 
          <CategoryRow category={category} 
                       level={0} 
                       rows={rows} 
                       columns={columns} 
                       totals={totals} 
                       key={category.key} />
        )}
      </tbody>
      <tfoot className="totals">
        <tr>
          <th>Totals</th>
          { rows.map(row =>
            <PivotCellLink currency={row.total} key={row.key} />
          )}
          <PivotCellLink currency={totals.total} />
        </tr>
      </tfoot>
    </table>
  )
}

function CategoryRow(props: {
  category: Item
  level: number
  rows: PivotRow[]
  columns: Item[]
  totals: PivotRow
}) {
  const { viewState } = useViewContext()
  const { model, pivotTable } = usePivotTables()
  const filterStack = useFilterContext()

  const { category, level, rows, columns, totals } = props
  const [,setItemState] = useState(viewState.get(category.key))

  const isEmpty = pivotTable.totals.cell(category.key).total === 0
  if (isEmpty || !viewState.isVisible(category.key)) {
    return <></>
  }

  const expandable = model.hasChildren(category.key)
  const expanded = viewState.isOpenTree(category.key)
  const icon = model.getItemIcon(category.key)
  
  const iconWidth = 1.5
  const styleIndent = { paddingLeft: iconWidth*level + "rem" }

  logger.trace("Rendering CategoryRow category=%s, expanded=%s, expandable=%s", 
                category.name, expanded, expandable)

  return (
    <>
      <tr>
        <th style={styleIndent} title={model.getItemDescription(category.key)}>
          <button onClick={onExpand} disabled={!expandable}>
            <i className={icon} />
            { model.getItemName(category) }
          </button>
        </th>

        { rows.map(row =>
          <PivotCellLink currency={row.cell(category.key).total}
                          onClick={() => onClickCell(row, category)} key={row.key} />
        )}

        <PivotCellLink currency={totals.cell(category.key).total} />
      </tr>

      { expanded && model.childrenSorted(category.key).map(child =>
        <CategoryRow category={child} 
                     level={level+1}
                     rows={rows} 
                     columns={columns} 
                     totals={totals}
                     key={child.key} />
      )}
    </>
  )

  function onClickCell(row:PivotRow, column:Item) {
    const scale = timeScaleStarting(row.scale.units, row.scale.start, row.scale.end)

    const { dimension } = getDimension(model, column)
  
    filterStack.push({ columnItem: column, dimension, scale, raw:true })
  }  


  function onExpand(e:any) {
    e.stopPropagation()
    
    const itemState = viewState.toggleOpenTree(category.key)
    if (itemState.openTree) {
      viewState.openTo(model, category.key)
    }
    setItemState(itemState)
  }
}
