import { AccountBalanceAreaChart } from "../../../ui/charts/cashflow/AccountBalanceAreaChart"
import { PageProvider } from "../../PageProvider"
import { Section } from "../../PageSection"

export function CashflowsPage() {
  return (
    <article>
      <h1>Cashflow</h1>
      <section>
        <p>TODO Describe what cashflows are and why they are important</p>

        <Section title="Sample Account Balance Chart">
          <p>TODO...</p>
          <PageProvider>
            <AccountBalanceAreaChart height={150}/>
          </PageProvider>
        </Section>
      </section>
    </article>
  )
}