import { getApp } from "firebase/app"
import { CACHE_SIZE_UNLIMITED, collection, CollectionReference, doc, DocumentData, FirestoreError, getFirestore, initializeFirestore, onSnapshot, persistentLocalCache, persistentMultipleTabManager, QuerySnapshot, Unsubscribe, writeBatch } from "firebase/firestore"
import { HasStatus, Item, ItemStatus } from "../types/Item"
import { getUserFilePath } from "../user/FilesService"
import { Counters } from "../utils/Counters"
import { Logger } from "../utils/Logger"
import { handleModelSnapshot } from "./ModelBuilder"

const logger = new Logger("core.FireStoreService")

export function isActive() : boolean {
  return getApp() !== undefined
}

export function initializeDB() {
  try {
    return initializeFirestore(getApp(), { 
      localCache: persistentLocalCache({
        tabManager: persistentMultipleTabManager(),
        cacheSizeBytes: CACHE_SIZE_UNLIMITED,
      }),
    })
  } catch (e:any) {
    const message = logger.error("initializeDB", "initializeFirestore failed: %s", e.message)
    throw Error(message, { cause:e })
  }
}

export async function closeDB() {
  logger.debug("closeDB called: userFile=%o", getUserFilePath())

  try {
    unsubscribeFileItems()
  } catch (e:any) {
    const message = logger.error("closeDB", "Error closing Firestore: %s", e.message)
    throw Error(message, { cause:e })
  }

  logger.debug("closeDB finished")
}

export function getUserItemCollection() {
  const { users, email, files, file, items } = getUserFilePath()
  const db = getFirestore()
  const itemCollection = collection(db, users, email, files, file, items)
  return itemCollection
}

/**
 * Save specified items to the current user's collection in Firestore 
 */
 export async function saveToDB(modifiedItems:Item[], userFile = getUserFilePath()) : Promise<void> {
  const { users, email, files, file, items } = userFile
  logger.start("saveToDatabase", "Saving %d items to %o", modifiedItems.length, userFile)

  try {
    const db = getFirestore()
    const promises:Promise<any>[] = []

    let batch = writeBatch(db)
    let count = 0
    let saved = 0

    for (const item of modifiedItems) {
      if (item.status !== ItemStatus.TRANSIENT) {
        // Update modified date
        item.modifiedDate = Date.now()

        // Remove undefined values since Firestore won't accept them
        for (const [key,value] of Object.entries(item)) {
          if (value === undefined) {
            delete item[key as keyof Item]
          }
        }

        try {
          const docRef = doc(db, users, email, files, file, items, item.key)
          if (item.status === ItemStatus.DELETED) {
            batch.delete(docRef)
          } else {
            batch.set(docRef, item)
          }
          count++
        } catch (e:any) {
          const message = logger.error("saveToDatabase","Failed to save item to %o: item=%o", userFile, item)
          throw Error(message, { cause:e })
        }

        // Maximum batch size, commmit and create new batch
        if (count === 500) {
          promises.push(batch.commit())
          saved += count
          count = 0
          batch = writeBatch(db)
        }
      }
    }
    
    if (count > 0) {
      promises.push(batch.commit())
      saved += count
    }
    
    logger.finish("saveToDatabase", "Commmitting %d batches with %d/%d items", promises.length, saved, modifiedItems.length)

    try {
      const now = Date.now()
      await Promise.all(promises) 
      logger.debug("saveToDatabase: Saved %d/%d items in %dms", saved, modifiedItems.length, Date.now()-now)
    } catch(reason:any) {
      const message = logger.error("saveToDatabase", "Error saving %d/%d items: reason=%o", saved, modifiedItems.length, reason)
      throw Error(message, { cause:reason })
    }

  } catch (e:any) {
    const message = logger.error("saveToDatabase", "Failed to save %d items to %o", modifiedItems.length, userFile)
    throw Error(message, { cause:e })
  } finally {
  }
}

/**
 * Subscribe to changes in the document collection for this user
 */
let unsubscribeItemsCallback:Unsubscribe | null = null
 
export function subscribeToFileItems() {
  unsubscribeItemsCallback = subscribe(getUserItemCollection(), handleModelSnapshot)
}

export function subscribe<T extends HasStatus>(collection:CollectionReference, handleSnapshot:(items:T[]) => void) {
  const collectionPath = collection.path
  logger.debug("subscribe: Subscribing to %s", collectionPath)

  return onSnapshot(collection, onNext, onError)

  /** Called whenever a document in the collection changes */
  function onNext(snapshot:QuerySnapshot<DocumentData>) {
    logger.start("subscribe.onNext", "%d changes detected in %s", snapshot.docChanges().length, collectionPath)

    let added=0, modified=0, removed=0

    const items:T[] = []
    for (const change of snapshot.docChanges()) {
      const item = change.doc.data()
      items.push(item as T)

      switch (change.type) {
        case "added":
          added++
          break
        case "modified":
          modified++
          item.status = ItemStatus.MODIFIED
          break
        case "removed":
          removed++
          item.status = ItemStatus.DELETED
          break
      }
    }
    
    // Update counters
    Counters.set(logger.name, "subscribe.onNext", undefined, items.length)
    
    logger.finish("subscribe.onNext", "%d changes (%d added, %d modified, %d removed) in %s", 
                  items.length, added, modified, removed, collectionPath)

    // Finally call the onChange callback
    if (items.length > 0) {
      handleSnapshot(items)
    }
  }

  /** Called when errors occur */
  function onError(error:FirestoreError) {
    const message = logger.error("subscribe.onError", "Error subscribing to %s", collectionPath)
    throw Error(message, { cause:error })
  }
}

export function unsubscribeFileItems() {
  if (unsubscribeItemsCallback) {
    unsubscribeItemsCallback()
    unsubscribeItemsCallback = null
  }
}
