import { ModelKeys } from "../../core/model/ModelKeys"
import { Logger } from "../../core/utils/Logger"
import { useInvestmentModel } from "../../hooks/context/InvestmentModelContext"
import { AssetChartCard } from "./asset/AssetChartCard"
import { AssetPerformanceCard } from "./asset/AssetPerformanceCard"
import { ValuationChartCard } from "./asset/ValuationChartCard"
import { ValuationEditorCard } from "./asset/ValuationEditorCard"
import { CashflowCard } from "./cashflow/CashflowCard"
import { CashflowChartCard } from "./cashflow/CashflowChartCard"
import { LoanSummaryCard } from "./loan/LoanSummaryCard"
import { PropertyDetailsCard } from "./property/PropertyDetailsCard"

export const logger = new Logger("cards.CardSelector")

export function CardSelector(props:any) {
  const { item, asset, hasAssets } = useInvestmentModel()

  const { name, open } = props
  const cardName = name || getCardName(item.typeKey, hasAssets)

  switch (cardName) {
    case "CashflowCard":
      return <CashflowCard open={open} />

    case "CashflowChartCard":
      return <CashflowChartCard open={open} />

    case "ValuationChartCard":
      return <ValuationChartCard open={open} />

    case "AssetChartCard":
      return <AssetChartCard open={open} />
  
    case "AssetPerformanceCard":
      return <AssetPerformanceCard open={open} />

    case "PropertySummaryCard":
      return <AssetChartCard open={open} />
      
    case "ValuationEditorCard":
      return <ValuationEditorCard open={open} />
  
    case "LoanSummaryCard":
      return <LoanSummaryCard open={open} />

    case "PropertyDetailsCard":
      return <PropertyDetailsCard item={item} asset={asset} />
  }
}

function getCardName(typeKey:string, hasAssets:boolean) : string {
  switch (typeKey) {
    case ModelKeys.asset.portfolio:
      return hasAssets ? "ValuationChartCard" : "CashflowChartCard"

    case ModelKeys.asset.investment:
    case ModelKeys.asset.kiwisaver:
    case ModelKeys.asset.stock:
      return "AssetChartCard"

    case ModelKeys.asset.property:
      return "PropertySummaryCard"
      
    case ModelKeys.type.valuation:
      return "ValuationEditorCard"
  
    case ModelKeys.account.loan:
      return "LoanSummaryCard"

    case ModelKeys.type.building:
      return "PropertyDetailsCard"
  }

  return "CashflowChartCard"
}
