import { Asset } from "../../../core/model/ModelKeys"
import { TransactionItem } from "../../../core/types/TransactionItem"
import { formatDate } from '../../../core/utils/DateFormat'
import { Logger } from "../../../core/utils/Logger"
import { LoggerConfig } from "../../../core/utils/LoggerConfig"
import { NumberFormats, formatCurrency, formatNumber } from '../../../core/utils/Numbers'
import { useModel } from "../../../hooks/context/ModelContext"
import { useTransferAccount } from "../../../hooks/transfer/useTransferAccount"
import { ItemLink } from "../../views/ViewLinks"

const logger = new Logger("transaction.TransactionItemDetails")

export function TransactionItemDetails({ transaction } : {transaction:TransactionItem}) {
  const { model } = useModel()
  const { transferAccount, relatedTransaction } = useTransferAccount(transaction)

  const account = model.getItem(transaction?.accountKey || transaction?.parentKey)
  const isUnitised = Asset.isUnitised(account)

  let amount = transaction?.value || (transaction as any)?.amount
  let date = transaction?.startDate || (transaction as any)?.date

  logger.debug("Rendering: transaction=%o", transaction)

  return (
    <table id="TransactionItemDetails" className="table form">
      <tbody>
        <tr>
          <td>Account</td>
          <td>{account?.name} [ {account?.code} ]</td>
        </tr>
        <tr>
          <td>Date</td>
          <td>{formatDate(date, LoggerConfig.defaultDateFormat)}</td>
        </tr>
        <tr>
          <td>Amount</td>
          <td>{formatCurrency(amount)}</td>
        </tr>
        <tr>
          <td>Balance</td>
          <td>{formatCurrency(transaction?.balance)}</td>
        </tr>
        { isUnitised &&
        <>
          <tr>
            <td>Units</td>
            <td>{formatNumber(transaction?.units, NumberFormats.units)}</td>
          </tr>
          <tr>
            <td>Unit Price</td>
            <td>{formatNumber(transaction?.unitPrice, NumberFormats.units)}</td>
          </tr>
          <tr>
            <td>Unit Balance</td>
            <td>{formatNumber(transaction?.unitBalance, NumberFormats.units)}</td>
          </tr>
        </>
        }
        <tr>
          <td>Name</td>
          <td>{transaction?.name}</td>
        </tr>
        <tr>
          <td>Description</td>
          <td>{transaction?.description}</td>
        </tr>
        <tr>
          <td>Type</td>
          <td>{ model.getItemName(transaction?.typeKey as string) }</td>
        </tr>
        { transferAccount &&
          <>
            <tr>
              <td>Transfer Account</td>
              <td>{ transferAccount.name }</td>
            </tr>
            <tr>
              <td>Related Transaction</td>
              <td><ItemLink item={relatedTransaction} /></td>
            </tr>
          </>
        }
        <tr>
          <td>Category</td>
          <td>
            { model.getItemName(transaction?.categoryKey) }
          </td>
        </tr>
        <tr>
          <td>Rule</td>
          <td>
            { model.getItemName(transaction?.ruleKey as string) + " (" + model.getItem(transaction?.ruleKey as string)?.sortOrder })
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div className="divider"/>
          </td>
        </tr>
        <tr>
          <td>Key</td>
          <td>
            <ItemLink item={model.getItem(transaction?.key)}>
              {transaction?.key}
            </ItemLink>
          </td>
        </tr>
        <tr>
          <td>Parent Key</td>
          <td>
            <ItemLink item={model.getItem(transaction?.parentKey)}>
              {transaction?.parentKey}
            </ItemLink>
          </td>
        </tr>
        <tr>
          <td>Code</td>
          <td>{transaction?.code}</td>
        </tr>
        <tr>
          <td>Sort Order</td>
          <td>{transaction?.sortOrder}</td>
        </tr>
        <tr>
          <td>Status</td>
          <td>{transaction?.status}</td>
        </tr>
      </tbody>
    </table>
  )
}
