import { Item, setItemPropNames } from "./Item"

export interface ValuationItem extends Item {
  /** Date of the valuation */
  date: number

  /** Value of the asset */
  value: number

  /** Unit value, only for unitised assets or shares */
  unitPrice?: number

  /** Unit balance as at the price date */
  unitBalance?: number
}

type ValuationItemProp = keyof ValuationItem

type ValuationItemRecord = Record<ValuationItemProp, ValuationItemProp>

export const ValuationItemProps:ValuationItemRecord = {
  key: "key",
  code: "code",
  name: "name",
  description: "description",
  parentKey: "parentKey",
  typeKey: "typeKey",
  links: "links",
  sortOrder: "sortOrder",
  status: "status",
  modifiedDate: "modifiedDate",
  date: "date",
  value: "value",
  unitPrice: "unitPrice",
  unitBalance: "unitBalance"
}

setItemPropNames(ValuationItemProps)

export const ValuationItemPropNames = Object.keys(ValuationItemProps) as ValuationItemProp[]
