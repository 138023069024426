import { atom } from "jotai"
import { RankedSearchOptions, Rankings, searchAndRankItems } from "../../core/model/RankedSearch"
import { Counter, Counters } from "../../core/utils/Counters"
import { getAtomValue, setAtomValue } from "../../core/atom/AtomUtils"

export class CountersCardAtoms {
  public static sortOrder = atom("NAME")

  public static searchValue = atom("")

  public static updatedTime = atom(Date.now())

  public static filteredCounters = atom<Counter[]>(
    (get) => {
      const searchValue = get(CountersCardAtoms.searchValue)
      get(CountersCardAtoms.updatedTime)

      if (searchValue && searchValue.length >= 2) {
        const rankedItems = searchAndRankItems(Counters.list, searchValue, CountersCardAtoms.searchOptions)
        return Array.from(rankedItems.values()).map(ri => ri.item)
      }

      return Counters.list
    }
  )

  public static sortedCounters = atom<Counter[]>(
    (get) => {
      const sortOrder = get(CountersCardAtoms.sortOrder)
      const filteredCounters = get(CountersCardAtoms.filteredCounters)

      switch (sortOrder) {
        case "NAME": return Counters.sortByName(filteredCounters)
        case "NAME-DESC": return Counters.sortByNameDesc(filteredCounters)
        case "COUNT": return Counters.sortByCount(filteredCounters)
        case "COUNT-DESC": return Counters.sortByCountDesc(filteredCounters)
        case "TOTAL": return Counters.sortByTotal(filteredCounters)
        case "TOTAL-DESC": return Counters.sortByTotalDesc(filteredCounters)
        case "AVERAGE": return Counters.sortByAverage(filteredCounters)
        case "AVERAGE-DESC": return Counters.sortByAverageDesc(filteredCounters)
        case "MIN": return Counters.sortByMin(filteredCounters)
        case "MIN-DESC": return Counters.sortByMinDesc(filteredCounters)
        case "MAX": return Counters.sortByMax(filteredCounters)
        case "MAX-DESC": return Counters.sortByMaxDesc(filteredCounters)
      }
      return []
    }
  )

  public static searchOptions: RankedSearchOptions<Counter> = {
    props: [
      { prop: "name", threshold: Rankings.CONTAINS },
    ],
  }

  public static reset() {
    Counters.clear()
    setAtomValue(CountersCardAtoms.updatedTime, Date.now())
    getAtomValue(CountersCardAtoms.sortedCounters)
  }
}
