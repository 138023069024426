import { ValuationModel } from '../../../core/invest/ValuationModel'
import { ModelKeys } from "../../../core/model/ModelKeys"
import { ValuationItem } from "../../../core/types/ValuationItem"
import { Logger } from "../../../core/utils/Logger"
import { useInvestmentModel } from '../../../hooks/context/InvestmentModelContext'
import { useCardOpen } from "../../../hooks/useCardOpen"
import { AssetPriceChart } from "../../charts/asset/AssetPriceChart"
import { Card, CardSection } from "../../controls/Card"
import { setDialogItem } from "../../controls/dialog/ItemDialog"
import { CardHeaderWithMenu } from '../CardHeaderWithMenu'
import { AssetPriceNarrative } from './AssetPriceNarrative'
import { ValuationItemTable } from './ValuationItemTable'

const logger = new Logger("ValuationEditorCard")

export function ValuationEditorCard(props: { title?:string, open?:boolean }) {
  const { title, open } = props
  const { model, item, name, asset, valuationItems, isUnitised } = useInvestmentModel<ValuationModel>()
  const { isOpen } = useCardOpen(logger.name, item.key, open === true)
  
  logger.debug("Rendering: valuation=%s, asset=%s", name, asset?.name)

  return (
    <Card id={logger.name} open={isOpen}>
      <CardHeaderWithMenu cardName={logger.name} title={title} showChildren>
        <ButtonCreate/>
      </CardHeaderWithMenu>
      <CardSection>
        <AssetPriceChart height={isUnitised ? 200 : 250} noHeader />
        <AssetPriceNarrative />
        <div className="scrollable">
          <ValuationItemTable />
        </div>
      </CardSection>
    </Card>
  )

  function ButtonCreate() {
    return (
      <menu>
        <button onClick={onCreate} title={`Add a valuation to ${asset.name}`}>
          <i className="far fa-plus"></i>
        </button>
      </menu>
    )
  }

  function onCreate() {
    const types = [ 
      ModelKeys.type.valuation, 
      ModelKeys.asset.investment, 
      ModelKeys.asset.kiwisaver,
      ModelKeys.asset.stock 
    ]

    // If the parent is not a ValuationItem | UnitTrust | Stock, use the first child
    let parent = item
    if (!types.includes(item.typeKey)) {
      const children = model.childrenOfType<ValuationItem>(item.key, ModelKeys.type.valuation)
      if (children && children.length > 0) {
        parent = children[0]
      }
    }


    // Create a new ValuationItem
    const newItem = model.newItem<ValuationItem>(parent.key, ModelKeys.type.valuation)
    newItem.date = Date.now()
    newItem.value = 0

    if (isUnitised) {
      const valuationSorted = valuationItems.toSorted((i1,i2) => (i2.date - i1.date))
      newItem.unitBalance = valuationSorted.at(0)?.unitBalance
    }

    // Open the valuation for editing
    setDialogItem(newItem, true)
  }
}
