import { useDropzone } from 'react-dropzone'
import { saveModifiedItems } from '../../../core/service/ModelAction'
import { Logger } from "../../../core/utils/Logger"
import { Card, CardHeader, CardSection } from '../../controls/Card'
import { useAtomRef } from '../../../hooks/useAtomRef'
import { ButtonOpen } from '../../controls/buttons/ButtonOpen'
import { ShowIfAtom } from '../../controls/ShowIf'

const logger = new Logger("cards.ImportDataCard")

export function ImportDataCard(props:any) {
  const { getRootProps, getInputProps } = useDropzone({multiple:false, onDrop:onDrop})
  const openAtom = useAtomRef(false)

  logger.debug("Rendering:")

  // Render a Card view
  return (
    <Card id="ImportDataCard">
      <CardHeader>
        <i className="fal fa-upload" />
        <h2>Import Data</h2>
        <menu>
          <ButtonOpen openAtom={openAtom}/>
        </menu>
      </CardHeader>
      <CardSection>
        <ShowIfAtom visible={openAtom}>
          <div className='dropzone link' {...getRootProps()}>
            <input {...getInputProps()} />
            <i className="fal fa-upload" />
            <abbr>Click to select files...</abbr>
            <label>
              Click to import data from a JSON file on your device, 
              or just drag 'n' drop a file to here
            </label>
          </div>
        </ShowIfAtom>
      </CardSection>
    </Card>
  )

  function onDrop(acceptedFiles:File[]) {
    logger.debug("onDrop: files=%o", acceptedFiles)

    for (const acceptedFile of acceptedFiles) {
      importFile(acceptedFile)
    }
  }

  function importFile(file:File) {
    const reader = new FileReader()
    reader.onload = (e:any) => {
      const data = e.target.result
      const items = JSON.parse(data)

      logger.debug("Importing %d items from %s", items.length, file.name)
      saveModifiedItems(items)
    }

    // Read file contents
    reader.readAsText(file)
  }
}
