import { NavLink } from "react-router-dom"
import { ModelKeys } from "../../core/model/ModelKeys"
import { Item } from "../../core/types/Item"
import { Logger } from "../../core/utils/Logger"
import { useModel } from "../../hooks/context/ModelContext"
import { useViewContext, ViewContextProvider } from "../../hooks/context/ViewContext"
import { useSidebar } from "../../hooks/useSidebar"
import { CardLink } from "../views/ViewLinks"

const logger = new Logger("layouts.JemstoneSidebar")

export function JemstoneSidebar(props:any) {
  const { showSidebar } = useSidebar()

  logger.debug("Rendering: showSidebar=%s", showSidebar)

  return (
    <ViewContextProvider>
      <aside className={showSidebar ? "sidebar open" : "sidebar closed"}>
        <HeaderLinks />
        <div className="divider"/>
        <TreeView />
      </aside>
    </ViewContextProvider>
  )
}

function HeaderLinks(props:any) {
  const { autohideSidebar } = useSidebar()

  const links = [
    {
      pathname: "/dashboard",
      icon: "fal fa-analytics",
      name: "Dashboard"
    },
    {
      pathname: "/table",
      icon: "fal fa-table",
      name: "Table View"
    },
    {
      pathname: "/wizard",
      icon: "fal fa-diagram-project",
      name: "Configuration Wizard"
    },
    {
      pathname: "/docs",
      icon: "fal fa-circle-info",
      name: "Documentation"
    },
    {
      pathname: "/files",
      icon: "fal fa-files",
      name: "File Manager"
    }
  ]

  return (
    <nav className="tree">
      { links.map(link => 
        <li title={link.name} key={link.pathname}>
          <summary>
            <NavLink to={{ pathname: link.pathname }} onClick={autohideSidebar}>
              <i className={link.icon} />
              {link.name}
            </NavLink>
          </summary>
        </li>
      )}
      {/* <FileManagerItem/> */}
    </nav>
  )
}

function TreeView(props:any) {
  const { model } = useModel()

  return (
    <nav className="tree">
      { model.childrenSorted(ModelKeys.root).map(item =>
        <TreeItem item={item} key={item.key} />
      )}
    </nav>
  )
}

function TreeItem({ item }: { item:Item }) {
  const { model } = useModel()
  const { viewState } = useViewContext()
  const { autohideSidebar } = useSidebar()
  const hasChildren = model.hasChildren(item.key)
  const isOpenTree = viewState.isOpenTree(item.key)

  return (
    <li title={model.getItemDescription(item)}>
      <summary>
        <button onClick={toggleOpenTree} disabled={!hasChildren}>
          <i className={model.getItemIcon(item)} />
        </button>
        <CardLink item={item} className="link" hideIcon={true} onClick={autohideSidebar} />
      </summary>
      { isOpenTree && hasChildren &&
        <nav className="tree">
          { model.childrenSorted(item.key).map(child =>
            <TreeItem item={child} key={child.key} />
          )}
        </nav>
      }
    </li>
  )

  function toggleOpenTree() {
    const itemState = viewState.toggleOpenTree(item.key)
    if (itemState.openTree) {
      viewState.openTo(model, item.key)
    }
    viewState.onEvent({name:"toggleOpenTree", key:item.key, value:itemState.openTree})
  }
}
