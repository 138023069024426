import { HasKey } from "../types/Item"
import { Logger } from "../utils/Logger"

const logger = new Logger("RankedSearch")

const RecordMatches = logger.isDebugEnabled

export const Rankings = {
  CASE_SENSITIVE_EQUAL: 10,
  EQUAL: 9,
  CONTAINS_ALL_WORDS: 8,
  STARTS_WITH_WORD: 7,
  STARTS_WITH: 6,
  CONTAINS_WORD: 5,
  WORD_STARTS_WITH: 4,
  CONTAINS: 3,
  ACRONYM: 2,
  MATCHES: 1,
  NO_MATCH: 0,
} as const

export type Ranking = typeof Rankings[keyof typeof Rankings]

export interface RankedItem<T extends HasKey> {
  /** The item that is ranked */
  item: T

  /** The ranking assigned to this item */
  rank: Ranking

  /** The property value that was searched */
  value?: string

  /** The number of words (split from search value) that matched */
  words: number

  /** The best ranked match */
  match?: RankedItemMatch

  /** Describe the matches we found  */
  matches: RankedItemMatch[]
}

interface RankedItemMatch {
  /** The property name that we searched */
  prop: string
  /** The property value that was searched */
  value: string
  /** The term we searched for */
  term: string
  /** The ranking of the match */
  rank: Ranking
}

interface PropGetter<T extends HasKey> {
  (item:T): string
}

type PropDef<T extends HasKey> = keyof T | PropGetter<T>

export interface PropOption<T extends HasKey> {
  prop: PropDef<T>
  threshold: Ranking
}

interface PropValue<T extends HasKey> extends PropOption<T> {
  value: string
}

export interface RankedSearchOptions<T extends HasKey> {
  props: PropOption<T>[]
}

/**
 * Search specified list of items for the searchValue
 * @param searchItems array of items to search
 * @param searchValue value to search for in each item - may contain multiple words
 * @param searchOptions properties to search in each item 
 */
export function searchAndRankItems<T extends HasKey>(searchItems:Iterable<T>, searchValue:string, searchOptions:RankedSearchOptions<T>) {
  // logger.start("searchAndRankItems", "Searching %d items for '%s'", items.length, searchValue)

  const searchWords = searchValue.split(' ')
  const propOptions = searchOptions.props

  // Map of ranked items, keyed by item.key
  const rankedItems = new Map<string,RankedItem<T>>()

  // Search all items for all terms
  for (const item of searchItems) {
    const propValues = getValues(item, propOptions)

    // Search for the complete searchValue
    const rankedItem = getRankedItem(item, propValues, [searchValue])
    if (rankedItem.rank >= Rankings.CONTAINS) {
      rankedItems.set(item.key, rankedItem)
    } 
    
    // Search for individual words (if there is more than 1 word)
    else if (searchWords.length > 1) {
      const wordSearchItem = getRankedItem(item, propValues, searchWords)

      // logger.debug("searchWords=%o, workSearchItem=%o", searchWords, wordSearchItem)

      // Only return a match if ALL words are matched somewhere in the item
      if (wordSearchItem.words >= searchWords.length) {
        wordSearchItem.rank = Rankings.CONTAINS_ALL_WORDS
        rankedItems.set(item.key, wordSearchItem)
      }
    }
  }

  // logger.finish("searchAndRankItems", "Returned %d/%d matches searching for '%s'", 
  //               rankedItems.size, items.length, searchValue)

  return rankedItems
}

/**
 * Determine the ranking for the specified item by searching the item fields specified in 
 * propValues for the searchTerms in the 
 * @param item Item to search - note this is passed only to included in rankedItem
 * @param propValues property values to be search
 * @param searchWords array of words to search for - might only be one
 * @returns 
 */
function getRankedItem<T extends HasKey>(item:T, propValues:PropValue<T>[], searchWords:string[]) {
  const rankedItem:RankedItem<T> = { item, rank:Rankings.NO_MATCH, matches:[], words:0 }

  // Search for each word
  for (const word of searchWords) {
    let wordCounted = false

    // Search each property
    for (const { value, prop, threshold } of propValues) {
      const rank = getRanking(value, word)
      if (rank >= threshold) {
        // This is for debug only
        if (RecordMatches) {
          const match:RankedItemMatch = {
            prop: prop as string,
            value: value,
            term: word,
            rank: rank
          }
          rankedItem.matches.push(match)

          if (rank > rankedItem.rank) {
            rankedItem.match = match
          }
        }

        // Count number of matching words
        if (!wordCounted && (rank === Rankings.CONTAINS_WORD || rank >= Rankings.STARTS_WITH_WORD)) {
          rankedItem.words++
          wordCounted = true
        }

        if (rank > rankedItem.rank) {
          rankedItem.rank = rank
          rankedItem.value = value
        }
      }
    }
  }
  return rankedItem
}

function getValues<T extends HasKey>(item:T, propOptions:PropOption<T>[]) {
  const propValues:PropValue<T>[] = []

  for (const propOption of propOptions) {
    const { prop, threshold } = propOption
    const value = getProperty(item, prop)
    propValues.push({ value, prop, threshold })
  }
  return propValues
}

function getProperty<T extends HasKey>(item:T, property:PropDef<T>) {
  if (typeof property === 'function') {
    return String(property(item))
  }
  return String(item[property])
}

/**
 * Gives a rankings score based on how well the two strings match.
 * @param {String} testString - the string to test against
 * @param {String} term - the string to rank i.e. the search string
 * @returns {Number} the ranking for how well term matches testString
 */
function getRanking(testString:string, term:string): Ranking {
  // too long
  if (term.length > testString.length) {
    return Rankings.NO_MATCH
  }

  // case sensitive equals
  if (testString === term) {
    return Rankings.CASE_SENSITIVE_EQUAL
  }

  // Lower casing before further comparison
  testString = testString.toLowerCase()
  term = term.toLowerCase()

  // case insensitive equals
  if (testString === term) {
    return Rankings.EQUAL
  }

  // contains word
  const index = indexOfWord(testString, term)
  if (index === 0) {
    return term.length < 2 ? Rankings.STARTS_WITH : Rankings.STARTS_WITH_WORD
  }
  if (index > 0) {
    return term.length < 2 ? Rankings.CONTAINS : Rankings.CONTAINS_WORD
  }

  // starts with
  const indexOfTerm = testString.indexOf(term)
  if (indexOfTerm === 0) {
    return Rankings.STARTS_WITH
  }

  // word starts with
  if (indexOfTerm > 0 && !isAlphaNumeric(testString.charCodeAt(indexOfTerm-1))) {
  // if (testString.includes(` ${term}`)) {
    return Rankings.WORD_STARTS_WITH
  }

  // contains
  if (indexOfTerm >= 0) {
    return Rankings.CONTAINS
  } else if (term.length === 1) {
    // If the only character in the given stringToRank
    //   isn't even contained in the testString, then
    //   it's definitely not a match.
    return Rankings.NO_MATCH
  }

  // // acronym
  // if (getAcronym(testString).includes(stringToRank)) {
  //   return rankings.ACRONYM
  // }

  // // will return a number between rankings.MATCHES and
  // // rankings.MATCHES + 1 depending  on how close of a match it is.
  // return getClosenessRanking(testString, stringToRank)

  return Rankings.NO_MATCH
}

function indexOfWord(testString:string, word:string) {
  const start = testString.indexOf(word)
  const end = start + word.length

  if (start === -1) {
    return -1
  }
  if (start > 0 && isAlphaNumeric(testString.charCodeAt(start-1))) {
    return -1
  }
  if (end < testString.length && isAlphaNumeric(testString.charCodeAt(end))) {
    return -1
  }

  return start
}

function isAlphaNumeric(cc:number) {
  return (cc > 47 && cc < 58) ||    // 0-9
         (cc > 64 && cc < 91) ||    // A-Z
         (cc > 96 && cc < 123)      // a-z
}

export function sortRankedItems<T extends HasKey>(rankedItems:Map<string,RankedItem<T>>) {
  return Array.from(rankedItems.values()).sort(sortRankedItem)
}

function sortRankedItem<T extends HasKey>(a: RankedItem<T>, b: RankedItem<T>) : number {
  if (a.rank === b.rank) {
    if (a.words === b.words) {
      // use the base sort function as a tie-breaker
      return String(a.value).localeCompare(String(b.value))
    } 
    return (a.words < b.words) ? -1 : 1
  }
  return (a.rank > b.rank) ? -1 : 1
}
