import { Area } from "recharts"
import { Logger } from "../../../core/utils/Logger"
import { usePivotTables } from "../../../hooks/pivot/usePivotTables"
import { CartesianChart, CartesianChartProps } from "../CartesianChart"
import { useChartProfile } from "../useChartProfile"
import { useGradientOffset } from "../useGradient"

const logger = new Logger("charts.AccountBalanceAreaChart")

export function AccountBalanceAreaChart(props:Partial<CartesianChartProps>) {
  const { name, pivotTable:{ rows } } = usePivotTables()
  const { animationDuration } = useChartProfile()
  const { title, units, noHeader, onClickXAxis } = props

  const data:any[] = []
  for (const row of rows) {
    if (row.endDate > 0) {
      const dp = { 
        date: row.endDate, 
        Cashflow: row.total,
        Balance: row.balance
      }
      data.push(dp)
    }
  }

  logger.setContext(name).debug("Rendering: title=%s, %d data points", title, data.length)

  // Get statistics
  // const stats = calcStddev(data.map(dp => dp.Balance))
  // const { min, max, sum, n, avg, stddev } = stats

  // let upper = avg + stddev*2
  // let lower = avg - stddev*2

  // for (const dp of data) {
  //   let value = dp.Balance
  //   if (value > upper) {
  //     dp.Balance = upper
  //   } else if (value < lower) {
  //     dp.Balance = lower
  //   }
  // }

  // logger.debug("stats:", stats)

  const { linearGradient, gradientFillUrl, gradientLineUrl } = useGradientOffset(data, "Balance")

  return (
    <div id="AccountBalanceAreaChart" className="chart">
      {!noHeader &&
        <h3>Account Balance</h3>
      }
      <CartesianChart {...props} data={data} units={units || "1M"} noLegend
                      onClickXAxis={onClickXAxis}>
        <defs>{ linearGradient }</defs>
        <Area type="monotone" 
              dataKey="Balance" 
              strokeWidth={2} 
              stroke={gradientLineUrl} 
              fill={gradientFillUrl} 
              animationDuration={animationDuration} />
      </CartesianChart>
    </div>
  )
}
