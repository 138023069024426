import { useParams } from "react-router-dom"
import { ModelKeys } from "../../core/model/ModelKeys"
import { Logger } from "../../core/utils/Logger"
import { useModel } from "../../hooks/context/ModelContext"
import { ViewContextProvider } from "../../hooks/context/ViewContext"
import { FormSelector } from "../forms/FormSelector"
import { JemstoneSidebar } from "../layouts/JemstoneSidebar"
import { ViewHeader } from "./ViewHeader"

const logger = new Logger("view.ItemView")

export function ItemView(props:any) {
  const { model } = useModel()
  const { key } = useParams<any>()

  const rootKey = key || ModelKeys.root
  const rootItem = model.getItem(rootKey)

  if (!model.has(rootKey)) {
    return <>Loading...</>
  }
  
  logger.debug("Rendering: rootKey=%s, rootItem=%o", rootKey, rootItem)

  return (
    <ViewContextProvider>
      <ViewHeader rootKey={rootKey} />
      <JemstoneSidebar/>
      
      <main>
        <div id="ItemView" className="grid">
          <FormSelector item={rootItem} onClose={() => {}} />
        </div>
      </main>
    </ViewContextProvider>
  )
}
