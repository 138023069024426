import { Logger } from "../../../core/utils/Logger"
import { useFilterContext } from "../../../hooks/context/FilterContext"
import { useInvestmentModel } from "../../../hooks/context/InvestmentModelContext"
import { ButtonClose } from "../../controls/buttons/ButtonClose"
import { Field, FieldSet, Form, FormBody } from "../../controls/form/Form"
import { ButtonBalance, ButtonCumTotal, PivotTypeButtons } from "../cashflow/CashflowPivotTableMenu"
import { DateRangeEditor } from "./DateRangeEditor"
import { DimensionMenu } from "./DimensionMenu"
import { DurationMenu } from "./DurationMenu"
import { FromToDateEditor } from "./FromToDateEditor"
import { IntervalMenu } from "./IntervalMenu"

export const logger = new Logger("cashflow.CashflowFilterCard")

export function CashflowFilterCard(props: any) {
  const { onClose } = props
  const { name } = useInvestmentModel()
  const { filter } = useFilterContext()

  logger.debug("Rendering: filter=%s", filter.id)

  return (
    <Form id="CashflowDateFilterCard">
      <header>
        <menu>
          <ButtonClose onClose={onClose}/>
        </menu>
        <h2>{name} Filter</h2>
        <Menu />
      </header>

      <FormBody noJson>
        <FieldSet className="dimension">
          <Field>
            <label>Columns</label>
            <DimensionMenu segmented />
          </Field>

          <Field>
            <label>Values</label>
            <menu className="segment">
              <ButtonBalance />
              <ButtonCumTotal />
            </menu>
          </Field>

          <Field>
            <label>Cashflow</label>
            <menu className="segment">
              <PivotTypeButtons/>
            </menu>
          </Field>
        </FieldSet>

        <DateRangeEditor />

        <FieldSet>
          <Field>
            <label>Duration</label>
            <DurationMenu units={[ "1Y", "2Y", "3Y", "5Y", "10Y", "20Y", "30Y" ]} />
          </Field>
          <Field>
            <label>Interval</label>
            <IntervalMenu segmented />
          </Field>
        </FieldSet>

        <FromToDateEditor />
{/* 
        <FieldSet className="date-selection">
          <label>Quick select non-contiguous dates <ButtonOpen openAtom={openAtom} /></label>
          <Expandable openAtom={openAtom}>
            <MultiDateSelectionMenu />
          </Expandable>
        </FieldSet> */}
      </FormBody>
    </Form>
  )

  function Menu(props: any) {
    const { filterStack, canPop } = useFilterContext()

    return (
      <menu>
        <button disabled={!canPop} onClick={() => filterStack.pop()} title="Pop Filter">
          <i className="fal fa-angle-left fs-2"></i>
        </button>
        <button disabled={!canPop} onClick={() => filterStack.clear()} title="Clear Filters">
          <i className="fal fa-filter-slash"></i>
        </button>
      </menu>
    )
  }
}
