import { PolarAngleAxis, RadialBar, RadialBarChart } from 'recharts'
import { PivotType } from '../../../core/invest/BudgetActualModel'
import { BudgetModel } from '../../../core/invest/BudgetModel'
import { ModelKeys } from "../../../core/model/ModelKeys"
import { Item } from '../../../core/types/Item'
import { Logger } from '../../../core/utils/Logger'
import { NumberFormats, formatNumber } from '../../../core/utils/Numbers'
import { TimeScale } from '../../../core/utils/TimeScale'
import { useFilterContext } from '../../../hooks/context/FilterContext'
import { useInvestmentModel } from '../../../hooks/context/InvestmentModelContext'
import { useModel } from '../../../hooks/context/ModelContext'
import { usePivotType } from '../../../hooks/pivot/usePivotType'
import { ChartHeader } from "../ChartHeader"
import { useChartProfile } from '../useChartProfile'
import { useBudgetActualPivotRows } from './useBudgetActualPivotRows'

const logger = new Logger("charts.BudgetActualRadialCharts")

export function BudgetActualRadialCharts(props:any) {
  const { model } = useModel()
  const { filter, filterStack, filterItem, isCategory } = useFilterContext()
  const { actualRow, budgetRow, actualTotal, budgetTotal, scale } = useBudgetActualPivotRows()

  const category = (isCategory && filterItem) ? filterItem : model.getItem(ModelKeys.category.root)

  const categories = model.childrenSorted(category.key)
                          .filter(child => actualRow.cell(child.key).total !== 0)

  logger.debug("Rendering: filter=%s, scale={%s}", filter.id, Logger.Scale(budgetRow.scale))

  return (
    <div id="BudgetActualRadialCharts" className="chart">
      <RadialChartHeader {...{ category, actualTotal, budgetTotal, scale }}/>

      { categories.length > 1 &&
        <div className="chart-body">
          { categories.map(category => 
            <RadialButton category={category} label={true} key={category.key}
                          actualValue={actualRow.cell(category.key).total}
                          budgetValue={budgetRow.cell(category.key).total}
                          onClick={() => filterStack.push({ columnItem:category, dimension:"Category" })} />
          )}
        </div>
      }
    </div>
  )
}

function RadialChartHeader({ category, actualTotal, budgetTotal, scale }:{
  category: Item
  actualTotal: number
  budgetTotal: number
  scale: TimeScale
}) {
  const investmentModel = useInvestmentModel<BudgetModel>()
  const { model } = useModel()
  const { colors } = useChartProfile()
  const { pivotType, setPivotType } = usePivotType()

  const color = (actualTotal < 0 || budgetTotal < 0) ? colors.orange[8] : colors.blue[4]

  return (
    <header>
      <ChartHeader scale={scale} />
      <RadialButton category={category} 
                    actualValue={actualTotal}
                    budgetValue={budgetTotal}
                    circleSize={40} 
                    outerRadius={19}
                    innerRadius={16}
                    iconClass={model.getItemIcon(category)}
      />
      <h3 className="subtitle">
        { model.getItemName(category) }
      </h3>

      <div className="totals">
        <button className="label" style={{color:color}} onClick={onTogglePivotType}>
          {pivotType + ":"}
        </button>
        <div className="total" style={{color:color}}>{ formatValue(actualTotal) }</div>

        <div className="label">Budget:</div>
        <div className="total">{ formatValue(budgetTotal) }</div>
      </div>
    </header>
  )

  function onTogglePivotType() {
    const type:PivotType = (pivotType === "Forecast") ? "Actual" : "Forecast"
    investmentModel.pivotType = type
    setPivotType(type)
  }
}

function RadialButton(props:{
  category: Item
  label?: boolean
  actualValue: number
  budgetValue: number
  budgetTotal?: number
  circleSize?: number
  outerRadius?: number
  innerRadius?: number
  iconClass?: string
  color?: string
  onClick?: () => void
}) {
  const { model } = useModel()
  const { colors, margin0, size, animationDuration } = useChartProfile()
  const { category, label, actualValue, budgetValue, budgetTotal, onClick } = props

  const { chartData, overun, ratio, delta, color } = getChartData(actualValue, budgetValue)

  logger.trace("RadialButton: category=%s, chartData=%o", category.name, chartData)

  const circleSize  = props.circleSize  || 80
  const outerRadius = props.outerRadius || 34
  const innerRadius = props.innerRadius || (outerRadius - (overun ? 5 : 3))
  
  const iconClass = props.iconClass || model.getItemIcon(category)

  const iconStyle:any = {
    position: "absolute",
    top: circleSize/4,
    height: circleSize,
    width: circleSize,
    fontSize: outerRadius + "px",
  }

  const iconColor:any = {
    color: color,
    opacity: Math.max(ratio, 0.5)
  }

  return (
    <div className="radial-button">
      <button onClick={onClick} 
              title={overun ? `Budget Overun: ${formatValue(delta)}` : ""}>
        <RadialBarChart
          height={circleSize}
          width={circleSize}
          cx="50%"
          cy="50%"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          barSize={5}
          data={chartData}
          startAngle={90}
          endAngle={-270}
          margin={margin0}
        >
          <PolarAngleAxis
            type="number"
            domain={[0, Math.abs(budgetValue)]}
            angleAxisId={0}
            tick={false}
          />
          <RadialBar
            background
            dataKey="value"
            cornerRadius={size.radiusPie}
            fill={color}
            animationDuration={animationDuration}
          />
        </RadialBarChart>

        <div style={iconStyle}>
          <i className={iconClass} style={iconColor} />
        </div>
      </button>

      { label && 
        <div>
          <div>{category.name}</div>
          <div style={{color:color}}>{ formatValue(actualValue) }</div>
          { (budgetTotal !== 0) &&
            <div>{formatValue(budgetValue)}</div>
          }
        </div>
      }
    </div>
  )

  function getChartData(actual:number, budget:number) {
    // Color of the bar
    const color = (actual < 0 || budget < 0) ? colors.orange[8] : colors.blue[4]

    // Have actuals overun the budget?
    let overun=0, ratio=0, delta=0 
    if (budgetTotal !== 0) {
      delta = Math.abs(actual) - Math.abs(budget)
      ratio = (budget === 0 ? 1 : actual/budget)
      overun = (ratio > 1.05 || (budget === 0 && delta > 500)) ? delta : 0
    }

    // Add items for each arc
    const chartData:any[] = []
    if (overun) {
      chartData.push({ name: "Overun", value: overun })
    }
    chartData.push({ name: "Actual", value: Math.abs(actual) })

    // Results
    return { chartData, overun, ratio, delta, color }
  }
}

function formatValue(value:any) {
  return formatNumber(value, NumberFormats.currency0)
}
