import { Type } from "../../core/model/ModelKeys"
import { Logger } from "../../core/utils/Logger"
import { clzName } from "../../core/utils/Utils"
import { useFormItem } from "../../hooks/context/FormContext"
import { useModel } from "../../hooks/context/ModelContext"
import { Card, CardSection } from "../controls/Card"
import { FormSelector } from "../forms/FormSelector"
import { WizardFooter } from "./WizardFooter"
import { WizardHeader } from "./WizardHeader"

const logger = new Logger("wizards.WizardFormSelector")

export function WizardFormSelector({ formItemKey } : { formItemKey:string }) {
  const { model } = useModel()
  const [ item ] = useFormItem(formItemKey)

  const itemType = model.getItem(item?.typeKey)
  const typeName = clzName(itemType?.name, { 
    "Account": model.isAccount(itemType),
    "File":    Type.isFile(item),
    "Folder":  Type.isFolder(item),
  })

  const title = `Provide details for your new ${typeName}`
  const subtitle = ""

  logger.debug("Rendering: %s=%o", formItemKey, item)

  return (
    <Card id={formItemKey} className="wizard-page" open>
      <WizardHeader title={title} subtitle={subtitle} />
      <CardSection>
        { item &&
          <FormSelector item={item} formItemKey={formItemKey} onClose={onClose} noHeader open={false} />
        }
      </CardSection>
      <WizardFooter showClose />
    </Card>
  )

  function onClose() {
  }
}
