import { InvestmentModel } from "../../../core/invest/InvestmentModel"
import { ValuationModel } from '../../../core/invest/ValuationModel'
import { Item } from "../../../core/types/Item"
import { Logger } from "../../../core/utils/Logger"
import { getYearSpan } from "../../../core/utils/TimeScale"
import { useFilterContext } from "../../../hooks/context/FilterContext"
import { useInvestmentModel } from '../../../hooks/context/InvestmentModelContext'
import { usePivotContent } from "../../../hooks/pivot/usePivotContent"
import { useCardOpen } from "../../../hooks/useCardOpen"
import { Card, CardSection } from "../../controls/Card"
import { CardHeaderWithMenu } from '../CardHeaderWithMenu'
import { AssetPerformance } from './AssetPerformance'

const logger = new Logger("AssetPerformanceCard")

export function AssetPerformanceCard(props: { title?:string, open?:boolean }) {
  const { title, open } = props
  const { name, item } = useInvestmentModel<ValuationModel>()
  const { isOpen } = useCardOpen(logger.name, item.key, open === true)
  const { filter } = useFilterContext()
  usePivotContent(undefined)

  logger.setContext(name).debug("Rendering: filter=%s", filter.id)

  return (
    <Card id={logger.name} className="form" open={isOpen}>
      <CardHeaderWithMenu cardName={logger.name} title={title || "Performance"} showYield />
      <CardSection>
        <AssetPerformance />
      </CardSection>
    </Card>
  )
}

export function getAssetFilter(investmentModel:InvestmentModel<Item>) {
  const { hasProperty, isProperty } = investmentModel

  let scale = {...investmentModel.filterDefault.scale }
  if (hasProperty || isProperty) {
    scale.units = "1Y"
  } else {
    scale = getYearSpan(Date.now(), 10)
    scale.start = 0
    scale.units = "1Y"
  }

  return { scale }
}
