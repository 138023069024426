import { endOfMonth } from "date-fns"
import { formatDate } from '../../../core/utils/DateFormat'

export function DateRange(props:{
  start?: number
  end?: number
  formatStr?: string
}) {
  const { start, end } = props
  const formatStr = props.formatStr || "dd-MMM-yyyy"

  // Handle missing dates
  if (!start && !end) {
    return <>Date</>
  }
  if (start && !end) {
    return <>{formatDate(start, formatStr)}</>
  }
  if (!start && end) {
    return <>{formatDate(end, formatStr)}</>
  }

  const s = start as number
  const e = end as number

  // 
  const d1 = new Date(s)
  const d2 = new Date(e)

  const dy1 = d1.getDate()
  const dy2 = d2.getDate()
  const m1 = d1.getMonth()
  const m2 = d2.getMonth()
  const y1 = d1.getFullYear()
  const y2 = d2.getFullYear()
  // const dm1 = getDaysInMonth(y1, m1)
  // const dm2 = getDaysInMonth(y2, m2)

  if (y1 !== y2) {
    if (m1 === 0 && m2 === 11) {
      return <Range f1="yyyy" f2="yyyy" separator={<> &#8211; </>}/>
    }
    return <Range f1="MMM-yyyy" f2="MMM-yyyy" separator={<> &#8211; </>}/>
  } 
  
  if (m1 === 0 && m2 === 11) {
    return <>{formatDate(s, "yyyy")}</>
  }
  if (m1 !== m2) {
    return <Range f1="MMM-" f2="MMM yyyy"/>
  } 
  
  // Year and month are the same
  if (dy1 !== dy2) {
    if (dy1 === 1 && dy2 === endOfMonth(e).getDate()) {
      return <>{formatDate(s, "MMM-yyyy")}</>
    }
    return <Range f1="MMM d-" f2="d yyyy"/>
  } 
  
  return <>{formatDate(s, formatStr)}</>

  function Range(props:any) {
    const { f1, f2, separator } = props
    return (
      <>
        <span>{formatDate(s,f1)}</span>
        { separator }
        <span>{formatDate(e,f2)}</span>
      </>
    )
  }
}