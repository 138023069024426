import { useSetAtom } from "jotai"
import { useEffect } from "react"
import { Item } from '../../core/types/Item'
import { Logger } from "../../core/utils/Logger"
import { SearchAtoms } from "../../hooks/atoms/SearchAtoms"
import { useModel } from '../../hooks/context/ModelContext'
import { MenuPlacement, MenuPopover } from "../controls/menu/MenuPopover"
import { SearchCard } from "./SearchCard"

const logger = new Logger("search.SearchResults")

interface SearchResultsProps {
  rootKey: string
  selected?: Item
  searchItems?: Item[]
  placeholder?: string
  placement?: MenuPlacement
  offset?: number[]
}

export function SearchResults({ rootKey, placement, offset }: SearchResultsProps) {
  const { model } = useModel()
  const setSearchRootKey = useSetAtom(SearchAtoms.searchRootKey)
  const setSearchValue = useSetAtom(SearchAtoms.searchValue)

  const rootItem = model.getItem(rootKey)

  useEffect(() => {
    setSearchRootKey(rootItem?.key)
    setSearchValue("")
  // eslint-disable-next-line
  },[])

  logger.debug("Rendering: rootItem='%s'", model.getItemName(rootItem))

  return (
    <MenuPopover buttonImage="fal fa-search"
                 placement={placement || "bottom"}
                 offset={offset || [0,18]}
                 onClose={onClose}>
      <SearchCard rootKey={rootItem?.key}/>
    </MenuPopover>
  )

  function onClose() {
    setSearchValue("")
  }
}
