import { atom, useAtom } from "jotai"
import { useVersionInfo } from "../../../../hooks/useVersionInfo"
import { Card, CardHeader, CardSection } from "../../../controls/Card"
import { ButtonOpen } from "../../../controls/buttons/ButtonOpen"
import { getDeviceInfo } from "./DeviceInfo"

const openAtom = atom(true)

export function DeviceInfoCard(props:any) {
  const [isOpen, setOpen] = useAtom(openAtom)

  return (
    <Card id="DeviceInfoCard" open={isOpen}>
      <CardHeader>
        <i className="fal fa-laptop-mobile" />
        <h2 onClick={() => setOpen(!isOpen)}>
          Device Information
        </h2>
        <menu>
          <ButtonOpen openAtom={openAtom} />
        </menu>
      </CardHeader>
      <CardSection>
        <DeviceInfoTable/>
      </CardSection>
    </Card>
  )
}

export function DeviceInfoTable() {
  const device = getDeviceInfo()
  const { AppInfo, FunctionsInfo: AppFuncInfo } = useVersionInfo()

  return (
    <table className="table form">
      <tbody>
        <tr>
          <th>Application</th>
          <td>{AppInfo.name} {AppInfo.version}</td>
        </tr>
        <tr>
          <th>Cloud Functions</th>
          <td>{AppFuncInfo?.name} {AppFuncInfo?.version}</td>
        </tr>
        <tr>
          <th>Device Type</th>
          <td>{device.deviceType}</td>
        </tr>
        <tr>
          <th>Operating System</th>
          <td>{device.os}</td>
        </tr>
        <tr>
          <th>Browser</th>
          <td>{device.browser}</td>
        </tr>
        <tr>
          <th>Engine</th>
          <td>{device.engine}</td>
        </tr>
        <tr>
          <th>Mobile</th>
          <td>{device.mobile}</td>
        </tr>
        <tr>
          <th>User Agent</th>
          <td className="text-wrap">{device.userAgent}</td>
        </tr>
      </tbody>
    </table>
  )
}