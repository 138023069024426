import { ReactNode, useEffect, useRef } from 'react'
import { Logger } from '../../../core/utils/Logger'
import { DialogFUI } from './DialogFUI'

const logger = new Logger("controls.Dialog")

export interface DialogProps { 
  open: boolean
  modal?: boolean
  className?: string
  children: ReactNode
  onClose?: () => void
}

export function Dialog(props:DialogProps) {
  return <DialogFUI {...props} />
}

/**
 * This is a managed wrapper around the HTMLDialogElement
 */
// eslint-disable-next-line
function DialogHTML(props:DialogProps) {
  const { open, modal, onClose, children } = props
  const dialogRef = useRef<HTMLDialogElement | null>(null)

  logger.debug("DialogHTML: Rendering, open=%s, modal=%s", open, modal)

  useEffect(() => {
    const dialogElement = dialogRef.current
    if (dialogElement) {
      if (!open) {
        dialogElement.close()
      } else if (modal === true) {
        dialogElement.showModal()
      } else {
        dialogElement.show()
      }
    }
  },[open,modal])

  return (
    <dialog ref={dialogRef} onClose={onCloseDialog}>
      { children }
    </dialog>
  )

  function onCloseDialog(e:any) {
    logger.debug("onCloseDialog called")

    const dialogElement = dialogRef.current
    if (dialogElement) {
      dialogElement.close()
    }
    if (onClose) {
      onClose()
    }
  }
}

/**
 * This is a wrapper around the Modal reactstrap component
 */
// function DialogModal(props:DialogProps) {
//   const { open, onClose, children } = props

//   logger.debug("DialogModal: Rendering, open=%s", open)

//   return (
//     <Modal
//       isOpen={open}
//       backdrop={true}
//       keyboard={true}
//       // toggle={onClosed}
//       onClosed={onClosed}
//     >
//       { children }
//     </Modal>
//   )

//   function onClosed() {
//     logger.debug("DialogModal.onClosed: open=%s", open)
//     if (onClose) {
//       onClose()
//     }
//   }
// }