import { useEffect, useRef } from 'react'
import { Logger } from "../../core/utils/Logger"
import { clzName } from '../../core/utils/Utils'
import { SearchAtoms } from '../../hooks/atoms/SearchAtoms'
import { Item } from '../../core/types/Item'

const logger = new Logger("control.ItemSearchBox")

export function ItemSearchBox(props:{
  searchItems: Item[]
  onSearchResults: (results:Item[], searchValue:string) => void
  placeholder?: string
  setfocus: boolean
  className?: string
}) {
  const { searchItems, onSearchResults, placeholder, setfocus } = props
  const className = clzName("editable", props.className)

  const inputRef = useRef<any>()

  let timeoutId:any = null
  let timeoutPeriod = 200

  logger.debug("Rendering: %d searchItems", searchItems.length)
  
  useEffect(() => {
    if (setfocus) {
      inputRef.current.focus()
    }
  // eslint-disable-next-line
  },[])

  return (
    <div className="search">
      <i className="fal fa-search"/>
      <input type="search"
            onChange={onChange}
            className={className}
            placeholder={placeholder || "Search"}
            ref={inputRef} />
    </div>
  )

  function onChange(e:any) {
    // Set timeout for view refresh
    if (timeoutId !== null) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => onWakeup(e.target.value), timeoutPeriod)
  }
  
  function onWakeup(searchValue:string) {
    const start = Date.now()
    timeoutId = null

    searchValue = searchValue.trim()
    const results = SearchAtoms.matchItems(searchItems, searchValue)

    logger.debug("onWakeup finished in %d ms: Searched %d items for '%s': %d found", 
                  Date.now()-start, searchItems.length, searchValue, results.length)
    
    if (results.length > 1000) {
      const totalHits = results.length
      results.length = 1000
      logger.debug("onWakeup: Truncating results to %d/%d items", results.length, totalHits)
    }

    if (onSearchResults) {
      onSearchResults(results, searchValue)
    }
  }
}
