import { CashflowBuilder } from '../../core/builder/CashflowBuilder'
import { PivotTable } from '../../core/builder/PivotTable'
import { Filter } from '../../core/filter/Filter'
import { BudgetActualModel, PivotType } from '../../core/invest/BudgetActualModel'
import { InvestmentModel } from '../../core/invest/InvestmentModel'
import { Model } from '../../core/model/Model'
import { Item } from '../../core/types/Item'
import { Logger } from '../../core/utils/Logger'
import { getDateFormat } from '../../core/utils/TimeScale'
import { getUniqueId } from '../../core/utils/Utils'

export class InvestmentModelPivotTables {
  private readonly logger = new Logger("models.InvestmentModelPivotTables")

  private _netPivot?: PivotTable
  private _budgetPivot?: PivotTable
  private _actualPivot?: PivotTable
  private _dateFormat?: string

  public readonly investmentModel: InvestmentModel<Item>
  public readonly filter: Filter

  constructor(investmentModel: InvestmentModel<Item>, filter: Filter) {
    this.investmentModel = investmentModel
    this.filter = filter

    this.logger.setContext(investmentModel.name).debug("Created with filter=%s", this.filter.id)
  }

  get name() : string {
    return this.investmentModel.name
  }

  get model() : Model {
    return this.investmentModel.model
  }

  get pivotTable(): PivotTable {
    const pivotType = (this.investmentModel as BudgetActualModel<Item>).pivotType
    if (!pivotType) {
      return this.netPivot
    }

    const pivotTable = (pivotType === "Actual") ? this.actualPivot :
                       (pivotType === "Budget") ? this.budgetPivot : this.netPivot

    // this.logger.debug("pivotTable: pivotType=%s, pivotTable.pivotType=%s", pivotType, pivotTable.pivotType)

    return pivotTable
  }

  get budgetPivot(): PivotTable {
    if (!this._budgetPivot) {
      const budgetCashflow = (this.investmentModel as BudgetActualModel<Item>).budgetCashflow
      if (!budgetCashflow) {
        return this.netPivot
      }
  
      this._budgetPivot = this.createPivotTable(budgetCashflow, this.filter, "Budget")
    }
    return this._budgetPivot
  }

  get actualPivot(): PivotTable {
    if (!this._actualPivot) {
      const actualCashflow = (this.investmentModel as BudgetActualModel<Item>).actualCashflow
      if (!actualCashflow) {
        return this.netPivot
      }
  
      this._actualPivot = this.createPivotTable(actualCashflow, this.filter, "Actual")
    }
    return this._actualPivot
  }

  get netPivot(): PivotTable {
    if (!this._netPivot) {
      this._netPivot = this.createPivotTable(this.investmentModel.cashflow, this.filter, "Forecast")
    }
    return this._netPivot
  }

  get dateFormat(): string {
    if (!this._dateFormat) {
      this._dateFormat = getDateFormat(this.filter.scale)
    }
    return this._dateFormat
  }

  private createPivotTable(cashflow: CashflowBuilder, filter: Filter, pivotType: PivotType): PivotTable {
    const method = `createPivotTable::${pivotType}`
    this.logger.start(method, "Filtering %d cashflow items, filter=%s", cashflow.length, filter.id)

    const { model, item } = this.investmentModel

    const scale = { units: filter.scale.units, start: 0, end: 0 }
    const cashflowFilter = { ...filter, id: getUniqueId(), scale }
    const cashflowItems = cashflow ? cashflow.filterBy(cashflowFilter) : []

    const pivotTable = new PivotTable(model, item, filter, pivotType).build(cashflowItems)

    const first = cashflowItems.at(0)?.date
    const last = cashflowItems.at(-1)?.date

    this.logger.finish(method, "Pivoted %d/%d cashflow items => %d rows, range={%s}, filter=%s",
                       cashflowItems.length, cashflow.length, pivotTable.allRows.length, Logger.Range(first,last), filter.id)

    return pivotTable
  }
}
