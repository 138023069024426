import { Asset, ModelKeys } from "../../../core/model/ModelKeys"
import { AccountItem } from "../../../core/types/AccountItem"
import { Item } from "../../../core/types/Item"
import { Logger } from "../../../core/utils/Logger"
import { clzName } from "../../../core/utils/Utils"
import { useFormItem } from "../../../hooks/context/FormContext"
import { useModel } from "../../../hooks/context/ModelContext"
import { Card, CardSection } from "../../controls/Card"
import { FormDataJson } from "../../forms/FormDataJson"
import { useWizard } from "../context/WizardContext"
import { WizardHeader } from "../WizardHeader"
import { WizardMenuListItem } from "../WizardMenu"

const logger = new Logger("wizards.SelectAccountParent")

export function SelectAccountParent() {   
  const { model } = useModel()
  const { wizard, primaryItemProp } = useWizard()
  const [ accountItem, setAccountItem ] = useFormItem<AccountItem>(primaryItemProp)

  const accountType = model.getItem(accountItem?.typeKey)
  const typeName = clzName(accountType?.name, { "Account": model.isAccount(accountType) })

  const title = `Select a location for your new ${typeName}`
  const subtitle = `Choose from the list below where you would like to create your new ${typeName}.`

  logger.debug("Rendering: accountType=%o", accountType)

  return (
    <Card id="SelectAccountParent" className="wizard-page">
      <WizardHeader title={title} subtitle={subtitle} />
      <CardSection className="wizard-menu">
        { getPotentialParentItems().map(item => 
          <WizardMenuListItem 
            icon={model.getItemIcon(item)}
            title={model.getItemName(item)}
            subtitle={model.getItemDescription(item)}
            active={item.key === accountItem?.parentKey}
            onClick={(e:any) => onSelectParent(item)}
            key={item.key} />
        )}
      </CardSection>
      <FormDataJson/>
    </Card>
  )

  function onSelectParent(parent:Item) {
    const parentKey = parent.key
    const sortOrder = model.newSortOrder(parent)
    accountItem && setAccountItem({...accountItem, parentKey, sortOrder})

    // Move to next page
    wizard.next()
  }

  function getPotentialParentItems() : Item[] {
    const accountTypeKey = accountItem?.typeKey
    const portfolios = model.childrenDeep(ModelKeys.root, (item => Asset.isPortfolio(item)))

    // Valuations must have an asset as a parent
    if (accountTypeKey === ModelKeys.type.valuation) {
      const assetTypeKeys = model.childrenKeysDeep(ModelKeys.asset.root)
      const parentItems = getChildrenOfType(portfolios, assetTypeKeys)
      return model.sortByName(parentItems)
    }

    // Buildings must have a property as a parent
    if (accountTypeKey === ModelKeys.type.building) {
      const typeKey = ModelKeys.asset.property
      const propertyKeys = model.childrenKeysDeep(typeKey).add(typeKey)
      const propertyItems = getChildrenOfType(portfolios, propertyKeys)
      return model.sortByName(propertyItems)
    }

    // Loans must have a property as a parent
    if (accountTypeKey === ModelKeys.account.loan) {
      const propertyKeys = new Set([ModelKeys.asset.property])
      const propertyItems = model.sortByName(getChildrenOfType(portfolios, propertyKeys))

      // logger.debug("getPotentialParentItems: Searching portfolios=%o for types=%o, found propertyItems=%o", 
      //               portfolios, propertyKeys, propertyItems.map(item => item.name))

      return [...portfolios, ...propertyItems]
    }

    return portfolios
  }

  function getChildrenOfType(items:Item[], typeKeys:Set<string>) {
    const children:Item[] = []
    for (const child of items) {
      model.childrenDeep(child.key, (item => typeKeys.has(item.typeKey)), children)
    }
    return children
  }
}
