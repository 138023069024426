import { Area } from "recharts"
import { useInvestmentModel } from "../../../hooks/context/InvestmentModelContext"
import { Logger } from "../../../core/utils/Logger"
import { CartesianChart } from "../CartesianChart"
import { useChartProfile } from "../useChartProfile"
import { useGradientRedGreen } from "../useGradient"
import { useLoanChartData } from "./LoanCashflowChart"

const logger = new Logger("charts.LoanBalanceChart")

export function LoanBalanceChart(props: any) {
  const { item, name } = useInvestmentModel()
  const { chartData } = useLoanChartData()
  const { size } = useChartProfile()

  const { linearGradient, gradientRedUrl } = useGradientRedGreen(true)

  logger.setContext(name).debug("Rendering %d data points", chartData.length)

  return (
    <div id="LoanBalanceChart" className="chart">
      <h3>Loan Balance</h3>
      <CartesianChart data={chartData} syncId={item.key} height={size.height} noLegend>
        <defs>{linearGradient}</defs>
        <Area type="monotone" dataKey="Balance" stackId="1" fill={gradientRedUrl} stroke={gradientRedUrl} strokeWidth={2} />
      </CartesianChart>
    </div>
  )
}
