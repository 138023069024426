import { Duration, add, addDays, differenceInCalendarDays, intervalToDuration, startOfDay, sub } from "date-fns"
import { LoanItem } from "../types/LoanItem"
import { formatDate, formatDurationSafe } from '../utils/DateFormat'
import { Logger } from "../utils/Logger"
import { round } from '../utils/Numbers'
import { isEqual } from "../utils/Utils"

const logger = new Logger("models.LoanAmortization")

export class LoanAmortization {
  private item: LoanItem

  constructor(item: LoanItem) {
    logger.debug("Creating new LoanAmortization object")

    this.item = item
    this.item.paymentFrequency ??= { weeks:2 }

    this.calcDates(item.startDate, item.finishDate, item.term)
    this.calcPayment()
  }

  get loan() {
    return this.item
  }

  set loan(item: LoanItem) {
    this.item = item
    this.calcDates(item.startDate, item.finishDate, item.term)
    this.calcPayment()
  }

  get principal() {
    return this.item.principal
  }

  set principal(principal:number) {
    this.item.principal = principal
    this.calcPayment()
  }

  get rate() {
    return this.item.rate
  }

  set rate(rate:number) {
    logger.debug("Setting rate=%f", rate)
    this.item.rate = rate
    this.calcPayment()
  }

  get term() {
    return this.item.term
  }

  set term(term:Duration) {
    logger.debug("Setting term=%o", term)
    
    const finishDate = this.item.startDate ? undefined : this.item.finishDate

    this.calcDates(this.item.startDate, finishDate, term)
    this.calcPayment()
  }

  get startDate() {
    return this.item.startDate
  }

  set startDate(startDate:number) {
    logger.debug("Setting startDate=%s", formatDate(startDate))

    const finishDate = this.item.term ? undefined : this.item.finishDate

    this.calcDates(startDate, finishDate, this.item.term)
    this.calcPayment()
  }

  get finishDate() {
    return this.item.finishDate
  }

  set finishDate(finishDate:number) {
    logger.debug("Setting finishDate=%s", formatDate(finishDate))

    const term = this.item.startDate ? undefined : this.item.term

    this.calcDates(this.item.startDate, finishDate, term)
    this.calcPayment()
  }

  get paymentFrequency() {
    return this.item.paymentFrequency
  }

  set paymentFrequency(paymentFrequency:Duration) {
    logger.debug("Setting paymentFrequency=%o", paymentFrequency)

    this.item.paymentFrequency = paymentFrequency
    this.calcPayment()
  }

  get paymentAmount() {
    return this.item.paymentAmount
  }

  set paymentAmount(paymentAmount:number) {
    logger.debug("Setting paymentAmount=%f", paymentAmount)

    this.item.paymentAmount = paymentAmount
    this.calcTerm()
  }

  private calcDates(startDate:number|undefined, finishDate:number|undefined, term:Duration|undefined) {
    // We have a start date - if term is set then calculate finishDate, if not and we have a finishDate
    // then calculate the term
    if (startDate) {
      this.item.startDate = startOfDay(startDate).getTime()

      if (term) {
        this.item.term = term
        this.item.finishDate = startOfDay(add(startDate, term)).getTime()
      }

      else if (finishDate) {
        this.item.finishDate = startOfDay(finishDate).getTime()
        this.item.term = intervalToDuration({ start: startDate, end: this.item.finishDate })
      }
    }

    // We have a term - if finishDate is set then calculate the startDate
    else if (term) {
      this.item.term = term
      
      if (finishDate) {
        this.item.finishDate = startOfDay(finishDate).getTime()
        this.item.startDate = startOfDay(sub(finishDate, term)).getTime()
      }
    } 
    
    // We have a finishDate but no startDate and no term - assume a 20 year term
    else if (finishDate) {
      this.item.finishDate = startOfDay(finishDate).getTime()
      this.item.term = { years:20 }
      this.item.startDate = startOfDay(sub(finishDate, this.item.term)).getTime()
    }

    // All fields are undefined, so set some defaults
    else {
      this.item.startDate = startOfDay(Date.now()).getTime()
      this.item.term = { years:20 }
      this.item.finishDate = startOfDay(add(this.item.startDate, this.item.term)).getTime()
    }

    logger.debug("calcDate: start=%s, finish=%s, term=%o", 
                  formatDate(this.item.startDate), formatDate(this.item.finishDate), this.item.term)
  }

  get daysPerPeriod() {
    return differenceInCalendarDays(add(0, this.paymentFrequency), 0)
  }

  /**
   * Calculate the number of payments in the term i.e. from startDate to finishDate
   */
  get numPayments() {
    const startDate = this.startDate
    const finishDate = this.finishDate
    const paymentFrequency = this.paymentFrequency

    // Count number of periods in the term
    let numPayments = 0
    for (let date = startDate; date <= finishDate; ) {
      numPayments++
      date = add(date, paymentFrequency).getTime()
    }

    return numPayments
  }

  /**
   * Calculate the principal (A) given the payment, interest rate, number of payments and payment frequency.
   * The new principal is set to this.item.principal
   * 
   * @returns the principal
   */
  public calcPrincipal() {
    const daysPerPeriod = this.daysPerPeriod

    const P = this.paymentAmount
    const I = this.rate
    const N = this.numPayments
    const R = 1 + (I/365 * daysPerPeriod)

    const A = P * (1 - Math.pow(R, -N)) / (R - 1)

    logger.debug("calcPrincipal: A=%f P=%f I=%f R=%f N=%f, days=%f", A, P, I, R, N, daysPerPeriod)

    this.item.principal = A
    return A
  }

  /**
   * Calculate the payment amount and number of payments from the principal, interest rate, term, 
   * startDate, and finishDate.
   * The new payment is set to this.item.paymentAmount
   * 
   * @returns paymentAmount
   */
  public calcPayment() {
    const daysPerPeriod = this.daysPerPeriod

    const A = this.principal
    const I = this.rate
    const N = this.numPayments
    const R = 1 + (I/365 * daysPerPeriod)

    const P = A * (R - 1) / (1 - Math.pow(R, -N))

    logger.debug("calcPayment: A=%f P=%f I=%f R=%f N=%f, days=%f", A, P, I, R, N, daysPerPeriod)

    this.item.paymentAmount = P
    return P
  }

  /**
   * Calculate the term (N) given principal, payment, interest rate, and payment frequency.
   * The new term is set to this.item.term
   * 
   * P = A * (R - 1) / (1 - Math.pow(R, -N))
   * =>  A * (R - 1) / P = 1 - Math.pow(R, -N)
   * =>  Math.pow(R, -N) = 1 - A * (R - 1) / P
   * => -N * Math.log(R) = Math.log(1 - A * (R - 1) / P)
   * =>  N = -Math.log(1 - A * (R - 1) / P) / Math.log(R)
   * 
   * @returns the term
   */
  public calcTerm() {
    const daysPerPeriod = this.daysPerPeriod

    const A = this.principal
    const P = this.paymentAmount
    const I = this.rate
    const R = 1 + (I/365 * daysPerPeriod)

    const N = -Math.log(1 - A * (R - 1) / P) / Math.log(R)
    
    const days = round(N * daysPerPeriod, 0)
    const term = intervalToDuration({ start:0, end:addDays(0, days) })

    logger.debug("calcTerm: A=%f P=%f I=%f R=%f N=%f, days=%f, term=%o, '%s'", 
                  A, P, I, R, N, daysPerPeriod, term, formatDurationSafe(term))

    // calcDates will update the term, and the affected dates
    if (!isEqual(term, this.item.term)) {
      const finishDate = this.item.startDate ? undefined : this.item.finishDate
      this.calcDates(this.item.startDate, finishDate, term)
    }
    return term
  }

  /**
   * TBC
   */
  public calcInterest() {

  }

  get logItem() {
    return {
      name:             this.item.name,
      description:      this.item.description,
      principal:        this.principal,
      rate:             this.rate,
      term:             this.term,
      paymentFrequency: this.paymentFrequency,
      paymentAmount:    this.paymentAmount,
      startDate:        formatDate(this.startDate,  Logger.DefaultDateFormat),
      finishDate:       formatDate(this.finishDate, Logger.DefaultDateFormat),
    }
  }
}