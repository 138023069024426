import { Model } from "../model/Model"
import { ModelKeys } from "../model/ModelKeys"
import { Logger } from "../utils/Logger"

const logger = new Logger("core.TransferMapper")

/**
 * Implements business rules that ensure integrity between the 2 parts of a
 * Transfer transaction - the TransferIn and the TransferOut.
 * 1. If one leg is of type TransferIn, the other MUST be of type TransferOut
 * 2. If one leg is of category TransferIn, the other MUST be of category TransferOut
 */
export class TransferMapper {
  private model: Model

  /** Maps category to a related category */
  private categoryToContra = new Map<string, string>()

  /** Maps type to a related type */
  private typeToContra = new Map<string, string>()

  /** Maps category to transferIn or transferOut */
  private categoryToType = new Map<string, string>()

  /** Maps category to transferIn or transferOut */
  private categoryToTransfer = new Map<string, string>()

  /** Maps type to transferIn or transferOut */
  private typeToTransfer = new Map<string, string>()

  /** Maps type to transferIn or transferOut */
  private typeToCategory = new Map<string, string>()

  constructor(model: Model) {
    this.model = model

    this.categoryToContra.set(ModelKeys.category.transferIn, ModelKeys.category.transferOut)
    this.categoryToContra.set(ModelKeys.category.transferOut, ModelKeys.category.transferIn)

    this.typeToContra.set(ModelKeys.transaction.transferIn, ModelKeys.transaction.transferOut)
    this.typeToContra.set(ModelKeys.transaction.transferOut, ModelKeys.transaction.transferIn)
    this.typeToContra.set(ModelKeys.transaction.moneyIn, ModelKeys.transaction.moneyOut)
    this.typeToContra.set(ModelKeys.transaction.moneyOut, ModelKeys.transaction.moneyIn)

    this.typeToCategory.set(ModelKeys.transaction.transferIn, ModelKeys.category.transferIn)
    this.typeToCategory.set(ModelKeys.transaction.transferOut, ModelKeys.category.transferOut)

    this.categoryToType.set(ModelKeys.category.transferIn, ModelKeys.transaction.transferIn)
    this.categoryToType.set(ModelKeys.category.transferOut, ModelKeys.transaction.transferOut)
    this.categoryToType.set(ModelKeys.category.buy, ModelKeys.transaction.transferIn)
    this.categoryToType.set(ModelKeys.category.sell, ModelKeys.transaction.transferOut)
    this.categoryToType.set(ModelKeys.category.incomeOther, ModelKeys.transaction.moneyIn)
    this.categoryToType.set(ModelKeys.category.expenseOther, ModelKeys.transaction.moneyOut)

    this.categoryToTransfer.set(ModelKeys.category.buy, ModelKeys.category.transferIn)
    this.categoryToTransfer.set(ModelKeys.category.sell, ModelKeys.category.transferOut)
    this.categoryToTransfer.set(ModelKeys.category.withdrawal, ModelKeys.category.transferIn)
    this.categoryToTransfer.set(ModelKeys.category.deposit, ModelKeys.category.transferOut)

    this.typeToTransfer.set(ModelKeys.transaction.moneyIn, ModelKeys.transaction.transferIn)
    this.typeToTransfer.set(ModelKeys.transaction.moneyOut, ModelKeys.transaction.transferOut)

    //
    logger.trace("Created TransferMapper")
  }

  public getSafeCategory(oldCategoryKey: string | undefined, oldTypeKey:string | undefined, newCategoryKey: string) {
    // Default is to force the typeKey to match the category
    const category = this.model.getItem(newCategoryKey)
    let categoryKey = category.key
    let typeKey = category.typeKey

    // If the transaction was a transfer, and is now a Buy/Sell, ensure the catego
    if ((this.isBuySell(categoryKey) || this.isRepayment(categoryKey)) && this.isTransfer(oldTypeKey)) {
      typeKey = this.typeToTransfer.get(typeKey) ?? typeKey
    }

    // Debug
    logger.debug("getSafeCategory: {category=%s, type=%s} => {category=%s, type=%s}",
      this.model.getItemName(oldCategoryKey), this.model.getItemName(oldTypeKey),
      this.model.getItemName(categoryKey), this.model.getItemName(typeKey))

    return { categoryKey, typeKey }
  }

  public getSafeCategoryForValue(categoryKey: string | undefined, oldTypeKey:string | undefined, value:number) {
    // Ensure transfers are consistent with sign of the value
    if (categoryKey === ModelKeys.category.transferIn && value < 0) {
      categoryKey = ModelKeys.category.transferOut
    }
    else if (categoryKey === ModelKeys.category.transferOut && value >= 0) {
      categoryKey = ModelKeys.category.transferIn
    }

    if (!categoryKey) {
      categoryKey = (value >= 0 ? ModelKeys.category.incomeOther : ModelKeys.category.expenseOther)
    }

    // If typeKey can be mapped safely then we are done
    let typeKey = this.categoryToType.get(categoryKey)
    if (typeKey) {
      return { categoryKey, typeKey }
    }

    // Otherwise ensure type is consistent with sign of value
    if (typeKey === ModelKeys.transaction.transferIn && value < 0) {
      typeKey = ModelKeys.transaction.transferOut
    }
    else if (typeKey === ModelKeys.transaction.transferOut && value >= 0) {
      typeKey = ModelKeys.transaction.transferIn
    }
    else {
      typeKey = (value < 0 ? ModelKeys.transaction.moneyOut : ModelKeys.transaction.moneyIn)
    }

    return { categoryKey, typeKey }
  }

  public getSafeCategoryForType(oldCategoryKey: string | undefined, oldTypeKey: string, typeKey: string) {
    // If the new typeKey does not match that for the category the set it to null
    let categoryKey = oldCategoryKey
    if (this.categoryToType.get(categoryKey as string) !== typeKey) {
      categoryKey = this.typeToCategory.get(typeKey) ?? categoryKey
    }

    // Debug
    logger.debug("getSafeCategoryForType: {category=%s, type=%s} => {category=%s, type=%s}",
      this.model.getItemName(oldCategoryKey), this.model.getItemName(oldTypeKey),
      this.model.getItemName(categoryKey), this.model.getItemName(typeKey))

    return { categoryKey, typeKey }
  }

  /**
   * For the specified category and type, return the categoryKey and typeKey for the
   * related (contra) leg of the transfer
   * @param categoryKey
   * @param typeKey
   * @returns
   */
  public getSafeRelatedCategory(categoryKey: string | undefined, typeKey: string) {
    let relatedTypeKey = this.typeToContra.get(typeKey) ?? typeKey
    let relatedCategoryKey = this.categoryToContra.get(categoryKey as string) ?? categoryKey

    // If the transaction was a transfer, and is now a Buy/Sell, ensure we have correct type
    if (this.isBuySell(relatedCategoryKey) && this.isTransfer(typeKey)) {
      relatedCategoryKey = this.categoryToTransfer.get(typeKey) ?? relatedCategoryKey
    }

    // Debug
    logger.debug("getSafeRelatedCategory: {category=%s, type=%s} => {category=%s, type=%s}",
      this.model.getItemName(categoryKey), this.model.getItemName(typeKey),
      this.model.getItemName(relatedCategoryKey), this.model.getItemName(relatedTypeKey))

    return { relatedCategoryKey, relatedTypeKey }
  }

  private isBuySell(categoryKey: string | undefined) {
    return (categoryKey === ModelKeys.category.buy || categoryKey === ModelKeys.category.sell)
  }

  private isTransfer(typeKey: string|undefined) {
    return (typeKey === ModelKeys.transaction.transferIn || typeKey === ModelKeys.transaction.transferOut)
  }

  private isRepayment(categoryKey: string | undefined) {
    return (categoryKey === ModelKeys.category.payment)
  }
}
