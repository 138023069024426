import { useState } from "react"
import { ModelKeys } from "../../../core/model/ModelKeys"
import { Item } from '../../../core/types/Item'
import { TypeItem } from "../../../core/types/TypeItem"
import { Logger } from "../../../core/utils/Logger"
import { useModel } from "../../../hooks/context/ModelContext"
import { MenuPopover, MenuPopoverProps } from "../menu/MenuPopover"

const logger = new Logger("control.AccountPicker")

export interface AccountPickerProps extends MenuPopoverProps {
  item?: Item
  selected?: Item
  readonly?: boolean
  showClear?: boolean
  showAccounts?: boolean
  showAssets?: boolean
  showProperties?: boolean
  onChange: (item:Item) => void
}

export function AccountPicker(props : AccountPickerProps) {
  const { model } = useModel()
  const [ accounts ] = useState(getAccounts())
  const [ assets ] = useState(getAssets())
  const [ properties ] = useState(getProperties())

  const { selected, readonly, label, buttonClass, buttonImage, title, placement, onChange } = props
  const { showClear, showAccounts, showAssets, showProperties } = props

  logger.debug("Rendering: selected=%s", selected?.name)

  if (readonly) {
    return <div className="readonly">{selected?.name}</div>
  }

  return (
    <MenuPopover label={label ?? selected?.name} 
                 placeholder="Select an Account..." 
                 buttonClass={buttonClass}
                 buttonImage={buttonImage ?? "fas fa-caret-down"} 
                 placement={placement ?? "bottom"}
                 title={title}>
      <menu className="popup">
        { showClear &&
          <>
            <MenuItems accounts={[getClearItem()]} />
            <div className="divider"/>
          </>
        }
        { showAccounts && 
          <>
            <MenuItems accounts={accounts} />
            <div className="divider"/>
          </>
        }
        { showAssets && 
          <>
            <MenuItems accounts={assets} />
            <div className="divider"/>
          </>
        }
        { showProperties &&
          <MenuItems accounts={properties} />
        }
      </menu>
    </MenuPopover>
  )

  function MenuItems({ accounts } : { accounts:Item[] }) {
    return (
      <>
        { accounts.map((account:Item) =>
          <button onClick={(e) => onChange(account)} key={account.key}>
            <i className={model.getItemIcon(account)} />
            { account.name }
          </button>
        )}
      </>
    )
  }

  function getClearItem() {
    const clearItem = model.newItem<TypeItem>("")
    clearItem.name = "Clear Account"
    clearItem.code = "DELETE"
    clearItem.typeIcon = "fas fa-times negative"

    return clearItem
  }

  function getAccounts() {
    const accounts = model.sortByName([
      ...model.getItemsByType(ModelKeys.account.checking),
      ...model.getItemsByType(ModelKeys.account.loan),
      ...model.getItemsByType(ModelKeys.account.deposit),
      ...model.getItemsByType(ModelKeys.account.creditcard),
      ...model.getItemsByType(ModelKeys.account.savings),
      ...model.getItemsByType(ModelKeys.account.wallet),
    ])
    
    return accounts
  }

  function getAssets() {
    return model.sortByName([
      ...model.getItemsByType(ModelKeys.asset.investment),
      ...model.getItemsByType(ModelKeys.asset.kiwisaver),
      ...model.getItemsByType(ModelKeys.asset.stock),
    ])
  }

  function getProperties() {
    return model.getItemsByType(ModelKeys.asset.property)
  }
}
