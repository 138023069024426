import { useState } from "react"
import { BarLoader } from "react-spinners"
import { processAccounts, processTransactions } from "../../../core/builder/bankstmt/AkahuBuilder"
import { fetchAccounts, fetchTransactions } from "../../../core/functions/FirebaseFunctions"
import { Logger } from "../../../core/utils/Logger"
import { clzName } from "../../../core/utils/Utils"
import { useModel } from "../../../hooks/context/ModelContext"
import { useChartProfile } from "../../charts/useChartProfile"
import { Card, CardHeader, CardSection } from "../../controls/Card"

const logger = new Logger("settings.DiagnosticTestCard")

export function BankStmtRefreshCard(props:any) {
  const { model } = useModel()

  logger.debug("Rendering:")

  return (
    <Card id="BankStmtRefreshCard" open>
      <CardHeader>
        <i className="fal fa-sync" />
        <h2>
          Refresh Bank Accounts and Transactions
        </h2>
      </CardHeader>
      <CardSection>
        <div className="wizard-menu">
          <LoadAccounts/>
          <LoadTransactions/>
        </div>
      </CardSection>
    </Card>
  )

  function LoadAccounts() {
    const [ loading, setLoading ] = useState(false)
    const [ count, setCount] = useState(0)
    const className = clzName("wizard-menu-item link", { active:loading})

    return (
      <li className={className} onClick={onFetch}>
        <i className="fal fa-function" />
        <h3>Refresh Bank Accounts</h3>
        <div>
          <p>Call the Akahu accounts API to refresh bank account details</p>
          <Spinner loading={loading}/>
          { count > 0 &&
            <em>Loaded {count} accounts from Akahu</em>
          }
        </div>
      </li>
    )
    
    function onFetch() {
      setCount(0)
      setLoading(true)

      fetchAccounts().then(accounts => {
        processAccounts(model, accounts)
        setCount(accounts.length)
        setLoading(false)
      })
    }
  }
  
  function LoadTransactions() {
    const [ loading, setLoading ] = useState(false)
    const [ count, setCount] = useState(0)
    const className = clzName("wizard-menu-item link", { active:loading})

    return (
      <li className={className} onClick={onFetch}>
        <i className="fal fa-function" />
        <h3>Refresh Bank Transactions</h3>
        <div>
          <p>Call the Akahu transactions API to refresh bank transaction details</p>
          <Spinner loading={loading}/>
          { count > 0 &&
            <em>Loaded {count} transactions from Akahu</em>
          }
        </div>
      </li>
    )
    
    function onFetch() {
      // const start = subDays(end, 30).getTime()
      const start = Date.parse("2024-01-01")
      // const start = Date.parse("2024-01-01T00:00:00+13:00") - 1
      // const end = Date.parse("2024-01-01T23:59:59.999Z")
      const end = Date.now()

      setCount(0)
      setLoading(true)

      fetchTransactions(start, end).then(transactions => {
        processTransactions(model, transactions)

        setCount(transactions.length)
        setLoading(false)
      })
    }
  }

  function Spinner({loading} : {loading:boolean}) {
    const { colors } = useChartProfile()

    return (
      <BarLoader width="100%" 
                 height="6px" 
                 color={colors.blue[5]} 
                 cssOverride={{ borderRadius:"3px"}} 
                 loading={loading}/>

    )
  }
}
