import { useAtomValue } from "jotai"
import { ReactNode, createContext, useContext, useMemo } from "react"
import { Logger } from "../../core/utils/Logger"
import { FilterStack, InitFilter } from "../filter/FilterStack"
import { newAtomMap, useAtomMap } from "../useAtomMap"
import { useInvestmentModel } from "./InvestmentModelContext"

const logger = new Logger("hooks.FilterProvider")

const filterStackAtomMap = newAtomMap<FilterStack>()

const FilterContext = createContext<FilterStack | null>(null)

export function useFilterContext() : FilterStack {
  const filterStack = useContext(FilterContext) as FilterStack
  try {
    useAtomValue(filterStack?.filterAtom)
  } catch (e:any) {
    logger.error("useFilterContext", "Unexpected error: %o", e)
  }
  return filterStack
}

/**
 * FilterProvider creates a FilterStack which manages a stack of Filters that can be navigated 
 * much like the Browser history.
 * 
 * A {@link FilterStack} is created for each different name and {@link InvestmentModel} combination
 * e.g. a CashflowCard may have 2 FilterStacks, one for "My Accounts" and for "My Investments".
 * 
 * @param initFilter 
 * @returns a FilterStack object
 */
export function FilterProvider(props : { 
  name: string
  children: ReactNode
  initFilter?: InitFilter
}) {
  const { name, initFilter, children } = props
  const { investmentModel, updated, item } = useInvestmentModel()

  const [ filterStack ] = useAtomMap(filterStackAtomMap, newFilterStack, name, item.key)

  useMemo(() => {
    // logger.setContext(name).debug("Notifying filterStack=%s of change in investmentModel=%s", filterStack.id, item.key)
    filterStack.onModelChanged(updated)
  },[ updated, filterStack ])

  logger.setContext(name).debug("Providing filter=%s, itemKey=%s", filterStack.filter.id, item.key)

  return (
    <FilterContext.Provider value={filterStack}>
      { children }
    </FilterContext.Provider>
  )

  function newFilterStack() : FilterStack {
    const filterStack = new FilterStack(investmentModel, name, initFilter)
    return filterStack
  }
}
