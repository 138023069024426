import { Item, setItemPropNames } from "./Item"

/**
 * Representation of a financial account
 */
export interface AccountItem extends Item {
  providerKey?: string,
  currency?: string
}

type AccountItemProp = keyof AccountItem

type AccountItemRecord = Record<AccountItemProp, AccountItemProp>

export const AccountItemProps:AccountItemRecord = {
  key: "key",
  code: "code",
  name: "name",
  description: "description",
  parentKey: "parentKey",
  typeKey: "typeKey",
  links: "links",
  sortOrder: "sortOrder",
  status: "status",
  modifiedDate: "modifiedDate",
  providerKey: "providerKey",
  currency: "currency",
}

setItemPropNames(AccountItemProps)

export const AccountItemPropNames = Object.keys(AccountItemProps) as AccountItemProp[]
