import { startOfDay } from "date-fns"
import { Filter } from "../filter/Filter"
import { PivotType } from "../invest/BudgetActualModel"
import { Model } from "../model/Model"
import { ModelKeys, Transaction } from "../model/ModelKeys"
import { CashflowItem } from "../types/CashflowItem"
import { Item } from "../types/Item"
import { Logger } from "../utils/Logger"
import { TimeScale, TimeUnits, intervalStarting } from "../utils/TimeScale"
import { getUniqueId } from "../utils/Utils"


/**
 * PivotTable is a class that builds a pivot table from a list of cashflow items. 
 * Each row represents a period of time as defined by the TimeUnits in the filter.
 * Each column represents a dimension of the pivot table - account, asset, category, type.
 */
export class PivotTable {
  private logger = new Logger("builders.PivotTable")

  private model: Model

  /** Filter for the pivot table */
  private filter: Filter

  /** List of rows in the pivot table */
  private _rows: PivotRow[] = []
  private _totals = new PivotRow()

  /** List of assets, accounts, types and categories that are included in the pivot table */
  private _accountTypes = new Set<Item>()
  private _accounts = new Set<Item>()
  private _assets = new Set<Item>()
  private _categories = new Set<Item>()
  private _types = new Set<Item>()

  private _pivotType: PivotType

  constructor(model:Model, account:Item, filter:Filter, pivotType: PivotType) {
    this.model = model
    this.filter = filter
    this._pivotType = pivotType
    this.logger.setContext(account?.name).addContext(pivotType)
  }

  get pivotType() {
    return this._pivotType
  }

  get units() : TimeUnits {
    return this.filter.scale.units || "1Y"
  }

  /**
   * Returns a TimeScale object where:
   * @start startDate of first row in the pivot table
   * @end endDate of last row in the pivot table
   * @units TimeUnits between rows
   */
  get scale() : TimeScale {
    let start=0, end=0

    const rows = this.rows
    if (rows && rows.length > 0) {
      start = rows[0].startDate
      end = rows[rows.length-1].endDate

      if (rows[0].openingRow && rows.length > 1) {
        start = rows[1].startDate
      }
    }
    return { start, end, units:this.units }
  }

  /** Get an unfiltered list of rows */
  get allRows() : PivotRow[] {
    return this._rows
  }

  /** Get a filtered list of rows */
  get rows() : PivotRow[] {
    const included = this.filter.included
    if (included && included.size > 0) {
      this.logger.trace("rows: included=%o", included)
      return this._rows.filter(row => included.has(row.date) || row.openingRow)
    }
    return this._rows
  }
 
  get columns() : Item[] {
    const { dimension, columnItem } = this.filter

    let columns
    switch (dimension) {
      case "Type":
        columns = columnItem ? this.categories.filter(category => category.typeKey === columnItem.key)
                             : [...this._types]
        break

      case "Category":
        // Categories that have transactions
        columns = this.model.children(columnItem?.key ?? ModelKeys.category.root,
                                     (category => this._categories.has(category)))

        // If no child columns then use the filter columnItem
        if (columns.length === 0 && columnItem) {
          columns = [columnItem]
        }

        // If only one column, then auto-drill to next level down
        else if (columns.length === 1) {
          const cols = this.model.children(columns[0].key, (category => this._categories.has(category)))
          if (cols.length > 0) {
            columns = cols
          }
        }
        break

      case "Account":
        columns = [...this._accounts]
        break

      case "Asset":
        columns = [...this._assets]
        break
  
      case "AccountType":
        columns = this.model.children(columnItem?.key ?? ModelKeys.account.root, 
                                     (accountType => this._accountTypes.has(accountType)))
        break
    }

    return columns.sort((i1,i2) => (i1.sortOrder - i2.sortOrder))
  }

  get firstRow() : PivotRow {
    if (this._rows.length > 0) {
      let row = this._rows[0]
      if (row.openingRow && this._rows.length > 1) {
        row = this._rows[1]
      }
      return row
    }
    return new PivotRow(0, 0, 0, this.units)
  }

  get lastRow() : PivotRow {
    if (this._rows.length > 0) {
      return this._rows[this._rows.length - 1]
    }
    return new PivotRow(0, 0, 0, this.units)
  }

  get currentRow() : PivotRow {
    return this.rowAt(Date.now())
  }

  get totals() : PivotRow {
    return this._totals
  }

  get accounts() : Item[] {
    return [...this._accounts]
  }
 
  get categories() : Item[] {
    return [...this._categories]
  }
 
  get hasIncome() {
    return Math.abs(this.totals.cell(ModelKeys.category.income).total) >= 0.005
  }
 
  get hasExpense() {
    return Math.abs(this.totals.cell(ModelKeys.category.expense).total) >= 0.005
  }

  get hasAssets() {
    return Math.abs(this.totals.cell(ModelKeys.asset.root).total) >= 0.005
  }

  public getAssets(row:PivotRow) {
    let assets = 0
    for (const account of this._accounts) {
      const balance = row.cell(account.key).balance
      if ((balance ?? 0) >= 0.05) {
        assets += balance
      }
    }
    return assets
  }

  public getDebt(row:PivotRow) {
    let debt = 0
    for (const account of this._accounts) {
      const balance = row.cell(account.key).balance
      if ((balance ?? 0) <= -0.05) {
        debt += balance
      }
    }
    return debt
  }

  public count(key:string) : number {
    key = this.model.getItemKey(key)
    return this.totals.cell(key).count
  }

  public total(key:string) : number {
    key = this.model.getItemKey(key)
    return this.totals.cell(key).total
  }

  public rowAt(date:number) : PivotRow {
    const lastRow = this.lastRow
    if (date > lastRow.endDate) {
      return lastRow
    }
    
    let found
    for (const row of this._rows) {
      if (date >= row.startDate && date <= row.endDate) {
        return row
      }
      if (row.endDate <= date) {
        found = row
      } else if (found) {
        return found
      }
    }
    return new PivotRow(0, date, 0, this.units)
  }

  public build(cashflowItems:CashflowItem[]) : PivotTable {
    // 
    const rows = []
    const totals = new PivotRow()

    const firstDate = cashflowItems[0]?.date || 0
    const startDate = Math.max(this.filter.scale.start, firstDate)
    const finishDate = this.filter.scale.end

    this.logger.start("build", "Started with %d cashflow items, firstDate=%s, range={%s}, filter=%o", 
                                cashflowItems.length, 
                                Logger.Date(firstDate), 
                                Logger.Range(startDate, finishDate), 
                                Logger.Filter(this.filter))

    // Create row that will accumulate opening balances and cumTotals
    const end = startOfDay(startDate).getTime() - 1
    let pivotRow = new PivotRow(0, end, 0, this.units, true)

    // Build the pivot table
    for (const item of cashflowItems) {
      // Process every item up to the finishDate so cumTotals and balances are correctly calculated
      if (finishDate && item.date > finishDate) {
        break
      }

      // Create new row as required
      if (item.date > pivotRow.endDate) {
        if (pivotRow.openingRow) {
          rows.push(pivotRow)
        }

        const interval = intervalStarting(this.units, item.date)
        pivotRow = new PivotRow(interval.start, interval.end, pivotRow.balance, this.units)
        rows.push(pivotRow)
      }

      // Update the row and cell totals for asset/account/category/type
      if (!Transaction.isInterestRate(item)) {
        pivotRow.count++
        pivotRow.balance += item.amount
        if (item.date >= startDate) {
          pivotRow.total += item.amount
        }

        // Update the pivot table cells for each dimension
        this.updateCell(pivotRow, item, item.accountKey,     this._accounts)
        this.updateCell(pivotRow, item, item.accountTypeKey, this._accountTypes)
        this.updateCell(pivotRow, item, item.typeKey,        this._types)

        // Update category totals for each dimension
        if (item.date >= startDate) {
          // Categories
          let categoryKey = item.categoryKey
          while (categoryKey) {
            this.updateCell(pivotRow, item, categoryKey, this._categories)
            if (categoryKey === ModelKeys.category.root) {
              break
            }
            categoryKey = this.model.getItem(categoryKey)?.parentKey
          }

          // Assets
          if (item.assetKey !== item.accountKey && this.model.hasChild(item.assetKey, ModelKeys.asset.root)) {
            this.updateCell(pivotRow, item, ModelKeys.asset.root, this._assets)
          }
        }
      }
    }

    // Keep new rows
    this._rows = rows
    this._totals = totals

    // Calculate running balance for each account, and grand totals
    this.calcTotals(rows, totals)

    // And we are done!
    this.logger.finish("build", "%d items => %d rows", cashflowItems.length, rows.length)
    return this
  }

  private updateCell(pivotRow:PivotRow, item:CashflowItem, columnKey:string|undefined, columnItems:Set<Item>) {
    if (!columnKey) {
      return
    }

    // Get existing cell, or create new
    let cell = pivotRow.getCell(columnKey)
    if (cell === undefined) {
      // Create a new cell
      cell = pivotRow.setCell(columnKey)

      // Add a new asset/account/category/type if required
      const columnItem = this.model.getItem(columnKey)
      columnItems.add(columnItem)
    }

    // Update cell totals
    cell.count++
    cell.total += item.amount

    if (item.accountKey === columnKey) {
      cell.balance += item.amount
    }
  }

  private calcTotals(rows:PivotRow[], totals:PivotRow) {
    // Calculate running balance for each account, and grand total balance
    if (rows.length > 0) {
      for (const account of this._accounts) {
        let balance = rows[0].cell(account.key).balance || 0

        for (let i=1; i < rows.length; i++) {
          const cell = rows[i].cell(account.key)
          cell.balance = (balance += cell.total)
        }

        totals.cell(account.key).balance = rows[rows.length-1].cell(account.key).balance
      }
    }

    // Combine all column keys
    const allColumns = [
      ...this._accountTypes, 
      ...this._accounts, 
      ...this._assets, 
      ...this._categories, 
      ...this._types ]

    // Calculate cumTotals
    let prevRow
    for (const row of rows) {
      row.cumTotal = row.total + (prevRow?.cumTotal || 0)
      row.percent = prevRow ? (row.balance - prevRow.balance) / Math.abs(prevRow.balance) : 0

      // Grand totals
      totals.total = totals.cumTotal = row.cumTotal

      for (const column of allColumns) {
        if (!column) continue
        
        const cell = row.cell(column.key)
        const prevCell = prevRow?.cell(column.key)
        cell.cumTotal = cell.total + (prevCell?.cumTotal || 0)

        totals.cell(column.key).cumTotal = cell.cumTotal
        if (!row.openingRow) {
          totals.cell(column.key).total += cell.total
        }
      }

      // 
      prevRow = row
    }

    // End date for totals rows
    if (rows.length > 0) {
      totals.endDate = rows[rows.length-1].endDate
      totals.balance = rows[rows.length-1].balance
    }
  }
}

export class PivotRow {
  /** Start date for this period */
  public startDate: number = 0

  /** End date for this period */
  public endDate: number = 0
  
  /** Count of transactions for this period */
  public count: number = 0
  
  /** Total of transaction amounts for this period */
  public total: number = 0
  
  /** Cumulative total of transaction amounts for this period */
  public cumTotal: number = 0

  /** Balance as at end of period */
  public balance: number = 0

  /** Percentage change in balance as compared to previous period */
  public percent: number = 0

  /** The TimeUnits for each row in the pivot */
  public units: TimeUnits

  /** Is this row an opening balance */
  public openingRow = false

  /** Pivot totals for each dimension - account, category, type */
  private cells = new Map<string,PivotCell>()

  constructor(startDate:number=0, endDate:number=0, balance:number=0, units:TimeUnits="1Y", openingBalance=false) {
    this.startDate = startDate
    this.endDate = endDate
    this.balance = balance
    this.units = units
    this.openingRow = openingBalance
  }

  get key() {
    return this.endDate
  }

  get date() {
    return this.endDate
  }

  get start() {
    return this.startDate
  }

  get end() {
    return this.endDate
  }

  get interval() : { start:number, end:number } {
    return { start:this.startDate, end:this.endDate }
  }

  get scale() : TimeScale {
    return { units:this.units, start:this.startDate, end:this.endDate }
  }

  get debt() {
    return this.cell(ModelKeys.account.loan).cumTotal
  }

  get assets() {
    return this.cell(ModelKeys.asset.root).cumTotal
  }

  get equity() {
    return this.assets + this.debt
  }

  get equityPercent() {
    const assets = this.assets
    return (assets !== 0) ? this.equity / assets : 0
  }

  get cashflow() {
    return this.total
  }

  get earnings() {
    return this.cell(ModelKeys.category.earnings).total
  }

  get buyCumulative() {
    return this.cell(ModelKeys.category.buy).cumTotal
  }

  get sellCumulative() {
    return this.cell(ModelKeys.category.sell).cumTotal
  }


  public cell(key:string) : PivotCell {
    const cell = this.cells.get(key)
    return cell || this.setCell(key)
  }

  public getCell(key:string) : PivotCell | undefined {
    return this.cells.get(key)
  }

  public setCell(key:string) : PivotCell {
    const cell:PivotCell = {
      id: getUniqueId(),
      key: key,
      count: 0,
      total: 0,
      cumTotal: 0,
      balance: 0,
    }
    this.cells.set(key, cell)
    return cell
  }
}

export interface PivotCell {
  /** The dimension key for the column */
  key: string

  /** Number of transactions in this category */
  count: number

  /** Total amount for this category of transaction, Debit = +ve, Credit = -ve */
  total: number

  cumTotal: number

  balance: number

  id: string
}
