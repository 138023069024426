/** Describes a route that can be passed to {@link createRouter} */
export interface RouteItem {
  index?: boolean
  path?: string
  name?: string
  icon?: string
  description?: string
  Component?: () => JSX.Element
  element?: JSX.Element
  children?: RouteItem[]
}

export function findRoute(searchPath:string, routes:RouteItem[]) : RouteItem | undefined {
  for (const route of routes) {
    if (route.path === searchPath) {
      return route
    }
    if (route.children && route.children.length > 0) {
      const result = findRoute(searchPath, route.children)
      if (result) {
        return result
      }
    }
  }
  return undefined
}
