import { Logger } from "../../core/utils/Logger"
import { useAtomKey } from "../../hooks/useAtomKey"
import { Card, CardSection } from "../controls/Card"
import { FormDataJson } from "../forms/FormDataJson"
import { WizardHeader } from "./WizardHeader"

const logger = new Logger("wizards.WizardMenu")

export interface WizardMenuItem {
  title: string
  subtitle: string
  icon?: string
  keys?: string[]
  children?: WizardMenuItem[]
}

export function WizardMenu({ id, wizardMenu, onSelect} : { 
  id: string
  wizardMenu: WizardMenuItem
  onSelect: (e:any, menuItem:WizardMenuItem) => void
}) {
  const { title, subtitle, children } = wizardMenu
  const [ selectedItem, setSelectedItem ] = useAtomKey<WizardMenuItem|undefined>(id, undefined)

  logger.debug("Rendering: id='%s', selectedItem='%s'", id, selectedItem?.title)
  
  return (
    <Card id={id} className="wizard-page">
      <WizardHeader title={title} subtitle={subtitle} />
      <CardSection className="wizard-menu">
        { children?.map(menuItem =>
          <WizardMenuListItem 
            icon={menuItem.icon}
            title={menuItem.title}
            subtitle={menuItem.subtitle}
            active={menuItem.title === selectedItem?.title}
            onClick={(e:any) => onSelectItem(e, menuItem)}
            key={menuItem.title}
          />
        )}
      </CardSection>
      <FormDataJson/>
    </Card>
  )

  function onSelectItem(e:any, menuItem:WizardMenuItem) {
    setSelectedItem(menuItem)
    onSelect(e, menuItem)
  }
}

export function WizardMenuListItem(props: {
  title: string
  subtitle: string
  icon?: string
  active?: boolean
  onClick?: (e:any) => void
}) {
  const { title, subtitle, icon, onClick } = props
  const active = props.active ? " active" : ""

  return (
    <li className={"wizard-menu-item link" + active} onClick={onClick}>
      {icon && <i className={icon} />}
      <h3>{title}</h3>
      <p>{subtitle}</p>
    </li>
  )
}