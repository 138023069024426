import { Duration, intervalToDuration } from "date-fns"
import { getInvestmentModelBuilder } from "../atom/InvestmentModelAtoms"
import { CashflowBuilder } from "../builder/CashflowBuilder"
import { InvestmentModelBuilder } from "../builder/InvestmentModelBuilder"
import { Filter } from "../filter/Filter"
import { Model } from "../model/Model"
import { Account, Asset, ModelKeys, Type } from "../model/ModelKeys"
import { AssetItem } from "../types/AssetItem"
import { Item } from "../types/Item"
import { LoanItem } from "../types/LoanItem"
import { TransactionItem } from "../types/TransactionItem"
import { ValuationItem } from "../types/ValuationItem"
import { Logger } from "../utils/Logger"
import { TimeScaleDefault } from "../utils/TimeScale"
import { getUniqueId } from "../utils/Utils"

export class InvestmentModel<T extends Item> {
  /** Identity of the investment */
  public readonly key = getUniqueId()

  /** The master data model */
  public readonly model: Model

  protected logger:Logger

  // Protected properties
  protected readonly itemKey: string
  protected readonly assetKey: string

  protected _start?: number
  protected _finish?: number
  protected _term?: Duration

  protected _filterDefault: Filter = { id:getUniqueId(), scale:TimeScaleDefault, dimension:"Category", raw:false }

  constructor(model:Model, item:T) {
    // Save parameters
    this.model = model
    this.itemKey = item.key
    this.logger = new Logger("models.InvestmentModel", this.name)

    this.assetKey = this.findAssetKey(this.item)
  }

  /** Get a reference to this model, useful when destructuring */
  get investmentModel() : InvestmentModel<T> {
    return this
  }

  /** Get a reference to the builder */
  get builder() {
    return getInvestmentModelBuilder().builder as InvestmentModelBuilder
  }

  /** Get time model was updated */
  get updated() {
    return getInvestmentModelBuilder().updated
  }

  /** The Item in the Model that this relates to */
  get item() : T {
    return this.model.getItem<T>(this.itemKey)
  }

  /** Asset in the Model that this relates to (note this could be item) */
  get asset() : AssetItem {
    return this.model.getItem<AssetItem>(this.assetKey)
  }

  /** Name of the investment */
  get name() : string {
    return this.model.getItemName(this.itemKey)
  }

  get hasAssets() : boolean {
    return this.model.childrenOfTypeDeep(this.itemKey, ModelKeys.asset.root).length > 0
  }

  get hasProperty() : boolean {
    return this.model.childrenOfTypeDeep(this.itemKey, ModelKeys.asset.property).length > 0
  }

  get hasLoans() : boolean {
    return this.model.childrenOfTypeDeep(this.itemKey, ModelKeys.account.loan).length > 0
  }

  /** Is this a BudgetModel */
  get isBudget() {
    return Account.isChecking(this.item) || Account.isCreditCard(this.item) || 
           Asset.isPortfolio(this.item) || Type.isValuation(this.item)
  }

  /** Is this a LoanModel */
  get isLoan() {
    return Account.isLoan(this.item)
  }

  /** Is this a PropertyModel */
  get isProperty() {
    return Asset.isProperty(this.item)
  }

  /** Is this a unitised asset */
  get isUnitised() {
    return Asset.isUnitised(this.asset)
  }

  /** Start date for the model */
  get start() : number {
    if (this._start === undefined) {
      this._start = this.getEarliestStart(this.item) || 0
    }
    return this._start
  }

  /** Finish date for the model */
  get finish() : number {
    if (this._finish === undefined) {
      this._finish = this.asset.sellDate || this.getLatestFinish(this.item) || 0
    }
    return this._finish
  }

  /** @returns Term for the investment model to be run */
  get term() : Duration {
    if (this._term === undefined) {
      const { start, finish } = this

      if (start && finish && (start < finish)) {
        this._term = intervalToDuration({ start, end:finish })
      } else {
        this._term = { years:20 }
      }
    }
    return this._term
  }

  /** @returns expected compound annual growth rate for this asset */
  get rate() : number {
    return this.asset.rate || 0
  }

  /** @returns a list of all child models */
  get children() {
    return this.getChildren()
  }

  /** @returns list of all budgets for this asset */
  get budgetItems() : Item[] {
    return this.model.childrenOfTypeDeep<Item>(this.item.key, ModelKeys.account.checking)
  }

  /** @returns list of all loans for this asset */
  get loanItems() : LoanItem[] {
    return this.model.childrenOfTypeDeep<LoanItem>(this.item.key, ModelKeys.account.loan)
  }

  /** @returns list of all valuation items for this asset */
  get valuationItems() : ValuationItem[] {
    return this.model.childrenOfTypeDeep<ValuationItem>(this.item.key, ModelKeys.type.valuation)
  }

  /** @returns list of all valuation items for this item */
  get transactionItems() {
    return this.model.childrenOfTypeDeep<TransactionItem>(this.item.key, ModelKeys.transaction.root)
  }

  /** @returns the cashflow builder for this item */
  get cashflow() {
    return this.getCashflowBuilder() as CashflowBuilder
  }

  /** @returns the list of cashflow items for current CashflowBuilder */
  get cashflowItems() {
    return this.cashflow.getCashflowItems()
  }
 
  get cashflowLength() : number {
    return this.cashflow.length
  }

  get filterDefault() : Filter {
    return this._filterDefault
  }

  get currentItem() {
    return this.cashflow.findLatest()
  }

  get currentBalance() {
    return this.currentItem?.balance
  }

  /** Invoked by ModelBuilder once all models have been instantiated */
  public onCreate() {
    this.logger.start("onCreate")
    
    this._start = undefined
    this._finish = undefined
    this._term = undefined

    this.logger.finish("onCreate")
  }

  /** Invoked by ModelBuilder after onCreate has been called for all models */
  public onBuild() {
    // this.logger.start("onBuild")
    // LogCashflowItems(this.cashflowItems)
    // this.logger.finish("onBuild")
  }

  /** Invoked by ModelBuilder after onBuild has been called for all models */
  public onPostBuild() {
    // this.logger.start("onPostBuild")
    // LogCashflowItems(this.cashflowItems)
    // this.logger.finish("onPostBuild")
  }

  protected getCashflowBuilder(): CashflowBuilder | undefined {
    return undefined
  }

  /** Children - portfolio has assets, an asset has accounts, etc */
  protected getChildren() {
    return this.builder.getChildren(this.itemKey)
  }

  /** Start date for the model, based upon earliest start date of all child models */
  protected getEarliestStart(ignoreItem?:Item) : number {
    let count = 0
    let start = Number.MAX_SAFE_INTEGER
    for (const model of this.getChildren()) {
      count++
      if (!ignoreItem || ignoreItem.key !== model.item.key) {
        start = Math.min(model.start, start)
      }
    }
    if (start === Number.MAX_SAFE_INTEGER) {
      start = 0
    }
    this.logger.debug("getEarliestStart: Earliest start date from %s models is %s", count, Logger.Date(start))
    return start
 }

  /** Finish date for the model, based upon latest finish date of all child models */
  protected getLatestFinish(ignoreItem?:Item) : number {
    let count = 0
    let finish = 0
    for (const model of this.getChildren()) {
      count++
      if (!ignoreItem || ignoreItem.key !== model.item.key) {
        finish = Math.max(model.finish, finish)
      }
    }
    this.logger.debug("getLatestFinish: Latest finish date from %s models is %s", count, Logger.Date(finish))
    return finish
  }

  /** Find the AssetItem that is a parent/grandparent of the specified item */
  private findAssetKey(item:Item) : string {
    const typeKeys = this.model.childrenKeys(ModelKeys.asset.root)

    this.logger.trace("findAssetKey: item=%o, typeKeys=%o", item, typeKeys)

    // Note: count is just to prevent an infinite loop in the event of data problems
    let asset = item, count=0
    while (asset && 
           asset.key !== ModelKeys.root && 
           asset.typeKey !== ModelKeys.account.deposit && 
           !typeKeys.includes(asset.typeKey) && 
           count < 10) {
      asset = this.model.getItemParent(asset)
      count++
    }

    if (this.logger) {
      const assetType = this.model.getItemType(asset)
      this.logger.trace("findAsset: item '%s' [%s], count=%d, found asset '%s', type '%s'", 
                         item?.name, item?.key, count, asset?.name, assetType?.name)
    }
    return (asset ? asset.key : item.key)
  }
}
