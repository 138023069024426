import { initializeApp } from "firebase/app"
import { createRoot } from 'react-dom/client'
import { App } from "./App"
import { getDeviceInfo } from "./ui/cards/settings/diagnostics/DeviceInfo"
import { AppInfo } from "./VersionInfo"

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

// Just so we can see it
console.debug("appInfo:", AppInfo )
console.debug("deviceInfo:", getDeviceInfo())
console.debug("process.env:", process.env)

// Eliminate React error regarding contenteditable
console.error = (function(_error) {
  return function(message) {
    if (typeof message !== 'string' || message.indexOf('component is `contentEditable`') === -1) {
      _error.apply(console, arguments)
    }
  }
})(console.error)

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCjK5P4BOc4uXfuPH8eK-y1-ruaR68-CSg",
  authDomain: "jemstone.co.nz",
  projectId: "jemstone-invest",
  storageBucket: "jemstone-invest.appspot.com",
  messagingSenderId: "166449446274",
  appId: "1:166449446274:web:14bfe81fa3aeeb2d0de39b"
}

// Initialize Firebase
initializeApp(firebaseConfig)
// console.info("firebase.app: %o", firebase)

// Boot the app
const container = document.getElementById('root')
createRoot(container).render(<App/>)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()
