import { Duration } from "date-fns"
import { Item, setItemPropNames } from "./Item"

export interface TransactionItem extends Item {
  /** Value of the transaction */
  value: number

  /** Running balance */
  balance?: number

  /** The account the transaction item relates to - could be an asset, loan, investment etc */
  accountKey?: string

  /** Category for the transaction */
  categoryKey?: string

  /** Merchant key - supplied via Akahu APIs */
  merchantKey?: string

  /** Frequency of a recurring transaction, undefined for one-off */
  frequency?: Duration

  /** Start date of the transaction */
  startDate?: number

  /** Finish date of the transaction */
  finishDate?: number

  /** Other leg of a transfer transaction */
  relatedTransactionKey?: string

  /** The BankStmtRulesItem used to categorise this transaction */
  ruleKey?: string

  /** The number of units bought or sold in this transaction */
  units?: number

  /** The unit price at the time of the transaction */
  unitPrice?: number

  /** Unit balance at the time of the transaction */
  unitBalance?: number
}

type TransactionItemProp = keyof TransactionItem

type TransactionItemRecord = Record<TransactionItemProp, TransactionItemProp>

export const TransactionItemProps: TransactionItemRecord = {
  key: "key",
  code: "code",
  name: "name",
  description: "description",
  value: "value",
  balance: "balance",
  accountKey: "accountKey",
  categoryKey: "categoryKey",
  merchantKey: "merchantKey",
  frequency: "frequency",
  startDate: "startDate",
  finishDate: "finishDate",
  relatedTransactionKey: "relatedTransactionKey",
  ruleKey: "ruleKey",
  units: "units",
  unitPrice: "unitPrice",
  unitBalance: "unitBalance",
  parentKey: "parentKey",
  typeKey: "typeKey",
  links: "links",
  sortOrder: "sortOrder",
  status: "status",
  modifiedDate: "modifiedDate",
}

setItemPropNames(TransactionItemProps)

export const TransactionItemPropNames = Object.keys(TransactionItemProps) as TransactionItemProp[]

// console.debug("TransactionItem: names=%o, props=%o", TransactionItemPropNames, TransactionItemProps)
