import { ExpandableContents } from "../PageSection";

export function UserGuides() {
  return (
    <article id="UserGuides">
      <h1>User Guides</h1>
      <section>
        <ExpandableContents path="user"/>
      </section>
    </article>
  )
}