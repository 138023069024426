import { Duration, add, endOfDay, startOfMonth } from "date-fns"
import { ValuationCalculator } from "../invest/ValuationCalculator"
import { Model } from "../model/Model"
import { Asset, ModelKeys } from "../model/ModelKeys"
import { AssetItem } from "../types/AssetItem"
import { CashflowItem } from "../types/CashflowItem"
import { Item, ItemStatus } from "../types/Item"
import { TransactionItem } from "../types/TransactionItem"
import { formatDate } from '../utils/DateFormat'
import { Logger } from "../utils/Logger"
import { CashflowBuilder, getAccountTypeKey, newCashflowItemKey } from "./CashflowBuilder"

export class FutureValueBuilder {
  protected model:Model
  protected asset:AssetItem
  protected account:Item
  protected built:boolean = false

  protected pv: number
  protected rate: number
  protected term: Duration
  protected startDate: number
  protected finishDate: number
  protected interestFrequency: Duration

  protected transactions: TransactionItem[] = []
  protected valuationCalc?: ValuationCalculator

  protected readonly cashflow: CashflowBuilder

  protected readonly logger = new Logger("builders.FutureValueBuilder")

  constructor(
    model:Model,
    asset:AssetItem,
    account:Item,
    pv:number, 
    rate:number, 
    term:Duration, 
    startDate:number,
    finishDate:number, 
    interestFrequency?: Duration
  ) {
    this.model = model
    this.asset = asset
    this.account = account
    this.pv = pv
    this.rate = rate
    this.term = term
    this.startDate  = startDate
    this.finishDate = finishDate
    this.interestFrequency = interestFrequency ?? { months:1 }

    this.logger.setContext(account.name)
    this.logger.debug("Created builder: pv=%f, rate=%f, %s, term=%o", 
                      this.pv, this.rate, Logger.Range(this.startDate, this.finishDate), this.term)

    this.cashflow = new CashflowBuilder(this.model,
                                        this.asset,
                                        this.account,
                                        this.isAmortization(),
                                        this.startDate,
                                        this.finishDate,
                                        this.rate,
                                        this.interestFrequency)
  }

  public isAmortization() : boolean {
    return false
  }

  public getCashflow() {
    return this.cashflow
  }

  public build(addBankStatements = false) {
    this.logger.start("build", "built=%s", this.built)

    // Reset the cashflow
    this.cashflow.reset(this.startDate,
                        this.finishDate,
                        this.rate,
                        this.interestFrequency)

    // Add transactions
    this.cashflow.addTransactions(this.transactions)
 
    // Add child transactions
    this.addChildTransactions()

    // Add interest for loans, earnings for unitised accounts
    if (this.isAmortization() && !this.model.hasChildOfType(this.account.key, ModelKeys.transaction.interest)) {
      this.addInterest()
    }

    // Add bank statements
    if (addBankStatements) {
      this.cashflow.addBankStatements()
    }

    // Add valuation points for unitised
    if (!this.isAmortization() && this.valuationCalc) {
      this.addValuations()
      this.addEarnings()
    }

    // Calculate summary
    this.cashflow.build()

    this.built = true
    this.logger.finish("build", "Added %d items to cashflow, built=%s", this.cashflow.length, this.built)
  }

  public setValuationCalc(valuationCalc: ValuationCalculator) : FutureValueBuilder {
    this.valuationCalc = valuationCalc
    return this
  }

  public addTransactions(transactions: TransactionItem[]) : FutureValueBuilder {
    this.logger.debug("addTransactions: adding %d transactions", transactions.length)
    this.transactions.push(...transactions)
    return this
  }

  protected addChildTransactions() {
    if (this.account && this.pv) {
      //
      const buyCategory = this.model.getItem(ModelKeys.category.buy)
      const accountTypeKey = getAccountTypeKey(this.model, this.account.key)
      const date = this.startDate

      // Create an opening balance transaction
      const openingBalance:CashflowItem = {
        key: newCashflowItemKey(),
        parentKey: newCashflowItemKey(),
        assetKey: this.asset.key,
        accountKey: this.account.key,
        accountTypeKey: accountTypeKey,
        categoryKey: buyCategory.key,
        typeKey: buyCategory.typeKey,
        name: "Purchase " + this.asset.name,
        date: date,
        amount: this.pv,
        balance: 0,
        calculated: false,
        sortOrder: date,
        status: ItemStatus.TRANSIENT,
      }
      this.cashflow.addItem(openingBalance)

      this.logger.debug("addChildTransactions: Added opening balance date=%s, balance=%f", 
                        formatDate(openingBalance.date), openingBalance.amount)
    }
  }

  protected addInterest() {
    const finishDate = this.finishDate
    const interestStartDate = startOfMonth(this.startDate).getTime()
    const interestFrequency = this.interestFrequency

    const interestCategory = this.model.getItem(this.isAmortization() ? ModelKeys.category.interest : ModelKeys.category.earnings)
    const accountTypeKey = getAccountTypeKey(this.model, this.account.key)

    this.logger.start("addInterest", "range={%s}, rate=%f, frequency=%o",
                      Logger.Range(interestStartDate, finishDate), this.rate, interestFrequency)

    let count = 0
    for (let date = interestStartDate;  date < finishDate; ) {
      date = add(date, interestFrequency).getTime()
      if (date > finishDate) {
        date = endOfDay(finishDate).getTime()
      }

      const interestDate = this.isAmortization() ? date : date-1

      this.cashflow.addItem({
        key: newCashflowItemKey(),
        parentKey: newCashflowItemKey(),
        assetKey: this.asset.key,
        accountKey: this.account.key,
        accountTypeKey: accountTypeKey,
        categoryKey: interestCategory.key,
        typeKey: interestCategory.typeKey,
        name: interestCategory.name,
        date: interestDate,
        amount: 0,
        balance: 0,
        calculated: false,
        sortOrder: interestDate,
        status: ItemStatus.TRANSIENT,
      })

      count++
    }

    this.logger.finish("addInterest", "Added %d interest items", count)
  }

  protected addEarnings() {
    const finishDate = this.finishDate
    const earningsStartDate = startOfMonth(this.startDate).getTime()
    const earningsFrequency = this.interestFrequency
    const earningsCategory = this.model.getItem(ModelKeys.category.earnings)
    const accountTypeKey = getAccountTypeKey(this.model, this.account.key)

    this.logger.start("addEarnings", "%s, rate=%f, frequency=%o",
                      Logger.Range(earningsStartDate, finishDate), this.rate, earningsFrequency)

    let count = 0
    for (let date = earningsStartDate;  date < finishDate; ) {
      date = add(date, earningsFrequency).getTime()
      if (date > finishDate) {
        date = endOfDay(finishDate).getTime()
      }

      this.cashflow.addItem({
        key: newCashflowItemKey(),
        parentKey: newCashflowItemKey(),
        assetKey: this.asset.key,
        accountKey: this.account.key,
        accountTypeKey: accountTypeKey,
        categoryKey: earningsCategory.key,
        typeKey: earningsCategory.typeKey,
        name: "Investment Earnings",
        date: date,
        amount: 0,
        balance: 0,
        calculated: false,
        sortOrder: date,
        status: ItemStatus.TRANSIENT,
      })

      // this.logger.debug("addEarnings: date=%s", formatDate(date, Logger.DefaultDateFormat))

      count++
    }

    this.logger.finish("addEarnings", "%s. Added %d earnings items", Logger.Range(earningsStartDate, finishDate), count)
  }

  protected addValuations() {
    const isUnitised = Asset.isUnitised(this.asset)
    const category = this.model.getItem(isUnitised ? ModelKeys.category.earnings : ModelKeys.category.balance)
    const accountTypeKey = getAccountTypeKey(this.model, this.account.key)
    const valuationItems = this.valuationCalc?.valuations || []

    this.logger.start("addValuations", "Adding %d valuationItems", valuationItems.length)

    // Create balance adjustments for all valuation points
    for (const valuation of valuationItems) {
      if (valuation.date > this.startDate) {
        this.cashflow.addItem({
          key: newCashflowItemKey(),
          parentKey: valuation.key,
          assetKey: this.asset.key,
          accountKey: this.account.key,
          accountTypeKey: accountTypeKey,
          categoryKey: category.key,
          typeKey: category.typeKey,
          name: isUnitised ? "Unit Price Update" : "Valuation Update",
          date: valuation.date,
          amount: valuation.value,
          balance: isUnitised ? (valuation.unitBalance || 0) * (valuation.unitPrice || 0) : valuation.value,
          sortOrder: valuation.date-1,
          calculated: false,
          bankStmt: false,
          status: ItemStatus.TRANSIENT,
        })
      }
      }

    // Done
    this.logger.finish("addValuations", "Added %d valuationItems", valuationItems.length)

    return this
  }
}
