import { getAuth } from 'firebase/auth'
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore'
import { atom } from 'jotai'
import { getAtomValue, setAtomValue } from '../atom/AtomUtils'
import { unloadModel } from '../service/ModelBuilder'
import { initializeDB, loadModel } from '../service/ModelService'
import { Logger } from '../utils/Logger'
import { createUserFile, getUserFilePath, loadFileItem, loadFiles, setUserFileKey, unloadFiles } from './FilesService'
import { UserProfile } from './UserProfile'

const logger = new Logger("core.UserService")

export const userProfileAtom = atom<UserProfile|undefined>()

/**
 * 
 * @returns The current authenticated user from firebase/auth
 */
export function getCurrentUser()  {
  return getAuth().currentUser
}

/**
 * @returns The email address of the current authenticated user
 */
export function getCurrentUserEmail() {
  return getCurrentUser()?.email ?? ""
}

export async function onLoginCurrentUser(fileKey?:string) {
  logger.start("onLoginCurrentUser", "Signing in user=%s, fileKey=%s", getCurrentUserEmail(), fileKey)

  // Initialize Firebase
  const success = await initializeDB(fileKey)

  // Load files for current authenticated user
  loadFiles()

  // Load model from current file
  if (success) {
    loadModel()
  }

  logger.finish("onLoginCurrentUser", "Signed in userProfile=%o", getUserProfile())
  return success
}

export function onLogoutCurrentUser() {
  logger.start("onLogoutCurrentUser", "Signing out user=%s", getCurrentUserEmail())

  unloadModel()
  unloadFiles()
  setUserProfile(undefined, false)

  logger.finish("onLogoutCurrentUser", "Signed out user=%s", getCurrentUserEmail())
}

/**
 * Store the specified user profile for the current authenticated user in an atom for global access, 
 * and also save to Firestore unless specifically instructed not to.
 * Note that the atom is used by useCurrentUser.
 */
export async function setUserProfile(profile:UserProfile|undefined, save=true) {
  logger.debug("setUserProfile: Setting profile=%o for user=%s", profile, getCurrentUserEmail())

  setAtomValue(userProfileAtom, profile)

  // Set the current file to the 
  setUserFileKey(profile?.fileKey)

  // Save the profile if required
  if (profile !== undefined && save) {
    await saveUserProfile(profile)
  }
}

/**
 * @returns The user profile for current authenicated user as set by {@link setUserProfile}
 */
export function getUserProfile() {
  return getAtomValue(userProfileAtom)
}

/**
 * Load the profile of the current authenticated user from Firebase. If not found then
 * assume user is new and create a new profile and a default model file.
 * The loaded profile can be obtained using {@link getUserProfile}
 */
export async function initUserProfile(fileKey?:string) {
  logger.start("initUserProfile", "fileKey=%s", fileKey)

  let userProfile = await loadUserProfile()
  if (!userProfile) {
    logger.debug("Creating new user profile and file")

    // Create a new file for the new user
    const filesItem = createUserFile("New File")

    // Create a new profile for the authenticated user
    const user = getCurrentUser()
    userProfile = {
      email:       user?.email       || "", 
      displayName: user?.displayName || "", 
      photoURL:    user?.photoURL    || "",
      theme:       "auto",
      fileKey:     filesItem.key,
    }
  }

  // Activate the current userProfile
  else {
    fileKey ??= userProfile.fileKey

    logger.debug("Activating userProfile=%o, loading fileKey=%s", userProfile, fileKey)
  
    // Verify the file exists before accepting it
    if (fileKey && await loadFileItem(fileKey)) {
      if (fileKey !== userProfile.fileKey) {
        userProfile.fileKey = fileKey
      }
    } 
    
    // Specified and/or recent file can't be loaded, so create a new one
    else {
      const filesItem = createUserFile("New File")
      userProfile.fileKey = filesItem.key
    }
  }
    
  // Save and activiate the profile
  setUserProfile(userProfile, true)

  logger.finish("initUserProfile", "returning true, userProfile=%o", userProfile)    
  return true
}

/**
 * @return the document located at /users/${email} that contains the current user's profile 
 */
async function loadUserProfile() {
  const { users, email } = getUserFilePath()

  const db = getFirestore()
  const docRef = doc(db, users, email)
  const userDoc = await getDoc(docRef)

  // const path = docRef.path
  // const exists = userDoc.exists().valueOf()
  const profile = userDoc.data() as UserProfile | undefined

  logger.debug("loadUserProfile: Loaded profile=%o from %s/%s", profile, users, email)

  return profile
}

async function saveUserProfile(profile:UserProfile) {
  const { users, email } = getUserFilePath()
  const docRef = doc(getFirestore(), users, email)

  logger.debug("saveUserProfile: Saving profile=%o to %s/%s", profile, users, email)
  
  return setDoc(docRef, profile)
}
