import { formatDistance } from 'date-fns'
import { LoanCalculator } from '../../../core/invest/LoanCalculator'
import { LoanModel } from '../../../core/invest/LoanModel'
import { formatDate, formatDurationSafe } from '../../../core/utils/DateFormat'
import { Logger } from '../../../core/utils/Logger'
import { NumberFormats, formatNumber } from '../../../core/utils/Numbers'
import { useInvestmentModel } from '../../../hooks/context/InvestmentModelContext'
import { usePivotTables } from '../../../hooks/pivot/usePivotTables'
import { useCardOpen } from '../../../hooks/useCardOpen'
import { LoanBalanceChart } from "../../charts/loan/LoanBalanceChart"
import { Card, CardSection } from '../../controls/Card'
import { CardHeaderWithMenu } from '../CardHeaderWithMenu'
import { LoanItemDetails } from './LoanItemDetails'

const logger = new Logger("LoanSummaryCard")

export function LoanSummaryCard({open, title}: {open?:boolean, title?:string}) {
  const { name, loan } = useInvestmentModel<LoanModel>()
  const { isOpen } = useCardOpen(logger.name, loan.key, open === true)

  logger.debug("Rendering: name=%s, loan=%o", name, loan)

  return (
    <Card id={logger.name} className="form" open={isOpen}>
      <CardHeaderWithMenu cardName={logger.name} title={title} showChildren showEdit showBalance/>
      <CardSection>
        <LoanItemDetails />
        <LoanBalanceChart />
        <LoanNarrative />
      </CardSection>
    </Card>
  )
}

export function LoanNarrative(props:any) {
  const { pivotTable } = usePivotTables()
  const { loan, calcTotalInterest, calcTotalPayments, numPayments,
          actualFinishDate, countRateChanges, currentBalance, currentItem 
        } = useInvestmentModel<LoanModel>()

  const { finishDate, paymentAmount, paymentFrequency } = loan
  const { totalInterest, totalDeposits } = new LoanCalculator(pivotTable)

  const interestSaved = Math.abs(calcTotalInterest) - Math.abs(totalInterest)

  return (
    <div id="LoanNarrative">
      <p>
        Your outstanding balance as at {formatDate(currentItem?.date, "MMMM yyyy ")} 
        is <b className="negative">{formatNumber(currentBalance, NumberFormats.currency0)}</b>, 
        and you can expect to finish repaying your loan in <b>{formatDate(actualFinishDate, "MMMM yyyy")}</b>.
      </p>
      { (countRateChanges > 0 || totalDeposits > 0) &&
        <p>
          This is {formatDistance(actualFinishDate, finishDate||Date.now())} early due to
          { totalDeposits > 0 &&
            <> {formatNumber(totalDeposits, NumberFormats.currency0)} in lump sum deposits</>
          }
          { countRateChanges > 0 &&
            <>
              {totalDeposits > 0 && <> and</>}
              <> {countRateChanges} interest rate changes</>
            </>
          }
          <>, which collectively have <b>saved {formatNumber(interestSaved, NumberFormats.currency0)}</b> in interest charges.</>
        </p>
      }
      <p>
        You were originally scheduled to repay your loan in {formatDate(finishDate, "MMMM yyyy ")}
        after {numPayments} payments of {formatNumber(paymentAmount, NumberFormats.currency0)}{' '}
        every {formatDurationSafe(paymentFrequency, { format: ['months', 'weeks'] })} - a total
        of {formatNumber(calcTotalPayments, NumberFormats.currency0)}.
      </p>
    </div>
  )
}
