import { Duration, endOfMonth, format, formatDuration, FormatDurationOptions, parse } from "date-fns"

export function formatDate(date: number | undefined, formatStr: string = "dd-MMM-yyyy"): string {
  if (date === undefined || date === null || date >= Infinity || isNaN(date)) {
    // console.warn("formatDate: Error formatting date: date=%s", date)
    return ""
  }
  return format(date, formatStr)
}

export function formatDateRange(start: number, end: number, formatStr: string = "dd-MMM-yyyy") {
  // Handle missing dates
  if (!start && !end) {
    return ""
  }
  if (start && !end) {
    return formatDate(start, formatStr)
  }
  if (!start && end) {
    return formatDate(end, formatStr)
  }

  // 
  const d1 = new Date(start)
  const d2 = new Date(end)

  const y1 = d1.getFullYear()
  const y2 = d2.getFullYear()
  if (y1 !== y2) {
    return Range("MMM-yyyy", "MMM-yyyy", " to ")
  }

  const m1 = d1.getMonth()
  const m2 = d2.getMonth()
  if (m1 === 0 && m2 === 11) {
    return formatDate(start, "yyyy")
  }
  if (m1 !== m2) {
    return Range("MMM-", "MMM yyyy")
  }

  const dy1 = d1.getDate()
  const dy2 = d2.getDate()
  if (dy1 !== dy2) {
    if (dy1 === 1 && dy2 === endOfMonth(end).getDate()) {
      return formatDate(start, "MMM-yyyy")
    }
    return Range("MMM d-", "d yyyy")
  }

  return formatDate(start, formatStr)

  function Range(f1: string, f2: string, separator = "") {
    return formatDate(start, f1) + separator + formatDate(end, f2)
  }
}

export function parseDate(dateStr: string, formatStr: string = "dd-MMM-yyyy"): Date {
  return parse(dateStr, formatStr, Date.now())
}

export function formatDurationSafe(duration:Duration|undefined, options?:FormatDurationOptions) {
  return formatDuration(duration ?? {}, options)
}