import { Logger } from "../../core/utils/Logger"
import { ViewContextProvider } from "../../hooks/context/ViewContext"
import { FilesCard } from "../cards/files/FilesCard"
import { JemstoneSidebar } from "../layouts/JemstoneSidebar"
import { ViewHeader } from "./ViewHeader"
import { ViewMenu } from "./ViewMenu"

export const logger = new Logger("view.FilesView")

export function FilesView(props:any) {
  logger.debug("Rendering: ")
            
  return (
    <ViewContextProvider>
      <ViewHeader rootKey="">
        <ViewMenu showTheme showWizard showUser />
      </ViewHeader>

      <JemstoneSidebar/>
      
      <main>
        <div id="FilesView" className="grid">
          <FilesCard/>
        </div>
      </main>
    </ViewContextProvider>
  )
}
