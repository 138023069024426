import { useId } from 'react'
import { Logger } from '../../core/utils/Logger'
import { useChartProfile } from './useChartProfile'

const logger = new Logger("charts.useGradient")

/**
 * Hook to return a unique gradient id that can be used with:
 * { linearGradient }
 * <Area fill={gradientUrl} />
 */
export function useGradient(data:any[], dataKey:string, fillColor?:string) {
  const gradientId = useId()
  const trendColor = useTrendColor(data, dataKey)

  return {
    gradientUrl: `url(#${gradientId})`,
    trendColor,
    linearGradient:
      <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
        <stop offset={0.1} stopColor={fillColor || trendColor} stopOpacity={0.8} />
        <stop offset={0.9} stopColor={fillColor || trendColor} stopOpacity={0.3} />
      </linearGradient>
  }
}

export function useGradientRedGreen(invertRed=false, invertGreen=false) {
  const { colors } = useChartProfile()
  const gradientRedId = useId()
  const gradientGreenId = useId()

  return {
    gradientRedUrl:   `url(#${gradientRedId})`,
    gradientGreenUrl: `url(#${gradientGreenId})`,
    linearGradient:
    <>
      <linearGradient id={gradientGreenId} x1="0" y1="0" x2="0" y2="1">
        <stop offset={0.1} stopColor={colors.green} stopOpacity={invertGreen ? 0.8 : 0.3} />
        <stop offset={0.9} stopColor={colors.green} stopOpacity={invertGreen ? 0.3 : 0.8} />
      </linearGradient>
      <linearGradient id={gradientRedId} x1="0" y1="0" x2="0" y2="1">
        <stop offset={0.1} stopColor={colors.red} stopOpacity={invertRed ? 0.3 : 0.8} />
        <stop offset={0.9} stopColor={colors.red} stopOpacity={invertRed ? 0.8 : 0.3} />
      </linearGradient>
    </>
  }
}

/**
 * Hook to return a unique gradient id that can be used with:
 * { linearGradient }
 * <Area fill={gradientUrl} />
 */
export function useGradientOffset(data:any[], dataKey:string) {
  const { colors } = useChartProfile()
  const gradientFillId = useId()
  const gradientLineId = useId()
  const { min, max, offset } = gradientOffset(data, dataKey)

  return {
    min,
    max,
    gradientFillUrl: `url(#${gradientFillId})`,
    gradientLineUrl: `url(#${gradientLineId})`,
    linearGradient:
      <>
        <linearGradient id={gradientFillId} x1="0" y1="0" x2="0" y2="1">
          <stop offset={0}      stopColor={colors.green} stopOpacity={0.8}/>
          <stop offset={offset} stopColor={colors.green} stopOpacity={0.3} />
          <stop offset={offset} stopColor={colors.red}   stopOpacity={0.8} />
          <stop offset={0}      stopColor={colors.red}   stopOpacity={0.3} />
        </linearGradient>
        <linearGradient id={gradientLineId} x1="0" y1="0" x2="0" y2="1">
          <stop offset={offset} stopColor={colors.green} stopOpacity={0.8} />
          <stop offset={offset} stopColor={colors.red}   stopOpacity={0.8} />
        </linearGradient>
      </>
  }
}

function gradientOffset(data:any[], dataKey:string) {
  let min = Number.MAX_SAFE_INTEGER
  let max = Number.MIN_SAFE_INTEGER
  
  for (const dp of data) {
    const value = dp[dataKey]
    if (value > max) max = value
    if (value < min) min = value
  }

  let offset = (max <= 0) ? 0 :
               (min >= 0) ? 1 :
               (max / (max - min))

  logger.trace("gradientOffset: max=%f, min=%f, offset=%f", max, min, offset)

  return { min, max, offset }
}

/**
 * If dataKey has increased over the period then show as green, otherwise red
 */
export function useTrendColor(data:any[], dataKey:string) {
  const { colors } = useChartProfile()

  if (data.length === 0) {
    return colors.green
  }

  const first = data.at(0)[dataKey] as number
  const last = data.at(-1)[dataKey] as number

  return (last >= first) ? colors.green : colors.red
}
