import { differenceInCalendarDays } from "date-fns"
import { PivotRow, PivotTable } from "../builder/PivotTable"
import { ModelKeys } from "../model/ModelKeys"
import { AssetItem } from "../types/AssetItem"
import { ValuationCalculator } from "./ValuationCalculator"
import { Logger } from "../utils/Logger"
import { ValuationItem } from "../types/ValuationItem"

const logger = new Logger("core.YieldCalculator")

export class YieldCalculator {
  private asset: AssetItem
  private pivotTable: PivotTable
  private valuationCalc?: ValuationCalculator
  private initialRow: PivotRow
  private currentRow: PivotRow
  private initialValuation?: ValuationItem
  private currentValuation?: ValuationItem
  private days: number

  constructor(asset:AssetItem, pivotTable:PivotTable, valuationCalc?:ValuationCalculator, currentRow?:PivotRow) {
    this.asset = asset
    this.pivotTable = pivotTable
    this.valuationCalc = valuationCalc

    this.initialRow = this.pivotTable.firstRow
    this.currentRow = currentRow ?? pivotTable.currentRow

    this.initialValuation = valuationCalc?.at(this.asset.purchaseDate)
    this.currentValuation = currentRow ? valuationCalc?.at(currentRow.date) : valuationCalc?.last
    this.currentValuation ??= valuationCalc?.last

    this.days = differenceInCalendarDays(this.currentRow.endDate, this.initialRow.startDate)
  }

  public at(currentRow:PivotRow) {
    return new YieldCalculator(this.asset, this.pivotTable, this.valuationCalc, currentRow)
  }

  get value() {
    const initialValue = this.initialValuation?.value || this.asset.purchasePrice
    const currentValue = this.currentValuation?.value || this.asset.purchasePrice

    return { initialValue, currentValue }
  }

  get debt() {
    const initialDebt = this.initialRow.debt
    const currentDebt = this.currentRow.debt

    return { initialDebt, currentDebt }
  }

  get equity() {
    const { initialValue, currentValue } = this.value
    const { initialDebt, currentDebt } = this.debt

    const initialEquity = initialValue + initialDebt
    const currentEquity = currentValue + currentDebt

    return { initialEquity, currentEquity }
  }

  get equityRatio() {
    const { initialEquity, currentEquity } = this.equity
    const { initialValue, currentValue } = this.value

    const initialEquityRatio = (initialValue === 0) ? 0 : Math.abs(initialEquity / initialValue)
    const currentEquityRatio = (currentValue === 0) ? 0 : Math.abs(currentEquity / currentValue)

    return { initialEquityRatio, currentEquityRatio }
  }

  get equityGrowth() {
    const { initialEquity, currentEquity } = this.equity
    return currentEquity - initialEquity
  }

  get yields() {
    const income   = this.currentRow.cell(ModelKeys.category.income)?.cumTotal ?? 0
    const expenses = this.currentRow.cell(ModelKeys.category.expense)?.cumTotal ?? 0
    const interest = this.currentRow.cell(ModelKeys.category.interest)?.cumTotal ?? 0

    const value = this.value.initialValue

    return this.calcYields(income, expenses, interest, value)
  }

  private calcYields(income:number, expenses:number, interest:number, value:number) : Yields {
    // Note expenses and interest will likely be negative
    const grossMargin = income + expenses
    const netMargin = grossMargin + interest
    const totalMargin = netMargin + this.equityGrowth

    const grossYield = (value === 0) ? 0 : this.calcCAGR(value, value + income, this.days)
    const netYield   = (value === 0) ? 0 : this.calcCAGR(value, value + netMargin, this.days)
    const totalYield = (value === 0) ? 0 : this.calcCAGR(value, value + totalMargin, this.days)

    return { 
      value,
      income, expenses, interest, 
      grossMargin, netMargin, totalMargin, 
      grossYield, netYield, totalYield,
    }
  }

  /**
   * Calculate the CAGR required to increase {@link currentValue} to {@link futureValue} in {@link days}
   * 
   * Implement the following calculation: (Math.pow(Math.abs(futureValue / currentValue), 1/days) - 1) * 365
   * 
   * @param currentValue 
   * @param futureValue 
   * @param days 
   * @returns 
   */
  private calcCAGR(currentValue:number, futureValue:number, days:number) {
    if (currentValue === 0) return 0

    const X = futureValue / currentValue
    const Y = Math.pow(Math.abs(X), 1/days)
    const Z = (Y - 1) * 365
    const T = (X < 0) ? Z * -1 : Z    // Deal with negative growth

    logger.debug("currentValue=%f, futureValue=%f, days=%d", currentValue, futureValue, days)
    logger.debug("X=%f, Y=%f, Z=%f, T=%f", X, Y, Z, T)

    return T
  }
  /*
  public metricsAt(date:number) {
    const value = this.valuationCalc?.at(date)?.value ?? 0
    const debt = this.pivotTable.rowAt(date)?.debt
    const equity = value + debt
    const equityRatio = (value === 0) ? 0 : equity / value
    const equityGrowth = equity - this.equity.initialEquity

    return { value, debt, equity, equityRatio, equityGrowth }
  }

  public yieldsAt(row:PivotRow) {
    const start = this.pivotTable.firstRow.startDate
    const end = this.pivotTable.lastRow.endDate
    const days = differenceInCalendarDays(end, start)

    const income = row.cell(ModelKeys.category.income)?.cumTotal ?? 0
    const expenses = row.cell(ModelKeys.category.expense)?.cumTotal ?? 0
    const interest = row.cell(ModelKeys.category.interest)?.cumTotal ?? 0

    const value = this.value.initialValue

    return this.calcYields(income, expenses, interest, value, days)
  }

  public yieldsFrom(first:PivotRow, last:PivotRow) : Yields {
    const days = differenceInCalendarDays(last.date, first.date)

    const yieldsFirst = this.yieldsAt(first)
    const yieldsLast = this.yieldsAt(last)

    const yields:Yields = {
      value:       yieldsLast.value - yieldsFirst.value,
      income:      yieldsLast.income - yieldsFirst.income, 
      expenses:    yieldsLast.expenses - yieldsFirst.expenses, 
      interest:    yieldsLast.interest - yieldsFirst.interest,
      netMargin:   yieldsLast.netMargin - yieldsFirst.netMargin, 
      grossMargin: yieldsLast.grossMargin - yieldsFirst.grossMargin, 
      totalMargin: yieldsLast.totalMargin - yieldsFirst.totalMargin,
      netYield:    0,// this.calcAnnualised(yieldsFirst.netMargin, yieldsLast.netMargin, days), 
      grossYield:  0, // this.calcAnnualised(yieldsFirst.grossMargin, yieldsLast.grossMargin, days), 
      totalYield:  0, //this.calcAnnualised(yieldsFirst.totalMargin, yieldsLast.totalMargin, days),
    }

    return yields
  }

  private calcYields(income:number, expenses:number, interest:number, value:number, days:number) : Yields {
    const grossMargin = income + expenses
    const netMargin = grossMargin + interest
    const totalMargin = netMargin + this.equityGrowth

    const grossYield = (value === 0) ? 0 : this.calcAnnualised(1, income / value, days)
    const netYield   = (value === 0) ? 0 : this.calcAnnualised(1, netMargin / value, days)
    const totalYield = (value === 0) ? 0 : this.calcAnnualised(1, totalMargin / value, days)

    return { 
      value,
      income, expenses, interest, 
      grossMargin, netMargin, totalMargin, 
      grossYield, netYield, totalYield,
    }
  }

  private calcAnnualised(first:number, last:number, days:number) {
    if (first === 0) return 0

    logger.debug("first=%f, last=%f, days=%d", first, last, days)
    logger.debug("Math.abs(last / first)=%f, 1/days=%f", Math.abs(last / first), 1/days)
    logger.debug("Math.pow(Math.abs(last / first), 1/days)=%f", Math.pow(Math.abs(last / first), 1/days))

    return (Math.pow(Math.abs(last / first), 1/days) - 1) * 365
  }
*/
}

export interface Yields {
  value: number,
  income: number, 
  expenses: number, 
  interest: number,
  grossMargin: number, 
  netMargin: number, 
  totalMargin: number,
  grossYield: number, 
  netYield: number, 
  totalYield: number,
}