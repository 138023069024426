import { setProperty } from "../../core/model/Model"
import { ModelKeys, Transaction } from "../../core/model/ModelKeys"
import { Item, ItemProps } from "../../core/types/Item"
import { TransactionItem } from "../../core/types/TransactionItem"
import { formatDate } from "../../core/utils/DateFormat"
import { Logger } from "../../core/utils/Logger"
import { NumberFormats, formatNumber } from '../../core/utils/Numbers'
import { useFormItem } from "../../hooks/context/FormContext"
import { useModel } from '../../hooks/context/ModelContext'
import { useTransferMapper } from "../../hooks/transfer/useTransferMapper"
import { useAtomRef } from "../../hooks/useAtomRef"
import { Editable } from "../controls/editor/Editable"
import { Field, FieldSet, Form, FormBody } from "../controls/form/Form"
import { FormFooter } from "../controls/form/FormFooter"
import { FormHeader } from "../controls/form/FormHeader"
import { ItemPickerTree } from "../controls/select/ItemPickerTree"
import { ShowIfAtom } from "../controls/ShowIf"
import { ItemFormSharedFields } from "./ItemFormSharedFields"

const logger = new Logger("forms.BankTransItemForm")

export function BankTransItemForm(props : {
  item: TransactionItem
  formItemKey?: string
  open?: boolean
  noHeader?: boolean
  onClose: () => void
}) {
  const { model } = useModel()
  const { formItemKey, onClose, noHeader, open } = props
  
  const [ trans, setTrans ] = useFormItem(formItemKey ?? "bankTrans", props.item)
  const openAtom = useAtomRef(open === true)

  const transferMapper = useTransferMapper()

  const numberFormat = Transaction.isInterestRate(trans) ? NumberFormats.percent2 : NumberFormats.currency2

  const icon = model.getItemIcon(trans) || "fal fa-line-columns"

  logger.debug("Rendering: key=%s", trans.key, trans)

  // Render a Card view
  return (
    <Form id="BankTransItemEditorCard">
      { !noHeader &&
        <FormHeader title="Bank Transaction Editor" icon={icon} onClose={onClose} openAtom={openAtom} />
      }
      
      <FormBody>
        <FieldSet>
          <Field>
            <label>Account</label>
            <div className="readonly">{model.getItemName(trans.accountKey)}</div>
          </Field>
          <ShowIfAtom visible={openAtom}>
            <Field className="code">
              <label>Code</label>
              <Editable item={trans} property={ItemProps.code} onChange={onChange} readonly={true} />
            </Field>
          </ShowIfAtom>
          <Field className="name">
            <label>Name</label>
            <Editable item={trans} property={ItemProps.name} onChange={onChange} readonly={false} />
          </Field>
          <Field className="description">
            <label>Description</label>
            <Editable item={trans} property={ItemProps.description} onChange={onChange} readonly={false} />
          </Field>
        </FieldSet>

        <FieldSet>
          <Field>
            <label>Date</label>
            <div className="readonly">{formatDate(trans.startDate)}</div>
          </Field>
          <Field>
            <label>Amount</label>
            <div className="readonly">{formatNumber(trans.value, numberFormat)}</div>
          </Field>
        </FieldSet>

        <FieldSet>
          <Field>
            <label>Category</label>
            <ItemPickerTree
              rootKey={ModelKeys.category.root}
              selected={model.getItem(trans.categoryKey)}
              onChange={onChangeCategory}
              placeholder="Select a Category..."
            />
          </Field>
          <Field>
            <label>Rule</label>
            <div className="readonly">{model.getItemName(trans.ruleKey)}</div>
          </Field>
          <Field>
            <label>Balance</label>
            <div className="readonly">{formatNumber(trans.balance, numberFormat)}</div>
          </Field>
        </FieldSet>

        <ItemFormSharedFields item={trans} onChange={onChange} readonly={true} openAtom={openAtom} />
      </FormBody>
      
      { !noHeader &&
        <FormFooter showClose showDelete showSave onClose={onClose}/>
      }
    </Form>
  )
  
  function onChange(trans:TransactionItem, property:any, value:any) {
    const newTrans = setProperty({...trans}, property, value)
    setTrans(newTrans)
  }

  /**
   * The user has selected the category, ensure consistency
   * @param trans 
   * @param category 
   */
  function onChangeCategory(category:Item) {
    const ruleKey = ModelKeys.bank.ruleOveride
    const { categoryKey, typeKey } = transferMapper.getSafeCategory(trans.categoryKey, trans.typeKey, category.key)

    const newTrans = {...trans, categoryKey, typeKey, ruleKey }
    setTrans(newTrans)
  }
}
