import { Outlet, useLocation } from "react-router-dom"
import { Logger } from "../../core/utils/Logger"
import { ViewContextProvider } from "../../hooks/context/ViewContext"
import { JemstoneHeader } from "../layouts/JemstoneHeader"
import { JemstoneSidebar } from "../layouts/JemstoneSidebar"
import { ViewMenu } from "../views/ViewMenu"
import { getWizardRoute, wizardRoutes } from "./WizardRoutes"

const logger = new Logger("wizards.WizardView")

export function WizardView(props:any) {
  return (
    <ViewContextProvider>
      <JemstoneHeader title={<Title/>}>
        <ViewMenu showTheme showDocs showWizard />
      </JemstoneHeader>
      
      <JemstoneSidebar/>
      
      <main id="WizardView" className="wizard">
        <Outlet/>
      </main>
    </ViewContextProvider>
  )
}

function Title() {
  const location = useLocation()

  let route = location.state?.route
  if (!route) {
    route = getWizardRoute(location.pathname)
  }
  if (!route) {
    route = wizardRoutes[0]
  }

  logger.debug("Rendering: location=%o, title=%s", location, route.name)

  return <>{route.name}</>
}
