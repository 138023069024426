import { atom, PrimitiveAtom, useAtom } from "jotai"

export const showJsonAtom = atom(false)

export function ButtonShowJson({ jsonAtom } : { jsonAtom?: PrimitiveAtom<boolean> }) {
  const [ showJson, setShowJson ] = useAtom(jsonAtom ?? showJsonAtom)

  return (
    <button className={showJson ? " active" : ""} title="Show JSON code"
            onClick={() => setShowJson(!showJson)}>
      <i className="fal fa-brackets-curly"/>
    </button>
  )
}