import { ModelKeys } from "../../../core/model/ModelKeys"
import { Logger } from "../../../core/utils/Logger"
import { FormDataProvider, useFormItem } from "../../../hooks/context/FormContext"
import { useModel } from "../../../hooks/context/ModelContext"
import { WizardProvider } from "../context/WizardContext"
import { SelectAccountParent } from "../shared/SelectAccountParent"
import { SelectAdditionalItems } from "../shared/SelectAdditionalItems"
import { WizardFormPages } from "../WizardFormPages"
import { WizardSavePage } from "../WizardSavePage"

const logger = new Logger("wizards.PortfolioWizard")

const pageIds = [
  "SelectAccountParent", 
  "SelectAdditionalItems",
  "WizardSavePage"
]

const primaryItemProp = "portfolioItem"

export function PortfolioWizard() {
  logger.debug("Rendering:")

  return (
    <WizardProvider name="PortfolioWizard" pageIds={pageIds} primaryItemProp={primaryItemProp}>
      <FormDataProvider>
        <SelectPortfolioParent />
        <SelectAdditionalItems/>
        <WizardFormPages pageIds={pageIds} />
        <WizardSavePage />
      </FormDataProvider>
    </WizardProvider>
  )
}

  /**
   * This serves to initialize the accountItem in the wizard formData, which is
   * always of type Portfolio. Note that we then reuse SelectAccountParent.
   * @returns response from {@link SelectAccountParent}
   */
  function SelectPortfolioParent() {
    const { model } = useModel()
    useFormItem(primaryItemProp, () => model.newItem("&!$@", ModelKeys.asset.portfolio))

    return <SelectAccountParent/>
  }
