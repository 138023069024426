import { Item, setItemPropNames } from "./Item"

/**
 * Have implemented a data driven typing system. Each Item in the model has a typeKey which
 * points to a TypeItem, which can then be used to drive different behaviours.
 * TypeItem defines some additional properties unique to a type.
 */
export interface TypeItem extends Item {
  /** Icon className (as per fontawesome) for items of this type */
  typeIcon: string

  /** List of ChartType keys that override the default ChartType for corresponding measureKey */
  chartKeys: string[]
}

type TypeItemProp = keyof TypeItem

type TypeItemRecord = Record<TypeItemProp, TypeItemProp>

export const TypeItemProps:TypeItemRecord = {
  key: "key",
  code: "code",
  name: "name",
  description: "description",
  parentKey: "parentKey",
  typeKey: "typeKey",
  links: "links",
  sortOrder: "sortOrder",
  status: "status",
  modifiedDate: "modifiedDate",
  typeIcon: "typeIcon",
  chartKeys: "chartKeys"
}

setItemPropNames(TypeItemProps)

export const TypeItemPropNames = Object.keys(TypeItemProps) as TypeItemProp[]


