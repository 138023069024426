import { PrimitiveAtom, atom, useAtom } from "jotai"
import { Logger } from "../core/utils/Logger"

const logger = new Logger("hooks.useAtomMap")

export type AtomMapType<T> = Map<string, Map<string,PrimitiveAtom<T>>>

const atomMapRegistry:AtomMapType<any>[] = []

export function newAtomMap<T>() : AtomMapType<T> {
  const atomMap = new Map<string, Map<string,PrimitiveAtom<T>>>()
  atomMapRegistry.push(atomMap)
  return atomMap
}

export function useAtomMap<T>(
  atomMap: AtomMapType<T>, 
  initValue: () => T,
  mapKey:  string,
  itemKey: string,
) : [ T, (newValue:T) => void, PrimitiveAtom<T> ]
{
  logger.trace("useAtomMap: mapKey=%s, itemKey=%s", mapKey, itemKey)

  // See if we have a map for mapKey - create one if not
  let itemAtomMap = atomMap.get(mapKey)
  if (!itemAtomMap) {
    itemAtomMap = new Map<string, PrimitiveAtom<T>>()
    atomMap.set(mapKey, itemAtomMap)
  }

  // See if we have an atom for itemKey - create one if not
  let valueAtom = itemAtomMap.get(itemKey)
  if (!valueAtom) {
    valueAtom = atom<T>(initValue())
    itemAtomMap.set(itemKey, valueAtom)
  }

  const [value, setValue] = useAtom(valueAtom)

  return [ 
    value, 
    (newValue:T) => setValue(newValue), 
    valueAtom 
  ]
}

export function resetAtomMaps() {
  logger.debug("resetAtomMaps: Clearing %d atomMaps", atomMapRegistry.length)
  
  for (const atomMap of atomMapRegistry) {
    atomMap.clear()
  }
}
