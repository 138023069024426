import { ReactNode } from "react"
import { clzName } from "../../core/utils/Utils"

export function Card(props:{
  id?: string
  open?: boolean
  className?: string
  children: ReactNode
  onClick?: (e:any) => void
}) {
  const { id, open, children, onClick } = props

  const className = clzName((open !== false ? "open" : "closed"), props.className)

  return (
    <article className={className} id={id} onClick={onClick}>
      {children}
    </article>
  )
}

export function CardHeader({ id, className, children, onClick }:{
  id?: string
  className?: string
  children: ReactNode
  onClick?: (e:any) => void
}) {
  return (
    <header className={className} id={id} onClick={onClick}>
      {children}
    </header>
  )
}

export function CardSection({ id, className, children, onClick }:{
  id?: string
  className?: string
  children: ReactNode
  onClick?: (e:any) => void
}) {
  return (
    <section className={className} id={id} onClick={onClick}>
      {children}
    </section>
  )
}

export function CardFooter({ id, className, children, onClick }:{
  id?: string
  className?: string
  children: ReactNode
  onClick?: (e:any) => void
}) {
  return (
    <footer className={className} id={id} onClick={onClick}>
      {children}
    </footer>
  )
}
