import { Model } from '../../../core/model/Model'
import { ModelKeys } from '../../../core/model/ModelKeys'
import { createItems } from "../../../core/service/ModelAction"
import { Item } from "../../../core/types/Item"
import { TypeItem } from '../../../core/types/TypeItem'
import { Logger } from "../../../core/utils/Logger"
import { useModel } from '../../../hooks/context/ModelContext'
import { useViewContext } from '../../../hooks/context/ViewContext'
import { AccountPicker } from '../select/AccountPicker'
import { Menu } from './Menu'

const logger = new Logger("control.MenuCreate")

export function MenuCreate({ rootKey, menuItemKeys }: { rootKey:string, menuItemKeys?:string[] }) {
  const { model } = useModel()
  const { viewState } = useViewContext()

  const itemName = model.getItemName(rootKey)
  const title = "Add item to " + itemName
  
  const menuItems = (menuItemKeys ?? []).map(key => model.getItem<TypeItem>(key))
                                        .filter(menuItem => (menuItem !== undefined))
  model.sortItems(menuItems)

  logger.trace("Rendering: rootKey=%s", rootKey, menuItemKeys, menuItems)

  return (
    <Menu buttonClass="CreateMenu" buttonImage="far fa-plus" title={title}>
      { menuItems.map(menuItem =>
        <MenuItem menuItem={menuItem} key={menuItem.key} />
      )}
      {/* <button className="menuitem" title={`Add an existing account to ${itemName}`} disabled>
        <i className="fal fa-link" />
        Add Linked Account
      </button> */}
      < div className="divider"/>
      <AccountPicker item={model.getItem(rootKey)} 
                     title={`Add an existing account to ${itemName}`}
                     label={<LinkLabel/>} 
                     buttonImage=""
                     placement="left"
                     onChange={onCreateLink}
                     showAccounts showAssets showProperties />
    </Menu>
  )

  function LinkLabel() {
    return (
      <>
        <i className="fal fa-link" />
        Add Account Link
      </>
    )
  }

  function MenuItem(props:any) {
    const menuItem = props.menuItem
    const typeItem = model.getItemType(menuItem)

    return (
      <button className="menuitem" onClick={() => onCreateItem(menuItem)} 
              title={`Add a new ${typeItem.name} to ${itemName}`}>
        <i className={typeItem.typeIcon} />
        Add {menuItem.name}
      </button>
    )
  }

  function onCreateItem(menuItem:Item) {
    // Create a child of the rootKey, or of the first selected item
    const selectedKeys = viewState.getSelectedKeys()
    const parentKey = (selectedKeys.length > 0) ? selectedKeys[0] : rootKey
    const typeKey = model.getItemType(menuItem).key

    logger.debug("onCreate: parentKey=%s, typeKey=%s, menuItem=%o", parentKey, typeKey, menuItem)

    // Create a new item, with same type as it's sibling
    const newItem = model.newItem(parentKey, typeKey, Model.KeyPrefixPortfolioItem)

    const event = createItems(model, [newItem])

    // Notify the ViewContextContext
    viewState.onEvent(event)
    viewState.clearSelected()
  }

  function onCreateLink(targetItem:Item) {
    // Create a new item, with same type as it's sibling
    const newItem = model.newItem(rootKey, ModelKeys.type.link, Model.KeyPrefixPortfolioItem)
    newItem.links = [targetItem.key]
    newItem.name = "Link to " + targetItem.name
    createItems(model, [newItem])
  }
}
