import { Duration, add, differenceInCalendarDays, endOfDay, subDays } from "date-fns"
import { getBankStmtModel } from "../atom/BankStmtModelAtoms"
import { getInvestmentModel } from "../atom/InvestmentModelAtoms"
import { Filter } from "../filter/Filter"
import { LoanAmortization } from "../invest/LoanAmortization"
import { Model } from "../model/Model"
import { Asset, Category, ModelKeys, Transaction } from "../model/ModelKeys"
import { AssetItem } from "../types/AssetItem"
import { CashflowItem } from "../types/CashflowItem"
import { Item, ItemStatus } from "../types/Item"
import { TransactionItem } from "../types/TransactionItem"
import { Counters } from "../utils/Counters"
import { Logger } from "../utils/Logger"
import { formatCurrency, isZero, round } from '../utils/Numbers'
import { getUniqueId } from "../utils/Utils"
import { CashflowFilter } from "./CashflowFilter"
import { TransferMapper } from "./TransferMapper"

export class CashflowBuilder {
  private model: Model
  private asset: AssetItem
  private account: Item
  private built: boolean = false

  private isAmortization: boolean
  private isUnitised: boolean
  private startDate: number
  private finishDate: number
  private interestRate: number
  private interestFrequency: Duration

  private cashflowItems: CashflowItem[] = []

  /** Utility class for filtering */
  private cashflowFilter: CashflowFilter

  /** For each transfer In/Out, a list of cashflow items generated from that */
  private transferItemsMap = new Map<string,CashflowItem[]>()

  private transferMapper:TransferMapper

  private logger = new Logger("builders.CashflowBuilder")

  constructor(model:Model,
              asset:AssetItem,
              account:Item,
              isAmortization:boolean,
              startDate:number, 
              finishDate:number, 
              interestRate:number, 
              interestFrequency:Duration = {months:1}) {
    this.model = model
    this.asset = asset
    this.account = account
    this.isAmortization = isAmortization
    this.isUnitised = Asset.isUnitised(account)
    this.startDate = startDate
    this.finishDate = finishDate
    this.interestRate = interestRate || asset.rate || 0
    this.interestFrequency = interestFrequency

    this.cashflowFilter = new CashflowFilter(model)
    this.transferMapper = new TransferMapper(model)

    this.logger.setContext(this.account?.name)
    this.logger.debug("New cashflow created: asset=%s, %s", this.asset?.name, Logger.Range(this.startDate, this.finishDate))
  }

  public addContext(context:string) : CashflowBuilder {
    this.logger.addContext(context)
    return this
  }

  public clone() {
    return new CashflowBuilder(
      this.model,
      this.asset,
      this.account,
      this.isAmortization,
      this.startDate,
      this.finishDate,
      this.interestRate,
      this.interestFrequency
    )
  }

  public getAsset() {
    return this.asset
  }

  public getCashflowItems() : CashflowItem[] {
    if (!this.built) {
      this.build()
    }    
    return this.cashflowItems
  }

  public getDateRange(items:CashflowItem[]) {
    const start = items.at(0)?.date
    const end = items.at(-1)?.date
    return { start, end }
  }

  public get length() {
    return this.cashflowItems.length
  }

  public getOpeningBalance() : CashflowItem | undefined {
    if (this.cashflowItems.length === 0) {
      return undefined
    }

    // Find item with earliest date
    let balance = undefined
    for (const item of this.cashflowItems) {
      if (balance === undefined || item.date < balance.date) {
        if (Category.isBalance(item)) {
          balance = item
        }
      }
    }
    return balance
  }

  public reset(startDate:number, finishDate:number, interestRate?:number, interestFrequency?:Duration) : CashflowBuilder {
    // Set parameters
    this.startDate = startDate
    this.finishDate = finishDate
    this.interestRate = interestRate ?? this.interestRate
    this.interestFrequency = interestFrequency ?? this.interestFrequency

    // Clear the cashflow
    this.cashflowItems = []
    this.transferItemsMap.clear()

    this.logger.debug("reset: cashflow is reset")
    this.built = false
    return this
  }

  public build() : CashflowBuilder {
    this.logger.start("build", "Building %d cashflow items, built=%s", this.cashflowItems.length, this.built)
    
    // Calculate interest and balances
    if (this.isUnitised) {
      this.calcUnitisedEarningsAndBalance(this.cashflowItems)
    } else {
      this.calcInterestAndBalance(this.cashflowItems)
    }

    // Built now
    this.built = true

    Counters.set(this.logger.name, "build", this.account.name, this.cashflowItems.length)

    // logCashflowItems(this.cashflowItems)

    const { start, end } = this.getDateRange(this.cashflowItems)

    this.logger.finish("build", "Built %d cashflow items, %s, built=%s", 
                      this.cashflowItems.length, Logger.Range(start,end), this.built)
    return this
  }

  public addItem(item: CashflowItem) : CashflowBuilder {
    this.cashflowItems.push(item)
    this.built = false
    return this
  }

  public addItems(items: CashflowItem[]) : CashflowBuilder {
    for (const item of items) {
      this.cashflowItems.push(item)
    }
    
    this.built = false

    const { start, end } = this.getDateRange(items)

    this.logger.debug("addItems: Added %d items (%d total), %s, built=%s", 
                      items.length, this.cashflowItems.length, Logger.Range(start,end), this.built)
    return this
  }

  public addCashflow(builder: CashflowBuilder | undefined) : CashflowBuilder {
    if (builder) {
      const items = builder.getCashflowItems()
      for (const item of items) {
        this.cashflowItems.push({...item})
      }

      this.built = false

      const { start, end } = this.getDateRange(items)

      this.logger.debug("addCashflow: Added %d items (%d total) from %s, %s, built=%s", 
                        items.length, this.cashflowItems.length, builder.account?.name, Logger.Range(start,end), this.built)
    }
    return this
  }

  public addTransactions(transactions: TransactionItem[]) : CashflowBuilder {
    this.logger.start("addTransactions", "Adding %d transactionItems", transactions.length)

    const accountTypeKey = getAccountTypeKey(this.model, this.account.key)

    let count = 0
    for (const trans of transactions) {
      const relatedTrans = this.model.getItem<TransactionItem>(trans.relatedTransactionKey)
      const startDate  = trans.startDate || this.startDate
      const finishDate = endOfDay(trans.finishDate || relatedTrans?.finishDate || this.finishDate).getTime()

      // Special handling for transfers where cashflow for other leg has been built
      if (relatedTrans && this.addTransferItems(trans, relatedTrans, accountTypeKey)) {
        continue
      }

      // Create cashflow items for date range
      if (trans.value !== 0 && startDate >= this.startDate) {
        const value = Transaction.isCredit(trans) || 
                      Transaction.isTransferOut(trans) ? -trans.value : trans.value
        const name = this.model.getItemName(trans)

        for (let date = startDate;  date < finishDate; ) {
          const item:CashflowItem = {
            key: newCashflowItemKey(),
            parentKey: trans.key,
            assetKey: this.asset.key,
            accountKey: this.account.key,
            accountTypeKey: accountTypeKey,
            categoryKey: trans.categoryKey,
            typeKey: trans.typeKey,
            name: name,
            date: date,
            amount: value,
            balance: 0,
            calculated: false,
            bankStmt: false,
            sortOrder: date,
            status: ItemStatus.TRANSIENT,
          }
          this.cashflowItems.push(item)
          count++

          // Keep track of related transactions
          if (relatedTrans) {
            this.saveTransferItem(trans, item)
          }

          if (trans.frequency) {
            const nextDate = add(date, trans.frequency).getTime()
            if (nextDate === date) {
              break
            }
            date = Math.min(nextDate, finishDate)
          } else {
            break
          }
        }
      }
    }

    this.built = false

    const start = transactions.at(0)?.startDate
    const end = transactions.at(-1)?.startDate

    this.logger.finish("addTransactions", "Added %d cashflowItems (of %d total), %d transferItems, %s, built=%s", 
                      count, this.cashflowItems.length, this.transferItemsMap.size, Logger.Range(start,end), this.built)
    return this
  }

  public addBankStatements() {
    this.logger.start("addBankStatements", "Adding bank stmts for account '%s'", this.account.code)

    const accountTypeKey = getAccountTypeKey(this.model, this.account.key)

    const bankStmtModel = getBankStmtModel()
    const { transactions } = bankStmtModel.getAccountTransactions(this.account)

    const cashflowItems:CashflowItem[] = []

    // Add transactions from all bank statements for specified account (in date descending order)
    for (const trans of transactions) {
      // Only include transactions for this account
      if (trans.accountKey === undefined || trans.accountKey === this.account.key) {
        // Assign a safe category and type
        const { categoryKey, typeKey } = this.transferMapper.getSafeCategoryForValue(trans.categoryKey, trans.typeKey, trans.value)

        cashflowItems.push({
          key:            newCashflowItemKey(),
          parentKey:      trans.key,
          assetKey:       this.asset.key,
          accountKey:     this.account.key,
          accountTypeKey: accountTypeKey,
          categoryKey:    categoryKey,
          typeKey:        typeKey,
          name:           trans.name,
          description:    trans.description,
          date:           trans.startDate || 0,
          amount:         trans.value,
          balance:        trans.balance || 0,
          calculated:     false,
          bankStmt:       true,
          sortOrder:      trans.sortOrder,
          status:         ItemStatus.TRANSIENT,
        })
      }
    }

    if (cashflowItems.length === 0) {
       this.logger.finish("addBankStatements", "%d/%d transactions found for account '%s'", 
                          cashflowItems.length, transactions.length, this.account.code)
      return this
    }

    // Add opening balance
    const opening = cashflowItems[cashflowItems.length-1]
    const closing = cashflowItems[0]

    const openingDate = subDays(opening.date,1).getTime()
    const openingBalance = opening.balance - opening.amount

    if (openingBalance) {
      const balanceCategory = this.model.getItem(ModelKeys.category.balance)
      const balanceItem:CashflowItem = {
        key: newCashflowItemKey(),
        parentKey: newCashflowItemKey(),
        assetKey: this.asset.key,
        accountKey: this.account.key,
        accountTypeKey: accountTypeKey,
        categoryKey: balanceCategory.key,
        typeKey: balanceCategory.typeKey,
        name: "Opening Balance**",
        date: openingDate,
        amount: openingBalance,
        balance: openingBalance,
        sortOrder: openingDate,
        calculated: false,
        bankStmt: true,
        status: ItemStatus.TRANSIENT,
      }
      cashflowItems.push(balanceItem)

      this.logger.debug("Created opening balance: date=%s, balance=%f", Logger.Date(balanceItem.date), balanceItem.balance)
    }

    // Add existing cashflow items that are before minDate or after maxDate. This has the effect of overwriting
    // budgeted cashflow items with actuals from the bank statement.
    const closingDate = endOfDay(closing.date).getTime()
    for (const item of this.cashflowItems) {
      if (item.date > closingDate || Transaction.isInterestRate(item)) {
        cashflowItems.push(item)
      }
    }

    // Then set new list
    this.cashflowItems = cashflowItems

    // Done
    this.built = false
    this.logger.finish("addBankStatements", "Added %d items (of %d total), %s, built=%s", 
                      cashflowItems.length, this.cashflowItems.length, Logger.Range(openingDate, closingDate), this.built)
    return this
  }

  protected calcInterestAndBalance(cashflowItems:CashflowItem[]) {
    this.logger.start("calcInterestAndBalance", "Processing %d items, interestRate=%f, built=%s", cashflowItems.length, this.interestRate, this.built)

    const earningsCategory = this.model.getItem(ModelKeys.category.earnings)
    const interestCategory = this.model.getItem(ModelKeys.category.interest)

    const offsetCashflow = getInvestmentModel((this.account as any).offsetAccountKey)?.cashflow
    const offsetCashflowItems = offsetCashflow?.getCashflowItems()
    this.logger.debug("Offset account: %s, items=%d", offsetCashflow?.account?.name, offsetCashflowItems?.length)

    // Sort by date ascending
    cashflowItems.sort((i1,i2) => i1.sortOrder - i2.sortOrder)

    // Variables used for accrued interest calcs
    let prevItemDate = this.startDate
    let balance = 0
    let interestAccrued = 0
    let interestRate = this.interestRate
    let loanPaymentAmount
    let offsetIndex = 0

    // Calculate balance and interest
    for (let i=0;  i < cashflowItems.length;  i++) {
      const item = cashflowItems[i]

      // item.calculated will be true when the item is being aggregated from another account cashflow
      if (item.calculated) {
        const amount = Transaction.isInterestRate(item) ? 0 : item.amount
        item.balance = round(balance += amount, 2)

        if (Transaction.isInterest(item)) {
          interestAccrued = 0
        }

        // Record the date of last transaction
        prevItemDate = item.date
        continue
      }

      // Bank statement items determine the balance for a single account
      if (item.bankStmt) {
        balance = item.balance

        if (Transaction.isInterest(item)) {
          interestAccrued = 0
        }

        // Set calculated flag, and record the date of last transaction
        item.calculated = true
        prevItemDate = item.date
        continue
      }
      
      // If this loan has an offset account, then calculate the offset balance used to calculate interest
      let offsetBalance = balance
      if (offsetCashflowItems) {
        offsetIndex = this.findLatestFrom(offsetCashflowItems, offsetIndex, item.date)
        if (offsetIndex >= 0) {
          item.offsetBalance = offsetCashflowItems[offsetIndex]?.balance
          offsetBalance = Math.min(balance + (cashflowItems.at(i-1)?.offsetBalance || 0), 0)
        }
      }

      // Calculate interest accrued since the last transaction, and add to running total
      item.days = differenceInCalendarDays(item.date, prevItemDate)
      item.interestRate = interestRate
      item.interest = round(offsetBalance * (item.interestRate/365 * item.days), 2)
      interestAccrued += item.interest

      // If this is an Interest Payment transaction, then pay accrued interest and reset
      if (Transaction.isInterest(item)) {
        item.amount = interestAccrued
        interestAccrued = 0
      }

      // If an interest rate change, new rate is in item.amount
      else if (Transaction.isInterestRate(item)) {
        interestRate = item.amount
      }

      // If a loan repayment, handle final payment including interest
      else if (this.isAmortization && Category.isPayment(item)) {
        // Increase the loan repayment if required
        if (loanPaymentAmount !== undefined) {
          item.amount = Math.max(loanPaymentAmount, item.amount)
          item.name = `Repayment - minimum is ${formatCurrency(loanPaymentAmount)}`
        }

        const outstanding = -(balance + interestAccrued)
        if (item.amount > outstanding) {
          item.amount = outstanding

          // Truncate the cashflow at the final payment
          cashflowItems.length = i+1
          this.truncateTransferItems(item.date)

          // Add final interest payment if required
          if (interestAccrued !== 0) {
            cashflowItems.push({
              key: newCashflowItemKey(),
              parentKey: item.parentKey,
              assetKey: item.assetKey,
              accountKey: item.accountKey,
              accountTypeKey: item.accountTypeKey,
              typeKey: interestCategory.typeKey,
              categoryKey: interestCategory.key,
              name: "Final Interest Payment",
              date: item.date,
              amount: 0,
              balance: 0,
              calculated: false,
              sortOrder: item.date,
              status: ItemStatus.TRANSIENT,
            })
          }
        }
      }

      // A balance adjustment transaction overrides the calculated balance
      if (Category.isBalance(item)) {
        const interest = item.amount - balance
        item.balance = item.amount
        balance = item.balance
        interestAccrued = 0

        if (i !== 0) {
          item.amount = item.interest = interest
          if (this.model.isAsset(item.accountTypeKey)) {
            item.categoryKey = earningsCategory.key
            item.typeKey = earningsCategory.typeKey
          }
        }
        
        this.logger.debug("Balance Trans: balance=%f, item={calculated=%s, amount=%f, balance=%f}", 
                          balance, item.calculated, item.amount, item.balance)
      } 
      
      // Update the accumulated balance, and set as item.balance
      else {
        const isInterestRate = Transaction.isInterestRate(item)
        const amount = isInterestRate ? 0 : item.amount
        item.balance = round(balance += amount, 2)

        // If a loan then recalculate the payment amount so we don't extend the term
        if (this.isAmortization && isInterestRate) {
          loanPaymentAmount = this.calcRevisedLoanPayment(item)
        }
      }

      // Set calculated flag, and record the date of last transaction
      item.calculated = true
      prevItemDate = item.date
    }

    // Remove zero interest/earnings transactions
    let zeroInterest = 0
    for (let i=1;  i < cashflowItems.length; ) {
      const item = cashflowItems[i]
      if (isZero(item.amount) && Transaction.isInterest(item)) {
        cashflowItems.splice(i, 1)
        zeroInterest++
      } else {
        i++
      }
    }

    this.logger.finish("calcInterestAndBalance", "Processed %d items, interestRate=%f, removed %d zero interest, built=%s", 
                        cashflowItems.length, this.interestRate, zeroInterest, this.built)
  }

  private calcRevisedLoanPayment(item:CashflowItem) {
    const loan:any = {
      ...this.account,
      principal: -item.balance,
      startDate: item.date,
      term: undefined,
      rate: item.amount
    }

    const amort = new LoanAmortization(loan)
    this.logger.debug("calcRevisedLoanPayment: loan=%o", amort.logItem)
    return amort.paymentAmount
  }

  private calcUnitisedEarningsAndBalance(cashflowItems:CashflowItem[]) {
    this.logger.start("calcUnitisedEarningsAndBalance", "Processing %d items, built=%s", cashflowItems.length, this.built)

    // Sort by date ascending
    cashflowItems.sort((i1,i2) => i1.sortOrder - i2.sortOrder)

    // Variables used for accrued earnings calcs
    let balance = 0

    // Calculate balance and interest
    for (let i=0;  i < cashflowItems.length;  i++) {
      const item = cashflowItems[i]
      const prevItem = cashflowItems.at(i-1)
      const prevBalance = prevItem?.balance || 0
      const prevItemDate = prevItem?.date || item.date

      item.earnings = prevItem?.earnings || 0
      item.interest = prevItem?.interest || 0

      // item.calculated will be true when the item is being aggregated from another account cashflow
      if (item.calculated) {
        const amount = Transaction.isInterestRate(item) ? 0 : item.amount
        item.balance = round(balance += amount, 2)

        // Move to next transaction
        continue
      }

      // Bank statement items define the balance and the amount
      if (item.bankStmt) {
        item.earnings += (item.balance - item.amount - prevBalance)
        balance = item.balance

        // Set calculated flag, and move to next transaction
        item.calculated = true
        continue
      }

      // Detect a unit price change by looking at type of parent TransactionItem.
      // If a unit price change then set item.balance, and adjust item.amount to the delta
      if (this.model.getItemTypeKey(item.parentKey) === ModelKeys.type.valuation) {
        if (Math.abs(item.amount) >= 0.005) {
          item.balance = balance = item.amount
          item.amount  = item.balance - prevBalance + item.earnings
          
          item.earnings = 0
          item.interest = 0
        }
      }

      // Calculate earnings accrued since the last transaction, and adjust balance
      else if (Category.isEarnings(item)) {
        const parent = this.model.getItemParent(item)
        if ((parent as TransactionItem)?.unitPrice === undefined) {
          item.days = differenceInCalendarDays(item.date, prevItemDate)
          item.interestRate = this.interestRate
          item.interest += round(prevBalance * (item.interestRate/365 * item.days), 2)  
        }

        item.amount = item.earnings + item.interest
        item.balance = balance = prevBalance + item.interest

        item.earnings = 0
        item.interest = 0
      }

      // Must be a forecast transaction
      else {
        item.days = differenceInCalendarDays(item.date, prevItemDate)
        item.interestRate = this.interestRate
        item.interest += round(prevBalance * (item.interestRate/365 * item.days), 2)

        item.balance = round(balance += item.amount, 2)
      }
  
      item.calculated = true
    }

    // Remove zero earnings transactions
    let zeroInterest = 0
    for (let i=1;  i < cashflowItems.length; ) {
      const item = cashflowItems[i]
      if (isZero(item.amount) && Transaction.isInterest(item)) {
        cashflowItems.splice(i, 1)
        zeroInterest++
      } else {
        i++
      }
    }

    this.logger.finish("calcUnitisedEarningsAndBalance", "Processed %d items, removed %d zero interest, built=%s", 
                        cashflowItems.length, zeroInterest, this.built)
  }

  private addTransferItems(trans:TransactionItem, relatedTrans:TransactionItem, accountTypeKey:string) : boolean {
    // Get investment model for transfer account, then get transfer items for the related transactions
    const relatedCashflow = getInvestmentModel(relatedTrans.parentKey)?.cashflow

    const transferItems = relatedCashflow?.transferItemsMap.get(relatedTrans.key)
    if (!transferItems || transferItems.length === 0) {
      return false
    }

    const { relatedCategoryKey, relatedTypeKey } = this.transferMapper.getSafeRelatedCategory(relatedTrans.categoryKey, relatedTrans.typeKey)

    for (const item of transferItems) {
      this.cashflowItems.push({
        ...item,
        key:            newCashflowItemKey(),
        parentKey:      trans.key,
        assetKey:       this.asset.key,
        accountKey:     this.account.key,
        accountTypeKey: accountTypeKey,
        categoryKey:    relatedCategoryKey,
        typeKey:        relatedTypeKey,
        amount:         -item.amount,
      })
    }

    return true
  }

  private saveTransferItem(trans:TransactionItem, item:CashflowItem) {
    let transferItems = this.transferItemsMap.get(trans.key)
    if (!transferItems) {
      transferItems = []
      this.transferItemsMap.set(trans.key, transferItems)
    }
    transferItems.push(item)
  }

  private truncateTransferItems(endDate:number) {
    for (const transferItems of this.transferItemsMap.values()) {
      for (let i=0;  i < transferItems.length;  i++) {
        if (transferItems[i].date > endDate) {
          transferItems.length = i
          break
        }
      }
    }
  }

  public filterBy(filter:Filter) : CashflowItem[] {
    return this.cashflowFilter.filterBy(filter, this.getCashflowItems())
  }

  public countBy(item:Item) {
    return this.cashflowFilter.countBy(item, this.getCashflowItems())
  }

  public findLatest(date = Date.now()) : CashflowItem | undefined {
    const items = this.getCashflowItems()
    let found
    for (let i=0, length = items.length;  i < length;  i++) {
      const item = items[i]
      if (item.date <= date) {
        found = item
      } else if (found) {
        return found
      }
    }
    return undefined
  }

  public findLatestFrom(items:CashflowItem[], start:number, date:number) : number {
    let found = -1
    for (let i=start, length = items.length;  i >= 0 && i < length;  i++) {
      const item = items[i]
      if (item.date <= date) {
        found = i
      } else if (found >= 0) {
        return found
      }
    }
    return -1
  }
}

export function getAccountTypeKey(model:Model, accountKey:string) {
  let count = 0
  let accountType = model.getItemType(accountKey) as Item

  while (accountType.parentKey !== ModelKeys.account.root && 
         accountType.parentKey !== ModelKeys.root && count < 10) {
    accountType = model.getItemParent(accountType)
    count++
  }
  return accountType.key
}

export function newCashflowItemKey() {
  return "CFI-" + getUniqueId()
}
