import { atom } from 'jotai'
import { BankStmtModelAtoms } from '../../core/atom/BankStmtModelAtoms'
import { ModelAtoms } from '../../core/atom/ModelAtoms'
import { Model } from '../../core/model/Model'
import { RankedSearchOptions, Rankings, searchAndRankItems, sortRankedItems } from '../../core/model/RankedSearch'
import { BankStmtFileItem } from '../../core/types/BankStmtFileItem'
import { ItemProps } from '../../core/types/Item'
import { TransactionItem } from '../../core/types/TransactionItem'
import { Logger } from '../../core/utils/Logger'
import { getAtomValue, setAtomValue } from '../../core/atom/AtomUtils'

const logger = new Logger("atoms.BankStmtTransAtoms")

export interface BankStmtTransModalAtom {
  title: string
  transactions: TransactionItem[]
}

export class BankStmtTransAtoms {
  public static searchValue = atom("")

  public static showAllTransactions = atom(true)

  public static bankStmtFile = atom<BankStmtFileItem>(Model.root as BankStmtFileItem)
  
  public static modalTransactions = atom<BankStmtTransModalAtom | undefined>(undefined)

  public static reset() {
    setAtomValue(BankStmtTransAtoms.searchValue, "")
    setAtomValue(BankStmtTransAtoms.showAllTransactions, true)
    setAtomValue(BankStmtTransAtoms.bankStmtFile, Model.root as BankStmtFileItem)
    setAtomValue(BankStmtTransAtoms.modalTransactions, undefined)

    getAtomValue(BankStmtTransAtoms.searchResults)
    getAtomValue(BankStmtTransAtoms.uncategorised)
  }

  public static allTransactions = atom<TransactionItem[]>(
    (get) => {
      const bankStmtModel = get(BankStmtModelAtoms.model)
      const bankStmtItem = get(BankStmtTransAtoms.bankStmtFile)
      if (bankStmtModel && bankStmtItem) {
        return bankStmtModel.getTransactions([bankStmtItem]).transactions
      }
      return []
    }
  )

  public static transactions = atom<TransactionItem[]>(
    (get) => {
      const showAll = get(BankStmtTransAtoms.showAllTransactions)
      return showAll ? get(BankStmtTransAtoms.allTransactions) : get(BankStmtTransAtoms.uncategorised)
    }
  )

  public static uncategorised = atom<TransactionItem[]>(
    (get) => get(BankStmtTransAtoms.allTransactions).filter(trans => trans.categoryKey === undefined)
  )

  public static searchResults = atom<TransactionItem[]>(
    (get) => {
      let transactions = get(BankStmtTransAtoms.transactions)  
      let length = transactions.length
    
      const searchValue = get(BankStmtTransAtoms.searchValue)
      const searchOptions = get(BankStmtTransAtoms.rankedSearchOptions)

      if (searchValue && searchValue.length > 0) {
        const rankedItems = searchAndRankItems(transactions, searchValue, searchOptions)
        transactions = sortRankedItems(rankedItems).map(ri => ri.item)
        // logger.debug("rankedItems=%o", rankedItems)
      }
    
      logger.debug("searchResults: Matched %d/%d transactions searching for '%s'", 
                    transactions.length, length, searchValue)
      return transactions
    }
  )

  public static rankedSearchOptions = atom<RankedSearchOptions<TransactionItem>>(
    (get) => {
      const { CONTAINS, WORD_STARTS_WITH } = Rankings
      const { model } = get(ModelAtoms.modelAtom)

      return {
        props: [
          { prop:ItemProps.name, threshold: WORD_STARTS_WITH },
          { prop:ItemProps.description, threshold: CONTAINS },
          { prop:(item:TransactionItem) => model.getItemName(item.categoryKey), threshold: WORD_STARTS_WITH },
          { prop:(item:TransactionItem) => model.getItemName(item.accountKey), threshold: WORD_STARTS_WITH },
          { prop:(item:TransactionItem) => model.getItemName(item.typeKey), threshold: WORD_STARTS_WITH },
          { prop:(item:TransactionItem) => model.getItemName(item.ruleKey), threshold: WORD_STARTS_WITH },
        ]
      }
    }
  )
}
