import { Area, Line } from "recharts"
import { Logger } from "../../../core/utils/Logger"
import { CartesianChart } from "../CartesianChart"
import { useChartProfile } from "../useChartProfile"
import { useGradient, useTrendColor } from "../useGradient"
import { useAssetEarningsData } from "./useAssetEarningsData"

const logger = new Logger("charts.AssetEarningsAreaChart")

export function AssetEarningsAreaChart(props:any) {
  const { title, height, units, syncId, noHeader, noLegend, onClickXAxis } = props
  const { colors, size, animationDuration } = useChartProfile()
  const aspect = props.aspect || size.aspect21x9

  const { data, currentEarnings } = useAssetEarningsData()
  const balanceColor = (currentEarnings >= 0) ? colors.green : colors.red
  const contribColor = useTrendColor(data, "Contribs")

  const { linearGradient, gradientUrl } = useGradient(data, "Contribs", balanceColor)

  logger.debug("Rendering: title=%s, %d data points", title, data.length)

  return (
    <div id="AssetEarningsAreaChart" className="chart">
      { !noHeader &&
        <h3>Earnings and Contributions</h3>
      }
      <CartesianChart title={title} data={data} 
                      aspect={aspect} 
                      height={height} 
                      units={units || "1M"} 
                      syncId={syncId} 
                      onClickXAxis={onClickXAxis}
                      noLegend={noLegend}>
        <defs>{ linearGradient }</defs>
        <Area type="monotone" dataKey="Balance"  stroke={balanceColor} strokeWidth={2} fill={gradientUrl} animationDuration={animationDuration}/>
        <Line type="monotone" dataKey="Contribs" stroke={contribColor} strokeWidth={2} strokeDasharray="3 3" dot={false} animationDuration={animationDuration} />
      </CartesianChart>
    </div>
  )
}
