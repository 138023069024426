import { useAtomValue } from "jotai"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { ModelKeys } from "../../core/model/ModelKeys"
import { Item } from '../../core/types/Item'
import { formatDate } from '../../core/utils/DateFormat'
import { Logger } from "../../core/utils/Logger"
import { NumberFormats, formatCurrency } from '../../core/utils/Numbers'
import { clzName } from "../../core/utils/Utils"
import { SearchAtoms } from "../../hooks/atoms/SearchAtoms"
import { useModel } from '../../hooks/context/ModelContext'
import { Card, CardHeader, CardSection } from "../controls/Card"
import { setDialogItem } from "../controls/dialog/ItemDialog"
import { useCardLink } from "../views/ViewLinks"
import { SearchBox } from "./SearchBox"

const logger = new Logger("search.SearchCard")

export function SearchCard(props:{ rootKey:string }) {
  const { model } = useModel()
  const searchResults = useAtomValue(SearchAtoms.searchResults)

  const rootItem = model.getItem(props.rootKey)

  logger.debug("Rendering: rootItem='%s', %d searchResults", model.getItemName(rootItem), searchResults.length)

  return (
    <Card id="SearchCard">
      <CardHeader onClick={(e) => e.stopPropagation()}>
        <SearchBox setfocus={true} />
        <div className="hits">{`${searchResults.length} hits`}</div>
      </CardHeader>
      <CardSection>
        <TreeView />
      </CardSection>
    </Card>
  )
}

function TreeView(props:any) {
  const { model } = useModel()
  const searchResultsMap = useAtomValue(SearchAtoms.searchResultsMap)

  logger.debug("Treeview: Rendering %d search results", searchResultsMap.size)

  return (
    <table className="table">
      <tbody>
      { model.childrenSorted(ModelKeys.root).map(item =>
        <TreeItem item={item} level={0} key={item.key} />
      )}
      </tbody>
    </table>
  )
}

function TreeItem({ item, level } : {item:Item, level:number}) {
  const { model } = useModel()
  const [ expanded, setExpanded ] = useState(level < 2)
  const cardLink = useCardLink(item)
  const navigate = useNavigate()
  const searchResultsMap = useAtomValue(SearchAtoms.searchResultsMap)

  const iconWidth = 1.6
  const styleIndent = level > 0 ? { paddingLeft: iconWidth*level + "rem" } : undefined

  const itemName = model.getItemName(item)                                   
  const itemIcon = model.getItemIcon(item.key)
  const searchHit = searchResultsMap.get(item.key)

  const hasDate = ('startDate' in item)
  const hasValue = ('value' in item)

  // logger.debug("TreeItem: Rendering item='%s', level=%d", item.name, level)

  return (
    <>
      <tr className="link" 
          title={model.getItemDescription(item.key)}
          onClick={(e) => onClickTreeItem(e, item)}>

        <td className="name" style={styleIndent}>
          <button onClick={onExpand}>
            <i className={itemIcon} />
          </button>
          {itemName}
        </td>
        <td>
          { searchHit && hasDate &&
            formatDate((item as any).startDate, "d/MM")
          }
        </td>
        <td className={clzName("value", {"negative": (item as any).value < 0})}>
          { searchHit && hasValue &&
            formatCurrency((item as any).value, NumberFormats.currency2)
          }
        </td>
      </tr>
      { expanded &&
        <>
          { getChildren(item.key).map(child =>
            <TreeItem item={child} level={level+1} key={child.key} />
          )}
        </>
      }
    </>
  )

  function onExpand(e:any) {
    e.stopPropagation()
    setExpanded(!expanded)
  }

  function onClickTreeItem(e:any, item:Item) {
    e.stopPropagation()
    if (searchHit) {
      setDialogItem(item, true)
    } else {
      navigate(cardLink)
    }
  }

  function getChildren(key:string) {
    return model.childrenSorted(key).filter(item => searchResultsMap.has(item.key))
  }
}
