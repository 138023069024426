import { atom, useAtom } from "jotai"
import { setAtomValue } from "../../core/atom/AtomUtils"

interface FilterBarContext {
  stackName?: string
  itemKey?: string
  visible: boolean
}

const filterBarAtom = atom<FilterBarContext>({ visible:false })

export function resetFilterBarAtom() {
  setAtomValue(filterBarAtom, { visible:false })
}

export function useFilterBar(stackName?:string, itemKey?:string) {
  const [ filterBarContext, setFilterBarContext ] = useAtom(filterBarAtom)

  const active = filterBarContext.visible &&
                (filterBarContext.stackName === stackName) &&
                (filterBarContext.itemKey === itemKey)

  stackName = stackName ?? filterBarContext.stackName
  itemKey = itemKey ?? filterBarContext.itemKey
  const visible = filterBarContext.visible
              
  return { stackName, itemKey, active, visible, setVisible }

  function setVisible(visible:boolean) {
    setFilterBarContext({ visible, stackName, itemKey })
  }
}
