import { Dimension } from "../../../core/filter/Filter"
import { Model } from "../../../core/model/Model"
import { ModelKeys } from "../../../core/model/ModelKeys"
import { useFilterContext } from "../../../hooks/context/FilterContext"
import { usePivotContent } from '../../../hooks/pivot/usePivotContent'
import { Menu } from "../../controls/menu/Menu"
import { ButtonBalance, ButtonCumTotal, ButtonPivotLayout } from "../cashflow/CashflowPivotTableMenu"

export function DimensionMenu({ segmented }: { segmented?: boolean }) {
  const { filter, model } = useFilterContext()

  const dimensions: Dimension[] = ["Account", "Type", "Category"]

  if (segmented) return (
    <>
      <menu className="segment">
        { dimensions.map(dimension => 
          <ButtonDimension dimension={dimension} key={dimension} />
        )}
        <ButtonRaw />
      </menu>
    </>
)

  return (
    <Menu buttonImage={getDimensionIcon(filter.dimension, model)} label={filter.dimension}>
      { dimensions.map(dimension => 
        <ButtonDimension dimension={dimension} key={dimension} />
      )}
      <ButtonRaw />
      <div className="divider"/>
      <ButtonBalance />
      <ButtonCumTotal />
      <div className="divider" />
      <ButtonPivotLayout />
    </Menu>
  )
}

function ButtonDimension({ dimension }:{ dimension: Dimension }) {
  const { filterStack, filter, model } = useFilterContext()
  const { isBalanceContent, setPivotContent } = usePivotContent()

  const className = (dimension === filter.dimension && !filter.raw ? "active" : "")
  const iconClass = getDimensionIcon(dimension, model)

  return (
    <button className={className} title={"Group by " + dimension} onClick={onClickDimension}>
      <i className={iconClass} />
      <label>{dimension}</label>
    </button>
  )

  function onClickDimension() {
    filterStack.push({ dimension, raw: false })

    if (dimension !== "Account" && isBalanceContent) {
      setPivotContent(undefined)
    }
  }
}

export function ButtonRaw() {
  const { filterStack, filter } = useFilterContext()

  const className = (filter.raw ? "active" : "")
  return (
    <button className={className} onClick={onClick} title="Transactions">
      <i className="fal fa-money-check-dollar" />
      <label>Transactions</label>
    </button>
  )

  function onClick() {
    filterStack.push({ raw: true })
  }
}

function getDimensionIcon(dimension: Dimension, model:Model) {
  switch (dimension) {
    case "AccountType": return model.getItemIcon(ModelKeys.category.finance)
    case "Account":     return model.getItemIcon(ModelKeys.account.root)
    case "Asset":       return model.getItemIcon(ModelKeys.asset.root)
    case "Type":        return model.getItemIcon(ModelKeys.transaction.root)
    case "Category":    return model.getItemIcon(ModelKeys.category.root)
  }
}
