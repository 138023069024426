import { Bar, Cell, ComposedChart, LabelList, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { PivotRow } from '../../../core/builder/PivotTable'
import { Item } from '../../../core/types/Item'
import { formatDate } from '../../../core/utils/DateFormat'
import { Logger } from '../../../core/utils/Logger'
import { Format, formatAssetValue, formatCurrency } from '../../../core/utils/Numbers'
import { useFilterContext } from "../../../hooks/context/FilterContext"
import { usePivotTables } from '../../../hooks/pivot/usePivotTables'
import { ColorPalette, useChartProfile } from '../useChartProfile'
import { useGradientRedGreen } from '../useGradient'

const logger = new Logger("charts.CompareWaterfallChart")

export function CompareWaterfallChart(props:{height?:number|string}) {
  const { pivotTable, dateFormat } = usePivotTables()

  const { actualRow, budgetRow } = getBudgetActualRows(pivotTable.scale.end || Date.now())

  logger.debug("Comparing: %s vs %s", Logger.Date(budgetRow?.date), Logger.Date(actualRow?.date))

  if (!actualRow || !budgetRow) {
    return <></>
  }

  return (
    <div id="CompareWaterfallChart" className="chart">
      <h3>
        { formatDate(budgetRow?.date || 0, dateFormat) } vs { formatDate(actualRow?.date || 0, dateFormat) }
      </h3>
      <CompareRowsWaterfallChart {...props} budgetRow={budgetRow} actualRow={actualRow} />
    </div>
  )

  function getBudgetActualRows(date:number) {
    let actualRow, budgetRow

    for (const row of pivotTable.rows) {
      if (!row.openingRow) {
        if (row.startDate <= date) {
          budgetRow = actualRow
          actualRow = row
        }
      }
    }
  
    return { actualRow, budgetRow }
  }
}

interface ChartDataPoint {
  key: string | number
  name: string 
  space: number
  debit: number
  credit: number
  value: number
  total: number
  color: string
  column?: Item
}

export function CompareRowsWaterfallChart(props:{ 
  actualRow: PivotRow
  budgetRow: PivotRow
  height?: number|string
  noYAxis?: boolean
  noTooltip?: boolean }) 
{
  const { name, pivotTable } = usePivotTables()
  const { filterStack } = useFilterContext()
  const { linearGradient, gradientRedUrl, gradientGreenUrl } = useGradientRedGreen()

  const { actualRow, budgetRow, noYAxis, noTooltip, height } = props
  let { colors, margin, size, ydomain, animationDuration } = useChartProfile()
  margin = {...margin, bottom:25 }

  const columns = pivotTable.columns.sort((c1,c2) => sortColumn(c1,c2))
  
  const chartData = getChartData()

  logger.setContext(name).debug("Comparing: %s vs %s, %d data points", 
        Logger.Date(budgetRow.date), Logger.Date(actualRow.date), chartData.length)
                  
  return (
    <ResponsiveContainer height={height || "100%"} width="100%">
      <ComposedChart data={chartData} margin={margin}
                     barCategoryGap={size.barCategoryGap} barGap={size.barGap} maxBarSize={size.maxBarSize}>
        <XAxis dataKey="name" textAnchor={"end"} angle={-35} interval={0} onClick={onClickXAxis} cursor="pointer" />
        { (!noYAxis) &&
          <YAxis width={40} domain={ydomain} tickFormatter={formatAssetValue} />
        }
        <ReferenceLine y={0} strokeDasharray="3 3" />
        <Bar dataKey="space" stackId="1" fill="transparent" animationDuration={animationDuration} radius={size.radiusBar} />
        <Bar dataKey="debit" stackId="1" animationDuration={animationDuration} radius={size.radiusBar}>
          { chartData.map((dp) => (
            <Cell fill={gradientGreenUrl} key={dp.key} cursor="pointer" onClick={() => onClickBar(dp.column)} />
          ))}
          <LabelList dataKey="value" position="top" formatter={formatDebit} />
        </Bar>
        <Bar dataKey="credit" stackId="1" animationDuration={animationDuration} radius={size.radiusBar}>
          { chartData.map((dp) => (
            <Cell fill={gradientRedUrl} key={dp.key} cursor="pointer" onClick={() => onClickBar(dp.column)} />
          ))}
          <LabelList dataKey="value" position="bottom" formatter={formatCredit} />
        </Bar>
        <Line dataKey="total" type="step" dot={false}
              stroke={colors.green}
              strokeDasharray="2 2"
              strokeWidth={1}
              animationDuration={animationDuration} />
        { (!noTooltip) &&
          <Tooltip formatter={(value:any) => formatAssetValue(value)} 
                   position={{y: 0}} />
        }
        <defs>{ linearGradient }</defs>
      </ComposedChart>
    </ResponsiveContainer>
  )

  function onClickBar(columnItem:Item|undefined) {
    filterStack.push({ columnItem })
  }

  function onClickXAxis(e:any) {
    const index = e.index
    if (index >= 0 && index < chartData.length) {
      const columnItem = chartData[e.index].column
      filterStack.push({ columnItem })
    }
  }

  function sortColumn(c1:Item, c2:Item) {
    const v1 = actualRow.cell(c1.key).total - budgetRow.cell(c1.key).total
    const v2 = actualRow.cell(c2.key).total - budgetRow.cell(c2.key).total

    return Math.abs(v2) - Math.abs(v1)
  }

  function getChartData() {
    const chartData:ChartDataPoint[] = []
    
    // Color indexes for bars
    const colorPalette = new ColorPalette()

    // Add budget
    let total = budgetRow.total
    chartData.push({ 
      key: budgetRow.key,
      name: "Budget", 
      space: 0,
      debit:  (total > 0 ? total : 0),
      credit: (total < 0 ? total : 0),
      value: total,
      total: total,
      color: colorPalette.get(total),
    })

    // Add budget variance
    let other=0
    for (const column of columns) {
      const value = actualRow.cell(column.key).total - budgetRow.cell(column.key).total
      if (Math.abs(value) > 250) {
        total += value
        chartData.push({ 
          key: column.key,
          name: column.name, 
          space: (value > 0 ? total - value : total),
          debit: (value > 0 ? value : 0),
          credit: (value < 0 ? -value : 0),
          value: value,
          total: total,
          color: colorPalette.get(value),
          column: column,
        })
      } else {
        other += value
      }
    }
    if (other !== 0) {
      chartData.push({ 
        key: "Other",
        name: "Other", 
        space: (other > 0 ? total - other : total),
        debit: (other > 0 ? other : 0),
        credit: (other < 0 ? -other : 0),
        value: other,
        total: total,
        color: colorPalette.get(other),
      })  
    }

    // Finally add actual
    const value = actualRow.total
    chartData.push({ 
      key: "Actual",
      name: "Actual", 
      space: 0,
      debit:  (value > 0 ? value : 0),
      credit: (value < 0 ? value : 0),
      value: value,
      total: value,
      color: colorPalette.get(value)
    })

    return chartData
  }
}

export function formatDebit(value:number) {
  return value > 0 ? formatLabelValue(value) : ""
}

export function formatCredit(value:number) {
  return value < 0 ? formatLabelValue(value) : ""
}

function formatLabelValue(amount:number | undefined) {
  const value = Math.abs(amount || 0)
  const mantissa = (value >= 1000000 ? 1 : 0)
  const format:Format = { output:"number", average:true, mantissa:mantissa, optionalMantissa:true }

  return formatCurrency(amount, format)
}
