import { ModelCodes } from "../../core/model/ModelCodes"
import { RouteItem } from "../controls/RouteUtils"
import { BankStmtView } from "../views/BankStmtView"
import { AccountWizard } from "./account/AccountWizard"
import { BankStmtFileWizard } from "./bankstmt/BankStmtFileWizard"
import { CreateFileWizard } from "./files/CreateFileWizard"
import { PortfolioWizard } from "./portfolio/PortfolioWizard"
import { PropertyWizard } from "./property/PropertyWizard"
import { WizardStartMenu } from "./WizardStartMenu"

/**
 * Define /wizard sub-routes
 */
export const wizardRoutes:RouteItem[] = [
  { path: "*", 
    name: "Wizard Start Menu",
    description: "Select one of the following tasks and our wizard will guide you through the process",
    Component: WizardStartMenu
  },
  { path: "account",
    name: "Create an Account",
    icon: "fal fa-credit-card",
    description: "Create a new bank account, credit card, term deposit, managed fund or stock account",
    Component: AccountWizard,
  },
  { path: "portfolio", 
    name: "Create a Portfolio",
    icon: "fal fa-briefcase",
    description: "A Portfolio is used to group multiple accounts of similar types together for reporting and analysis",
    Component: PortfolioWizard,
  },
  { path: "property", 
    name: "Create a Property",
    icon: "fal fa-home",
    description: "Create a new Property asset, and a mortgage if required",
    Component: PropertyWizard,
  },
  { path: "files", 
    name: "Create a File",
    icon: "fal fa-files",
    description: "Create a new File for a new model",
    Component: CreateFileWizard,
  },
  { path: "bank/file", 
    name: "Load Bank Statement Files",       
    icon: "fal fa-file-invoice-dollar",
    description: "Load bank statement transactions from an OFX or CSV file that you have downloaded from your bank or investment manager website",
    Component: BankStmtFileWizard 
  },
  { path: "../bank/" + ModelCodes.bank.rules, 
    name: "Manage Bank Statement Rules",
    icon: "fal fa-wand-sparkles",
    description: "Manage the set of rules used to auto-categorise transactions loaded from bank statements",
    Component: BankStmtView
  },
]

export function getWizardRoute(pathname:string) {
  const route = wizardRoutes.find(route => (`/wizard/${route.path}` === pathname))
  return route
}