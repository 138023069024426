import { ModelKeys } from "../../../core/model/ModelKeys"
import { updateItems } from "../../../core/service/ModelAction"
import { BankStmtFileItemProps } from "../../../core/types/BankStmtFileItem"
import { Item, ItemProps } from "../../../core/types/Item"
import { TransactionItemProps } from "../../../core/types/TransactionItem"
import { TypeItemProps } from '../../../core/types/TypeItem'
import { formatDate } from '../../../core/utils/DateFormat'
import { Logger } from "../../../core/utils/Logger"
import { useModel } from '../../../hooks/context/ModelContext'
import { Editable } from '../../controls/editor/Editable'
import { ItemPickerTree } from '../../controls/select/ItemPickerTree'
import { ItemLink } from "../../views/ViewLinks"

const logger = new Logger("component.ItemDetailsEditor")

export function ItemDetailsEditor({item}:{item:Item}) {
  const { model } = useModel()

  logger.debug("Rendering: key=%s", item?.key, item)

  return (
    <table className="table form">
      <tbody>
        <tr>
          <th>Key</th>
          <td><Editable item={item} property={ItemProps.key} /></td>
        </tr>
        <tr>
          <th>Code</th>
          <td><Editable item={item} property={ItemProps.code} /></td>
        </tr>
        <tr>
          <th>Name</th>
          <td><Editable item={item} property={ItemProps.name} /></td>
        </tr>
        <tr>
          <th>Description</th>
          <td><Editable item={item} property={ItemProps.description} /></td>
        </tr>
        <tr>
          <th>Parent Key</th>
          <td>
            <ItemPickerTree
              rootKey={ModelKeys.root}
              searchItems={model.items()}
              selected={model.getItem(item.parentKey)}
              onChange={(selectedItem: Item) => onChangeItem(item, ItemProps.parentKey, selectedItem.key)} />
          </td>
        </tr>
        <tr>
          <th>Type</th>
          <td>
            { model.isType(item)
              ? <>{ model.getItemName(item.typeKey) }</>
              : <ItemPickerTree
                rootKey={ModelKeys.type.root}
                selected={model.getItem(item.typeKey)}
                onChange={(selectedItem: Item) => onChangeItem(item, ItemProps.typeKey, selectedItem.key)} />
            }
          </td>
        </tr>

        {(item as any).categoryKey &&
          <tr>
            <th>Category</th>
            <td>
              <ItemPickerTree
                rootKey={ModelKeys.category.root}
                selected={model.getItem((item as any).categoryKey)}
                onChange={(selectedItem: Item) => onChangeCategory(item, selectedItem.key)} />
            </td>
          </tr>
        }

        {(item as any).relatedTransactionKey &&
          <tr>
            <th>Related Transaction</th>
            <td><ItemLink item={model.getItem((item as any).relatedTransactionKey)} /></td>
          </tr>
        }

        {(item as any).accountNumber &&
          <tr>
            <th>Account Number</th>
            <td><Editable item={item} property={BankStmtFileItemProps.accountNumber} /></td>
          </tr>
        }
        <tr>
          <th>Icon</th>
          <td><Editable item={item} property={TypeItemProps.typeIcon} /></td>
        </tr>
        <tr>
          <th>Sort Order</th>
          <td>{item.sortOrder}</td>
        </tr>
        <tr>
          <th>Status</th>
          <td>{item.status}</td>
        </tr>
        <tr>
          <th>Modified Date</th>
          <td>{item.modifiedDate ? formatDate(item.modifiedDate, "yyyy-MM-dd hh:mm:ss.SSS") : ""}</td>
        </tr>
      </tbody>
    </table>
  )

  function onChangeItem(item:Item, property:keyof Item, value:any) {
    logger.debug("onChangeItem: item.key=%s, property=%s, value=%s", item.key, property, value)

    const event = updateItems(model, [item.key], property, value)
    logger.debug("onChangeItem finished: event=%o", event)
  }

  function onChangeCategory(item:Item, value:any) {
    const property = TransactionItemProps.categoryKey
    logger.debug("onChangeCategory: item.key=%s, property=%s, value=%s", item.key, property, value)

    const event = updateItems(model, [item.key], property, value)
    logger.debug("onChangeCategory finished: event=%o", event)
  }
}
