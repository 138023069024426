import { Type } from "../../../core/model/ModelKeys"
import { Logger } from '../../../core/utils/Logger'
import { TimeScale } from '../../../core/utils/TimeScale'
import { FilterProvider } from '../../../hooks/context/FilterContext'
import { InvestmentModelProvider, useInvestmentModel } from '../../../hooks/context/InvestmentModelContext'
import { CardSelector } from "../CardSelector"
import { AssetChartCard } from '../asset/AssetChartCard'
import { AssetPerformanceCard, getAssetFilter } from '../asset/AssetPerformanceCard'
import { ValuationEditorCard } from '../asset/ValuationEditorCard'
import { CashflowCard } from '../cashflow/CashflowCard'

const logger = new Logger("PropertySection")

export function PropertySection(props: any) {
  const { model, asset, name } = useInvestmentModel()
  const scale:TimeScale = { start:asset.purchaseDate ?? 0, end:0, units:"1M" }

  logger.setContext(name).debug("Rendering: key=%s, asset=%o", asset.key, asset)

  return (
    <FilterProvider name={logger.name} initFilter={getAssetFilter} >
      <AssetChartCard open />
      <CashflowCard />

      <FilterProvider name={logger.name + ".AssetPerformance"} initFilter={{scale}}>
        <AssetPerformanceCard open />
      </FilterProvider>

      <FilterProvider name={logger.name + ".ValuationEditor"} initFilter={getAssetFilter}>
        <ValuationEditorCard title="Valuations" />
      </FilterProvider>
      
      { model.childrenSorted(asset.key)
             .filter(child => !Type.isValuation(child)).map(child =>
        <InvestmentModelProvider item={child} key={child.key}>
          <FilterProvider name={logger.name}>
            <CardSelector />
          </FilterProvider>
        </InvestmentModelProvider>
      )}
    </FilterProvider>
  )
}
