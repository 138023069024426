import { Format, NumberFormats, formatNumber, isNegative } from "../../core/utils/Numbers"
import { clzName } from "../../core/utils/Utils"

export interface NumericCellProps {
  value? :number, 
  format?: Format, 
  className?: string, 
  colSpan?: number
  title?: string
}

export function NumericCell({ value, format, className, colSpan, title } : NumericCellProps) {
  const clz = clzName(className, { negative: isNegative(value) })
  
  return (
    <td className={clz} colSpan={colSpan} title={title}>
      { formatNumber(value, format ?? NumberFormats.number2) }
    </td>
  )
}

export function CurrencyCell(props : NumericCellProps) {
  return <NumericCell {...props} format={NumberFormats.currency0}/>
}

export function UnitsCell(props : NumericCellProps) {
  return <NumericCell {...props} format={NumberFormats.units} />
}

export function PercentCell(props : NumericCellProps) {
  return <NumericCell {...props} format={props.format ?? NumberFormats.percent1}/>
}
