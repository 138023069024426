import { isSameDay } from "date-fns"
import { millisecondsInDay } from "date-fns/constants"
import { Model } from "../../../../core/model/Model"
import { RankedSearchOptions, Rankings, searchAndRankItems } from "../../../../core/model/RankedSearch"
import { Item, ItemProps } from "../../../../core/types/Item"
import { Logger } from "../../../../core/utils/Logger"

const logger = new Logger("diagnostics.searchSpeedTest")

export function searchSpeedTest(model:Model) {
  
  const searchValues = ["Countdown", "New World", "Transfer", "Medium USD", "BNZ Payment Living Costs", "Rent", "YouMoney XFR"]

  speedTestRankedSearch(model, searchValues)
}

function speedTestRankedSearch(model:Model, searchValues:string[]) {
  const searchItems = model.items()
  const searchOptions = getSearchOptions()

  logger.start("RankedSearch", "Starting RankedSearch test, searching %d items", searchItems.length)

  for (const value of searchValues) {
    const start = Date.now()
    const results = searchAndRankItems(searchItems, value, searchOptions)
    // const results = sortRankedItems(rankedItems).map(ri => ri.item)

    logger.debug("Matched %d hits in %dms searching for '%s'", results.size, Date.now()-start, value)
  }

  logger.finish("RankedSearch")

  function getSearchOptions() : RankedSearchOptions<Item> {
    const { CONTAINS, WORD_STARTS_WITH, STARTS_WITH } = Rankings

    return {
      props: [
        { prop: ItemProps.name, threshold: WORD_STARTS_WITH },
        { prop: ItemProps.description, threshold: CONTAINS },
        { prop: (item:any) => model.getItemName(item.categoryKey), threshold: STARTS_WITH },
        { prop: (item:any) => model.getItemName(item.accountKey), threshold: WORD_STARTS_WITH },
        { prop: (item:any) => model.getItemName(item.typeKey), threshold: STARTS_WITH },
        // { prop: (item:any) => model.getItemName(item.ruleKey), threshold: STARTS_WITH },
      ]
    }
  }
}

export function sameDayTests(iterations = 100000) {
  logger.start("sameDayTests.isSameDay")

  let date1 = Date.now()
  let date2 = date1 + millisecondsInDay
  let sameCount1 = 0

  for (let i=0;  i < iterations;  i++) {
    if (isSameDay(date1, date2)) {
      sameCount1++
    }
    date1 += millisecondsInDay
    date2 += millisecondsInDay * i
  }

  logger.finish("sameDayTests.isSameDay", "%d iterations, same=%d", iterations, sameCount1)

  logger.start("sameDayTests.floor")
  let date3 = Date.now()
  let date4 = date1 + millisecondsInDay
  let sameCount2 = 0

  for (let i=0;  i < iterations;  i++) {
    if (isSameDay(date3, date4)) {
      if (Math.floor(date3/millisecondsInDay) === Math.floor(date4/millisecondsInDay))
      sameCount2++
    }
    date3 += millisecondsInDay
    date4 += millisecondsInDay * i
  }
  logger.finish("sameDayTests.floor", "%d iterations, same=%d", iterations, sameCount2)
}
