import { useErrorBoundary } from "react-error-boundary"
import { reloadModel } from "../../core/service/ModelService"
import { Logger } from "../../core/utils/Logger"
import { AppInfo } from "../../VersionInfo"
import { DeviceInfoTable } from "../cards/settings/diagnostics/DeviceInfoCard"
import { JemstoneHeader } from "../layouts/JemstoneHeader"
import { signOut } from "../layouts/JemstoneLogin"

const logger = new Logger("views.ErrorFallbackView")

export function ErrorFallbackView({ error } : { error:any }) {
  const { resetBoundary } = useErrorBoundary()

  logger.debug("Rendering: error=%o", error)

  return (
    <>
      <JemstoneHeader />

      <main className="error-boundary">
        <article>
          <header>
            <h1>
              <i className="far fa-bug"/>
              Unhandled Error
            </h1>
            <menu>
              <button onClick={onLogout}>Logout</button>
              <button onClick={onReload}>Reload</button>
              <button onClick={onRetry}>Try again</button>
            </menu>
          </header>
          <section>
            <p>
              An error has been caught that cannopt be automatically handled. Please retry the operation again by:
            </p>
            <ol>
              <li>
                Click <button onClick={onRetry}>Try again</button>
              </li>
              <li>
                Click <button onClick={onReload}>Reload</button> to fully reload the model and rebuild all cashflows
              </li>
              <li>
                Click <button onClick={onLogout}>Logout</button> to logout of {AppInfo.name} and then login again
              </li>
              <li>
                If not successful please contact me on <a href="mailto:peter.chandler.nz@gmail.com">peter.chandler.nz@gmail.com</a>
              </li>
            </ol>

            <h2>Error</h2>
            <pre>{error.message}</pre>
            <pre>{error.cause?.message}</pre>

            <h2>Stack Trace</h2>
            <pre>{error.stack ?? error.message}</pre>
            <pre>{error.cause?.stack ?? error.cause?.message}</pre>

            <h2>Device & Browser Information</h2>
            <DeviceInfoTable/>
          </section>
        </article>
      </main>
    </>
  )

  function onReload() {
    logger.debug("onReload: Reloading model then calling resetBoundary")

    try {
      reloadModel()
      resetBoundary()
    } catch(reason:any) {
      logger.error("onReload: Exception detected, reason=%o", reason)
    }
  }

  function onRetry() {
    logger.debug("onRetry: Calling resetBoundary")
    resetBoundary()
  }

  function onLogout() {
    logger.debug("onLogout: Calling signOut")
    signOut()
  }
}
