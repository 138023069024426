import { setProperty } from "../../core/model/Model"
import { ModelKeys } from "../../core/model/ModelKeys"
import { AssetItemProps } from "../../core/types/AssetItem"
import { ItemProps } from "../../core/types/Item"
import { PropertyItem, PropertyItemProps } from "../../core/types/PropertyItem"
import { ValuationItem } from "../../core/types/ValuationItem"
import { Logger } from "../../core/utils/Logger"
import { NumberFormats } from "../../core/utils/Numbers"
import { ifBlank } from "../../core/utils/Utils"
import { useFormItem } from "../../hooks/context/FormContext"
import { useModel } from '../../hooks/context/ModelContext'
import { useAtomRef } from "../../hooks/useAtomRef"
import { DateEditor } from "../controls/editor/DateEditor"
import { Editable } from "../controls/editor/Editable"
import { Field, FieldSet, Form, FormBody } from "../controls/form/Form"
import { FormFooter } from "../controls/form/FormFooter"
import { FormHeader } from "../controls/form/FormHeader"
import { ItemFormSharedFields } from "./ItemFormSharedFields"

const logger = new Logger("forms.PropertyItemForm")

export function PropertyItemForm(props : {
  item: PropertyItem
  formItemKey?: string
  open?: boolean
  noHeader?: boolean
  onClose: () => void
}) {
  const { model } = useModel()
  const { formItemKey, open, noHeader, onClose } = props
  
  const [ propertyItem, setPropertyItem ] = useFormItem(formItemKey ?? "propertyItem", props.item)
  const [ valuationItem, setValuationItem ] = useFormItem("valuationItem", getValuationItem)
  const openAtom = useAtomRef(open === true)

  const icon = model.getItemIcon(propertyItem) || "fal fa-line-columns"
  const currencyFormat = NumberFormats.currency0

  logger.debug("Rendering: key=%s, open=%s, item=%o, props.item=%o", propertyItem.key, open, propertyItem, props.item)

  return (
    <Form id="PropertyItemForm">
      { !noHeader &&
        <FormHeader title="Property Editor" icon={icon} onClose={onClose} openAtom={openAtom} />
      }
      <FormBody>
        <FieldSet>
          <Field className="name">
            <label>Name</label>
            <Editable item={propertyItem} property={ItemProps.name} onChange={onChange} readonly={false} />
          </Field>
          <Field className="description">
            <label>Description</label>
            <Editable item={propertyItem} property={ItemProps.description} onChange={onChange} readonly={false} />
          </Field>
          <Field className="description">
            <label>Address</label>
            <Editable item={propertyItem} property={PropertyItemProps.address} onChange={onChange} readonly={false} />
          </Field>
        </FieldSet>

        <FieldSet>
          <Field>
            <label>Purchase Price</label>
            <Editable item={propertyItem} property={AssetItemProps.purchasePrice} onChange={onChange} numberFormat={currencyFormat} />
          </Field>
          <Field>
            <label>Growth Rate (CAGR)</label>
            <Editable item={propertyItem} property={AssetItemProps.rate} onChange={onChange} numberFormat={NumberFormats.percent1} />
          </Field>
          <Field>
            <label>Purchase Date</label>
            <DateEditor item={propertyItem} property={AssetItemProps.purchaseDate} onChange={onChange} placeholder={Date.now()} />
          </Field>
          <Field>
            <label>Sell Date</label>
            <DateEditor item={propertyItem} property={AssetItemProps.sellDate} onChange={onChange} />
          </Field>
        </FieldSet>

        <FieldSet>
          <Field>
            <label>Legal Description</label>
            <Editable item={propertyItem} property={PropertyItemProps.legalDescription} onChange={onChange} />
          </Field>
          <Field>
            <label>Land Area</label>
            <Editable item={propertyItem} property={PropertyItemProps.landArea} onChange={onChange} />
          </Field>
        </FieldSet>
        
        <ItemFormSharedFields item={propertyItem} onChange={onChange} readonly={false} openAtom={openAtom} />
      </FormBody>
      
      { !noHeader &&
        <FormFooter showClose showDelete showSave onClose={onClose}/>
      }
    </Form>
  )

  function onChange(item:PropertyItem, property:any, value:any) {
    logger.debug("onChange: item.key=%s, property=%s, value=%s", item.key, property, value)

    const newItem = setProperty({...item}, property, value)
    setPropertyItem(newItem)

    if (property === ItemProps.name) {
      const newValuation = setProperty({...valuationItem}, property, ifBlank(value, "Property") + " Value")
      setValuationItem(newValuation)
    }
  }

  function getValuationItem() {
    let valuationItem = model.childrenOfType<ValuationItem>(propertyItem.key, ModelKeys.type.valuation).at(0)
    if (!valuationItem) {
      valuationItem = model.newItem<ValuationItem>(propertyItem.key, ModelKeys.type.valuation)
      valuationItem.name = "Property Valuation"
      valuationItem.date = propertyItem.purchaseDate
      valuationItem.value = propertyItem.purchasePrice
    }
    return valuationItem
  }
}
