import { useState } from "react"
import { Item } from '../../../core/types/Item'
import { Logger } from "../../../core/utils/Logger"
import { useModel } from '../../../hooks/context/ModelContext'
import { useViewContext, ViewContextProvider } from "../../../hooks/context/ViewContext"
import { ItemSearchBox } from '../../search/ItemSearchBox'
import { Card, CardHeader, CardSection } from "../Card"
import { MenuPlacement, MenuPopover } from '../menu/MenuPopover'

const logger = new Logger("control.ItemPickerTree")

export function ItemPickerTree(props: {
  rootKey:      string
  selected?:    Item
  searchItems?: Item[]
  placeholder?: string
  placement?:   MenuPlacement
  className?:   string
  buttonImage?: string
  readonly?:    boolean
  onChange:     (item:Item) => void
}) {
  const { model } = useModel()
  const { rootKey, selected, placeholder, placement, onChange, buttonImage, readonly } = props

  const searchItems = props.searchItems ?? model.childrenDeep(rootKey)

  logger.trace("Rendering: rootKey='%s', searchItems=%d", rootKey, searchItems.length)

  if (readonly) {
    return <div className="readonly">{selected?.name}</div>
  }

  return (
    <MenuPopover label={selected?.name} 
                  placeholder={placeholder}
                  buttonImage={buttonImage || "fas fa-caret-down"} 
                  placement={placement || "bottom"}>
      <ViewContextProvider>
        <ItemPickerResultsCard rootKey={rootKey} searchItems={searchItems} onChange={onChange} />
      </ViewContextProvider>
    </MenuPopover>
  )
}

export function ItemPickerResultsCard({ rootKey, searchItems, onChange }: {
  rootKey: string
  searchItems:Item[]
  onChange: (item:Item) => void
}) {
  const { model } = useModel()
  const { viewState } = useViewContext()
  const [,setSearchResults] = useState<any>()

  logger.debug("Rendering: %d searchItems", searchItems.length)

  return (
    <Card id="ItemPickerResultsCard">
      <CardHeader onClick={(e) => e.stopPropagation()}>
        <ItemSearchBox searchItems={searchItems} onSearchResults={onSearchResults} setfocus={true} />
      </CardHeader>
      <CardSection>
        <menu className="tree">
          { model.childrenSorted(rootKey).map(item =>
            <TreeItem item={item} onChange={onChange} key={item.key} />
          )}
        </menu>
      </CardSection>
    </Card>
  )

  function onSearchResults(results:Item[]) {
    viewState.setSearchHits(model, results)
    setSearchResults(results)
  }
}

function TreeItem({ item, onChange }: { 
  item:Item 
  onChange: (item:Item) => void
}) {
  const { model } = useModel()
  const { viewState } = useViewContext()
  const [ itemState, setItemState ] = useState(viewState.get(item.key))

  logger.trace("Rendering TreeItem itemKey=%s, itemState: searchHit=%s, visible=%s, openTree=%d", 
                item.key, itemState.searchHit, itemState.visible, itemState.openTree)

  if (item === undefined || !viewState.isVisible(item.key)) {
    return <></>
  }
                                  
  const hasChildren = model.hasChildren(item.key)
  const expanded = viewState.isOpenTree(item.key)

  return (
    <li onClick={(e) => onSelectItem(e, item)} title={model.getItemDescription(item.key)}>
      <summary className="link" >
        <button onClick={onClickIcon} disabled={!hasChildren}>
          <i className={model.getItemIcon(item.key)} />
        </button>
        { model.getItemName(item) }
      </summary>
      { expanded && hasChildren &&
        <menu className="tree">
          { model.childrenSorted(item.key).map(child =>
            <TreeItem item={child} onChange={onChange} key={child.key} />
          )}
        </menu>
      }
    </li>
  )

  function onSelectItem(e:any, item:Item) {
    e.stopPropagation()
        
    logger.debug("onSelectItem: itemKey=%o", item)
    if (onChange !== undefined) {
      onChange(item)
    }
  }

  function onClickIcon(e:any) {
    e.stopPropagation()
    
    const itemState = viewState.toggleOpenTree(item.key)
    if (itemState.openTree) {
      viewState.openTo(model, item.key)
    }
    setItemState(itemState)
  }
}
