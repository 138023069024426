import { useSidebar } from "../../../hooks/useSidebar"

export function ButtonSidebar(props:any) {
  const { toggleSidebar } = useSidebar()

  return (
    <button onClick={toggleSidebar}>
      <i className="fal fa-bars"/>
    </button>
  )
}