import { Logger } from "../core/utils/Logger"
import { newAtomMap, useAtomMap } from "./useAtomMap"

const logger = new Logger("cards.useCardOpenAtom")

const cardOpenAtomMap = newAtomMap<boolean>()

export function useCardOpen(cardName:string, itemKey:string, initValue = false) {
  const [value, setValue, valueAtom] = useAtomMap(cardOpenAtomMap, () => initValue, cardName, itemKey)

  logger.trace("name=%s, itemKey=%s, initValue=%s, value=%s", cardName, itemKey, initValue, value)

  return {
    isOpen:   value,
    setOpen:  setValue,
    toggle:   () => setValue(!value),
    openAtom: valueAtom,
  }
}
