import { atom } from 'jotai'
import { ModelAtoms } from '../../core/atom/ModelAtoms'
import { RankedSearchOptions, Rankings } from '../../core/model/RankedSearch'
import { CashflowItem } from '../../core/types/CashflowItem'
import { Item, ItemProps } from '../../core/types/Item'
import { Logger } from "../../core/utils/Logger"
import { SearchAtoms } from '../atoms/SearchAtoms'

export const logger = new Logger("cashflow.hooks.CashflowSearchAtoms")

export class CashflowSearchAtoms {
  
  public static searchItems = atom<CashflowItem[]>([])

  public static searchValue = atom("")

  public static searchResults = atom<CashflowItem[]>(
    (get) => {
      let results = get(CashflowSearchAtoms.searchItems)
      if (!results) {
        return []
      }

      let length = results.length
    
      const searchValue = get(CashflowSearchAtoms.searchValue)
      const searchOptions = get(CashflowSearchAtoms.searchOptions)
      if (searchValue && searchValue.length >= 2) {
        results = SearchAtoms.matchItems(results, searchValue, searchOptions)
      }

      logger.debug("searchResults.get: %d/%d items, searchValue='%s'", 
                    results.length, length, searchValue)
      return results
    }
  )

  public static searchOptions = atom<RankedSearchOptions<Item>>(
    (get) => {
      const { CONTAINS, WORD_STARTS_WITH } = Rankings
      return { 
        props: [
          { prop: ItemProps.name,        threshold: WORD_STARTS_WITH },
          { prop: ItemProps.description, threshold: CONTAINS },
          { prop: ItemProps.code,        threshold: WORD_STARTS_WITH },
          { prop: (item:any) => get(ModelAtoms.modelAtom).model.getItemName(item.categoryKey), threshold: WORD_STARTS_WITH },
        ], 
      }
    }
  )
}
