import { useSetAtom } from "jotai"
import { useEffect } from "react"
import { Logger } from "../../../../core/utils/Logger"
import { CountersCard } from "./CountersCard"
import { CountersCardAtoms } from '../../../../hooks/atoms/CountersCardAtoms'
import { DeviceInfoCard } from "./DeviceInfoCard"
import { DiagnosticTestCard } from "./DiagnosticTestCard"
import { ModelAnalyzerCards } from "./ModelAnalyzerCard"
import { TimersCard } from "./TimersCard"
import { TimersCardAtoms } from '../../../../hooks/atoms/TimersCardAtoms'

const logger = new Logger("settings.DiagnosticSection")

export function DiagnosticSection() {
  const setTimerUpdatedTime = useSetAtom(TimersCardAtoms.updatedTime)
  const setCounterUpdatedTime = useSetAtom(CountersCardAtoms.updatedTime)
  
  // Set timer intervals to auto-refresh the timers
  useEffect(() => {
    const intervalId = setInterval(() => {
      const time = Date.now()
      setTimerUpdatedTime(time)
      setCounterUpdatedTime(time)
    }, 1000)

    return () => clearInterval(intervalId)

  // eslint-disable-next-line
  },[])

  logger.trace("Rendering:")

  return (
    <section id="DiagnosticSection">
      <DeviceInfoCard/>
      <ModelAnalyzerCards/>
      <TimersCard/>
      <CountersCard/>
      <DiagnosticTestCard/>
    </section>
  )
}
