import { useAtomValue } from "jotai"
import { ReactNode, createContext, useContext, useMemo } from "react"
import { InvestmentModelAtoms } from "../../core/atom/InvestmentModelAtoms"
import { InvestmentModel } from "../../core/invest/InvestmentModel"
import { Item } from "../../core/types/Item"
import { Logger } from "../../core/utils/Logger"

const logger = new Logger("context.InvestmentModelContext")
const timeFormat = "HH:mm:ss.SSS"

export interface InvestmentModelState {
  investmentModel: any,
  updated: number
}

const InvestmentModelContext = createContext<InvestmentModelState>({ 
  investmentModel: undefined, 
  updated: Date.now() 
})

export function useInvestmentModelBuilder() {
  return useAtomValue(InvestmentModelAtoms.builder)
}

export function useInvestmentModel<T extends InvestmentModel<Item>>() : T {
  return useContext(InvestmentModelContext).investmentModel
}

export function InvestmentModelProvider(props:{item:Item, children:ReactNode}) {
  const { children, item } = props
  const { builder, updated } = useInvestmentModelBuilder()
  // const state = useAtomValue(InvestmentModelAtoms.investmentModel(item?.key))
  
  const state = useMemo(() => {
    logger.debug("Provided model for item={name:%s, key:%s, updated:%s}", item?.name, item?.key, 
                  Logger.Date(updated, timeFormat))

    const investmentModel = builder?.getModel(item?.key)
    return { investmentModel, updated }
  }, [item, builder, updated])

  return (
    <InvestmentModelContext.Provider value={state}>
      { children }
    </InvestmentModelContext.Provider>
  )
}
