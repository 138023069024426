import { useAtomValue } from "jotai"
import { TransactionItem } from '../../../core/types/TransactionItem'
import { Card, CardHeader, CardSection } from "../../controls/Card"
import { ButtonBack } from '../../controls/buttons/ButtonBack'
import { ButtonClose } from "../../controls/buttons/ButtonClose"
import { ButtonShowJson, showJsonAtom } from '../../controls/buttons/ButtonShowJson'
import { ItemDetailsJson } from "../settings/ItemDetailsJson"
import { TransactionItemDetails } from './TransactionItemDetails'

export function TransactionItemDetailsCard(props:{
  transaction:TransactionItem
  id?: string
  noHeader?: boolean
  onBack?: (e:any, transaction:TransactionItem) => void
  onClose?: () => void
}) {
  const { transaction, id, noHeader, onBack, onClose } = props
  const showJson = useAtomValue(showJsonAtom)

  let title = "Transaction Details"
  if (transaction.key.startsWith("OFX-")) {
    title = "Bank Transaction Details"
  } else if (transaction.key.startsWith("CFI-")) {
    title = "Cashflow Transaction Details"
  }

  return (
    <Card id={id || "TransactionItemDetailsCard"}>
      { !noHeader &&
        <CardHeader>
          { onBack &&
            <ButtonBack item={transaction} onBack={onBack} />
          }
          <h2>{title}</h2>
          <menu>
            <ButtonShowJson/>
            { onClose &&
              <ButtonClose onClose={onClose} />
            }
          </menu>
        </CardHeader>
      }
      <CardSection>
        <TransactionItemDetails transaction={transaction} />
        { showJson &&
          <ItemDetailsJson item={transaction} />
        }
      </CardSection>
    </Card>
  )
}