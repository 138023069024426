import { Asset } from "../../../core/model/ModelKeys"
import { BankStmtFileItem } from "../../../core/types/BankStmtFileItem"
import { Item } from "../../../core/types/Item"
import { formatDate } from '../../../core/utils/DateFormat'
import { Logger } from "../../../core/utils/Logger"
import { NumberFormats, formatNumber } from '../../../core/utils/Numbers'
import { clzName } from "../../../core/utils/Utils"
import { useBankStmtModel } from '../../../hooks/bankstmt/useBankStmtModel'
import { useModel } from "../../../hooks/context/ModelContext"
import { useViewContext } from "../../../hooks/context/ViewContext"
import { ProgressBar } from "../../controls/ProgressBar"
import { ButtonDelete } from "../../controls/buttons/ButtonDelete"
import { setDialogItem } from "../../controls/dialog/ItemDialog"
import { DateRange } from "../../controls/editor/DateRange"
import { CardLink } from "../../views/ViewLinks"
import { ButtonSelectEnable } from "../table/ModelTable"
import { ApplyMenu } from "./BankStmtCard"

const logger = new Logger("table.BankStmtTable")

export function BankStmtTable(props:any) {
  const { accounts, statements } = useBankStmtModel()

  logger.debug("Rendering %d bank statements in %d accounts", statements.length, accounts.length)

  return (
    <table id="BankStmtTable" className="table cashflow-table sticky-headers">
      <thead>
        <tr>
          <th className="rowmenu">
            <ButtonSelectEnable/>
          </th>
          <th className="name">Statements</th>
          <th className="start">Start</th>
          <th className="end">End</th>
          <th className="balance">Opening</th>
          <th className="balance">Closing</th>
          <th className="categorised">Categorised</th>
          <th className="menu"></th>
        </tr>
      </thead>
      <tbody>
        { accounts.map(account =>
          <BankStmtAccount account={account} key={account?.key}/>
        )}
      </tbody>
    </table>
  )
}

function BankStmtAccount({account}:{account:Item}) {
  const { model } = useModel()
  const { viewState } = useViewContext()
  const { bankStmtModel } = useBankStmtModel()
  const { statements, transactions, 
          categorised, uncategorised } = bankStmtModel.getAccountTransactions(account)

  const { openTree } = viewState.get(account.key)

  const isUnitised = Asset.isUnitised(account)
  
  const first = statements.at(-1)
  const last = statements.at(0)

  if (!account) {
    return <></>
  }

  return (
    <>
      <tr>
        { viewState.showSelectCheckbox &&
          <td className="rowmenu">
            <ButtonSelectStmts item={account} statements={statements} />
          </td>
        }

        <td className="name" colSpan={viewState.showSelectCheckbox ? 1 : 2}>
          <button onClick={toggleOpenTree}>
            <i className={openTree ? "far fa-angle-down" : "far fa-angle-right"} />
          </button>
          <CardLink item={model.getItem(account.key)} hideIcon />
        </td>
        
        <td className="start">{formatDate(first?.startDate || 0)}</td>
        <td className="end">{formatDate(last?.endDate || 0)}</td>

        <BalanceCell balance={first?.openingBalance} unitBalance={first?.openingUnitBalance} isUnitised={isUnitised} />
        <BalanceCell balance={last?.closingBalance} unitBalance={last?.closingUnitBalance} isUnitised={isUnitised}/>

        <td className="categorised">
          <ProgressBar total={transactions.length} count={categorised}>
            {transactions.length}
          </ProgressBar>
        </td>
        <td className="menu">
          <ApplyMenu transactions={transactions} categorised={categorised} uncategorised={uncategorised}>
            <button className="hidden"><i className="fas fa-times"/></button>
          </ApplyMenu>
        </td>
      </tr>
      
      { openTree && statements.map((statement) => 
        <BankStmtRow statement={statement} isUnitised={isUnitised} key={statement.key} />
      )}
    </>
  )

  function toggleOpenTree() {
    const itemState = viewState.toggleOpenTree(account.key)
    viewState.onEvent({name:"toggleOpenTree", key:account.key, value:itemState.openTree})
  }
}

function BankStmtRow({statement, isUnitised}:{statement:BankStmtFileItem, isUnitised:boolean}) {
  const { viewState } = useViewContext()
  const { bankStmtModel } = useBankStmtModel()
  const { transactions, categorised, uncategorised } = bankStmtModel.getTransactions([statement])

  return (
    <tr className="link" onClick={() => setDialogItem(statement, true)}>
      { viewState.showSelectCheckbox &&
        <td className="rowmenu">
          <ButtonSelectStmts item={statement} />
        </td>
      }

      <td className="name" style={{paddingLeft: "1.6rem"}} colSpan={viewState.showSelectCheckbox ? 1 : 2}>
        <CardLink item={statement} hideIcon>
          <DateRange start={statement.startDate} end={statement.endDate} />
        </CardLink>
      </td>

      <td className="start">{formatDate(statement.startDate)}</td>
      <td className="end">{formatDate(statement.endDate)}</td>

      <BalanceCell balance={statement.openingBalance} unitBalance={statement.openingUnitBalance} isUnitised={isUnitised} />
      <BalanceCell balance={statement.closingBalance} unitBalance={statement.closingUnitBalance} isUnitised={isUnitised} />

      <td className="categorised">
        <ProgressBar total={transactions.length} count={categorised}>
          {transactions.length}
        </ProgressBar>
      </td>
      <td className="menu">
        <ApplyMenu transactions={transactions} categorised={categorised} uncategorised={uncategorised}>
          <ButtonDelete item={statement} />
        </ApplyMenu>
      </td>
    </tr>
  )
}

function BalanceCell({ balance, unitBalance, isUnitised } : { balance?:number, unitBalance?:number, isUnitised:boolean }) {
  const format = isUnitised ? NumberFormats.units : NumberFormats.currency2
  const value = isUnitised ? unitBalance : balance

  return (
    <td className={clzName("balance", { negative: value && value < 0 })}>
      { formatNumber(value, format) }
    </td>
  )
}

function ButtonSelectStmts({item, statements}:{item:Item, statements?:BankStmtFileItem[]}) {
  const { viewState } = useViewContext()
  const selected = viewState.isSelected(item.key)
  const classImage = (selected ? "fal fa-check-circle" : "fal fa-circle")

  return (
    <button onClick={onClickSelect}>
      <i className={classImage} />
    </button>
  )

  function onClickSelect(e:any) {
    e.stopPropagation()

    const itemState = viewState.toggleSelected(item.key)
    if (statements) {
      for (const stmt of statements) {
        viewState.setSelected(stmt.key, itemState.selected)
      }
    }
    viewState.onEvent(itemState)
  }
}
