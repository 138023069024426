import { useFilterContext } from "../../../hooks/context/FilterContext"
import { DateInput } from "../../controls/editor/DateInput"
import { Field, FieldSet } from "../../controls/form/Form"

export function FromToDateEditor(props: any) {
  const { filterStack, scaleSnapped } = useFilterContext()
  return (
    <FieldSet>
      <Field className="range">
        <label>From</label>
        <menu>
          <DateInput date={scaleSnapped.start} onChange={onChangeStart} />
        </menu>
      </Field>
      <Field className="range">
        <label>To</label>
        <menu>
          <DateInput date={scaleSnapped.end} onChange={onChangeEnd} />
        </menu>
      </Field>
    </FieldSet>
  )

  function onChangeStart(start:number) {
    const scale = {...filterStack.scale, start }
    filterStack.push({ scale })
  }

  function onChangeEnd(end:number) {
    const scale = {...filterStack.scale, end }
    filterStack.push({ scale })
  }
}
