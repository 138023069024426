import { ReactNode } from "react"
import { Link } from "react-router-dom"
import { toLowerNospaces } from "../core/utils/Utils"
import { ExpandableLabel } from "../ui/controls/Expandable"
import { findRoute } from "../ui/controls/RouteUtils"
import { documentationRoutes } from "./DocumentationRoutes"

export function Section({ title, sectionMap, children }: { 
  title: string,
  sectionMap?: Map<string,string>
  children: ReactNode 
}) {
  const id = toLowerNospaces(title)
  if (sectionMap) {
    sectionMap.set(id, title)
  }

  return (
    <section id={id}>
      <h2>{title}</h2>
      {children}
    </section>
  )
}

export function SectionList({ sectionMap } : { sectionMap: Map<string,string> }) {
  const items:JSX.Element[] = []
  for (const id of sectionMap.keys()) {
    items.push(<li><a href={'#'+id}>{sectionMap.get(id)}</a></li>)
  }

  return (
    <ul className="toc">{ items }</ul>
  )
}

export function ChildPageList({ path } : { path:string }) {
  const parentRoute = findRoute(path, documentationRoutes)
  return (
    <ul>
      { parentRoute?.children?.filter(route => route.path).map(route => 
        <li>
          <Link to={route.path as string}>{route.name}</Link>
        </li>
      )}
    </ul>
  )
}

export function ExpandableContents({ path } : { path:string }) {
  return (
    <ExpandableLabel label="Contents">
      <ChildPageList path={path} />
    </ExpandableLabel>
  )
}