import { useSetAtom } from 'jotai'
import { useParams } from "react-router-dom"
import { Bank, ModelKeys } from "../../core/model/ModelKeys"
import { BankStmtFileItem } from "../../core/types/BankStmtFileItem"
import { Logger } from "../../core/utils/Logger"
import { BankStmtTransAtoms } from "../../hooks/bankstmt/BankStmtTransAtoms"
import { useModel } from "../../hooks/context/ModelContext"
import { ViewContextProvider } from '../../hooks/context/ViewContext'
import { ItemDetailsCardSelector } from "../cards/ItemDetailsCardSelector"
import { BankStmtCard } from "../cards/bankstmt/BankStmtCard"
import { BankStmtFileUploadCard } from "../cards/bankstmt/BankStmtFileUploadCard"
import { BankStmtListCard } from '../cards/bankstmt/BankStmtListCard'
import { BankStmtRuleCard } from "../cards/bankstmt/BankStmtRuleCard"
import { BankStmtTransDialog } from "../cards/bankstmt/BankStmtTransDialog"
import { JemstoneSidebar } from '../layouts/JemstoneSidebar'
import { ViewHeader } from "./ViewHeader"
import { ExportDataCard } from '../cards/settings/ExportDataCard'
import { BankStmtRefreshCard } from '../cards/bankstmt/BankStmtRefreshCard'

const logger = new Logger("view.BankStmtView")

export function BankStmtView() {
  const { model } = useModel()
  const { key } = useParams<any>()
  // const { pathname } = useLocation()
  const setBankStmtFile = useSetAtom(BankStmtTransAtoms.bankStmtFile)

  const rootKey = key as string || ModelKeys.bank.stmts
  const rootItem = model.getItem(rootKey)
  if (!rootItem) {
    return <></>
  }

  // Set atom if a bank statement
  const isBankStmt = Bank.isStmt(model, rootItem)
  if (isBankStmt) {
    setBankStmtFile(rootItem as BankStmtFileItem)
  }

  logger.debug("Rendering: item='%s', isBankStmt=%s", rootItem.name, isBankStmt)
            
  return (
    <ViewContextProvider>
      <ViewHeader rootKey={rootKey} />
      
      <JemstoneSidebar/>
      
      <main>
        <div id="BankStmtView" className="grid">
          <CardSelector/>
        </div>
      </main>

      <BankStmtTransDialog title="transactions"/>
    </ViewContextProvider>
  )

  function CardSelector(props:any) {
    if (rootItem.key === ModelKeys.bank.root || rootItem.key === ModelKeys.bank.stmts) {
      return (
        <>
          <BankStmtListCard/>
          <BankStmtRefreshCard/>
          <BankStmtFileUploadCard/>
          <ExportDataCard bankStmts={true} />
        </>
      )
    }
    if (rootItem.key === ModelKeys.bank.rules) {
      return <BankStmtRuleCard />
    }
    if (isBankStmt) {
      return <BankStmtCard />
    }
    return <ItemDetailsCardSelector item={rootItem} />
  }
}
