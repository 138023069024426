import { Provider } from 'jotai'
import { RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom'
import { getAtomStore } from "./core/atom/AtomUtils"
import { documentationRoutes } from './docs/DocumentationRoutes'
import { DocumentationView } from './docs/DocumentationView'
import { JemstoneLogin } from './ui/layouts/JemstoneLogin'
import { ProtectedRoute } from './ui/layouts/ProtectedRoute'
import { BankStmtView } from './ui/views/BankStmtView'
import { CardView } from './ui/views/CardView'
import { DashboardView } from './ui/views/DashboardView'
import { FilesView } from './ui/views/FilesView'
import { ItemView } from './ui/views/ItemView'
import { SettingsView } from './ui/views/SettingsView'
import { TableView } from './ui/views/TableView'
import { wizardRoutes } from './ui/wizards/WizardRoutes'
import { WizardView } from './ui/wizards/WizardView'

import "./ui/assets/css/jemstone.css"
import "./ui/assets/fontawesome-pro/css/fontawesome.min.css"
import "./ui/assets/fontawesome-pro/css/light.min.css"
import "./ui/assets/fontawesome-pro/css/regular.min.css"
import "./ui/assets/fontawesome-pro/css/solid.min.css"
import "./ui/assets/fontawesome-pro/css/thin.min.css"

const routes:RouteObject[] = [
  { path: "/login", Component: JemstoneLogin },
  { path: "/*",     Component: ProtectedRoute,
    children: [
      { path: "dashboard",      Component: DashboardView },
      { path: "card/:key",      Component: CardView },
      { path: "item/:key",      Component: ItemView },
      { path: "settings/:key",  Component: SettingsView },
      { path: "settings",       Component: SettingsView },
      { path: "table/:key",     Component: TableView },
      { path: "table",          Component: TableView },
      { path: "bank/:key",      Component: BankStmtView },
      { path: "files",          Component: FilesView },
      { path: "wizard/*",       Component: WizardView, children: wizardRoutes as any },
      { path: "docs/*",         Component: DocumentationView, children: documentationRoutes as any },
      { path: "*",              Component: DashboardView },
    ]
  },
]

// Create the browser router
const router = createBrowserRouter(routes)

// Create the app
export function App(props:any) {
  return (
    <Provider store={getAtomStore()}>
      <RouterProvider router={router} />
    </Provider>
  )
}

// // Create the app
// export function App(props:any) {
//   return (
//     <Provider store={getAtomStore()}>
//       <BrowserRouter>
//         <Routes>
//           <Route path="/*"                element={ <JemstoneLayoutWithAuth/> }>
//             <Route path="dashboard/:key"  element={ <DashboardView/> }/>
//             <Route path="dashboard"       element={ <DashboardView/> }/>
//             <Route path="card/:key"       element={ <CardView/> }/>
//             <Route path="card"            element={ <CardView/> }/>
//             <Route path="item/:key"       element={ <ItemView/> }/>
//             <Route path="settings/:key"   element={ <SettingsView/> }/>
//             <Route path="settings"        element={ <SettingsView/> }/>
//             <Route path="table/:key"      element={ <TableView/> } />
//             <Route path="table"           element={ <TableView/> } />
//             <Route path="file/:key"       element={ <BankStmtView/> } />
//             <Route path="file"            element={ <BankStmtView/> } />
//             <Route path="setup/*"         element={ <WizardView/> }/>
//             <Route path="docs/*"          element={ <DocumentationView /> }/>
//             <Route path="*"               element={ <DashboardView/> } />
//           </Route>
//         </Routes>
//       </BrowserRouter>
//     </Provider>
//   )
// }
