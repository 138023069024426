import { differenceInCalendarDays, differenceInCalendarYears } from "date-fns"
import { ValuationItem } from "../types/ValuationItem"

export class ValuationCalculator {
  public valuations: ValuationItem[]
  public isUnitised: boolean

  public constructor(valuations: ValuationItem[], isUnitised:boolean) {
    this.valuations = valuations || []
    this.valuations.sort((v1,v2) => v1.date - v2.date)
    this.isUnitised = isUnitised
  } 

  get count() {
    return this.valuations?.length ?? 0
  }

  get first() {
    return this.valuations?.at(0)
  }

  get last() {
    return this.valuations?.at(-1)
  }

  get firstDate() {
    return this.first?.date ?? 0
  }

  get lastDate() {
    return this.last?.date ?? 0
  }

  get days() {
    return differenceInCalendarDays(this.lastDate, this.firstDate)
  }

  get years() {
    return differenceInCalendarYears(this.lastDate, this.firstDate)
  }

  get increase() {
    if (this.first && this.last) {
      if (this.first.unitPrice && this.last.unitPrice) {
        return this.last.unitPrice - this.first.unitPrice
      }
      return this.last.value - this.first.value
    }
    return 0
  }

  get increasePercent() {
    if (this.first && this.last) {
      if (this.first.unitPrice && this.last.unitPrice) {
        return (this.last.unitPrice - this.first.unitPrice) / this.first.unitPrice
      }
      return this.first.value ? (this.last.value - this.first.value) / this.first.value : 0
    }
    return 0
  }

  get cagr() {
    return this.cagrFrom(this.first, this.last)
  }

  public cagrFrom(initial:ValuationItem|number|undefined, future:ValuationItem|number|undefined) {
    if (typeof initial === "number") {
      initial = this.at(initial)
    }
    if (typeof future === "number") {
      future = this.at(future)
    }

    if (initial && future) {
      const days = differenceInCalendarDays(future.date, initial.date)
      if (initial.unitPrice && future.unitPrice) {
        return (Math.pow(future.unitPrice / initial.unitPrice, 1/days) - 1) * 365
      }
      return initial.value ? (Math.pow(future.value / initial.value, 1/days) - 1) * 365 : 0
    }
    return 0
  }

  public at(date:number) {
    return this.findLatest(date)
  }

  public findLatest(date = Date.now()) : ValuationItem | undefined {
    let found
    for (const item of this.valuations) {
      if (item.date <= date) {
        found = item
      } else if (found) {
        return found
      } else {
        return this.first
      }
    }
    return this.last
  }
}
