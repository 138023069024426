import { NumberFormats } from '../core/utils/Numbers'
import { useDebug } from "./useDebug"

export function useCurrencyFormat() {
  const { debug } = useDebug()
  const currencyFormat = debug ? NumberFormats.number2 : NumberFormats.integer

  // logger.debug("useCurrencyFormat: debug=%s, currencyFormat=%o", debug, currencyFormat)

  return currencyFormat
}