import { ReactNode } from "react"
import { clzName } from "../../../core/utils/Utils"
import { FormDataJson } from "../../forms/FormDataJson"
import { Card } from "../Card"

export function Form({id, children} : { id?:string, children:ReactNode}) {
  return (
    <Card id={id} className="form" open onClick={(e:any) => e.stopPropagation()}>
      {children}
    </Card>
  )
}

export function FormBody({noJson, children} : { noJson?:boolean, children:ReactNode }) {
  return (
    <section>
      <div>
        {children}
      </div>
      {!noJson && 
        <FormDataJson />
      }
    </section>
  )
}

export function FieldSet({className, visible, children} : { className?:string, visible?:boolean, children:ReactNode}) {
  if (visible !== false) return (
    <fieldset className={className}>
      {children}
    </fieldset>
  )
  return <></>
}

export function Field({className, visible, children} : { className?:string, visible?:boolean, children:ReactNode}) {
  if (visible !== false) return (
    <div className={clzName("field", className)}>
      {children}
    </div>
  )
  return <></>
}
