import { useAtomValue } from "jotai"
import { NormalizeItem } from "../../../core/model/NormalizeItem"
import { Item } from '../../../core/types/Item'
import { useModel } from "../../../hooks/context/ModelContext"
import { ShowIf } from "../../controls/ShowIf"
import { showJsonAtom } from '../../controls/buttons/ButtonShowJson'

export function ItemDetailsJson<T extends Item>({ item, items }: { item?:T, items?:(T|undefined)[] }) {
  const showJson = useAtomValue(showJsonAtom)

  items ??= [item]

  return (
    <ShowIf visible={showJson}>
      <div className="json">
        <JsonTable items={items} />
        {/* <pre>{ JSON.stringify(item, null, 2) }</pre> */}
      </div>
    </ShowIf>
  )
}

export function JsonTable<T extends Item>({ items }: { items:(T|undefined)[] }) {
  const { model } = useModel()
  
  return (
    items.filter(item => (item !== undefined)).map(item =>
      <table className="json" key={item.key}>
        <tbody>
          { NormalizeItem.sortedKeys(item).map(key => 
            <tr key={key}>
              <th>{ key }:</th>
              <ValueCells key1={key} value={(item as any)[key]} />
            </tr>
          )}
        </tbody>
      </table>
    )
  )

  function ValueCells({key1,value} : { key1:any, value:any }) {
    const valueStr = NormalizeItem.format(model, key1, value)
    const explanation = NormalizeItem.resolve(model, key1, value)

    if (explanation) return (
      <>
        <td>{valueStr}</td>
        <td>{explanation}</td>
      </>
    )

    return (
      <td colSpan={2}>{valueStr}</td>
    )
  }
}
