import config from './logger-config.json'

export enum Level { ERROR=1, WARN, INFO, LOG, DEBUG, TRACE }

export class LoggerConfig {
  public static readonly DefaultLogLevel = LoggerConfig.getDefaultLogLevel()

  public static readonly inclusions = new Set<string>(config.inclusions)
  public static readonly exclusions = new Set<string>(config.exclusions)

  public static readonly defaultLevel = config.defaultLevel

  public static readonly defaultDateFormat = config.defaultDateFormat

  public static readonly useGroups = config.useGroups

  public static getDefaultLogLevelStr() {
    const NODE_ENV = process.env.NODE_ENV ?? "development"
    return config.defaultLevel[NODE_ENV]
  }

  private static getDefaultLogLevel() {
    const NODE_ENV = process.env.NODE_ENV ?? "development"
    const level = config.defaultLevel[NODE_ENV]
  
    console.debug("Logger.defaultLevel: build='%s', level=%s", NODE_ENV, level)
  
    switch (level) {
      case "ERROR": return Level.ERROR
      case "WARN":  return Level.WARN
      case "INFO":  return Level.INFO
      case "LOG":   return Level.LOG
      case "DEBUG": return Level.DEBUG
      case "TRACE": return Level.TRACE
      default:      return Level.LOG
    }
  }
}
