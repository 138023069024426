import { NavLink } from "react-router-dom"
import { Logger } from "../../core/utils/Logger"
import { Card, CardSection } from "../controls/Card"
import { RouteItem } from "../controls/RouteUtils"
import { WizardHeader } from "./WizardHeader"
import { wizardRoutes } from "./WizardRoutes"

const logger = new Logger("wizards.WizardStartMenu")

export function WizardStartMenu() {
  const start = wizardRoutes[0]
  const routes = getRoutesToRender()
  
  logger.debug("Rendering: routes=%o", routes)

  return (
    <Card id="WizardStartPage" className="wizard-page">
      <WizardHeader title={start.name} subtitle={start.description} />
      <CardSection className="wizard-menu">
        { routes.map(route =>
          <li className="wizard-menu-item" key={route.path}>
            <i className={route.icon} />
            <h2>
              <NavLink to={route.path ?? ""} state={{route}}>
                {route.name}
              </NavLink>
            </h2>
            <p>{route.description}</p>
          </li>
        )}
      </CardSection>
    </Card>
  )

  function getRoutesToRender() {
    const routes:Partial<RouteItem>[] = []

    for (let i=1; i < wizardRoutes.length; i++) {
      const { path, name, icon, description } = wizardRoutes[i]
      routes.push({ path, name, icon, description })
    }

    return routes
  }
}

