import ShortUniqueId from 'short-unique-id'
import isEqualTo from "lodash/isEqual"
import camelCase from "lodash/camelCase"
import classNames from 'classnames'

const { randomUUID } = new ShortUniqueId({ length: 8 })

export function getUniqueId() {
  return randomUUID()
}

export function clzName(...args: classNames.ArgumentArray) {
  const result = classNames(args)
  return (result === "") ? undefined : result
}

export function isBlank(text:string|undefined) {
  return (!text || text.length === 0)
}

export function ifBlank(text:string|undefined, value:string) {
  return (!text || text.length === 0) ? value : text
}

export function isEqual(o1:any, o2:any) {
  return isEqualTo(o1,o2)
}

export function startsWith(data:string | undefined, search:string) : boolean {
  try {
    if (data && typeof data === "string") {
      return data.startsWith(search)
    }
  } catch (e:any) {
    console.warn("startsWith: Cannot search for '%s' in: ", data)
  }

  return false
}

export function toLowerNospaces(text: string): string {
  return text.trim().toLowerCase().replace(/ /g, "-")
}

/**
 * Convert some text to camel case
 */
export function toCamelCase(text:string|undefined) {
  return camelCase(text)
}

/**
 * Convert some text to sentence case
 */
export function toSentenceCase(text: string): string {
  return text.replace( /([A-Z])/g, " $1" ).trimStart()
}

/**
 * Return an string array of enum labels that have been sentence cased
 * @param e An enum
 */
export function makeEnumNames(e: any): string[] {
  let labels = []

  for (let item in e) {
    if (isNaN(Number(item))) {
      labels.push(toSentenceCase(item))
    }
  }
  return labels
}
