import { Unsubscribe } from "firebase/auth"
import { collection, doc, getDoc, getFirestore, setDoc } from "firebase/firestore"
import { DataFileName, getDataItems } from "../data/DataItems"
import { saveToDB, subscribe } from "../service/FireStoreService"
import { ItemStatus } from "../types/Item"
import { Logger } from "../utils/Logger"
import { FilesItem, getFiles, newFile, setFiles } from "./FilesModel"
import { getCurrentUserEmail } from "./UserService"

const logger = new Logger("core.FilesService")

let userFilePath: UserFilePath | undefined

export class UserFilePath {
  public readonly users = "users"
  public readonly files = "files"
  public readonly items = "items"

  public readonly file: string

  constructor(fileKey?:string) {
    this.file = fileKey ?? "FI-DEFAULT"
  }
  
  get email() {
    return getCurrentUserEmail()
  }

  toString() {
    return `${this.users}/${this.email}/${this.files}/${this.file}/${this.items}`
  }
}

export function getUserFilePath() {
  if (!userFilePath) {
    userFilePath = new UserFilePath()
  }
  return userFilePath
}

export function setUserFileKey(fileKey?:string) {
  userFilePath = new UserFilePath(fileKey)
  logger.debug("setUserFileKey: fileKey=%s, userFilePath=%o", fileKey, userFilePath)
}

export function loadFiles() {
  logger.start("loadFiles", "Loading files for currentUser=%s", getCurrentUserEmail())
  subscribeFiles()
  logger.finish("loadFiles")
}

export function unloadFiles() {
  userFilePath = undefined
  unsubscribeFiles()
}

/**
 * Create a new item in /users/${email}/files, and load the file with items 
 * in /users/${email}/files/${fileKey}/items
 * 
 * @returns 
 */
export function createUserFile(file:FilesItem|string, personaFile?:DataFileName) {
  const fileItem = (typeof file === "string") ? newFile(file) : file

  // Save the new file
  saveFileItem(fileItem)

  // Load new file with default data
  const filePath = new UserFilePath(fileItem.key)
  saveToDB(getDataItems("Base"), filePath)

  // Also save persona template if required
  if (personaFile) {
    saveToDB(getDataItems(personaFile), filePath)
  }

  return fileItem
}

/**
 * Subscribe to changes in the document collection for this user
 */
let unsubscribeFilesCallback:Unsubscribe | null = null
 
function subscribeFiles() {
  const collection = getUserFileCollection()
  logger.debug("subscribeUserFiles: collection=%s", collection.path)

  unsubscribeFilesCallback = subscribe(collection, onFilesSnapshot)
}

function unsubscribeFiles() {
  if (unsubscribeFilesCallback) {
    unsubscribeFilesCallback()
    unsubscribeFilesCallback = null
  }
}

function getUserFileCollection() {
  const { users, email, files } = getUserFilePath()
  return collection(getFirestore(), users, email, files)
}

/**
 * Called when new or modified files are subscribed to, create a new FilesItem[] array and replace
 * existing FileItem's with the modified item, or add it to the array
 * 
 * @param modifiedFiles 
 */
function onFilesSnapshot(modifiedFiles:FilesItem[]) {
  
  const newFiles = [...getFiles()]

  logger.debug("setModifiedFiles: %d modified files to merge: modifiedFiles=%o, files=%o", modifiedFiles.length, modifiedFiles, newFiles)

  for (const newFile of modifiedFiles) {
    const index = newFiles.findIndex(file => file.key === newFile.key)
    if (index < 0) {
      // Append new file
      newFiles.push(newFile)
    } else if (newFile.status === ItemStatus.DELETED) {
      // File deleted - remove it
      newFiles.splice(index,1)
    } else {
      // File exists - replace it
      newFiles[index] = newFile
    }
  }

  setFiles(newFiles)
}

export async function saveFileItem(fileItem:FilesItem) {
  const { users, email, files } = getUserFilePath()
  const docRef = doc(getFirestore(), users, email, files, fileItem.key)

  logger.debug("saveFileItem: Saving file=%o to %s", fileItem, docRef.path)
  
  return setDoc(docRef, fileItem)
}

export async function loadFileItem(fileKey:string) {
  const { users, email, files } = getUserFilePath()
  const docRef = doc(getFirestore(), users, email, files, fileKey)

  const userDoc = await getDoc(docRef)
  const fileItem = userDoc.data() as FilesItem | undefined

  logger.debug("loadFileItem: Loaded filesItem=%o from %s", fileItem, docRef.path)

  return fileItem
}
