import { ModelKeys } from "../../../core/model/ModelKeys"
import { Logger } from "../../../core/utils/Logger"
import { TimeScale } from "../../../core/utils/TimeScale"
import { useFilterContext } from "../../../hooks/context/FilterContext"
import { useInvestmentModel } from "../../../hooks/context/InvestmentModelContext"
import { usePivotTables } from "../../../hooks/pivot/usePivotTables"

const logger = new Logger("charts.budget.useBudgetActualPivotRows")

export function useBudgetActualPivotRows(date:number = Date.now()) {
  const { name } = useInvestmentModel()
  const { filter, filterItem, isCategory } = useFilterContext()
  const { model, pivotTable, budgetPivot } = usePivotTables()
  const scale = getDateRange()

  logger.setContext(name).debug("filter=%o", Logger.Filter(filter))

  const category = (isCategory && filterItem) ? filterItem : model.getItem(ModelKeys.category.root)

  // Note that pivotTable depends upon the pivotType setting - Actual, Budget, Forecast
  const actualRow = scale.end ? pivotTable.totals  : pivotTable.rowAt(date)
  const budgetRow = scale.end ? budgetPivot.totals : budgetPivot.rowAt(date)
  const actualTotal = actualRow.cell(category.key).total
  const budgetTotal = budgetRow.cell(category.key).total

  const start = scale.start || budgetRow.startDate
  const end = scale.end || budgetRow.endDate

  return {
    actualRow, actualTotal,
    budgetRow, budgetTotal,
    scale: { start, end, units:scale.units } as TimeScale
  }

  function getDateRange() {
    const { scale, included } = filter
    if ((included && included.size > 0) || scale.start || scale.end) {
      return pivotTable.scale
    }
    return scale
  }
}