import { useState } from 'react'
import { formatDate, parseDate } from '../../../core/utils/DateFormat'
import { Logger } from "../../../core/utils/Logger"

const logger = new Logger("control.DateInput")

export function DateInput(props:{
  date?: number
  placeholder?: any
  readonly?: boolean
  onChange?: any
}) {
  const { onChange, placeholder } = props
  const [ readonly, setReadonly ] = useState(props.readonly !== false)

  const initialDate = props.date

  const initialDateStr = initialDate ? formatDate(initialDate, "yyyy-MM-dd") : undefined
  const placeholderStr = placeholder ? formatDate(placeholder, "yyyy-MM-dd") : undefined

  logger.debug("Rendering: initialDate=%s", Logger.Date(initialDate))

  if (readonly) return (
    <button onClick={(e:any) => setReadonly(false)}>
      { formatDate(initialDate, "dd-MMM-yyyy")}
    </button>
  )
              
  return (
    <input type="date" 
           defaultValue={initialDateStr}
           placeholder={!initialDateStr ? placeholderStr : undefined}
           tabIndex={readonly ? undefined : 0}
           autoFocus
           onBlur={onBlur} />
  )

  function onBlur(e:any) {
    const newDate = parseDate(e.target.value, "yyyy-MM-dd")

    if (newDate && newDate.getTime() !== initialDate) {
      logger.debug("onChangeDate: newDate=%o", newDate)

      if (onChange !== undefined) {
        onChange(newDate?.getTime())
      }
    }
    setReadonly(true)
  }
}