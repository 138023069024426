import { Item } from "../types/Item"
import { Logger } from "../utils/Logger"
import { Progress } from "./Progress"

const logger = new Logger("utils.BackgroundBuilder")

export interface BackgroundBuilderEvents {
  onStart() : void
  onFinish() : void
  onProgress(progress:Progress) : void
}

export class BackgroundBuilder<T extends Item> {  
  protected start = 0
  protected total = 0
  protected count = 0
  protected modified:T[] = []
  protected events?:BackgroundBuilderEvents

  public run(events?:BackgroundBuilderEvents) {
    this.events = events
    this.setTimer()
  }

  private setTimer() {
    setTimeout(() => this.onIdle(), 1)
  }

  private onIdle() {
    if (this.start === 0) {
      this.onStart()
    }

    // Begin next block of work
    this.start = Date.now()
    this.doWork()

    // Update progress counters
    if (this.events?.onProgress) {
      this.events?.onProgress(this.progress)
    }

    // Schedule next timeslice if more work to do
    if (this.workRemaining) {
      this.setTimer()
    } else {
      this.onFinish()
    }
  }

  protected doWork() {}

  protected onStart() {
    this.modified = []
    if (this.events?.onStart) {
      this.events?.onStart()
    }
  }
  
  protected onFinish() {
    const progress = this.progress
    logger.debug("onFinish: progress=%o", progress)

    if (this.events?.onProgress) {
      this.events?.onProgress(progress)
    }
    if (this.events?.onFinish) {
      this.events?.onFinish()
    }
  }

  get progress() {
    const progress:Progress = { 
      count: this.count, 
      total: this.total, 
      modified: this.modified.length,
      modifiedItems: this.modified,
    }
    return progress
  }

  get workRemaining() {
    return Math.max(0, this.total - this.count)
  }

  get timeRemaining() {
    return Math.max(0, 100 - this.timeElapsed)
  }

  get timeElapsed() {
    return Date.now() - this.start
  }

  get percentComplete() {
    return this.total ? this.count/this.total * 100 : 100
  }
}
