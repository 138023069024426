import { useSetAtom } from "jotai"
import { Logger } from "../../core/utils/Logger"
import { useFilterContext } from "../context/FilterContext"
import { CashflowSearchAtoms } from "./CashflowSearchAtoms"

const logger = new Logger("hooks.useCashflowSearch")

export function useCashflowSearch() {
  logger.start("useCashflowSearch")

  const setSearchItems = useSetAtom(CashflowSearchAtoms.searchItems)
  const { cashflowItems } = useFilterContext()

  setSearchItems(cashflowItems)

  // const logItems = cashflowItems.map(item => (
  //   {
  //     date: formatDate(item.date),
  //     name: item.name,
  //     amount: item.amount
  //   }
  // ))

  logger.finish("useCashflowSearch", "Set %d cashflowItems", cashflowItems.length)
}