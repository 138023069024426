import Papa from 'papaparse'
import { logStatements } from "../../invest/BankStmtModel"
import { Model } from "../../model/Model"
import { ModelKeys } from "../../model/ModelKeys"
import { BankStmtFileItem } from "../../types/BankStmtFileItem"
import { ItemStatus } from "../../types/Item"
import { TransactionItem } from "../../types/TransactionItem"
import { formatDate, parseDate } from '../../utils/DateFormat'
import { Logger } from "../../utils/Logger"
import { parseNumber } from '../../utils/Numbers'
import { FileParserResults } from './FileParserResults'

const logger = new Logger("builders.CsvFisherFundsParser")

interface CsvTrans {
  Date: string
  Fund: string
  Description: string
  Units: number
  "Unit Price": string
  Amount: number
}

export function loadFisherFundsCsv(csvData:string, model:Model, fileName:string) : FileParserResults {
  logger.start("loadFisherFundsCsv", "Parsing file '%s'", fileName)

  // Parse the CSV data
  const result = Papa.parse<CsvTrans>(csvData, {
    header: true,
    dynamicTyping: true
  })

  // Create a model item for the bank statement file
  const accountNumber = getAccountNumber(fileName)
  
  const statements:BankStmtFileItem[] = []
  const transactions:TransactionItem[] = []

  // Map data to Transaction items
  const length = result.data.length
  for (let i=0; i < length; i++) {
    const record = result.data[i]
    if (record.Date) {
      const date = parseDate(record.Date, "yyyy-MM-dd").getTime()
      const unitPrice = parseNumber(record["Unit Price"]) || 0
      const units = record.Units
      const value = record.Amount

      const categoryKey = (value >= 0) ? ModelKeys.category.buy : ModelKeys.category.sell
      const typeKey = model.getItemTypeKey(categoryKey)

      const fundAccountCode = accountNumber + "-" + record.Fund
      const statement = getStatement(fundAccountCode)

      const trans:TransactionItem = {
        key: model.newKey(statement.key, "CSV-"),
        parentKey: statement.key,
        typeKey: typeKey,
        categoryKey: categoryKey,
        startDate: date,
        value: value,
        units: units,
        unitPrice: unitPrice,
        name: record.Fund,
        description: record.Description,
        sortOrder: date + (length-i),              // Use index to preserve ordering
        status: ItemStatus.NEW,
        modifiedDate: Date.now(),
      }

      if (trans.description === "Switch") {
        trans.description = (trans.value > 0) ? "Switch In" : "Switch Out"
      }

      transactions.push(trans)
    }
  }

  if (logger.isTraceEnabled) {
    logger.debug("Parsed %d items from file '%s'", 
      transactions.length, fileName,
      transactions.map(item => ({ 
        key: item.key,
        code: item.code,
        accountKey: item.accountKey,
        accountCode: model.getItem(item.accountKey)?.code,
        date: formatDate(item.startDate || 0),
        value: item.value,
      })))
  }

  logger.finish("loadFisherFundsCsv", "Parsed file '%s', %d/%d transactions in %d statements", 
                fileName, transactions.length, length, statements.length, logStatements(statements))

  return { data:result.data, statements, transactions }
  
  
  /** Extract the account number from the filename */
  function getAccountNumber(fileName:string) {
    const p0 = fileName.indexOf("_FI")
    const p1 = fileName.indexOf("_", p0+1)
  
    return fileName.substring(p0+1, p1)
  }
  
  /** Get a statement for the specified account */
  function getStatement(fundAccountCode:string) : BankStmtFileItem {
    let statement = statements.find(stmt => stmt.accountNumber === fundAccountCode)
    if (statement === undefined) {
      statement = model.newItem<BankStmtFileItem>(ModelKeys.bank.stmts, ModelKeys.file.csv, "CSV-")
      statement.name = fileName
      statement.accountKey = model.getItemKey(fundAccountCode)
      statement.accountNumber = fundAccountCode
      statements.push(statement)
    }

    return statement
  }
}
