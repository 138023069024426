import { Link, Outlet } from "react-router-dom"
import { Logger } from "../core/utils/Logger"
import { ViewMenu } from "../ui/views/ViewMenu"
import { AppInfo } from "../VersionInfo"
import { ButtonSidebar, Sidebar } from "./Sidebar"

import "./css/documentation.css"

const logger = new Logger("docs.DocumentationView")

export function DocumentationView(props:any) {
  logger.debug("Rendering:")
  
  return (
    <>
      <Header/>
      <Sidebar/>
      <main className="documentation">
        <Outlet/>
      </main>
    </>
  )
}

function Header() {
  const tooltip = `${AppInfo.name} ${AppInfo.version}`

  return (
    <header className="documentation">
      <ButtonSidebar/>
      <Link to="/" title={tooltip} />

      <h1>
        <Link to="/docs">Jemstone Money</Link>
      </h1>

      <nav>
        <ViewMenu rootKey="" showTheme>
          <Link to="user">User</Link>
          <Link to="dev">Developer</Link>
        </ViewMenu>
      </nav>
    </header>
  )
}

