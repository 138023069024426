import { add } from "date-fns"
import { Model } from "../model/Model"
import { Category, ModelKeys } from "../model/ModelKeys"
import { AssetItem } from "../types/AssetItem"
import { ItemStatus } from "../types/Item"
import { LoanItem } from "../types/LoanItem"
import { TransactionItem } from "../types/TransactionItem"
import { getUniqueId } from "../utils/Utils"
import { getAccountTypeKey, newCashflowItemKey } from "./CashflowBuilder"
import { FutureValueBuilder } from "./FutureValueBuilder"

export class AmortizationBuilder extends FutureValueBuilder {
  private loan: LoanItem

  constructor(model:Model, asset:AssetItem, loan:LoanItem) {
    super(
      model,
      asset,
      loan,
      loan.principal ?? 0,
      loan.rate ?? 0.03,
      loan.term ?? { years:20 },
      loan.startDate ?? Date.now(),
      loan.finishDate,
    )
    this.loan = loan;
    this.logger.setName("builders.AmortizationBuilder")
  }

  public isAmortization() : boolean {
    return true
  }
  
  protected addChildTransactions() {
    if (this.loan) {
      this.logger.start("addChildTransactions")

      const drawdownCategory = this.model.getItem(ModelKeys.category.withdrawal)
      const accountTypeKey = getAccountTypeKey(this.model, this.loan.key)
      const date = this.startDate

      // Loan drawdown
      this.cashflow.addItem({
        key: newCashflowItemKey(),
        parentKey: getUniqueId(),
        assetKey: this.asset.key,
        accountKey: this.loan.key,
        accountTypeKey: accountTypeKey,
        categoryKey: drawdownCategory.key,
        typeKey: drawdownCategory.typeKey,
        name: "Loan Drawdown",
        date: date,
        amount: -Math.abs(this.loan.principal),
        balance: 0,
        calculated: false,
        sortOrder: date,
        status: ItemStatus.TRANSIENT,
      })

      // Loan transactions with a non-zero value
      const transactions = this.model.children<TransactionItem>(this.loan.key, (trans => trans.value !== 0))
                                     .sort((t1,t2) => (t1.startDate ?? 0) - (t2.startDate ?? 0))

      // Ensure successive loan payments do not overlap
      let lp = -1
      for (let i=0; i < transactions.length; i++) {
        const trans = transactions[i]
        if (Category.isPayment(trans)) {
          if (lp > -1) {
            transactions[lp].finishDate = trans.startDate
          }
          lp = i
        }
      }

      // If no payments defined, add one for default payment and frequency
      if (lp === -1) {
        transactions.push(AmortizationBuilder.getPaymentTransaction(this.model, this.loan))
      } 

      // Now add all transactions to the cashflow
      this.cashflow.addTransactions(transactions)

      this.logger.finish("addChildTransactions", "Added %d transactions", transactions.length)
    }
  }

  public static getPaymentTransaction(model:Model, loan:LoanItem) {
    let paymentTransaction = model.getItem<TransactionItem>(loan.paymentTransactionKey)

    if (!paymentTransaction) {
      const paymentCategory = model.getItem(ModelKeys.category.payment)

      let startDate
      if (loan.startDate && loan.paymentFrequency) {
        startDate = add(loan.startDate, loan.paymentFrequency).getTime()
      }

      paymentTransaction = { 
        key:          model.newKey(loan.key),
        parentKey:    loan.key,
        categoryKey:  paymentCategory.key,
        typeKey:      paymentCategory.typeKey,
        name:         paymentCategory.name,
        startDate:    startDate,
        value:        loan.paymentAmount,
        frequency:    loan.paymentFrequency,
        sortOrder:    startDate as number,
        status:       ItemStatus.NEW,
      }
    }
    return paymentTransaction
  }
}