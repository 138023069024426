import { PortfolioSummaryTable } from "../../../ui/cards/dashboard/PortfolioSummaryTable"
import { PageProvider } from "../../PageProvider"
import { Section } from "../../PageSection"

export function PortfolioPage() {
  return (
    <article>
      <h1>Accounts and Portfolios</h1>
      <section>
        <p>
          TODO: Describe what a portfolio is
        </p>
        <Section title="Portfolios">
          <p>Here is a live portfolio dashboard</p>
          <PageProvider itemCode="MY-INVESTMENTS">
            <PortfolioSummaryTable/>
          </PageProvider>
        </Section>
      </section>
    </article>
  )
}