import { useMemo } from "react"
import { ModelAnalyzer } from "../../../../core/builder/ModelAnalyzer"
import { useModel } from "../../../../hooks/context/ModelContext"
import { useCardOpen } from "../../../../hooks/useCardOpen"
import { Card, CardHeader, CardSection } from "../../../controls/Card"
import { ButtonOpen } from "../../../controls/buttons/ButtonOpen"

export function ModelAnalyzerCards() {
  return (
    <>
      <ModelAnalyzerCard type="producer" title="Model Dependencies - Producers"/>
      <ModelAnalyzerCard type="consumer" title="Model Dependencies - Consumers"/>
    </>
  )
}

export function ModelAnalyzerCard({type,title}: {type:string, title:string}) {
  const { model } = useModel()
  const { isOpen, toggle, openAtom } = useCardOpen("ModelAnalyzerCard", type, false)
  const analyzer = useMemo(() => new ModelAnalyzer(model, true), [model])

  return (
    <Card className={`ModelAnalyzerCard ${type}`} open={isOpen}>
      <CardHeader>
        <i className="fal fa-diagram-project" />
        <h2 onClick={toggle}>
          {title}
        </h2>
        <menu>
          <ButtonOpen openAtom={openAtom} />
        </menu>
      </CardHeader>
      <CardSection>
        <p>
          Producers require that all Consumers be rebuilt when they change.
          Consumers require the output from the Producer in order to be built.
        </p>
        { type === "producer" && <ProducerTable/> }
        { type === "consumer" && <ConsumerTable/> }
      </CardSection>
    </Card>
  )

  function ProducerTable() {
    const producers = model.sortByName(analyzer.producers)

    return (
      <table className="table sticky-headers">
        <thead>
          <tr>
            <th>Producer</th>
            <th>Consumers</th>
          </tr>
        </thead>
        <tbody>
          { producers.map(producer =>
            <tr key={producer.key}>
              <th>{producer.name}</th>
              <td colSpan={2}>
                { analyzer.getConsumerNames(producer.key) }
              </td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  function ConsumerTable() {
    const consumers = model.sortByName(analyzer.consumers)

    return (
      <table className="table sticky-headers">
        <thead>
          <tr>
            <th>Consumer</th>
            <th>Producers</th>
          </tr>
        </thead>
        <tbody>
          { consumers.map(consumer =>
            <tr key={consumer.key}>
              <th>{consumer.name}</th>
              <td colSpan={2}>
                { analyzer.getProducerNames(consumer.key) }
              </td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }
}