import { useFilterBar } from "../../hooks/filter/useFilterBar"
import { CashflowFilterBar } from "../cards/filter/CashflowFilterBar"

export function JemstoneFilterBar(props:any) {
  const { visible } = useFilterBar()

  return (
    <aside className={ visible ? "filterbar open" : "filterbar closed" }>
      <CashflowFilterBar/>
    </aside>
  )
}